/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2016 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import Button from '@react/react-spectrum/Button';
import classNames from 'classnames';
import React, {Component} from 'react';
import {Link} from '../../routes/namedRouteUtils';

/*
  expects any of the following props (all optional)
    text, learnMoreLocation, learnMoreText, actionButtonLocation, actionButtonText
*/
class NoContent extends Component {
  render = () => {
    const {
      className,
      text,
      alignLeft,
      learnMoreText,
      learnMoreLinkText,
      learnMoreLocation,
      actionButtonText,
      actionButtonLocation,
      actionButtonDisabled,
      videoUrl
    } = this.props;

    return (
      <div
        className={ classNames((!alignLeft ? 'u-textCenter' : ''), className) }
      >
        {videoUrl ? (
          <div style={{maxWidth: '560px', margin:'auto'}}>
            <div className="u-16X9-aspect-ratio-box u-aspect-ratio-box">
              <iframe
                className="u-rounded u-noBorder aspect-ratio-target"
                width="560"
                height="315"
                src={videoUrl}
                frameBorder="0"
              />
            </div>
          </div>
        ) : null}
        <div className="u-marginBottom">
          {text ? <div className="u-paddingTop">{text}</div> : null}
          {learnMoreText ?
            <div className="u-paddingTop">
              {learnMoreText || ''}
              {/*these links don't link internally and don't use our internal routes*/}
              <a href={learnMoreLocation} target="_blank" className="spectrum-Link">
                {learnMoreLinkText}
              </a>
            </div>
          : null }
        </div>
        {actionButtonText ?
          <div>
            <Button
              element={Link}
              variant="primary"
              to={actionButtonLocation}
              disabled={actionButtonDisabled}
            >
              {actionButtonText || 'Add New Item'}
            </Button>
          </div>
        : null}
      </div>
    );
  };
};

export default NoContent;
