/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import React from 'react';
import ChevronUpIcon from '@react/react-spectrum/Icon/ChevronUp';
import ChevronDownIcon from '@react/react-spectrum/Icon/ChevronDown';
import classNames from 'classnames';
import {snakeCase} from 'lodash-es';

export default function SortableHeaderCell({column, children, query, onSortValueChanged}) {
  let sortValue;
  if (column.sortValue) {
    sortValue = snakeCase(column.sortValue);
  } else {
    // this assumes that we always use json api data with attributes
    sortValue = snakeCase(
      column.key.startsWith('attributes.') ?
        column.key.replace('attributes.', '') :
        column.key
    );
  }
  const sortFromQuery = query?.sort?.sortValue;
  const isDescending = sortFromQuery?.qualifier === 'descending';
  const matchesColumn = Boolean(sortFromQuery);
  return (
    <span
      className={classNames(
        {'activeSort': matchesColumn},
        'sortableHeader',
        'u-pointer'
      )}
      onClick={() => {
        if (onSortValueChanged) {
          onSortValueChanged(sortValue);
        }
      }}
    >
      {children}
      <span
        className={classNames(
          'sortIcons',
          'u-inlineBlock',
          'u-relativePosition',
          'u-paddingLeftXs'
        )}
      >
        <ChevronUpIcon
          className={classNames(
            {'active': isDescending},
            'sortAscending',
            'sortIcon',
            'u-block',
            'u-absolutePosition'
          )}
          size="XS"
        />
        <ChevronDownIcon
          className={classNames(
            {'active': !isDescending},
            'sortDescending',
            'sortIcon',
            'u-block',
            'u-absolutePosition'
          )}
          size="XS"
        />
      </span>
    </span>
  );
};
