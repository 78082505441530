/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/


import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {GLOBAL_DIALOGS_KEY, getActionCreators as getDialogsActions} from './dialogsActions';
import Dialog from 'spectrum-alternatives/dialog/Dialog';
import Button from '@react/react-spectrum/Button';


export const TERMS_OF_USE_KEY = 'termsOfUse';

class GlobalDialogs extends Component {
  render = () => {
    const {termsOfUseOpen, dialogsActions} = this.props;

    return (
      <Dialog
        open={termsOfUseOpen}
        onClose={dialogsActions.closeDialog.bind(this, TERMS_OF_USE_KEY)}
      >
        <Dialog.Header>Terms of Use</Dialog.Header>
        <Dialog.Content className="termsOfUseContent">
            If Adobe is not listed as the provider of an application (or extension) on the
            listing, then the application is not provided or warranted by Adobe. Your use
            of such application is subject to the terms of use provided by the publisher/
            developer of that application. You and/or other users at your company may be
            able to use this application to access your Customer Data or Customer Content
            as defined in your agreement with Adobe. However, using this application may
            also result in transmission of such data out of Adobe’s systems. To the extent
            data is transmitted out of Adobe’s systems, Adobe is not responsible for the
            privacy, security or integrity of that data.
        </Dialog.Content>
        <Dialog.Footer>
          <Button
            variant="primary"
            onClick={dialogsActions.closeDialog.bind(this, TERMS_OF_USE_KEY)}
          >
            OK
          </Button>
        </Dialog.Footer>
      </Dialog>
    );
  };
}

function mapStateToProps(state) {
  return {
    termsOfUseOpen: state.dialogs.getIn([GLOBAL_DIALOGS_KEY, TERMS_OF_USE_KEY])
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dialogsActions: bindActionCreators(getDialogsActions(GLOBAL_DIALOGS_KEY), dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalDialogs);
