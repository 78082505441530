/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

export default function promiseCreator() {
  const promiseUtil = {
    resolve: null,
    reject: null,
    new() {
      const promise = new Promise((resolve, reject)=>{
        promiseUtil.resolve = resolve;
        promiseUtil.reject = reject;
      }).catch((err)=>{
        if (err instanceof Error) {
          console.error(err);
        }
        // if we decide to log an error when the code editor is canceled
        // we will do that here
      });
      return promise;
    }
  };
  return promiseUtil;
};
