import productionConfig from '../../imsEcConfig/config_production.json';
import qaConfig from '../../imsEcConfig/config_qa.json';
import stagingConfig from '../../imsEcConfig/config_staging.json';

// if a setting needs to be dynamic in the url we should add its key here.
export const ALLOWED_CONFIG_PARAM_KEYS = [
  'reactor-lens_version',
  'lens_env',
  'lens_dev',
  'lens_debug',
  'lens_errorReportingEnabled',
  'api_protocol',
  'api_domain',
  'api_port',
];

export const configs = {
  production: productionConfig,
  qa: qaConfig,
  staging: stagingConfig
};

export const domainMappings = {
  // these should map to the domains in the unified shell
  // https://git.corp.adobe.com/exc/unified-shell/edit/master/packages/unified-shell/src/js/solutions/launch.ts
  // localhost here is debateable, but qa is it's most common use case
  'localhost': 'qa',
  'lens-engd.launch.adobe.net': 'engd',
  'lens-dev.launch.adobe.net': 'dev',
  'lens-qe1.launch.adobe.net': 'qa',
  'launch-stage.adobe.com': 'stage',
  'launch-demo.adobe.com': 'demo',
  'launch.adobe.com': 'prod',
  'experience-qa.adobe.com': 'qa',
  'experience.adobe.com': 'prod',
};

// for production we don't allow swapping blacksmith
if (domainMappings[window.location.hostname] === 'prod') {
  delete ALLOWED_CONFIG_PARAM_KEYS.api_protocol;
  delete ALLOWED_CONFIG_PARAM_KEYS.api_domain;
  delete ALLOWED_CONFIG_PARAM_KEYS.api_port;
}
