/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe Systems Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe Systems Incorporated and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Adobe Systems Incorporated and its
 * suppliers and are protected by all applicable intellectual property
 * laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe Systems Incorporated.
 **************************************************************************/

import {
  STORAGE_DURATION_NONE,
} from '../components/properties/dataElements/dataElementEditActions';
import {ROUTE_NAMES} from '../routes/namedRouteUtils';
import { RESOURCE_TYPES } from './api/apiTypes';

export const ORG_ID_REGEX = new RegExp(/(\w{24})@AdobeOrg/g);
export const RESOURCE_ID_REGEX = new RegExp(/(([A-Z]{2})+\w{32})/g);
export const NEW_RESOURCE_ID_REGEX = new RegExp(/(([A-Z]{2})+new\w{26})/g);
export const DELEGATE_DESCRIPTOR_ID_REGEX = new RegExp(
  /([^\s|"]+::(conditions|dataElements|events|actions|extensionConfiguration)::[^\s|"]+)/g
);

/**
 * Given a resource name, returns info about the name.
 * @param resourceName
 * @returns {{baseName: string, hasCopyText: boolean, copyNumber: number}}
 */
export const getResourceNameInfo = (resourceName) => {
  const resourceNameRegex = /(.*) copy( (\d+))?/;
  const nameMatch = resourceName.match(resourceNameRegex);
  return {
    // The name without any copy text
    baseName: nameMatch ? nameMatch[1] : resourceName,
    // Whether the name has copy text
    hasCopyText: Boolean(nameMatch),
    // The copy number, if one exists.
    copyNumber: nameMatch && Number(nameMatch[3])
  };
};

/**
 * Given the name of a resource that's being copied and a list of names already being used, returns
 * a new, unique name that can be used for the new resource. As an example, if originName were
 * "foo", names would be tried in this order:
 *
 * foo copy
 * foo copy 2
 * foo copy 3
 * foo copy 4
 * ...
 *
 * If originName were "foo copy 3", names would be tried in this order:
 *
 * foo copy 4
 * foo copy 5
 * foo copy 6
 * ...
 *
 * This is intended to replicate OS X Finder behavior when duplicating files.
 */
export const deriveCopyName = (resourceName, reservedNames) => {
  const { baseName, hasCopyText, copyNumber } = getResourceNameInfo(resourceName);

  if (!hasCopyText) {
    // The first time we get a copy name we might be interested in a different
    // property where the original resource name doesn't exist yet. In this case
    // we don't want to add ' copy' to the name.
    const newName = reservedNames.includes(resourceName) ? baseName + ' copy' : baseName;

    if (reservedNames.indexOf(newName) === -1) {
      return newName;
    }
  }

  let candidateNumber = copyNumber ? copyNumber + 1 : 2;

  while (true) {
    const newName = baseName + ' copy ' + candidateNumber;

    if (reservedNames.indexOf(newName) === -1) {
      return newName;
    }

    candidateNumber++;
  }
};

/**
 * Returns whether the resource's origin has NOT been deleted.
 * @param resource The resource in question.
 * @param isNew Whether the resource is in the process of being created (has yet to be saved).
 * @returns {boolean}
 */
export const getIsExistent = (resource, isNew) => {
  return isNew || (
    resource &&
    resource.id &&
    resource.meta &&
    !resource.meta.deletedAt &&
    !resource.meta.originDeletedAt
  );
};

/**
 * Returns whether the resource is enabled.
 * @param resource The resource in question.
 * @param isNew Whether the resource is in the process of being created (has yet to be saved).
 * @returns {boolean}
 */
export const getIsEnabled = (resource, isNew) => {
  return isNew || (resource && resource.attributes.enabled);
};

/**
 * Returns whether the resource is HEAD (rather than a prior revision).
 * @param resource The resource in question.
 * @param isNew Whether the resource is in the process of being created (has yet to be saved).
 * @returns {boolean}
 */
export const getIsHead = (resource, isNew) => {
  return isNew || (resource && resource.attributes.revisionNumber === 0);
};

/**
 * Returns whether the resource is Latest.
 * @param resource The resource in question.
 * @returns {boolean}
 */
export const isResourceLatestAndUnchanged = (resource) => {
  return (
    resource.attributes.revisionNumber === 0 ||
    resource.attributes.revisionNumber === resource.meta.latestRevisionNumber
  ) && resource.attributes.dirty === false;
};

/**
 * Returns whether the resource may be edited.
 * @param resource The resource in question.
 * @param isNew Whether the resource is in the process of being created (has yet to be saved).
 * @param hasRight Whether the user has the appropriate right to edit the resource.
 * @returns {boolean}
 */
export const getIsEditable = (resource, isNew, hasRight) => {
  return hasRight && getIsExistent(resource, isNew) && getIsHead(resource, isNew);
};

export function getResourceOriginId(resource) {
  // the or on this is a little strange but it is there beacuse
  // the api returns null when there are no revisions of this resource
  // meaning this revision is the origin
  return resource?.relationships?.origin?.data?.id || resource?.id;
}

export function getResourceByOriginId(resources, originId) {
  return resources.find(resource=>{
    const resourceOriginId = getResourceOriginId(resource);
    return resourceOriginId === originId;
  });
}

export const resourcesHaveSameOrigin = (...resources) => {
  if (!resources || !resources.length) {
    return true;
  }

  const firstResourceOrigin = getResourceOriginId(resources[0]);
  const anyMismatchedOrigins = resources.some(resource=>(
    getResourceOriginId(resource.links) !== firstResourceOrigin
  ));
  return !anyMismatchedOrigins;
};

export function getResourceCreateLocation(resourceType, params) {
  let location = {params: {...params}};

  const CREATE_ROUTE_PARAM_NEW = 'new';

  // add to this list as you need it
  if (resourceType === RESOURCE_TYPES.ENVIRONMENTS) {
    location.name = ROUTE_NAMES.EDIT_ENVIRONMENT;
    location.params.environment = CREATE_ROUTE_PARAM_NEW;
  } else if (resourceType === RESOURCE_TYPES.SECRETS) {
    location.name = ROUTE_NAMES.EDIT_SECRET;
    location.params.secret = CREATE_ROUTE_PARAM_NEW;
  }

  if (!location.name) {
    console.warn(`resourceType "${resourceType}" was unknown for creating a location link to a "new" resource.`);
  }

  return location;
}

export function getResourceInfoById(resourceId, params = {}) {
  if (!resourceId) { return; }

  params = {...params}; // copy so we don't run into immutable concerns
  let endpointNames = {};
  let type = null;
  let typeSingular = null;
  let typeForDisplay = null;
  let typeForDisplaySingular = null;
  let routeName = null;
  let location = null;

  if (resourceId.substring(0, 2) === 'CO') {
    endpointNames['get'] = 'getCompany';
    type = 'companies';
    typeSingular = 'company';
    typeForDisplay = 'companies';
    typeForDisplaySingular = 'company';
    routeName = 'company';
    params['company'] = resourceId;
  } else if (resourceId.substring(0, 2) === 'PR') {
    endpointNames['get'] = 'getProperty';
    type = 'properties';
    typeSingular = 'property';
    typeForDisplay = 'properties';
    typeForDisplaySingular = 'property';
    routeName = 'propertyOverview';
    params['property'] = resourceId;
  } else if (resourceId.substring(0, 2) === 'RL') {
    endpointNames['get'] = 'getRule';
    type = 'rules';
    typeSingular = 'rule';
    typeForDisplay = 'rules';
    typeForDisplaySingular = 'rule';
    routeName = 'editRule';
    params['rule'] = resourceId;
  } else if (resourceId.substring(0, 2) === 'RC') {
    endpointNames['get'] = 'getRuleComponent';
    type = 'ruleComponents';
    typeSingular = 'ruleComponent';
    typeForDisplay = 'rule components';
    typeForDisplaySingular = 'rule component';
    routeName = 'editRuleComponent';
    params['ruleComponent'] = resourceId;
  } else if (resourceId.substring(0, 2) === 'DE') {
    endpointNames['get'] = 'getDataElement';
    type = 'dataElements';
    typeSingular = 'dataElement';
    typeForDisplay = 'data elements';
    typeForDisplaySingular = 'data element';
    routeName = 'editDataElement';
    params['dataElement'] = resourceId;
  } else if (resourceId.substring(0, 2) === 'EP') {
    endpointNames['get'] = 'getExtensionPackage';
    type = 'extensionPackages';
    typeSingular = 'extensionPackage';
    typeForDisplay = 'extension packages';
    typeForDisplaySingular = 'extension package';
    routeName = 'installExtension';
    params['extensionPackage'] = resourceId;
  } else if (resourceId.substring(0, 2) === 'EX') {
    endpointNames['get'] = 'getExtension';
    type = 'extensions';
    typeSingular = 'extension';
    typeForDisplay = 'extensions';
    typeForDisplaySingular = 'extension';
    routeName = 'editExtension';
    params['extension'] = resourceId;
  } else if (resourceId.substring(0, 2) === 'EN') {
    endpointNames['get'] = 'getEnvironment';
    type = 'environments';
    typeSingular = 'environment';
    typeForDisplay = 'environments';
    typeForDisplaySingular = 'environment';
    routeName = 'editEnvironment';
    params['environment'] = resourceId;
  } else if (resourceId.substring(0, 2) === 'HT') {
    endpointNames['get'] = 'getHost';
    type = 'hosts';
    typeSingular = 'host';
    typeForDisplay = 'hosts';
    typeForDisplaySingular = 'host';
    routeName = 'editHost';
    params['host'] = resourceId;
  } else if (resourceId.substring(0, 2) === 'SE') {
    endpointNames['get'] = 'getSecret';
    type = 'secrets';
    typeSingular = 'secret';
    typeForDisplay = 'secrets';
    typeForDisplaySingular = 'secret';
    routeName = 'editSecret';
    params['secret'] = resourceId;
  } else if (resourceId.substring(0, 2) === 'LB') {
    endpointNames['get'] = 'getLibrary';
    type = 'libraries';
    typeSingular = 'library';
    typeForDisplay = 'libraries';
    typeForDisplaySingular = 'library';
    routeName = 'editLibrary';
    params['library'] = resourceId;
  } else if (resourceId.substring(0, 2) === 'BL') {
    endpointNames['get'] = 'getBuild';
    type = 'builds';
    typeSingular = 'build';
    typeForDisplay = 'builds';
    typeForDisplaySingular = 'build';
    routeName = 'publishing';
    params['build'] = resourceId;
  } else if (resourceId.substring(0, 2) === 'NT') {
    endpointNames['get'] = 'getNote';
    type = 'notes';
    typeSingular = 'note';
    typeForDisplay = 'notes';
    typeForDisplaySingular = 'note';
    params['note'] = resourceId;
  }

  if (routeName) {
    location = { name: routeName, params };
  }

  return {
    endpointNames,
    type,
    typeSingular,
    typeForDisplay,
    typeForDisplaySingular,
    params,
    location
  };
}

export function getSingularResourceTypeForDisplayFromId(resourceId) {
  const resourceInfo = getResourceInfoById(resourceId);
  return resourceInfo?.typeForDisplaySingular;
}

export function getResourceTypeForDisplayFromId(resourceId) {
  const resourceInfo = getResourceInfoById(resourceId);
  return resourceInfo?.typeForDisplay;
}

export function getSingularResourceTypeFromId(resourceId) {
  const resourceInfo = getResourceInfoById(resourceId);
  return resourceInfo?.typeSingular;
};

export function getResourceTypeFromId(resourceId) {
  const resourceInfo = getResourceInfoById(resourceId);
  return resourceInfo?.type;
};

export function getApiMappingFromId(resourceId) {
  const resourceInfo = getResourceInfoById(resourceId);
  return resourceInfo?.endpointNames?.get;
};

// optional params must contain 'company' and 'property' for resources
// that are only found inside a propety
export function getResourceLocation(resourceId, params) {
  const resourceInfo = getResourceInfoById(resourceId, params);
  return resourceInfo?.location;
};

export function getAttributeDiffs(resourceA, resourceB) {
  if (!resourceA || !resourceB) {
    return [];
  }
  let attributesThatAreTheSame = (
    Object.keys(resourceA.attributes).reduce((accumulator, key)=>{
      // storageDuration on dataElements has some special logic when values are null
      // this is because the select component does not play nice with the null value
      if (
        key === 'storageDuration' &&
        (
          resourceA.attributes[key] === STORAGE_DURATION_NONE ||
          resourceA.attributes[key] === null
        ) && (
          resourceB.attributes[key] === STORAGE_DURATION_NONE ||
          resourceB.attributes[key] === null
        )
      ) {
        accumulator.push(key);
      }
      if (resourceA.attributes[key] === resourceB.attributes[key]) {
        accumulator.push(key);
      }
      return accumulator;
    },[])
  );
  (
    resourceA.relationships.extension?.data?.id ===
    resourceB.relationships.extension?.data?.id
  ) && attributesThatAreTheSame.push('extension');
  (
    resourceA.relationships.updatedWithExtension?.data?.id ===
    resourceB.relationships.updatedWithExtension?.data?.id
  ) && attributesThatAreTheSame.push('updatedWithExtension');
  return attributesThatAreTheSame;
}

// this can create single or multiple relationships
export function buildRelationships(relationshipIds) {
  if (Array.isArray(relationshipIds)) {
    return {
      data: relationshipIds.map((relationshipId)=>{
        return {
          id: relationshipId,
          type: getResourceTypeFromId(relationshipId)
        };
      })
    };
  }

  return {
    data: {
      id: relationshipIds,
      type: getResourceTypeFromId(relationshipIds)
    }
  };
}

export const getResourcePreparedForSave = (
  resource,
  {
    attributes: attributeOverrides = {},
    relationships: relationshipsOverrides = {}
  } = {}
) => {

  return resource
  .without('id')
  .update('attributes', (attributes)=>{
    return Object.keys(attributeOverrides).reduce((updatedAttributes, attributeKey)=>{
      return updatedAttributes.set(attributeKey, attributeOverrides[attributeKey]);
    }, attributes).without(
      'revisionNumber'
    );
  })
  .update('relationships', (relationships) => {
    // if an extension was not passed in, then we'll use the one already present
    // on the resource

    return Object.keys(relationshipsOverrides).reduce((updatedRelationships, relationshipKey)=>{
      return updatedRelationships.set(relationshipKey, relationshipsOverrides[relationshipKey]);
    }, relationships).without(
      // this should be cleaned up when its removed from the api
      'updatedWithExtensionPackage'
    );

  });
};

// only retains minimal info about a resource (id, type, name, and displayName)
export const getMinimalResources = (
  resources = [],
  finalUpdater
) => {
  if (!resources) { return resources; }

  // ensure resources is an array which allows single items to be passed as well
  resources = Array.isArray(resources) ? resources : [resources];
  return resources.map(resource=>{
    let minimalResource = {
      id: resource.id,
      type: resource.type,
      attributes: {
        name: resource.attributes.name
      }
    };

    // only extensions and extensionPackages have the displayName attribute
    if (resource.attributes.displayName) {
      minimalResource.attributes.displayName = resource.attributes.displayName;
    }

    if (finalUpdater) {
      minimalResource = finalUpdater(resource, minimalResource);
    }

    return minimalResource;
  });
};

// returns ruleComponents grouped by actions to take to get to the desired finalRuleComponents state
// this function assumes that both ruleComponent lists are from different revisions of the same rule
export const getRuleComponentDiffActions = (
  initialRuleComponents,
  finalRuleComponents
) => {
  // actions to take to get to the finalRuleComponents state
  let actionGroups = {
    toDelete: [],
    toUpdate: [], // {initialRuleComponent, finalRuleComponent}
    toCreate: []
  };

  // compare rule components so we know which to delete, update and add
  initialRuleComponents.forEach((initialRuleComponent)=>{
    const finalRuleComponentMatch = finalRuleComponents.find((_finalRuleComponent)=>{
      return getResourceOriginId(_finalRuleComponent) === getResourceOriginId(initialRuleComponent);
    });

    if (!finalRuleComponentMatch) {
      // remove it
      actionGroups.toDelete.push(initialRuleComponent);
    } else {
      // update it
      actionGroups.toUpdate.push({initialRuleComponent, finalRuleComponent: finalRuleComponentMatch});
    }
  });
  finalRuleComponents.forEach((finalRuleComponent)=>{
    const initialRuleComponentMatch = initialRuleComponents.find((_initialRuleComponent)=>{
      return getResourceOriginId(_initialRuleComponent) === getResourceOriginId(finalRuleComponent);
    });

    if (!initialRuleComponentMatch) {
      // add it
      actionGroups.toCreate.push(finalRuleComponent);
    }
  });

  return actionGroups;
};

export const getRevisionName = (resource) => {
  if (!resource) { return null; }

  let revisionName = '';
  const revisionNumber = resource?.attributes.revisionNumber;
  const revisionMatchesLatest = isResourceLatestAndUnchanged(resource);
  if (revisionNumber === 0) {
    revisionName = 'Latest';
  } else if (typeof revisionNumber === 'number') {
    revisionName = 'Revision ' + resource.attributes.revisionNumber + (
      revisionMatchesLatest ? ' (Latest)' : ''
    );
  }

  return revisionName;
};
