/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/


import Immutable from 'seamless-immutable';
import pQueue from 'p-queue';
import {
  getApiData,
  getApiMappingName
} from '../../../utils/api/apiTools';
import {CONTAINS, EQ} from '../../../utils/sortFilterQueryParamsUtils';
import {RULES, EXTENSIONS, DATA_ELEMENTS} from '../../../utils/api/apiTypes';
import {actionCreators as apiActions} from '../../../utils/api/apiActions';
import {capitalizeFirstLetter} from '../../exc-sdk-local-shell/utils/genericUtils';
import {
  deriveCopyName,
  getMinimalResources,
  getResourceNameInfo,
} from '../../../utils/resourceUtils';
import {
  getIsAllResourcesCompletedFromState,
  getPropertyCopyFromState,
  getCompanyHasChangedFromState
} from './PropertyCopySelectors';
import {getCurrentRouteParamsFromState, getCurrentRouteFromState} from '../../../routes/routeSelectors';
import {actionCreators as propertyEditActions} from '../edit/propertyEditActions';
import {push} from '../../../routes/namedRouteUtils';
import {actionCreators as resourceCopyActions} from '../../resourceCopy/resourceCopyActions';
import {actionCreators as toastsActions} from '../../higherOrderComponents/toastsActions';
import actionsHandler from '../../../redux/actionsHandler';
import {
  PROCESS_LOCKED,
  PROCESS_LOCK_TIMEOUT,
  refreshCurrentWindowProcessLock,
  isProcessLockedByAnotherWindow,
  removeCurrentWindowProcessLock,
  getCompanyScopedStateFromLocalStorage,
  saveCompanyScopedStateToLocalStorage,
  removeCompanyScopedStateFromLocalStorage
} from './propertyCopyLocalStorageUtils';
import { getActionCreators as getListActions } from '../../controlledList/controlledListActions';
import { getPaginationFromState } from '../../pagination/paginationSelectors';
import { ENDPOINT_GROUP_KEYS } from '../../../utils/api/apiMappingsUtils';
import { PROPERTY_LIST_STATE_KEY } from '../list/properties';

export const PROPERTY_COPY_NAME_CHECK_STATE_KEY = 'propertyDuplicateNameCheck';
export const COPY_PROCESS_TIMEOUT = 1000 * 60 * 60 * 24; // 24 hours
export const COPY_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  PREPARING: 'PREPARING',
  IN_PROGRESS: 'IN_PROGRESS',
  PAUSED: 'PAUSED',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED'
};
const COMPANY_CHANGED = 'COMPANY_CHANGED';
const OFFLINE = 'OFFLINE';


let abortController;
// swallowAbortErrors is used about 15 times in this file. It was easier to set the value
// 1 time for debugging purposes.
const swallowAbortErrors = false;
// the redux copyStatus variable is used for rendering but it
// can be updated by another window during restore from localStorage
// so currentWindowPaused is used for this window only
let currentWindowPaused = false;
const defaultPageSize = 9;
let offlineToastId = null;
let processLockedToastId = null;

export const SET_DIALOG_OPEN = 'propertyCopy/SET_DIALOG_OPEN';
export const INITIALIZE = 'propertyCopy/INITIALIZE';
export const SET_NEW_PROPERTY_NAME = 'propertyCopy/SET_NEW_PROPERTY_NAME';
export const SET_NEW_PROPERTY_NAME_FIELD_TOUCHED = 'propertyCopy/SET_NEW_PROPERTY_NAME_FIELD_TOUCHED';
export const SET_NEW_PROPERTY_NAME_VALID = 'propertyCopy/SET_NEW_PROPERTY_NAME_VALID';
export const SET_COPY_STATUS = 'propertyCopy/SET_COPY_STATUS';
export const SET_COMPANY = 'propertyCopy/SET_COMPANY';
export const SET_SOURCE_PROPERTY = 'propertyCopy/SET_SOURCE_PROPERTY';
export const SET_SOURCE_PROPERTY_EXTENSIONS = 'propertyCopy/SET_SOURCE_PROPERTY_EXTENSIONS';
export const SET_DESTINATION_PROPERTY = 'propertyCopy/SET_DESTINATION_PROPERTY';
export const SET_DESTINATION_PROPERTY_EXTENSIONS = 'propertyCopy/SET_DESTINATION_PROPERTY_EXTENSIONS';
export const SET_RESOURCES_META = 'propertyCopy/SET_RESOURCES_META';
export const SET_CURRENT_RESOURCETYPE = 'propertyCopy/SET_CURRENT_RESOURCETYPE';
export const SET_CURRENT_PAGE = 'propertyCopy/SET_CURRENT_PAGE';
export const SET_RESOURCES_DATA = 'propertyCopy/SET_RESOURCES_DATA';
export const SET_COPIED_RESOURCES_DATA = 'propertyCopy/SET_COPIED_RESOURCES_DATA';
export const SET_FAILED_RESOURCES_DATA = 'propertyCopy/SET_FAILED_RESOURCES_DATA';


const defaultState = Immutable({
  dialogOpen: false,
  newPropertyName: '',
  newPropertyNameFieldTouched: false,
  newPropertyNameValid: false,
  copyStatus: COPY_STATUS.NOT_STARTED,
  company: null,
  sourceProperty: null,
  destinationProperty: null,
  sourcePropertyExtensions: [],
  destinationPropertyExtensions: [],
  resourcesMeta: {
    [EXTENSIONS]: null,
    [RULES]: null,
    [DATA_ELEMENTS]: null
  },
  currentResourceType: null,
  currentPage: null,
  resourcesData: [],
  copiedResourcesData: [],
  failedResourcesData: []
});

// Reducers
export default actionsHandler({
  [SET_DIALOG_OPEN]: (state, {payload: {dialogOpen}}) => {
    return state.merge({dialogOpen});
  },
  [INITIALIZE]: (state, {payload: {restoredState}}) => {
    // only restore localStorage to state if the copy was in progress
    if (
      restoredState?.copyStatus === COPY_STATUS.PAUSED ||
      restoredState?.copyStatus === COPY_STATUS.PREPARING ||
      restoredState?.copyStatus === COPY_STATUS.IN_PROGRESS
    ) {
      // we extend defaultState with the restoredState because
      // not ALL of state was stored to localStorage
      return {...defaultState, ...restoredState};
    } else {
      return {...defaultState};
    }
  },
  [SET_NEW_PROPERTY_NAME]: (state, {payload: {newPropertyName}}) => {
    return state.merge({newPropertyName});
  },
  [SET_NEW_PROPERTY_NAME_FIELD_TOUCHED]: (state, {payload: {newPropertyNameFieldTouched}}) => {
    return state.merge({newPropertyNameFieldTouched});
  },
  [SET_NEW_PROPERTY_NAME_VALID]: (state, {payload: {newPropertyNameValid}}) => {
    return state.merge({newPropertyNameValid});
  },
  [SET_COPY_STATUS]: (state, {payload: {copyStatus}}) => {
    return state.merge({copyStatus});
  },
  [SET_COMPANY]: (state, {payload: {company}}) => {
    return state.merge({company});
  },
  [SET_SOURCE_PROPERTY]: (state, {payload: {sourceProperty}}) => {
    return state.merge({sourceProperty});
  },
  [SET_SOURCE_PROPERTY_EXTENSIONS]: (state, {payload: {sourcePropertyExtensions}}) => {
    return state.merge({sourcePropertyExtensions});
  },
  [SET_DESTINATION_PROPERTY]: (state, {payload: {destinationProperty}}) => {
    return state.merge({destinationProperty});
  },
  [SET_DESTINATION_PROPERTY_EXTENSIONS]: (state, {payload: {destinationPropertyExtensions}}) => {
    return state.merge({destinationPropertyExtensions});
  },
  [SET_RESOURCES_META]: (state, {payload: {resourceType, meta}}) => {
    return state.setIn(['resourcesMeta', resourceType], meta);
  },
  [SET_CURRENT_RESOURCETYPE]: (state, {payload: {currentResourceType}}) => {
    return state.merge({currentResourceType});
  },
  [SET_CURRENT_PAGE]: (state, {payload: {currentPage}}) => {
    return state.merge({currentPage});
  },
  [SET_RESOURCES_DATA]: (state, {payload: {resourcesData}}) => {
    return state.merge({resourcesData});
  },
  [SET_COPIED_RESOURCES_DATA]: (state, {payload: {copiedResourcesData}}) => {
    return state.merge({copiedResourcesData});
  },
  [SET_FAILED_RESOURCES_DATA]: (state, {payload: {failedResourcesData}}) => {
    return state.merge({failedResourcesData});
  },
  default: (state = defaultState) => {
    return state;
  }
});


// Action Creators
export let actionCreators = {
  initAbortController() {
    return () => { abortController = new AbortController(); };
  },
  cancelRequests() {
    return () => { abortController?.abort(); };
  },
  // this function wraps other propertyCopy actionCreators to persist state to localStorage
  handleActionAndSaveStateToLocalStorage(action) {
    return (dispatch) => {
      const dispatchedResult = dispatch(action);
      if (dispatchedResult?.then) {
        return dispatchedResult.then((result)=>{
          dispatch(actionCreators.minimizeResourcesAndPersistToLocalStorage());
          return result;
        });
      } else {
        dispatch(actionCreators.minimizeResourcesAndPersistToLocalStorage());
      }
    };
  },
  minimizeResourcesAndPersistToLocalStorage() {
    return (dispatch, getState) => {
      const params = getCurrentRouteParamsFromState(getState());
      const propertyCopyState = getPropertyCopyFromState(getState());

      // persisted data should contain the minimum detail required to restore copy progress
      // this is particularly important for large lists of resources
      const propertyCopyStateMinimized = propertyCopyState.merge({
        expires: new Date().getTime() + COPY_PROCESS_TIMEOUT, // a new expiration is set every time we update localStorage
        sourceProperty: propertyCopyState.sourceProperty,
        destinationProperty: propertyCopyState.destinationProperty,
        destinationPropertyExtensions: getMinimalResources(propertyCopyState.destinationPropertyExtensions),
        resourcesData: getMinimalResources(propertyCopyState.resourcesData, (resource, newResource)=>{
          return Immutable({...newResource, copyStarted: Boolean(resource.copyStarted)});
        }),
        copiedResourcesData: getMinimalResources(propertyCopyState.copiedResourcesData),
        failedResourcesData: getMinimalResources(propertyCopyState.failedResourcesData),
      });

      saveCompanyScopedStateToLocalStorage(params, propertyCopyStateMinimized);
    };
  },
  initialize({resetLocalStorage} = {}) {
    return (dispatch, getState) => {
      const params = getCurrentRouteParamsFromState(getState());
      let restoredState = Immutable(getCompanyScopedStateFromLocalStorage(params));
      const copyStatus = restoredState?.copyStatus;
      const isCopying = (
        copyStatus === COPY_STATUS.PREPARING ||
        copyStatus === COPY_STATUS.IN_PROGRESS ||
        copyStatus === COPY_STATUS.PAUSED
      );

      // Reset state if copying is not in progress or paused. This is important because
      // it is the only time that state gets completely reset after it has been updated
      // the first time.
      if (!isCopying) {
        restoredState = Immutable(defaultState);
      }
      // There's no need to save this to localStorage yet because if localStorage was
      // present, it was just restored from there. Regardless we'll update redux though.
      dispatch({
        type: INITIALIZE,
        payload: {restoredState}
      });

      // if window reloaded then we'll change to a paused state
      if (isCopying) {
        dispatch(actionCreators.pause());
      }

      // before the window unloads we want to cleanup it's lock
      // this doesn't work in all browsers and even in Chrome sometimes
      // it fails. As a fallback the lock has an expiration.
      const onBeforeUnload = () => { removeCurrentWindowProcessLock(params); };
      window.removeEventListener('beforeunload', onBeforeUnload); // ensure only 1 event listening
      window.addEventListener('beforeunload', onBeforeUnload);

      // when the user acknowledges copy completion via the "Close" or "Go to new property" buttons,
      // we'll cleanup localStorage. It will be recreated when the PropertyCopyDialog is opened again.
      if (resetLocalStorage) { removeCompanyScopedStateFromLocalStorage(params); }
    };
  },
  setDialogOpen(dialogOpen) {
    return actionCreators.handleActionAndSaveStateToLocalStorage({
      type: SET_DIALOG_OPEN,
      payload: {dialogOpen}
    });
  },
  setNewPropertyName(newPropertyName) {
    return actionCreators.handleActionAndSaveStateToLocalStorage({
      type: SET_NEW_PROPERTY_NAME,
      payload: {newPropertyName}
    });
  },
  setNewPropertyNameFieldTouched(newPropertyNameFieldTouched) {
    return actionCreators.handleActionAndSaveStateToLocalStorage({
      type: SET_NEW_PROPERTY_NAME_FIELD_TOUCHED,
      payload: {newPropertyNameFieldTouched}
    });
  },
  setNewPropertyNameValid(newPropertyNameValid) {
    return actionCreators.handleActionAndSaveStateToLocalStorage({
      type: SET_NEW_PROPERTY_NAME_VALID,
      payload: {newPropertyNameValid}
    });
  },
  getInitialNewPropertyName(sourceProperty) {
    return (dispatch, getState) => {
      const params = getCurrentRouteParamsFromState(getState());
      return dispatch(apiActions.apiAction({
        name: getApiMappingName(ENDPOINT_GROUP_KEYS.PROPERTIES, 'get'),
        urlData: {...params},
        urlParams: {
          // Optimally, we would use STARTS WITH to filter down to existing items that
          // start with our base names, but STARTS WITH doesn't exist at the moment.
          // CONTAINS still works, though we'll just be dealing with a bigger dataset.
          'filter[name]': CONTAINS + ' ' + getResourceNameInfo(
            sourceProperty.attributes.name
          ).baseName
        },
        stateKey: PROPERTY_COPY_NAME_CHECK_STATE_KEY,
        abortSignal: abortController?.signal,
        swallowAbortErrors
      })).then(()=>{
        const duplicationNameCheckState = getApiData(getState(), PROPERTY_COPY_NAME_CHECK_STATE_KEY);
        const reservedNameProperties = duplicationNameCheckState?.properties || [];
        const reservedNames = reservedNameProperties.map(existingProperty => existingProperty.attributes.name);
        return deriveCopyName(sourceProperty.attributes.name, reservedNames);
      }).catch(()=>{
        // we can swallow this error because getting the new property copy name is
        // nice-to-have functionality for the user but not required to continue
      });
    };
  },
  validateNewPropertyName({
    onIsValid = ()=>{}
  } = {}) {
    return (dispatch, getState) => {
      const propertyCopyState = getPropertyCopyFromState(getState());
      const {newPropertyName} = propertyCopyState;

      const params = getCurrentRouteParamsFromState(getState());
      return dispatch(apiActions.apiAction({
        name: getApiMappingName(ENDPOINT_GROUP_KEYS.PROPERTIES, 'get'),
        urlData: {...params},
        urlParams: {
          'filter[name]': EQ + ' ' + newPropertyName.trim()
        },
        stateKey: PROPERTY_COPY_NAME_CHECK_STATE_KEY,
        abortSignal: abortController?.signal,
        swallowAbortErrors
      })).then(()=>{
        const nameCheckState = getApiData(getState(), PROPERTY_COPY_NAME_CHECK_STATE_KEY);
        const propertiesOfSameName = nameCheckState?.properties || [];
        const isValid = propertiesOfSameName.length === 0;
        if (isValid) {
          dispatch(actionCreators.setNewPropertyNameValid(true));
          onIsValid('Valid property name');
        } else {
          dispatch(actionCreators.setNewPropertyNameValid(false));
          // Invalid property name so we will never resolve
        }
      });
    };
  },
  setCompany(company) {
    return actionCreators.handleActionAndSaveStateToLocalStorage({
      type: SET_COMPANY,
      payload: {company}
    });
  },
  setSourceProperty(sourceProperty) {
    return actionCreators.handleActionAndSaveStateToLocalStorage({
      type: SET_SOURCE_PROPERTY,
      payload: {sourceProperty}
    });
  },
  setSourcePropertyExtensions(sourcePropertyExtensions) {
    return actionCreators.handleActionAndSaveStateToLocalStorage({
      type: SET_SOURCE_PROPERTY_EXTENSIONS,
      payload: {sourcePropertyExtensions}
    });
  },
  setDestinationProperty(destinationProperty) {
    return actionCreators.handleActionAndSaveStateToLocalStorage({
      type: SET_DESTINATION_PROPERTY,
      payload: {destinationProperty}
    });
  },
  setDestinationPropertyExtensions(destinationPropertyExtensions) {
    return actionCreators.handleActionAndSaveStateToLocalStorage({
      type: SET_DESTINATION_PROPERTY_EXTENSIONS,
      payload: {destinationPropertyExtensions}
    });
  },
  setCopyStatus(copyStatus) {
    return actionCreators.handleActionAndSaveStateToLocalStorage({
      type: SET_COPY_STATUS,
      payload: {copyStatus}
    });
  },
  setResourcesMeta(resourceType, meta) {
    return actionCreators.handleActionAndSaveStateToLocalStorage({
      type: SET_RESOURCES_META,
      payload: {resourceType, meta}
    });
  },
  setCurrentResourceType(currentResourceType) {
    return actionCreators.handleActionAndSaveStateToLocalStorage({
      type: SET_CURRENT_RESOURCETYPE,
      payload: {currentResourceType}
    });
  },
  setCurrentPage(currentPage) {
    return actionCreators.handleActionAndSaveStateToLocalStorage({
      type: SET_CURRENT_PAGE,
      payload: {currentPage}
    });
  },
  setResourcesData(resourcesData) {
    return actionCreators.handleActionAndSaveStateToLocalStorage({
      type: SET_RESOURCES_DATA,
      payload: {resourcesData}
    });
  },
  setCopiedResourcesData(copiedResourcesData) {
    return actionCreators.handleActionAndSaveStateToLocalStorage({
      type: SET_COPIED_RESOURCES_DATA,
      payload: {copiedResourcesData}
    });
  },
  setFailedResourcesData(failedResourcesData) {
    return actionCreators.handleActionAndSaveStateToLocalStorage({
      type: SET_FAILED_RESOURCES_DATA,
      payload: {failedResourcesData}
    });
  },
  showProcessLockedToast() {
    return (dispatch) => {
      processLockedToastId = dispatch(toastsActions.addToast({
        timeout: PROCESS_LOCK_TIMEOUT,
        variant: 'warning',
        message: 'Copying is locked by another window. Try again in a moment.'
      }));
    };
  },
  copyProperty(company, sourceProperty, isRetrying) {
    return (dispatch, getState) => {
      let params = getCurrentRouteParamsFromState(getState());
      params = { ...params, property: sourceProperty.id };

      // if the company has changed, we cannot continue because localStorage state is
      // scoped to the company
      const companyHasChanged = dispatch(actionCreators.pauseIfCompanyHasChanged());
      if (companyHasChanged) {
        return Promise.resolve({message: 'Company has changed.', type: COMPANY_CHANGED});
      }

      // check if the copy process is locked by another window
      if (isProcessLockedByAnotherWindow(params)) {
        dispatch(actionCreators.showProcessLockedToast());
        return Promise.resolve({message: 'Copy process locked by another window.', type: PROCESS_LOCKED});
      } else {
        // make this the locking window
        refreshCurrentWindowProcessLock(params);
      }

      // if the copy is paused then we'll wait until the user manually resumes or it auto resumes
      const {copyStatus} = getPropertyCopyFromState(getState());
      if (copyStatus === COPY_STATUS.PAUSED) {
        return Promise.resolve({message: 'Copy paused.'});
      }

      dispatch(actionCreators.setCompany(company));
      dispatch(actionCreators.setSourceProperty(sourceProperty));

      // if resuming we'll leave the copy status set as IN_PROGRESS.
      // otherwise, we don't have resources yet, so we'll go to the
      // PREPARING state
      if (copyStatus !== COPY_STATUS.IN_PROGRESS) {
        dispatch(actionCreators.setCopyStatus(COPY_STATUS.PREPARING));
      }

      // load current page of all resources so we can calculate progress and keep track of paging
      return dispatch(
        actionCreators.loadFirstPageOfAllResourcesWithMeta()
      ).then((firstPageAllResourcesWithMeta) => {
        // update the meta for all resource types so that we can use it to calculate overall progress
        dispatch(actionCreators.setResourcesMeta(EXTENSIONS, firstPageAllResourcesWithMeta[0].meta));
        dispatch(actionCreators.setResourcesMeta(RULES, firstPageAllResourcesWithMeta[1].meta));
        dispatch(actionCreators.setResourcesMeta(DATA_ELEMENTS, firstPageAllResourcesWithMeta[2].meta));
        dispatch(actionCreators.setCopyStatus(COPY_STATUS.IN_PROGRESS));

        const propertyCopyState = getPropertyCopyFromState(getState());
        const existingDestinationProperty = propertyCopyState.destinationProperty;

        // create the new destination property if it doesn't already exist
        const createOrUpdatePropertyPromise = existingDestinationProperty ? Promise.resolve(
          existingDestinationProperty
        ) : dispatch(
          // copy the property with settings
          propertyEditActions.createProperty(params, {
            id: sourceProperty.id,
            type: sourceProperty.type,
            attributes: {
              ...sourceProperty.attributes.without([
                'name',
                'createdAt',
                'updatedAt',
                'createdByEmail',
                'createdByDisplayName',
                'updatedByEmail',
                'updatedByDisplayName',
                'enabled',
                'token'
              ]),
              name: propertyCopyState.newPropertyName.trim()
            }
          }, false, abortController)
        ).then(()=>{
          const newDestinationProperty = getState().api?.property;
          dispatch(actionCreators.setNewPropertyName(newDestinationProperty.attributes.name));
          dispatch(actionCreators.setDestinationProperty(newDestinationProperty));
          return newDestinationProperty;
        });

        return createOrUpdatePropertyPromise.then(async() => {
          // we copy resource types in order - extensions, data elements, then rules.
          // this allows us to pick up where we left off by skipping the resource type
          // if it has already completed.
          let anyFailures = false;
          const currentResourceType = propertyCopyState.currentResourceType || EXTENSIONS;
          if (
            window.navigator?.onLine &&
            currentResourceType === EXTENSIONS
          ) {
            try {
              await dispatch(actionCreators.setResourceTypeAndBeginCopy(params, EXTENSIONS, isRetrying));
            } catch (error) {
              anyFailures = true;
            }
          }
          if (
            window.navigator?.onLine && !anyFailures &&
            (
              currentResourceType === EXTENSIONS ||
              currentResourceType === DATA_ELEMENTS
            )
          ) {
            try {
              await dispatch(actionCreators.setResourceTypeAndBeginCopy(params, DATA_ELEMENTS, isRetrying));
            } catch (error) {
              anyFailures = true;
            }
          }
          if (
            window.navigator?.onLine && !anyFailures &&
            (
              currentResourceType === EXTENSIONS ||
              currentResourceType === DATA_ELEMENTS ||
              currentResourceType === RULES
            )
          ) {
            try {
              await dispatch(actionCreators.setResourceTypeAndBeginCopy(params, RULES, isRetrying));
            } catch (error) {
              anyFailures = true;
            }
          }

          // close the dialog any time the process completes (even if the state is paused)
          dispatch(actionCreators.setDialogOpen(false));

          const finalCopyStatus = getPropertyCopyFromState(getState()).copyStatus;

          // toast the user on the current status
          const allResourcesComplete = getIsAllResourcesCompletedFromState(getState());
          if (allResourcesComplete && finalCopyStatus !== COPY_STATUS.CANCELLED) {
            removeCurrentWindowProcessLock(params);
            dispatch(toastsActions.addToast({
              timeout: 7000,
              variant: 'info',
              message: 'Property copy has completed.'
            }));
            dispatch(actionCreators.setCopyStatus(COPY_STATUS.COMPLETED));
          } else if (!window.navigator?.onLine) {
            offlineToastId = dispatch(toastsActions.addToast({
              variant: 'warning',
              message: 'You appear to be offline. Property copy has been paused.'
            }));
          }

          // we'll try/catch the list reload in case the user went offline
          try {
            await dispatch(actionCreators.reloadPropertiesList());
          } catch (error) {
            dispatch(actionCreators.pauseIfOffline());
          }

          // cleanup
          if (finalCopyStatus === COPY_STATUS.CANCELLED) {
            removeCurrentWindowProcessLock(params);
            removeCompanyScopedStateFromLocalStorage(params);
          }

          return Promise.resolve({message: 'Copy completed'});
        });
      }).catch(()=>{
        dispatch(actionCreators.pauseIfOffline());
      });
    };
  },
  setResourceTypeAndBeginCopy(sourcePropertyParams, resourceType, isRetrying) {
    return (dispatch, getState) => {
      const propertyCopyState = getPropertyCopyFromState(getState());
      const destinationPropertyParams = {...sourcePropertyParams, property: propertyCopyState.destinationProperty.id};
      dispatch(actionCreators.setCurrentResourceType(resourceType));
      let currentPage = isRetrying ? 1 : (propertyCopyState.currentPage || 1);
      dispatch(actionCreators.setCurrentPage(currentPage));
      return dispatch(actionCreators.copyResourcesOfCurrentType({
        sourcePropertyParams, destinationPropertyParams, isRetrying
      }));
    };
  },
  // this is a recursive/paging function
  copyResourcesOfCurrentType({sourcePropertyParams, destinationPropertyParams, isRetrying, onResourcesComplete} = {}) {
    return async(dispatch, getState) => {
      const propertyCopyState = getPropertyCopyFromState(getState());
      const {
        resourcesData,
        failedResourcesData,
        copiedResourcesData,
        currentResourceType,
        currentPage,
        sourcePropertyExtensions,
        destinationPropertyExtensions
      } = propertyCopyState;

      // if retrying, we'll load only the failed resources of the current resource type
      // otherwise, we'll load the current page of the current resource type
      let loadingResourcesPromise;
      if (isRetrying) {
        const failedResourcesOfCurrentType = failedResourcesData.filter(
          _resource=>_resource.type === currentResourceType
        );

        if (!failedResourcesOfCurrentType.length) {
          return Promise.resolve({message: 'No failed resources of current type'});
        }

        let failedResourcePromises = [];
        failedResourcesOfCurrentType.forEach((resource)=>{
          const existingCopiedResource = copiedResourcesData.find(_resource=>_resource.id === resource.id);
          if (existingCopiedResource) {
            // if it actually succeeded then we'll just remove it from the failed items list
            const newFailedResourcesData = failedResourcesData.filter((failedResource)=>{
              return failedResource.id !== resource.id;
            });
            dispatch(actionCreators.setFailedResourcesData(newFailedResourcesData));
          } else {
            // if it hasn't yet succeeded then we'll load it
            failedResourcePromises.push(dispatch(resourceCopyActions.loadResource({
              resourceId: resource.id,
              params: sourcePropertyParams,
              bypassError: true,
              abortSignal: abortController?.signal,
              swallowAbortErrors
            })));
          }
        });

        loadingResourcesPromise = Promise.all(failedResourcePromises);
      } else {
        loadingResourcesPromise = dispatch(actionCreators.loadResourcesWithMeta({
          resourceType: currentResourceType,
          params: sourcePropertyParams,
          urlParams: {
            'page[size]': defaultPageSize,
            'page[number]': currentPage
          },
          bypassError: true,
          abortSignal: abortController?.signal,
          swallowAbortErrors
        })).then(
          resourcesWithMeta=>resourcesWithMeta.resources
        );
      }

      // get current page of resources
      return loadingResourcesPromise.then(async(resourcesWithMetaCurrentPage) => {
        // use the loaded resources to fully rehydrate resources restored from localStorage
        const hydratedResourcesData = [...resourcesData].map(_resource=>{
          const loadedResourceMatch = resourcesWithMetaCurrentPage.find(
            existingResource=>existingResource.id === _resource.id
          );

          // ensure the copyStarted attribute is present
          const copyStarted = Boolean(_resource.copyStarted);
          return loadedResourceMatch ? Immutable({
            ...loadedResourceMatch,
            copyStarted
          }) : Immutable({
            ..._resource,
            copyStarted
          });
        });

        // some loaded resources may be not be in the list that was restored
        // from localStorage. We'll add them to the end of the list if they are
        // not already queued, in-progress or completed.
        let combinedResources = [...hydratedResourcesData];
        resourcesWithMetaCurrentPage.forEach(resource=>{
          // already queued or in-progress
          const existingResource = resourcesData.find(
            existingResource=>existingResource.id === resource.id
          );
          // already copied
          const existingCopiedResource = copiedResourcesData.find(
            existingResource=>existingResource.id === resource.id
          );
          if (!existingResource && !existingCopiedResource) {
            combinedResources.push(Immutable({
              ...resource,
              copyStarted: false
            }));
          }
        });

        // now that all resources are hydrated and populated, update the store
        dispatch(actionCreators.setResourcesData(combinedResources));

        // if we restored from localStorage, we need to repopulate our temporary extensions
        // so that we don't have to load the required extension every time we copy a resource
        let latestSourcePropertyExtensions = [];
        if (currentResourceType === DATA_ELEMENTS || currentResourceType === RULES) {
          if (!sourcePropertyExtensions.length) {
            await dispatch(resourceCopyActions.loadExtensions({
              params: sourcePropertyParams,
              bypassError: true,
              abortSignal: abortController?.signal,
              swallowAbortErrors
            })).then(extensions=>{
              latestSourcePropertyExtensions = extensions;
              return dispatch(actionCreators.setSourcePropertyExtensions(extensions));
            }).catch(()=>{
              dispatch(actionCreators.pauseIfOffline());
            });
          } else {
            latestSourcePropertyExtensions = sourcePropertyExtensions;
          }
          if (!destinationPropertyExtensions.length) {
            await dispatch(resourceCopyActions.loadExtensions({
              params: destinationPropertyParams,
              bypassError: true,
              abortSignal: abortController?.signal,
              swallowAbortErrors
            })).then(extensions=>{
              return dispatch(actionCreators.setDestinationPropertyExtensions(extensions));
            }).catch(()=>{
              dispatch(actionCreators.pauseIfOffline());
            });;
          }
        }

        // continue copying using only the current resource type
        const resourcesOfCurrentTypeCurrentPage = combinedResources.filter(resource=>{
          const resourceInCurrentPage = resourcesWithMetaCurrentPage.find(
            _resource=>_resource.id === resource.id
          );
          return resource.type === currentResourceType && resourceInCurrentPage;
        });

        // If the current page of resources have all been copied, then proceed to next page.
        // This is an optimization to prevent unneeded loading when resuming the copy process
        // on a large property.
        const allResourcesOfCurrentTypeCurrentPageCopied = resourcesOfCurrentTypeCurrentPage.every(resource=>{
          return Boolean(copiedResourcesData.find(copiedResource=>copiedResource.id === resource.id));
        });
        if (allResourcesOfCurrentTypeCurrentPageCopied) {
          return dispatch(actionCreators.copyNextPageOrComplete({
            sourcePropertyParams,
            destinationPropertyParams,
            isRetrying,
            onResourcesComplete
          }));
        }

        // extend resources so we'll know about required extensions and ruleComponents
        return dispatch(resourceCopyActions.getExtendedResourcesData({
          params: sourcePropertyParams,
          resources: resourcesOfCurrentTypeCurrentPage,
          extensions: latestSourcePropertyExtensions,
          bypassError: true,
          abortSignal: abortController?.signal,
          swallowAbortErrors
        })).then(extendedResourcesData=>{
          // make all promises from loaded resources
          const resourcesCopyPromise = new Promise((currentPageResolve)=>{
            const resourcesCopyQueue = new pQueue({concurrency: 3});

            extendedResourcesData.forEach(resourceData => {
              const {resource} = resourceData;

              resourcesCopyQueue.add(()=>{
                const propertyCopyState = getPropertyCopyFromState(getState());

                // define success/failure handlers early because we'll need them at different places based on the
                // current copy status (copying/cancelled)
                const resourceSuccessHandler = (result)=>{
                  const {copiedResourcesData, failedResourcesData} = getPropertyCopyFromState(getState());
                  // if we are resuming, it's possible that a resource failed previously
                  // and has now succeeded, so we'll remove it from the failedResources list
                  const existingFailedResource = failedResourcesData.find(
                    existingResource=>existingResource.id === resource.id
                  );
                  if (existingFailedResource) {
                    const newFailedResourcesData = failedResourcesData.filter((failedResource)=>{
                      return failedResource.id !== resource.id;
                    });
                    dispatch(actionCreators.setFailedResourcesData(newFailedResourcesData));
                  }

                  // add to the succeeded list
                  const existingCopiedResource = copiedResourcesData.find(
                    existingResource=>existingResource.id === resource.id
                  );
                  if (!existingCopiedResource) {
                    const newCopiedResourcesData = copiedResourcesData.concat(resource);
                    dispatch(actionCreators.setCopiedResourcesData(newCopiedResourcesData));
                  }
                  return Promise.resolve(result);
                };
                const resourceErrorHandler = (error)=>{
                  const {failedResourcesData} = getPropertyCopyFromState(getState());
                  const existingFailedResourceIndex = failedResourcesData.findIndex(
                    existingResource=>existingResource.id === resource.id
                  );
                  const resourceWithError = {...resource, error: error};
                  const newFailedResourcesData = (
                    existingFailedResourceIndex >= 0 ?
                    failedResourcesData.set(existingFailedResourceIndex, resourceWithError) :
                    failedResourcesData.concat(resourceWithError)
                  );
                  dispatch(actionCreators.setFailedResourcesData(newFailedResourcesData));
                  dispatch(actionCreators.pauseIfOffline());
                  return Promise.resolve(error);
                };

                // if the company has changed, we cannot continue because localStorage state is
                // scoped to the company
                const companyHasChanged = dispatch(actionCreators.pauseIfCompanyHasChanged());
                if (companyHasChanged) {
                  return currentPageResolve({message: 'Company has changed.', type: COMPANY_CHANGED});
                }

                // before copying each resource, we'll check if the user has cancelled
                if (propertyCopyState.copyStatus === COPY_STATUS.CANCELLED) {
                  return currentPageResolve(
                    resourceErrorHandler({message: 'User cancelled'})
                  );
                }

                // before copying each resource, we'll check if we are online
                if (!window.navigator?.onLine) {
                  return currentPageResolve({message: 'Copy paused due to offline mode', type: OFFLINE});
                }

                // before copying each resource, we'll check if the copy process is locked by another window
                if (isProcessLockedByAnotherWindow(sourcePropertyParams)) {
                  return currentPageResolve({message: 'Copy process locked by another window.', type: PROCESS_LOCKED});
                } else {
                  // refresh the lock for this window
                  refreshCurrentWindowProcessLock(sourcePropertyParams);
                }

                // before copying each resource, we'll check if it has already been copied or started
                const {copiedResourcesData} = propertyCopyState;
                const existingCopiedResource = copiedResourcesData.find(
                  existingResource=>existingResource.id === resource.id
                );
                if (existingCopiedResource) {
                  return currentPageResolve(
                    resourceSuccessHandler({message: 'Already copied'})
                  );
                }

                // now that all checks have been done, update the copyStarted status for this resource
                let resourcesDataWithUpdatedCopyStatus = propertyCopyState.resourcesData.map(_resourceData=>{
                  const isCurrentResource = _resourceData.id === resource.id;
                  return (!isCurrentResource || _resourceData.copyStarted) ? _resourceData : _resourceData.set('copyStarted', true);
                });
                dispatch(actionCreators.setResourcesData(resourcesDataWithUpdatedCopyStatus));

                // copy each resource
                let currentResourceCopyPromise = Promise.resolve();
                if (resource.type === EXTENSIONS) {
                  currentResourceCopyPromise = dispatch(
                    resourceCopyActions.copyExtensionToDestinationProperty({
                      params: destinationPropertyParams,
                      extension: resource,
                      abortSignal: abortController?.signal,
                      swallowAbortErrors,
                      bypassError: true
                    })
                  );
                } else if (resource.type === DATA_ELEMENTS) {
                  const {destinationPropertyExtensions} = getPropertyCopyFromState(getState());
                  currentResourceCopyPromise = dispatch(
                    resourceCopyActions.copyDataElementToDestinationProperty({
                      sourcePropertyParams,
                      destinationPropertyParams,
                      destinationPropertyExtensions,
                      dataElementData: resourceData,
                      abortSignal: abortController?.signal,
                      swallowAbortErrors,
                      bypassError: true
                    })
                  );
                } else if (resource.type === RULES) {
                  const {destinationPropertyExtensions} = getPropertyCopyFromState(getState());
                  currentResourceCopyPromise = dispatch(
                    resourceCopyActions.copyRuleToDestinationProperty({
                      sourcePropertyParams,
                      destinationPropertyParams,
                      destinationPropertyExtensions,
                      ruleData: resourceData,
                      abortSignal: abortController?.signal,
                      swallowAbortErrors,
                      bypassError: true
                    })
                  );
                }

                // pQueue doesn't return promise results on completion so each
                // promise needs to handle it's own then() and catch() cases
                return currentResourceCopyPromise.then(resourceSuccessHandler).catch(resourceErrorHandler);
              });
            });

            return resourcesCopyQueue.onIdle().then(()=>{
              const onComplete = () => {
                // the order of these resolves is important when multiple pages exist
                currentPageResolve(); // resolve current page
                onResourcesComplete && onResourcesComplete(); // resolve previous page
              };

              // if cancelled or offline or then we won't continue
              const {copyStatus} = getPropertyCopyFromState(getState());
              if (copyStatus === COPY_STATUS.CANCELLED || !window.navigator?.onLine) {
                return onComplete();
              }

              return dispatch(actionCreators.copyNextPageOrComplete({
                sourcePropertyParams,
                destinationPropertyParams,
                isRetrying,
                onResourcesComplete: onComplete
              }));
            });
          });

          return resourcesCopyPromise;
        }).catch((error)=>{
          dispatch(actionCreators.pauseIfOffline());
          throw error;
        });
      }).catch((error)=>{
        dispatch(actionCreators.pauseIfOffline());
        throw error;
      });
    };
  },
  copyNextPageOrComplete({
    sourcePropertyParams,
    destinationPropertyParams,
    isRetrying,
    onResourcesComplete
  } = {}) {
    return (dispatch, getState) => {
      const propertyCopyState = getPropertyCopyFromState(getState());
      const {currentPage, resourcesMeta, currentResourceType} = propertyCopyState;
      // continue paging - but only if we are not retrying (as there will only be 1 page in that case)
      if (!isRetrying && currentPage < resourcesMeta[currentResourceType].pagination.totalPages) {
        // recurse with next page
        dispatch(actionCreators.setCurrentPage(currentPage + 1));
        return dispatch(
          actionCreators.copyResourcesOfCurrentType({
            sourcePropertyParams, destinationPropertyParams, isRetrying, onResourcesComplete
          })
        );
      } else {
        // all pages completed
        dispatch(actionCreators.setCurrentPage(1));
        return onResourcesComplete ? onResourcesComplete() : Promise.resolve();
      }
    };
  },
  pauseIfCompanyHasChanged() {
    return (dispatch, getState) => {
      const companyFromState = getPropertyCopyFromState(getState()).company;
      if (companyFromState && getCompanyHasChangedFromState(getState())) {
        dispatch(actionCreators.pause());
        return true;
      }
    };
  },
  pauseIfOffline() {
    return (dispatch) => {
      if (!window.navigator?.onLine) {
        dispatch(actionCreators.pause());
      }
    };
  },
  pause() {
    return (dispatch, getState) => {
      const propertyCopyState = getPropertyCopyFromState(getState());
      if (propertyCopyState.copyStatus !== COPY_STATUS.PAUSED) {
        dispatch(actionCreators.setCopyStatus(COPY_STATUS.PAUSED));
        dispatch(actionCreators.cancelRequests());

        // reset copyStarted status on all resources that haven't completed copying
        const resourcesDataCopyStartedReset = propertyCopyState.resourcesData.map(resourceData=>{
          const copyCompleted = propertyCopyState.copiedResourcesData.find(
            _resource=>_resource.id === resourceData.id
          );
          return copyCompleted ? resourceData : resourceData.set('copyStarted', false);
        });
        dispatch(actionCreators.setResourcesData(resourcesDataCopyStartedReset));
      }

      if (!currentWindowPaused) {
        // cross-window communication in 2019 is still way harder than it should be.
        // https://stackoverflow.com/questions/28230845/communication-between-tabs-or-windows
        // `BroadcastChannel` is not supported in IE or Safari yet.
        // The `storage` event is not yet supported in Chrome.
        // `postMessage` requires a reference to a shared window - only works within the same window hierarchy.
        // `cookies` are dumb.
        //
        // npm packages like crosstab and broadcast-channel rely on much hackery to accomplish this
        // (timeouts, cookies, localStorage, temporary controller windowIds, etc). They are generally
        // subject to delays which create race conditions.
        //
        // As a result of no other good options we will "watch" localStorage while paused
        currentWindowPaused = true; // we don't redux this because the redux state is shared between windows via localStorage
        dispatch(actionCreators.watchLocalStorageForUpdatesWhilePaused());
      }
    };
  },
  resume() {
    return (dispatch, getState) => {
      // reset our abortController in case you went offline
      dispatch(actionCreators.initAbortController());

      const params = getCurrentRouteParamsFromState(getState());

      // check if the copy process is locked by another window
      if (isProcessLockedByAnotherWindow(params)) {
        dispatch(actionCreators.showProcessLockedToast());
        return Promise.resolve({message: 'Copy process locked by another window.', type: PROCESS_LOCKED});
      } else {
        // make this the locking window
        refreshCurrentWindowProcessLock(params);
      }

      const propertyCopyState = getPropertyCopyFromState(getState());
      if (propertyCopyState.sourceProperty) {
        if (getIsAllResourcesCompletedFromState(getState())) {
          dispatch(actionCreators.setCopyStatus(COPY_STATUS.COMPLETED));
          currentWindowPaused = false; // we don't redux this because the redux state is shared between windows via localStorage
        } else if (propertyCopyState.copyStatus === COPY_STATUS.PAUSED) {
          dispatch(actionCreators.setCopyStatus(COPY_STATUS.IN_PROGRESS));
          currentWindowPaused = false; // we don't redux this because the redux state is shared between windows via localStorage
          dispatch(actionCreators.copyProperty(propertyCopyState.company, propertyCopyState.sourceProperty));
        }
      }
    };
  },
  retry() {
    return (dispatch, getState) => {
      const propertyCopyState = getPropertyCopyFromState(getState());
      dispatch(actionCreators.setCurrentResourceType(EXTENSIONS));
      dispatch(actionCreators.setCopyStatus(COPY_STATUS.IN_PROGRESS));
      dispatch(actionCreators.copyProperty(propertyCopyState.company, propertyCopyState.sourceProperty, true));
    };
  },
  cancelCopying(updateCopyStatus = true) {
    return (dispatch, getState) => {
      const params = getCurrentRouteParamsFromState(getState());
      if (updateCopyStatus) {
        dispatch(actionCreators.setCopyStatus(COPY_STATUS.CANCELLED));
      }
      dispatch(actionCreators.cancelRequests());
      removeCurrentWindowProcessLock(params);
      removeCompanyScopedStateFromLocalStorage(params);
    };
  },
  reloadPropertiesList() {
    return async(dispatch, getState) => {
      const currentRoute = getCurrentRouteFromState(getState());
      const paginationInfo = getPaginationFromState(PROPERTY_LIST_STATE_KEY, getState());
      // only reload the list if we are still on the `properties` route
      const propertiesListActions = getListActions(PROPERTY_LIST_STATE_KEY);
      return (
        currentRoute.name === 'properties' && paginationInfo?.isInitialized ?
        dispatch(propertiesListActions.loadList({bypassError: true})) :
        Promise.resolve()
      );
    };
  },
  watchLocalStorageForUpdatesWhilePaused() {
    return (dispatch, getState) => {
      clearTimeout(this.watcherTimeout);

      if (currentWindowPaused) {
        const params = getCurrentRouteParamsFromState(getState());
        const propertyCopyState = getPropertyCopyFromState(getState());
        let restoredState = Immutable(getCompanyScopedStateFromLocalStorage(params));

        // stop watching changes if cancelled or if the localStorage state was removed
        if (!restoredState || restoredState.copyStatus === COPY_STATUS.CANCELLED) {
          currentWindowPaused = false;
          dispatch(actionCreators.cancelCopying());
          dispatch(actionCreators.reloadPropertiesList());
          return;
        }

        // if the company has changed, we cannot continue because localStorage state is
        // scoped to the company
        const companyHasChanged = dispatch(actionCreators.pauseIfCompanyHasChanged());
        if (companyHasChanged) { return; }

        // if this window was offline but has come back online and is still the control window
        // then it should be safe to auto resume
        if (restoredState.copyStatus === COPY_STATUS.PAUSED) {
          if (window.navigator?.onLine && !isProcessLockedByAnotherWindow(params)) {
            dispatch(toastsActions.removeToast(offlineToastId));
            dispatch(toastsActions.removeToast(processLockedToastId));
            dispatch(actionCreators.resume());
            return;
          }
        }

        // if the copyStatus or the resources have changed then we expect that copying has resumed in another window
        // so we'll restore the latest state from localStorage periodically
        if (
          restoredState.copyStatus !== COPY_STATUS.PAUSED ||
          propertyCopyState.resourcesData.length !== restoredState.resourcesData.length ||
          propertyCopyState.copiedResourcesData.length !== restoredState.copiedResourcesData.length ||
          propertyCopyState.copiedResourcesData.length !== restoredState.copiedResourcesData.length
        ) {
          // while our local window is paused we need to keep COPY_STATUS paused as well unless it's actually completed
          const allResourcesComplete = getIsAllResourcesCompletedFromState(getState());
          if (!allResourcesComplete) {
            restoredState = restoredState.set('copyStatus', COPY_STATUS.PAUSED);
          }

          dispatch(actionCreators.handleActionAndSaveStateToLocalStorage({
            type: INITIALIZE,
            payload: {restoredState}
          }));
        }

        this.watcherTimeout = setTimeout(()=>{
          dispatch(actionCreators.watchLocalStorageForUpdatesWhilePaused());
        }, 5000);
      }
    };
  },
  loadFirstPageOfAllResourcesWithMeta() {
    return (dispatch, getState) => {
      const propertyCopyState = getPropertyCopyFromState(getState());
      const params = {
        ...getCurrentRouteParamsFromState(getState()),
        property: propertyCopyState.sourceProperty.id
      };
      const urlParams = {
        'page[size]': defaultPageSize,
        'page[number]': 1
      };

      const bypassError = true;
      return Promise.all([
        dispatch(actionCreators.loadResourcesWithMeta({
          resourceType: EXTENSIONS,
          params,
          urlParams,
          bypassError,
          abortSignal: abortController?.signal,
          swallowAbortErrors
        })),
        dispatch(actionCreators.loadResourcesWithMeta({
          resourceType: RULES,
          params,
          urlParams,
          bypassError,
          abortSignal: abortController?.signal,
          swallowAbortErrors
        })),
        dispatch(actionCreators.loadResourcesWithMeta({
          resourceType: DATA_ELEMENTS,
          params,
          urlParams,
          bypassError,
          abortSignal: abortController?.signal,
          swallowAbortErrors
        }))
      ]).catch((error)=>{
        dispatch(actionCreators.pauseIfOffline());
        return error;
      });
    };
  },
  loadResourcesWithMeta({
    resourceType,
    params,
    urlParams = {},
    bypassError,
    abortSignal,
    swallowAbortErrors
  } = {}) {
    const STATE_KEY = 'propertyCopyResources';
    return (dispatch, getState) => {
      return dispatch(apiActions.apiAction({
        name: 'get' + capitalizeFirstLetter(resourceType),
        stateKey: STATE_KEY,
        urlData: params,
        urlParams: {...urlParams},
        bypassError,
        abortSignal,
        swallowAbortErrors
      })).then((result)=>{
        const resources = getApiData(getState(), STATE_KEY)[resourceType];
        const resourcesList = resourceType === 'extensions' ? Object.values(resources) || [] : resources;
        return {
          resources: resourcesList,
          type: resourceType,
          meta: result.res?.body?.meta
        };
      });
    };
  },
  goToDestinationProperty() {
    return (dispatch, getState) => {
      let params = getCurrentRouteParamsFromState(getState());
      const propertyCopyState = getPropertyCopyFromState(getState());
      const destinationPropertyParams = {...params, property: propertyCopyState.destinationProperty.id};
      return dispatch(push({ name: 'propertyOverview', params: destinationPropertyParams }));
    };
  }
};
