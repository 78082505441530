/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/

/**
 * Exports <Dialog>, <Dialog.Header>, <Dialog.Footer> and <Dialog.Content>.
 * Each of these components can be used to
 * flexible spectrum dialogs.
 *
 * @example
 * // Simple Dialog
 * <Dialog
 *   open={ this.state.open }
 *   onClose={ this.handleClose }
 * >
 *   <Dialog.Header>My Heading</Dialog.Header>
 *   <Dialog.Content>
 *     <div>My content</div>
 *   </Dialog.Content>
 *   <Dialog.Footer>
 *     <Button variant="secondary" onClick={ this.handleClose }>Cancel</Button>
 *     <Button variant="cta" onClick={ this.handleConfirm }>OK</Button>
 *   </Dialog.Footer>
 * </Dialog>
 *
 * @example
 * // Fullscreen Dialog
 * <Dialog
 *   mode="fullscreen"
 *   open={ this.state.open }
 *   onClose={ this.handleClose }
 * >
 *   <Dialog.Header
 *     actions={
 *       <div>
 *         <Button variant="secondary" onClick={ this.handleClose }>Cancel</Button>
 *         <Button variant="cta" onClick={ this.handleConfirm }>OK</Button>
 *       </div>
 *     }
 *   >
 *     My Heading
 *   </Dialog.Header>
 *   <Dialog.Content>
 *     <div>My content</div>
 *   </Dialog.Content>
 *   <Dialog.Footer>
 *     <div>
 *      Any content here will be sticky on the bottom of the dialog.
 *      Footer is not required.
 *     </div>
 *   </Dialog.Footer>
 * </Dialog>
 */
import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-overlays/Modal';
import classNames from 'classnames';
import OpenTransition from '@react/react-spectrum/utils/OpenTransition';
import Heading from '@react/react-spectrum/Heading';

// this is already imported and this import ends up being imported after the local
// import below which messed with all the dialog styles and required specificity hacks
// we should figure out how to make this import work rather than doing the hacks
// importSpectrumCSS('dialog');

import './index.styl';

const getContainer = ()=>{
  // this is a little bit brittle maybe. this just finds the first provider and attaches the modal to it
  // a better approach would be to traverse up the DOM until we find provider. but this works
  return document.querySelector('.react-spectrum-provider');
}

const Dialog = ({
  className,
  children,
  open = false,
  mode = 'centered',
  variant = 'default',
  style = {},
  onClose,
  ...otherProps
}) => {
  open = Boolean(open); //ensure boolean
  return (
    <Modal
      container={getContainer}
      { ...otherProps }
      transition={ OpenTransition }
      backdropTransition={ OpenTransition }
      style={{
        // when in fulscreen mode, we want the dialog to act like
        // content allowing things like error toasts to overlap it
        zIndex: mode === 'fullscreenTakeover' ? 80000 : 100000,
        position: 'relative'
      }}
      renderBackdrop={props => <div className={
        classNames(
          'spectrum-Underlay',
          { 'is-open': open },
        )
      } />}
      show={ open }
      backdrop={ mode !== 'fullscreenTakeover' }
      onHide={ onClose }
    >
      <div
        className={
          classNames(
            'spectrum-Dialog',
            `spectrum-Dialog--${ variant }`,
            `spectrum-Dialog--${ mode }`,
            {
              'is-open': open,
            },
            className,
          )
        }
        style={style}
        data-test-id={otherProps['data-test-id']}
      >
        { open ? children : null }
      </div>
    </Modal>
  );
};

Dialog.propTypes = {
  title: PropTypes.string,
  confirmLabel: PropTypes.node,
  cancelLabel: PropTypes.node,
  mode: PropTypes.oneOf([ 'centered', 'fullscreen', 'fullscreenTakeover' ]),
  variant: PropTypes.oneOf([ 'default', 'error', 'help', 'info', 'success', 'warning' ]),
  onEnter: PropTypes.func, // Callback fired before the Dialog transitions in
  onEntering: PropTypes.func, // Callback fired as the Dialog begins to transition in
  onEntered: PropTypes.func, // Callback fired after the Dialog finishes transitioning in
  onExit: PropTypes.func, // Callback fired right before the Dialog transitions out
  onExiting: PropTypes.func, // Callback fired as the Dialog begins to transition out
  onExited: PropTypes.func, // Callback fired after the Dialog finishes transitioning out
  /**
   * A callback fired when either the backdrop is clicked, or the escape key is pressed.
   *
   * The `onClose` callback only signals intent from the Dialog,
   * you must actually set the `open` prop to `false` for the Dialog to close.
   */
  onClose: PropTypes.func,
};

Dialog.Header = ({
  className,
  title,
  children,
  variant = 'default',
  actions,
  ...otherProps
}) => (
  <div
    className={
      classNames(
        'spectrum-Dialog-header',
        `spectrum-Dialog-header--${ variant }`,
        className,
      )
    }
    { ...otherProps }
  >
    <Heading size={ 2 } className="spectrum-Dialog-title">
      { title || children }
    </Heading>
    { actions }
  </div>
);

Dialog.Header.displayName = 'DialogHeader';
Dialog.Header.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  children: PropTypes.node,
  variant: PropTypes.oneOf([ 'default', 'error', 'help', 'info', 'success', 'warning' ]),
  actions: PropTypes.node,
  onClose: PropTypes.func,
};

Dialog.Footer = ({
  className,
  ...otherProps
}) => (
  <div
    className={ classNames('spectrum-Dialog-footer', className) }
    { ...otherProps }
  />
);

Dialog.Footer.displayName = 'DialogFooter';
Dialog.Footer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

Dialog.Content = ({
  className,
  ...otherProps
}) => (
  <div
    className={
      classNames(
        'spectrum-Dialog-content',
        className,
      )
    }
    { ...otherProps }
  />
);

Dialog.Content.displayName = 'DialogContent';
Dialog.Content.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Dialog;
