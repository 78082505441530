/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import {default as smoothScroll} from 'smoothscroll-polyfill';


export function applyPolyfills() {
  return new Promise(resolve=>{
    smoothScroll.polyfill();

    // polyfill for intl when missing
    if (!window.Intl) {
      // hello safari
      require.ensure(['intl'], (require) => {
        require('intl');
        require('intl/locale-data/jsonp/en.js');
        resolve();
      }, 'intl-polyfill');
    } else {
      // hello chrome, ie, etc.!
      resolve();
    }
  });
};
