/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/


export const storageKeyPrefix = 'lens_';

// sessionStorage utils

let storageSupportChecked = false;
let storageSupported = false;
export function isStorageSupported(_window = window) {
  // Chrome now blocks localStorage and sessionStorage by default in private windows inside an iframe :(

  // note that in order to test this without backward compatability issues we have to actually try/catch
  // See the following url for the reasons why...
  // https://github.com/Modernizr/Modernizr/blob/master/feature-detects/storage/localstorage.js

  if (!storageSupportChecked) {
    var key = storageKeyPrefix + '_supported';
    try {
      _window.localStorage.setItem(key, true);
      _window.localStorage.removeItem(key);
      storageSupported = true;
    } catch (e) {
      console.log('Web Storage APIs appear to be blocked or unsupported.');
      storageSupported = false;
    }

    storageSupportChecked = true;
  }

  return storageSupported;
}

export function setSessionStorageItem(key, value, _window = window) {
  if (!isStorageSupported()) { return; }

  if (typeof key === 'undefined' || typeof value === 'undefined') {
    throw new Error('Failed to execute \'setSessionStorageItem\': 2 arguments required.');
  }
  _window.sessionStorage.setItem(storageKeyPrefix + key, JSON.stringify(value));
}

export function getSessionStorageItem(key, _window = window) {
  if (!isStorageSupported()) { return null; }

  try {
    let value = _window.sessionStorage.getItem(storageKeyPrefix + key);
    return JSON.parse(value);
  } catch (error) {
    throw new Error('Error parsing sessionStorage key: ' + storageKeyPrefix + key);
  }
}

export function removeSessionStorageItem(key, _window = window) {
  if (!isStorageSupported()) { return; }

  _window.sessionStorage.removeItem(storageKeyPrefix + key, _window = window);
}


// localStorage utils

export function setLocalStorageItem(key, value, _window = window) {
  if (!isStorageSupported()) { return; }

  if (typeof key === 'undefined' || typeof value === 'undefined') {
    throw new Error('Failed to execute \'setLocalStorageItem\': 2 arguments required.');
  }
  _window.localStorage.setItem(storageKeyPrefix + key, JSON.stringify(value));
}

export function getLocalStorageItem(key, _window = window) {
  if (!isStorageSupported()) { return null; }

  try {
    let value = _window.localStorage.getItem(storageKeyPrefix + key);
    return JSON.parse(value);
  } catch (error) {
    throw new Error('Error parsing localStorage key: ' + storageKeyPrefix + key);
  }
}

export function removeLocalStorageItem(key, _window = window) {
  if (!isStorageSupported()) { return; }

  _window.localStorage.removeItem(storageKeyPrefix + key);
}
