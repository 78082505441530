/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import {get} from 'lodash-es';
import Immutable from 'seamless-immutable';
import {actionCreators as apiActions} from './apiActions';
import apiMappings from './apiMappings';

export const APP_ERROR_CODES = {
  GENERAL_ERROR: 'generalError',
  NETWORK_ERROR: 'networkError',
  CONFIGURATION_LOAD_ERROR: 'configurationLoadError',
  SUBMIT_FOR_APPROVAL_ERROR: 'submitForApprovalError'
};

export function getApiMappingName(endpointGroupKey, verb, matchOnlyList) {
  for (const apiName in apiMappings) {
    const apiMapping = apiMappings[apiName];
    const matchedListApiName = matchOnlyList && apiMapping.isList ? apiName : null;

    if (apiMapping.endpointGroupKey === endpointGroupKey && verb === apiMapping.verb) {
      return matchedListApiName ? matchedListApiName : apiName;
    }
  }

  // hint that we may not find an apiMapping in the loop, and that's ok.
  return undefined;
}
export function populateUrl(url, urlData, params) {
  for (let dataKey in urlData) {
    let paramReplacer = new RegExp(':' + dataKey + '\\b\\?*', 'g');
    url = url.replace(paramReplacer, urlData[dataKey]);
  }
  for (let dataKey in params) {
    url += url.indexOf('?') === -1 ? '?' : '&';
    url += dataKey + '=' + encodeURIComponent(params[dataKey]);
  }
  return url;
};

/*
* @Deprecated
* TODO Remove once everything is using stateKey.
*/
export function isEndpointLoading(state, ...endpointNames) {
  const endpointStates = state.api.endpoints;
  return endpointNames.some((endpointName) => {
    return Boolean(endpointStates?.[endpointName]?.loading);
  });
}

/*
* @Deprecated
* TODO Remove once everything is using stateKey.
*/
export function getEndpointError(state, endpointName) {
  return state.api.endpoints[endpointName] && state.api.endpoints[endpointName].error;
}

// endpointName can be a string for one endpoint or an array for multiple endpoints
export function isEndpointLoadingForStateKey(state, stateKey, endpointName) {
  const stateSlice = state.api[stateKey];
  if (stateSlice?.endpoints) {
    const endpointStates = stateSlice.endpoints;
    const endpointNames = Array.isArray(endpointName) ? endpointName : [endpointName];
    return endpointNames.some((name) => {
      const endpoint = endpointStates[name];
      if (endpoint?.pages) { // support loadAllPages actions
        return endpoint?.loading || Object.keys(endpoint.pages).some(pageId=>{
          return endpoint.pages[pageId].loading;
        });
      } else {
        return endpoint?.loading;
      }
    });
  } else {
    return false;
  }
}

export function getEndpointErrorForStateKey(state, stateKey, endpointName) {
  const stateSlice = state.api[stateKey];
  return stateSlice &&
    stateSlice.endpoints[endpointName] &&
    stateSlice.endpoints[endpointName].error;
}

export function getApiData(state, stateKey) {
  return state.api[stateKey] || Immutable({});
}

export function getApiDataByEndpointNameForStateKey(state, stateKey, endpointName) {
  const endpoint = state.api?.[stateKey]?.endpoints?.[endpointName];

  if (endpoint?.pages) {
    // handle loadAllPages case
    return Object.values(endpoint.pages).reduce((data, item) => {
      if (item?.data) {
        return data.concat(item.data);
      } else {
        return data;
      };
    }, []);
  }

  return endpoint?.data;
}

// generates a standard application error object using the optionally passed in response
export function getAppError({
  lensCode = APP_ERROR_CODES.GENERAL_ERROR,
  message = 'Unkown error',
  networkResponse
} = {}) {
  const dateTime = new Date().toString();
  let appError = {
    lensCode,
    dateTime,
    message,
    urlAtTimeOfError: window.location.toString(),
    networkResponse
  };

  if (networkResponse) {
    let headers = networkResponse.headers || {};
    // we have to use iterators to get all headers from a fetch request
    for (let headerEntry of networkResponse.headers.entries()) {
      headers[headerEntry[0]] = headerEntry[1];
    }

    appError.networkResponse = {
      headers,
      status: networkResponse.status || 520, // http catch all status code
      statusText: networkResponse.statusText || 'no-request',
      type: networkResponse.type || null,
      url: networkResponse.url || null
    };
  }

  return appError;
}

export function getGenericApiErrorBody(options = {}) {
  return {
    errors: [getGenericApiError(options)]
  };
}

// create generic overridable error
export function getGenericApiError({
  id = null,
  status = null,
  code = null,
  title = 'Unkown error',
  detail = 'An unknown error has occurred.',
  meta = {request_id: null},
  source = {}
} = {}) {
  return {id, status, code, title, detail, meta, source};
};


/**
 * Loads all pages of a list and returns all results and does not update state.
 * @deprecated - Web Socket implementation will replace calls to this function.
 * @param dispatch
 * @param apiMapping
 * @param params
 * @param urlParams
 * @param abortSignal
 * @returns {array}
 */
export function publishingLoadAllPages(dispatch, apiMapping, params, urlParams = {}, abortSignal) {
  let allItems = [];

  function getNextPage(nextPage) {
    if (nextPage) {
      urlParams = {
        ...urlParams,
        'page[number]': nextPage.toString()
      };
    }

    return dispatch(apiActions.apiAction({
      name: apiMapping,
      urlData: params,
      urlParams,
      updateState: false,
      abortSignal
    })).then((result)=>{
      // save results
      const items = get(result, 'res.body.data');
      allItems = allItems.concat(items);

      const currentPage = get(result, 'res.body.meta.pagination.currentPage');
      const totalPages = get(result, 'res.body.meta.pagination.totalPages');
      if (currentPage && totalPages && totalPages > currentPage) {
        return getNextPage(currentPage + 1);
      } else {
        return allItems;
      }
    });
  }

  return getNextPage();
}
