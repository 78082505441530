/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/


import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import Dialog from 'spectrum-alternatives/dialog/Dialog';
import Button from '@react/react-spectrum/Button';
import Switch from '@react/react-spectrum/Switch';
import Link from '@react/react-spectrum/Link';
import {hasUserRefusedDiagnosticTracking, setUserDiagnosticTrackingRefusal} from '../layouts/baseLayout/privacyUtils';
import {GLOBAL_DIALOGS_KEY, getActionCreators as getDialogsActions} from './dialogsActions';

export const PRIVACY_DIALOG_STATE_KEY = 'PRIVACY_DIALOG_STATE_KEY';

class PrivacySettingsDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userRefusedDiagnosticTrackingChanged: false
    };
  }

  closeDialog = ()=> {
    this.props.dispatch(getDialogsActions(GLOBAL_DIALOGS_KEY).closeDialog(PRIVACY_DIALOG_STATE_KEY));

    if (this.state.userRefusedDiagnosticTrackingChanged) {
      // log rocket does not have a method to stop tracking on the fly.
      window.location.reload();
    }
  };


  render() {
    const { privacyDialogOpen } = this.props;
    const { userRefusedDiagnosticTrackingChanged } = this.state;
    const userHasDisabledDiagnosticTracking = hasUserRefusedDiagnosticTracking();

    return (
      <Dialog
        open={privacyDialogOpen}
        variant="default"
        onClose={()=>{
          this.closeDialog();
        }}
      >
        <Dialog.Header>Privacy Settings</Dialog.Header>
        <Dialog.Content>
          <p>
            Launch collects some optional data to improve stability and user experience.
            <br/>
            <br/>
            <Switch
              onChange={()=>{
                setUserDiagnosticTrackingRefusal(!userHasDisabledDiagnosticTracking);
                this.setState({
                  userRefusedDiagnosticTrackingChanged: !userRefusedDiagnosticTrackingChanged
                });
              }}
              checked={!userHasDisabledDiagnosticTracking}
              label="Allow optional data collection"
            />
            <br/>
            <br/>
            Visit Adobe’s <Link target="_blank" href="http://www.adobe.com/go/marketingcloud_privacy">Privacy Policy</Link> page for more info
          </p>
        </Dialog.Content>
        <Dialog.Footer>
          <Button
            variant="primary"
            onClick={() => {
              this.closeDialog();
            }}
          >
            Close
          </Button>
        </Dialog.Footer>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    privacyDialogOpen: state.dialogs.getIn([GLOBAL_DIALOGS_KEY, PRIVACY_DIALOG_STATE_KEY])
  };
}

export default connect(mapStateToProps)(PrivacySettingsDialog);
