/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/


import {Component} from 'react';

class UpdateThrottler extends Component {
  nextRenderTime = null;
  nextRenderTimeout = null;
  static defaultProps = {
    delay: 3 * 1000 // default to a 3 second throttle delay
  };
  shouldComponentUpdate() {
    // manual throttle with trailing update
    const {delay} = this.props;
    const now = new Date().getTime();
    if (
      this.nextRenderTime === null ||
      now >= this.nextRenderTime
    ) {
      clearTimeout(this.nextRenderTimeout); // prevent next render because we'll render now instead
      this.nextRenderTime = now + delay;
      return true;
    } else {
      // delay next render instead of rendering now
      clearTimeout(this.nextRenderTimeout);
      this.nextRenderTimeout = setTimeout(()=>{ this.forceUpdate(); }, delay);
      return false;
    }
  }
  componentWillUnmount() {
    clearTimeout(this.nextRenderTimeout);
  }
  render() {
    return this.props.children;
  }
};

export default UpdateThrottler;
