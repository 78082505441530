/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/


import { EXTENSIONS, RULES, DATA_ELEMENTS } from '../../../utils/api/apiTypes';
import { getCurrentRouteParamsFromState } from '../../../routes/routeSelectors';
import { COPY_STATUS } from './propertyCopyActions';
import { PROPERTY_LIST_STATE_KEY } from '../list/properties';

export function getSelectedPropertyFromState(state) {
  return state.selections?.[PROPERTY_LIST_STATE_KEY]?.selectedItems?.[0];
}

export function getPropertyCopyFromState(state) {
  return state.propertyCopy;
}

export function getCompanyHasChangedFromState(state) {
  const params = getCurrentRouteParamsFromState(state);
  const propertyCopyState = getPropertyCopyFromState(state);
  return params.company !== propertyCopyState.company?.id;
};

export function getIsCopyingFromState(state) {
  const propertyCopyStatus = getPropertyCopyFromState(state).copyStatus;
  return (
    propertyCopyStatus === COPY_STATUS.PREPARING ||
    propertyCopyStatus === COPY_STATUS.IN_PROGRESS ||
    propertyCopyStatus === COPY_STATUS.PAUSED
  );
}

export function getAllResourcesCountFromState(state) {
  const propertyCopyState = getPropertyCopyFromState(state);

  const allResourcesCount = (
    (propertyCopyState.resourcesMeta?.[EXTENSIONS]?.pagination.totalCount || 0) +
    (propertyCopyState.resourcesMeta?.[RULES]?.pagination.totalCount || 0) +
    (propertyCopyState.resourcesMeta?.[DATA_ELEMENTS]?.pagination.totalCount || 0)
  );

  return allResourcesCount;
}

export function getCompletedResourcesCountFromState(state) {
  const propertyCopyState = getPropertyCopyFromState(state);
  const {copiedResourcesData, failedResourcesData} = propertyCopyState;
  const completedResourcesCount = copiedResourcesData.length + failedResourcesData.length;
  return completedResourcesCount;
}

export function getCompletedResourcesPercentFromState(state) {
  const allResourcesCount = getAllResourcesCountFromState(state);
  const completedResourcesCount = getCompletedResourcesCountFromState(state);
  const percentComplete = completedResourcesCount === 0 ? 0 : (
    Math.round(completedResourcesCount / allResourcesCount * 100)
  );
  return percentComplete;
}

export function getIsAllResourcesCompletedFromState(state) {
  const allResourcesCount = getAllResourcesCountFromState(state);
  const completedResourcesCount = getCompletedResourcesCountFromState(state);
  return allResourcesCount === completedResourcesCount;
}
