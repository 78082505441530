/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import Immutable from 'seamless-immutable';
import actionsHandler from '../../../redux/actionsHandler';
import {actionCreators as apiActions} from '../../../utils/api/apiActions';
import {actionCreators as extensionCacheBustActions} from './extensionCacheBustActions';
import {actionCreators as notesActions} from '../../rightNavigation/notesActions';
import {replace, push} from '../../../routes/namedRouteUtils';
import {getApiData} from '../../../utils/api/apiTools';
import {
  getRevisionRangeParameterName,
  getRevisionRange
} from '../../compareView/compareViewUtils';
import { EXTENSIONS } from '../../../utils/api/apiTypes';
import { getCurrentNewNoteTextFromState } from '../../rightNavigation/notesSelectors';


export const STATE_KEY = 'extensionEdit';
export const EXTENSION_REVISIONS_STATEKEY = 'extensionEditRevisions';

let abortController;


export const SET_DIRTY = 'extensionEdit/SET_DIRTY';
export const SET_IS_EXTENSION_VALIDATING = 'extensionEdit/SET_IS_EXTENSION_VALIDATING';
export const SET_SAVE_TRIGGERED = 'extensionEdit/SET_SAVE_TRIGGERED';
export const SET_EXTENSION = 'extensionEdit/SET_EXTENSION';
export const UPDATE_EXTENSION_SETTINGS = 'extensionEdit/UPDATE_EXTENSION_SETTINGS';

//Reducers
export default actionsHandler({
  [SET_IS_EXTENSION_VALIDATING](state, action) {
    return state.set('isExtensionValidating', action.payload);
  },
  [SET_SAVE_TRIGGERED](state, action) {
    return state.set('saveTriggered', action.payload);
  },
  [SET_DIRTY](state, action) {
    return state.set('iframeDirty', action.payload);
  },
  [SET_EXTENSION](state, action) {
    return state.set('extension', action.payload);
  },
  [UPDATE_EXTENSION_SETTINGS](state, action) {
    return state.setIn(['extension', 'attributes', 'settings'], action.payload);
  },
  default(state = Immutable({iframeDirty: false, isExtensionValidating: false, saveTriggered: false})) {
    return state;
  }
});

//Action Creators
export let actionCreators = {
  goToExtensionRevision(params, revisionId) {
    return (dispatch)=>{
      dispatch(push({
        name: 'editExtension',
        params: { ...params, extension: revisionId }
      }));
    };
  },
  goToComponentCompare({
    params,
    revisionRange,
    shouldReplaceRoute = false
  }) {
    return (dispatch, getState)=>{
      dispatch((shouldReplaceRoute ? replace : push)({
        name: 'editExtensionCompare',
        params: {
          ...params,
          [getRevisionRangeParameterName(EXTENSIONS)]: revisionRange ? revisionRange : getRevisionRange({
            rightRevisionId: getApiData(getState(), STATE_KEY).extension.id
          })
        }
      }));
    };
  },
  setIsExtensionValidating(isValidating) {
    return {
      type: SET_IS_EXTENSION_VALIDATING,
      payload: isValidating
    };
  },
  setSaveTriggered(value) {
    return {
      type: SET_SAVE_TRIGGERED,
      payload: value
    };
  },
  setDirty(value) {
    return {
      type: SET_DIRTY,
      payload: value
    };
  },
  initializeDelegate(isNew, params) {
    return (dispatch, getState) => {
      abortController = new AbortController();

      dispatch(actionCreators.setIsExtensionValidating(false));
      dispatch(actionCreators.setSaveTriggered(false));
      dispatch(actionCreators.setDirty(false));
      if (isNew) {
        return dispatch(apiActions.apiAction({
          name: 'getExtensionPackage',
          stateKey: STATE_KEY,
          urlData: {...params},
          abortSignal: abortController.signal
        }));
      } else {
        return dispatch(apiActions.apiAction({
          name: 'getExtension',
          stateKey: STATE_KEY,
          urlData: {...params},
          urlParams: {
            include: 'extension_package'
          },
          abortSignal: abortController.signal
        })).then(()=>{
          dispatch(actionCreators.setExtension(getApiData(getState(), STATE_KEY).extension));
        });
      }
    };
  },
  tearDownExtension() {
    return (dispatch) => {
      abortController?.abort();

      dispatch(apiActions.resetEndpoint(
        'getExtension',
        STATE_KEY
      ));
      dispatch(apiActions.resetEndpoint(
        'getExtensionPackage',
        STATE_KEY
      ));
      dispatch({
        type: SET_EXTENSION,
        payload: null
      });
      dispatch(apiActions.resetEndpoint(
        'getRevisions',
        EXTENSION_REVISIONS_STATEKEY
      ));
    };
  },
  createExtension(params, requestData) {
    return (dispatch) => {

      const createExtension = dispatch(apiActions.apiAction({
        name: 'createExtension',
        stateKey: STATE_KEY,
        urlData: {...params},
        data: {data: requestData}
      }));

      createExtension.then(dispatch(extensionCacheBustActions.setCacheCooldown(false)));

      return createExtension;
    };
  },
  updateExtension(params, requestData) {
    return (dispatch)=>{
      const updateExtension = dispatch(apiActions.apiAction({
        name: 'updateExtension',
        stateKey: STATE_KEY,
        urlData: {...params},
        data: {data: requestData}
      }));

      updateExtension.then(dispatch(extensionCacheBustActions.setCacheCooldown(false)));

      return updateExtension;
    };
  },
  saveExtensionAndNote({
    params,
    extension,
    delegateDisplay,
    isNew,
    publishingActions,
    buildOnSave = false,
    selectedDevLibrary,
    saveToLibrary = false
  }) {
    return (dispatch, getState) => {
      const newNoteText = getCurrentNewNoteTextFromState(getState());
      const rightRailActiveTab = getState().rightRail.activeTab;
      // this will handle disabling save right after it's clicked. If an extension edit validation returns a promise,
      // save still needs to disable while waiting for that promise to resolve.
      dispatch(actionCreators.setIsExtensionValidating(true));
      // this all needs to change with and we need to do a cache refresh
      return delegateDisplay.validate().then(
        delegateDisplay.getSettings
      ).catch(()=>{
        dispatch(actionCreators.setIsExtensionValidating(false));
        return Promise.reject({message:'Extension has invalid settings'});
      }).then((settings)=>{
        const noteSavePromise = (
          newNoteText && rightRailActiveTab ?
          dispatch(notesActions.saveCurrentViewNote(newNoteText)) :
          Promise.resolve()
        );
        dispatch(actionCreators.setIsExtensionValidating(false));
        dispatch(actionCreators.setSaveTriggered(true));
        // If there is text in the note field when saving resource, save note
        return Promise.all([
          noteSavePromise,
          dispatch(actionCreators[isNew ? 'createExtension' : 'updateExtension'](
            params,
            extension.setIn(['attributes','settings'], JSON.stringify(settings))
          ))
        ]).then(()=>{
          const extensionEditApiData = getApiData(getState(), STATE_KEY);
          return extensionEditApiData.extension;
        });
      }).then((savedExtension)=>{
        if (saveToLibrary) {
          return dispatch(publishingActions.saveLatestDelegatesToLibrary(
            {...params, extension: savedExtension.id, library: selectedDevLibrary.id},
            [savedExtension],
            selectedDevLibrary
          ));
        }
        if (buildOnSave) {
          return dispatch(publishingActions.saveLatestDelegatesToLibraryAndBuild(
            {...params, extension: savedExtension.id, library: selectedDevLibrary.id},
            [savedExtension],
            selectedDevLibrary
          ));
        }
      }).then(()=>{
        dispatch(actionCreators.goToInstalledExtensions(params));
      }).catch((err)=>{
        console.log(err && err.message || err);
      });
    };
  },
  goToInstalledExtensions(params) {
    return (dispatch) => {
      dispatch(push({ name: 'installedExtensions', params: params }));
    };
  },
  goToExtensionCatalog(params) {
    return (dispatch) => {
      dispatch(push({ name: 'extensionCatalog', params: params }));
    };
  },
  setExtension(extensionRevision) {
    return {
      type: SET_EXTENSION,
      payload: extensionRevision
    };
  },
  updateExtensionSettings(settings) {
    return {
      type: UPDATE_EXTENSION_SETTINGS,
      payload: settings
    };;
  },
  loadExtensionRevisions(params) {
    return (dispatch)=>{
      return dispatch(apiActions.apiAction({
        name: 'getRevisions',
        urlData: {
          ...params,
          resource: params.extension,
          resourceType: 'extensions'
        },
        stateKey: EXTENSION_REVISIONS_STATEKEY,
        abortSignal: abortController.signal
      }));
    };
  }
};
