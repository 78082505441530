/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2021 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import {SECRET_EDIT_STATE_KEY} from './secretsEditActions';
export const SECRETS_LIST_STATE_KEY = 'secretsList';

export function getSecretsEnvironmentsIncludedFromState(state) {
  return state[SECRETS_LIST_STATE_KEY].environments;
}

export function getCurrentSecretFromState(state) {
  return state.api?.[SECRET_EDIT_STATE_KEY]?.secret;
}

export function isRetryingSecret(state) {
  const secret = getCurrentSecretFromState(state);
  return Boolean(
    (secret?.attributes?.status === 'pending' ||
      secret?.meta?.status === 'pending')
    && isPollingSecretStatusFromState(state)
  );
}

export function isGeneratingTestToken(state) {
  return state.secretsEdit.isGeneratingTestToken;
}

export function isPollingSecretStatusFromState(state) {
  return state.polling?.[SECRET_EDIT_STATE_KEY]?.isPolling;
}

export function isPollingPaginatedListOfSecretsFromState(state) {
  return state.polling?.[SECRETS_LIST_STATE_KEY]?.isPolling;
}
