import React from 'react';

export default function rocket(props) {
  return (
    <svg className={props.className}
      xmlns="http://www.w3.org/1999/xlink"
      width="195px"
      height="450px"
      viewBox="0 0 195 354"
    >
      <linearGradient
        id="WingRight"
        gradientUnits="userSpaceOnUse"
        x1="195.888"
        y1="318.4996"
        x2="144.0025"
        y2="318.4996"
        gradientTransform="matrix(0.5543 -0.8324 -0.8324 -0.5543 334.6657 613.1614)"
      >
        <stop  offset="0" style={{stopColor:'#D4A92A'}}/>
        <stop  offset="0.5032" style={{stopColor:'#F79520'}}/>
        <stop  offset="0.9913" style={{stopColor:'#F68A28'}}/>
        <stop  offset="1" style={{stopColor:'#F68A28'}}/>
      </linearGradient>
      <path
        d="M148.8,254.1c-1.4,8.2-2.5,15.1-3.6,21.9c-1.6,9.5-5.1,34.1-5.1,34.1s3.4,2.8,7.1,5.1
        c11.2,7.1,19.2,12.8,31.5,21c1.8-13,7.6-45.5,7.6-45.5"
        fill="url(#WingRight)"
      />
      <linearGradient
        id="WingLeft"
        gradientUnits="userSpaceOnUse"
        x1="138.1811"
        y1="425.0396"
        x2="53.2256"
        y2="425.0396"
        gradientTransform="matrix(0.5543 -0.8324 -0.8324 -0.5543 334.6657 613.1614)"
      >
        <stop  offset="0" style={{stopColor:'#D4A92A'}}/>
        <stop  offset="0.5032" style={{stopColor:'#F79520'}}/>
        <stop  offset="0.9913" style={{stopColor:'#F68A28'}}/>
        <stop  offset="1" style={{stopColor:'#F68A28'}}/>
      </linearGradient>
      <path
        d="M46.2,255.1c1.3,8.2,2.4,15.1,3.6,21.9c1.5,9.5,4.9,34.1,4.9,34.1s-3.5,2.7-7,5.1
        c-11.2,7.1-19.3,12.7-31.7,20.8c-1.7-13.1-7.2-45.5-7.2-45.5"
        fill="url(#WingLeft)"
      />
      <linearGradient
        id="RocketCenter"
        gradientUnits="userSpaceOnUse"
        x1="373.0782"
        y1="86.759"
        x2="89.8022"
        y2="86.759"
        gradientTransform="matrix(0.5483 -0.8363 0.8363 0.5483 -96.4427 332.979)"
      >
        <stop  offset="0" style={{stopColor:'#D4A92A'}}/>
        <stop  offset="0.5032" style={{stopColor:'#F79520'}}/>
        <stop  offset="0.9913" style={{stopColor:'#F68A28'}}/>
        <stop  offset="1" style={{stopColor:'#F68A28'}}/>
      </linearGradient>
      <path
        d="M152,119.9c-4.7-41.7-25.7-74.5-53.8-105.5c-5.5,7-10.5,13.2-15.2,19.4
        c-19,24.4-32.6,51.6-37.8,81.9c-3.1,18.2-2.7,37.2-1.4,55.6c1.6,22.1,5.6,44,8.8,65.9c4.4,30.9,9.1,61.7,13.9,92.4
        c0.4,2.2,3,5.1,5.1,5.7c15.5,4.6,31.3,5.1,47.3,2.2c9.7-1.8,9.6-2,11-11.8c5.3-36.6,10.1-73.4,16.3-109.8
        C151.3,184.2,155.6,152.6,152,119.9z M97.8,163.7c-13,0-23.1-10.2-22.9-23.3c0.2-12.5,10.9-23.2,23.4-23.3
        c12.8-0.1,23.3,10.2,23.4,23.1C121.7,152.6,110.5,163.6,97.8,163.7z"
        fill="url(#RocketCenter)"
      />
    </svg>
  );
}
