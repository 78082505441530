import { template } from 'lodash-es';

/** container object for exporting which allows proper mocking in testing */
const genericUtils = {};

/**
 * Checks is the item is an actual object.
 *
 * @param {*} item that needs to be checked
 * @return {boolean} whether the item was an object
 */
const isObject = item =>
  typeof item === 'object' &&
  item !== null &&
  item.toString() === {}.toString();

/**
 * Checks is the item is an actual array.
 *
 * @param {*} item that needs to be checked
 * @return {boolean} whether the item was an array
 */
const isArray = item =>
  Object.prototype.toString.call(item) === '[object Array]';

/**
 * Makes a deep clone of any object including arrays in all depths
 *
 * @param {object} source The object to be cloned.
 * @return {object} the new cloned object
 */
const cloneDeep = source => JSON.parse(JSON.stringify(source));

/**
 * Method to conditionally copy properties from one object to another using an optional filter to exclude
 * properties we want to leave untouched
 *
 * @param {object} target the target object where we want to put the new properties in
 * @param {object} source the source object that we want to copy properties from
 * @param {array} filters list of keys or values that we want to exclude from the copy
 * @param {array<string>} nullDeleteFields The fields to delete from the final object if newOffer value for that key is null.
 * @return {object} the new object after merging properties from source into target
 */
const conditionalMergeJSON = (
  target,
  source,
  filters = [],
  nullDeleteFields = []
) => {
  const clonedTarget = cloneDeep(target);
  const defaultFilters = [null, undefined, 'undefined', 'null'];

  for (const key in source) {
    if (isObject(clonedTarget[key]) && isObject(source[key])) {
      clonedTarget[key] = conditionalMergeJSON(
        clonedTarget[key],
        source[key],
        filters,
        nullDeleteFields
      );
    } else if (nullDeleteFields.includes(key) && source[key] === null) {
      delete clonedTarget[key];
    } else if (
      !defaultFilters.includes(source[key]) &&
      !filters.includes(key) &&
      !filters.includes(source[key])
    ) {
      clonedTarget[key] = source[key];
    }
  }
  return clonedTarget;
};

/**
 * Method that uses LoDash template to expand strings using the token with format {{value}}
 *
 * @param {string} templateString the string containing the tokens to be replaced by the template function
 * @param {object} parts key value pairs where key is the token property and the value is the replacement for the token
 * @param {function} validator optional function to be called and validate the parts passed in
 * @return {string} the resulting string with the properties from parts
 */
const expandTemplate = (templateString, parts, validator) => {
  if (typeof validator === 'function') {
    validator(parts);
  }

  if (!isObject(parts)) {
    throw new Error('Object parts must be defined and is an object');
  }

  return template(templateString, {
    interpolate: /{{(.+?)}}/g
  })(parts);
};

/**
 * Checks for object equality as objects are compared with its reference. This ensures that even new objects
 * from are checked based on its properties and values
 *
 * @param {object} source the source object to be compared against the target
 * @param {object} target the target object to be compared against the source
 * @return {boolean} whether the source object is equal to the target object
 */
const isObjectEquivalent = (source, target) => {
  const sourceProps = Object.getOwnPropertyNames(source);
  const targetProps = Object.getOwnPropertyNames(target);

  if (sourceProps.length !== targetProps.length) {
    return false;
  }

  for (let index = 0; index < sourceProps.length; index++) {
    const propName = sourceProps[index];

    if (isObject(source[propName]) && isObject(target[propName])) {
      return isObjectEquivalent(source[propName], target[propName]);
    } else if (source[propName] !== target[propName]) {
      return false;
    }
  }

  return true;
};

/**
 * Turn a string to camel case.
 * @param {String} str - the string to change to camelCase
 * @return {String} the camelCase string
 */
const toCamel = str => str.replace(/\W+(.)/g, ([, chr]) => chr.toUpperCase());

/**
 * Set a regular Date string and return in this format (yyyy-mm-dd)
 * @param {string} dateString - the date string
 * @return {string} the date string in this format yyyy-mm-dd
 */


/**
 * Validates a property of a parsed XDM schema.
 * @param {object} schemaProperty Parsed XDM schema property
 * @param {object} propKey xdm schema key for schemaProperty
 * @return {object} Error or true
 */
const validateParsedSchemaProperty = (schemaProperty, propKey = '') => {
  let isValid = true;
  let propErrs = '';
  const requiredProperties = ['path', 'title', 'key', 'renderable', 'control'];

  if (schemaProperty.hasChildren) {
    requiredProperties.push('enum');
  }

  /* Iterates through schemaProperty keys and recurses if another xdm: prefix is found.
   * Supports validation of nested schemas.
  */
  Object.keys(schemaProperty).forEach(key => {
    if (key.includes('xdm:')) {
      const propValid = validateParsedSchemaProperty(schemaProperty[key], key);

      if (typeof propValid === 'string') {
        isValid = false;
        propErrs += `${propValid}\n`;
      }
    }
  });

  if (
    !schemaProperty.referenced &&
    !schemaProperty.items &&
    propKey !== 'xdm:customMetadata'
  ) {
    requiredProperties.forEach(reqProp => {
      if (!Object.keys(schemaProperty).includes(reqProp)) {
        isValid = false;
        propErrs += ` - Schema node ${propKey} missing property ${reqProp}.\n`;
      }
    });
  }
  return isValid ? true : propErrs;
};

/**
 * Validates parsed XDM schemas.
 * @param {object} parsedSchema Parsed XDM schema object
 * @return {object} Error or true
 */
const validateParsedSchema = parsedSchema => {
  let isValid = true;
  let propErrs = '';

  if (!parsedSchema.title) {
    return new Error('Invalid prop format. xdmSchema does not have a title.');
  }

  Object.keys(parsedSchema).forEach(key => {
    if (key.includes('xdm:')) {
      const propValid = validateParsedSchemaProperty(parsedSchema[key], key);

      if (typeof propValid === 'string') {
        isValid = false;
        propErrs += `${propValid}\n`;
      }
    }
  });

  if (!isValid) {
    return new Error(
      `Invalid xdm schema prop. Missing required properties. \n${propErrs}`
    );
  }

  return true;
};

/**
 * Custom method to validate data prop for XDMForm
 * @param {object} propValue - data prop
 * @return {object} Error message or true
 */
const validateDataProp = propValue => {
  if (!isObject(propValue[0])) {
    return new Error('Invalid prop. Prop data must be an array of objects');
  }

  return true;
};

/**
 * Capitalize the first letter of a given string.
 * @param {String} str - Input string
 * @return {String} the string with its first letter capitalized
 */
const capitalizeFirstLetter = str => {
  if (str && str.length > 0) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str;
};

/**
 * Inserts a given array starting at index of another array.
 * @param {Integer} insIdx - the index to insert the array at.
 * @param {Array} insArray - the array to be inserted.
 * @param {Array} arr - the array to insert into.
 */
const insertAt = (insIdx, insArr, arr) => [
  ...arr.slice(0, insIdx),
  ...insArr,
  ...arr.slice(insIdx)
];

/* eslint no-magic-numbers :0 */
const deferred = (
  predicate = () => true,
  retryInterval = 10,
  maxRetries = Infinity,
  promise
) => {
  const deferredPromise =
    promise ||
    new Promise((resolve, reject) => {
      if (predicate()) {
        return resolve(true);
      } else if (maxRetries > 0) {
        setTimeout(() => {
          deferred(predicate, maxRetries - 1, deferredPromise);
        }, retryInterval);
      } else {
        return reject(new TypeError('Maximum Retries Exceeded'));
      }
    });

  return deferredPromise;
};

/**
 * Get the highest z-index value
 * @param {object} node
 * @returns {number} the z-index value
 */
const getTopZIndex = node => {
  const nodes = node.getElementsByTagName('*');
  let highest = 0;
  let current = 0;

  for (let index = 0; index < nodes.length; index++) {
    current = Number(window.getComputedStyle(nodes[index]).zIndex);
    if (current > highest) {
      highest = current;
    }
  }

  return highest;
};

genericUtils.isArray = isArray;
genericUtils.isObject = isObject;
genericUtils.cloneDeep = cloneDeep;
genericUtils.conditionalMergeJSON = conditionalMergeJSON;
genericUtils.expandTemplate = expandTemplate;
genericUtils.isObjectEquivalent = isObjectEquivalent;
genericUtils.toCamel = toCamel;
genericUtils.validateParsedSchemaProperty = validateParsedSchemaProperty;
genericUtils.validateParsedSchema = validateParsedSchema;
genericUtils.validateDataProp = validateDataProp;
genericUtils.capitalizeFirstLetter = capitalizeFirstLetter;
genericUtils.insertAt = insertAt;
genericUtils.deferred = deferred;
genericUtils.getTopZIndex = getTopZIndex;

export {
  genericUtils,
  isArray,
  isObject,
  cloneDeep,
  conditionalMergeJSON,
  expandTemplate,
  isObjectEquivalent,
  toCamel,
  validateParsedSchemaProperty,
  validateParsedSchema,
  validateDataProp,
  capitalizeFirstLetter,
  insertAt,
  deferred,
  getTopZIndex
};
