/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import Immutable from 'seamless-immutable';

import actionsHandler from '../../../redux/actionsHandler';
import {actionCreators as apiActions} from '../../../utils/api/apiActions';
import {delegateImplementationsNormalizer} from
  '../../../utils/api/apiNormalizers';
import {
  updateRuleComponentInGroupedComponents,
  getRuleComponentFromGroupedComponents,
  flattenGroupedRuleComponents,
  setRuleComponentsOrderAttributeBasedOnSortIndex,
  groupAndSortRuleComponents
} from './ruleEditHelpers';
import {
  getResourceOriginId,
  NEW_RESOURCE_ID_REGEX
} from '../../../utils/resourceUtils';
import {
  RULE_COMPONENTS
} from '../../../utils/api/apiTypes';
import {
  getEditorRuleComponent,
  getActiveRuleComponentById,
  getRuleComponentsWithChanges,
  getActiveRuleComponents,
  getDeletionRuleComponents
} from './ruleEditSelectors';
import { randomAlphaNumeric } from '../../../utils/dataUtils';
import {actionCreators as ruleComponentEditorActions} from './ruleComponentEditorActions';

export const EDITOR_RULE_COMPONENT = 'editorRuleComponent';
export const PRISTINE_RULE_COMPONENTS = 'pristineRuleComponents';
export const ACTIVE_RULE_COMPONENTS = 'activeRuleComponents';
export const DELETION_RULE_COMPONENTS = 'deletionRuleComponents';

export const SET_DEFAULT_STATE = 'delegate/SET_DEFAULT_STATE';
export const INITIALIZE_RULE_COMPONENTS = 'delegate/INITIALIZE_RULE_COMPONENTS';
export const REPLACE_PRISTINE_WITH_ACTIVE = 'delegate/REPLACE_PRISTINE_WITH_ACTIVE';
export const REPLACE_ACTIVE_WITH_PRISTINE = 'delegate/REPLACE_ACTIVE_WITH_PRISTINE';
export const EDIT_NEW_RULE_COMPONENT = 'delegate/EDIT_NEW_RULE_COMPONENT';
export const COPY_EDITOR_RULE_COMPONENT_TO_ACTIVE = 'delegate/COPY_EDITOR_RULE_COMPONENT_TO_ACTIVE';
export const SET_EDITOR_RULE_COMPONENT_EXTENSION_ID = 'delegate/SET_EDITOR_RULE_COMPONENT_EXTENSION_ID';
export const SET_EDITOR_RULE_COMPONENT_REVSION_ID = 'delegate/SET_EDITOR_RULE_COMPONENT_REVSION_ID';
export const SET_EDITOR_RULE_COMPONENT_LOADED = 'delegate/SET_EDITOR_RULE_COMPONENT_LOADED';
export const SET_EDITOR_RULE_COMPONENT_SETTINGS = 'delegate/SET_EDITOR_RULE_COMPONENT_SETTINGS';
export const SET_EDITOR_RULE_COMPONENT_NEGATE = 'delegate/SET_EDITOR_RULE_COMPONENT_NEGATE';
export const SET_EDITOR_RULE_COMPONENT_NAME = 'delegate/SET_EDITOR_RULE_COMPONENT_NAME';
export const SET_EDITOR_RULE_COMPONENT_RULE_ORDER = 'delegate/SET_EDITOR_RULE_COMPONENT_RULE_ORDER';
export const SET_EDITOR_RULE_COMPONENT_TIMEOUT = 'delegate/SET_EDITOR_RULE_COMPONENT_TIMEOUT';
export const SET_EDITOR_RULE_COMPONENT_DELAY_NEXT = 'delegate/SET_EDITOR_RULE_COMPONENT_DELAY_NEXT';
export const SET_RULE_COMPONENT_ORDER = 'delegate/SET_RULE_COMPONENT_ORDER';
export const QUEUE_RULE_COMPONENT_FOR_DELETION = 'delegate/QUEUE_RULE_COMPONENT_FOR_DELETION';
export const CLEAN_DELETION_RULE_COMPONENTS = 'delegate/CLEAN_DELETION_RULE_COMPONENTS';
export const SET_EDITOR_RULE_COMPONENT_DIRTINESS = 'delegate/SET_EDITOR_RULE_COMPONENT_DIRTINESS';
export const SET_RULE_COMPONENT_ERROR = 'delegate/SET_RULE_COMPONENT_ERROR';
export const SET_EDITOR_RULE_COMPONENT_VALIDITY = 'delegate/SET_EDITOR_RULE_COMPONENT_VALIDITY';
export const UPDATE_ACTIVE_RULE_COMPONENT = 'delegate/UPDATE_ACTIVE_RULE_COMPONENT';
export const ADD_ACTIVE_RULE_COMPONENT = 'delegate/ADD_ACTIVE_RULE_COMPONENT';
export const EDIT_EXISTING_RULE_COMPONENT = 'delegate/EDIT_EXISTING_RULE_COMPONENT';
export const STOP_EDITING_RULE_COMPONENT = 'delegate/STOP_EDITING_RULE_COMPONENT';
export const SET_RULE_COMPONENT_LOAD_FAILED = 'delegate/SET_RULE_COMPONENT_LOAD_FAILED';
export const SET_EXTENSIONS_USED_BY_RESOURCE_ARE_INSTALLED = 'delegate/SET_EXTENSIONS_USED_BY_RESOURCE_ARE_INSTALLED';
export const SET_EXTENSIONS_INSTALLED_CONTAIN_DELEGATE_TYPE = 'delegate/SET_EXTENSIONS_INSTALLED_CONTAIN_DELEGATE_TYPE';

// Creates a temporary ID for new rule components.
const createNewRuleComponentId = (() => {
  let currentNewId = 1;
  return () => {
    const newStringId = '' + currentNewId++;
    // the final string should be 'RC' + 32 characters
    // the word 'new' helps us differentiate an unsaved rule component from a saved one
    return 'RC' + 'new' + newStringId.padStart(3, '0') + randomAlphaNumeric(26);
  };
})();

//Reducers
export default actionsHandler({
  [SET_DEFAULT_STATE](state, action) {
    return state.set(action.payload.stateKey, {
      [EDITOR_RULE_COMPONENT]: null,
      [PRISTINE_RULE_COMPONENTS]: null,
      [ACTIVE_RULE_COMPONENTS]: null,
      [DELETION_RULE_COMPONENTS]: [],
      ruleComponentsInitialized: false,
      extensionsInstalledContainDelegateType: true
    });
  },
  [INITIALIZE_RULE_COMPONENTS](state, action) {
    const {stateKey, ruleComponents} = action.payload;
    const groupedRuleComponents = groupAndSortRuleComponents(delegateImplementationsNormalizer({
      delegateImplementations: ruleComponents
    }));
    return state
      .setIn([stateKey, ACTIVE_RULE_COMPONENTS], groupedRuleComponents)
      .setIn([stateKey, PRISTINE_RULE_COMPONENTS], groupedRuleComponents)
      .setIn([stateKey, 'ruleComponentsInitialized'], true);
  },
  [REPLACE_PRISTINE_WITH_ACTIVE](state, action) {
    const {stateKey} = action.payload;
    return state
      .setIn([stateKey, PRISTINE_RULE_COMPONENTS], state[stateKey][ACTIVE_RULE_COMPONENTS]);
  },
  [REPLACE_ACTIVE_WITH_PRISTINE](state, action) {
    const {stateKey} = action.payload;
    return state
      .setIn([stateKey, ACTIVE_RULE_COMPONENTS], state[stateKey][PRISTINE_RULE_COMPONENTS]);
  },
  [EDIT_NEW_RULE_COMPONENT](state, {payload:{
    stateKey,
    componentType,
    extensionId
  }}) {
    const newRuleComponent = {
      attributes: {
        // extension developers need a way to know that the rule component that is
        // loaded is new. That is the purpose of this null. It should never be null
        // after running get settings for saving the delegate.
        settings: null
      },
      relationships: {
        extension: {
          data: {
            id: extensionId,
            type: 'extensions'
          }
        }
      },
      isLoaded: false,
      valid: true,
      dirty: false,
      id: createNewRuleComponentId(),
      componentType,
      type: RULE_COMPONENTS
    };

    return state.setIn([
      stateKey,
      EDITOR_RULE_COMPONENT
    ], newRuleComponent);
  },
  [COPY_EDITOR_RULE_COMPONENT_TO_ACTIVE](state, {payload: {
    stateKey,
    ruleComponent
  }}) {
    return state.updateIn([
      stateKey,
      ACTIVE_RULE_COMPONENTS
    ], (groupedRuleComponents)=>{
      return groupedRuleComponents.update(
        ruleComponent.componentType,
        (ruleComponents) => ruleComponents.concat(ruleComponent)
      );
    });
  },
  [SET_EDITOR_RULE_COMPONENT_EXTENSION_ID](state, {payload: {
    stateKey,
    newExtensionId
  }}) {
    return state.updateIn([
      stateKey,
      EDITOR_RULE_COMPONENT
    ], (ruleComponent) => {
      return ruleComponent
        .set('extensionId', newExtensionId)
        .setIn(['relationships', 'extension', 'data', 'id'], newExtensionId);
    });
  },
  [SET_EDITOR_RULE_COMPONENT_REVSION_ID](state, {payload:{
    stateKey,
    newRevisionId
  }}) {
    return state.updateIn([
      stateKey,
      EDITOR_RULE_COMPONENT
    ], (ruleComponent) => {
      ruleComponent = ruleComponent.setIn([
        'attributes',
        'delegateDescriptorId'
      ], newRevisionId);

      return ruleComponent
        .setIn(['attributes', 'settings'], null)
        .set('valid', true)
        .set('delegateDescriptorId', newRevisionId);
    });
  },
  [SET_EDITOR_RULE_COMPONENT_LOADED](state, {payload:{
    stateKey,
    newStatus
  }}) {
    return state.updateIn([
      stateKey,
      EDITOR_RULE_COMPONENT
    ], (ruleComponent) => {
      if (ruleComponent) {
        return ruleComponent.set('isLoaded', newStatus);
      }
    });
  },
  [SET_EDITOR_RULE_COMPONENT_SETTINGS](state, {payload:{
    stateKey,
    newSettings
  }}) {
    return state.updateIn([
      stateKey,
      EDITOR_RULE_COMPONENT
    ], (ruleComponent) => {
      return ruleComponent.setIn(['attributes', 'settings'], JSON.stringify(newSettings));
    });
  },
  [SET_EDITOR_RULE_COMPONENT_NEGATE](state, {payload:{
    stateKey,
    newNegate
  }}) {
    return state.updateIn([
      stateKey,
      EDITOR_RULE_COMPONENT
    ], (ruleComponent) => {
      return ruleComponent.setIn(['attributes', 'negate'], newNegate);
    });
  },
  [SET_EDITOR_RULE_COMPONENT_NAME](state, {payload:{
    stateKey,
    newName
  }}) {
    return state.updateIn([
      stateKey,
      EDITOR_RULE_COMPONENT
    ], (ruleComponent) => {
      return ruleComponent.setIn(['attributes', 'name'], newName);
    });
  },
  [SET_EDITOR_RULE_COMPONENT_RULE_ORDER](state, {payload:{
    stateKey,
    newRuleOrder
  }}) {
    return state.updateIn([
      stateKey,
      EDITOR_RULE_COMPONENT
    ], (ruleComponent) => {
      return ruleComponent.setIn(['attributes', 'ruleOrder'], Number(newRuleOrder));
    });
  },
  [SET_EDITOR_RULE_COMPONENT_TIMEOUT](state, {payload:{
    stateKey,
    newTimeout
  }}) {
    return state.updateIn([
      stateKey,
      EDITOR_RULE_COMPONENT
    ], (ruleComponent) => {
      return ruleComponent.setIn(['attributes', 'timeout'], Number(newTimeout));
    });
  },
  [SET_EDITOR_RULE_COMPONENT_DELAY_NEXT](state, {payload:{
    stateKey,
    newDelayNext
  }}) {
    return state.updateIn([
      stateKey,
      EDITOR_RULE_COMPONENT
    ], (ruleComponent) => {
      return ruleComponent.setIn(['attributes', 'delayNext'], Boolean(newDelayNext));
    });
  },
  [SET_RULE_COMPONENT_ORDER](
    state,
    {payload: {
      stateKey,
      ruleComponentId,
      newOrder
    }}
  ) {
    return state.updateIn([
      stateKey,
      ACTIVE_RULE_COMPONENTS
    ], (groupedRuleComponents)=>{
      const ruleComponent =
        getRuleComponentFromGroupedComponents(groupedRuleComponents, ruleComponentId);

      groupedRuleComponents = groupedRuleComponents.update(ruleComponent.componentType, (ruleComponentsForType) => {
        const index = ruleComponentsForType.indexOf(ruleComponent);
        // We have to make it mutable because we're splicing, which modifies the array.
        // If we didn't, seamless-immutable would throw an error when splicing.
        ruleComponentsForType = ruleComponentsForType.asMutable();

        if (index !== -1) {
          ruleComponentsForType.splice(index, 1);
        }

        ruleComponentsForType.splice(newOrder, 0, ruleComponent);
        ruleComponentsForType = Immutable(ruleComponentsForType);

        return ruleComponentsForType;
      });

      groupedRuleComponents =
        setRuleComponentsOrderAttributeBasedOnSortIndex(groupedRuleComponents);

      return groupedRuleComponents;
    });
  },
  [QUEUE_RULE_COMPONENT_FOR_DELETION](state, {payload:{stateKey, ruleComponentId}}) {
    return state.updateIn([stateKey, DELETION_RULE_COMPONENTS], (ruleComponents)=>{
      const pristineRuleComponent = getRuleComponentFromGroupedComponents(
        state[stateKey][PRISTINE_RULE_COMPONENTS],
        ruleComponentId
      );

      // If the rule component isn't found in the collection of pristine rule components, it
      // means that the rule component was never previously saved to the server, so we don't
      // need to queue it up for deletion.
      if (pristineRuleComponent) {
        ruleComponents = ruleComponents.concat(pristineRuleComponent);
      }

      return ruleComponents;
    }).updateIn(
      [stateKey, ACTIVE_RULE_COMPONENTS],
      (groupedRuleComponents)=>{
        let flattened = flattenGroupedRuleComponents(groupedRuleComponents);
        const index = flattened.findIndex(ruleComponent => ruleComponent.id === ruleComponentId);

        if (index !== -1) {
          flattened = flattened.asMutable();
          flattened.splice(index, 1);
          flattened = Immutable(flattened);
        }

        groupedRuleComponents = groupAndSortRuleComponents(flattened);
        groupedRuleComponents =
          setRuleComponentsOrderAttributeBasedOnSortIndex(groupedRuleComponents);
        return groupedRuleComponents;
      }
    );
  },
  [CLEAN_DELETION_RULE_COMPONENTS](state, {payload:{stateKey}}) {
    return state.setIn([stateKey, DELETION_RULE_COMPONENTS], []);
  },
  [SET_EDITOR_RULE_COMPONENT_DIRTINESS](state, {payload:{stateKey, dirty}}) {
    return state.updateIn([
      stateKey,
      EDITOR_RULE_COMPONENT
    ], (ruleComponent) => {
      return ruleComponent.set('dirty', dirty);
    });
  },
  [SET_RULE_COMPONENT_ERROR](state, {payload:{stateKey, ruleComponentId, error}}) {
    return state.updateIn([
      stateKey,
      ACTIVE_RULE_COMPONENTS
    ], (groupedRuleComponents) => {
      return updateRuleComponentInGroupedComponents(
        groupedRuleComponents,
        ruleComponentId,
        ruleComponent => {
          return ruleComponent.set('error', error);
        }
      );
    });
  },
  [SET_EDITOR_RULE_COMPONENT_VALIDITY](state, {payload:{stateKey, isValid}}) {
    return state.updateIn([
      stateKey,
      EDITOR_RULE_COMPONENT
    ], ruleComponent => {
      return ruleComponent.set('valid', isValid);
    });
  },
  [UPDATE_ACTIVE_RULE_COMPONENT](state, {payload: {stateKey, ruleComponentId, updates}}) {
    return state.updateIn([
      stateKey,
      ACTIVE_RULE_COMPONENTS
    ], (groupedRuleComponents) => {
      return updateRuleComponentInGroupedComponents(
        groupedRuleComponents,
        ruleComponentId,
        ruleComponent => ruleComponent.merge(updates, { deep: true })
      );
    });
  },
  [ADD_ACTIVE_RULE_COMPONENT](state, {payload: {stateKey, component}}) {
    return state.updateIn([
      stateKey,
      ACTIVE_RULE_COMPONENTS
    ], (groupedRuleComponents) => {
      let flattened = flattenGroupedRuleComponents(groupedRuleComponents);
      flattened = flattened.concat([component.set('id', createNewRuleComponentId())]);
      return groupAndSortRuleComponents(flattened);
    });
  },
  [EDIT_EXISTING_RULE_COMPONENT](state, {payload: {stateKey, ruleComponentId}}) {
    const ruleComponent = getRuleComponentFromGroupedComponents(
      state[stateKey][ACTIVE_RULE_COMPONENTS],
      ruleComponentId
    );
    return state.setIn([
      stateKey,
      EDITOR_RULE_COMPONENT
    ], ruleComponent);
  },
  [STOP_EDITING_RULE_COMPONENT](state, {payload: {stateKey}}) {
    return state.setIn([
      stateKey,
      EDITOR_RULE_COMPONENT
    ], null);
  },
  [SET_RULE_COMPONENT_LOAD_FAILED](state, {payload: {stateKey}}) {
    return state.updateIn([
      stateKey,
      EDITOR_RULE_COMPONENT
    ], (ruleComponent) => {
      return ruleComponent.set('loadFailed', true);
    });
  },
  default: (state = Immutable({}))=> {
    return state;
  }
});

//Action Creators

export const actionCreators = (stateKey)=>{

  const actionCreators = {
    setDefaultState() {
      return {
        type: SET_DEFAULT_STATE,
        payload: {
          stateKey
        }
      };
    },
    initialize(ruleComponents) {
      return (dispatch)=>{
        dispatch({
          type: INITIALIZE_RULE_COMPONENTS,
          payload: {
            stateKey,
            ruleComponents
          }
        });
      };
    },
    replacePristineWithActive() {
      return {
        type: REPLACE_PRISTINE_WITH_ACTIVE,
        payload: {
          stateKey
        }
      };
    },
    replaceActiveWithPristine() {
      return {
        type: REPLACE_ACTIVE_WITH_PRISTINE,
        payload: {
          stateKey
        }
      };
    },
    keepEditorChanges(ruleComponentId, formValues, iframeValidate, iframeGetSettings) {
      return (dispatch, getState) => {

        dispatch(actionCreators.setEditorRuleComponentNegate(formValues.logicType === 'exception'));

        dispatch(actionCreators.setEditorRuleComponentName(
          formValues.name
        ));

        if (formValues.ruleOrder !== undefined) {
          dispatch(actionCreators.setEditorRuleComponentRuleOrder(
            formValues.ruleOrder
          ));
        }

        if (formValues.timeout !== undefined) {
          dispatch(actionCreators.setEditorRuleComponentTimeout(
            formValues.timeout
          ));
        }

        if (formValues.delayNext !== undefined) {
          dispatch(actionCreators.setEditorRuleComponentDelayNext(
            formValues.delayNext
          ));
        }

        return dispatch(actionCreators.validateEditorRuleComponent(
          iframeValidate,
          iframeGetSettings
        ))
        .then(()=> iframeGetSettings())
        .then((settings)=>{
          dispatch(
            actionCreators.setEditorRuleComponentSettings(
              settings
            )
          );
          dispatch(actionCreators.setEditorRuleComponentDirtiness(false));
          dispatch(actionCreators.setEditorRuleComponentLoaded(false));
          dispatch(ruleComponentEditorActions.setIsRuleComponentValidating(false));

          const ruleComponent = getEditorRuleComponent(
            getState(),
            stateKey
          );

          const ruleComponentIsNotNew = Boolean(
            getActiveRuleComponentById(getState(), stateKey, ruleComponentId)
          );

          if (ruleComponentIsNotNew) {
            dispatch(actionCreators.updateActiveRuleComponent(
              ruleComponentId,
              ruleComponent
            ));
          } else {
            dispatch(actionCreators.copyEditorRuleComponentToActive(
              ruleComponent
            ));
          }
          return ruleComponentId;
        });
      };
    },
    editNewRuleComponent(componentType, extensionId) {
      return {
        type: EDIT_NEW_RULE_COMPONENT,
        payload: {
          stateKey,
          componentType,
          extensionId
        }
      };
    },
    copyEditorRuleComponentToActive(ruleComponent) {
      return (dispatch, getState) => {
        const activeRuleComponents = getActiveRuleComponents(getState(), stateKey);
        const newOrder = activeRuleComponents[ruleComponent.componentType].length;

        dispatch({
          type: COPY_EDITOR_RULE_COMPONENT_TO_ACTIVE,
          payload: {
            stateKey,
            ruleComponent
          }
        });

        dispatch(actionCreators.setRuleComponentOrder(
          ruleComponent.id,
          newOrder
        ));
      };
    },
    setEditorRuleComponentExtensionId(newExtensionId) {
      return {
        type: SET_EDITOR_RULE_COMPONENT_EXTENSION_ID,
        payload: {
          stateKey,
          newExtensionId
        }
      };
    },
    setEditorRuleComponentRevisionId(newRevisionId) {
      return {
        type: SET_EDITOR_RULE_COMPONENT_REVSION_ID,
        payload: {
          stateKey,
          newRevisionId
        }
      };
    },
    setEditorRuleComponentLoaded(newStatus) {
      return {
        type: SET_EDITOR_RULE_COMPONENT_LOADED,
        payload: {
          stateKey,
          newStatus
        }
      };
    },
    setEditorRuleComponentSettings(newSettings) {
      return {
        type: SET_EDITOR_RULE_COMPONENT_SETTINGS,
        payload: {
          stateKey,
          newSettings
        }
      };
    },
    setEditorRuleComponentNegate(newNegate) {
      return {
        type: SET_EDITOR_RULE_COMPONENT_NEGATE,
        payload: {
          stateKey,
          newNegate
        }
      };
    },
    setEditorRuleComponentName(newName) {
      return {
        type: SET_EDITOR_RULE_COMPONENT_NAME,
        payload: {
          stateKey,
          newName
        }
      };
    },
    setEditorRuleComponentRuleOrder(newRuleOrder) {
      return {
        type: SET_EDITOR_RULE_COMPONENT_RULE_ORDER,
        payload: {
          stateKey,
          newRuleOrder
        }
      };
    },
    setEditorRuleComponentTimeout(newTimeout) {
      return {
        type: SET_EDITOR_RULE_COMPONENT_TIMEOUT,
        payload: {
          stateKey,
          newTimeout
        }
      };
    },
    setEditorRuleComponentDelayNext(newDelayNext) {
      return {
        type: SET_EDITOR_RULE_COMPONENT_DELAY_NEXT,
        payload: {
          stateKey,
          newDelayNext
        }
      };
    },
    setRuleComponentOrder(ruleComponentId, newOrder) {
      return {
        type: SET_RULE_COMPONENT_ORDER,
        payload: {
          stateKey,
          ruleComponentId,
          newOrder
        }
      };
    },
    queueRuleComponentForDeletion(ruleComponentId) {
      return (dispatch)=>{
        dispatch({
          type: QUEUE_RULE_COMPONENT_FOR_DELETION,
          payload: {
            stateKey,
            ruleComponentId
          }
        });
      };
    },
    setEditorRuleComponentDirtiness(dirty) {
      return {
        type: SET_EDITOR_RULE_COMPONENT_DIRTINESS,
        payload: {
          stateKey,
          dirty
        }
      };
    },
    setEditorRuleComponentValidity(isValid) {
      return (dispatch)=>{
        dispatch({
          type: SET_EDITOR_RULE_COMPONENT_VALIDITY,
          payload: {
            stateKey,
            isValid
          }
        });
      };
    },
    saveRuleComponents(params) {
      return (dispatch, getState)=> {
        const savePromises = getRuleComponentsWithChanges(getState(), stateKey)
          .map((ruleComponent)=>{
            return dispatch(actionCreators.saveRuleComponent(
              ruleComponent.id, params
            ));
          });

        const deletionRuleComponents = getDeletionRuleComponents(getState(), stateKey);
        const deletionPromises = deletionRuleComponents.map((ruleComponent)=>{
          return dispatch(actionCreators.deleteRuleComponent(
            ruleComponent.id, params
          ));
        });

        return Promise.all(savePromises.concat(deletionPromises)).then((resolves) => {
          dispatch(actionCreators.replacePristineWithActive());
          dispatch(actionCreators.cleanDeletionRuleComponents());
          return resolves;
        }, (rejects) => {
          dispatch(actionCreators.replacePristineWithActive());
          dispatch(actionCreators.cleanDeletionRuleComponents());
          return Promise.reject(rejects);
        });
      };
    },
    saveRuleComponent(ruleComponentId, params) {
      return (dispatch)=> {
        const promise = dispatch(
          actionCreators.saveRuleComponentApiCall(ruleComponentId, params)
        ).then(({res})=>{
          if (res.ok) {
            dispatch(actionCreators.updateActiveRuleComponent(
              ruleComponentId,
              res.body.data
            ));
          } else {
            throw new Error('An error was encountered while saving.');
          }
        });

        promise.catch(()=>{
          dispatch(actionCreators.setRuleComponentError(
            ruleComponentId,
            'An error was encountered while saving.'
          ));
        });

        return promise;
      };
    },
    saveRuleComponentApiCall(ruleComponentId, params) {
      return (dispatch, getState) => {
        let ruleComponent = getActiveRuleComponentById(getState(), stateKey, ruleComponentId);
        let isNew = Boolean(ruleComponent.id.match(NEW_RESOURCE_ID_REGEX));
        let urlData = {...params};
        urlData.ruleComponent = !isNew ? ruleComponent.id : undefined;

        ruleComponent = ruleComponent.merge({
          type: 'rule_components',
          attributes: {
            order: ruleComponent.attributes.order || 0,
            revision: false
          },
          relationships: {
            rules: {
              data:[{
                id: params.rule,
                type: 'rules'
              }]
            }
          }
        }, { deep: true });

        return dispatch(apiActions.apiAction({
          name: (isNew ? 'create' : 'update') + 'RuleComponent',
          stateKey,
          urlData: urlData,
          data: {data: {
            id: ruleComponent.id,
            type: ruleComponent.type,
            attributes: ruleComponent.attributes,
            relationships: ruleComponent.relationships
          }}
        }));
      };
    },
    deleteRuleComponent(ruleComponentId, params) {
      return (dispatch)=> {
        return dispatch(apiActions.apiAction({
          name: 'deleteRuleComponent',
          stateKey,
          urlData: {
            ...params,
            ruleComponent: ruleComponentId
          },
        }));
      };
    },
    setRuleComponentError(ruleComponentId, error) {
      return {
        type: SET_RULE_COMPONENT_ERROR,
        payload: {
          stateKey,
          ruleComponentId,
          error
        }
      };
    },
    cleanDeletionRuleComponents() {
      return {
        type: CLEAN_DELETION_RULE_COMPONENTS,
        payload: {
          stateKey
        }
      };
    },
    validateEditorRuleComponent(iframeValidate, iframeGetSettings) {
      return (dispatch, getState)=>{
        const state = getState();
        const ruleComponent = getEditorRuleComponent(state, stateKey);
        const storeValidity = (isValid)=>{
          dispatch(
            actionCreators.setEditorRuleComponentValidity(isValid)
          );
        };

        //if the rule component has no descriptor we should fail
        if (!ruleComponent.delegateDescriptorId) {
          storeValidity(false);
          return Promise.reject(false);
        }

        if (ruleComponent.isLoaded) {
          // If either validate or getSettings fails we need to consider it invalid because
          // both prevent us from successfully saving.
          return Promise.all([iframeValidate(), iframeGetSettings()])
            .then(() => {
              storeValidity(true);
              return Promise.resolve();
            }).catch(() => {
              storeValidity(false);
              return Promise.reject();
            });
        } else {
          storeValidity(false);
          return Promise.reject();
        }
      };
    },
    updateActiveRuleComponent(ruleComponentId, updates) {
      return {
        type: UPDATE_ACTIVE_RULE_COMPONENT,
        payload: {
          stateKey,
          ruleComponentId,
          updates
        }
      };
    },
    addActiveRuleComponent(component) {
      return {
        type: ADD_ACTIVE_RULE_COMPONENT,
        payload: {
          stateKey,
          component
        }
      };
    },
    editExistingRuleComponent(ruleComponentId) {
      return {
        type: EDIT_EXISTING_RULE_COMPONENT,
        payload: {
          stateKey,
          ruleComponentId
        }
      };
    },
    stopEditingRuleComponent() {
      return {
        type: STOP_EDITING_RULE_COMPONENT,
        payload: {
          stateKey
        }
      };
    },
    setRuleComponentLoadFailed() {
      return {
        type: SET_RULE_COMPONENT_LOAD_FAILED,
        payload: {
          stateKey
        }
      };
    },
    setRuleComponentsToRevision(newRuleComponents) {
      return (dispatch, getState)=>{
        //reset to pristine
        dispatch(actionCreators.cleanDeletionRuleComponents());
        dispatch(actionCreators.replaceActiveWithPristine());
        const currentComponents = getActiveRuleComponents(getState(), stateKey);
        Object.keys(newRuleComponents).forEach((componentType)=>{

          currentComponents[componentType].forEach((component)=>{

            const componentMatch = newRuleComponents[componentType].find((checkComponent)=>{
              return getResourceOriginId(checkComponent) === getResourceOriginId(component);
            });

            if (!componentMatch) {
              // remove components
              dispatch(actionCreators.queueRuleComponentForDeletion(component.id));
            } else {
              //update component
              dispatch(actionCreators.updateActiveRuleComponent(component.id, componentMatch.without('id')));
            }
          });

          newRuleComponents[componentType].forEach((component)=>{

            const componentMatch = currentComponents[componentType].find((checkComponent)=>{
              return getResourceOriginId(checkComponent) === getResourceOriginId(component);
            });

            if (!componentMatch) {
              // add component
              dispatch(actionCreators.addActiveRuleComponent(component));
            }
          });

        });
      };
    }
  };
  return actionCreators;
};
