/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import { getCurrentRouteFromState, getLastRouteFromState, getCurrentRouteParamsFromState } from '../../routes/routeSelectors';
import { getRights } from '../rights/rightsSelectors';
import { isAnyLibraryEditRoute } from '../properties/publishing/publishingUtils';

export function getNotesTypeFromRoute(route) {
  // deepest nested routes should be selected first because
  // notes initialize using the current route
  if (route?.name === 'editProperty') {return 'propertyNotes';}
  if (route?.name === 'editRule') {return 'ruleNotes';}
  if (route?.name === 'editRuleEditRuleComponent') {return 'ruleComponentNotes';}
  if (route?.name === 'editDataElement') {return 'dataElementNotes';}
  if (route?.name === 'editSecret') {return 'secretNotes';}
  if (route?.name === 'editExtension' || route?.name === 'installExtension') {return 'extensionNotes';}
  if (isAnyLibraryEditRoute(route)) {return 'libraryNotes';}
}

export function getIsNewResourceFromState(state) {
  // installExtension params are different than all the rest
  // '/install` isn't a param so we just have to check the URL for it
  const urlContainsExtensionPackageInstall = window.location.pathname.match(/extensions\/.+\/install$/i);
  if (urlContainsExtensionPackageInstall) {
    return true;
  } else {
    const params = getCurrentRouteParamsFromState(state);
    const notesType = getNotesTypeSingularByCurrentRouteFromState(state);
    return notesType && params[notesType.replace('Note', '')]?.indexOf('new') >= 0;
  }
}

export function getNotesTypeByCurrentRouteFromState(state) {
  const route = getCurrentRouteFromState(state);
  return getNotesTypeFromRoute(route);
}

export function getNotesTypeSingularByCurrentRouteFromState(state) {
  return getNotesTypeByCurrentRouteFromState(state)?.replace(/s$/, '');
}

export function getNotesGetListEndpoint(notesType) {
  return notesType && 'get' + notesType.charAt(0).toUpperCase() + notesType.slice(1);
}

export function getNotesCreateEndpoint(notesTypeSingular) {
  return notesTypeSingular && 'create' + notesTypeSingular.charAt(0).toUpperCase() + notesTypeSingular.slice(1);
}

export function getNotesEndpointsFromNotesTypes(notesType, notesTypeSingular) {
  return {
    getList: getNotesGetListEndpoint(notesType),
    create: getNotesCreateEndpoint(notesTypeSingular)
  };
}

export function getNotesEndpointsByCurrentRoute(state) {
  const notesType = getNotesTypeByCurrentRouteFromState(state);
  const notesTypeSingular = getNotesTypeSingularByCurrentRouteFromState(state);
  return (
    notesType &&
    notesTypeSingular &&
    getNotesEndpointsFromNotesTypes(notesType, notesTypeSingular)
  );
}

export function getNotesGetListEndpointByLastRoute(state) {
  const route = getLastRouteFromState(state);
  const notesType = getNotesTypeFromRoute(route);
  return getNotesGetListEndpoint(notesType);
}

export function getNotesFromState(state) {
  const notesType = getNotesTypeByCurrentRouteFromState(state);
  return state?.api?.[notesType]?.[notesType];
}
export function getNotesTotalCountFromState(state) {
  const notesType = getNotesTypeByCurrentRouteFromState(state);
  const notesEndpoint = 'get' + notesType?.charAt(0).toUpperCase() + notesType?.slice(1);
  return state?.api?.[notesType]?.endpoints?.[notesEndpoint]?.pagination?.totalCount;
}
export function getIsNotesEditableFromState(state) {
  const notesType = getNotesTypeByCurrentRouteFromState(state);
  const rights = getRights(state);
  const propertyPlatform = state.api.property?.attributes?.platform;
  return notesType && (
    (notesType.match(/property/gi) && rights?.[propertyPlatform]?.manageProperties) ||
    (notesType.match(/rule/gi) && rights.manageRules) || // includes ruleComponents
    (notesType.match(/dataElement/gi) && rights.manageDataElements) ||
    (notesType.match(/extension/gi) && rights.manageExtensions) ||
    (notesType.match(/library/gi) && rights.manageDevLibraries) ||
    (notesType.match(/secret/gi) && rights.manageEnvironments)
  );
}

export function getCurrentNewNoteTextFromState(state) {
  return state?.notes?.newNoteText;
}
