/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/


import {get} from 'lodash-es';
import {
  libraryTypes,
  publishingMessages,
  environmentStates,
  PUBLISHING_KEYS,
} from './publishingActions';
import {
  getLibraryEnvironment,
  libraryHasUpstreamChanges
} from './publishingUtils';
import Immutable from 'seamless-immutable';
import { LIBRARY_EDIT_KEY } from './publishingActions';

export function getEnvironmentsWithAvailability(state) {
  const environments = state.api.environments || [];
  const currentLibrary = state.api.library || {};

  // return a new copy of environments with availability
  // and the library using them
  return environments.map((environment)=>{
    const libraryIdUsingEnvironment = environment.relationships?.library?.data?.id;
    // always enable the current library's environment so you can
    // switch back to it after selecting something else
    const environmentInUse = libraryIdUsingEnvironment && libraryIdUsingEnvironment !== currentLibrary.id;

    return environment.merge({
      libraryId: libraryIdUsingEnvironment,
      isAvailable: !environmentInUse && environment.attributes.stage === 'development'
    });
  });

};

export function getEnvironmentsByStage(state, stage) {
  const environments = state.api.environments || [];
  return environments.filter((environment)=>{
    return environment.attributes.stage === stage;
  });
}

export function getBuildError(state, library) {
  const devExists = Boolean(getEnvironmentsByStage(state, environmentStates.DEVELOPMENT).length);
  const stagingExists = Boolean(getEnvironmentsByStage(state, environmentStates.STAGING).length);
  const productionEnvironment = getEnvironmentsByStage(state, environmentStates.PRODUCTION)[0];
  const productionExists = Boolean(productionEnvironment);
  const embedMigrationFailed = get(productionEnvironment, 'attributes.status') === 'failed';

  if (!library || !library.attributes) {
    return;
  } else if (library.attributes.state === libraryTypes.states.DEVELOPMENT && !devExists) {
    return publishingMessages.NO_DEVELOPMENT_ENVIRONMENT;
  } else if (library.attributes.state === libraryTypes.states.SUBMITTED && !stagingExists) {
    return publishingMessages.NO_STAGING_ENVIRONMENT;
  } else if (library.attributes.state === libraryTypes.states.APPROVED && !productionExists) {
    return publishingMessages.NO_PRODUCTION_ENVIRONMENT;
  } else if (library.attributes.state === libraryTypes.states.APPROVED && embedMigrationFailed) {
    return publishingMessages.ENVIRONMENT_MIGRATION_FAILED;
  } else if (!getLibraryEnvironment(library)) {
    return publishingMessages.NO_ENVIRONMENT;
  }
}

export function getPromotionErrorFromState(state, library) {
  if (!library || !library.attributes) {
    return;
  } else if (library.attributes.buildRequired
    && library.attributes.state !== libraryTypes.states.APPROVED) {
    return publishingMessages.BUILD_REQUIRED;
  } else if (libraryHasUpstreamChanges(library, getAllDevAndNonDevLibrariesFromState(state))) {
    return publishingMessages.UPSTREAM_CHANGES;
  }
};

export function getLibraryResourcesFromState(state) {
  return [
    ...(state.api.libraryRuleResources || []),
    ...(state.api.libraryDataElementResources || []),
    ...(state.api.libraryExtensionResources || []),
  ];
}

export function getDevelopmentLibrariesFromState(state) {
  return state?.api?.[PUBLISHING_KEYS.DEVELOPMENT]?.libraries;
}

export function getDevelopmentLibraryFromState(state, libraryId) {
  return state?.api?.[PUBLISHING_KEYS.DEVELOPMENT]?.libraries?.find(library => library.id === libraryId);
}

export function isPollingDevelopmentLibrariesFromState(state) {
  return Boolean(state?.polling[PUBLISHING_KEYS.DEVELOPMENT]?.isPolling);
}

export function getNonDevelopmentLibrariesFromState(state) {
  return state?.api?.[PUBLISHING_KEYS.NON_DEVELOPMENT]?.libraries;
}

export function getIncludedForNonDevelopmentLibrariesFromState(state) {
  return state?.api?.[PUBLISHING_KEYS.NON_DEVELOPMENT]?.endpoints?.['getLibraries']?.included;
}

export function isPollingNonDevelopmentLibrariesFromState(state) {
  return Boolean(state?.polling[PUBLISHING_KEYS.NON_DEVELOPMENT]?.isPolling);
}

export function getAllDevAndNonDevLibrariesFromState(state) {
  const devLibraries = getDevelopmentLibrariesFromState(state);
  const nonDevLibraries = getNonDevelopmentLibrariesFromState(state);

  // keep the API consistent between this function and the individual get calls
  // that have nothing in state.
  if (!devLibraries && !nonDevLibraries) {
    return undefined;
  }

  return (devLibraries || Immutable([])).concat(nonDevLibraries || Immutable([]));
}

export function getExpandedLibraryResourceFromState(state) {
  return state.expanded[LIBRARY_EDIT_KEY]?.expandedItems?.[0];
}
