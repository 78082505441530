/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/
import { cloneDeep } from 'lodash-es';
import actionsHandler from '../../../redux/actionsHandler';
import {actionCreators as apiActions} from '../../../utils/api/apiActions';
import {
  CREATE_HOST_API_NAME,
  DEFAULT_HOST_DATA,
  EDGE_HOST_TYPE_CLOUDFLARE_WORKER
} from '../hosts/hostEditActions';
import {
  CREATE_ENVIRONMENT_API_NAME,
  DEFAULT_DEVELOPMENT_ENVIRONMENT,
  DEFAULT_STAGING_ENVIRONMENT,
  DEFAULT_PRODUCTION_ENVIRONMENT
} from '../environments/environmentEditActions';
import { buildSaveData as buildEnvironmentSaveData } from '../environments/environmentEditHelpers';
import {actionCreators as mobileExtensionActions} from '../mobilePropertyActions';
import Immutable from 'seamless-immutable';
import { MOBILE_PROPERTY, EDGE_PROPERTY } from '../propertyTypes';

//Reducers
export default actionsHandler({
  default(state = Immutable({})) {
    return state;
  }
});

//Action Creators
export let actionCreators = {
  createDefaultHost(params, platform) {
    const data = cloneDeep(DEFAULT_HOST_DATA);
    if (platform === EDGE_PROPERTY) {
      data.data.attributes.type_of = EDGE_HOST_TYPE_CLOUDFLARE_WORKER;
    }

    return apiActions.apiAction({
      name: CREATE_HOST_API_NAME,
      urlData: { host: 'new', ...params},
      data
    });
  },
  createDefaultEnvironment(params, data) {
    return apiActions.apiAction({
      name: CREATE_ENVIRONMENT_API_NAME,
      urlData: {...params},
      data
    });
  },
  createProperty(params, requestData, createDefaultExtensions = true, abortSignal) {
    return (dispatch, getState)=>{
      if (requestData.attributes.platform === EDGE_PROPERTY) {
        delete requestData.attributes.domains;
      }
      return dispatch(apiActions.apiAction({
        name: 'createProperty',
        urlData: {...params},
        data: {
          data: {...requestData}
        }
      }, abortSignal)).then(()=>{
        const property = getState().api.property;
        const paramsWithProperty = { ...params, property: property.id };

        dispatch(actionCreators.createDefaultHost(paramsWithProperty, requestData.attributes.platform)).then(() => {
          const host = getState().api.host;

          if (host && host.id) {
            dispatch(actionCreators.createDefaultEnvironment(
              { environment: 'new', ...paramsWithProperty }, buildEnvironmentSaveData(DEFAULT_DEVELOPMENT_ENVIRONMENT, host)
            ));
            dispatch(actionCreators.createDefaultEnvironment(
              { environment: 'new', ...paramsWithProperty }, buildEnvironmentSaveData(DEFAULT_STAGING_ENVIRONMENT, host)
            ));
            dispatch(actionCreators.createDefaultEnvironment(
              { environment: 'new', ...paramsWithProperty }, buildEnvironmentSaveData(DEFAULT_PRODUCTION_ENVIRONMENT, host)
            ));

            if (createDefaultExtensions && property.attributes.platform === MOBILE_PROPERTY) {
              dispatch(mobileExtensionActions.createDefaultExtensions(paramsWithProperty));
            }
          }
        });
      });
    };
  },
  updateProperty(params, requestData) {
    return (dispatch) =>{
      return dispatch(apiActions.apiAction({
        name: 'updateProperty',
        urlData:{ ...params },
        data: {
          data: {
            id: params.property,
            ...requestData
          }
        }
      }));
    };
  }
};
