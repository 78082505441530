/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import {actionCreators as apiActions} from '../../utils/api/apiActions';
import {getApiData} from '../../utils/api/apiTools';
import {actionCreators as extensionEditActions} from './extensions/extensionEditActions';
import {buildSaveData as buildExtensionSaveData} from './extensions/extensionEditHelpers';
import { MOBILE_PROPERTY } from './propertyTypes';

export const STATE_KEY = 'mobileProperty';

const DEFAULT_EXTENSIONS_MAP = {
  CORE: 'core',
  PROFILE: 'profile'
};

const DEFAULT_EXTENSION_NAMES = Object.values(DEFAULT_EXTENSIONS_MAP);

//Action Creators
export let actionCreators = {
  createDefaultExtensions(params) {
    return (dispatch, getState) => {
      const property = getState().api.property;
      dispatch(apiActions.apiAction({
        name: 'getExtensionPackages',
        stateKey: STATE_KEY,
        urlData: params,
        urlParams: {
          'filter[name]': DEFAULT_EXTENSION_NAMES.map(name => `EQ ${name}`).join(','),
          'filter[platform]': `EQ ${MOBILE_PROPERTY}`,
          'max_availability': property.attributes.development ? 'development' : 'public'
        }
      })).then(() => {
        const extensionPackages = getApiData(getState(), STATE_KEY).extensionPackages;

        Object.keys(extensionPackages).forEach((extensionPackageId) => {
          const extensionPackage = extensionPackages[extensionPackageId];
          const extensionName = extensionPackage.attributes.name;
          const extensionParams = {
            extensionPackage: extensionPackage.id,
            ...params
          };
          let extensionData = buildExtensionSaveData(extensionPackage);
          let extensionSettings;

          switch (extensionName) {
            case DEFAULT_EXTENSIONS_MAP.CORE:
              extensionSettings = {
                'lifecycle.sessionTimeout': 300,
                'experienceCloud.org': getState().ims.profile.attributes.activeOrg
              };
              break;
            default:
              extensionSettings = {};
          }

          extensionData = extensionData.setIn(['attributes', 'settings'], JSON.stringify(extensionSettings));

          dispatch(extensionEditActions.createExtension(extensionParams, extensionData));
        });
      });
    };
  }
};
