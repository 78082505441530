/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/


import {getUID} from './dataUtils';
import {hasUserRefusedDiagnosticTracking} from '../components/layouts/baseLayout/privacyUtils';
import { getLocalStorageItem, setLocalStorageItem } from './storageUtils';

const LOGROCKET_USER_ID = 'logrocketUserId';
const CONSENT_GIVEN_FOR_LOGROCKET = 'consentGivenForLogrocket';


let logRocketUserID;
let isLogrocketIdentitySetup = false;

let logRocketLoader = Promise.resolve([
  {default:{
    init:()=>{},
    identify:()=>{},
    reduxMiddleware:()=>{}
  }},
  {default:()=>{}}
]);

// For future reference we can host the async loaded js file. But it would probably include
// some sort of script to keep it up to date. This is how we would do it.
// If a blank cdn is provided this would not load the external script.
//const LogRocket = require('logrocket/setup')({ sdkServer: 'https://cdn.adobe.com' })
export async function initLogRocket() {
  // ENABLE_LOGROCKET
  if (
    hasUserRefusedDiagnosticTracking() ||
    window.environmentSettings.lens_environmentName === 'local'
  ) {
    // do nothing
  } else {
    logRocketUserID = getLocalStorageItem(LOGROCKET_USER_ID) || getAndSetupNewUserID();

    logRocketLoader = Promise.all([
      import(/* webpackChunkName: "logrocket" */ 'logrocket'),
      import(/* webpackChunkName: "logrocket" */ 'logrocket-react')
    ]);
  }


  const [{default:LogRocket}, {default:setupLogRocketReact}] = await logRocketLoader;
  LogRocket.init('3purdz/lens',{
    network: {
      requestSanitizer: function(request) {
        request.body = null;
        if (request.headers['authorization']) {
          request.headers['authorization'] = '';
          request.headers['x-gw-ims-org-id'] = '';
        }

        if (request.url) {
          // remove all requests that are not to the api
          const apiBaseUrl = window.environmentSettings.api_url;
          if (request.url.indexOf(apiBaseUrl) !== 0) {
            return null;
          }
          request.url = request.url.split('?')[0];
        }

        // we remove any query params so that any user input in a filter gets omitted
        if (request.referrer) {
          request.referrer = request.referrer.split('?')[0];
        }

        request.credentials = null;

        return request;
      },
      responseSanitizer: function() {
        // This is the simple way to handle it.
        // We would need to do a ton of looking around to properly sanitize the data.
        // This will at least give us the timing of the api calls. Logrocket records
        // when requests happen and the urls. So this just strips the responses of any data.
        return null;
      },
    },
    dom: {
      // this stops all document level requests from getting recorded such as script tags and images
      // this is mostly to hide analytics beacons
      shouldRecordPerf: false,
    }
  });
  setupLogRocketReact(LogRocket);
  setupLogRocketIdentity();
}

function getAndSetupNewUserID() {
  const newUserID = getUID();
  setLocalStorageItem(LOGROCKET_USER_ID, newUserID);
  return newUserID;
}


export function setUserConsent(answer) {
  setLocalStorageItem(CONSENT_GIVEN_FOR_LOGROCKET, answer);
}

export async function setupLogRocketIdentity() {
  const [{default:LogRocket}] = await logRocketLoader;
  if (!isLogrocketIdentitySetup) {
    isLogrocketIdentitySetup = true;
    return LogRocket.identify(logRocketUserID);
  }
}

export async function addLogRocketMiddleware(middlewares) {
  const [{default:LogRocket}] = await logRocketLoader;
  middlewares.push(LogRocket.reduxMiddleware({
    stateSanitizer: function() {
      return {};
    },
    actionSanitizer: function(action) {
      return {type: action.type};
    },
  }));
}

export async function getLogRocketSessionURL() {
  const [{default:LogRocket}] = await logRocketLoader;
  return LogRocket.sessionURL;
}


