/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe Systems Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe Systems Incorporated and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Adobe Systems Incorporated and its
 * suppliers and are protected by all applicable intellectual property
 * laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe Systems Incorporated.
 **************************************************************************/

import actionsHandler from '../../redux/actionsHandler';
import Immutable from 'seamless-immutable';

export const SET_IS_COPYING = 'clipboard/SET_IS_COPYING';
export const CANCEL_COPY = 'clipboard/CANCEL_COPY';

const defaultState = Immutable({});

export default actionsHandler({
  [SET_IS_COPYING](state, { payload: { stateKey, key, timeoutId }}) {
    return state.set(stateKey, { key, timeoutId });
  },
  [CANCEL_COPY](state, { payload: { stateKey }}) {
    return state.set(stateKey, {});
  },
  default: (state = defaultState) => {
    return state;
  }
});

export const getActionCreators = (stateKey) => ({
  setIsCopying({ key, timeMillis = 2000 }) {
    return dispatch => {
      const clipboardActions = getActionCreators(stateKey);
      // cancel anything currently happening
      dispatch(clipboardActions.cancelCopy());

      const timeoutId = setTimeout(() => {
        // cancel in timeout ms
        dispatch(clipboardActions.cancelCopy());
      }, timeMillis);

      dispatch({
        type: SET_IS_COPYING,
        payload: {
          stateKey,
          key,
          timeoutId
        }
      });
    };
  },
  cancelCopy() {
    return (dispatch, getState) => {
      const state = getState();
      if (state.clipboard[stateKey]?.timeoutId) {
        clearTimeout(state.clipboard[stateKey].timeoutId);
        dispatch({
          type: CANCEL_COPY,
          payload: {
            stateKey
          }
        });
      }
    };
  }
});
