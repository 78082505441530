import { keyBy } from 'lodash-es';

export function getAllSearchResultsForStateKeyByResourceTypeFromState(stateKey, state) {
  // isSingularQueryEndpoint
  if (Array.isArray(state.api?.[stateKey]?.elasticSearch?.data)) {
    return keyBy(state.api[stateKey].elasticSearch.data, 'type');
  }

  // isUsingGroupKeyData. After data was aggregated by type, we lost the match score
  // relevancy across types.
  return {
    ...state.api[stateKey].elasticSearch
  };
}

export function getIsSearchLoadingForStateKeyFromState(stateKey, state) {
  return state?.elasticSearch?.[stateKey]?.loading;
}

/**
 * Using a scopList, pluck and display the data in the order as the scopes used.
 *
 * Returns a structure exactly like an Object.entries call.
 */
export function getOrderedSearchResultsFromState(stateKey, resourceTypes, state) {
  const dataByType = getAllSearchResultsForStateKeyByResourceTypeFromState(stateKey, state);

  return resourceTypes.reduce((results, resourceType) => {
    // Guard against missing results by type
    if (dataByType[resourceType].data.length) {
      results.push([resourceType, dataByType[resourceType]]);
      return results;
    }

    return results;
  }, []);
}

/**
 * The length that the search must be before a query will hit the API.
 */
export function getRequiredTermCountForSearch(state) {
  return state.elasticSearch.requiredCharacterCountForSearch;
}
