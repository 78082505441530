/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import {uniq, uniqBy, snakeCase} from 'lodash-es';
import Immutable from 'seamless-immutable';
import pQueue from 'p-queue';

import {CONTAINS} from '../../utils/sortFilterQueryParamsUtils';
import {
  DATA_ELEMENTS,
  EXTENSIONS,
  EXTENSION_PACKAGES,
  RULES,
  RULE_COMPONENTS
} from '../../utils/api/apiTypes';
import {actionCreators as apiActions} from '../../utils/api/apiActions';
import {apiItemLinksToIds} from '../../utils/api/apiNormalizers';
import {
  buildRelationships,
  deriveCopyName,
  getResourceNameInfo,
  getResourcePreparedForSave,
  getSingularResourceTypeFromId,
  getApiMappingFromId
} from '../../utils/resourceUtils';
import {filterListFromList} from '../../utils/dataUtils';
import {
  getApiData,
  getApiMappingName,
} from '../../utils/api/apiTools';
import {getResourceCopy} from './ResourceCopySelectors';
import {
  isNewerVersionOfSameExtension,
  isOlderOrSameVersionOfSameExtension
} from '../properties/extensions/extensionsUtils';
import actionsHandler from '../../redux/actionsHandler';
import { getActionCreators as getPaginationActions } from '../pagination/paginationActions';
import { getCurrentRouteParamsFromState } from '../../routes/routeSelectors';
import { ENDPOINT_GROUP_KEYS } from '../../utils/api/apiMappingsUtils';

export const SET_DIALOG_OPEN = 'resourceCopy/SET_DIALOG_OPEN';
export const INITIALIZE = 'resourceCopy/INITIALIZE';
export const SET_DESTINATION_PROPERTY = 'resourceCopy/SET_DESTINATION_PROPERTY';
export const SET_COPY_EXTENSION_SETTINGS = 'resourceCopy/SET_COPY_EXTENSION_SETTINGS';
export const SET_COPY_STATUS = 'resourceCopy/SET_COPY_STATUS';
export const SET_RESOURCES = 'resourceCopy/SET_RESOURCES';
export const SET_RESOURCES_DATA = 'resourceCopy/SET_RESOURCES_DATA';
export const SET_COPIED_RESOURCES = 'resourceCopy/SET_COPIED_RESOURCES';
export const SET_FAILED_RESOURCES = 'resourceCopy/SET_FAILED_RESOURCES';


export const COPY_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  EXTENSIONS_FAILED: 'EXTENSIONS_FAILED',
  PROPERTY_DEVELOPMENT_STATUS_DIFFERS: 'PROPERTY_DEVELOPMENT_STATUS_DIFFERS',
  PROPERTY_PLATFORM_DIFFERS: 'PROPERTY_PLATFORM_DIFFERS'
};
export const EXTENSIONS_FAILED_REASON = {
  NEWER_VERSION_ON_SOURCE_PROPERTY: 'NEWER_VERSION_ON_SOURCE_PROPERTY',
  GENERIC_ERROR: 'GENERIC_ERROR',
  DISCONTINUED_EXTENSION: 'DISCONTINUED_EXTENSION'
};

export const RESOURCE_COPY_KEYS = {
  PROPERTIES: 'resourceCopyProperties',
};

let abortController;

const defaultState = Immutable({
  dialogOpen: false,
  destinationProperty: null,
  copyExtensionSettings: false,
  copyStatus: COPY_STATUS.NOT_STARTED,
  resources: [],
  resourcesData: [],
  copiedResourcesData: [],
  failedResourcesData: []
});

// Reducers
export default actionsHandler({
  [SET_DIALOG_OPEN]: (state, action) => {
    return state.set('dialogOpen', action.payload.isOpen);
  },
  [INITIALIZE]: (state, {defaultDestinationProperty}) => {
    return {
      ...defaultState,
      destinationProperty: defaultDestinationProperty
    };
  },
  [SET_DESTINATION_PROPERTY]: (state, action) => {
    return state.set('destinationProperty', action.payload.destinationProperty);
  },
  [SET_COPY_EXTENSION_SETTINGS]: (state, action) => {
    return state.set('copyExtensionSettings', action.payload.copyExtensionSettings);
  },
  [SET_COPY_STATUS]: (state, {payload}) => {
    return state.set('copyStatus', payload.copyStatus);
  },
  [SET_RESOURCES]: (state, {payload}) => {
    return state.set('resources', payload.resources);
  },
  [SET_RESOURCES_DATA]: (state, {payload}) => {
    return state.set('resourcesData', payload.resourcesData);
  },
  [SET_COPIED_RESOURCES]: (state, {payload}) => {
    return state.set('copiedResourcesData', payload.copiedResourcesData);
  },
  [SET_FAILED_RESOURCES]: (state, {payload}) => {
    return state.set('failedResourcesData', payload.failedResourcesData);
  },
  default: (state = defaultState) => {
    return state;
  }
});

export const STATE_KEY = 'resourceCopy';
export const RESOURCE_COPY_PROPERTIES_STATE_KEY = 'resourceCopyPropertyList';
// Action Creators
export let actionCreators = {
  initializePropertyPagination() {
    return (dispatch, getState) => {
      const paginationActions = getPaginationActions(RESOURCE_COPY_PROPERTIES_STATE_KEY);

      dispatch(paginationActions.initialize({
        pageSize: 30,
        forcedParams: getCurrentRouteParamsFromState(getState()),
        endpointGroupKey: ENDPOINT_GROUP_KEYS.PROPERTIES,
        shouldMergeResults: true,
        queryPage: 1,
        useHistory: false
      }));
      dispatch(apiActions.manuallySetData(['api', STATE_KEY, RESOURCE_COPY_KEYS.PROPERTIES], []));
    };
  },
  initAbortController() {
    return () => { abortController = new AbortController(); };
  },
  cancelRequests() {
    return () => { abortController.abort(); };
  },
  loadProperties({
    searchTerm,
    needsNextPageForTerm = false, // Typically used to show the search isn't ready yet
  }) {
    return async(dispatch, getState) => {
      if (
        Number(getState()?.pagination?.property?.currentPage) >= Number(getState()?.pagination?.property?.totalPages)
      ) {
        return;
      }

      dispatch(actionCreators.cancelRequests());
      dispatch(actionCreators.initAbortController());

      const paginationActions = getPaginationActions(RESOURCE_COPY_PROPERTIES_STATE_KEY);

      if (!needsNextPageForTerm) {
        // flag we're doing an initial load of some properties. Clear the caching.
        dispatch(paginationActions.setPendingQueryPage(1));
      } else {
        dispatch(paginationActions.incrementPendingQueryPage());
      }

      if (searchTerm) {
        dispatch(paginationActions.setPendingQueryFilter('name', searchTerm, 'CONTAINS'));
      } else {
        dispatch(paginationActions.clearPendingQueryFilter());
      }

      // fetch for the next page of properties
      await dispatch(paginationActions.loadPaginationPage({ abortController }));
    };
  },
  setDialogOpen(isOpen) {
    return {
      type: SET_DIALOG_OPEN,
      payload: {isOpen}
    };
  },
  initialize(defaultDestinationProperty) {
    return {type: INITIALIZE, defaultDestinationProperty};
  },
  setDestinationProperty(destinationProperty) {
    return {
      type: SET_DESTINATION_PROPERTY,
      payload: {destinationProperty}
    };
  },
  setCopyExtensionSettings(copyExtensionSettings) {
    return {
      type: SET_COPY_EXTENSION_SETTINGS,
      payload: {copyExtensionSettings}
    };
  },
  copyResources(params, resources, onComplete) {
    return (dispatch, getState) => {
      dispatch(actionCreators.setResources(resources));
      dispatch(actionCreators.setCopyStatus(COPY_STATUS.IN_PROGRESS));

      const destinationProperty = getResourceCopy(getState()).destinationProperty;

      // pull the type from the first resource
      const type = resources[0].type;
      const typeSingular = getSingularResourceTypeFromId(resources[0].id);

      // if we are copying an extension, then the extensions settings are required
      // so we force the value here
      if (type === EXTENSIONS) {
        dispatch(actionCreators.setCopyExtensionSettings(true));
      }

      let loadingPromises = [];
      let sourcePropertyExtensions = [];
      let destinationPropertyExtensions = [];

      // get extensions on source property
      loadingPromises.push(
        dispatch(actionCreators.loadExtensions({
          params,
          bypassError: true,
          abortController
        })).then(extensions=>{
          sourcePropertyExtensions = extensions;
        })
      );
      // get extensions on destination property
      const destinationPropertyParams = destinationProperty ? {
        ...params, property: destinationProperty.id
      } : {
        ...params
      };
      loadingPromises.push(
        dispatch(actionCreators.loadExtensions({
          params: destinationPropertyParams,
          bypassError: true,
          abortController
        })).then(extensions=>{
          destinationPropertyExtensions = extensions;
        })
      );

      return Promise.all(loadingPromises).then(()=>{
        return dispatch(actionCreators.getExtendedResourcesData({
          params,
          resources,
          extensions: sourcePropertyExtensions
        })).then((extendedResourcesData)=>{
          return dispatch(actionCreators.copyResourceExtensionsToDestinationProperty(
            params, extendedResourcesData, destinationPropertyExtensions
          ));
        }).then((copiedExtensions)=>{
          // We are not storing copiedExtensions on state unless you are only copying extensions.
          // This is because we don't need them for any additional rendering or decisioning accept right here.

          // Extensions on the destination may have changed during copyResourceExtensionsToDestinationProperty
          // so we'll make sure we have the latest local data.
          destinationPropertyExtensions = filterListFromList(
            destinationPropertyExtensions, copiedExtensions, 'attributes.name'
          ).concat(copiedExtensions);

          const resourceCopyState = getResourceCopy(getState());

          const extendedResourcesData = resourceCopyState.resourcesData;

          // In order to ensure that our new names are unique, we retrieve all existing resources
          // that contain the base name of the resources being copied. As an example, the base name
          // of "foo copy 3" would be "foo". We then use these existing resource names to properly
          // derive new, unique names for the newly created resources.
          const baseNames = uniq(extendedResourcesData.map((resourceDataItem) => {
            return getResourceNameInfo(resourceDataItem.resource.attributes.name).baseName;
          })).join(',');

          // because extensions can't be manually named by the user we only need to do a reserved name
          // check for rules & data elements
          const duplicateNameCheckStateKey = 'duplicateNameResources';
          const duplicateNamesPromise = type === EXTENSIONS ? Promise.resolve() : dispatch(apiActions.apiAction({
            name: getApiMappingName(typeSingular, 'get'),
            urlData: {...destinationPropertyParams},
            urlParams: {
              'filter[revision_number]': 'EQ 0',
              // Optimally, we would use STARTS WITH to filter down to existing data elements that
              // start with our base names, but STARTS WITH doesn't exist at the moment.
              // CONTAINS still works, though we'll just be dealing with a bigger dataset.
              'filter[name]': CONTAINS + ' ' + baseNames
            },
            stateKey: duplicateNameCheckStateKey
          }));

          return duplicateNamesPromise.then(()=>{
            let reservedNameResources = (
              type === EXTENSIONS ? [] : getApiData(getState(), duplicateNameCheckStateKey)[type]
            );
            let reservedNames = (
              type === EXTENSIONS
            ) ? [] : reservedNameResources.map(existingResource => existingResource.attributes.name);

            const resourceCopyQueue = new pQueue({concurrency: 3});

            extendedResourcesData.forEach((resourceDataItem, resourceDataItemIndex)=>{
              const {resource} = resourceDataItem;

              resourceCopyQueue.add(()=>{
                // define success/failure handlers early because we'll need them at different places based on the
                // current copy status (copying/cancelled)
                const resourceSuccessHandler = (result)=>{
                  const copiedResourcesData = getResourceCopy(getState()).copiedResourcesData.concat(resourceDataItem);
                  dispatch(actionCreators.setCopiedResources(copiedResourcesData));
                  return result;
                };
                const resourceErrorHandler = (error)=>{
                  const failedResourcesData = getResourceCopy(getState()).failedResourcesData.concat({
                    ...resourceDataItem,
                    error: error.message
                  });
                  dispatch(actionCreators.setFailedResources(failedResourcesData));
                  throw error;
                };

                // before copying each resource, we'll check if the user has cancelled
                if (getResourceCopy(getState()).copyStatus === COPY_STATUS.CANCELLED) {
                  return resourceErrorHandler({message: 'User cancelled'});
                }

                // update copyStarted status
                const latestExtendedResourcesData = getResourceCopy(getState()).resourcesData;
                const extendedResourcesDataWithUpdatedStatus = latestExtendedResourcesData.set(resourceDataItemIndex, {
                  ...resourceDataItem,
                  copyStarted: true
                });
                dispatch(actionCreators.setResourcesData(extendedResourcesDataWithUpdatedStatus));


                let currentResourceCopyPromise;

                // extensions will have already been copied
                if (type === EXTENSIONS) {
                  currentResourceCopyPromise = Promise.resolve('already copied');
                } else {
                  // handle rules and data elements
                  const newName = deriveCopyName(resource.attributes.name, reservedNames);
                  reservedNames = [...reservedNames, newName];

                  if (type === DATA_ELEMENTS) {
                    currentResourceCopyPromise = dispatch(actionCreators.copyDataElementToDestinationProperty({
                      sourcePropertyParams: params,
                      destinationPropertyParams,
                      destinationPropertyExtensions,
                      dataElementData: resourceDataItem,
                      newCopyName: newName
                    }));
                  } else if (type === RULES) {
                    currentResourceCopyPromise = dispatch(actionCreators.copyRuleToDestinationProperty({
                      sourcePropertyParams: params,
                      destinationPropertyParams,
                      destinationPropertyExtensions,
                      ruleData: resourceDataItem,
                      newCopyName: newName
                    }));
                  }
                }

                // pQueue doesn't return promise results on completion so each
                // promise needs to handle it's own then() and catch() cases
                return currentResourceCopyPromise.then(resourceSuccessHandler).catch(resourceErrorHandler);
              });
            });

            return resourceCopyQueue.onIdle();
          }).then(()=>{
            dispatch(actionCreators.setCopyStatus(COPY_STATUS.COMPLETED));
            return onComplete ? onComplete() : true;
          }).catch(()=>{
            dispatch(actionCreators.setCopyStatus(COPY_STATUS.COMPLETED));
            return onComplete ? onComplete() : false;
          });
        }).catch((error)=>{
          // copyResourceExtensionsToDestinationProperty failed
          let copyStatus = COPY_STATUS.EXTENSIONS_FAILED;
          if (error.type === COPY_STATUS.PROPERTY_DEVELOPMENT_STATUS_DIFFERS) {
            copyStatus = COPY_STATUS.PROPERTY_DEVELOPMENT_STATUS_DIFFERS;
          } else if (error.type === COPY_STATUS.PROPERTY_PLATFORM_DIFFERS) {
            copyStatus = COPY_STATUS.PROPERTY_PLATFORM_DIFFERS;
          }
          dispatch(actionCreators.setCopyStatus(copyStatus));

          const {failedResourcesData} = getResourceCopy(getState());
          const newFailedResourcesData = failedResourcesData.concat(error.resources.map(resource=>{
            return {resource, error};
          }));
          dispatch(actionCreators.setFailedResources(uniqBy(newFailedResourcesData, 'resource.id')));
          return onComplete ? onComplete() : false;
        });
      }).catch(()=>{
        // precheck or extensions failed
        dispatch(actionCreators.setCopyStatus(COPY_STATUS.EXTENSIONS_FAILED));
        return onComplete ? onComplete() : false;
      });
    };
  },
  getExtendedResourcesData({
    params = {},
    resources = [],
    extensions = [],
    bypassError = false,
    abortSignal,
    swallowAbortErrors
  }) {
    return (dispatch, getState) => {
      if (!resources.length) { return Promise.resolve([]); }

      const type = resources[0].type;
      let loadingPromises = [];

      // get resources, and their children
      let resourcesData = []; // [{resource, parentResource, requiredExtensions: [], copyStarted: false}]
      resources.forEach(resource=>{
        resourcesData.push({
          resource,
          parentResource: resource,
          requiredExtensions: [],
          copyStarted: false
        });

        if (type === RULES) {
          const parentResourceIndex = resourcesData.findIndex(item=>item.resource.id === resource.id);
          resourcesData[parentResourceIndex] = {
            ...resourcesData[parentResourceIndex],
            ruleComponents: []
          };

          const ruleComponentsStateKey = 'resourceCopyRuleComponents';
          loadingPromises.push(dispatch(apiActions.apiAction({
            name: 'getRuleComponents',
            urlData: {...params, rule: resource.id},
            stateKey: ruleComponentsStateKey,
            bypassError,
            abortSignal,
            swallowAbortErrors
          })).then(()=>{
            const ruleComponents = getApiData(getState(), ruleComponentsStateKey).ruleComponents.asMutable();
            resourcesData[parentResourceIndex] = {
              ...resourcesData[parentResourceIndex],
              ruleComponents: ruleComponents.map(ruleComponent=>{
                return {
                  resource: ruleComponent,
                  parentResource: resource,
                  requiredExtensions: []
                };
              })
            };
          }));
        }
      });

      // update resourcesData with required extensions
      return Promise.all(loadingPromises).then(()=>{
        resourcesData.forEach((resourcesDataItem)=>{
          const {resource, parentResource} = resourcesDataItem;

          // for rules, we'll handle the child ruleComponents
          // for everything else, we'll handle the parentResource
          let resources = (
            resource.type === RULES
          ) ? resourcesDataItem.ruleComponents.map(item=>item.resource) : [resource];
          resources.forEach(resource=>{
            const resourceLinks = apiItemLinksToIds(resource.links);

            // find the required extension
            const requiredExtensionOnSourceProperty = (
              resource.type === EXTENSIONS
            ) ? resource : extensions.find(
              extension=>extension.id === resourceLinks.extension
            );

            // if we didn't find the extension on the source property then it has been uninstalled
            // and we need to load it directly
            const getExtensionPromise = (
              requiredExtensionOnSourceProperty
            ) ? Promise.resolve(
              requiredExtensionOnSourceProperty
            ) : dispatch(actionCreators.loadExtension({
              params: {
                ...params,
                extension: resourceLinks.extension,
              },
              bypassError,
              abortSignal,
              swallowAbortErrors
            }));

            loadingPromises.push(getExtensionPromise);

            getExtensionPromise.then((requiredExtension)=>{
              const parentResourceIndex = resourcesData.findIndex(item=>item.resource.id === parentResource.id);
              if (resource.type === RULE_COMPONENTS) {
                resourcesData[parentResourceIndex] = {
                  ...resourcesData[parentResourceIndex],
                  requiredExtensions: uniqBy(
                    resourcesData[parentResourceIndex].requiredExtensions.concat(requiredExtension),
                    'attributes.name'
                  ),
                  ruleComponents: resourcesData[parentResourceIndex].ruleComponents.map(item=>{
                    return item.resource.id === resource.id ? {
                      ...item,
                      requiredExtensions: [requiredExtension]
                    } : item;
                  })
                };
              } else {
                resourcesData[parentResourceIndex] = {
                  ...resourcesData[parentResourceIndex],
                  requiredExtensions: uniqBy(
                    resourcesData[parentResourceIndex].requiredExtensions.concat(requiredExtension),
                    'attributes.name'
                  )
                };
              }
            });
          });
        });

        return Promise.all(loadingPromises).then(()=>{
          // return the parentResources which contain the child ruleComponents at this point
          dispatch(actionCreators.setResourcesData(resourcesData));
          return resourcesData;
        });
      });
    };
  },
  copyResourceExtensionsToDestinationProperty(
    params = {}, extendedResourcesData = [], destinationPropertyExtensions = []
  ) {
    return (dispatch, getState) => {
      const state = getState();
      const sourceProperty = getApiData(state, 'property');
      const destinationProperty = getResourceCopy(state).destinationProperty;

      // if we are copying within the same property then no extension copying is required
      const sourceAndDestinationPropertiesMatch = destinationProperty?.id === params.property;
      if (sourceAndDestinationPropertiesMatch) {
        return Promise.resolve([]);
      }

      const propertyPlatformMatches = sourceProperty.attributes.platform === destinationProperty.attributes.platform;

      // if there is no destinationProperty then it is the same as the source property in which case it is
      // safe to copy 'development' extensions
      const sourcePropertyIsDevelopment = sourceProperty.attributes.development;
      const destinationPropertyIsDevelopment = (
        destinationProperty
      ) ? destinationProperty.attributes.development : sourceProperty.attributes.developement;
      const developmentStatusMatches = destinationPropertyIsDevelopment === sourcePropertyIsDevelopment;

      let destinationPropertyParams = {...params};
      if (destinationProperty) {
        destinationPropertyParams = {...params, property: destinationProperty.id};
      }

      let newerSourcePropertyExtensions = [];
      let copiedExtensions = [];
      extendedResourcesData.forEach(resourceDataItem=>{
        resourceDataItem.requiredExtensions.forEach(requiredExtension=>{
          const extensionOnDestination = destinationPropertyExtensions.find(
            extension=>extension.attributes.name === requiredExtension.attributes.name
          );

          // if the extension is not installed on the destination then we are guaranteed to
          // get the latest version when it is installed
          const isSafeToCopyExtension = (
            !extensionOnDestination || isOlderOrSameVersionOfSameExtension(requiredExtension, extensionOnDestination)
          );

          // We cannot copy the extension in the following cases...
          // - it is installed on the destination at an older version than what is required by the source
          //     (we don't know if settings were added to the newer version)
          // - the source and destination properties `development` attribute differs
          //     (cannot copy from development to non-development or vice-versa)
          if (!isSafeToCopyExtension) {
            if (
              extensionOnDestination && isNewerVersionOfSameExtension(requiredExtension, extensionOnDestination)
            ) {
              newerSourcePropertyExtensions.push(requiredExtension);
            }
          }
        });
      });

      // combine all required extensions
      const requiredExtensions = extendedResourcesData.reduce((requiredExtensions, resourceDataItem)=>{
        return uniqBy(requiredExtensions.concat(resourceDataItem.requiredExtensions), 'attributes.name');
      }, []);

      if (!propertyPlatformMatches) {
        return Promise.reject({
          type: COPY_STATUS.PROPERTY_PLATFORM_DIFFERS,
          message: 'source property platform differs from destination property',
          resources: extendedResourcesData
        });
      } else if (!developmentStatusMatches) {
        return Promise.reject({
          type: COPY_STATUS.PROPERTY_DEVELOPMENT_STATUS_DIFFERS,
          message: 'source property development status differs from destination property',
          resources: extendedResourcesData
        });
      } else if (newerSourcePropertyExtensions.length) {
        return Promise.reject({
          type: EXTENSIONS_FAILED_REASON.NEWER_VERSION_ON_SOURCE_PROPERTY,
          message: 'newer extensions on source property',
          resources: newerSourcePropertyExtensions
        });
      } else {
        const copyExtensionSettings = getResourceCopy(getState()).copyExtensionSettings;

        return Promise.all(requiredExtensions.map(requiredExtension=>{
          const copiedExtension = copiedExtensions.find(
            extension=>extension.attributes.name === requiredExtension.attributes.name
          );
          if (!copiedExtension) {
            copiedExtensions.push(requiredExtension);

            const copyExtensionPromise = dispatch(
              actionCreators.copyExtensionToDestinationProperty({
                params: destinationPropertyParams,
                extension: requiredExtension,
                shouldCopySettings: copyExtensionSettings,
                bypassError: true
              })
            );

            return copyExtensionPromise.then((copiedExtension)=>{
              return Promise.resolve(copiedExtension);
            }).catch(error => {
              if (error.message.includes('discontinued')) {
                return Promise.reject({
                  type: EXTENSIONS_FAILED_REASON.DISCONTINUED_EXTENSION,
                  message: error.message,
                  resources: [requiredExtension]
                });
              }

              return Promise.reject({
                type: EXTENSIONS_FAILED_REASON.GENERIC_ERROR,
                message: error.message,
                resources: [requiredExtension]
              });
            });
          }
        }));
      }
    };
  },
  copyExtensionToDestinationProperty({
    params,
    extension,
    shouldCopySettings,
    abortSignal,
    swallowAbortErrors,
    bypassError
  }) {
    return (dispatch, getState) => {
      // find extension on destinationProperty
      return dispatch(actionCreators.loadExtensionByName({
        params,
        extensionName: extension.attributes.name,
        abortSignal,
        swallowAbortErrors,
        bypassError
      })).then((existingExtension)=>{
        if (existingExtension) {
          // copy extension settings PATCH

          // if the extension is already present on the destination
          // then we will only copy it if shouldCopySettings is true
          if (shouldCopySettings) {
            const extensionSaveData = {
              data: {
                ...existingExtension,
                attributes: extension.attributes
              }
            };

            return dispatch(apiActions.apiAction({
              name: 'updateExtension',
              urlData: {...params, extension: existingExtension.id},
              data: extensionSaveData,
              abortSignal,
              stateKey: STATE_KEY,
              swallowAbortErrors,
              bypassError,
              noReduxDomEventEmit: true
            })).then(()=>{
              const updatedExtension = getApiData(getState(), STATE_KEY).extension;
              return Promise.resolve(updatedExtension);
            });
          } else {
            return Promise.resolve(existingExtension);
          }
        } else {
          // install new extension

          // If an upgrade is available, we need to use the latest extension package ID
          // because you are not allowed to install an old version of an extension package
          const latestExtensionPackageId = (
            extension.meta.upgradeExtensionPackageId || extension.relationships.extensionPackage.data.id
          );
          const extensionSaveData = {
            data: {
              attributes: extension.attributes.delegateDescriptorId ? {
                ...extension.attributes
              } : {},
              type: EXTENSIONS,
              relationships: {
                extensionPackage: {
                  data: {
                    id: latestExtensionPackageId,
                    type: snakeCase(EXTENSION_PACKAGES)
                  }
                }
              }
            }
          };

          return dispatch(apiActions.apiAction({
            name: 'createExtension',
            urlData: params,
            data: extensionSaveData,
            abortSignal,
            stateKey: STATE_KEY,
            swallowAbortErrors,
            bypassError,
            noReduxDomEventEmit: true
          })).then(()=>{
            const createdExtension = getApiData(getState(), STATE_KEY).extension;
            return Promise.resolve(createdExtension);
          });
        }
      });
    };
  },
  copyDataElementToDestinationProperty({
    sourcePropertyParams,
    destinationPropertyParams,
    destinationPropertyExtensions,
    dataElementData, // see getExtendedResourcesData
    newCopyName,
    abortSignal,
    swallowAbortErrors,
    bypassError
  }) {
    return (dispatch, getState) => {
      const dataElement = dataElementData.resource;
      const newName = newCopyName || dataElement.attributes.name;

      // prep for save
      let extendDataElementWith = {
        attributes: {
          name: newName
        }
      };
      // add destination property extension if copying to a different property
      if (sourcePropertyParams.property !== destinationPropertyParams.property) {
        // use latest extension from destination
        const requiredExtensionName = dataElementData.requiredExtensions[0].attributes.name;
        const destinationExtension = destinationPropertyExtensions.find(
          extension=>extension.attributes.name === requiredExtensionName
        );

        if (!destinationExtension) {
          return Promise.reject({
            message: `The "${requiredExtensionName}" extension is required for this data element,
              but failed to copy to the destination property.`
          });
        }

        // apply latest extension relationship from destination property
        extendDataElementWith.relationships = {
          extension: buildRelationships(destinationExtension?.id)
        };
      }
      const dataElementForSave = getResourcePreparedForSave(dataElement, extendDataElementWith);

      // create the copy
      const typeSingular = getSingularResourceTypeFromId(dataElement.id);
      return dispatch(apiActions.apiAction({
        name: getApiMappingName(typeSingular, 'post'),
        urlData: { ...destinationPropertyParams },
        data: { data: dataElementForSave },
        abortSignal,
        stateKey: STATE_KEY,
        swallowAbortErrors,
        bypassError,
        noReduxDomEventEmit: true
      })).then(()=>{
        const result = getState().api[STATE_KEY][typeSingular];
        return Promise.resolve(result);
      });
    };
  },
  copyRuleToDestinationProperty({
    sourcePropertyParams,
    destinationPropertyParams,
    destinationPropertyExtensions,
    ruleData, // see getExtendedResourcesData
    newCopyName,
    abortSignal,
    swallowAbortErrors,
    bypassError
  }) {
    return (dispatch, getState) => {
      const rule = ruleData.resource;
      const newName = newCopyName || rule.attributes.name;

      // prep for save
      const extendRuleWith = {
        attributes: {
          name: newName
        }
      };
      const ruleForSave = getResourcePreparedForSave(rule, extendRuleWith);

      // create the copy
      const typeSingular = getSingularResourceTypeFromId(rule.id);
      return dispatch(apiActions.apiAction({
        name: getApiMappingName(typeSingular, 'post'),
        urlData: {...destinationPropertyParams},
        data: {data: ruleForSave},
        abortSignal,
        stateKey: STATE_KEY,
        swallowAbortErrors,
        bypassError,
        noReduxDomEventEmit: true
      })).then(()=>{
        const newRule = getState().api[STATE_KEY][typeSingular];

        // handle ruleComponents
        return dispatch(apiActions.apiAction({
          name: 'getRuleComponents',
          urlData: {...sourcePropertyParams, rule: rule.id},
          abortSignal,
          stateKey: STATE_KEY,
          swallowAbortErrors,
          bypassError
        })).then(()=>{
          const ruleComponents = getState().api[STATE_KEY].ruleComponents;

          // rule components can only be attached to exactly 1 rule which means we actually
          // have to copy them by creating new ones
          const ruleComponentCreatePromises = [];
          ruleComponents.forEach((ruleComponent)=>{
            let ruleComponentData = ruleData.ruleComponents.find(
              item=>item.resource.id === ruleComponent.id
            );

            // prep for save
            let extendRuleComponentWith = {
              relationships: {
                rules: buildRelationships([newRule.id])
              }
            };
            // add destination property extension if copying to a different property
            if (sourcePropertyParams.property !== destinationPropertyParams.property) {
              // use latest extension from destination
              const requiredExtensionName = ruleComponentData.requiredExtensions[0].attributes.name;
              const destinationExtension = destinationPropertyExtensions.find(
                extension=>extension.attributes.name === requiredExtensionName
              );

              if (!destinationExtension) {
                return Promise.reject({
                  message: `The "${requiredExtensionName}" extension is required for this rule,
                    but failed to copy to the destination property.`
                });
              }

              // apply latest extension relationship from destination property
              extendRuleComponentWith.relationships.extension = buildRelationships(destinationExtension?.id);
            }
            let ruleComponentForSave = getResourcePreparedForSave(ruleComponent, extendRuleComponentWith);

            // save each rule component to a new rule component attached to head
            // replace any existing rule components on head with this revision's ruleComponents
            ruleComponentCreatePromises.push(dispatch(apiActions.apiAction({
              name: 'createRuleComponent',
              urlData: {...destinationPropertyParams},
              data: {data: ruleComponentForSave},
              abortSignal,
              stateKey: STATE_KEY,
              swallowAbortErrors,
              bypassError,
              noReduxDomEventEmit: true
            })));
          });

          return Promise.all(ruleComponentCreatePromises).then(()=>{
            // if all rule components copy successfully then we'll return the rule as
            // our succeeded result
            return newRule;
          });
        });
      });
    };
  },
  loadExtensions({
    params,
    urlParams = {},
    loadAllPages,
    bypassError,
    abortSignal,
    swallowAbortErrors
  } = {}) {
    return (dispatch, getState) => {
      return dispatch(apiActions.apiAction({
        name: 'getExtensions',
        stateKey: STATE_KEY,
        urlData: params,
        urlParams: {...urlParams},
        loadAllPages,
        bypassError,
        abortSignal,
        swallowAbortErrors
      })).then(()=>{
        const extensions = getApiData(getState(), STATE_KEY).extensions;
        return extensions && Object.values(extensions) || [];
      });
    };
  },
  loadExtensionByName({
    params,
    extensionName,
    bypassError,
    abortController
  } = {}) {
    return (dispatch) => {
      return dispatch(actionCreators.loadExtensions({
        params,
        urlParams: {
          'filter[name]': 'EQ ' + extensionName,
          'page[size]': 1
        },
        bypassError,
        abortController
      })).then((extensions)=>{
        return extensions.length && extensions[0] || null;
      });
    };
  },
  loadExtension({
    params,
    urlParams = {},
    bypassError,
    abortController
  } = {}) {
    return (dispatch, getState) => {
      return dispatch(apiActions.apiAction({
        name: 'getExtension',
        urlData: params,
        stateKey: STATE_KEY,
        urlParams: {...urlParams},
        bypassError,
        abortController
      })).then(()=>{
        const extension = getApiData(getState(), STATE_KEY).extension;
        return extension;
      });
    };
  },
  loadResource({
    resourceId,
    params,
    urlParams = {},
    bypassError,
    abortSignal,
    swallowAbortErrors
  } = {}) {
    return (dispatch, getState) => {
      const singularType = getSingularResourceTypeFromId(resourceId);
      return dispatch(apiActions.apiAction({
        name: getApiMappingFromId(resourceId),
        urlData: {...params, [singularType]: resourceId},
        urlParams: {...urlParams},
        stateKey: STATE_KEY,
        bypassError,
        abortSignal,
        swallowAbortErrors
      })).then(()=>{
        const resource = getApiData(getState(), STATE_KEY)[singularType];
        return resource;
      });
    };
  },
  cancelCopying() {
    return (dispatch) => {
      return dispatch(actionCreators.setCopyStatus(COPY_STATUS.CANCELLED));
    };
  },
  setCopyStatus(copyStatus) {
    return {type: SET_COPY_STATUS, payload: {copyStatus}};
  },
  setResources(resources) {
    return {type: SET_RESOURCES, payload: {resources}};
  },
  setResourcesData(resourcesData) {
    return {type: SET_RESOURCES_DATA, payload: {resourcesData}};
  },
  setCopiedResources(copiedResourcesData) {
    return {type: SET_COPIED_RESOURCES, payload: {copiedResourcesData}};
  },
  setFailedResources(failedResourcesData) {
    return {type: SET_FAILED_RESOURCES, payload: {failedResourcesData}};
  }
};
