/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/


import React from 'react';
import {Link} from '../../routes/namedRouteUtils';
import {FormattedDate, FormattedMessage} from 'react-intl';
import {apiTypeToDisplayName} from '../../utils/api/apiTypes';
import {get, capitalize, startCase} from 'lodash-es';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import Tooltip from '@react/react-spectrum/Tooltip';
import AlertIcon from '@react/react-spectrum/Icon/Alert';
import InfoIcon from '@react/react-spectrum/Icon/Info';
import {publishingMessages} from '../properties/publishing/publishingActions';
import {EXTENSIONS} from '../../utils/api/apiTypes';
import {StatusLight, Link as Spectrum3Link} from '@adobe/react-spectrum';
import { getPropertyTypeIcon } from '../properties/propertyTypes';
import RevisionLabel from '../properties/publishing/RevisionSelector/RevisionLabel';
import { getSingularResourceTypeForDisplayFromId } from '../../utils/resourceUtils';
import {Tag} from 'spectrum-alternatives/TagList';
import Wait from '@react/react-spectrum/Wait';
import AppleIcon from '@react/react-spectrum/Icon/Apple';
import AndroidIcon from '@react/react-spectrum/Icon/Android';

export const TextCellRenderer = ({rowData, column}) => {
  return <span data-private>{get(rowData, column.key) || ''}</span>;
};

export const CapitalizedTextCellRenderer = ({rowData, column}) => {
  return <span data-private>{capitalize(get(rowData, column.key)) || ''}</span>;
};

export const LowerCaseTextCellRenderer = ({ rowData, column }) => {
  return <span data-private>{get(rowData, column.key)?.toLowerCase() || ''}</span>;
};

export const ResourceTypeRenderer = ({rowData}) => {
  if (rowData.entity) {
    return <span>{startCase(getSingularResourceTypeForDisplayFromId(rowData.entity.id) || '')}</span>;
  } else {
    return <span>{startCase(getSingularResourceTypeForDisplayFromId(rowData.id) || '')}</span>;
  }
};

export const UserCellRenderer = ({rowData}) => {
  const user = rowData.user;
  return (
    <div>
      <div data-private>{user.displayName || ''}</div>
      <div data-private className="u-smallerText1 u-dim">{user.email || ''}</div>
    </div>
  );
};

export const getLinkCellRenderer = function(locationProvider) {
  return function _linkCellRenderer(props) {
    const { rowData } = props;

    let location;
    if (typeof locationProvider === 'function') {
      location = locationProvider(rowData);
    }

    return <LinkCellRenderer {...props} location={location} />;
  };
};

export const LinkCellRenderer = (props) => {
  const { column, rowData, location } = props;

  const content = get(rowData, column.key) || '[system] unknown';

  return content ? (
    <Link to={location} className="spectrum-Link" data-private>
      { content }
    </Link>
  ) : null;
};

export const DateCellRenderer = (props) => {
  const { column, rowData } = props;

  const date = get(rowData, column.key);
  return date ? <FormattedDate value={date} format="shortDateTime" /> : null;
};

export const getPropertyLinkCellRenderer = function(locationProvider) {
  return function _linkCellRenderer(props) {
    const { column, rowData } = props;

    let location;
    if (typeof locationProvider === 'function') {
      location = locationProvider(rowData);
    }
    const content = get(rowData, column.key) || '[system] unknown';
    const isDevelopment = rowData?.attributes?.development;
    return content ? (
      <Link to={location} className="spectrum-Link" data-private>
        <div className="u-flex">
          {content}
          {isDevelopment ? (
            <Tag variant="or" size="S" className="warning developmentTag u-noMarginVertical u-marginLeftSm">
              Development
            </Tag>
          ) : null}
        </div>
      </Link>
    ) : null;
  };
};

export const EnabledStatusCellRenderer = (props) => {
  const { column, rowData } = props;
  const cellValue = get(rowData, column.key);

  if (rowData.isBlank) {
    return null;
  }

  return !cellValue ? (
    <div className="enabledStatusCellRenderer u-flex">
      <StatusLight variant="notice">
        <div className="u-flexCenter">Disabled</div>
      </StatusLight>
    </div>
  ) : (
    <div className="enabledStatusCellRenderer u-flex">
      <StatusLight variant="active">
        <div className="u-flexCenter">Enabled</div>
      </StatusLight>
    </div>
  );
};

export const SecretStatusRenderer = ({
  status: credentialSaveStatus,
  refreshStatus,
  isPolling,
  tooltip,
  placement = 'right',
  shortLabels = false,
  onPress = null
}) => {
  let status;
  let label;
  if (refreshStatus) {
    status = refreshStatus;
    // only put (Refresh) at the end when on the list view and not a succeeded secret
    label = `${status.charAt(0).toUpperCase() + status.slice(1)}${shortLabels  || refreshStatus === 'succeeded' ? '' : ' (Refresh)'}`;
  } else {
    status = credentialSaveStatus;
  }

  let variant;
  let defaultLabel;
  if (status === 'failed') {
    defaultLabel = 'Failed';
    variant = 'negative';
  } else if (status === 'pending') {
    variant = 'notice';
    defaultLabel = 'Pending';
  } else if (status === 'manual_authorization_required') {
    variant = 'notice';
    defaultLabel = 'Auth Needed';
  } else if (status === 'succeeded') {
    variant = 'active';
    defaultLabel = 'Succeeded';
  } else {
    variant = 'neutral';
    defaultLabel = 'Unknown';
  }

  return (
    <div className="statusCellRenderer u-flex u-flexCenterAlignItems">
      {refreshStatus === 'pending' || status === 'pending' && isPolling
        ? (
          <>
            <Wait size="S" className="u-marginRightSm" data-test-pending-spinner />
            <div className="u-flexCenter" data-test-status-label>{label || defaultLabel}</div>
          </>
        ) : (
          <StatusLight variant={variant} data-test-status-light >
            <div className="u-flexCenter" data-test-status-label>
              {onPress ? (
                <Spectrum3Link onPress={onPress} variant="secondary">
                  {label || defaultLabel}
                </Spectrum3Link>
              ) : (
                label || defaultLabel
              )}
            </div>
          </StatusLight>
        )}
      {Boolean(tooltip) && !isPolling && (
        <OverlayTrigger placement={placement} trigger="hover">
          <span className="u-marginLeftSm">
            <InfoIcon size="XS" data-tooltip-trigger />
          </span>
          <Tooltip>
            {tooltip}
          </Tooltip>
        </OverlayTrigger>
      )}
    </div>
  );
};

/*
  TODO: consolidate the usages of status renderers to here. Check SecretStatusRenderer, EnabledStatusCellRenderer, etc.
export const StatusCellRenderer = (props) => {
  const { column, rowData } = props;
  const status = get(rowData, column.key);

  if (status === 'failed') {
    return (
      <div className="statusCellRenderer u-flex">
        <StatusLight variant="negative" />
        <div className="u-flexCenter">Failed</div>
      </div>
    );
  } else if (status === 'pending') {
    return (
      <div className="statusCellRenderer u-flex">
        <StatusLight variant="notice" />
        <div className="u-flexCenter">Pending</div>
      </div>
    );
  } else if (status === 'succeeded') {
    return (
      <div className="statusCellRenderer u-flex">
        <StatusLight variant="active" />
        <div className="u-flexCenter">Succeeded</div>
      </div>
    );
  } else {
    return (
      <div className="statusCellRenderer u-flex">
        <StatusLight variant="neutral" />
        <div className="u-flexCenter">Unknown</div>
      </div>
    );
  }
};
*/

export const PropertyTypeCellRenderer = ({ column, rowData }) => {
  const cellValue = get(rowData, column.key);
  const PropertyIcon = getPropertyTypeIcon(cellValue);

  return (
    <span>
      <span className="u-flex">
        <PropertyIcon size="M" />
        <span className="iconLabel">{capitalize(cellValue)}</span>
      </span>
    </span>
  );
};

export const PublishedStatusCellRenderer = (props) => {
  const { column, rowData } = props;

  const content = get(rowData, column.key);

  return <span>{content === 'saved' ? '--' : content}</span>;
};

export const FormattedMessageCellRenderer = (props) => {
  const { column, rowData } = props;

  return <FormattedMessage id={get(rowData, column.key)} />;
};

export const StorageDurationCellRenderer = (props) => {
  const { column, rowData } = props;

  return <FormattedMessage id={get(rowData, column.key) || 'none'} />;
};


// RevisionSelector renderers below

export const ChangeTypeCellRenderer = (props) => {
  const { column, rowData } = props;
  const cellValue = get(rowData, column.key);
  // TODO: check if this item is in the library we are building off of
  return <span className="c-success">{ cellValue ? 'ADD' : 'REMOVE' }</span>;
};

export const ResourceNameCellRenderer = (props) => {
  const { column, rowData, rendererData } = props;
  const cellValue = rowData.type === EXTENSIONS ?
    get(rowData, ['attributes', 'displayName']) :
    get(rowData, column.key);
  const type = apiTypeToDisplayName(rowData.type);

  const validationWarning = (rendererData && !rendererData.revisionSelectorAllValid) ? (
    <OverlayTrigger placement="bottom">
      <span className='u-paddingLeftSm u-flex u-flexCenterAlignItems'>
        <AlertIcon className="icon c-error u-lineHeightNone" size="S" data-test-id="newResourceInvalid" />
      </span>
      <Tooltip>
        Select a rule, data element, or extension and a revision
      </Tooltip>
    </OverlayTrigger>
  ) : null;

  const cellContent = rowData.isBlank ? (
    <span className="u-flex u-flexOne u-italic" data-test-id="newResourceRow">
      Select a change below... {validationWarning}
    </span>
  ) : (
    <span className="c-defaultDk1">
      {!rendererData.hideResourceType ? (
        <span className="c-defaultDk2 u-bold">{type}: </span>
      ) : null}
      <span className="u-linkColor u-hoverUnderline" data-private>{cellValue}</span>
    </span>
  );

  return rowData.location ? (
    <Link to={rowData.location} className="spectrum-Link u-noTextDecoration">{cellContent}</Link>
  ) : (
    <span>{cellContent}</span>
  );
};

export const RevisionNameCellRenderer = (props) => {
  const { column, rowData, rendererData } = props;
  if (rowData.isBlank) { return null; }

  const enabled = get(rowData, 'attributes.enabled');
  const revisionNumber = get(rowData, column.key);
  const library = rendererData.library;

  const inUseUpstream = get(rowData, 'warnings.inUseUpstream');
  const inOtherDevLibrary = get(rowData, 'warnings.inOtherDevLibrary');

  return (
    <span className="revisionCell u-flex u-flexOne u-noWrap">
      <RevisionLabel resource={rowData} />

      {!Boolean(rendererData.hideWarnings) && (
        <React.Fragment>
          { inUseUpstream ? (
            <OverlayTrigger placement="bottom">
              <span className="u-iconInTextOffset u-marginLeftXs">
                <AlertIcon className="icon c-error" size="S" />
              </span>
              <Tooltip>
                {publishingMessages.REVISION_IN_UPSTREAM_LIBRARY}
              </Tooltip>
            </OverlayTrigger>
          ) : null}

          { (!library || library.attributes.state !== 'published') && !enabled && !inUseUpstream ? (
            <OverlayTrigger placement="bottom">
              <span className="u-iconInTextOffset u-marginLeftXs">
                <AlertIcon className="icon c-warning" size="S" />
              </span>
              <Tooltip>
                {publishingMessages.REVISION_DISABLED}
              </Tooltip>
            </OverlayTrigger>
          ) : null}

          { inOtherDevLibrary && !inUseUpstream && enabled ? (
            <OverlayTrigger placement="bottom">
              <span className="u-iconInTextOffset u-marginLeftXs">
                <InfoIcon className="icon c-primary" size="S" />
              </span>
              <Tooltip>
                {publishingMessages.REVISION_IN_OTHER_DEV_LIBRARY}
              </Tooltip>
            </OverlayTrigger>
          ) : null}

          { revisionNumber === 0 && !inOtherDevLibrary && !inUseUpstream && enabled ? (
            <OverlayTrigger placement="bottom">
              <span className="u-iconInTextOffset u-marginLeftXs">
                <InfoIcon className="icon c-primary" size="S" />
              </span>
              <Tooltip>
                {publishingMessages.NEW_REVISION_CREATION}
              </Tooltip>
            </OverlayTrigger>
          ) : null}
        </React.Fragment>
      )}
    </span>
  );
};

export const ConfigurationTypesCellRenderer = (props) => {
  const { rendererData, rowData } = props;
  let rowConfigurations = rendererData[rowData.id];
  let hasFcmConfig = false;
  let hasApnsConfig = false;

  if (!rowConfigurations) {
    return (
      <Wait size="S"></Wait>
    );
  }

  rowConfigurations?.forEach(config => {
    let configurationType = config.attributes.messaging_service;
    if (configurationType === 'fcm') {
      hasFcmConfig = true;
    } else if (configurationType === 'apns') {
      hasApnsConfig = true;
    }
  });

  if (hasFcmConfig && hasApnsConfig) {
    return (
      <div className="u-flex">
        <AndroidIcon size="S"/>
        <AppleIcon size="S"/>
      </div>
    );
  } else if (hasFcmConfig) {
    return (
      <div className="u-flex">
        <AndroidIcon size="S"/>
      </div>
    );
  } else if (hasApnsConfig) {
    return (
      <div className="u-flex">
        <AppleIcon size="S"/>
      </div>
    );
  }

  return null;
};
