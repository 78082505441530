import React from 'react';
import Button from '@react/react-spectrum/Button';
import classNames from 'classnames';
import '../style/index.styl';

export default class PaginationList extends React.Component {
  state = {
    uncontrolledValue: null,
  }

  componentDidMount() {
    if (this.props.defaultValue != null) {
      this.setState({ uncontrolledValue: this.props.defaultValue });
    }
  }

  /*
  * currentPage: The new page currentPage that the user has moved to. Either through
  *   the buttons for previous or next, or by clicking on a page link.
  * parentFuncToNotify: props.onChange, etc
  */
  notifyParentAndUpdateUncontrolledValue = (currentPage, parentFuncToNotify) => {
    this.setState({ uncontrolledValue: currentPage });
    if (parentFuncToNotify) {
      parentFuncToNotify(currentPage);
    }
  }

  render() {
    const initialProps = {
      currentPage: this.props.currentPage,
      onChange: this.props.onChange,
      className: this.props.className,
      isDisabled: this.props.isDisabled,
      lastPageNumber: this.props.lastPageNumber
    };

    if (this.state.uncontrolledValue != null) {
      return (
        <ControlledPaginationList
          {...initialProps}
          defaultValue={undefined}
          onChange={currentPage => this.notifyParentAndUpdateUncontrolledValue(currentPage, this.props.onChange)}
          currentPage={this.state.uncontrolledValue}
        />
      );
    }

    return <ControlledPaginationList {...initialProps} />;
  }
}

/**
 *
 * @param firstPageNumber
 * @param currentPage
 * @param lastPageNumber
 * @returns {array[string]}
 */
function determineLabelsBetweenFringeNumbers(firstPageNumber, currentPage, lastPageNumber) {
  if (firstPageNumber == null || currentPage == null || lastPageNumber == null) {
    return [];
  }
  if (firstPageNumber >= lastPageNumber || currentPage > lastPageNumber) {
    return [];
  }

  const paginationButtonsBetweenFirstPageLastPageCount = 5;
  let labels = [];

  const labelRadius = Math.floor(paginationButtonsBetweenFirstPageLastPageCount / 2);

  const firstLabel = Math.max(currentPage - labelRadius, firstPageNumber + 1);

  // we never push the first page or last page into the list here, we let the pagination rendering assume they'll
  // always show those.
  for (let i = 0; i < paginationButtonsBetweenFirstPageLastPageCount; i++) {
    const label = firstLabel + i;

    if (label >= lastPageNumber) {
      const proposedFrontLabel = labels[0] - 1;
      if (proposedFrontLabel > 1) {
        labels.unshift(proposedFrontLabel);
      } else {
        continue;
      }
    } else {
      labels.push(label);
    }
  }

  // if there are zero or 1 pages, then we don't need to check this logic
  if (labels.length > 1) {
    if (labels[0] - 1 > 1) {
      labels.unshift('...');
    }

    if (lastPageNumber - labels[labels.length - 1] > 1) {
      labels.push('...');
    }
  }

  return labels;
}

const ELLIPSIS_LABEL = '...';

class ControlledPaginationList extends React.PureComponent {
  static defaultProps = {
    onChange: () => {}
  }

  render() {
    const {
      currentPage,
      onChange,
      className,
      isDisabled: disabledFromProps
    } = this.props;

    let {
      lastPageNumber
    } = this.props;

    if (lastPageNumber == null) {
      console.error('PaginationList: lastPageNumber is required');
      return null;
    } else if (lastPageNumber < currentPage) {
      console.error('PaginationList: lastPageNumber can not be less than currentPage');
      return null;
    }

    let paginationButtonLabels = determineLabelsBetweenFringeNumbers(1, currentPage, lastPageNumber);
    if (!paginationButtonLabels.includes(1)) {
      paginationButtonLabels.unshift(1);
    }
    if (!paginationButtonLabels.includes(lastPageNumber)) {
      paginationButtonLabels.push(lastPageNumber);
    }

    const pageComponents = paginationButtonLabels.map((label, index) => {
      const isEllipses = label === ELLIPSIS_LABEL;
      const isDisabled = disabledFromProps || isEllipses;
      const isSelected = parseInt(currentPage, 10) === label;

      return (
        <Button
          role='link'
          key={`pagination-list-item-${label}-${index}`}
          variant='action'
          onClick={!isDisabled && !isSelected ? () => onChange(label) : null}
          disabled={isDisabled}
          className={classNames(
            {'ellipses': isEllipses},
            {'ellipsesLeft': isEllipses && index <= paginationButtonLabels.length / 2},
            {'ellipsesRight': isEllipses && index >= paginationButtonLabels.length / 2},
            {'spectrum-Pagination-pageButton': !isEllipses},
            { 'is-selected': isSelected }
          )}
          label={!isEllipses
            ? Number(label).toLocaleString('en-US')
            : label
          }
          quiet
        />
      );
    });

    const baseClassNames = classNames('spectrum-Pagination spectrum-Pagination--listing', className);
    if (pageComponents.length < 2) {
      // maintain element so flexed items will rely on a node always present
      return <nav className={baseClassNames} />;
    }

    return (
      <nav className={baseClassNames}>
        <Button
          role='link'
          variant='primary'
          className='spectrum-Pagination-prevButton'
          label='Previous'
          disabled={disabledFromProps || currentPage === 1}
          onClick={() => onChange(Math.max(1, currentPage - 1))}
          quiet
        />
        {pageComponents}
        <Button
          role='link'
          variant='primary'
          className='spectrum-Pagination-nextButton'
          label='Next'
          disabled={disabledFromProps || currentPage === lastPageNumber}
          onClick={() => onChange(Math.min(lastPageNumber, currentPage + 1))}
          quiet
        />
      </nav>
    );
  }
}
