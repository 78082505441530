/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/


import React, {Component} from 'react';
import {connect} from 'react-redux';
import {push} from '../../routes/namedRouteUtils';
import Heading from '@react/react-spectrum/Heading';
import Rocket from './launchRocket/launchRocket.js';

export class Maintenance extends Component {

  componentDidMount() {
    if (!this.props.maintenanceActive) {
      this.props.dispatch(push({name: 'home'}));
    }
  }

  render = () => (
    <div className="maintenance-main u-flex u-flexCenterAlignItems u-flexCenterJustifyContent u-fullWidth u-fullHeight u-flexColumn-phone">
      <div>
        <Rocket className="maintenance-rocket"/>
      </div>
      <div className="maintenance-text u-lightWeight u-flex u-flexCenterJustifyContent u-flexColumn u-textLeft u-marginHorizontalXl u-textCenter-phone">
        <Heading size={1}>Scheduled Maintenance</Heading>
        <div className="u-normalWeight">
          We are currently doing some upgrades, calibrating the engines,
          performing the pre-flight checks, and calculating a flight path.
          We should be ready to lift off in the next few minutes.
        </div>
      </div>
    </div>
  );
}

export default connect((state) => ({
  maintenanceActive: state.globals.maintenanceActive
}))(Maintenance);
