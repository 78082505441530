/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import snakeize from 'snakeize';
import camelize from 'camelize';
import {snakeCase, camelCase} from 'lodash-es';
import {
  EXTENSIONS,
  ACTIONS,
  CONDITIONS,
  EVENTS,
  DATA_ELEMENTS,
  getDelegateTypeFromDelegateDescriptorId
} from './apiTypes';
import {RESOURCE_ID_REGEX} from '../resourceUtils';

function camelizeType(responseBody) {
  if (responseBody.body && responseBody.body.data) {
    if (responseBody.body.data.type) {
      //singleitem
      responseBody.body.data.type = camelCase(responseBody.body.data.type);
    } else if (Array.isArray(responseBody.body.data)) {
      // list of items
      responseBody.body.data = responseBody.body.data.map((arrayItem)=>{
        arrayItem.type = camelCase(arrayItem.type);
        return arrayItem;
      });
    }
    if (responseBody.body.included && Array.isArray(responseBody.body.included)) {
      responseBody.body.included = responseBody.body.included.map((arrayItem)=>{
        arrayItem.type = camelCase(arrayItem.type);
        return arrayItem;
      });
    }
  }
  return responseBody;
}

/**
 * Normalizes occurrences of the "type" fields throughout the request body.
 */
function snakeizeType(requestBody) {
  if (requestBody?.data?.type) {
    // single item
    requestBody.data.type = snakeCase(requestBody.data.type);
  } else if (requestBody?.type) {
    // metrics endpoints have `type` in the root
    // getCompanyUsageMetrics
    requestBody.type = snakeCase(requestBody.type);
  } else {
    console.error('No type was provided while saving resource');
  }

  if (requestBody?.data?.relationships) {
    requestBody.data.relationships = normalizeRelationshipResourceType(requestBody.data.relationships);
  }
  return requestBody;
}

export function apiResponseBodyNormalizer(responseBody, translateCase = true) {
  const updatedResponseBody = translateCase ? camelizeType(camelize(responseBody)) : responseBody;
  return updatedResponseBody;
}

export function apiRequestBodyNormalizer(requestBody, translateCase = true) {
  const updatedRequestBody = translateCase ? snakeizeType(snakeize(requestBody)) : requestBody;
  return updatedRequestBody;
}

export function elasticSearchRequestBodyNormalizer(requestBody) {
  requestBody.data.resource_types = requestBody.data.resourceTypes.map(resource => snakeCase(resource));
  delete requestBody.data.resourceTypes;
  return requestBody;
}

/**
 * Normalizes the "type" inside each relationships entry
 */
export const normalizeRelationshipResourceType = (relationships = {}) => {
  const normalizedRelationships = {};

  Object.keys(relationships).forEach(snakedResourceType => {
    const relationshipData = relationships[snakedResourceType]?.data;
    if (relationshipData || relationshipData === null) {
      if (Array.isArray(relationships[snakedResourceType].data)) {
        const normalizedResourceData = relationships[snakedResourceType].data.map(resource => {
          return {
            ...resource,
            // there isn't always a 1:1 naming between snakedResourceType and resource.type
            type: snakeCase(resource.type),
          };
        });

        normalizedRelationships[snakedResourceType] = {
          data: normalizedResourceData
        };
      } else {
        const relationship = relationships[snakedResourceType];
        normalizedRelationships[snakedResourceType] = relationshipData === null ? {
          data: null
        } : {
          data: {
            ...relationship.data,
            // there isn't always a 1:1 naming between snakedResourceType and relationship.data.type
            type: snakeCase(relationship.data.type),
          }
        };
      }
    }
  });

  return normalizedRelationships;
};

export let relationshipNormalizer = function(item) {
  const relationships = {};
  if (item && item.relationships) {
    Object.keys(item.relationships).forEach((key)=>{

      if (item.relationships[key].data && item.relationships[key].data.id) {
        relationships[key] = item.relationships[key].data.id;
      }
    });
  }
  return relationships;
};

// extracts linkIds from url links
// http://localhost:9010/properties/PR7def0756b6a04550be955eac4c438147
//   => PR7def0756b6a04550be955eac4c438147
export let apiItemLinksToIds = function(apiItemLinks) {
  const links = {};
  if (apiItemLinks) {
    Object.keys(apiItemLinks).forEach((key)=>{
      let linkIdMatches = apiItemLinks[key] && apiItemLinks[key].match(RESOURCE_ID_REGEX);
      if (linkIdMatches && linkIdMatches.length) {
        links[key] = linkIdMatches[linkIdMatches.length - 1];
      }
    });
  }
  return links;
};

export function extensionPackagesNormalizer(extensionPackagesArray) {
  let extensionPackages = {};
  extensionPackagesArray.forEach((extensionPackage) => {
    extensionPackages[extensionPackage.id] = extensionPackage;
  });
  return extensionPackages;
}

export function delegateDescriptorsNormalizer(extensionPackages) {
  let delegateDescriptors = {};
  // these descriptor types are for comunicating with the api.
  // they are similar to our delegateTypes BUT THEY ARE NOT THE SAME.
  // ruleDescriptorTypes are saved on delegate descriptors and instances as type
  // delegateTypes are saved on a delegate descriptors and instances as componentType
  let ruleDescriptorTypes = [ACTIONS, EVENTS, CONDITIONS, DATA_ELEMENTS];

  // ruleComponents
  extensionPackages.forEach((extensionPackage) => {
    ruleDescriptorTypes.forEach((descriptorType) => {
      if (extensionPackage && extensionPackage.attributes[descriptorType]) {
        extensionPackage.attributes[descriptorType].forEach((delegateDescriptor)=>{
          delegateDescriptor = {
            ...delegateDescriptor
          };

          addDelegateDescriptorMeta(
            delegateDescriptor,
            descriptorType,
            extensionPackage.id
          );

          delegateDescriptors[delegateDescriptor.id] = delegateDescriptor;
        });
      }
    });

    //add configuration to the list of delegate descriptors
    let configurationDelegateDescriptor = extensionPackage.attributes.configuration;

    if (configurationDelegateDescriptor) {
      configurationDelegateDescriptor = {
        ...configurationDelegateDescriptor
      };

      addDelegateDescriptorMeta(
        configurationDelegateDescriptor,
        EXTENSIONS,
        extensionPackage.id
      );

      delegateDescriptors[configurationDelegateDescriptor.id] = configurationDelegateDescriptor;
    }
  });

  return delegateDescriptors;
}

function addDelegateDescriptorMeta(
  delegateDescriptor,
  componentType,
  extensionPackageId
) {

  delegateDescriptor.componentType = componentType;
  delegateDescriptor.extensionPackageId = extensionPackageId;
}


// expects an item with links
// returns the same item with typedId
export function getIdByTypeFromLinks(linkedItem, type) {
  return linkedItem.links[type].split('/')[4];
}

export function delegateImplementationNormalizer(delegateImplementation) {
  //TODO: extensionId can probably be removed but i need to get other stuff working first
  delegateImplementation = delegateImplementation.set('extensionId', (
    delegateImplementation.type === 'extensions' ?
    delegateImplementation.id :
    delegateImplementation?.relationships?.extension.data.id
  ));

  const delegateDescriptorId = delegateImplementation.attributes.delegateDescriptorId;

  return delegateImplementation.merge({
    isLoaded: false,
    extensionId: delegateImplementation.extensionId,
    delegateDescriptorId: delegateDescriptorId,
    valid: true,
    dirty: false,
    componentType: getDelegateTypeFromDelegateDescriptorId(
      delegateImplementation.attributes.delegateDescriptorId
    )
  });
}

export function delegateImplementationsNormalizer({delegateImplementations}) {
  return delegateImplementations.map((delegateImplementation)=>{
    return delegateImplementationNormalizer(delegateImplementation);
  });
}
