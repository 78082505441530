/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import Button from '@react/react-spectrum/Button';
import GearsIcon from '@react/react-spectrum/Icon/Gears';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {push} from '../../../routes/namedRouteUtils';
import NoContent from '../../content/noContent';
import TableActions from '../../CustomTable/TableActions';
import {Link} from '../../../routes/namedRouteUtils';
import CustomTable from '../../CustomTable/CustomTable';
import {
  PropertyTypeCellRenderer,
  getPropertyLinkCellRenderer
} from '../../CustomTable/CustomTable-CellRenderers';
import {getRights} from '../../rights/rightsSelectors';
import ContentLayout from '../../layouts/contentLayout/contentLayout';
import ControlledListLoader from '../../controlledList/ControlledListLoader';
import SortableHeaderCell from '../../CustomTable/SortableHeaderCell.jsx';
import {CONFIRMDELETE_DIALOG_KEY} from '../../controlledList/controlledListActions';
import Dialog from 'spectrum-alternatives/dialog/Dialog';
import CopyIcon from '@react/react-spectrum/Icon/Copy';
import DeleteIcon from '@react/react-spectrum/Icon/Delete';
import {MenuItem} from '@react/react-spectrum/Menu';
import AlertIcon from '@react/react-spectrum/Icon/Alert';
import AutoTruncatingActionMenu from '../../higherOrderComponents/AutoTruncatingActionMenu';
import {actionCreators as propertyCopyActions} from '../propertyCopy/propertyCopyActions';
import {COPY_STATUS} from '../propertyCopy/propertyCopyActions';
import {getPropertyCopyFromState, getIsCopyingFromState} from '../propertyCopy/PropertyCopySelectors';
import { getPaginationFromState } from '../../pagination/paginationSelectors';
import { getDialogStatusesFromState } from '../../controlledList/dialogsSelectors';
import { getSelectableItemsInfoFromState } from '../../controlledList/selectableListSelectors';
import { getActionCreators as getPaginationActions } from '../../pagination/paginationActions';
import { getActionCreators as getListActions } from '../../controlledList/controlledListActions';
import { getActionCreators as getSelectableListOfItemsActions } from '../../controlledList/selectableListActions';
import { getActionCreators as getDialogsActions } from '../../higherOrderComponents/dialogsActions';
import PaginationFooter from '../../pagination/PaginationFooter.jsx';
import {getPaginationPageSizeFromLocalStorage} from '../../pagination/paginationUtils.js';
import { ENDPOINT_GROUP_KEYS } from '../../../utils/api/apiMappingsUtils';
import AppFooter from '../../layouts/baseLayout/appFooter';
import { CAPABILITY_TYPES } from '../../../routes/capabilityUtils';
import { DateCellRenderer } from '../../CustomTable/CustomTable-CellRenderers';
import { expandShellLeftNav } from '../../higherOrderComponents/imsWrapperUtils';

export const PROPERTY_LIST_STATE_KEY = 'propertyList';
const paginationActions = getPaginationActions(PROPERTY_LIST_STATE_KEY);
const listActions = getListActions(PROPERTY_LIST_STATE_KEY);
const selectableListActions = getSelectableListOfItemsActions(PROPERTY_LIST_STATE_KEY);
const dialogsActions = getDialogsActions(PROPERTY_LIST_STATE_KEY);

export class Properties extends Component {
  constructor(props) {
    super(props);
    const { dispatch } = props;

    dispatch(paginationActions.initialize({
      pageSize: getPaginationPageSizeFromLocalStorage(PROPERTY_LIST_STATE_KEY),
      editViewRouteNames: ['editProperty'],
      endpointGroupKey: ENDPOINT_GROUP_KEYS.PROPERTIES
    }));
    dispatch(listActions.initialize({
      optionalBaseQueryParams: {
        'filter[platform]': props.eventForwardingPropertiesOnly ? 'EQ edge' : 'NOT edge'
      }
    }));
    dispatch(listActions.loadList());
    expandShellLeftNav();
  }
  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(paginationActions.onViewUnmounted());
    dispatch(listActions.onViewUnmounted());
  }
  getPropertyLocationByRow = (row) => {
    return this.getPropertyLocation(row.id);
  };
  getPropertyLocation = (propertyId) => {
    return { name: 'property', params: { ...this.props.match.params, property: propertyId } };
  };
  getEditPropertyLocation = (propertyId) => {
    return { name: 'editProperty', params: { ...this.props.match.params, property: propertyId } };
  };
  getEdgeEditPropertyLocation = (propertyId) => {
    return { ...this.getEditPropertyLocation(propertyId)};
  };
  onSortValueChanged = sortValue => {
    const { dispatch } = this.props;

    dispatch(paginationActions.setPendingQuerySort(sortValue));
    return dispatch(listActions.loadList());
  };
  render = () => {
    const {
      dispatch,
      pagination,
      dialogs,
      rights,
      deleting,
      propertyCopyStatus,
      propertyCopySourceProperty,
      propertyCopyDestinationProperty,
      isCopying,
      selectedItemsInfo,
      eventForwardingPropertiesOnly,
      shouldUserSeeCompanies
    } = this.props;

    const selectedProperty = selectedItemsInfo?.selectedItems?.[0];
    const canManageSelectedProperty = rights?.[selectedProperty?.attributes.platform]?.manageProperties;
    const canDeleteSelectedProperty = (
      !isCopying ||
      (
        selectedProperty?.id &&
        selectedProperty?.id !== propertyCopySourceProperty?.id &&
        selectedProperty?.id !== propertyCopyDestinationProperty?.id
      )
    );

    const canCreateEdgeProperty = rights?.edge?.manageProperties;
    const canCreateWebAndMobileProperties = rights?.web?.manageProperties || rights?.mobile?.manageProperties;
    const canCreateProperty = eventForwardingPropertiesOnly ? canCreateEdgeProperty : canCreateWebAndMobileProperties;
    const newPropertyLocation = eventForwardingPropertiesOnly ? this.getEdgeEditPropertyLocation('new') : this.getEditPropertyLocation('new');
    const editPropertyLocation = eventForwardingPropertiesOnly ?
      this.getEdgeEditPropertyLocation(selectedProperty?.id) : this.getEditPropertyLocation(selectedProperty?.id);

    const noProperties = ()=> (
      <NoContent
        videoUrl="https://www.youtube.com/embed/cWFGGt6H_qI?rel=0&showinfo=0"
        learnMoreText="Need more info? "
        learnMoreLinkText=" See the Docs."
        learnMoreLocation="https://adobe.com/go/launch_help_properties"
        actionButtonLocation={newPropertyLocation}
        actionButtonText="Add New Property"
        actionButtonDisabled={!canCreateProperty}
      />
    );

    const renderTableActions = (isFiltered)=> {
      return (
        <TableActions
          className="u-flex u-noWrap u-overflowXAuto"
          rows={pagination.pageItems}
          selectedRows={selectedItemsInfo.selectedItems}
          listActions={listActions}
          paginationActions={paginationActions}
          listQuery={pagination.query}
          primaryCtaMenu={
            <Button
              className="u-marginLeft"
              element={Link}
              to={newPropertyLocation}
              variant="cta"
              onClick={() => {
                dispatch(selectableListActions.deselectAll());
              }}
              disabled={!canCreateProperty}
            >
              New Property
            </Button>
          }
          selectedMenu={
            <AutoTruncatingActionMenu
              menuItems={[
                <Button
                  element={Link}
                  to={editPropertyLocation}
                  quiet
                  variant="primary"
                  icon={<GearsIcon size="S"/>}
                >
                  Configure
                </Button>,
                <Button
                  quiet
                  variant="primary"
                  icon={<CopyIcon size="S"/>}
                  disabled={!canManageSelectedProperty}
                  onClick={()=>{
                    if (propertyCopyStatus !== COPY_STATUS.IN_PROGRESS) {
                      dispatch(propertyCopyActions.initialize());
                    }
                    dispatch(propertyCopyActions.setDialogOpen(true));
                  }}
                >
                  Copy
                </Button>,
                <Button
                  quiet
                  variant="primary"
                  icon={<DeleteIcon size="S" />}
                  disabled={!canManageSelectedProperty}
                  onClick={()=>{
                    dispatch(dialogsActions.openDialog(CONFIRMDELETE_DIALOG_KEY));
                  }}
                >
                  Delete
                </Button>
              ]}
              truncatedMenuItems={[
                <MenuItem value="configure" icon={<GearsIcon />}>Configure</MenuItem>,
                <MenuItem value="copy" disabled={!canManageSelectedProperty} icon={<CopyIcon />}>Copy</MenuItem>,
                <MenuItem value="delete" disabled={!canManageSelectedProperty} icon={<DeleteIcon />}>Delete</MenuItem>
              ]}
              truncatedMenuOnSelect={(option)=>{
                if (option === 'configure') {
                  dispatch(
                    push(this.getEditPropertyLocation(selectedProperty?.id))
                  );
                } else if (option === 'copy') {
                  if (propertyCopyStatus !== COPY_STATUS.IN_PROGRESS) {
                    dispatch(propertyCopyActions.initialize());
                  }
                  dispatch(propertyCopyActions.setDialogOpen(true));
                } else if (option === 'delete') {
                  dispatch(dialogsActions.openDialog(CONFIRMDELETE_DIALOG_KEY));
                }
              }}
            />
          }
          isFiltered={isFiltered}
        />
      );
    };

    const headerCellProps = {
      query: pagination.query,
      onSortValueChanged: this.onSortValueChanged
    };

    const renderTable = () => (
      <div>
        <CustomTable
          rows={pagination.pageItems}
          loading={pagination.loading}
          selectable
          selectedRows={selectedItemsInfo.selectedItems}
          allowSelectAll={false}
          onToggleRowSelection={(property)=>{
            const selectedPropertyIsOn = selectedItemsInfo.selectedItems.includes(property);
            dispatch(selectableListActions.deselectAll());
            // turn clicked item on if it was previously off (actually toggle it)
            if (!selectedPropertyIsOn) {
              dispatch(selectableListActions.toggleSelectItem(property));
            }
          }}
          onToggleAllRowsSelected={() => {
            dispatch(selectableListActions.toggleSelectAll());
          }}
          columns={[
            {
              key: 'attributes.name',
              headerCellContent: ({ column }) => {
                return (
                  <SortableHeaderCell {...headerCellProps} column={column}>
                    Name
                  </SortableHeaderCell>
                );
              },
              cellContent: getPropertyLinkCellRenderer(this.getPropertyLocationByRow)
            },
            {
              key: 'attributes.platform',
              headerCellContent: ({ column }) => {
                return (
                  <SortableHeaderCell {...headerCellProps} column={column}>
                    Platform
                  </SortableHeaderCell>
                );
              },
              headerCellClassName: 'propertyTypeHeaderCell',
              cellContent: PropertyTypeCellRenderer,
            },
            {
              key: 'attributes.createdAt',
              headerCellContent: ({ column }) => {
                return (
                  <SortableHeaderCell {...headerCellProps} column={column}>
                    Created
                  </SortableHeaderCell>
                );
              },
              headerCellClassName: 'dateHeaderCell',
              cellContent: DateCellRenderer,
            },
            {
              key: 'attributes.updatedAt',
              headerCellContent: ({ column }) => {
                return (
                  <SortableHeaderCell {...headerCellProps} column={column}>
                    Last Modified
                  </SortableHeaderCell>
                );
              },
              headerCellClassName: 'dateHeaderCell',
              cellContent: DateCellRenderer,
            }
          ]}
        />

        <PaginationFooter stateKey={PROPERTY_LIST_STATE_KEY} />
      </div>
    );

    return (
      <ContentLayout
        displayCompanyBreadcrumb={shouldUserSeeCompanies}
        displayCapabilityBreadCrumb={true}
      >
        <div className="properties u-flexOne">
          <ControlledListLoader
            list={pagination.pageItems}
            listQuery={pagination.query}
            initialLoadCompleted={pagination.initialLoadCompleted}
            loading={pagination.loading}
            listRenderer={renderTable}
            listHeaderRenderer={renderTableActions}
            emptyListRenderer={noProperties}
          />

          <Dialog
            open={dialogs[CONFIRMDELETE_DIALOG_KEY]}
            variant="error"
            className="propertyDeleteDialog"
          >
            <Dialog.Header>
              {canDeleteSelectedProperty ? (
                <div className="u-flex u-flexCenterAlignItems u-flexSpaceBetween">
                  <span>
                    Are you sure?
                  </span>
                  <AlertIcon size="M"/>
                </div>
              ) : 'Delete Property'}
            </Dialog.Header>
            <Dialog.Content>
              { canDeleteSelectedProperty ? (
                <div>
                  <p>
                    The property listed below will be deleted.&nbsp;
                    <span className="c-error u-bold">
                      This action cannot be undone, nor can deleted properties be restored by you or Adobe.
                    </span>
                  </p>
                  <ul>
                    <li>
                      {selectedItemsInfo?.selectedItems[0]?.attributes.name}
                    </li>
                  </ul>
                </div>
              ) : (
                <p>
                  This property cannot be deleted while being copied. Try again after it completes.
                </p>
              )}
            </Dialog.Content>
            <Dialog.Footer className="u-noWrap">
              <Button
                quiet
                variant="primary"
                onClick={()=>{
                  dispatch(dialogsActions.closeDialog(CONFIRMDELETE_DIALOG_KEY));
                }}
              >
                {canDeleteSelectedProperty ? 'Cancel' : 'Close'}
              </Button>
              {canDeleteSelectedProperty && (
                <Button
                  variant="warning"
                  disabled={deleting}
                  onClick={()=>{
                    dispatch(listActions.deleteListItems({
                      resources: selectedItemsInfo.selectedItems
                    }));
                  }}
                >
                  Yes, permanently delete it
                </Button>
              )}
            </Dialog.Footer>
          </Dialog>
        </div>
        <AppFooter className="u-stickyPosition u-noPadding u-marginTop"/>
      </ContentLayout>
    );
  };
};

function mapStateToProps(state, passedProps) {
  const eventForwardingPropertiesOnly = passedProps?.params?.capability === CAPABILITY_TYPES.EVENT_FORWARDING;
  const propertyCopyState = getPropertyCopyFromState(state);
  return {
    eventForwardingPropertiesOnly,
    deleting: state.controlledList.deleting,
    rights: getRights(state),
    shouldUserSeeCompanies: state.globals.shouldUserSeeCompanies,
    propertyCopyStatus: propertyCopyState.copyStatus,
    propertyCopySourceProperty: propertyCopyState.sourceProperty,
    propertyCopyDestinationProperty: propertyCopyState.destinationProperty,
    isCopying: getIsCopyingFromState(state),
    pagination: getPaginationFromState(PROPERTY_LIST_STATE_KEY, state),
    dialogs: getDialogStatusesFromState(PROPERTY_LIST_STATE_KEY, state),
    selectedItemsInfo: getSelectableItemsInfoFromState(PROPERTY_LIST_STATE_KEY, state)
  };
};

const ConnectedProperties = connect(mapStateToProps)(Properties);

export default ConnectedProperties;
