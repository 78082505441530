/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import actionsHandler from '../../../../redux/actionsHandler';
import {actionCreators as apiActions} from '../../../../utils/api/apiActions';
import Immutable from 'seamless-immutable';
import {ENDPOINT_GROUP_KEYS} from '../../../../utils/api/apiMappingsUtils';

export const SET_LOADING = 'libraryUpstream/SET_LOADING';
export const SELECT_DELEGATE_TYPE = 'libraryUpstream/SELECT_DELEGATE_TYPE';
export const SET_FILTER = 'libraryUpstream/SET_FILTER';

export const LIBRARY_UPSTREAM_STATE_KEY = 'libraryUpstream';
export const UPSTREAM_STATE_KEYS = {
  rules: ENDPOINT_GROUP_KEYS.UPSTREAM_RULES,
  dataElements: ENDPOINT_GROUP_KEYS.UPSTREAM_DATA_ELEMENTS,
  extensions: ENDPOINT_GROUP_KEYS.UPSTREAM_EXTENSIONS
};

const defaultState = Immutable({
  loading: false,
  selectedDelegateType: null,
  filter: null
});

//Reducers
export default actionsHandler({
  [SET_LOADING](state, {payload:{isLoading}}) {
    return state.set('loading', isLoading);
  },
  [SELECT_DELEGATE_TYPE](state, {payload:{delegateType}}) {
    return state.set('selectedDelegateType', delegateType);
  },
  [SET_FILTER](state, {payload:{filter}}) {
    return state.set('filter', filter);
  },
  default: (state = defaultState)=> {
    return state;
  }
});


//Action Creators
export let actionCreators = {
  setLoading(isLoading) {
    return {
      type: SET_LOADING,
      payload: { isLoading }
    };
  },
  setDelegateType(type) {
    return {
      type: SELECT_DELEGATE_TYPE,
      payload: { delegateType: type }
    };
  },
  setFilter(filter) {
    return {
      type: SET_FILTER,
      payload: { filter }
    };
  },

  loadUpstreamBuild(upstreamLibrary, updateState = true) {
    return (dispatch)=>{
      return dispatch(apiActions.apiAction({
        name: 'getUpstreamBuild',
        urlData: {
          library: upstreamLibrary.id
        },
        updateState,
        stateKey:LIBRARY_UPSTREAM_STATE_KEY
      }));
    };
  },

  resetUpstreamBuild() {
    return (dispatch)=>{
      return dispatch(apiActions.resetEndpoint('getUpstreamBuild', LIBRARY_UPSTREAM_STATE_KEY));
    };
  },

  changeFilter(params, filter) {
    return (dispatch) => {
      dispatch(actionCreators.setFilter(filter));
    };
  },
  changeResourceType(params, resourceType) {
    return (dispatch) => {
      dispatch(actionCreators.setFilter(null));
      dispatch(actionCreators.setDelegateType(resourceType));
    };
  }
};
