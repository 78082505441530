import {connect} from 'react-redux';
import {getActionCreators as getDialogsActions} from '../higherOrderComponents/dialogsActions';


const mapStateToProps = (state, props) => {
  const dialogs = state.dialogs[props.dialogsStateKey];

  return {
    dialogs: dialogs || {}
  };
};

export default connect(mapStateToProps)(
  function DialogHost({
    render,
    dialogs,
    dialogsStateKey
  }) {
    return render(
      {
        dialogs,
        dialogsActions: getDialogsActions(dialogsStateKey)
      }
    );
  }
);
