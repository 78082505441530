/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

export const GT = 'GT';
export const LT = 'LT';
export const EQ = 'EQ';
export const LIKE = 'LIKE';
export const CONTAINS = 'CONTAINS';
export const NOT = 'NOT';

// this should be the equivalent of (?<!\\),(?!\s)
// javascript does not support negetive lookbehind
// it will split a string on commas when the comma
// is not escaped with a preceding '\' or a ' ' after the comma
function splitValues(values) {
  values = values ? values : [];
  let theValues = [];
  let escaped = false;
  let lastSlicePosition = -1;
  for (let i = 0; i < values.length; i++) {
    if (i + 1 === values.length) {
      theValues.push(values.slice(lastSlicePosition + 1, values.length));
    }
    switch (values.charAt(i)) {
      case '\\':
        escaped = !escaped; break;
      case ',':
        if (!escaped && values.charAt(i + 1) !== ' ') {
          theValues.push(values.slice(lastSlicePosition + 1, i));
          lastSlicePosition = i;
        }
      default:
        escaped = false;
    }
  }
  return theValues;
}

const qualifierRegex = new RegExp(`^(?:${GT}|${LT}|${EQ}|${LIKE}|${CONTAINS}|${NOT})(?= )`);
function getFilterValueParts(values) {
  let filterValues = {};
  values.forEach((value)=>{
    const qualifier = (value.match(qualifierRegex) || []) [0];
    const filterValue = qualifier ? value.substr(value.indexOf(' ') + 1) : value;
    filterValues[filterValue] = {'qualifiers': {
      [qualifier || EQ]: true
    }};
  });
  return filterValues;
}

// I opted not to include '+' due to plus being treated as a space in
// react router
function getSortValueParts(values) {
  let sortValues = {};
  values.forEach((value)=>{
    const isDescending = value.charAt(0) === '-';
    sortValues[isDescending ? value.substr(1) : value] = {
      qualifier: isDescending ? 'descending' : 'ascending'
    };
  });
  return sortValues;
}

export function queryParser(query = {}) {
  let finalQuery = {};

  Object.keys(query).forEach((queryKey)=>{
    const fullMatch = (queryKey.match(/(\S+?)\[([^\]]+)\]?/) || [] );
    const queryType = fullMatch[1] || queryKey;
    const nestedType = fullMatch[2];
    const queryValues = splitValues(query[queryKey]);
    finalQuery[queryType] = finalQuery[queryType] || {};

    if (nestedType) {
      finalQuery[queryType][nestedType] = query[queryKey];
    }

    if (queryType === 'filter') {
      finalQuery[queryType][nestedType] = getFilterValueParts(queryValues);
    } else if (queryType === 'sort') {
      finalQuery[queryType] = getSortValueParts(queryValues);
    }
    // This give the ability to pass along most query params. This causes the api to have issues.
    // I have opted to be specific on what we use from the url. Only 'sort' and 'filter' as of now.
    // else {
    //   finalQuery[queryType] = query[queryKey];
    // }

  });
  return finalQuery;
};

export function querySerializer(query = {}) {
  let finalQuery = {};
  Object.keys(query).forEach((queryKey)=>{

    if (typeof query[queryKey] === 'string') {
      finalQuery[queryKey] = query[queryKey];
      return;
    }

    Object.keys(query[queryKey]).forEach((nestedType)=>{
      if (queryKey === 'sort') {
        const isDescending = query[queryKey][nestedType].qualifier === 'descending';
        finalQuery[queryKey] = finalQuery[queryKey] || '';
        finalQuery[queryKey] += finalQuery[queryKey] ? ',' : '';
        finalQuery[queryKey] = finalQuery[queryKey] + (isDescending ? '-' : '') + nestedType;
      } else {
        if (queryKey === 'filter') {
          const filterKey = `${queryKey}[${nestedType}]`;
          (
            query[queryKey][nestedType] &&
            Object.keys(query[queryKey][nestedType]).forEach((valueName)=>{
              Object.keys(query[queryKey][nestedType][valueName].qualifiers).forEach((qualifier)=>{
                finalQuery[filterKey] = finalQuery[filterKey] || '';
                finalQuery[filterKey] += finalQuery[filterKey] ? ',' : '';
                finalQuery[filterKey] += qualifier + ' ' + valueName;
              });
            })
          );
        } else {
          finalQuery[`${queryKey}[${nestedType}]`] = query[queryKey][nestedType];
        }
      }
    });

  });
  return finalQuery;
}
