/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import {getApiData} from '../../utils/api/apiTools';
import {get} from 'lodash-es';
import { isEqual } from 'lodash-es';

// When you add an extension to a property it is given a unique Id. So every instance
// of Core will have a different Id. This will retrieve that Id for the extension for
// the current property.
export function getDefaultExtensionId(state, stateKey, delegateType) {
  const apiData = getApiData(state, stateKey);
  const extensionPackages = apiData.extensionPackages;
  const extensions = apiData.extensions;
  const extensionsWithDelegateType = getExtensionsWithDelegateType(
    extensionPackages,
    extensions,
    delegateType
  );

  let defaultExtension;

  if (extensionsWithDelegateType.length) {
    const coreExtension = extensionsWithDelegateType.find((extension) => {
      return extension.attributes.name === 'core';
    });

    // Prefer the core extension over others.
    if (coreExtension) {
      defaultExtension = coreExtension;
    } else {
      defaultExtension = extensionsWithDelegateType[0];
    }
  }

  if (defaultExtension) {
    return defaultExtension.id;
  }
}

export function getExtensionsWithDelegateType(extensionPackages, extensions, type) {
  //filter removes the extensions that don't have any delegates of this type.
  return Object.keys(extensions || {}).filter((extensionKey)=> {
    const extension = extensions[extensionKey];
    const extensionPackage = extensionPackages[extension.relationships.extensionPackage.data.id];

    return (
      extensionPackage &&
      extensionPackage.attributes &&
      extensionPackage.attributes[type]
    );
  }).map((extensionId) => {
    return extensions[extensionId];
  });
}

export const areExtensionsUsedByResourceInstalled = (delegateImplementations, extensions) => {
  return delegateImplementations.every((delegateImplementation) => {
    const extensionId = get(delegateImplementation, ['relationships','extension','data','id']);
    // Note that if we're editing an extension, it won't have any links to extensions.
    return extensions[extensionId];
  });
};

export const getSettingsWithTimeout = (delegateDisplayGetSettings, maxTime = 200) => {
  return new Promise(async(resolve, reject)=>{
    const timeout = setTimeout(()=>{
      reject('getSettings() took too long');
    }, maxTime);

    if (delegateDisplayGetSettings) {
      try {
        const currentSettings = await delegateDisplayGetSettings();
        clearTimeout(timeout);
        resolve(currentSettings);
      } catch (error) {
        reject(error);
      }
    }
  });
};

export const getResourceSettingsDirtiness = async({
  resource,
  delegateDisplayGetSettings,
  iframeTouched
}) => {
  let currentSettings;
  try {
    currentSettings = await getSettingsWithTimeout(delegateDisplayGetSettings);
  } catch (error) {
    // if there was an error getting current settings,
    // we don't know if the iframe is dirty so we'll
    // return true to be safe. This could be due to
    // a timeout or a failed async promise from the
    // extension view.
    console.warn(error);

    // if getSettings throws an error, fallback to iframeTouched
    return iframeTouched;
  }

  // Before saving, we JSON.stringify settings objects which removes any
  // keys with undefined values (but it retains nulls). Because of this and
  // the fact that object key order is not guaranteed, we have to stringify,
  // then parse before we can accurately compare settings as objects.
  const currentSettingsCleaned = JSON.parse(JSON.stringify(currentSettings));

  // determine dirtiness from actual settings
  let settingsAreDirty = false;

  // if the settings have never been saved, we cannot determine dirtiness using
  // actual settings values so we'll fall back to iframeTouched
  if (resource.attributes.settings === null) {
    settingsAreDirty = iframeTouched;
  } else {
    settingsAreDirty = !isEqual(
      currentSettingsCleaned,
      JSON.parse(resource.attributes.settings)
    );
  }

  return settingsAreDirty;
};
