/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import {actionCreators as apiActions} from '../../../utils/api/apiActions';
import {push} from '../../../routes/namedRouteUtils';

export const EDGE_HOST_TYPE_CLOUDFLARE_WORKER = 'cloudflare_worker';

export const DEFAULT_HOST_DATA = {
  data: {
    attributes: {
      name: 'Managed by Adobe',
      type_of: 'akamai'
    },
    type: 'hosts'
  }
};

export const CREATE_HOST_API_NAME = 'createHost';
export const HOST_EDIT_STATE_KEY = 'hostEdit';

//Action Creators
export let actionCreators = {
  loadHosts(params) {
    return apiActions.apiAction({
      name: 'getHosts',
      urlData: {...params},
      stateKey: HOST_EDIT_STATE_KEY
    });
  },
  loadHost(params, callback) {
    return (dispatch, getState)=> {
      dispatch(apiActions.apiAction({
        name: 'getHost',
        urlData: {...params},
        stateKey: HOST_EDIT_STATE_KEY
      })).then(()=>{
        return callback(getState().api[HOST_EDIT_STATE_KEY].host.attributes.status);
      });
    };
  },
  createHost(params, data) {
    return apiActions.apiAction({
      name: CREATE_HOST_API_NAME,
      urlData: {...params},
      data: data,
      stateKey: HOST_EDIT_STATE_KEY
    });
  },
  updateHost(params, data) {
    return apiActions.apiAction({
      name: 'updateHost',
      urlData: {...params},
      data: data,
      stateKey: HOST_EDIT_STATE_KEY
    });
  },
  goToHosts(params) {
    return push({ name: 'hosts', params });
  },
  goToHost(params) {
    return push({ name: 'editHost', params });
  },
};
