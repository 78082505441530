import Immutable from 'seamless-immutable';
import { actionCreators as apiActions } from '../../utils/api/apiActions';
import { sortBy, capitalize, snakeCase, camelCase, unset } from 'lodash-es';
import {
  getOrderedSearchResultsFromState,
  getRequiredTermCountForSearch
} from './elasticSearchSelectors';
import { RESOURCE_TYPES } from '../../utils/api/apiTypes';
import actionsHandler from '../../redux/actionsHandler';
import { getCurrentRouteParamsFromState } from '../../routes/routeSelectors';
import { push } from '../../routes/namedRouteUtils';

export const SEARCH_SCOPES = Immutable({
  ALL: { label: 'All', value: undefined },
  AUDIT_EVENTS: { label: 'Audit Events', value: RESOURCE_TYPES.AUDIT_EVENTS },
  BUILDS: { label: 'Builds', value: RESOURCE_TYPES.BUILDS },
  CALLBACKS: { label: 'Callbacks', value: RESOURCE_TYPES.CALLBACKS },
  COMPANIES: { label: 'Companies', value: RESOURCE_TYPES.COMPANIES },
  DATA_ELEMENTS: { label: 'Data Elements', value: RESOURCE_TYPES.DATA_ELEMENTS },
  ENVIRONMENTS: { label: 'Environments', value: RESOURCE_TYPES.ENVIRONMENTS },
  EXTENSION_PACKAGES: { label: 'Extension Packages', value: RESOURCE_TYPES.EXTENSION_PACKAGES },
  EXTENSIONS: { label: 'Extensions', value: RESOURCE_TYPES.EXTENSIONS },
  HOSTS: { label: 'Hosts', value: RESOURCE_TYPES.HOSTS},
  LIBRARIES: { label: 'Libraries', value: RESOURCE_TYPES.LIBRARIES },
  PROPERTIES: { label: 'Properties', value: RESOURCE_TYPES.PROPERTIES },
  RULE_COMPONENTS: { label: 'Rule Components', value: RESOURCE_TYPES.RULE_COMPONENTS },
  RULES: { label: 'Rules', value: RESOURCE_TYPES.RULES },
});

export const INITIALIZE = 'elasticSearch/INITIALIZE';
export const SET_ELASTIC_SEARCH_META = 'elasticSearch/SET_ELASTIC_SEARCH_META';
export const SET_ELASTIC_SEARCH_LOADING = 'elasticSearch/SET_ELASTIC_SEARCH_LOADING';

// Guarantee "All" is the first option.
export const SEARCH_SCOPES_LIST = Immutable([SEARCH_SCOPES.ALL]).concat(sortBy(SEARCH_SCOPES.without('ALL'), 'label'));

// reducer
const defaultState = Immutable({
  requiredCharacterCountForSearch: 2,
});

export default actionsHandler({
  [INITIALIZE](state, { payload }) {
    return state.setIn([payload.stateKey], Immutable({}));
  },
  [SET_ELASTIC_SEARCH_META](state, { payload }) {
    return state.setIn([payload.stateKey, 'meta'], Immutable(payload.meta));
  },
  [SET_ELASTIC_SEARCH_LOADING](state, { payload }) {
    return state.setIn([payload.stateKey, 'loading'], payload.loading);
  },
  default: (state = defaultState) => {
    return state;
  }
});

// This does not allow for multiple of these elastic search components on the same page.
// If we need that functionality this AbortController creation will need to move to the view.
let abortController = new AbortController();

// actions
export const actionCreators = {
  resetSearch({ stateKey, meta }) {
    return dispatch => {
      dispatch(apiActions.resetData(['elasticSearch'], stateKey));
      dispatch({
        type: SET_ELASTIC_SEARCH_META,
        payload: {
          stateKey,
          meta,
        }
      });
      dispatch(actionCreators.setLoading({
        stateKey,
        loading: true
      }));
    };
  },
  setLoading({stateKey, loading}) {
    return {
      type: SET_ELASTIC_SEARCH_LOADING,
      payload: {
        stateKey,
        loading
      }
    };
  },
  abortSearch(stateKey) {
    return (dispatch) => {
      abortController.abort();
      dispatch(actionCreators.setLoading({
        stateKey,
        loading: false
      }));
    };
  },
  /** A very specific type of elasticSearch query. */
  quickSearch({
    search,
    from = 0,
    size = 10,
    stateKey,
    propertyId,
    resourcesTypes,
  }) {
    return async(dispatch, getState) => {
      // kill the endpoints
      dispatch(actionCreators.abortSearch(stateKey));
      // nuke the old elasticSearch results
      dispatch(actionCreators.resetSearch({
        stateKey,
        meta: { search }
      }));
      abortController = new AbortController();

      // don't hit search if we don't have these params.
      if (search?.length < getRequiredTermCountForSearch(getState())) {
        return [];
      }

      if (!propertyId) {
        throw new Error('propertyId is required for quickSearch');
      }

      // I need to be declarative on "all" so we can handle redux keys
      if (!Array.isArray(resourcesTypes) || !resourcesTypes.length) {
        resourcesTypes = SEARCH_SCOPES_LIST.map(scope => scope.value);
      }

      const promises = resourcesTypes.map(singleResourceType => {
        let requestData = {
          data: {
            from,
            size,
            query: {
              'attributes.*': {
                value: search
              },
              'relationships.property.data.id': {
                value: propertyId
              },
              'attributes.revision_number': {
                value: 0, // get the latest revision.
              },
              // uncomment this as soon as blacksmith supports it.
              'attributes.deleted_at': {
                exists: false, // only get resources that have not been deleted
              }
            },
            // this is getting changed to resource types
            resourceTypes: [singleResourceType],
          }
        };

        if (requestData.data.resourceTypes.includes(SEARCH_SCOPES.EXTENSION_PACKAGES.value)) {
          // NOTE: when asking for many resource types per query, it's possible
          // that we'll get all revisions for other resources when we do this to
          // get extension package results. Currently not a problem since our implementation
          // sends 1 query per resource type, but worthing bringing up to the backend
          // team.
          unset(requestData, ['data', 'query', 'attributes.revision_number']);
        }

        return dispatch(apiActions.apiAction({
          name: 'elasticSearch',
          abortSignal: abortController.signal,
          stateKey,
          endpointGroupKey: singleResourceType,
          data: requestData,
        }));
      });

      try {
        await Promise.all(promises);
      } catch (error) {
        // in case of failure continue
      }

      dispatch(actionCreators.setLoading({
        stateKey,
        loading: false
      }));

      const orderedByResourceType = getOrderedSearchResultsFromState(stateKey, resourcesTypes, getState());

      // The reason we don't limit the query is because we don't know how many resourcesTypes
      // will match using "ALL" for a search term, so we limit results that we render, and we
      // always query for a larger amount of results.
      // NOTE: maxResultsPerSection will never go over size requested by user
      let maxResultsPerSection = Math.min(size, 3);
      if (orderedByResourceType.length === 1) {
        // use as many results as the query size, but don't go over 10 total
        maxResultsPerSection = Math.min(size, 10);
      } else if (orderedByResourceType.length <= 3) {
        maxResultsPerSection = Math.min(size, 5);
      }

      return orderedByResourceType
        .reduce((dropdownList, [resourceType, { data, meta }]) => {
          // get rid of the option grouping in the menu if we only have 1 scope selected.
          // optionGroup gets passed to spectrum's Autocomplete MenuItem components.
          const optionGroup = resourcesTypes.length > 1
            ? capitalize(snakeCase(resourceType).split('_').join(' '))
            : undefined;

          let resourceResults = data.slice(0, maxResultsPerSection).map(resource => {
            return {
              value: resource.id,
              label: resource.attributes?.displayName || resource?.attributes?.name,
              optionGroup,
              data: resource, // during selection, you can receive the full data object
            };
          }, []);

          // maxResultsPerSection drives how many we show to the user, so regardless
          // of query size, if there are ever more hits than we show, that means
          // that there are more. NOTE: remember that maxResultsPerSection should
          // never go over size.
          if (data.length && meta?.totalHits > maxResultsPerSection) {
            resourceResults = resourceResults.concat({
              label: '...more',
              optionGroup,
              disabled: true,
            });
          }

          return dropdownList.concat(resourceResults);
        }, []);
    };
  },
  /** Given a resource type, figures out where the edit page is. */
  goToEditResource({ data }) {
    return (dispatch, getState) => {
      const { type: resourceType, id: resourceId } = data;
      const typeSingular = resourceType.substr(0, resourceType.length - 1);

      const params = {
        ...getCurrentRouteParamsFromState(getState()),
        [typeSingular]: resourceId,
      };

      dispatch(push({ name: camelCase(`edit_${typeSingular}`), params }));
    };
  }
};
