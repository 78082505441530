/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import {parse} from 'query-string';

export function getCurrentLocationFromState(state) {
  return state.router?.location;
}

export function getCurrentRouteFromState(state) {
  return state.router?.location?.route;
}

export function getLastLocationFromState(state) {
  return state.router?.lastLocation;
}

export function getLastRouteFromState(state) {
  return state.router?.lastLocation?.route;
}

export function getCurrentRouteParamsFromState(state) {
  return state.router?.location?.params;
}

export function getLastRouteParamsFromState(state) {
  return state.router?.lastLocation?.params;
}

export function getCurrentQueryParamsFromState(state) {
  return parse(state.router?.location?.search || '');
}

export function getLastQueryParamsFromState(state) {
  return parse(state.router?.lastLocation?.search || '');
}
