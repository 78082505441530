/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import actionsHandler from '../../../redux/actionsHandler';
import Immutable from 'seamless-immutable';

export const INSTALL_INSTRUCTIONS_DIALOG_KEY = 'installInstructionsDialog';
export const SET_WORKING_ENVIRONMENT = 'environments/SET_WORKING_ENVIRONMENT';

const defaultState = {
  workingEnvironment: null
};

//Reducers
export default actionsHandler({
  [SET_WORKING_ENVIRONMENT](state, {payload: {environment}}) {
    return state.set('workingEnvironment', environment);
  },
  default(state = Immutable(defaultState)) {
    return state;
  }
});

//Action Creators
export let actionCreators = {
  setWorkingEnvironment(environment) {
    return {
      type: SET_WORKING_ENVIRONMENT,
      payload: {environment}
    };
  }
};
