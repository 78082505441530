/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import Immutable from 'seamless-immutable';
import {actionCreators as apiActions} from '../../../utils/api/apiActions';
import {delegateImplementationsNormalizer} from '../../../utils/api/apiNormalizers';
import {
  isResourceLatestAndUnchanged,
  getResourceOriginId
} from '../../../utils/resourceUtils';
import {
  getRuleCompareViewData,
  getResourceByOriginIdAndStateKey,
  getResourceFromRuleComponentsByOrigin
} from '../compareViewSelectors';
import {getApiData} from '../../../utils/api/apiTools';
import actionsHandler from '../../../redux/actionsHandler';
import {getRuleComponentsDiff} from './ruleCompareViewUtils';
import {groupAndSortRuleComponents} from '../../properties/rules/ruleEditHelpers';
import {actionCreators as componentCompareActions} from '../componentCompareView/componentCompareActions';
import {
  ACTIONS,
  CONDITIONS,
  EVENTS,
  RULES,
  RULE_COMPONENTS
} from '../../../utils/api/apiTypes';
import {
  getRevisionIdsFromRevisionRange,
  getRevisionRange,
  getDominantSideData,
  getRevisionToUseForNonDominantSide,
  getRevisionRangeParameterName
} from '../compareViewUtils';
import {push, replace} from '../../../routes/namedRouteUtils';
import {getRuleComponentFromGroupedComponents} from '../../properties/rules/ruleEditHelpers';
import {actionCreators as routerActions} from '../../../redux/routerActions';


export const STATE_KEY_LEFT = 'ruleCompareViewLeft';
export const STATE_KEY_RIGHT = 'ruleCompareViewRight';

let fetchRuleDataAbortController;

// action consts
export const SET_SIDE_STATE = 'ruleCompareView/SET_SIDE_STATE';
export const SET_BASE_RESOURCE_DATA = 'ruleCompareView/SET_BASE_RESOURCE_DATA';
export const SET_LOADING = 'ruleCompareView/SET_LOADING';
export const CLEAN_UP = 'ruleCompareView/CLEAN_UP';
export const SET_ERROR = 'ruleCompareView/SET_ERROR';
export const SET_LATEST_HAS_UNSAVED_CHANGES = 'ruleCompareView/SET_LATEST_HAS_UNSAVED_CHANGES';


const initialState = Immutable({
  loading: true,
  initialLoadCompleted: false,
  [STATE_KEY_LEFT]: {
    loading: true,
    ruleComponents: groupAndSortRuleComponents(),
    id: null
  },
  [STATE_KEY_RIGHT]: {
    loading: true,
    ruleComponents: groupAndSortRuleComponents(),
    id: null
  },
  diffRows: {
    [ACTIONS]: [],
    [CONDITIONS]: [],
    [EVENTS]: []
  }
});

//Reducers
export default actionsHandler({
  [SET_SIDE_STATE](state, action) {
    const otherSideStateKey = (
      action.payload.stateKey === STATE_KEY_LEFT ?
      STATE_KEY_RIGHT :
      STATE_KEY_LEFT
    );

    const sideComponentsToChange = groupAndSortRuleComponents(
      delegateImplementationsNormalizer(
        {delegateImplementations:action.payload.ruleComponents}
      )
    );

    const otherSideCurrentComponents = state[otherSideStateKey].ruleComponents;


    // left and right side
    const allComponentsWithDiff = getRuleComponentsDiff(
      action.payload.stateKey === STATE_KEY_RIGHT ? {
        rightComponents: sideComponentsToChange,
        leftComponents: otherSideCurrentComponents,
        property: action.payload.property
      } : {
        rightComponents: otherSideCurrentComponents,
        leftComponents: sideComponentsToChange,
        property: action.payload.property
      },
    );

    return state.setIn(
      [action.payload.stateKey, 'ruleComponents'],
      allComponentsWithDiff[action.payload.stateKey]
    ).setIn(
      [otherSideStateKey, 'ruleComponents'],
      allComponentsWithDiff[otherSideStateKey]
    ).setIn(
      [action.payload.stateKey, 'id'],
      action.payload.id
    ).set(
      'diffRows',
      allComponentsWithDiff.boundGroupPairedComponents
    ).setIn(
      [action.payload.stateKey, 'rule'],
      action.payload.rule
    );
  },
  [SET_BASE_RESOURCE_DATA](state, action) {
    return state.set(
      'revisions', action.payload.revisions
    ).set(
      'originId', action.payload.originId
    ).set(
      'latestRevisionId', action.payload.latestRevisionId
    ).set(
      'originDirty', action.payload.originDirty
    );
  },
  [SET_LOADING](state, action) {
    const otherSideStateKey = (
      action.payload.stateKey === STATE_KEY_LEFT ?
      STATE_KEY_RIGHT :
      STATE_KEY_LEFT
    );

    state = state.setIn(
      (
        action.payload.stateKey ?
        [action.payload.stateKey, 'loading'] :
        ['loading']
      ),
      action.payload.loading
    );

    if (
      !state[action.payload.stateKey].loading &&
      !state[otherSideStateKey].loading &&
      state.loading
    ) {
      state = state.set(
        'loading', false
      ).set(
        'initialLoadCompleted', true
      );
    }
    return state;
  },
  [CLEAN_UP]() {
    return initialState;
  },
  [SET_ERROR](state, action) {
    if (action.payload.stateKey) {
      return state.setIn([action.payload.stateKey, 'error'], action.payload.message);
    } else {
      return state.set('error', action.payload.message);
    }
  },
  [SET_LATEST_HAS_UNSAVED_CHANGES](state, action) {
    return state.set('latestHasUnsavedChanges', action.payload);
  },
  default: (state)=> {
    return state ? state : initialState;
  }
});

//Action Creators
export let actionCreators = {
  initialize({
    params,
    howToSetNonDominantSide = 'previousRevision', // previousRevision, upstream, none
    leftManualResourceData,
    rightManualResourceData,
    onlyUseManualData,
    latestHasUnsavedChanges,
    locationUpdater
  }) {

    fetchRuleDataAbortController = new AbortController();

    const {
      leftRevisionId,
      rightRevisionId
    } = getRevisionIdsFromRevisionRange(params[getRevisionRangeParameterName(RULES)]);

    const leftSideId = leftManualResourceData?.id || leftRevisionId;
    const rightSideId = rightManualResourceData?.id || rightRevisionId;

    // We dont know what side data will be available to us. So we we find which side we do have data for.
    // that becomes the dominantSideId
    let {
      dominantSideId,
      nonDominantSideId,
      dominantStateKey,
      nonDominantStateKey,
      dominantSideData,
      nonDominantSideData,
    } = getDominantSideData({
      leftSideId,
      rightSideId,
      leftManualResourceData,
      rightManualResourceData,
      stateKeyLeft: STATE_KEY_LEFT,
      stateKeyRight: STATE_KEY_RIGHT
    });

    return (dispatch, getState) =>{
      dispatch(actionCreators.setLatestHasUnsavedChanges(Boolean(latestHasUnsavedChanges)));
      return dispatch(actionCreators.fetchAndSetRuleData({
        stateKey: dominantStateKey,
        revisionId: dominantSideId,
        manualRuleData: dominantSideData,
        params,
        shouldSetResourceData: true,
        locationUpdater,
        abortSignal: fetchRuleDataAbortController?.signal
      })).then(()=>{
        // this should get the first revision that is not head.
        // we need a key here that finds the upstream version of this resource.
        // So we can default to upstream for the left side.

        if (
          howToSetNonDominantSide !== 'none' &&
          !nonDominantSideId &&
          !onlyUseManualData
        ) {

          const state = getState();
          const ruleCompareViewState = getRuleCompareViewData(state);

          nonDominantSideId = getRevisionToUseForNonDominantSide({
            dominantSideId,
            latestRevisionId: ruleCompareViewState.latestRevisionId,
            revisions: ruleCompareViewState.revisions,
            originId: ruleCompareViewState.originId,
            originDirty: ruleCompareViewState.originDirty,
            howToSetNonDominantSide
          });

        }

        howToSetNonDominantSide !== 'none' &&
        !nonDominantSideId &&
        dispatch(actionCreators.setError({
          stateKey: nonDominantStateKey,
          message: 'Nothing to compare. This rule only has one revision.'
        }));

        return nonDominantSideId ? dispatch(actionCreators.fetchAndSetRuleData({
          stateKey: nonDominantStateKey,
          revisionId: nonDominantSideId,
          manualRuleData: nonDominantSideData,
          params,
          shouldSetResourceData: false,
          locationUpdater,
          abortSignal: fetchRuleDataAbortController?.signal
        })) : dispatch(actionCreators.setLoading({loading:false, stateKey:nonDominantStateKey}));

      })
      .catch(() => {
        // the user probably left the page and we've aborted the fetch calls.
        console.log('Aborting the calls to load revision data for Rule Compare');
      });
    };
  },
  setLatestHasUnsavedChanges(latestHasUnsavedChanges) {
    return {
      type: SET_LATEST_HAS_UNSAVED_CHANGES,
      payload: latestHasUnsavedChanges
    };
  },
  goToComponentCompare({params, originId, shouldReplaceRoute = false}) {
    return (dispatch, getState)=>{
      const rightRevision = getResourceByOriginIdAndStateKey(getState(), originId, STATE_KEY_RIGHT);
      const leftRevision = getResourceByOriginIdAndStateKey(getState(), originId, STATE_KEY_LEFT);
      dispatch((shouldReplaceRoute ? replace : push)({
        name: params.library ? 'editLibraryRuleComponentCompare' : 'editRuleComponentCompare',
        params: {
          ...params,
          [getRevisionRangeParameterName(RULE_COMPONENTS)]: getRevisionRange({
            leftRevisionId: leftRevision?.id,
            rightRevisionId: rightRevision?.id
          })
        }
      }));
    };
  },

  initializeComponentCompare({params, originId, componentCompareStateKey}) {
    return (dispatch, getState)=>{
      const state = getState();
      if (originId) {
        var existingRightRuleComponent = getResourceByOriginIdAndStateKey(state, originId, STATE_KEY_RIGHT);
        var existingLeftRuleComponent = getResourceByOriginIdAndStateKey(state, originId, STATE_KEY_LEFT);
      } else {
        const {rightRevisionId , leftRevisionId} = getRevisionIdsFromRevisionRange(
          params?.[getRevisionRangeParameterName(RULE_COMPONENTS)]
        );
        var ruleCompareData = getRuleCompareViewData(state);
        var existingLeftRuleComponent = getRuleComponentFromGroupedComponents(
          ruleCompareData[STATE_KEY_LEFT].ruleComponents,
          leftRevisionId
        );

        var existingRightRuleComponent = getRuleComponentFromGroupedComponents(
          ruleCompareData[STATE_KEY_RIGHT].ruleComponents,
          rightRevisionId
        );


        if (!existingLeftRuleComponent && existingRightRuleComponent) {
          existingLeftRuleComponent = getResourceFromRuleComponentsByOrigin(
            ruleCompareData[STATE_KEY_LEFT].ruleComponents,
            getResourceOriginId(existingRightRuleComponent)
          );
        }

        if (!existingRightRuleComponent && existingLeftRuleComponent) {
          existingRightRuleComponent = getResourceFromRuleComponentsByOrigin(
            ruleCompareData[STATE_KEY_RIGHT].ruleComponents,
            getResourceOriginId(existingLeftRuleComponent)
          );
        }
      }


      if (!existingLeftRuleComponent && !existingRightRuleComponent) {
        dispatch(routerActions.goToParentRoute({params, shouldReplaceRoute: true}));
      } else {
        dispatch(componentCompareActions(componentCompareStateKey).initialize({
          params,
          onlyUseManualData: true,
          leftManualResourceData: existingLeftRuleComponent,
          rightManualResourceData: existingRightRuleComponent
        }));
      }
    };
  },
  setSideState({stateKey, id, ruleComponents, rule}) {
    return (dispatch, getState)=>{
      dispatch({
        type: SET_SIDE_STATE,
        payload: {
          stateKey,
          ruleComponents,
          rule,
          id,
          property: getState().api.property
        }
      });
    };
  },
  setBaseResourceData({revisions, originId, latestRevisionId, originDirty}) {
    return {
      type: SET_BASE_RESOURCE_DATA,
      payload: {
        revisions,
        originId,
        latestRevisionId,
        originDirty
      }
    };
  },
  fetchAndSetRuleData({
    stateKey,
    revisionId,
    manualRuleData,
    params,
    shouldSetResourceData,
    locationUpdater,
    abortSignal
  }) {
    return (dispatch, getState)=> {
      // when getting a rule with includes from the api if there are no includes
      // the includes object is not present. This mean old rule components never get cleared out.
      // this clean up action fixes that.
      dispatch(apiActions.resetData([ 'ruleComponents'], stateKey));
      dispatch(actionCreators.setLoading({loading:true, stateKey}));
      return Promise.all([
        manualRuleData ? null : dispatch(apiActions.apiAction({
          name: 'getRule',
          stateKey: stateKey,
          urlData: {
            ...params,
            rule: revisionId
          },
          abortSignal
        })),
        manualRuleData ? null : dispatch(apiActions.apiAction({
          name: 'getRuleComponents',
          stateKey: stateKey,
          urlData: {
            ...params,
            rule: revisionId
          },
          abortSignal
        })),
        shouldSetResourceData ? dispatch(apiActions.apiAction({
          name: 'getRevisions',
          stateKey: stateKey,
          urlData: {
            ...params,
            resource: revisionId,
            resourceType: 'rules'
          },
          urlParams: {
            sort: '-revision_number'
          },
          abortSignal
        })) : null
      ]).then(()=>{
        if (manualRuleData) {
          dispatch(apiActions.manuallySetData(['api', stateKey, 'rule'], manualRuleData.rule));
          dispatch(apiActions.manuallySetData(['api', stateKey, 'ruleComponents'], manualRuleData.ruleComponents));
        }

        // We dont know extension revision changes.
        // Extension at head is always returned on all revisions of all resources relationships to extension.
        // This means we cannot show that the extension has changed between 2 versions.
        // It also means we cannot show that an extension was historically disabled or removed.
        // Blacksmith is working on adding a updatedWithExtension relationship for this.

        const apiState = getApiData(getState(), stateKey);
        const rule = apiState.rule;
        const ruleComponents = apiState.ruleComponents || [];


        // It would be great if we can make these requests with the list endpoints.
        // However I cannot due to filtering only working on attributes, id is not an attribute.
        return Promise.all(ruleComponents.map((ruleComponent)=>(
          ruleComponent.relationships.extension.data.id
        )).filter((extensionId, index, self)=> (
          self.indexOf(extensionId) === index
        )).map((extensionId)=>(
          dispatch(apiActions.apiAction({
            name: 'getExtension',
            stateKey: stateKey,
            urlData: {
              ...params,
              extension: extensionId
            },
            abortSignal
          }))
        ))).then((extensionResponses)=>{
          const state = getState();
          let sideState = getApiData(state, stateKey);

          const revisions = sideState?.revisions || [];

          const latestRevisionId = revisions.find(isResourceLatestAndUnchanged)?.id;

          extensionResponses.forEach((extensionResponse)=>{
            const extensionData = extensionResponse.res.body.data;
            sideState = sideState.setIn(['extensions',extensionData.id], extensionData);
          });

          dispatch(apiActions.manuallySetData(['api', stateKey, 'extensions'], sideState.extensions));
          dispatch(actionCreators.setSideState({
            stateKey: stateKey,
            id: sideState?.rule?.id,
            rule,
            ruleComponents
          }));

          shouldSetResourceData && dispatch(actionCreators.setBaseResourceData({
            revisions,
            originId: getResourceOriginId(sideState?.rule),
            latestRevisionId,
            originDirty: revisions?.[0]?.attributes.dirty
          }));

          const ruleCompareState = getRuleCompareViewData(state);
          locationUpdater && locationUpdater(getRevisionRange({
            leftRevisionId: (
              stateKey === STATE_KEY_LEFT ?
              sideState?.rule?.id :
              ruleCompareState?.[STATE_KEY_LEFT]?.id ||
              ''
            ),
            rightRevisionId: (
              stateKey === STATE_KEY_RIGHT ?
              sideState?.rule?.id :
              ruleCompareState?.[STATE_KEY_RIGHT]?.id ||
              ''
            )
          }));

          dispatch(actionCreators.setLoading({loading:false, stateKey}));
        });
      });
    };
  },
  cleanUp() {
    fetchRuleDataAbortController?.abort();
    return (dispatch)=> {
      dispatch(apiActions.resetData(['revisions', 'endpoints', 'ruleComponents', 'extension', 'extensions', 'rule'], STATE_KEY_RIGHT));
      dispatch(apiActions.resetData(['revisions', 'endpoints', 'ruleComponents', 'extension', 'extensions', 'rule'], STATE_KEY_LEFT));
      return dispatch({
        type: CLEAN_UP
      });
    };
  },
  setError(error) {
    return {
      type: SET_ERROR,
      payload: {
        ...error
      }
    };
  },
  setLoading({loading, stateKey}) {
    return {
      type: SET_LOADING,
      payload: {
        stateKey,
        loading
      }
    };
  }
};
