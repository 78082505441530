/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import actionsHandler from '../../redux/actionsHandler';
import promiseCreator from './promiseCreator';
import Immutable from 'seamless-immutable';

export const RESET = 'codeEditor/RESET';
export const SET_CODE_EDITOR_OPEN = 'codeEditor/SET_CODE_EDITOR_OPEN';
export const SET_LANGUAGE = 'codeEditor/SET_LANGUAGE';
export const SET_CODE = 'codeEditor/SET_CODE';
export const SET_HIGHLIGHTED_LINE = 'codeEditor/SET_HIGHLIGHTED_LINE';
export const SET_CODE_STATUS_EXPANDED = 'codeEditor/SET_CODE_STATUS_EXPANDED';
export const SET_LINTING = 'codeEditor/SET_LINTING';
export const SET_LINT_WORKER_FAILED = 'codeEditor/SET_LINT_WORKER_FAILED';
export const SET_LINT_ERRORS = 'codeEditor/SET_LINT_ERRORS';
export const SET_MINIFYING = 'codeEditor/SET_MINIFYING';
export const SET_MINIFIER_WORKER_FAILED = 'codeEditor/SET_MINIFIER_WORKER_FAILED';
export const SET_MINIFIER_ERRORS = 'codeEditor/SET_MINIFIER_ERRORS';


const initialState = {
  editorOpen: false,
  language: null,
  code: '',
  highlightedLine: null,
  codeStatusExpanded: false,
  isLinting: false,
  lintWorkerFailed: false,
  lintErrors: [],
  isMinifying: false,
  minifierWorkerFailed: false,
  minifierErrors: []
};

//Reducers
export default actionsHandler({
  [RESET]: ()=> {
    return Immutable(initialState);
  },
  [SET_CODE_EDITOR_OPEN]: (state, {payload:editorOpen})=> {
    return state.merge({ editorOpen });
  },
  [SET_LANGUAGE]: (state, {payload:language})=> {
    return state.merge({ language });
  },
  [SET_CODE]: (state, {payload:code})=> {
    return state.merge({ code });
  },
  [SET_HIGHLIGHTED_LINE]: (state, {payload:highlightedLine})=> {
    return state.merge({ highlightedLine });
  },
  [SET_CODE_STATUS_EXPANDED]: (state, {payload:codeStatusExpanded})=> {
    return state.merge({ codeStatusExpanded });
  },
  [SET_LINTING]: (state, {payload:isLinting})=> {
    return state.merge({ isLinting });
  },
  [SET_LINT_WORKER_FAILED]: (state, {payload:lintWorkerFailed})=> {
    return state.merge({ lintWorkerFailed });
  },
  [SET_LINT_ERRORS]: (state, {payload:lintErrors})=> {
    return state.merge({ lintErrors });
  },
  [SET_MINIFYING]: (state, {payload:isMinifying})=> {
    return state.merge({ isMinifying });
  },
  [SET_MINIFIER_WORKER_FAILED]: (state, {payload:minifierWorkerFailed})=> {
    return state.merge({ minifierWorkerFailed });
  },
  [SET_MINIFIER_ERRORS]: (state, {payload:minifierErrors})=> {
    return state.merge({ minifierErrors });
  },
  default:(state)=> {
    return state || Immutable(initialState);
  }
});

export const promiseUtil = promiseCreator();

//Action Creators
export let actionCreators = {
  reset() {
    return { type: RESET };
  },
  setCodeEditorOpen(editorOpen) {
    return {
      type: SET_CODE_EDITOR_OPEN,
      payload: editorOpen
    };
  },
  setLanguage(language) {
    return {
      type: SET_LANGUAGE,
      payload: language
    };
  },
  setCode(code) {
    return {
      type: SET_CODE,
      payload: code
    };
  },
  setHighlightedLine(highlightedLine) {
    return {
      type: SET_HIGHLIGHTED_LINE,
      payload: highlightedLine
    };
  },
  setCodeStatusExpanded(codeStatusExpanded) {
    return {
      type: SET_CODE_STATUS_EXPANDED,
      payload: codeStatusExpanded
    };
  },
  setLinting(isLinting) {
    return {
      type: SET_LINTING,
      payload: isLinting
    };
  },
  setLintWorkerFailed(lintWorkerFailed) {
    return {
      type: SET_LINT_WORKER_FAILED,
      payload: lintWorkerFailed
    };
  },
  setLintErrors(lintErrors) {
    return {
      type: SET_LINT_ERRORS,
      payload: lintErrors
    };
  },
  setMinifying(isMinifying) {
    return {
      type: SET_MINIFYING,
      payload: isMinifying
    };
  },
  setMinifierWorkerFailed(minifierWorkerFailed) {
    return {
      type: SET_MINIFIER_WORKER_FAILED,
      payload: minifierWorkerFailed
    };
  },
  setMinifierErrors(minifierErrors) {
    return {
      type: SET_MINIFIER_ERRORS,
      payload: minifierErrors
    };
  }
};
