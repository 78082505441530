import Immutable from 'seamless-immutable';
import actionsHandler from '../../../redux/actionsHandler';

export const SET_IS_FILTER_PANEL_OPEN = 'filterPanel/SET_FILTER_PANEL_OPEN';

// Reducers
export default actionsHandler({
  [SET_IS_FILTER_PANEL_OPEN]: (state, action) => {
    return state.set('isOpen', action.payload);
  },
  default: (state = Immutable({isOpen: false})) => {
    return state;
  }
});

// Action Creators
export let actionCreators = {
  setIsFilterPanelOpen(value) {
    return {
      type: SET_IS_FILTER_PANEL_OPEN,
      payload: value
    };
  }
};
