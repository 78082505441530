/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import actionsHandler from '../../redux/actionsHandler';
import Immutable from 'seamless-immutable';

export const GLOBAL_DIALOGS_KEY = 'global';

export const OPEN_DIALOG = 'dialogs/OPEN_DIALOG';
export const CLOSE_DIALOG = 'dialogs/CLOSE_DIALOG';

//Reducers
export default actionsHandler({
  [OPEN_DIALOG](state, action) {
    return state.setIn([action.stateKey, action.dialogId], true);
  },
  [CLOSE_DIALOG](state, action) {
    return state.setIn([action.stateKey, action.dialogId], false);
  },
  default(state = Immutable({})) {
    return state;
  }
});


//Action Creators
export const getActionCreators = (stateKey)=> {
  return {
    openDialog(dialogId) {
      return { type: OPEN_DIALOG, stateKey, dialogId };
    },
    closeDialog(dialogId) {
      return { type: CLOSE_DIALOG, stateKey, dialogId };
    }
  };
};
