/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/


// Resource Types
export const AUDIT_EVENTS = 'auditEvents';
export const APP_CONFIGURATIONS = 'appConfigurations';
export const APP_SURFACES = 'appSurfaces';
export const BUILDS = 'builds';
export const CALLBACKS = 'callbacks';
export const COMPANIES = 'companies';
export const DATA_ELEMENTS = 'dataElements';
export const ENVIRONMENTS = 'environments';
export const EXTENSION_PACKAGES = 'extensionPackages';
export const EXTENSIONS = 'extensions';
export const HOSTS = 'hosts';
export const SECRETS = 'secrets';
export const LIBRARIES = 'libraries';
export const PROPERTIES = 'properties';
export const RULE_COMPONENTS = 'ruleComponents';
export const RULES = 'rules';

// api resource types are snake case
// most of the time these get turned into camel case but
// there are some cases where we need to interact directly with these types
export const API_RESOURCE_TYPES = {
  AUDIT_EVENTS: 'audit_Events',
  APP_CONFIGURATIONS: 'app_configurations',
  APP_SURFACES: 'app_surfaces',
  BUILDS: 'builds',
  CALLBACKS: 'callbacks',
  COMPANIES: 'companies',
  DATA_ELEMENTS: 'data_elements',
  ENVIRONMENTS: 'environments',
  EXTENSION_PACKAGES: 'extension_packages',
  EXTENSIONS: 'extensions',
  HOSTS: 'hosts',
  SECRETS: 'secrets',
  LIBRARIES: 'libraries',
  PROPERTIES: 'properties',
  RULE_COMPONENTS: 'rule_components',
  RULES: 'rules',
};

export const RESOURCE_TYPES = {
  AUDIT_EVENTS,
  APP_CONFIGURATIONS,
  APP_SURFACES,
  BUILDS,
  CALLBACKS,
  COMPANIES,
  DATA_ELEMENTS,
  ENVIRONMENTS,
  EXTENSION_PACKAGES,
  EXTENSIONS,
  HOSTS,
  SECRETS,
  LIBRARIES,
  PROPERTIES,
  RULE_COMPONENTS,
  RULES,
};

// Rule Component Types
export const ACTIONS = 'actions';
export const CONDITIONS = 'conditions';
export const EVENTS = 'events';

// These don't exist on the server, but are used to give a more
// human-readable categorization string between conditions with negate = false and negate = true
export const REGULAR = 'regular';
export const EXCEPTION = 'exception';


export function apiTypeToDisplayName(type, lowerCase) {
  let displayName = '';

  switch (type) {
    case RULES:
      displayName = 'Rule'; break;
    case RULE_COMPONENTS:
      displayName = 'Rule Component'; break;
    case DATA_ELEMENTS:
      displayName = 'Data Element'; break;
    case EXTENSIONS:
      displayName = 'Extension'; break;
    case ACTIONS:
      displayName = 'Action'; break;
    case CONDITIONS:
      displayName = 'Condition'; break;
    case EVENTS:
      displayName = 'Event'; break;
    case APP_SURFACES:
      displayName = 'App Surfaces'; break;
    default:
      displayName = '';
  }

  return lowerCase ? displayName.toLowerCase() : displayName;
}

export function getDelegateTypeFromDelegateDescriptorId(delegateDescriptorId) {
  return delegateDescriptorId.split('::')[1];
}
