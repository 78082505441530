/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/


import {get} from 'lodash-es';
import {isEqual} from 'lodash-es';

/**
 * filters anything in filteringList out of sourceList by checking comparator on items from both lists
 * @param  {array} sourceList
 * @param  {array} filteringList
 * @param  {function(srcItem, filteringItem) or attribute string} comparator
 * @return {filteredSourceList}
 */
export function filterListFromList(sourceList, filteringList, comparator) {
  return sourceList.filter((sourceListItem)=>{
    const foundMatchingFilteringItem = filteringList.find((filteringListItem)=>{
      if (typeof comparator === 'function') {
        return comparator(sourceListItem, filteringListItem);
      } else if (comparator) {
        const srcAttribute = get(sourceListItem, comparator);
        const filteringAttribute = get(filteringListItem, comparator);
        return (srcAttribute || filteringAttribute) && srcAttribute === filteringAttribute;
      } else {
        return sourceListItem === filteringListItem;
      }
    });
    return !foundMatchingFilteringItem; // if we don't find a match then we'll keep it
  });
}

// https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
export function getUID() {
  // the cypto solution is the best way to make sure there are no collisions
  // the math.random is a fallback
  /*eslint-disable */
  return window.crypto ?
  ( [ 1e7 ] +- 1e3 +- 4e3 +- 8e3 +- 1e11 ).replace( /[018]/g, capture =>
    (capture ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> capture / 4).toString(16)
  ) :
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(capture) {
    var randomNumber = Math.random() * 16 | 0;
    var finalValue = capture == 'x' ? randomNumber : (randomNumber & 0x3 | 0x8);
    return finalValue.toString(16);
  });
  /*eslint-enable */
}

// This function can be used inside a shouldComponentUpdate to diff
// this.props and nextProps while ignoring specific props. It was first
// needed by addControlledList which received an ever-changing getChildren
// prop. There are probably several other places where this could improve
// render times.
export function arePropsDifferent(propsA = {}, propsB = {}, ignoredProps = []) {
  const anyValidDiff = (
    Object.keys(propsA).length !== Object.keys(propsB).length ||
    Object.keys(propsA).some(propKey=>{
      return (
        !ignoredProps.includes(propKey) &&
        !isEqual(propsA[propKey], propsB[propKey])
      );
    })
  );

  return anyValidDiff;
}

export function randomAlphaNumeric(length) {
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;

  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function escapeCommas(subject) {
  return subject.replace(/,/g,'\\,');
}

// adds react-safe space characters to the right or left of a string
export function padStringForReact(text, {leftPad = 0, rightPad = 0} = {}) {
  let paddedString = '';
  for (let i = 0; i < leftPad; i++) { paddedString += '\u0020'; }
  paddedString += text;
  for (let i = 0; i < rightPad; i++) { paddedString += '\u0020'; }
  return paddedString;
}
