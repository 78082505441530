/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import Immutable from 'seamless-immutable';
import {actionCreators as apiActions} from '../../utils/api/apiActions';
import {getCurrentRouteParamsFromState} from '../../routes/routeSelectors';
import { getActionCreators as getPaginationActions } from '../pagination/paginationActions';
import { getActionCreators as getListActions } from '../controlledList/controlledListActions';
import {
  getNotesTypeByCurrentRouteFromState,
  getNotesEndpointsByCurrentRoute,
  getNotesGetListEndpointByLastRoute
} from './notesSelectors';
import actionsHandler from '../../redux/actionsHandler';

export const STATE_KEY = 'notes';

export const SET_NEW_NOTE_TEXT = 'notes/SET_NEW_NOTE_TEXT';
export const CLEANUP_NOTE = 'notes/CLEANUP_NOTE';

let abortController;

//Reducers
export default actionsHandler({
  [SET_NEW_NOTE_TEXT]: (state = {}, action) => {
    return state.set('newNoteText', action.payload.newNoteText);
  },
  [CLEANUP_NOTE]: (state) => {
    return state.set('newNoteText', '');
  },
  default: (state = Immutable({})) => {
    return state;
  }
});

//Action Creators
export let actionCreators = {
  initAbortController() {
    return () => { abortController = new AbortController(); };
  },
  abortRequestsAndCleanupNotes() {
    return (dispatch, getState) => {
      const notesType = getNotesTypeByCurrentRouteFromState(getState());
      abortController?.abort();

      // reset the endpoint which contains the pagination data (totalCount)
      const lastGetEndpoint = getNotesGetListEndpointByLastRoute(getState());
      if (lastGetEndpoint) {
        dispatch(apiActions.resetEndpoint(lastGetEndpoint, notesType));
      }

      // reset the page data which will otherwise conflict with
      // later infiniteScroll page loads
      const endpoints = getNotesEndpointsByCurrentRoute(getState());
      if (endpoints?.getList) {
        dispatch(apiActions.resetData([endpoints?.getList], notesType));
      }
    };
  },
  loadCurrentViewFirstNote() {
    return (dispatch, getState) => {
      const params = getCurrentRouteParamsFromState(getState());
      const endpoints = getNotesEndpointsByCurrentRoute(getState());
      const notesType = getNotesTypeByCurrentRouteFromState(getState());
      return dispatch(apiActions.apiAction({
        name: endpoints.getList,
        urlData: {...params},
        urlParams: {
          sort: '-updated_at',
          'page[size]': 1
        },
        abortSignal: abortController?.signal,
        stateKey: notesType
      }));
    };
  },
  saveCurrentViewNote(newNoteText) {
    return (dispatch, getState) => {
      const params = getCurrentRouteParamsFromState(getState());
      const endpoints = getNotesEndpointsByCurrentRoute(getState());
      const notesType = getNotesTypeByCurrentRouteFromState(getState());
      return dispatch(apiActions.apiAction({
        name: endpoints.create,
        urlData: {...params},
        data: {
          data: {
            attributes: { text: newNoteText },
            type: 'notes'
          }
        },
        stateKey: notesType
      })).then(()=>{
        dispatch(actionCreators.cleanUpNote());
        if (!abortController?.signal?.aborted) {
          return dispatch(actionCreators.reloadNotesList());
        }
      });
    };
  },
  reloadNotesList() {
    return async(dispatch, getState) => {
      const notesType = getNotesTypeByCurrentRouteFromState(getState());
      const paginationActions = getPaginationActions(notesType);
      const listActions = getListActions(notesType);

      dispatch(paginationActions.resetData());
      dispatch(paginationActions.setPendingQueryPage(1));
      dispatch(paginationActions.persistPendingQuery());
      return dispatch(listActions.loadInfiniteScrollList({isFirstPage: true}));
    };
  },
  setNewNoteText(newNoteText) {
    return {
      type: SET_NEW_NOTE_TEXT,
      payload: { newNoteText }
    };
  },
  cleanUpNote() {
    return {
      type: CLEANUP_NOTE
    };
  }
};
