/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe Systems Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe Systems Incorporated and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Adobe Systems Incorporated and its
 * suppliers and are protected by all applicable intellectual property
 * laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe Systems Incorporated.
 **************************************************************************/

import Immutable from 'seamless-immutable';
import {
  SECRETS_LIST_STATE_KEY,
  isPollingPaginatedListOfSecretsFromState
} from './secretsSelectors';
import {getPaginationFromState} from '../../pagination/paginationSelectors';
import {actionCreators as apiActions} from '../../../utils/api/apiActions';
import {actionCreators as asyncPollActions} from '../../../utils/asyncPollActions';
import {getActionCreators as getListActions} from '../../controlledList/controlledListActions';
import {some} from 'lodash-es';
import actionsHandler from '../../../redux/actionsHandler';

export const SET_ENVIRONMENTS = 'secretsList/SET_ENVIRONMENTS';

let getPaginatedSecretsAbortController = new AbortController();
const paginatedSecretsStatusPollingActions = asyncPollActions(SECRETS_LIST_STATE_KEY);
const listActions = getListActions(SECRETS_LIST_STATE_KEY);

const defaultState = Immutable({
  environments: [],
  isGeneratingTestToken: false
});

export default actionsHandler({
  [SET_ENVIRONMENTS](state, { payload: { environments }}) {
    return state.set('environments', environments);
  },
  default: (state = defaultState) => {
    return state;
  }
});

export const actionCreators = {
  setEnvironments(environments) {
    return dispatch => {
      dispatch({
        type: SET_ENVIRONMENTS,
        payload: {
          environments
        }
      });
    };
  },
  getRelatedDataElements(secret, params) {
    return apiActions.apiAction({
      stateKey: SECRETS_LIST_STATE_KEY,
      name: 'getSecretDataElements',
      urlData: {
        ...params,
        secret: secret.id,
      },
      loadAllPages: true
    });
  },
  startPaginatedSecretsStatusPolling() {
    return async(dispatch, getState) => {
      if (!isPollingPaginatedListOfSecretsFromState(getState())) {
        getPaginatedSecretsAbortController.abort();
        getPaginatedSecretsAbortController = new AbortController();

        dispatch(paginatedSecretsStatusPollingActions.startPolling(async() => {
          const result = await dispatch(listActions.loadList({
            abortSignal: getPaginatedSecretsAbortController?.signal
          }));
          const environments = result?.res?.body?.included || [];
          dispatch(actionCreators.setEnvironments(environments));

          const secrets = getPaginationFromState(SECRETS_LIST_STATE_KEY, getState())?.pageItems || [];
          const pageHasPendingSecrets = some(secrets, secret => {
            return Boolean(
              secret.meta?.refresh_status === 'pending' ||
              secret.attributes.status === 'pending'
            );
          });

          if (!pageHasPendingSecrets) {
            dispatch(paginatedSecretsStatusPollingActions.stopPolling());
            getPaginatedSecretsAbortController.abort();
          } else if (!isPollingPaginatedListOfSecretsFromState(getState())) {
            return dispatch(actionCreators.startPaginatedSecretsStatusPolling());
          }
        }));
      }
    };
  },
  stopSecretsPolling() {
    return (dispatch, getState) => {
      if (isPollingPaginatedListOfSecretsFromState(getState())) {
        dispatch(paginatedSecretsStatusPollingActions.stopPolling());
      }
      getPaginatedSecretsAbortController.abort();
    };
  }
};
