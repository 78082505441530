/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import actionsHandler from '../../redux/actionsHandler';
import Immutable from 'seamless-immutable';

export const SET_IS_STORAGE_SUPPORTED = 'globals/SET_IS_STORAGE_SUPPORTED';
export const OPEN_GLOBAL_MESSAGE = 'globals/OPEN_GLOBAL_MESSAGE';
export const CLOSE_GLOBAL_MESSAGE = 'globals/CLOSE_GLOBAL_MESSAGE';
export const FIRST_AUTH_SUCCEEDED = 'globals/FIRST_AUTH_SUCCEEDED';
export const UNAUTHORIZED = 'globals/UNAUTHORIZED';
export const FORBIDDEN = 'globals/FORBIDDEN';
export const SET_BACKDROP_VISIBLE = 'globals/SET_BACKDROP_VISIBLE';
export const SET_MAINTENANCE_ACTIVE = 'globals/SET_MAINTENANCE_ACTIVE';
export const SET_SHOULD_USER_SEE_COMPANIES = 'globals/SET_SHOULD_USER_SEE_COMPANIES';
export const SET_LEFT_NAV_FEATURE_FLAGS = 'shellActions/SET_LEFT_NAV_FEATURE_FLAGS';

//Reducers
export default actionsHandler({
  [SET_LEFT_NAV_FEATURE_FLAGS](state, { payload:{featureFlags} }) {
    return state.set('featureFlags',featureFlags);
  },
  [SET_IS_STORAGE_SUPPORTED](state, { payload:{storageSupported} }) {
    return state.merge({storageSupported});
  },
  [FIRST_AUTH_SUCCEEDED](state) {
    return state.set('firstAuthSucceeded', true);
  },
  [UNAUTHORIZED](state, { payload:{isUnauthorized} }) {
    return state.set('apiUnauthorized', isUnauthorized);
  },
  [FORBIDDEN](state, { payload:{isForbidden} }) {
    return state.set('apiForbidden', isForbidden);
  },
  [SET_BACKDROP_VISIBLE](state, { payload:{visible} }) {
    return state.set('backdropVisible', visible);
  },
  [SET_MAINTENANCE_ACTIVE](state, { payload:{active} }) {
    return state.set('maintenanceActive', active);
  },
  [SET_SHOULD_USER_SEE_COMPANIES](state, { payload:{shouldUserSeeCompanies} }) {
    return state.set('shouldUserSeeCompanies', shouldUserSeeCompanies);
  },
  default(state = Immutable({
    storageSupported: true,
    firstAuthSucceeded: false,
    apiUnauthorized: false,
    apiForbidden: false,
    backdropVisible: false,
    maintenanceActive: false,
    shouldUserSeeCompanies: false
  })) {
    return state;
  }
});

//Action Creators
export let actionCreators = {
  setStorageSupported(storageSupported) {
    return {
      type: SET_IS_STORAGE_SUPPORTED,
      payload: { storageSupported }
    };
  },
  setFirstAuthSucceeded() {
    return { type: FIRST_AUTH_SUCCEEDED };
  },
  setApiUnauthorized(isUnauthorized) {
    return {
      type: UNAUTHORIZED,
      payload: { isUnauthorized }
    };
  },
  setApiForbidden(isForbidden) {
    return {
      type: FORBIDDEN,
      payload: { isForbidden }
    };
  },
  setBackdropVisible(visible) {
    return {
      type: SET_BACKDROP_VISIBLE,
      payload: { visible }
    };
  },
  maintenanceActive(active) {
    return {
      type: SET_MAINTENANCE_ACTIVE,
      payload: { active }
    };
  },
  setShouldUserSeeCompanies(shouldUserSeeCompanies) {
    return {
      type: SET_SHOULD_USER_SEE_COMPANIES,
      payload: { shouldUserSeeCompanies }
    };
  },
  setLeftNavFlags(featureFlags) {
    return (dispatch)=>{
      dispatch({
        type: SET_LEFT_NAV_FEATURE_FLAGS,
        payload: {featureFlags}
      });
    };
  }
};
