/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import {
  AirbrakeErrorBoundary
} from './utils/errorReporter';

import 'whatwg-fetch';
import './style.spectrum.pattern';
import './style.main.pattern';
import ReactSpectrumProviderV2 from '@react/react-spectrum/Provider';
import {
  defaultTheme,
  Provider as ReactSpectrumProviderV3
} from '@adobe/react-spectrum';
import ReactDOM from 'react-dom';
import React from 'react';
import {IntlProvider} from 'react-intl';
// import locales from './i18n/locales';
import formats from './i18n/formats';
import messages from './i18n/en';
import Routes from './routes/routes';
import {Provider as ReduxProvider} from 'react-redux';
import ImsWrapper from './components/higherOrderComponents/imsWrapper';
import Toasts from './components/higherOrderComponents/toasts';
import ConfirmNavigationDialog from './components/higherOrderComponents/confirmNavigationDialog';
import TermsOfUseDialog from './components/higherOrderComponents/termsOfUseDialog';
import {applyPolyfills} from './utils/polyfills';
import {applySecureRedirect} from './utils/secureRedirect';
import {ConnectedRouter} from 'connected-react-router';
import {exposeToastActions} from './components/higherOrderComponents/toastsActions';
import DocumentTitle from './utils/DocumentTitle';
import {initLogRocket} from './utils/logrocketUtils';
import {rootDocumentTitle} from './routes/routes';
import {updateUnifiedShellTitleByRoutePath} from './components/higherOrderComponents/imsWrapperUtils';
import PrivacySettingsDialog from './components/higherOrderComponents/PrivacySettingsDialog';
import {initializeStoreWithBrowserHistory, exposeStore} from './utils/store';

const bootstrap = async()=>{
  applySecureRedirect();
  await applyPolyfills();
  initLogRocket();
  const {store, browserHistory} = await initializeStoreWithBrowserHistory();
  exposeStore();
  exposeToastActions(store.dispatch);

  browserHistory.listen((location) =>  {


    // this will re-inject supported query params that are not in the url due to navigation
    let locationSearch = location.search;
    window.environmentSettings.lens_configUrlParamKeys.forEach((paramKey) => {
      if (window.environmentSettings[paramKey] && !location?.search?.includes(paramKey)) {
        locationSearch = `${locationSearch}${locationSearch.includes('?') ? '&' : '?'}${paramKey}=${window.environmentSettings[paramKey]}`;
      }
    });

    if ( locationSearch !== location.search ) {
      browserHistory.replace({
        ...location,
        search: locationSearch
      });
    }

    updateUnifiedShellTitleByRoutePath(location.pathname);
  });

  ReactDOM.render(
    <ReduxProvider store={store} key="provider">
      <div id="exc-control-wrapper" className="u-flex u-flexOne">
        <ReactSpectrumProviderV2
          theme="light"
          typekitId={window.environmentSettings.lens_typekitId}
          className="u-flex u-flexOne reactSpectrumProvider-v2"
        >
          <ReactSpectrumProviderV3
            theme={defaultTheme}
            colorScheme="light"
            width="100%"
            UNSAFE_className="reactSpectrumProvider-v3 u-flex u-flexOne"
          >
            <ConnectedRouter history={browserHistory}>
              <ImsWrapper>
                <AirbrakeErrorBoundary>
                  <IntlProvider locale="en" formats={formats} messages={messages}>
                    <DocumentTitle title={rootDocumentTitle}>
                      <React.Fragment>{/*this fragment is needed to force DocumentTitle to not create an extra div*/}
                        <Routes />
                        <Toasts />
                        <PrivacySettingsDialog />
                        <ConfirmNavigationDialog />
                        <TermsOfUseDialog />
                      </React.Fragment>
                    </DocumentTitle>
                  </IntlProvider>
                </AirbrakeErrorBoundary>
              </ImsWrapper>
            </ConnectedRouter>
          </ReactSpectrumProviderV3>
        </ReactSpectrumProviderV2>
      </div>
    </ReduxProvider>,
    document.getElementById('dtm-ui-wrapper')
  );

};

bootstrap();
