// fix uncontrolled component being converted to controlled component

import autobind from 'autobind-decorator';
import Button from '@react/react-spectrum/Button';
import classNames from 'classnames';
import {cloneIcon} from '@react/react-spectrum/utils/icon';
import CrossSmall from '@react/react-spectrum/Icon/core/CrossSmall';
import intlMessages from '@react/react-spectrum/Search/intl/*.json';
import Magnifier from '@react/react-spectrum/Icon/core/Magnifier';
import {messageFormatter} from '@react/react-spectrum/utils/intl';
import React, {Component} from 'react';
import Textfield from 'spectrum-alternatives/Textfield';

importSpectrumCSS('search');
const formatMessage = messageFormatter(intlMessages);

const normalizeValue = value => value || '';

@autobind
export default class Search extends Component {
  static defaultProps = {
    icon: <Magnifier />,
    onChange: function() {},
    onSubmit: function() {}
  };

  constructor(props) {
    super(props);

    const {
      value,
      defaultValue
    } = props;

    this.state = {
      value: normalizeValue(value || defaultValue),
    };
  }

  componentDidUpdate() {
    if (this.props.value && normalizeValue(this.props.value) !== this.state.value) { // fixed
      this.setState({
        value: this.props.value
      });
    }
  }

  handleTextKeyDown(e) {
    const {onSubmit, onKeyDown, disabled} = this.props;
    const {value} = this.state;
    const key = e.which;

    if (key === 13 || key === 27) {
      e.preventDefault();
    }

    if (disabled) {
      return;
    }

    if (key === 13) {
      onSubmit(value);
    }

    if (key === 27) {
      this.handleTextChange('', e, 'escapeKey');
    }

    if (onKeyDown) {
      onKeyDown(e);
    }
  }

  handleTextChange(value, e, from = 'input') {
    const {onChange, disabled} = this.props;
    if (disabled || value === this.state.value) {
      return;
    }

    if (!('value' in this.props)) {
      this.setState({
        value
      });
    }

    onChange(value, e, {from});
  }

  handleClearButtonClick(e) {
    this.handleTextChange('', e, 'clearButton');
    // restore focus to the searchbox
    if (this.searchbox) {
      this.searchbox.focus();
    }
  }

  render() {
    const {
      disabled,
      className,
      icon,
      role = 'search',
      ...otherProps
    } = this.props;
    const {value} = this.state;

    return (
      <div
        role={role}
        className={
          classNames(
            'spectrum-Search',
            {'is-disabled': disabled},
            className
          )
        }
      >
        <Textfield
          role="searchbox"
          ref={s => this.searchbox = s}
          className="spectrum-Search-input"
          value={value}
          disabled={disabled}
          {...otherProps}
          onKeyDown={this.handleTextKeyDown}
          onChange={this.handleTextChange}
        />
        {cloneIcon(icon, {className: 'spectrum-Search-icon', size: 'S'})}
        {
          value !== '' &&
            <Button
              aria-label={formatMessage('Clear search')}
              variant="clear"
              icon={<CrossSmall />}
              disabled={disabled}
              onClick={this.handleClearButtonClick}
            />
        }
      </div>
    );
  }
}

Search.displayName = 'Search';
