/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import React from 'react';
import actionsHandler from '../../../redux/actionsHandler';
import { actionCreators as apiActions } from '../../../utils/api/apiActions';
import Immutable from 'seamless-immutable';
import {push} from '../../../routes/namedRouteUtils';
import {apiItemLinksToIds} from '../../../utils/api/apiNormalizers';
import {getNewLibrary, getFirstUpstreamLibrary, getLibraryLocation, getLibraryEnvironment, deNormalizeLibraryData, addWarningStatesToDelegates, getEnvironmentLocation, getPropertyLocation} from './publishingUtils';
import {resourcesHaveSameOrigin} from '../../../utils/resourceUtils';
import {actionCreators as revisionSelectorActions} from './RevisionSelector/revisionSelectorActions';
import {actionCreators as libraryUpstreamActions} from './LibraryUpstream/libraryUpstreamActions';
import {actionCreators as getExpandedActions} from '../../CustomTable/expandedActions';
import {actionCreators as toastsActions} from '../../higherOrderComponents/toastsActions';
import {actionCreators as workingLibraryActions} from '../workingLibraryActions';
import {
  getBuildError,
  getPromotionErrorFromState,
  getEnvironmentsByStage,
  getLibraryResourcesFromState,
  getDevelopmentLibrariesFromState,
  isPollingDevelopmentLibrariesFromState,
  getNonDevelopmentLibrariesFromState,
  isPollingNonDevelopmentLibrariesFromState,
  getAllDevAndNonDevLibrariesFromState,
  getIncludedForNonDevelopmentLibrariesFromState
} from './publishingSelectors';
import { scrollElementIntoView } from '../../../utils/domUtils';
import {
  publishingLoadAllPages,
  getApiMappingName,
  getAppError,
  APP_ERROR_CODES,
  getApiData
} from '../../../utils/api/apiTools';
import {cloneDeep, get} from 'lodash-es';
import {Link} from '../../../routes/namedRouteUtils';
import {RULES, DATA_ELEMENTS, EXTENSIONS} from '../../../utils/api/apiTypes';
import {isOlderOrSameVersionOfSameExtension} from '../extensions/extensionsUtils';
import {filterListFromList} from '../../../utils/dataUtils';

export const INIT_DELEGATES = 'publishing/INIT_DELEGATES';
export const CLEAN_LIBRARY = 'publishing/CLEAN_LIBRARY';
export const SET_LATEST_DELEGATES = 'publishing/SET_LATEST_DELEGATES';
export const ADD_DELEGATE_TO_LIBRARY = 'publishing/ADD_DELEGATE_TO_LIBRARY';
export const REMOVE_DELEGATE_FROM_LIBRARY = 'publishing/REMOVE_DELEGATE_FROM_LIBRARY';
export const REPLACE_DELEGATE_IN_LIBRARY = 'publishing/REPLACE_DELEGATE_IN_LIBRARY';
export const SET_ACTIVE_DIALOG = 'publishing/SET_ACTIVE_DIALOG';
export const SET_WORKING_LIBRARY = 'publishing/SET_WORKING_LIBRARY';
export const SET_MISSING_EXTENSIONS_INFO = 'publishing/SET_MISSING_EXTENSIONS_INFO';
export const SET_EXTENSION_VALIDATION_LOADING = 'publishing/SET_EXTENSION_VALIDATION_LOADING';
export const SET_NON_DEVELOPMENT_LIBRARIES_BUILDS = 'publishing/SET_NON_DEVELOPMENT_LIBRARIES_BUILDS';

import { actionCreators as asyncPollActions } from '../../../utils/asyncPollActions';
import { getSessionStorageItem } from '../../../utils/storageUtils';
import { removeSessionStorageItem } from '../../../utils/storageUtils';
import { setSessionStorageItem } from '../../../utils/storageUtils';
import { getRevisionRangeParameterName, getRevisionRange } from '../../compareView/compareViewUtils';
import { replace } from '../../../routes/namedRouteUtils';
import { getCurrentRouteParamsFromState } from '../../../routes/routeSelectors';
import { getApiMappingFromId } from '../../../utils/resourceUtils';
import { getSingularResourceTypeFromId } from '../../../utils/resourceUtils';
import { getRevisionIdsFromRevisionRange } from '../../compareView/compareViewUtils';
import { getResourceTypeFromId } from '../../../utils/resourceUtils';
import { COMPARE_BASE_RESOURCE_STATE_KEY } from '../../compareView/componentCompareView/componentCompareActions';
import { getCompareRightSideResourceId } from '../../compareView/compareViewSelectors';


export const PUBLISHING_KEY = 'publishing';
export const LIBRARY_EDIT_KEY = 'libraryEdit';
export const REPUBLISH_KEY = 'republish';

let abortController;

export const libraryTypes = {
  buildStatus: {
    PENDING: 'pending',
    SUCCESS: 'succeeded',
    FAILED: 'failed'
  },
  states: {
    REJECTED: 'rejected',
    DEVELOPMENT: 'development',
    SUBMITTED: 'submitted',
    APPROVED: 'approved',
    PUBLISHED: 'published'
  },
  transitions: {
    DEVELOP: 'develop',
    SUBMIT: 'submit',
    REJECT: 'reject',
    APPROVE: 'approve'
  }
};

export const environmentStates = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production'
};

// these should become error numbers with internationalized translations at some point
export const publishingMessages = {
  NO_ENVIRONMENT: 'Library has no attached environment. Attach an environment and then try again.',
  BUILD_REQUIRED: 'There has not been a successful build since the last state change. ' +
    'Build the library and then try again.',
  UPSTREAM_CHANGES: 'Library cannot be submitted until other upstream libraries have ' +
    'been published.',
  NO_DEVELOPMENT_ENVIRONMENT: 'No development environment exists. Create one and then try again.',
  NO_STAGING_ENVIRONMENT: 'No staging environment exists. Create one and then try again.',
  NO_PRODUCTION_ENVIRONMENT: 'No production environment exists. Create one and then try again.',
  ENVIRONMENT_MIGRATION_FAILED: 'The current production environment failed to link from to the DTM embed code. ' +
    'It must be removed. Then you can create a new production environment and publish this library again.',
  NEW_REVISION_CREATION: 'A new revision of this resource will be created from the latest save.',
  REVISION_IN_OTHER_DEV_LIBRARY:
    'This revision is in use by another library in development.',
  REVISION_IN_UPSTREAM_LIBRARY:
    'This revision is in use by a previously submitted library and will prevent ' +
    'a successful build. Remove this change or select a different revision.',
  REVISION_DISABLED:
    'This revision is disabled and will be removed from the build when published.',
  ARCHIVE_OUTDATED:
    'This library\'s environment has been changed since it was last built. ' +
    'Its archive has been outdated. Try again after rebuilding this library.',
  ARCHIVE_OUTDATED_PUBLISHED:
    'This library\'s environment has been changed since it was last built. ' +
    'Its archive has been outdated. Try again after publishing another library.'
};

export const PUBLISHING_KEYS = {
  DEVELOPMENT: `${PUBLISHING_KEY}_developmentLibraries`,
  NON_DEVELOPMENT: `${PUBLISHING_KEY}_nonDevelopmentLibraries`,
};

const developmentLibrariesPollingActions = asyncPollActions(PUBLISHING_KEYS.DEVELOPMENT);
const nonDevelopmentLibrariesPollingActions = asyncPollActions(PUBLISHING_KEYS.NON_DEVELOPMENT);
const republishPollingActions = asyncPollActions(REPUBLISH_KEY);
let devLibrariesAbortController = new AbortController();
let nonDevLibrariesAbortController = new AbortController();
let devLibrariesPromise;
let nonDevLibrariesPromise;

const defaultState = Immutable({
  initialSelectedDelegates: [],
  selectedDelegates: [],
  manualDialog: {
    header: null,
    content: null,
    footer: null
  },
  latestDelegates: [],
  activeDialog: null,
  workingLibrary: null,
  extensionValidationLoading: null,
  missingExtensions: [],
  missingExtensionsUninstalled: [],
  missingUpgradedExtensions: [],
  resourcesToRemove: [],
  requiredResourceData: [],
  nonDevelopmentLibrariesBuilds: null
});

//Reducers
export default actionsHandler({
  [INIT_DELEGATES](state, { payload: { delegates } }) {
    return state.merge({
      'initialSelectedDelegates': delegates,
      'selectedDelegates': delegates
    });
  },
  [CLEAN_LIBRARY]() {
    return defaultState;
  },
  [SET_LATEST_DELEGATES](state, { payload: { latestDelegates } }) {
    return state.set('latestDelegates', latestDelegates);
  },
  [ADD_DELEGATE_TO_LIBRARY](state, { payload: { delegate } }) {
    return state.update('selectedDelegates', (selectedDelegates) => {
      return selectedDelegates.concat(delegate);
    });
  },
  [REMOVE_DELEGATE_FROM_LIBRARY](state, { payload: { delegate } }) {
    return state.update('selectedDelegates', (selectedDelegates) => {
      const indexToDelete = selectedDelegates.findIndex((selectedDelegate) => {
        return delegate.id === selectedDelegate.id;
      });
      return selectedDelegates
        .slice(0, indexToDelete)
        .concat(selectedDelegates.slice(indexToDelete + 1));
    });
  },
  [REPLACE_DELEGATE_IN_LIBRARY](state, { payload: { oldDelegate, newDelegate } }) {
    const oldDelegateLinks = apiItemLinksToIds(oldDelegate.links);
    return state.update('selectedDelegates', (selectedDelegates) => {
      const indexToReplace = selectedDelegates.findIndex((selectedDelegate) => {
        const oldId = (oldDelegateLinks.origin || oldDelegate.id);
        const isBlank = selectedDelegate.isBlank;
        const currentLinks = isBlank ? {} : apiItemLinksToIds(selectedDelegate.links);
        const currentId = currentLinks.origin || selectedDelegate.id;
        return oldId === currentId;
      });

      return selectedDelegates.slice(0, indexToReplace)
        .concat(newDelegate)
        .concat(selectedDelegates.slice(indexToReplace + 1));
    });
  },
  [SET_ACTIVE_DIALOG](state, { payload: { dialogKey, header, content, footer, variant } }) {
    let newState = state.set('activeDialog', dialogKey);
    // set content for manualDialog (alert messages)
    if (typeof header === 'string') {
      newState = newState.setIn(['manualDialog', 'header'], header);
    }
    if (typeof content === 'string') {
      newState = newState.setIn(['manualDialog', 'content'], content);
    }
    if (typeof footer === 'string') {
      newState = newState.setIn(['manualDialog', 'footer'], footer);
    }
    if (typeof variant === 'string') {
      newState = newState.setIn(['manualDialog', 'variant'], variant);
    }
    return newState;
  },
  [SET_WORKING_LIBRARY](state, { payload: { library } }) {
    return state.set('workingLibrary', library);
  },
  [SET_MISSING_EXTENSIONS_INFO](state, {
    payload: {
      missingExtensions,
      missingExtensionsUninstalled,
      missingUpgradedExtensions,
      resourcesToRemove,
      requiredResourceData
    }
  }) {
    return state.merge({
      missingExtensions,
      missingExtensionsUninstalled,
      missingUpgradedExtensions,
      resourcesToRemove,
      requiredResourceData
    });
  },
  [SET_EXTENSION_VALIDATION_LOADING](state, { payload: { isLoading } }) {
    return state.set('extensionValidationLoading', isLoading);
  },
  [SET_NON_DEVELOPMENT_LIBRARIES_BUILDS](state, { payload: { nonDevelopmentLibrariesBuilds } }) {
    return state.merge({nonDevelopmentLibrariesBuilds});
  },
  default: (state = defaultState) => {
    return state;
  }
});

const isAnyLibraryOrBuildPending = (libraries = [], builds = []) => {
  let libraryPending = libraries?.some(library => library?.meta?.buildStatus === libraryTypes.buildStatus.PENDING);
  let buildRepublishPending = builds?.some(build => build?.meta?.republishStatus === 'pending');
  return libraryPending || buildRepublishPending;
};



//Action Creators
export function getActionCreators(actionsKey) {
  const actionCreators = {
    initDelegates(delegates) {
      abortController = new AbortController();

      return {
        type: INIT_DELEGATES,
        payload: { delegates }
      };
    },
    cleanLibrary() {
      return (dispatch) => {
        dispatch(apiActions.resetEndpoint('getLibrary'));
        dispatch(apiActions.resetEndpoint('getLibraryRules'));
        dispatch(apiActions.resetEndpoint('getLibraryDataElements'));
        dispatch(apiActions.resetEndpoint('getLibraryExtensions'));

        return dispatch({ type: CLEAN_LIBRARY });
      };
    },
    loadDevelopmentLibraries({
      params,
      urlParams = {},
      loadAllPages = false,
      updateState = true,
      stateKey = PUBLISHING_KEYS.DEVELOPMENT,
      abortSignal,
    }) {
      return (dispatch) => {
        return dispatch(apiActions.apiAction({
          name: 'getLibraries',
          urlData: { ...params },
          urlParams: {
            'sort': '-updated_at',
            ...urlParams,
            'filter[state]': 'EQ development,EQ rejected'
          },
          loadAllPages,
          stateKey,
          updateState,
          abortSignal,
        }));
      };
    },
    loadNonDevelopmentLibraries({
      params,
      urlParams = {},
      loadAllPages = false,
      updateState = true,
      stateKey = PUBLISHING_KEYS.NON_DEVELOPMENT,
      abortSignal,
    }) {
      return (dispatch, getState) => {
        return dispatch(apiActions.apiAction({
          name: 'getLibraries',
          urlData: { ...params },
          urlParams: {
            sort: '-updated_at',
            ...urlParams,
            'filter[state]': 'EQ submitted,EQ approved,EQ published',
            include: 'last_build'
          },
          loadAllPages,
          stateKey,
          updateState,
          abortSignal,
        })).then((result)=>{
          const nonDevelopmentLibrariesBuilds = getIncludedForNonDevelopmentLibrariesFromState(getState()) || [];
          dispatch(actionCreators.setNonDevelopmentLibrariesBuilds(nonDevelopmentLibrariesBuilds));
          return result;
        });
      };
    },
    loadLibraries({
      params,
      urlParams,
      updateState = true,
      loadAllPages = false,
      stateKey,
      abortSignal,
    }) {
      return (dispatch) => {
        return dispatch(apiActions.apiAction({
          name: 'getLibraries',
          urlData: { ...params },
          urlParams: {
            sort: '-updated_at',
            ...urlParams
          },
          updateState,
          loadAllPages,
          stateKey,
          abortSignal,
        }));
      };
    },
    loadLibrary(params, urlParams, updateState = true) {
      return (dispatch) => {
        return dispatch(apiActions.apiAction({
          name: 'getLibrary',
          urlData: { ...params },
          urlParams,
          updateState,
          indlude: 'last_build'
        }));
      };
    },
    loadLibraryAndSetWorkingLibrary(params, urlParams, updateState = true) {
      return (dispatch, getState) => {
        return dispatch(actionCreators.loadLibrary(params, urlParams, updateState)).then(() => {
          if (updateState) {
            const state = getState();
            return dispatch(actionCreators.setWorkingLibrary(state.api.library));
          }
        });
      };
    },
    // only loads specific revisions of resources
    // does not respect filters
    loadLibraryResources(params) {
      return (dispatch) => {
        return Promise.all([
          dispatch(apiActions.apiAction({
            name: 'getLibraryRules',
            urlData: { ...params },
            loadAllPages: true
          })),
          dispatch(apiActions.apiAction({
            name: 'getLibraryDataElements',
            urlData: { ...params },
            loadAllPages: true
          })),
          dispatch(apiActions.apiAction({
            name: 'getLibraryExtensions',
            urlData: { ...params },
            loadAllPages: true
          })),
        ]);
      };
    },
    loadLibraryResourcesAndUpstreamBuild(params) {
      return (dispatch, getState) => {
        return Promise.all([
          dispatch(actionCreators.loadLibraryAndSetWorkingLibrary(params)).then(() => {
            const library = getState().api.library;

            // we have to load the upstream library to get its build ID
            // which we can use to load the build contents :\
            const upstreamLibrary = get(library, 'relationships.upstreamLibrary.data');
            // ...but only if there is an upstream to load
            return upstreamLibrary ?
              dispatch(libraryUpstreamActions.loadUpstreamBuild(upstreamLibrary)) : true;
          }),
          dispatch(actionCreators.loadLibraryResources(params)).then(() => {
            return dispatch(actionCreators.initDelegates(
              getLibraryResourcesFromState(getState())
            ));
          })
        ]);
      };
    },
    // loads upstream resources of libraries in submitted or approved states
    // does not load published resources
    // does not update state while loading
    loadSubmittedAndApprovedLibraryResources(params) {
      return (dispatch, getState) => {
        return dispatch(actionCreators.loadLibraries({
          params,
          updateState: false,
          loadAllPages: false,
          urlParams: {
            'filter[state]': 'EQ submitted,EQ approved',
            'sort': '-updated_at'
          }
        })).then((result) => {
          return get(result, 'res.body.data[0]');
        }).then((upstreamLibrary) => {
          if (upstreamLibrary) {
            return dispatch(actionCreators.loadLibraryResources({
              ...params,
              library: upstreamLibrary.id
            }));
          }
        }).then(() => {
          return getLibraryResourcesFromState(getState()) || [];
        });
      };
    },
    loadCompareBaseResource(resourceId) {
      return (dispatch, getState) => {
        const currentRouteParams = getCurrentRouteParamsFromState(getState());
        if (!resourceId) {
          resourceId = getCompareRightSideResourceId(currentRouteParams);
        }

        if (resourceId) {
          return dispatch(actionCreators.loadResource({
            resourceId: resourceId,
            params: currentRouteParams,
            stateKey: COMPARE_BASE_RESOURCE_STATE_KEY,
            abortSignal: abortController?.signal
          }));
        }
      };
    },
    resetCompareBaseResource() {
      return (dispatch) => {
        dispatch(apiActions.resetData([
          'rule',
          'dataElement',
          'extension'
        ], COMPARE_BASE_RESOURCE_STATE_KEY));
      };
    },
    loadResource({
      resourceId,
      params,
      stateKey,
      abortSignal
    } = {}) {
      return (dispatch, getState) => {
        const singularType = getSingularResourceTypeFromId(resourceId);
        return dispatch(apiActions.apiAction({
          name: getApiMappingFromId(resourceId),
          urlData: {...params, [singularType]: resourceId},
          stateKey,
          abortSignal
        })).then(()=>{
          const resource = getApiData(getState(), stateKey)[singularType];
          return resource;
        });
      };
    },
    loadLatestDelegates(params) {
      return (dispatch) => {
        // get all resources at head
        return dispatch(actionCreators.loadAllChangedResources(params, {
          'filter[revision_number]': 'EQ 0'
        })).then((latestDelegates) => {
          return dispatch({
            type: SET_LATEST_DELEGATES,
            payload: { latestDelegates }
          });
        }).catch((err) => {
          throw err;
        });
      };
    },
    // used by "Save to Library" feature
    saveLatestDelegatesToLibrary(params, latestDelegates, library) {
      return (dispatch, getState)=>{
        // if the library is rejected then we need to transition it to development
        // before updating it
        const transitionLibraryPromise = (
          library.attributes.state !== libraryTypes.states.DEVELOPMENT
        ) ? dispatch(
          actionCreators.transitionLibrary(params, library, libraryTypes.transitions.DEVELOP)
        ) : Promise.resolve();

        return transitionLibraryPromise.then(()=>{
          const latestDelegatesOrigins = latestDelegates.map((latestDelegate) => {
            return apiItemLinksToIds(latestDelegate.links).origin;
          });

          // load the library resources
          let libraryResources = [];
          return dispatch(actionCreators.loadLibraryResources(params)).then(()=>{
            libraryResources = getLibraryResourcesFromState(getState());
            // if any of the delegates are already in the library, remove them
            // so we can add head (latest)
            libraryResources = libraryResources.filter((libraryResource)=>{
              const links = apiItemLinksToIds(libraryResource.links);
              return !latestDelegatesOrigins.includes(links.origin);
            });
            // add the latest delegates to the library
            libraryResources = libraryResources.concat(latestDelegates);

            // save the library
            return dispatch(actionCreators.updateLibrary(params, {
              id: library.id,
              name: library.attributes.name,
              selectedDelegates: libraryResources,
              selectedEnvironment: get(library, 'relationships.environment.data.id') || null
            }));
          });
        });
      };
    },
    // used by "Save to Library and Build" feature
    saveLatestDelegatesToLibraryAndBuild(params, latestDelegates, library) {
      return (dispatch) => {
        dispatch(actionCreators.saveLatestDelegatesToLibrary(params, latestDelegates, library)).then(() => {
          // start the build
          if (getLibraryEnvironment(library)) {
            return dispatch(actionCreators.buildLibraryAndStartPolling(
              params, library
            ));
          } else {
            dispatch(actionCreators.setWorkingLibraryAndDialog(library, 'noEnvironment'));
          }
        });
      };
    },
    saveLibrary(params, saveData) {
      return (dispatch) => {
        return dispatch(apiActions.apiAction({
          name: 'createLibrary',
          urlData: { ...params },
          data: deNormalizeLibraryData(saveData)
        }));
      };
    },
    updateLibrary(params, saveData) {
      return (dispatch) => {
        const denormalizedData = deNormalizeLibraryData(saveData);
        return dispatch(apiActions.apiAction({
          name: 'updateLibrary',
          urlData: { ...params },
          data: denormalizedData
        }));
      };
    },
    transitionLibrary(params, saveData, transitionType) {
      return (dispatch) => {
        return dispatch(apiActions.apiAction({
          name: 'updateLibrary',
          urlData: { ...params },
          data: {
            data: {
              id: saveData.id,
              type: saveData.type,
              attributes: {},
              meta: {
                action: transitionType
              }
            }
          }
        }));
      };
    },
    deleteLibrary(params) {
      return apiActions.apiAction({
        name: 'deleteLibrary',
        urlData: { ...params }
      });
    },
    loadEnvironments(params) {
      return apiActions.apiAction({
        name: 'getEnvironments',
        urlData: { ...params }
      });
    },
    loadRules(params) {
      return apiActions.apiAction({
        name: 'getRules',
        urlData: { ...params }
      });
    },
    loadDataElements(params) {
      return apiActions.apiAction({
        name: 'getDataElements',
        urlData: { ...params }
      });
    },
    loadBuilds(params) {
      return apiActions.apiAction({
        name: 'getBuilds',
        urlData: { ...params }
      });
    },
    loadLatestBuild(params) {
      return apiActions.apiAction({
        name: 'getBuilds',
        urlData: params,
        urlParams: { 'page[size]': '1' },
        stateKey: 'latestBuild'
      });
    },
    loadExtension(params) {
      return apiActions.apiAction({
        name: 'getExtension',
        urlData: { ...params }
      });
    },
    loadExtensions(params) {
      return apiActions.apiAction({
        name: 'getExtensions',
        urlData: { ...params }
      });
    },
    addDelegateToLibrary(delegate) {
      return {
        type: ADD_DELEGATE_TO_LIBRARY,
        payload: { delegate }
      };
    },
    removeDelegateFromLibrary(delegate) {
      return {
        type: REMOVE_DELEGATE_FROM_LIBRARY,
        payload: { delegate }
      };
    },
    replaceDelegateInLibrary(oldDelegate, newDelegate) {
      return {
        type: REPLACE_DELEGATE_IN_LIBRARY,
        payload: { oldDelegate, newDelegate }
      };
    },
    goToPublishing(params) {
      return push({ name: PUBLISHING_KEY, params });
    },
    goToLibraryEdit(params, library) {
      return push(getLibraryLocation(params, library));
    },
    goToEnvironments(params) {
      return push({ name: 'environments', params });
    },
    goToEnvironmentEdit(params, environment) {
      return push(getEnvironmentLocation(params, environment));
    },
    buildLibrary(params) {
      return (dispatch) => {
        return dispatch(apiActions.apiAction({
          name: 'createBuild',
          urlData: { ...params }
        }));
      };
    },
    setActiveDialog(dialogKey, dialogHeader, dialogContent, dialogFooter, variant) {
      return {
        type: SET_ACTIVE_DIALOG,
        payload: {
          dialogKey: dialogKey || '',
          header: dialogHeader || null,
          content: dialogContent || null,
          footer: dialogFooter || null,
          variant: variant || 'default'
        }
      };
    },
    setWorkingLibrary(library) {
      return {
        type: SET_WORKING_LIBRARY,
        payload: { library }
      };
    },
    setMissingExtensionsInfo(
      missingExtensions,
      missingExtensionsUninstalled,
      missingUpgradedExtensions,
      resourcesToRemove,
      requiredResourceData
    ) {
      return {
        type: SET_MISSING_EXTENSIONS_INFO,
        payload: {
          missingExtensions,
          missingExtensionsUninstalled,
          missingUpgradedExtensions,
          resourcesToRemove,
          requiredResourceData
        }
      };
    },
    // loads all resources using specified urlParams
    // does not update state
    loadAllChangedResources(params, urlParams) {
      return (dispatch) => {
        let loadingPromises = [];
        loadingPromises.push(
          publishingLoadAllPages(dispatch, 'getRules', params, urlParams)
        );
        loadingPromises.push(
          publishingLoadAllPages(dispatch, 'getDataElements', params, urlParams)
        );
        loadingPromises.push(
          publishingLoadAllPages(dispatch, 'getExtensions', params, urlParams)
        );

        return Promise.all(loadingPromises).then(([rules, dataElements, extensions]) => {
          return [].concat(
            rules || [],
            dataElements || [],
            extensions || []
          );
        }).catch((err) => {
          throw err;
        });
      };
    },
    addDisabledChangeToLibrary(params, delegate) {
      return (dispatch) => {
        // prep for save
        let delegateToSave = cloneDeep(delegate);
        delete delegateToSave.attributes.revisionNumber;
        delegateToSave.attributes.enabled = false;

        const links = apiItemLinksToIds(delegateToSave.links);
        const typeSingular = delegateToSave.type.substring(0, delegateToSave.type.length - 1);
        return dispatch(apiActions.apiAction({
          name: getApiMappingName(typeSingular, 'patch'),
          urlData: { ...params, [typeSingular]: links.origin },
          data: { data: delegateToSave }
        })).then(() => {
          // load head
        }).then(() => {
          // add delegate head to library
          dispatch(actionCreators.addDelegateToLibrary(delegateToSave));
        });
      };
    },
    addChangeToLibrary(params) {
      const expandedActions = getExpandedActions(LIBRARY_EDIT_KEY);
      return (dispatch, getState) => {
        const state = getState();
        const selectedDelegates = state.publishing.selectedDelegates;

        dispatch(expandedActions.collapseAll(true));

        const newDelegate = Immutable({
          id: 'new-' + new Date().getTime(),
          isBlank: true
        });

        // only allow 1 new item at a time
        let existingNewDelegate = selectedDelegates.find((delegate) => {
          return delegate.isBlank;
        });
        if (existingNewDelegate) {
          dispatch(expandedActions.toggleExpandedItem(existingNewDelegate, true));
          scrollElementIntoView(
            '[data-resource-id=' + existingNewDelegate.id + ']'
          );
        } else {
          dispatch(revisionSelectorActions.setAll());
          dispatch(actionCreators.addDelegateToLibrary(newDelegate));
          dispatch(expandedActions.toggleExpandedItem(newDelegate, true));
          scrollElementIntoView(
            '[data-resource-id=' + newDelegate.id + ']'
          );
          // select rules by default (save a click in most cases)
          dispatch(revisionSelectorActions.setDelegateType('rules'));
          dispatch(revisionSelectorActions.loadResourceList('rules', params));
        }

        dispatch(actionCreators.loadLatestDelegates(params));
      };
    },
    addAllChangesToLibrary(params) {
      const expandedActions = getExpandedActions(LIBRARY_EDIT_KEY);
      return (dispatch, getState) => {
        const state = getState();
        const selectedDelegates = state.publishing.selectedDelegates;

        dispatch(expandedActions.collapseAll(true));
        dispatch(revisionSelectorActions.setLoading(true));

        Promise.all([
          dispatch(actionCreators.loadAllChangedResources(params, { 'filter[published]': 'EQ false' })),
          dispatch(actionCreators.loadSubmittedAndApprovedLibraryResources(params))
        ]).then(([nonPublishedDelegates, submittedDelegates]) => {
          dispatch(actionCreators.loadLatestDelegates(params));
          dispatch(revisionSelectorActions.setLoading(false));

          // remove submittedDelegates from nonPublishedDelegates so we only have
          // changes that are not upstream
          const changesNotUpstream = nonPublishedDelegates.filter((nonPublishedDelegate) => {
            const foundUpstreamDelegate = submittedDelegates.find((submittedDelegate) => {
              // nonPublishedDelegates always come back as head even if the latest cut revision
              // matches head. Therefore, if the submittedDelegate matches latest (head) and
              // its originId matches the nonPublishedDelegate originId, we consider them a match.

              // this revision is the latest (and therefore will match its upstream reference)
              const submittedRevisionIsLatest = (
                (submittedDelegate.attributes.revisionNumber ===
                  submittedDelegate.meta.latestRevisionNumber)
              );

              const submittedDelegateLinks = apiItemLinksToIds(submittedDelegate.links);
              const nonPublishedDelegateLinks = apiItemLinksToIds(nonPublishedDelegate.links);
              return (
                submittedRevisionIsLatest &&
                submittedDelegateLinks.origin === nonPublishedDelegateLinks.origin
              );
            });

            return nonPublishedDelegate.attributes.dirty ? true : !foundUpstreamDelegate;
          });

          // no changes found
          if (!changesNotUpstream || !changesNotUpstream.length) {
            dispatch(actionCreators.setActiveDialog(
              'manual',
              'No Changes Found',
              'There are no new changes to add!'
            ));
            return;
          }

          // combine existing selected delegates with changed delegates
          const allDelegates = selectedDelegates.filter((delegate) => {
            return !delegate.isBlank; // filter out blank (new) item
          }).concat(changesNotUpstream);

          // unique the list and update the library
          const uniqueDelegates = [];
          allDelegates.forEach((delegate) => {
            const delegateLinks = apiItemLinksToIds(delegate.links);
            // check if anything else with the same originId has already
            // been added to the list (there can only be 1)
            const existingDelegateIndex = uniqueDelegates.findIndex((_delegate) => {
              const _delegateLinks = apiItemLinksToIds(_delegate.links);
              return _delegateLinks.origin === delegateLinks.origin;
            });
            const existingDelegate = uniqueDelegates[existingDelegateIndex];

            // If this delegate is head or if it is a newer revision of something
            // that's already in the list, we will replace the existing item in the list
            if (
              existingDelegate && (
                (
                  delegate.attributes.revisionNumber >
                  existingDelegate.attributes.revisionNumber
                ) || (
                  delegate.attributes.revisionNumber === 0
                )
              )
            ) {
              // replace existing delegate in list with newer revision or head
              // but if it's not already at the latest
              if (
                existingDelegate.attributes.revisionNumber !==
                existingDelegate.meta.latestRevisionNumber ||
                delegate.attributes.revisionNumber === 0 &&
                delegate.attributes.dirty
              ) {
                uniqueDelegates.splice(existingDelegateIndex, 1, delegate);
              }
            } else {
              // it's not already in the list so we'll add it
              uniqueDelegates.push(delegate);
            }
          });

          dispatch(actionCreators.initDelegates(uniqueDelegates));
        }).catch((err) => {
          dispatch(revisionSelectorActions.setLoading(false));
          throw err;
        });
      };
    },
    downloadArchive(params, library, libraryEnvironment, stage, outdatedMessage) {
      return (dispatch) => {
        // load the latest build so we can check if the download link is valid
        dispatch(actionCreators.loadLatestBuild({ ...params, library: library.id })).then((result) => {
          const latestBuild = get(result, 'res.body.data[0]');
          // check if the environment has changed since the latest build
          const environmentHasChanged = latestBuild && (
            new Date(libraryEnvironment.attributes.updatedAt)
            > new Date(latestBuild.attributes.updatedAt)
          );

          if (library.attributes.buildRequired || stage === libraryTypes.states.APPROVED) {
            dispatch(actionCreators.setActiveDialog(
              'manual', 'Build Required', publishingMessages.BUILD_REQUIRED, null, null
            ));
          } else if (environmentHasChanged && stage !== libraryTypes.states.PUBLISHED) {
            dispatch(actionCreators.setActiveDialog(
              'manual', 'Archive Outdated', outdatedMessage, null, null
            ));
          } else {
            // use window.top.location so Unified Shell's CSP doesn't block the download
            window.top.location = latestBuild.meta.directArtifactUrl;
          }
        });
      };
    },
    setNonDevelopmentLibrariesBuilds(nonDevelopmentLibrariesBuilds) {
      return {
        type: SET_NON_DEVELOPMENT_LIBRARIES_BUILDS,
        payload: { nonDevelopmentLibrariesBuilds }
      };
    },


    // ---------------------------------------------------------
    // functions below are workflow functions that get called
    // directly from the views and refresh data when needed
    // ---------------------------------------------------------

    transitionLibraryAndRefreshLibraries(params, library, type, dontRefreshLibraries) {
      return (dispatch) => {
        const paramsWithLibrary = { ...params, library: library.id };
        // the object for saveData needs to be the intial api response
        return dispatch(actionCreators.transitionLibrary(
          paramsWithLibrary, library, type
        )).then(() => {
          dispatch(workingLibraryActions.setWorkingLibraryNeedsRefresh());

          // must refresh libraries after any changes
          return dontRefreshLibraries ? Promise.resolve() : Promise.all([
            dispatch(actionCreators.loadLibraryAndSetWorkingLibrary(paramsWithLibrary)),
            dispatch(actionCreators.loadDevelopmentLibraries({ params: paramsWithLibrary, loadAllPages: true })),
            dispatch(actionCreators.loadNonDevelopmentLibraries({ params: paramsWithLibrary }))
          ]);
        });
      };
    },
    loadUpstreamExtensions(params, library) {
      return (dispatch, getState) => {
        // get upstreamLibrary
        const libraryIsNew = !Boolean(library.id);
        let upstreamLibrary = get(library, 'relationships.upstreamLibrary.data');
        if (!upstreamLibrary || libraryIsNew) {
          upstreamLibrary = getFirstUpstreamLibrary(library, getNonDevelopmentLibrariesFromState(getState()));
        }

        if (upstreamLibrary) {
          return dispatch(libraryUpstreamActions.loadUpstreamBuild(
            upstreamLibrary
          )).then((result) => {
            const upstreamBuildId = get(result, 'res.body.data[0].id');
            return publishingLoadAllPages(dispatch, 'getUpstreamExtensions', {
              ...params,
              build: upstreamBuildId
            });
          });
        } else {
          return Promise.resolve([]);
        }
      };
    },
    validateAndOpenMissingExtensionsDialog(params, onNoChangesRequired) {
      return (dispatch, getState) => {
        const state = getState();
        const library = state.api.library || getNewLibrary();

        const selectedDelegates = state.publishing.selectedDelegates;

        let loadingPromises = [];
        let requiredResourceData = []; // {parentResource, resource, resourceUpdatedWithExtensionId, resourceExtensionId, requiredExtension}
        let includedExtensions = [];
        let missingExtensionsUninstalled = [];
        let missingUpgradedExtensions = [];
        let resourcesToRemove = [];

        dispatch({
          type: SET_EXTENSION_VALIDATION_LOADING,
          payload: { isLoading: true }
        });

        const doneLoadingAction = {
          type: SET_EXTENSION_VALIDATION_LOADING,
          payload: { isLoading: false }
        };

        // populate requiredResourceData
        // disabled things will be removed before the build so we can ignore them
        selectedDelegates.forEach((resource) => {
          if (resource.type === RULES && resource.attributes.enabled) {
            loadingPromises.push(publishingLoadAllPages(dispatch, 'getRuleComponents', {
              ...params,
              rule: resource.id
            }).then((ruleComponents) => {
              ruleComponents.forEach((ruleComponent) => {
                requiredResourceData.push({
                  // the ruleComponent (not the rule) is what actually depends on the extension
                  // but we'll show the rule to the user so that's what we'll include here
                  parentResource: resource,
                  resource: ruleComponent,
                  resourceUpdatedWithExtensionId:
                    ruleComponent.relationships.updatedWithExtension.data.id,
                  resourceExtensionId: ruleComponent.relationships.extension.data.id
                });
              });
            }));
          } else if (resource.type === DATA_ELEMENTS && resource.attributes.enabled) {
            requiredResourceData.push({
              parentResource: resource,
              resource,
              resourceUpdatedWithExtensionId:
                resource.relationships.updatedWithExtension.data.id,
              resourceExtensionId: resource.relationships.extension.data.id
            });
          } else if (resource.type === EXTENSIONS) {
            const resourceLinks = apiItemLinksToIds(resource.links);
            const extensionFromCacheAtHead = state.api.extensionCache.extensions[resourceLinks.origin];
            // if we found it in the cache by originId then it's still installed on the property
            if (extensionFromCacheAtHead) {
              // if the relationships.extension id doesn't match then it's been upgraded
              if (
                // old revisions of extensions do not change the extension package relationship when head is upgraded
                resource.relationships.extensionPackage.data.id !==
                extensionFromCacheAtHead.relationships.extensionPackage.data.id
              ) {
                // it's been upgraded so we'll remove the outdated extension and add the updated one
                resourcesToRemove.push(resource);
                missingUpgradedExtensions.push(extensionFromCacheAtHead);
              } else {
                includedExtensions.push(extensionFromCacheAtHead);
              }
            }
          }
        });

        // get latest libraries so we can make sure we have the latest upstream library
        // in case this is a new library
        loadingPromises.push(dispatch(actionCreators.loadNonDevelopmentLibraries({ params })).then(() => {
          // get upstream extensions for later validation
          return dispatch(actionCreators.loadUpstreamExtensions(params, library));
        }).then((upstreamExtensions) => {
          // upstream extensions will always be at a specific revision (other than head)
          // so we'll try to find them in the extensionCache by originId
          const upstreamExtensionsByOrigin = upstreamExtensions.map((upstreamExtension) => {
            const resourceLinks = apiItemLinksToIds(upstreamExtension.links);
            const extension = state.api.extensionCache.extensions[resourceLinks.origin];
            if (!extension) { missingExtensionsUninstalled.push(upstreamExtension); }
            return extension;
          }).filter(upstreamExtension => typeof upstreamExtension !== 'undefined');

          // add upstream extensions to the already includedExtensions since they will
          // be part of the next build automatically - unless the extension is already there
          upstreamExtensionsByOrigin.forEach(upstreamExtensionByOrigin => {
            const foundIncludedExtension = includedExtensions.find(includedExtension => {
              return resourcesHaveSameOrigin(includedExtension, upstreamExtensionByOrigin);
            });
            if (!foundIncludedExtension) {
              includedExtensions.push(upstreamExtensionByOrigin);
            }
          });
        }));

        Promise.all(loadingPromises).then(() => {
          const uniqueRequiredExtensionIds = requiredResourceData.reduce((
            uniqueRequiredExtensionIds,
            {resourceUpdatedWithExtensionId}
          ) => {
            return (
              uniqueRequiredExtensionIds.includes(resourceUpdatedWithExtensionId) ?
              uniqueRequiredExtensionIds :
              uniqueRequiredExtensionIds.concat(resourceUpdatedWithExtensionId)
            );
          }, []);
          loadingPromises = [];
          loadingPromises.push(Promise.all(uniqueRequiredExtensionIds.map(uniqueRequiredExtensionId => {
            return dispatch(apiActions.apiAction({
              name: 'getExtension',
              stateKey: 'checkMissingExtensions',
              urlData: {
                ...params,
                extension: uniqueRequiredExtensionId
              },
              updateState: false
            }));
          })));
        }).then(() => {
          // when loadingPromises is done requiredResourceData AND includedExtensions
          // should be completely populated
          Promise.all(loadingPromises).then((results) => {
            let missingExtensions = [];
            let uninstalledExtensionsIdsLoaded = [];
            let uninstalledExtensionsPromises = [];
            // this pulls the responses from the extension requests that used the updated with extension
            let requiredResourceExtensions = results[results.length - 1].map((response) => response.res.body.data);

            // populate missingExtensions with any extensions pulled from the extensionCache
            requiredResourceData.forEach(requiredResourceDataItem => {
              const { resourceExtensionId, resourceUpdatedWithExtensionId } = requiredResourceDataItem;
              const extensionFromCacheAtHead = state.api.extensionCache.extensions[
                resourceExtensionId
              ];
              const requiredResourceExtension = requiredResourceExtensions.find(
                extension=>extension.id === resourceUpdatedWithExtensionId
              );

              // populate missingUpgradedExtensions if any are installed on the property
              const extensionHasBeenUpgraded = (
                extensionFromCacheAtHead &&
                extensionFromCacheAtHead.relationships.extensionPackage.data.id !==
                requiredResourceExtension.relationships.extensionPackage.data.id
              );
              const foundMissingUpgradedExtension = extensionFromCacheAtHead && missingUpgradedExtensions.find(
                extension => extension.id === extensionFromCacheAtHead.id
              );
              if (extensionHasBeenUpgraded && !foundMissingUpgradedExtension) {
                // we need to populate this because we don't have all the needed data yet
                // to compare includedExtensions by name, but we will do that below
                missingUpgradedExtensions.push(extensionFromCacheAtHead);
              }

              // did we already add this extension?
              const missingExtension = missingExtensions.find(
                extension => extension.id === resourceExtensionId
              );
              // is the extension already in the library or upstream?
              const includedExtension = includedExtensions.find(
                extension => extension.id === resourceExtensionId
              );

              // if it's not already in the list or in the library or upstream
              // then add it to the list
              if (!missingExtension && !includedExtension) {
                if (extensionFromCacheAtHead) {
                  missingExtensions.push(extensionFromCacheAtHead);
                } else {
                  // if it's been uninstalled then we don't have the full extension object
                  // in our cache so we'll need to load it by id, but only if we haven't already
                  // started loading it
                  if (!uninstalledExtensionsIdsLoaded.includes(resourceExtensionId)) {
                    uninstalledExtensionsIdsLoaded.push(resourceExtensionId);
                    uninstalledExtensionsPromises.push(
                      dispatch(actionCreators.loadExtension({
                        ...params,
                        extension: resourceExtensionId
                      })).then((result) => {
                        const uninstalledExtension = get(result, 'res.body.data');
                        missingExtensionsUninstalled.push(uninstalledExtension);
                      })
                    );
                  }
                }
              }
            });

            Promise.all(uninstalledExtensionsPromises).then(() => {
              // all Promise.all()s are resolved at this point so tell the view that loading is done
              dispatch(doneLoadingAction);

              let anyDisabledRequiredResourceData = false;
              // update requiredResourceData with the actual requiredExtension now that all loading is completed
              // adding the actual requiredExtension should allow us to check if it's disabled
              // and warn the user if so. requiredExtension should always be present! If it's not,
              // it's a sign that some logic above isn't working as expected.
              requiredResourceData = requiredResourceData.map(requiredResourceDataItem => {
                const { resourceExtensionId } = requiredResourceDataItem;
                const extensionFromCacheAtHead = state.api.extensionCache.extensions[resourceExtensionId];
                const includedExtension = includedExtensions.find(
                  extension => extension.id === resourceExtensionId
                );
                const uninstalledExtension = missingExtensionsUninstalled.find(
                  extension => extension.id === resourceExtensionId
                );

                // we want to use the includedExtension first if it's available
                // because it will be the specific revision we are building with
                const requiredExtension = includedExtension || extensionFromCacheAtHead || uninstalledExtension;
                if (!requiredExtension.attributes.enabled) {
                  anyDisabledRequiredResourceData = true;
                }
                return { ...requiredResourceDataItem, requiredExtension };
              });

              // complete the list of resources to remove due to uninstalled extension dependencies
              missingExtensionsUninstalled.forEach(uninstalledExtension => {
                requiredResourceData.filter(({ resourceExtensionId }) => {
                  // if the resource is a rule, then resourceExtensionId is for its child rule component
                  return resourceExtensionId === uninstalledExtension.id;
                }).forEach(({ parentResource }) => {
                  const existingResource = resourcesToRemove.find(
                    resourceWithUninstalledExtension => resourceWithUninstalledExtension.id === parentResource.id
                  );
                  if (!existingResource) {
                    resourcesToRemove.push(parentResource);
                  }
                });
              });

              // remove any missingUpgradedExtensions from missingExtensions so that we
              // only present them to the user once (since both will be presented separately)
              missingExtensions = filterListFromList(missingExtensions, missingUpgradedExtensions, 'id');

              // if any missingExtensions or missingUpgradedExtensions are already included
              // at a newer version then we'll remove/ignore them
              missingExtensions = filterListFromList(
                missingExtensions, includedExtensions, isOlderOrSameVersionOfSameExtension
              );
              missingUpgradedExtensions = filterListFromList(
                missingUpgradedExtensions, includedExtensions, isOlderOrSameVersionOfSameExtension
              );

              // if changes are required then we'll show the dialog,
              // otherwise we'll just continue
              if (
                missingExtensions.length ||
                (missingExtensionsUninstalled.length && resourcesToRemove.length) ||
                missingUpgradedExtensions.length ||
                anyDisabledRequiredResourceData
              ) {
                dispatch(
                  actionCreators.setMissingExtensionsInfo(
                    missingExtensions,
                    missingExtensionsUninstalled,
                    missingUpgradedExtensions,
                    resourcesToRemove,
                    requiredResourceData
                  )
                );
                dispatch(actionCreators.setActiveDialog('missingExtensions'));
              } else {
                return onNoChangesRequired && onNoChangesRequired();
              }
            }).catch(() => {
              dispatch(doneLoadingAction);
            });
          });
        }).catch(() => {
          dispatch(doneLoadingAction);
        });
      };
    },
    /**
     * Polls under the publishingKeys entries from this file.
     * Makes the API calls to fetch libraries, then decides if polling should
     * continue.
     */
    startLibrariesPolling(params = {}) {
      return async(dispatch, getState) => {
        // Other components tied tightly to publishing may have already started polling.
        if (!isPollingDevelopmentLibrariesFromState(getState())) {
          devLibrariesPromise = new Promise((resolve)=>{
            devLibrariesAbortController.abort();
            devLibrariesAbortController = new AbortController();

            dispatch(developmentLibrariesPollingActions.startPolling(async()=>{
              await dispatch(actionCreators.loadDevelopmentLibraries({
                params,
                loadAllPages: true,
                abortSignal: devLibrariesAbortController?.signal,
              }));

              // When the API call resolves, figure out if we should be done polling its call
              const libraries = getDevelopmentLibrariesFromState(getState());
              if (!isAnyLibraryOrBuildPending(libraries)) {
                dispatch(developmentLibrariesPollingActions.stopPolling());
                devLibrariesAbortController.abort();
                resolve(libraries);
              } else if (!isPollingDevelopmentLibrariesFromState(getState())) {
                // libraries are pending, but another shared component might have stopped polling during their use of
                // calling this function.
                return dispatch(actionCreators.startLibrariesPolling(params));
              }
            }));
          });
        }

        // Other components tied tightly to publishing may have already started polling.
        if (!isPollingNonDevelopmentLibrariesFromState(getState())) {
          nonDevLibrariesPromise = new Promise((resolve)=>{
            nonDevLibrariesAbortController.abort();
            nonDevLibrariesAbortController = new AbortController();

            dispatch(nonDevelopmentLibrariesPollingActions.startPolling(async()=>{
              await dispatch(actionCreators.loadNonDevelopmentLibraries({
                params,
                abortSignal: nonDevLibrariesAbortController?.signal,
              }));

              // When the API call resolves, figure out if we should be done polling its call
              const libraries = getNonDevelopmentLibrariesFromState(getState());
              const builds = getIncludedForNonDevelopmentLibrariesFromState(getState());
              if (!isAnyLibraryOrBuildPending(libraries, builds)) {
                dispatch(nonDevelopmentLibrariesPollingActions.stopPolling());
                nonDevLibrariesAbortController.abort();
                resolve(libraries);
              } else if (!isPollingNonDevelopmentLibrariesFromState(getState())) {
                // libraries are pending, but another shared component might have stopped polling during their use of
                // calling this function.
                return dispatch(actionCreators.startLibrariesPolling(params));
              }
            }));
          });
        }

        return Promise.all([devLibrariesPromise, nonDevLibrariesPromise]).then(results=>{
          return {
            devLibraries: results[0],
            nonDevLibraries: results[1]
          };
        });
      };
    },
    /**
     * Polls under the publishingKeys entries from this file.
     * Cancels the poll calls.
     */
    stopLibraryPolling() {
      return (dispatch, getState) => {
        if (isPollingDevelopmentLibrariesFromState(getState())) {
          dispatch(developmentLibrariesPollingActions.stopPolling());
        }

        if (isPollingNonDevelopmentLibrariesFromState(getState())) {
          dispatch(nonDevelopmentLibrariesPollingActions.stopPolling());
        }

        devLibrariesAbortController.abort();
        nonDevLibrariesAbortController.abort();
      };
    },
    submitAndBuildLibraryToStaging(params, library) {
      return async(dispatch, getState) => {
        let allLibraries = getAllDevAndNonDevLibrariesFromState(getState());
        let latestLibrary = allLibraries.find(_library=>_library.id === library.id);

        await dispatch(actionCreators.submitForApproval(params, library));
        allLibraries = getAllDevAndNonDevLibrariesFromState(getState());
        latestLibrary = allLibraries.find(_library=>_library.id === library.id);

        await dispatch(actionCreators.validateThenBuildLibraryAndStartPolling(params, latestLibrary));
      };
    },
    approveAndPublishLibrary(params, library) {
      return async(dispatch, getState) => {
        let allLibraries = getAllDevAndNonDevLibrariesFromState(getState());
        let latestLibrary = allLibraries.find(_library=>_library.id === library.id);

        // approve
        await dispatch(actionCreators.approve(params, latestLibrary));
        allLibraries = getAllDevAndNonDevLibrariesFromState(getState());
        latestLibrary = allLibraries.find(_library=>_library.id === library.id);

        // publish
        await dispatch(actionCreators.publish(params, latestLibrary));
      };
    },
    validateThenBuildLibraryAndStartPolling(params, library, onProcessStarted) {
      return async(dispatch, getState) => {
        // ensure we are working with the latest library data
        // EX: after transitioning we need to get the latest library because it's environment
        //     will have changed
        let latestLibrary = getAllDevAndNonDevLibrariesFromState(getState()).find((_library) => {
          return _library.id === library.id;
        });

        let buildError = getBuildError(getState(), latestLibrary);
        let conflictsExist = false;
        let warningsExist = false;

        // load latest libraries
        const loadingPromises = [
          dispatch(actionCreators.loadLibraryResources({
            ...params,
            library: latestLibrary.id
          })),
          dispatch(actionCreators.loadNonDevelopmentLibraries({ params })),
          dispatch(actionCreators.loadDevelopmentLibraries({ params, loadAllPages: true }))
        ];
        return Promise.all(loadingPromises).then(() => {
          let libraryResources = addWarningStatesToDelegates(
            getLibraryResourcesFromState(getState()), latestLibrary, getAllDevAndNonDevLibrariesFromState(getState())
          );
          conflictsExist = libraryResources.some((resource) => {
            return resource.warnings.inUseUpstream;
          });
          warningsExist = libraryResources.some((resource) => {
            return resource.warnings.inOtherDevLibrary;
          });
        }).then(() => {
          // auto attach staging/production environments when needed
          // this has to happen at build time because the current user must
          // be someone who has rights in those states
          const stagingEnvironment = getEnvironmentsByStage(getState(), 'staging')[0];
          const productionEnvironment = getEnvironmentsByStage(getState(), 'production')[0];
          let autoAttachedEnvironment;
          if (latestLibrary.attributes.state === libraryTypes.states.SUBMITTED
            && buildError === publishingMessages.NO_ENVIRONMENT
            && stagingEnvironment) {
            autoAttachedEnvironment = stagingEnvironment;
          } else if (latestLibrary.attributes.state === libraryTypes.states.APPROVED
            && buildError === publishingMessages.NO_ENVIRONMENT
            && productionEnvironment) {
            autoAttachedEnvironment = productionEnvironment;
          }
          if (autoAttachedEnvironment) {
            return dispatch(
              actionCreators.updateLibrary({ ...params, library: latestLibrary.id }, {
                id: latestLibrary.id,
                selectedEnvironment: autoAttachedEnvironment.id
              })
            ).then(() => {
              return Promise.all([
                dispatch(actionCreators.loadDevelopmentLibraries({ params, loadAllPages: true })),
                dispatch(actionCreators.loadNonDevelopmentLibraries({ params }))
              ]);
            }).then(() => {
              latestLibrary = getAllDevAndNonDevLibrariesFromState(getState()).find((_library) => {
                return _library.id === library.id;
              });
              // run check and build again after attaching the environment and refreshing libraries
              return dispatch(actionCreators.validateThenBuildLibraryAndStartPolling(
                params, latestLibrary, onProcessStarted
              ));
            });
          } else if (buildError || conflictsExist || warningsExist) {
            if (buildError === publishingMessages.NO_DEVELOPMENT_ENVIRONMENT) {
              return dispatch(
                actionCreators.setWorkingLibraryAndDialog(
                  latestLibrary, 'noDevelopment', null, null, null
                )
              );
            } else if (buildError === publishingMessages.NO_STAGING_ENVIRONMENT) {
              return dispatch(
                actionCreators.setWorkingLibraryAndDialog(
                  latestLibrary, 'noStaging', null, null, null
                )
              );
            } else if (buildError === publishingMessages.NO_PRODUCTION_ENVIRONMENT) {
              return dispatch(
                actionCreators.setWorkingLibraryAndDialog(
                  latestLibrary, 'noProduction', null, null, null
                )
              );
            } else if (buildError === publishingMessages.ENVIRONMENT_MIGRATION_FAILED) {
              return dispatch(
                actionCreators.setWorkingLibraryAndDialog(
                  latestLibrary, 'embedMigrationFailed', null, null, null
                )
              );
            } else if (buildError === publishingMessages.NO_ENVIRONMENT) {
              return dispatch(
                actionCreators.setWorkingLibraryAndDialog(
                  latestLibrary, 'noEnvironment', null, null, null
                )
              );
            } else if (conflictsExist) {
              dispatch(actionCreators.setWorkingLibraryAndDialog(library, 'buildConflicts'));
            } else if (warningsExist) {
              dispatch(actionCreators.setWorkingLibraryAndDialog(library, 'buildWarnings'));
            } else {
              return dispatch(
                actionCreators.setWorkingLibraryAndDialog(
                  latestLibrary, 'manual', 'Warning', buildError
                )
              );
            }
          } else {
            // library has an environment and there are no errors or warnings so we do the build
            onProcessStarted?.();
            return dispatch(
              actionCreators.buildLibraryAndStartPolling(params, library)
            );
          }
        });
      };
    },
    buildLibraryAndStartPolling(params, library) {
      if (getLibraryEnvironment(library)) {
        return (dispatch, getState) => {
          return dispatch(actionCreators.buildLibrary({
            ...params,
            library: library.id
          })).then(() => {
            // message to the user that the build is started
            dispatch(toastsActions.addToast({
              timeout: 7000,
              variant: 'info',
              renderer: (
                <span>
                  A build was started for&nbsp;
                  <Link to={getLibraryLocation(params, library)}>
                    {library.attributes.name}
                  </Link>.
                </span>
              )
            }));

            dispatch(workingLibraryActions.setWorkingLibraryNeedsRefresh());

            const pollingCallback = () => {
              let pollingPromises = [];
              if (actionsKey === PUBLISHING_KEY) {
                pollingPromises.push(dispatch(actionCreators.loadDevelopmentLibraries({ params, loadAllPages: true })));
                pollingPromises.push(dispatch(actionCreators.loadNonDevelopmentLibraries({ params })));
              } else if (actionsKey === LIBRARY_EDIT_KEY) {
                pollingPromises.push(dispatch(actionCreators.loadBuilds(params)));
                pollingPromises.push(dispatch(actionCreators.loadLibraryAndSetWorkingLibrary(params)));
              }
              return Promise.all(pollingPromises);
            };

            const anyPending = () => {
              const allLibraries = getAllDevAndNonDevLibrariesFromState(getState());

              if (actionsKey === PUBLISHING_KEY) {
                return allLibraries.some((library) => {
                  return library.meta.buildStatus === libraryTypes.buildStatus.PENDING;
                });
              } else if (actionsKey === LIBRARY_EDIT_KEY) {
                return allLibraries.some((build) => {
                  return build.attributes.status === libraryTypes.buildStatus.PENDING;
                });
              }
            };

            // if there was a successful build with no delay, the library/build never changes
            // to a pending status. So we only start polling after the first load - if any
            // libraries/builds are actually pending
            return pollingCallback().then(async(result) => {
              if (anyPending(result)) {
                return dispatch(actionCreators.startLibrariesPolling(params));
              }
            });
          });
        };
      } else {
        return (dispatch) => {
          dispatch(actionCreators.setWorkingLibraryAndDialog(library, 'noEnvironment'));
        };
      }
    },
    sendToDevelopment(params, library, dontRefreshData) {
      return (dispatch) => {
        return dispatch(actionCreators.transitionLibraryAndRefreshLibraries(
          params, library, libraryTypes.transitions.DEVELOP, dontRefreshData
        ));
      };
    },
    setWorkingLibraryAndDialog(
      library, dialogKey, dialogHeader, dialogContent, dialogFooter, variant
    ) {
      return (dispatch) => {
        return Promise.all([
          dispatch(actionCreators.setWorkingLibrary(library)),
          dispatch(
            actionCreators.setActiveDialog(
              dialogKey, dialogHeader, dialogContent, dialogFooter, variant
            )
          )
        ]);
      };
    },
    getPromotionError(library, requireSuccessfulBuild) {
      return (dispatch, getState) => {
        const state = getState();

        let promotionError = getPromotionErrorFromState(state, library);

        if (
          requireSuccessfulBuild &&
          library.meta.buildStatus !== libraryTypes.buildStatus.SUCCESS
        ) {
          promotionError = publishingMessages.BUILD_REQUIRED;
        }

        return promotionError;
      };
    },
    validateAndOpenDialog(library, dialogKey, requireSuccessfulBuild) {
      return (dispatch) => {
        const promotionError = dispatch(actionCreators.getPromotionError(library, requireSuccessfulBuild));

        if (promotionError) {
          if (promotionError === publishingMessages.UPSTREAM_CHANGES) {
            return dispatch(
              actionCreators.setWorkingLibraryAndDialog(library, 'blocked')
            );
          } else {
            return dispatch(
              actionCreators.setWorkingLibraryAndDialog(library, 'manual', 'Warning', promotionError)
            );
          }
        } else {
          return dispatch(
            actionCreators.setWorkingLibraryAndDialog(library, dialogKey, null, null, null)
          );
        }
      };
    },
    submitForApproval(params, library) {
      return (dispatch) => {
        let errorMessage;
        if (library.meta.buildStatus !== libraryTypes.buildStatus.SUCCESS) {
          errorMessage = library.meta.buildRequiredDetail;
        } else if (!getLibraryEnvironment(library)) {
          errorMessage = publishingMessages.NO_ENVIRONMENT;
        }

        if (errorMessage) {
          dispatch(toastsActions.addToast({
            variant: 'error',
            error: getAppError({
              lensCode: APP_ERROR_CODES.SUBMIT_FOR_APPROVAL_ERROR,
              message: errorMessage
            })
          }));
          return Promise.reject(errorMessage);
        } else {
          return dispatch(actionCreators.transitionLibraryAndRefreshLibraries(
            params, library, libraryTypes.transitions.SUBMIT
          ));
        }
      };
    },
    delete(params, library) {
      return (dispatch, getState) => {
        // Can't delete rejected libraries so we'll send it to development first
        const toDevelopmentPromise = (library.attributes.state === libraryTypes.states.REJECTED) ?
          dispatch(actionCreators.sendToDevelopment(params, library)) :
          Promise.resolve();

        return toDevelopmentPromise.then(() => {
          // do the delete
          return dispatch(actionCreators.deleteLibrary({
            ...params,
            library: library.id
          })).then(() => {
            // check if it is the active library
            const isWorkingLibrary = getState().getIn(['workingLibrary', 'library', 'id']) === library.id;
            if (isWorkingLibrary) {
              dispatch(workingLibraryActions.setWorkingLibrary(params, null));
            }
            dispatch(workingLibraryActions.setWorkingLibraryNeedsRefresh());

            if (actionsKey === LIBRARY_EDIT_KEY) {
              dispatch(actionCreators.goToPublishing(params));
            } else {
              dispatch(actionCreators.loadDevelopmentLibraries({ params, loadAllPages: true }));
              dispatch(actionCreators.loadNonDevelopmentLibraries({ params }));
            }
          });
        });
      };
    },
    reject(params, library) {
      return (dispatch) => {
        return dispatch(actionCreators.transitionLibraryAndRefreshLibraries(
          params, library, libraryTypes.transitions.REJECT
        ));
      };
    },
    approve(params, library) {
      return (dispatch) => {
        return dispatch(actionCreators.transitionLibraryAndRefreshLibraries(
          params, library, libraryTypes.transitions.APPROVE
        ));
      };
    },
    publish(params, library, onProcessStarted) {
      return (dispatch) => {
        return dispatch(actionCreators.validateThenBuildLibraryAndStartPolling(
          params, library, onProcessStarted
        ));
      };
    },
    republish(params, library, property) {
      return (dispatch, getState) => {
        return dispatch(apiActions.apiAction({
          name: 'updateBuild',
          data: {
            data: {
              id: library?.relationships?.lastBuild?.data?.id,
              type: 'builds',
              'meta': { action: 'republish' }
            }
          },
          urlData: { id: library?.relationships?.lastBuild?.data?.id },
          urlParams: { ...params  }
        })).then(() => {
          dispatch(actionCreators.loadNonDevelopmentLibraries({params}));
          // message to the user that the republish is started
          let republishStartedToastId = dispatch(toastsActions.addToast({
            timeout: 7000,
            variant: 'info',
            renderer: (
              <span>
                A republish was started for&nbsp;
                <Link to={getLibraryLocation(params, library)}>
                  {library.attributes.name}
                </Link>.
              </span>
            )
          }));
          dispatch(republishPollingActions.startPolling(() => {
            return dispatch(actionCreators.loadLibrary(
              {params, library: library.id},
              {include: 'last_build'}
            )).then(() => {
              if (getState().api.library?.meta?.currentlyLive) {
                dispatch(republishPollingActions.stopPolling());
                // Add the toast that will need to be removed per session on a property
                dispatch(toastsActions.removeToast(republishStartedToastId));
                dispatch(actionCreators.addRepublishToast(property, library, params));
                return dispatch(actionCreators.loadNonDevelopmentLibraries({params}));
              }
            });
          }, {delayFirstCall: 500}));
        });
      };
    },
    addRepublishToast(property, library, params) {
      // Check to see if a previous republish toast was dismissed on this
      // property and remove it from session storage before popping a new toast
      if (getSessionStorageItem('republishToastDismissed_' + property.id)) {
        removeSessionStorageItem('republishToastDismissed_' + property.id);
      }
      return function(dispatch) {
        dispatch(toastsActions.addToast({
          variant: 'warning',
          onClose: () => {
            setSessionStorageItem('republishToastDismissed_' + property.id, true);
          },
          renderer: (
            <span>
              Library&nbsp;
              <Link to={getLibraryLocation(params, library)}>
                {library?.attributes.name}
              </Link> on property&nbsp;
              <Link to={getPropertyLocation(params, property)}>
                {property?.attributes.name}
              </Link> has been republished. The latest resources on this property
              may be different than those in production.
            </span>
          ),
          republish: true,
          propertyId: property.id
        }));
      };
    },
    goToResourceCompareView({
      params,
      revisionRange,
      shouldReplaceRoute = false
    }) {
      return (dispatch)=>{
        const compareRouteMapping = {
          [RULES]: 'editLibraryRuleCompare',
          [DATA_ELEMENTS]: 'editLibraryDataElementCompare',
          [EXTENSIONS]: 'editLibraryExtensionCompare'
        };

        const {rightRevisionId} = getRevisionIdsFromRevisionRange(revisionRange);
        const resourceType = getResourceTypeFromId(rightRevisionId);

        dispatch((shouldReplaceRoute ? replace : push)({
          name: compareRouteMapping[resourceType],
          params: {
            ...params,
            [resourceType]: rightRevisionId,
            [getRevisionRangeParameterName(resourceType)]: revisionRange ? revisionRange : getRevisionRange({
              rightRevisionId
            })
          }
        }));
      };
    }
  };

  return actionCreators;
};
