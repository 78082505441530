/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

export default function actionsHandler(actions) {
  return function handleAction(state, action) {
    if (actions[action.type]) {
      return actions[action.type](state, action);
    } else {
      try {
        return actions.default(state, action);
      } catch (e) {
        console.error(e, 'You must define a "default" action type in your reducer.');
      }
    }
  };
};
