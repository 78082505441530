/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import Immutable from 'seamless-immutable';
import {actionCreators as apiActions} from '../../../utils/api/apiActions';
import {
  getApiMappingFromId,
  getSingularResourceTypeFromId,
  getResourceTypeFromId,
  isResourceLatestAndUnchanged,
  getResourceOriginId
} from '../../../utils/resourceUtils';
import {getComponentCompareOrDefaultData} from '../compareViewSelectors';
import {
  getRevisionRange,
  getDominantSideData,
  getRevisionToUseForNonDominantSide
} from '../compareViewUtils';

import {getApiData} from '../../../utils/api/apiTools';
import actionsHandler from '../../../redux/actionsHandler';
import {snakeCase} from 'lodash-es';

export const STATE_KEY_LEFT = 'componentCompareLeft';
export const STATE_KEY_RIGHT = 'componentCompareRight';
export const COMPONENT_COMPARE_STATE_KEY = 'genericComponentCompare';
export const RULE_COMPONENT_COMPARE_STATE_KEY = 'ruleComponentCompare';
export const RULE_COMPONENT_EXTENSION_COMPARE_STATE_KEY = 'ruleComponentExtensionCompare';
export const DATA_ELEMENT_COMPARE_STATE_KEY = 'dataElementCompare';
export const DATA_ELEMENT_EXTENSION_COMPARE_STATE_KEY = 'dataElementExtensionCompare';
export const COMPARE_BASE_RESOURCE_STATE_KEY = 'compareBaseResource';

// action consts
export const INITIALIZE_STATE = 'componentCompare/INITIALIZE_STATE';
export const SET_COMPARE_VIEW_OPEN = 'componentCompare/SET_COMPARE_VIEW_OPEN';
export const SET_RESOURCE_DATA = 'componentCompare/SET_RESOURCE_DATA';
export const SET_BASE_RESOURCE_DATA = 'componentCompare/SET_BASE_RESOURCE_DATA';
export const TOGGLE_SETTINGS_TAKEOVER_ACTIVE = 'componentCompare/TOGGLE_SETTINGS_TAKEOVER_ACTIVE';
export const TOGGLE_IGNORE_CODE_TRANSFORMS = 'componentCompare/TOGGLE_IGNORE_CODE_TRANSFORMS';
export const SET_LOADING = 'componentCompare/SET_LOADING';
export const CLEAN_UP = 'componentCompare/CLEAN_UP';
export const SET_LATEST_HAS_UNSAVED_CHANGES = 'componentCompare/SET_LATEST_HAS_UNSAVED_CHANGES';
export const COPY_API_STATE_TO_STATE_KEY = 'componentCompare/COPY_API_STATE_TO_STATE_KEY';


let abortController;


export const initialState = Immutable({
  loading: true,
  resourceType: null,
  initialLoadCompleted: false,
  isOpen: false,
  expandedSettingsViewActive: false,
  ignoreCodeTransforms: false,
  [STATE_KEY_LEFT]: {
    id: null,
    loading: true,
    resource: null
  },
  [STATE_KEY_RIGHT]: {
    id: null,
    loading: true,
    resource: null
  }
});

//Reducers
export default actionsHandler({
  [INITIALIZE_STATE](state, action) {
    return state.set(action.payload.componentCompareStateKey, initialState);
  },
  [TOGGLE_SETTINGS_TAKEOVER_ACTIVE](state, action) {
    return state.setIn([action.payload.componentCompareStateKey, 'expandedSettingsViewActive'], !state[action.payload.componentCompareStateKey].expandedSettingsViewActive);
  },
  [TOGGLE_IGNORE_CODE_TRANSFORMS](state, action) {
    return state.setIn([action.payload.componentCompareStateKey, 'ignoreCodeTransforms'], !state[action.payload.componentCompareStateKey].ignoreCodeTransforms);
  },
  [SET_COMPARE_VIEW_OPEN](state, action) {
    return state.setIn(
      [action.payload.componentCompareStateKey, 'isOpen'], action.payload.isOpen
    ).setIn(
      [action.payload.componentCompareStateKey, 'originId'], action.payload.originId
    );
  },
  [SET_RESOURCE_DATA](state, action) {
    return state.setIn(
      [action.payload.componentCompareStateKey, action.payload.stateKey, 'resource'],
      action.payload.resource
    ).setIn(
      [action.payload.componentCompareStateKey, action.payload.stateKey, 'id'],
      action.payload.resource.id
    );
  },
  [SET_BASE_RESOURCE_DATA](state, action) {
    return state.setIn(
      [action.payload.componentCompareStateKey, 'revisions'], action.payload.revisions
    ).setIn(
      [action.payload.componentCompareStateKey, 'originId'], action.payload.originId
    ).setIn(
      [action.payload.componentCompareStateKey, 'latestRevisionId'], action.payload.latestRevisionId
    ).setIn(
      [action.payload.componentCompareStateKey, 'originDirty'], action.payload.originDirty
    ).setIn(
      [action.payload.componentCompareStateKey, 'resourceType'], getResourceTypeFromId(action.payload.originId)
    );
  },
  [SET_LOADING](state, action) {
    const otherSideStateKey = (
      action.payload.stateKey === STATE_KEY_LEFT ?
      STATE_KEY_RIGHT :
      STATE_KEY_LEFT
    );

    state = state.setIn(
      (
        action.payload.stateKey ?
        [action.payload.componentCompareStateKey, action.payload.stateKey, 'loading'] :
        [action.payload.componentCompareStateKey, 'loading']
      ),
      action.payload.loading
    );

    if (
      !state[action.payload.componentCompareStateKey][action.payload.stateKey].loading &&
      !state[action.payload.componentCompareStateKey][otherSideStateKey].loading &&
      state[action.payload.componentCompareStateKey].loading
    ) {
      state = state.setIn(
        [action.payload.componentCompareStateKey, 'loading'], false
      ).setIn(
        [action.payload.componentCompareStateKey, 'initialLoadCompleted'], true
      );
    }
    return state;
  },
  [CLEAN_UP](state, action) {
    return state.set(action.payload.componentCompareStateKey, initialState);
  },
  [SET_LATEST_HAS_UNSAVED_CHANGES](state, action) {
    return state.setIn([action.payload.componentCompareStateKey, 'latestHasUnsavedChanges'], action.payload.latestHasUnsavedChanges);
  },
  [COPY_API_STATE_TO_STATE_KEY](state, action) {
    return state.updateIn(
      [action.payload.componentCompareStateKey, STATE_KEY_LEFT],
      (stateByStateKey)=>(stateByStateKey.merge(action.payload[STATE_KEY_LEFT]))
    ).updateIn(
      [action.payload.componentCompareStateKey, STATE_KEY_RIGHT],
      (stateByStateKey)=>(stateByStateKey.merge(action.payload[STATE_KEY_RIGHT]))
    );
  },
  default: (state)=> {
    return state ? state : Immutable({});
  }
});

//Action Creators
export function actionCreators(componentCompareStateKey = COMPONENT_COMPARE_STATE_KEY) {
  const actionCreators = {
    toggleSettingsTakeOverActive() {
      return {
        type: TOGGLE_SETTINGS_TAKEOVER_ACTIVE,
        payload: {componentCompareStateKey}
      };
    },
    toggleIgnoreCodeTransforms() {
      return {
        type: TOGGLE_IGNORE_CODE_TRANSFORMS,
        payload: {componentCompareStateKey}
      };
    },
    setCompareViewOpen({isOpen, originId}) {
      return {
        type: SET_COMPARE_VIEW_OPEN,
        payload: {
          isOpen,
          originId,
          componentCompareStateKey
        }
      };
    },
    shouldSetResourceData(resource, stateKey) {
      return {
        type: SET_RESOURCE_DATA,
        payload: {
          resource,
          stateKey,
          componentCompareStateKey
        }
      };
    },
    setBaseResourceData({
      revisions,
      originId,
      latestRevisionId,
      originDirty
    }) {
      return {
        type: SET_BASE_RESOURCE_DATA,
        payload: {
          revisions,
          originId,
          latestRevisionId,
          originDirty,
          componentCompareStateKey
        }
      };
    },
    fetchAndSetResourceData({
      stateKey,
      revisionId,
      manualData,
      params,
      shouldSetResourceData = false,
      locationUpdater
    }) {
      return (dispatch, getState)=> {
        dispatch(actionCreators.setLoading({loading:true, stateKey}));
        let resourceType = getResourceTypeFromId(revisionId);
        const isExtension = resourceType === 'extensions';

        return Promise.all([
          manualData ? null : dispatch(apiActions.apiAction({
            name: getApiMappingFromId(revisionId),
            stateKey: stateKey,
            abortSignal: abortController.signal,
            urlData: {
              ...params,
              [getSingularResourceTypeFromId(revisionId)]: revisionId
            }
          })),
          // The api does not support us requesting revisions of rule components
          // So we are forced to view the revisions by the revisions on the rule.
          // The TODO: is to figure how we would like to handle this for library edit compare view
          shouldSetResourceData && resourceType !== 'ruleComponents' ? dispatch(apiActions.apiAction({
            name: 'getRevisions',
            stateKey: stateKey,
            abortSignal: abortController.signal,
            urlData: {
              ...params,
              resource: revisionId,
              resourceType: snakeCase(resourceType)
            },
            urlParams: {
              sort: '-revision_number'
            }
          })) : null
        ]).then(()=>{

          if (manualData) {
            dispatch(apiActions.manuallySetData([
              'api',
              stateKey,
              getSingularResourceTypeFromId(revisionId)
            ], manualData));
          }

          const sideState = getApiData(getState(), stateKey);
          const revisions = sideState?.revisions || [];
          const resource = sideState[getSingularResourceTypeFromId(revisionId)];
          const latestRevisionId = revisions.find(isResourceLatestAndUnchanged)?.id;

          return (isExtension ? dispatch(apiActions.apiAction({
            name: 'getExtensionPackage',
            stateKey: stateKey,
            abortSignal: abortController.signal,
            urlData: {
              ...params,
              extensionPackage: resource.relationships.extensionPackage.data.id
            }
          })) : dispatch(apiActions.apiAction({
            name: 'getExtension',
            stateKey: stateKey,
            abortSignal: abortController.signal,
            urlData: {
              ...params,
              extension: (
                resource.relationships.updatedWithExtension?.data.id ||
                // if it's a rule component and no revision of the rule has been cut using this rule component,
                // updatedWithExtension will not exist so we'll use the extension relationship instead
                resource.relationships.extension.data.id
              )
            },
            urlParams: {
              include: 'extension_package'
            }
          }))).then(()=>{
            dispatch(actionCreators.copyApiStateToStateKey({
              STATE_KEY_LEFT: getApiData(getState(), STATE_KEY_LEFT),
              STATE_KEY_RIGHT: getApiData(getState(), STATE_KEY_RIGHT)
            }));
          }).then(()=>{
            dispatch(actionCreators.shouldSetResourceData(resource, stateKey));
            shouldSetResourceData && dispatch(actionCreators.setBaseResourceData({
              revisions,
              originId: getResourceOriginId(resource),
              latestRevisionId,
              originDirty: revisions?.[0]?.attributes.dirty,
            }));

            const componentCompareState = getComponentCompareOrDefaultData(getState(), componentCompareStateKey);
            locationUpdater && locationUpdater(getRevisionRange({
              leftRevisionId: (
                stateKey === STATE_KEY_LEFT ?
                resource?.id :
                componentCompareState?.[STATE_KEY_LEFT]?.id ||
                ''
              ),
              rightRevisionId: (
                stateKey === STATE_KEY_RIGHT ?
                resource?.id :
                componentCompareState?.[STATE_KEY_RIGHT]?.id ||
                ''
              )
            }));
            dispatch(actionCreators.setLoading({loading:false, stateKey}));
          });
        });
      };
    },
    setLatestHasUnsavedChanges(latestHasUnsavedChanges) {
      return {
        type: SET_LATEST_HAS_UNSAVED_CHANGES,
        payload: {
          latestHasUnsavedChanges,
          componentCompareStateKey
        }
      };
    },
    initialize({
      params,
      shouldSetResourceData = false,
      howToSetNonDominantSide = 'previousRevision', // previousRevision, upstream, none
      leftManualResourceData,
      rightManualResourceData,
      leftRevisionId,
      rightRevisionId,
      onlyUseManualData,
      latestHasUnsavedChanges,
      locationUpdater
    }) {

      const leftSideId = leftManualResourceData?.id || leftRevisionId;
      const rightSideId = rightManualResourceData?.id || rightRevisionId;

      // We dont know what side data will be available to us. So we we find which side we do have data for.
      // that becomes the dominantSideId
      let {
        dominantSideId,
        nonDominantSideId,
        dominantStateKey,
        nonDominantStateKey,
        dominantSideData,
        nonDominantSideData,
      } = getDominantSideData({
        leftSideId,
        rightSideId,
        leftManualResourceData,
        rightManualResourceData,
        stateKeyLeft: STATE_KEY_LEFT,
        stateKeyRight: STATE_KEY_RIGHT
      });

      abortController = new AbortController();
      return (dispatch, getState)=> {
        dispatch({
          type: INITIALIZE_STATE,
          payload: {componentCompareStateKey}
        });
        dispatch(actionCreators.setLatestHasUnsavedChanges(latestHasUnsavedChanges));
        return (
          dominantSideId ?
          dispatch(actionCreators.fetchAndSetResourceData({
            stateKey: dominantStateKey,
            revisionId: dominantSideId,
            manualData: dominantSideData,
            params,
            shouldSetResourceData,
            locationUpdater
          })) :
          Promise.resolve()
        ).then(()=>{


          if (
            howToSetNonDominantSide !== 'none' &&
            !nonDominantSideId &&
            !onlyUseManualData
          ) {

            const state = getState();
            const componentCompareState = getComponentCompareOrDefaultData(state, componentCompareStateKey);

            nonDominantSideId = getRevisionToUseForNonDominantSide({
              dominantSideId,
              latestRevisionId: componentCompareState.latestRevisionId,
              revisions: componentCompareState.revisions,
              originId: componentCompareState.originId,
              originDirty: componentCompareState.originDirty,
              howToSetNonDominantSide
            });

          }

          return (
            nonDominantSideId ?
            dispatch(actionCreators.fetchAndSetResourceData({
              stateKey: nonDominantStateKey,
              revisionId: nonDominantSideId,
              manualData: nonDominantSideData,
              params,
              locationUpdater
            })) :
            dispatch(actionCreators.setLoading({loading:false, stateKey:nonDominantStateKey}))
          );
        });
      };
    },
    cleanUp() {
      abortController?.abort();
      return (dispatch)=>{
        dispatch(apiActions.resetData(['revisions', 'endpoints', 'ruleComponent', 'extension', 'extensionPackage', 'delegateDescriptors'], STATE_KEY_RIGHT));
        dispatch(apiActions.resetData(['revisions', 'endpoints', 'ruleComponent', 'extension', 'extensionPackage', 'delegateDescriptors'], STATE_KEY_LEFT));
        dispatch({
          type: CLEAN_UP,
          payload: {componentCompareStateKey}
        });
      };
    },
    setLoading({loading, stateKey}) {
      return {
        type: SET_LOADING,
        payload: {
          stateKey,
          loading,
          componentCompareStateKey
        }
      };
    },
    copyApiStateToStateKey({STATE_KEY_LEFT:left, STATE_KEY_RIGHT:right}) {
      // the idea with this action is to copy all the data that was just loaded from the api
      // for the left and right sides and put the copy under the compareView slice of state.
      return {
        type: COPY_API_STATE_TO_STATE_KEY,
        payload: {
          componentCompareStateKey,
          [STATE_KEY_LEFT]: left,
          [STATE_KEY_RIGHT]: right
        }
      };
    }
  };
  return actionCreators;
};
