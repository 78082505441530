/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/
import React, {Component} from 'react';
import StatusDetailsErrorMessage from './StatusDetailsErrorMessage';

class PropertyCopyStatusDetailsErrorMessage extends Component {
  render = () => {
    // Mapping lens error message to match the statusDetailsErrorMessage expectations
    // This is modeled after a build error
    let formattedError = {
      code: this.props.error.networkResponse?.body.errors[0].code,
      detail: this.props.error.message,
    };

    return (
      <StatusDetailsErrorMessage error={formattedError}/>
    );
  };
}

export default PropertyCopyStatusDetailsErrorMessage;
