/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import {
  getExtensionReducer,
  getExtensionsReducer,
  getExtensionPackageReducer,
  getExtensionPackagesReducer,
  getRevisionLibrariesReducer
} from './apiResponseHandlers';
import {
  elasticSearchReducer,
  companyMetricsReducer,
  getListReducer,
  reduceToApiSlice,
  ENDPOINT_GROUP_KEYS,
  LIST_RESOURCE_MAPPING_KEYS
} from './apiMappingsUtils';



let defaultPageSize = 999;

/*eslint-disable */
/**
 * endpointGroupKey          This is used to associate endpoints that interact with the same resource types. (List views use this)
 * isList                    This is used to define if the results are an array. There is some normalization that is treated differently on lists.
 * url                       This is the (blacksmith) url that will be communicated with. This is allowed to have variables in it.
 * verb                      Method or Verb are the common terms for this. These are the CRUD operation types EX GET, POST, PATCH, DELETE
 * params                    These are url query params. These act as defaults.
 * mappingLocation           This is the location data will be mapped to. On state.api or state.api[stateKey]
 * responseReducer           This is a custom reducer for adding the response data to state.
 */
/*eslint-enable */

export default {
  // search
  elasticSearch: {
    isList: true,
    url: `${window.environmentSettings.api_url}/search`,
    verb: 'post',
    responseReducer: elasticSearchReducer
  },

  // companies
  getCompanies: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.COMPANIES,
    isList: true,
    url: window.environmentSettings.api_url + '/companies',
    verb: 'get',
    params: { 'page[size]': defaultPageSize },
    mappingLocation: LIST_RESOURCE_MAPPING_KEYS.COMPANIES,
    responseReducer: getListReducer('companies')
  },
  getCompany: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.COMPANIES,
    url: window.environmentSettings.api_url + '/companies/:company',
    verb: 'get',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('company', action.responseBody.data);
    })
  },
  createCompany: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.COMPANIES,
    url: window.environmentSettings.api_url + '/companies',
    verb: 'post',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('company', action.responseBody.data);
    })
  },
  updateCompany: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.COMPANIES,
    url: window.environmentSettings.api_url + '/companies/:company',
    verb: 'patch',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('company', action.responseBody.data);
    })
  },
  // company edge metrics
  getCompanyUsageMetrics: {
    isList: true,
    url: window.environmentSettings.api_url + '/companies/:company/edge_usage_metrics',
    verb: 'post',
    responseReducer: companyMetricsReducer
  },
  getCompanyErrorMetrics: {
    isList: true,
    url: window.environmentSettings.api_url + '/companies/:company/edge_error_metrics',
    verb: 'post',
    responseReducer: companyMetricsReducer
  },
  getCompanyPerformanceTimeMetrics: {
    isList: true,
    url: window.environmentSettings.api_url + '/companies/:company/edge_performance_metrics',
    verb: 'post',
    responseReducer: companyMetricsReducer
  },

  // properties
  getProperties: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.PROPERTIES,
    isList: true,
    url: window.environmentSettings.api_url + '/companies/:company/properties',
    verb: 'get',
    params: {
      'page[size]': defaultPageSize,
      'filter[copying]': 'EQ false'
    },
    mappingLocation: LIST_RESOURCE_MAPPING_KEYS.PROPERTIES,
    responseReducer: getListReducer('properties')
  },
  getProperty: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.PROPERTIES,
    url: window.environmentSettings.api_url + '/properties/:property',
    verb: 'get',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('property', action.responseBody.data);
    })
  },
  createProperty: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.PROPERTIES,
    url: window.environmentSettings.api_url + '/companies/:company/properties',
    verb: 'post',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('property', action.responseBody.data);
    })
  },
  updateProperty: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.PROPERTIES,
    url: window.environmentSettings.api_url + '/properties/:property',
    verb: 'patch',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('property', action.responseBody.data);
    })
  },
  deleteProperty: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.PROPERTIES,
    url: window.environmentSettings.api_url + '/properties/:property',
    verb: 'delete',
    responseReducer: reduceToApiSlice((state)=> {
      return state.set('property', null);
    })
  },

  // rules
  getRules: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.RULES,
    isList: true,
    url: window.environmentSettings.api_url + '/properties/:property/rules',
    verb: 'get',
    params: { 'page[size]': defaultPageSize },
    mappingLocation: LIST_RESOURCE_MAPPING_KEYS.RULES,
    responseReducer: getListReducer('rules')
  },

  getRule: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.RULES,
    url: window.environmentSettings.api_url + '/rules/:rule',
    verb: 'get',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('rule', action.responseBody.data);
    })
  },
  createRule: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.RULES,
    url: window.environmentSettings.api_url + '/properties/:property/rules',
    verb: 'post',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('rule', action.responseBody.data);
    })
  },
  updateRule: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.RULES,
    url: window.environmentSettings.api_url + '/rules/:rule',
    verb: 'patch',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('rule', action.responseBody.data);
    })
  },
  deleteRule: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.RULES,
    url: window.environmentSettings.api_url + '/rules/:rule',
    verb: 'delete',
    responseReducer: reduceToApiSlice((state)=> {
      return state.set('rule', null);
    })
  },

  // rule components
  getRuleComponents: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.RULE_COMPONENTS,
    isList: true,
    url: window.environmentSettings.api_url + '/rules/:rule/rule_components',
    verb: 'get',
    params: { 'page[size]': defaultPageSize },
    mappingLocation: LIST_RESOURCE_MAPPING_KEYS.RULE_COMPONENTS,
    responseReducer: getListReducer('ruleComponents')
  },
  getRuleComponent: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.RULE_COMPONENTS,
    url: window.environmentSettings.api_url + '/rule_components/:ruleComponent',
    verb: 'get',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('ruleComponent', action.responseBody.data);
    })
  },
  createRuleComponent: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.RULE_COMPONENTS,
    url: window.environmentSettings.api_url + '/properties/:property/rule_components',
    verb: 'post'
  },
  updateRuleComponent: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.RULE_COMPONENTS,
    url: window.environmentSettings.api_url + '/rule_components/:ruleComponent',
    verb: 'patch',
  },
  deleteRuleComponent: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.RULE_COMPONENTS,
    url: window.environmentSettings.api_url + '/rule_components/:ruleComponent',
    verb: 'delete'
  },
  getRuleComponentRules: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.RULE_COMPONENTS,
    isList: true,
    url: window.environmentSettings.api_url + '/rule_components/:ruleComponent/rules',
    verb: 'get',
    params: { 'page[size]': defaultPageSize },
    mappingLocation: LIST_RESOURCE_MAPPING_KEYS.RULES,
    responseReducer: getListReducer('rules')
  },

  // dataElements
  getDataElements: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.DATA_ELEMENTS,
    isList: true,
    url: window.environmentSettings.api_url + '/properties/:property/data_elements',
    verb: 'get',
    params: { 'page[size]': defaultPageSize },
    mappingLocation: LIST_RESOURCE_MAPPING_KEYS.DATA_ELEMENTS,
    responseReducer: getListReducer('dataElements')
  },
  getDataElement: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.DATA_ELEMENTS,
    url: window.environmentSettings.api_url + '/data_elements/:dataElement',
    verb: 'get',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('dataElement', action.responseBody.data);
    })
  },
  createDataElement: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.DATA_ELEMENTS,
    url: window.environmentSettings.api_url + '/properties/:property/data_elements',
    verb: 'post',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('dataElement', action.responseBody.data);
    })
  },
  updateDataElement: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.DATA_ELEMENTS,
    url: window.environmentSettings.api_url + '/data_elements/:dataElement',
    verb: 'patch',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('dataElement', action.responseBody.data);
    })
  },
  deleteDataElement: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.DATA_ELEMENTS,
    url: window.environmentSettings.api_url + '/data_elements/:dataElement',
    verb: 'delete',
    responseReducer: reduceToApiSlice((state)=> {
      return state.set('dataElement', null);
    })
  },

  // extensions
  getExtensions: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.EXTENSIONS,
    isList: true,
    url: window.environmentSettings.api_url + '/properties/:property/extensions',
    verb: 'get',
    params: { 'page[size]': defaultPageSize },
    mappingLocation: LIST_RESOURCE_MAPPING_KEYS.EXTENSIONS,
    responseReducer: (state, action)=>{
      return reduceToApiSlice(getExtensionsReducer)(
        getListReducer('extensions')(state, action),
        action
      );
    }
  },
  getExtension: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.EXTENSIONS,
    url: window.environmentSettings.api_url + '/extensions/:extension',
    verb: 'get',
    responseReducer: reduceToApiSlice(getExtensionReducer)
  },
  createExtension: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.EXTENSIONS,
    url: window.environmentSettings.api_url + '/properties/:property/extensions',
    verb: 'post',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('extension', action.responseBody.data);
    })
  },
  updateExtension: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.EXTENSIONS,
    url: window.environmentSettings.api_url + '/extensions/:extension',
    verb: 'patch',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('extension', action.responseBody.data);
    })
  },
  deleteExtension: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.EXTENSIONS,
    url: window.environmentSettings.api_url + '/extensions/:extension',
    verb: 'delete',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('extension', action.responseBody.data);
    })
  },

  // revisions
  getRevisions: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.REVISIONS,
    isList: true,
    url: window.environmentSettings.api_url + '/:resourceType/:resource/revisions',
    verb: 'get',
    params: {
      'page[size]': defaultPageSize,
      'sort': '-revision_number'
    },
    mappingLocation: LIST_RESOURCE_MAPPING_KEYS.REVISIONS,
    responseReducer: getListReducer('revisions')
  },

  // revision libraries
  getRevisionLibraries: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.REVISION_LIBRARIES,
    isList: true,
    url: window.environmentSettings.api_url + '/:resourceType/:resource/libraries',
    verb: 'get',
    params: {
      'page[size]': defaultPageSize
    },
    mappingLocation: LIST_RESOURCE_MAPPING_KEYS.REVISION_LIBRARIES,
    responseReducer: (state, action)=>{
      return reduceToApiSlice(getRevisionLibrariesReducer)(
        getListReducer('revisionLibraries')(state, action),
        action
      );
    }
  },

  // extension packages
  getExtensionPackages: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.EXTENSION_PACKAGES,
    isList: true,
    url: window.environmentSettings.api_url + '/extension_packages',
    verb: 'get',
    params: { 'page[size]': defaultPageSize },
    mappingLocation: LIST_RESOURCE_MAPPING_KEYS.EXTENSION_PACKAGES,
    responseReducer: (state, action)=>{
      return reduceToApiSlice(getExtensionPackagesReducer)(
        getListReducer('extensionPackages')(state, action),
        action
      );
    }
  },
  getExtensionPackage: {
    url: window.environmentSettings.api_url + '/extension_packages/:extensionPackage',
    verb: 'get',
    responseReducer: reduceToApiSlice(getExtensionPackageReducer)
  },

  // environments
  getEnvironments: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.ENVIRONMENTS,
    isList: true,
    url: window.environmentSettings.api_url + '/properties/:property/environments',
    verb: 'get',
    params: { 'page[size]': defaultPageSize },
    mappingLocation: LIST_RESOURCE_MAPPING_KEYS.ENVIRONMENTS,
    responseReducer: getListReducer('environments')
  },
  createEnvironment: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.ENVIRONMENTS,
    url: window.environmentSettings.api_url + '/properties/:property/environments',
    verb: 'post',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('environment', action.responseBody.data);
    })
  },
  getEnvironment: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.ENVIRONMENTS,
    url: window.environmentSettings.api_url + '/environments/:environment',
    verb: 'get',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('environment', action.responseBody.data);
    })
  },
  updateEnvironment: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.ENVIRONMENTS,
    url: window.environmentSettings.api_url + '/environments/:environment',
    verb: 'patch',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('environment', action.responseBody.data);
    })
  },
  deleteEnvironment: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.ENVIRONMENTS,
    url: window.environmentSettings.api_url + '/environments/:environment',
    verb: 'delete',
    responseReducer: reduceToApiSlice((state)=> {
      return state.set('environment', null);
    })
  },

  // hosts
  getHosts: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.HOSTS,
    isList: true,
    url: window.environmentSettings.api_url + '/properties/:property/hosts',
    verb: 'get',
    params: { 'page[size]': defaultPageSize },
    mappingLocation: LIST_RESOURCE_MAPPING_KEYS.HOSTS,
    responseReducer: getListReducer('hosts')
  },
  createHost: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.HOSTS,
    url: window.environmentSettings.api_url + '/properties/:property/hosts',
    verb: 'post',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('host', action.responseBody.data);
    })
  },
  getHost: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.HOSTS,
    url: window.environmentSettings.api_url + '/hosts/:host',
    verb: 'get',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('host', action.responseBody.data);
    })
  },
  updateHost: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.HOSTS,
    url: window.environmentSettings.api_url + '/hosts/:host',
    verb: 'patch',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('host', action.responseBody.data);
    })
  },
  deleteHost: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.HOSTS,
    url: window.environmentSettings.api_url + '/hosts/:host',
    verb: 'delete',
    responseReducer: reduceToApiSlice((state)=> {
      return state.set('host', null);
    })
  },

  // secrets
  getSecrets: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.SECRETS,
    isList: true,
    url: window.environmentSettings.api_url + '/properties/:property/secrets',
    verb: 'get',
    params: { 'page[size]': defaultPageSize },
    mappingLocation: LIST_RESOURCE_MAPPING_KEYS.SECRETS,
    responseReducer: getListReducer('secrets')
  },
  createSecret: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.SECRETS,
    url: window.environmentSettings.api_url + '/properties/:property/secrets',
    verb: 'post',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.setIn(['createdSecrets', action.responseBody.data.id], action.responseBody.data);
    })
  },
  getSecret: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.SECRETS,
    url: window.environmentSettings.api_url + '/secrets/:secret',
    verb: 'get',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('secret', action.responseBody.data);
    })
  },
  getSecretDataElements: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.SECRETS,
    url: window.environmentSettings.api_url + '/secrets/:secret/data_elements',
    verb: 'get',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('secretDataElements', action.responseBody.data);
    })
  },
  updateSecret: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.SECRETS,
    url: window.environmentSettings.api_url + '/secrets/:secret',
    verb: 'patch',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('secret', action.responseBody.data);
    })
  },
  deleteSecret: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.SECRETS,
    url: window.environmentSettings.api_url + '/secrets/:secret',
    verb: 'delete',
    responseReducer: reduceToApiSlice((state)=> {
      return state.set('secret', null);
    })
  },
  getEnvironmentSecrets: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.ENVIRONMENTS,
    url: window.environmentSettings.api_url + '/environments/:environment/secrets',
    verb: 'get',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('secrets', action.responseBody.data);
    })
  },

  // libraries
  getLibraries: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.LIBRARIES,
    isList: true,
    url: window.environmentSettings.api_url + '/properties/:property/libraries',
    verb: 'get',
    params: { 'page[size]': defaultPageSize },
    mappingLocation: LIST_RESOURCE_MAPPING_KEYS.LIBRARIES,
    responseReducer: getListReducer('libraries')
  },
  getLibrary: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.LIBRARIES,
    url: window.environmentSettings.api_url + '/libraries/:library',
    verb: 'get',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('library', action.responseBody.data);
    })
  },
  createLibrary: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.LIBRARIES,
    url: window.environmentSettings.api_url + '/properties/:property/libraries',
    verb: 'post',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('library', action.responseBody.data);
    })
  },
  updateLibrary: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.LIBRARIES,
    url: window.environmentSettings.api_url + '/libraries/:library',
    verb: 'patch',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('library', action.responseBody.data);
    })
  },
  deleteLibrary: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.LIBRARIES,
    url: window.environmentSettings.api_url + '/libraries/:library',
    verb: 'delete',
    responseReducer: reduceToApiSlice((state)=> {
      return state.set('library', null);
    })
  },

  // library resources
  getLibraryRules: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.RULE_LIBRARY_RESOURCES,
    isList: true,
    url: window.environmentSettings.api_url + '/libraries/:library/rules',
    verb: 'get',
    params: { 'page[size]': defaultPageSize },
    mappingLocation: LIST_RESOURCE_MAPPING_KEYS.LIBRARY_RULE_RESOURCES,
    responseReducer: getListReducer('libraryRuleResources')
  },
  getLibraryDataElements: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.DATA_ELEMENT_LIBRARY_RESOURCES,
    isList: true,
    url: window.environmentSettings.api_url + '/libraries/:library/data_elements',
    verb: 'get',
    params: { 'page[size]': defaultPageSize },
    mappingLocation: LIST_RESOURCE_MAPPING_KEYS.LIBRARY_DATA_ELEMENT_RESOURCES,
    responseReducer: getListReducer('libraryDataElementResources')
  },
  getLibraryExtensions: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.EXTENSION_LIBRARY_RESOURCES,
    isList: true,
    url: window.environmentSettings.api_url + '/libraries/:library/extensions',
    verb: 'get',
    params: { 'page[size]': defaultPageSize },
    mappingLocation: LIST_RESOURCE_MAPPING_KEYS.LIBRARY_EXTENSION_RESOURCES,
    responseReducer: getListReducer('libraryExtensionResources')
  },

  // Builds
  getBuilds: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.BUILDS,
    isList: true,
    url: window.environmentSettings.api_url + '/libraries/:library/builds',
    verb: 'get',
    params: { 'page[size]': defaultPageSize },
    mappingLocation: LIST_RESOURCE_MAPPING_KEYS.BUILDS,
    responseReducer: getListReducer('builds')
  },
  getBuild: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.BUILDS,
    url: window.environmentSettings.api_url + '/builds/:build',
    verb: 'get',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('build', action.responseBody.data);
    })
  },
  createBuild: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.BUILDS,
    url: window.environmentSettings.api_url + '/libraries/:library/builds',
    verb: 'post',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('build', action.responseBody.data);
    })
  },
  updateBuild: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.BUILDS,
    url: window.environmentSettings.api_url + '/builds/:id',
    verb: 'patch',
    responseReducer: reduceToApiSlice((state, action) => {
      return state.set('build', action.responseBody.data);
    })
  },

  // upstream
  getUpstreamBuild: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.UPSTREAM_BUILDS,
    isList: true,
    url: window.environmentSettings.api_url + '/libraries/:library/builds',
    verb: 'get',
    params: {
      'page[size]': '1',
      'filter[status]': 'EQ succeeded',
      sort: '-created_at'
    },
    responseReducer: reduceToApiSlice((state, action)=> {
      // only return first item
      const data = action.responseBody.data;
      return state.set('upstreamBuild', data && data[0]);
    })
  },
  getUpstreamRules: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.UPSTREAM_RULES,
    isList: true,
    url: window.environmentSettings.api_url + '/builds/:build/rules',
    verb: 'get',
    params: { 'page[size]': defaultPageSize },
    mappingLocation: LIST_RESOURCE_MAPPING_KEYS.UPSTREAM_RULES,
    responseReducer: getListReducer('upstreamRules')
  },
  getUpstreamDataElements: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.UPSTREAM_DATA_ELEMENTS,
    isList: true,
    url: window.environmentSettings.api_url + '/builds/:build/data_elements',
    verb: 'get',
    params: { 'page[size]': defaultPageSize },
    mappingLocation: LIST_RESOURCE_MAPPING_KEYS.UPSTREAM_DATA_ELEMENTS,
    responseReducer: getListReducer('upstreamDataElements')
  },
  getUpstreamExtensions: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.UPSTREAM_EXTENSIONS,
    isList: true,
    url: window.environmentSettings.api_url + '/builds/:build/extensions',
    verb: 'get',
    params: { 'page[size]': defaultPageSize },
    mappingLocation: LIST_RESOURCE_MAPPING_KEYS.UPSTREAM_EXTENSIONS,
    responseReducer: getListReducer('upstreamExtensions')
  },

  // notes
  getPropertyNotes: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.PROPERTY_NOTES,
    isList: true,
    url: window.environmentSettings.api_url + '/properties/:property/notes',
    verb: 'get',
    mappingLocation: LIST_RESOURCE_MAPPING_KEYS.PROPERTY_NOTES,
    responseReducer: getListReducer('propertyNotes')
  },
  createPropertyNote: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.PROPERTY_NOTES,
    url: window.environmentSettings.api_url + '/properties/:property/notes',
    verb: 'post',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('propertyNote', action.responseBody.data);
    })
  },
  getRuleNotes: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.RULE_NOTES,
    isList: true,
    url: window.environmentSettings.api_url + '/rules/:rule/notes',
    verb: 'get',
    mappingLocation: LIST_RESOURCE_MAPPING_KEYS.RULE_NOTES,
    responseReducer: getListReducer('ruleNotes')
  },
  createRuleNote: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.RULE_NOTES,
    url: window.environmentSettings.api_url + '/rules/:rule/notes',
    verb: 'post',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('ruleNote', action.responseBody.data);
    })
  },
  getRuleComponentNotes: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.RULE_COMPONENT_NOTES,
    isList: true,
    url: window.environmentSettings.api_url + '/rule_components/:ruleComponent/notes',
    verb: 'get',
    mappingLocation: LIST_RESOURCE_MAPPING_KEYS.RULE_COMPONENT_NOTES,
    responseReducer: getListReducer('ruleComponentNotes')
  },
  createRuleComponentNote: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.RULE_COMPONENT_NOTES,
    url: window.environmentSettings.api_url + '/rule_components/:ruleComponent/notes',
    verb: 'post',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('ruleComponentNote', action.responseBody.data);
    })
  },
  getDataElementNotes: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.DATA_ELEMENT_NOTES,
    isList: true,
    url: window.environmentSettings.api_url + '/data_elements/:dataElement/notes',
    verb: 'get',
    mappingLocation: LIST_RESOURCE_MAPPING_KEYS.DATA_ELEMENT_NOTES,
    responseReducer: getListReducer('dataElementNotes')
  },
  createDataElementNote: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.DATA_ELEMENT_NOTES,
    url: window.environmentSettings.api_url + '/data_elements/:dataElement/notes',
    verb: 'post',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('dataElementNote', action.responseBody.data);
    })
  },
  getSecretNotes: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.SECRET_NOTES,
    isList: true,
    url: window.environmentSettings.api_url + '/secrets/:secret/notes',
    verb: 'get',
    mappingLocation: LIST_RESOURCE_MAPPING_KEYS.SECRET_NOTES,
    responseReducer: getListReducer('secretNotes')
  },
  createSecretNote: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.SECRET_NOTES,
    url: window.environmentSettings.api_url + '/secrets/:secret/notes',
    verb: 'post',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('secretNote', action.responseBody.data);
    })
  },
  getExtensionNotes: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.EXTENSION_NOTES,
    isList: true,
    url: window.environmentSettings.api_url + '/extensions/:extension/notes',
    verb: 'get',
    mappingLocation: LIST_RESOURCE_MAPPING_KEYS.EXTENSION_NOTES,
    responseReducer: getListReducer('extensionNotes')
  },
  createExtensionNote: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.EXTENSION_NOTES,
    url: window.environmentSettings.api_url + '/extensions/:extension/notes',
    verb: 'post',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('extensionNote', action.responseBody.data);
    })
  },
  getLibraryNotes: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.LIBRARY_NOTES,
    isList: true,
    url: window.environmentSettings.api_url + '/libraries/:library/notes',
    verb: 'get',
    mappingLocation: LIST_RESOURCE_MAPPING_KEYS.LIBRARY_NOTES,
    responseReducer: getListReducer('libraryNotes')
  },
  createLibraryNote: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.LIBRARY_NOTES,
    url: window.environmentSettings.api_url + '/libraries/:library/notes',
    verb: 'post',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('libraryNote', action.responseBody.data);
    })
  },

  // auditEvents
  getAuditEvents: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.AUDIT_EVENTS,
    url: window.environmentSettings.api_url + '/audit_events',
    verb: 'get',
    mappingLocation: LIST_RESOURCE_MAPPING_KEYS.AUDIT_EVENTS,
    responseReducer: getListReducer('auditEvents')
  },

  // appConfigurations
  getAppConfigurations: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.APP_CONFIGURATIONS,
    isList: true,
    url: window.environmentSettings.api_url + '/companies/:company/app_configurations',
    verb: 'get',
    params: { 'page[size]': defaultPageSize },
    mappingLocation: LIST_RESOURCE_MAPPING_KEYS.APP_CONFIGURATIONS,
    responseReducer: getListReducer('appConfigurations')
  },
  getAppConfiguration: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.APP_CONFIGURATIONS,
    url: window.environmentSettings.api_url + '/app_configurations/:appConfiguration',
    verb: 'get',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('appConfiguration', action.responseBody.data);
    })
  },
  createAppConfiguration: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.APP_CONFIGURATIONS,
    url: window.environmentSettings.api_url + '/companies/:company/app_configurations',
    verb: 'post',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('appConfiguration', action.responseBody.data);
    })
  },
  updateAppConfiguration: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.APP_CONFIGURATIONS,
    url: window.environmentSettings.api_url + '/app_configurations/:appConfiguration',
    verb: 'patch',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('appConfiguration', action.responseBody.data);
    })
  },
  deleteAppConfiguration: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.APP_CONFIGURATIONS,
    url: window.environmentSettings.api_url + '/app_configurations/:appConfiguration',
    verb: 'delete',
    responseReducer: reduceToApiSlice((state)=> {
      return state.set('appConfiguration', null);
    })
  },


  // AppSurfaceAppConfigurationRelationship
  createAppConfigurationRelationshipsForAppSurface: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.APP_SURFACES_APP_CONFIGURATIONS_RELATIONSHIPS,
    isList: true,
    url: window.environmentSettings.api_url + '/app_surfaces/:appSurface/relationships/app_configurations',
    verb: 'post'
  },

  // appSurfaces
  getAppSurfaces: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.APP_SURFACES,
    isList: true,
    url: window.environmentSettings.api_url + '/companies/:company/app_surfaces',
    verb: 'get',
    params: { 'page[size]': defaultPageSize },
    mappingLocation: LIST_RESOURCE_MAPPING_KEYS.APP_SURFACES,
    responseReducer: getListReducer('appSurfaces')
  },
  getAppSurface: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.APP_SURFACES,
    url: window.environmentSettings.api_url + '/app_surfaces/:appSurface',
    verb: 'get',
    responseReducer: reduceToApiSlice((state, action) => {
      return state.set('appSurface', action.responseBody.data);
    })
  },
  getAppConfigurationsForSurface: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.APP_SURFACES_APP_CONFIGURATIONS,
    isList: true,
    url: window.environmentSettings.api_url + '/app_surfaces/:appSurface/app_configurations',
    verb: 'get'
  },
  createAppSurface: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.APP_SURFACES,
    url: window.environmentSettings.api_url + '/companies/:company/app_surfaces',
    verb: 'post',
    responseReducer: reduceToApiSlice((state, action) => {
      return state.set('appSurface', action.responseBody.data);
    })
  },
  updateAppSurface: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.APP_SURFACES,
    url: window.environmentSettings.api_url + '/app_surfaces/:appSurface',
    verb: 'patch',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('appSurface', action.responseBody.data);
    })
  },
  deleteAppSurface: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.APP_SURFACES,
    url: window.environmentSettings.api_url + '/app_surfaces/:appSurface',
    verb: 'delete',
    responseReducer: reduceToApiSlice((state)=> {
      return state.set('appSurface', null);
    })
  },

  // profile
  getProfile: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.PROFILE,
    url: window.environmentSettings.api_url + '/profile',
    verb: 'get',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('profile', action.responseBody.data);
    })
  },
  updateProfile: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.PROFILE,
    url: window.environmentSettings.api_url + '/profile',
    verb: 'patch',
    responseReducer: reduceToApiSlice((state, action)=> {
      return state.set('profile', action.responseBody.data);
    })
  },

  //Entitlements
  createEntitlement: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.ENTITLEMENTS,
    url: window.environmentSettings.api_url + '/companies/:company/entitlements',
    verb: 'post',
    responseReducer: reduceToApiSlice((state, action) => {
      return state.set('entitlements', action.responseBody.data);
    })
  },
  deleteEntitlement: {
    endpointGroupKey: ENDPOINT_GROUP_KEYS.ENTITLEMENTS,
    url: window.environmentSettings.api_url + '/entitlements/:entitlement',
    verb: 'delete'
  }

};
