/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import actionsHandler from '../../../../redux/actionsHandler';
import {actionCreators as apiActions} from '../../../../utils/api/apiActions';
import {RULES, DATA_ELEMENTS, EXTENSIONS} from '../../../../utils/api/apiTypes';
import {snakeCase} from 'lodash-es';
import Immutable from 'seamless-immutable';
import {CONTAINS} from '../../../../utils/sortFilterQueryParamsUtils';
import { getActionCreators as getDialogsActions } from '../../../higherOrderComponents/dialogsActions';
import {getCurrentRouteParamsFromState} from '../../../../routes/routeSelectors';
import {getActionCreators as getPublishingActions, LIBRARY_EDIT_KEY} from '../publishingActions';
import {actionCreators as getExpandedActions} from '../../../CustomTable/expandedActions';
import { getApiData } from '../../../../utils/api/apiTools';
import {getResourcePreparedForSave, buildRelationships, getRuleComponentDiffActions} from '../../../../utils/resourceUtils';
import {actionCreators as resourceCopyActions} from '../../../resourceCopy/resourceCopyActions';
import { getExpandedLibraryResourceFromState } from '../publishingSelectors';

export const REVISION_SELECTOR_STATE_KEY = 'revisionSelector';
export const REPLACE_LATEST_STATE_KEY = 'replaceLatest';

const dialogsActions = getDialogsActions(REVISION_SELECTOR_STATE_KEY);

const INITIALIZE = 'revisionSelector/INITIALIZE';
export const SET_LOADING = 'revisionSelector/SET_LOADING';
export const SELECT_DELEGATE_TYPE = 'revisionSelector/SELECT_DELEGATE_TYPE';
export const SELECT_DELEGATE = 'revisionSelector/SELECT_DELEGATE';
export const SELECT_REVISION = 'revisionSelector/SELECT_REVISION';
export const SET_FILTER = 'revisionSelector/SET_FILTER';
export const SET_FILTER_TYPE = 'revisionSelector/SET_FILTER_TYPE';
export const SET_ALL_VALID = 'revisionSelector/SET_ALL_VALID';
export const SET_REPLACING_LATEST = 'revisionSelector/SET_REPLACING_LATEST';
export const SET_REPLACE_LATEST_VALIDATION_DATA = 'revisionSelector/SET_REPLACE_LATEST_VALIDATION_DATA';


let changeFilterTimeout = 0;

const defaultState = Immutable({
  loading: false,
  selectedDelegateType: null,
  selectedDelegate: null,
  selectedRevision: null,
  filter: null,
  filterType: 'all',
  allValid: true,
  replacingLatest: false,
  replaceLatestValidationData: null
});

//Reducers
export default actionsHandler({
  [INITIALIZE]() {
    return defaultState;
  },
  [SET_LOADING](state, {payload:{isLoading}}) {
    return state.set('loading', isLoading);
  },
  [SELECT_DELEGATE_TYPE](state, {payload:{delegateType}}) {
    return state.set('selectedDelegateType', delegateType);
  },
  [SELECT_DELEGATE](state, {payload:{delegate}}) {
    return state.set('selectedDelegate', delegate);
  },
  [SELECT_REVISION](state, {payload:{revision}}) {
    return state.set('selectedRevision', revision);
  },
  [SET_FILTER](state, {payload:{filter}}) {
    return state.set('filter', filter);
  },
  [SET_FILTER_TYPE](state, {payload:{filterType}}) {
    return state.set('filterType', filterType);
  },
  [SET_ALL_VALID](state, {payload:{allValid}}) {
    return state.set('allValid', allValid);
  },
  [SET_REPLACING_LATEST](state, {payload:{replacingLatest}}) {
    return state.set('replacingLatest', replacingLatest);
  },
  [SET_REPLACE_LATEST_VALIDATION_DATA](state, {payload:{replaceLatestValidationData}}) {
    return state.set('replaceLatestValidationData', replaceLatestValidationData);
  },
  default: (state = defaultState)=> {
    return state;
  }
});


//Action Creators
export let actionCreators = {
  initialize() {
    return { type: INITIALIZE };
  },
  setLoading(isLoading) {
    return {
      type: SET_LOADING,
      payload: { isLoading }
    };
  },
  setDelegateType(type) {
    return {
      type: SELECT_DELEGATE_TYPE,
      payload: { delegateType: type }
    };
  },
  setDelegate(delegate) {
    return {
      type: SELECT_DELEGATE,
      payload: { delegate }
    };
  },
  setRevision(revision) {
    return {
      type: SELECT_REVISION,
      payload: { revision }
    };
  },
  setFilter(filter) {
    return {
      type: SET_FILTER,
      payload: { filter }
    };
  },
  setFilterType(filterType) {
    return {
      type: SET_FILTER_TYPE,
      payload: { filterType }
    };
  },
  setAllValid(allValid) {
    return {
      type: SET_ALL_VALID,
      payload: { allValid }
    };
  },
  setReplacingLatest(replacingLatest) {
    return {
      type: SET_REPLACING_LATEST,
      payload: { replacingLatest }
    };
  },
  setReplaceLatestValidationData(replaceLatestValidationData) {
    return {
      type: SET_REPLACE_LATEST_VALIDATION_DATA,
      payload: { replaceLatestValidationData }
    };
  },

  loadResourceList(type, params, filterType, filter) {
    return (dispatch)=>{
      let apiEndpointName;
      if (type === RULES) {
        apiEndpointName = 'getRules';
      } else if (type === DATA_ELEMENTS) {
        apiEndpointName = 'getDataElements';
      } else if (type === EXTENSIONS) {
        apiEndpointName = 'getExtensions';
      }

      if (apiEndpointName) {
        let urlParams = {};
        if (filterType === 'changed') { urlParams['filter[published]'] = 'EQ false'; }
        if (filter) {
          urlParams['filter[revision_number]'] = 'EQ 0';
          urlParams['filter[name]'] = CONTAINS + ' ' + filter;
        }
        return dispatch(apiActions.apiAction({
          name: apiEndpointName,
          urlData: {...params},
          urlParams
        }));
      } else {
        return false;
      }
    };
  },
  loadResourceRevisions(delegate, params) {
    return (dispatch)=>{
      return dispatch(apiActions.apiAction({
        name: 'getRevisions',
        urlData: {
          ...params,
          resource: delegate.id,
          resourceType: snakeCase(delegate.type)
        }
      }));
    };
  },
  loadRevisionLibraries(delegate, params) {
    return (dispatch)=>{
      return dispatch(apiActions.apiAction({
        name: 'getRevisionLibraries',
        urlData: {
          ...params,
          resource: delegate.id,
          resourceType: snakeCase(delegate.type)
        },
      }));
    };
  },

  // sets or resets all the things
  setAll(params, filter, filterType, delegateType, delegate, revision) {
    return (dispatch) => {
      dispatch(actionCreators.setFilter(filter || ''));
      dispatch(actionCreators.setFilterType(filterType || 'all'));
      if (params) {
        return Promise.all([
          dispatch(actionCreators.changeResourceType(params, delegateType)),
          dispatch(actionCreators.changeSelectedDelegate(params, delegate)),
          dispatch(actionCreators.changeSelectedRevision(params, revision))
        ]);
      } else {
        return Promise.all([
          dispatch(actionCreators.setDelegateType(null)),
          dispatch(actionCreators.setDelegate(null)),
          dispatch(actionCreators.setRevision(null))
        ]);
      }
    };
  },
  changeFilter(params, filter) {
    return (dispatch, getState) => {
      const state = getState();
      const { selectedDelegateType, filterType } = state.revisionSelector;

      dispatch(actionCreators.setDelegate(null));
      dispatch(actionCreators.setRevision(null));
      dispatch(actionCreators.setFilter(filter));
      clearTimeout(changeFilterTimeout);
      changeFilterTimeout = setTimeout(() => {
        dispatch(actionCreators.loadResourceList(
          selectedDelegateType, params, filterType, filter ? filter : null
        ));
      }, 700);
    };
  },
  changeFilterType(params, filterType) {
    return (dispatch, getState) => {
      const state = getState();
      const { selectedDelegateType, filter } = state.revisionSelector;

      dispatch(actionCreators.setDelegate(null));
      dispatch(actionCreators.setRevision(null));
      dispatch(actionCreators.setFilterType(filterType));
      return dispatch(actionCreators.loadResourceList(
        selectedDelegateType, params, filterType, filter ? filter : null
      ));
    };
  },
  changeResourceType(params, resourceType) {
    return (dispatch, getState) => {
      const state = getState();
      const { filter, filterType } = state.revisionSelector;

      dispatch(actionCreators.setFilter(null));
      dispatch(actionCreators.setRevision(null));
      dispatch(actionCreators.setDelegate(null));
      dispatch(actionCreators.setDelegateType(resourceType));
      return dispatch(actionCreators.loadResourceList(
        resourceType, params, filterType, filter ? filter : null
      ));
    };
  },
  changeSelectedDelegate(params, selectedDelegate) {
    return (dispatch) => {
      dispatch(actionCreators.setRevision(null));
      dispatch(actionCreators.setDelegate(selectedDelegate));
      return dispatch(actionCreators.loadResourceRevisions(selectedDelegate, params));
    };
  },
  changeSelectedRevision(params, selectedRevision) {
    return (dispatch) => {
      dispatch(actionCreators.setRevision(selectedRevision));
      return dispatch(actionCreators.loadRevisionLibraries(selectedRevision, params));
    };
  },
  setReplaceLatestDialogOpen({isOpen}) {
    return (dispatch) => {
      if (isOpen) {
        dispatch(dialogsActions.openDialog(REPLACE_LATEST_STATE_KEY));
      } else {
        dispatch(dialogsActions.closeDialog(REPLACE_LATEST_STATE_KEY));
      }
    };
  },

  validateResourceExtensionDependencies(resource) {
    return async(dispatch, getState) => {
      let requiredExtensions = [];
      let uninstalledExtensions = [];
      let upgradedExtensionPackages = [];

      const params = getCurrentRouteParamsFromState(getState());

      if (resource.type === RULES || resource.type === DATA_ELEMENTS) {
        const extensionPackages = await dispatch(actionCreators.loadExtensionPackages({
          params,
          loadAllPages: true,
          bypassError: false,
          abortSignal: null,
          swallowAbortErrors: false
        }));
        const extensions = await dispatch(resourceCopyActions.loadExtensions({
          params,
          loadAllPages: true,
          bypassError: false,
          abortSignal: null,
          swallowAbortErrors: false
        }));
        const extendedResourcesData = await dispatch(resourceCopyActions.getExtendedResourcesData({
          params,
          resources: [resource],
          extensions: extensions,
          abortSignal: null,
          swallowAbortErrors: false
        }));

        requiredExtensions = extendedResourcesData[0].requiredExtensions;
        requiredExtensions.forEach(requiredExtension => {
          // if the required extension was not found, it has been uninstalled
          const foundRequiredResourceExtension = extensions.find(
            extension=>extension.id === requiredExtension.id
          );
          if (!foundRequiredResourceExtension) {
            uninstalledExtensions.push(requiredExtension);
          }

          // check the required extensionPackage by name for a different version
          const foundExtensionPackage = extensionPackages.find(
            extensionPackage=>extensionPackage.attributes.name === requiredExtension.attributes.name
          );
          if (
            !foundExtensionPackage ||
            requiredExtension.attributes.version !== foundExtensionPackage.attributes.version
          ) {
            upgradedExtensionPackages.push(foundExtensionPackage);
          }
        });
      }

      const replaceLatestValidationData = {
        isValid: uninstalledExtensions.length === 0 && upgradedExtensionPackages.length === 0,
        requiredExtensions,
        uninstalledExtensions,
        upgradedExtensionPackages
      };

      dispatch(actionCreators.setReplaceLatestValidationData(replaceLatestValidationData));
      return replaceLatestValidationData;
    };
  },
  loadExtensionPackages({
    params,
    urlParams = {},
    loadAllPages,
    bypassError,
    abortSignal
  } = {}) {
    return (dispatch, getState) => {
      const STATE_KEY = 'revisionSelectorExtensionPackages';
      const property = getState().api.property;
      const platform = property.attributes.platform;
      return dispatch(apiActions.apiAction({
        name: 'getExtensionPackages',
        stateKey: STATE_KEY,
        urlData: params,
        urlParams: {
          'filter[platform]': `EQ ${platform}`,
          'max_availability': property.attributes.development ? 'development' : 'public',
          ...urlParams
        },
        loadAllPages,
        bypassError,
        abortSignal,
        swallowAbortErrors: false
      })).then(()=>{
        const extensionPackages = getApiData(getState(), STATE_KEY).extensionPackages;
        return extensionPackages && Object.values(extensionPackages) || [];
      });
    };
  },

  replaceLatestWithSelectedRevision() {
    return (dispatch, getState) => {
      dispatch(actionCreators.setReplacingLatest(true));

      const params = getCurrentRouteParamsFromState(getState());
      const {selectedRevision} = getState().revisionSelector;

      let reviseResourcePromise;
      if (selectedRevision.type === RULES) {
        reviseResourcePromise = dispatch(actionCreators.replaceLatestRule({
          params,
          ruleRevision: selectedRevision,
          abortSignal: null,
          swallowAbortErrors: false
        }));
      } else if (selectedRevision.type === DATA_ELEMENTS) {
        reviseResourcePromise = dispatch(actionCreators.replaceLatestDataElement({
          params,
          dataElementRevision: selectedRevision,
          abortSignal: null,
          swallowAbortErrors: false
        }));
      } else if (selectedRevision.type === EXTENSIONS) {
        reviseResourcePromise = dispatch(actionCreators.replaceLatestExtension({
          params,
          extensionRevision: selectedRevision,
          abortSignal: null,
          swallowAbortErrors: false
        }));
      }

      return reviseResourcePromise.then(()=>{
        // update the revision list so we can select the newly created revision
        return dispatch(actionCreators.loadResourceRevisions(selectedRevision, params)).then(()=>{
          dispatch(actionCreators.setReplacingLatest(false));

          const revisions = getState().api.revisions;
          const latestRevision = revisions.find(
            resource=>resource.attributes.revisionNumber === resource.meta.latestRevisionNumber
          );

          // due to a circular dependency issue, webpack won't resolve getPublishingActions or LIBRARY_EDIT_KEY until
          // after initialization which prevents us from defining these variables at the top of the file
          const publishingActions = getPublishingActions(LIBRARY_EDIT_KEY);
          const expandedActions = getExpandedActions(LIBRARY_EDIT_KEY);
          const expandedResource = getExpandedLibraryResourceFromState(getState());
          dispatch(publishingActions.replaceDelegateInLibrary(expandedResource, latestRevision));
          dispatch(actionCreators.setDelegate(latestRevision));
          dispatch(expandedActions.collapseAll(true));
          dispatch(actionCreators.setReplaceLatestDialogOpen(false));
        });
      }).catch(()=>{
        dispatch(actionCreators.setReplacingLatest(false));
      });
    };
  },
  replaceLatestRule({params, ruleRevision, abortSignal, swallowAbortErrors, bypassError}) {
    return (dispatch) => {
      const latestId = ruleRevision.relationships.origin.data.id;
      const ruleForSave = {
        ...getResourcePreparedForSave(ruleRevision),
        id: latestId
      };

      // update the content
      return dispatch(apiActions.apiAction({
        name: 'updateRule',
        urlData: {...params, rule: latestId},
        data: {data: ruleForSave},
        abortSignal,
        swallowAbortErrors,
        bypassError,
        noReduxDomEventEmit: true
      })).then(async()=>{
        await dispatch(
          actionCreators.copyRuleComponentsToLatest({
            params,
            ruleRevision,
            abortSignal,
            swallowAbortErrors,
            bypassError
          })
        );

        // create a revision of the resource
        const dataForRevise = {id: latestId, type: RULES, meta: {action: 'revise'}};
        return dispatch(apiActions.apiAction({
          name: 'updateRule',
          urlData: {...params, rule: latestId},
          data: {data: dataForRevise},
          abortSignal,
          swallowAbortErrors,
          bypassError,
          noReduxDomEventEmit: true
        }));
      });
    };
  },
  copyRuleComponentsToLatest({params, ruleRevision, abortSignal, swallowAbortErrors, bypassError}) {
    return async(dispatch, getState) => {
      // We can't just delete all and copy from the old revision because the changes will not be connected
      // in compare view. Instead, we'll update only rule components that are different (to preserve a
      // semi-understandable revision history). There is still an issue where if a rule component was
      // deleted and then restored from an old revision, it will still not show as connected in compare view.
      // This is a backend limitation because ruleComponents cannot be restored - originIds will not match
      // between old ruleComponent revisions and restored ones.

      const latestId = ruleRevision.relationships.origin.data.id;

      const latestRuleComponents = await dispatch(apiActions.apiAction({
        name: 'getRuleComponents',
        urlData: {...params, rule: latestId},
        abortSignal,
        swallowAbortErrors,
        bypassError,
        noReduxDomEventEmit: true
      })).then(()=>{
        return getApiData(getState(), 'ruleComponents');
      });

      const revisionRuleComponents = await dispatch(apiActions.apiAction({
        name: 'getRuleComponents',
        urlData: {...params, rule: ruleRevision.id},
        abortSignal,
        swallowAbortErrors,
        bypassError,
        noReduxDomEventEmit: true
      })).then(()=>{
        return getApiData(getState(), 'ruleComponents');
      });

      const ruleComponentActionsForLatest = getRuleComponentDiffActions(latestRuleComponents, revisionRuleComponents);

      // delete
      const ruleComponentDeletePromises = [];
      ruleComponentActionsForLatest.toDelete.forEach((ruleComponent)=>{
        ruleComponentDeletePromises.push(dispatch(apiActions.apiAction({
          name: 'deleteRuleComponent',
          urlData: {...params, ruleComponent: ruleComponent.id},
          abortSignal,
          swallowAbortErrors,
          bypassError,
          noReduxDomEventEmit: true
        })));
      });

      // update
      const ruleComponentUpdatePromises = [];
      ruleComponentActionsForLatest.toUpdate.forEach((ruleComponents)=>{
        const {initialRuleComponent, finalRuleComponent} = ruleComponents;

        // rule components can only be attached to 1 rule revision and are
        // automatically revised/updated when the rule gets revised so each
        // rule revision will have a different ruleComponent id for the same
        // ruleComponent.
        const ruleComponentForSave = {
          ...getResourcePreparedForSave(finalRuleComponent),
          id: initialRuleComponent.id
        };
        ruleComponentUpdatePromises.push(dispatch(apiActions.apiAction({
          name: 'updateRuleComponent',
          urlData: {...params, ruleComponent: initialRuleComponent.id},
          data: {data: ruleComponentForSave},
          abortSignal,
          swallowAbortErrors,
          bypassError,
          noReduxDomEventEmit: true
        })));
      });

      // create
      const ruleComponentCreatePromises = [];
      ruleComponentActionsForLatest.toCreate.forEach((ruleComponent)=>{
        const ruleComponentForSave = {
          ...getResourcePreparedForSave(ruleComponent, {
            relationships: {
              ...ruleComponent.relationships,
              rules: buildRelationships([latestId])
            }
          }),
        };
        ruleComponentCreatePromises.push(dispatch(apiActions.apiAction({
          name: 'createRuleComponent',
          urlData: {...params},
          data: {data: ruleComponentForSave},
          abortSignal,
          swallowAbortErrors,
          bypassError,
          noReduxDomEventEmit: true
        })));
      });

      return Promise.all([
        ...ruleComponentDeletePromises,
        ...ruleComponentUpdatePromises,
        ...ruleComponentCreatePromises
      ]);
    };
  },
  replaceLatestDataElement({params, dataElementRevision, abortSignal, swallowAbortErrors, bypassError}) {
    return (dispatch) => {
      const latestId = dataElementRevision.relationships.origin.data.id;
      const dataElementForSave = {
        ...getResourcePreparedForSave(dataElementRevision),
        id: latestId
      };

      // update the content
      return dispatch(apiActions.apiAction({
        name: 'updateDataElement',
        urlData: {...params, dataElement: latestId},
        data: {data: dataElementForSave},
        abortSignal,
        swallowAbortErrors,
        bypassError,
        noReduxDomEventEmit: true
      })).then(()=>{
        // revise the resource
        const dataForRevise = {id: latestId, type: DATA_ELEMENTS, meta: {action: 'revise'}};
        return dispatch(apiActions.apiAction({
          name: 'updateDataElement',
          urlData: {...params, dataElement: latestId},
          data: {data: dataForRevise},
          abortSignal,
          swallowAbortErrors,
          bypassError,
          noReduxDomEventEmit: true
        }));
      });
    };
  },
  replaceLatestExtension({params, extensionRevision, abortSignal, swallowAbortErrors, bypassError}) {
    return (dispatch, getState) => {
      const extensionSaveData = {
        data: { ...extensionRevision }
      };

      const latestId = extensionRevision.relationships.origin.data.id;
      return dispatch(apiActions.apiAction({
        name: 'updateExtension',
        urlData: {...params, extension: latestId},
        data: extensionSaveData,
        abortSignal,
        swallowAbortErrors,
        bypassError,
        noReduxDomEventEmit: true
      })).then(()=>{
        // extensions automatically cut a new revision on every save, so there
        // is no need to do an extra request with {action:'revise'}
        const updatedExtension = getApiData(getState(), 'extension');
        return Promise.resolve(updatedExtension);
      });
    };
  }
};
