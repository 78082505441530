/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import React from 'react';
import Wait from '@react/react-spectrum/Wait';
import NoContent from '../content/noContent';

export default function ControlledListLoader({
  list,
  listQuery,
  initialLoadCompleted,
  loading,
  listRenderer,
  listHeaderRenderer,
  noResultsRenderer = ()=>(
    <NoContent
      className="u-padding"
      text="No results based on your chosen filters."
    />
  ),
  emptyListRenderer,
  className,
  filtersRenderer,
  usesSearchEndpoint
}) {


  const queryContainsFilters = Boolean(listQuery.filter);

  let finalListRenderer = listRenderer;
  let finalListHeaderRenderer = listHeaderRenderer;
  if (!list || list && !list.length) {
    finalListRenderer = noResultsRenderer;
    // Since we're planning on switching filters on other resources to use search,
    // we still need this logic. But if we're using search (like in audit events)
    // we don't want it to hit this part.
    if (!queryContainsFilters && !loading && !usesSearchEndpoint) {
      finalListHeaderRenderer = ()=>null;
      finalListRenderer = emptyListRenderer;
    }
  }


  return (
    filtersRenderer ? (
      <div className={className ? className : ''}>
        {!initialLoadCompleted ? null : finalListHeaderRenderer(queryContainsFilters)}
        <div className="u-flex u-flexOne u-paddingBottom">
          {filtersRenderer()}
          {!initialLoadCompleted ? (<Wait centered size="L"></Wait>) : finalListRenderer()}
        </div>
      </div>
    ) : (
      <div className={className ? className : ''}>
        {!initialLoadCompleted ? null : finalListHeaderRenderer(queryContainsFilters)}
        {!initialLoadCompleted ? (<Wait centered size="L"></Wait>) : finalListRenderer()}
      </div>
    )
  );
}
