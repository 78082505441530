/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/


import compareVersions from 'compare-versions';
// a > b results in 1
// a === b results in 0
// a < b results in -1

export function isNewerVersionOfSameExtension(sourceExtension, extensionInQuestion) {
  const versionCompared = compareVersions(sourceExtension.attributes.version, extensionInQuestion.attributes.version);
  return isSameExtensionName(sourceExtension, extensionInQuestion) && versionCompared === 1;
}

export function isOlderOrSameVersionOfSameExtension(sourceExtension, extensionInQuestion) {
  const versionCompared = compareVersions(sourceExtension.attributes.version, extensionInQuestion.attributes.version);
  return isSameExtensionName(sourceExtension, extensionInQuestion) && versionCompared < 1;
}

export function isSameExtensionName(sourceExtension, extensionInQuestion) {
  return sourceExtension.attributes.name === extensionInQuestion.attributes.name;
}

export function getExtensionPackageEcmaVersion(extensionPackage) {
  let ecmaVersion = null;
  // handle things like 'es5', 'es6', 'es7', 'es2015', 'es2016', ..., 'es2021', etc
  // es2015 = es6 so we don't need to handle prior ES years.
  const numericEcmaVersion = parseInt(extensionPackage?.attributes?.ecmaVersion?.toLowerCase()?.replace(/^es/, ''));
  if (!isNaN(numericEcmaVersion)) {
    ecmaVersion = numericEcmaVersion;
  }
  return ecmaVersion;
}
