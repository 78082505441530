/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import Immutable from 'seamless-immutable';
import actionsHandler from '../../redux/actionsHandler';

export const STATE_KEY = 'rightRail';
export const SET_RIGHT_RAIL_ACTIVE_TAB = 'rightRail/SET_RIGHT_RAIL_ACTIVE_TAB';

// Reducers
export default actionsHandler({
  [SET_RIGHT_RAIL_ACTIVE_TAB]: (state = {}, action) => {
    return state.set('activeTab', action.payload.activeTab);
  },
  default: (state = Immutable({activeTab: null})) => {
    return state;
  }
});

// Action Creators
export let actionCreators = {
  setRightRailActiveTab(activeTab) {
    return {
      type: SET_RIGHT_RAIL_ACTIVE_TAB,
      payload: {activeTab}
    };
  }
};
