import {
  getApiAction,
  addOutstandingRequestId,
  updateEndpoint,
  API_CALL_LOADING,
  API_CALL_SUCCESS,
  API_CALL_FAIL,
  removeOutstandingRequestId,
  getNewRequestId,
  updateEndpointStatus
} from './apiActions';

import apiMappings from './apiMappings';

export const API_CALL_LOAD_ALL_PAGES_DONE = '@@api/API_CALL_LOAD_ALL_PAGES_DONE';

export function apiActionsLoadAllPages(dispatch, options) {
  const groupRequestId = getNewRequestId();
  const { urlParams = {} } = options;
  return dispatch(getApiAction({
    ...options,
    groupRequestId,
    urlParams: { // during loadAllPages, respect any urlParams that come in from options, then override a few things
      ...urlParams,
      'page[number]': 1,
      'page[size]': 100, // this is the max allowed by the api
    }
  })).then(({action, res})=>{
    const pagination = res.body.meta.pagination;
    const promises = [{action, res}];

    for (let iterator = 2; iterator <= pagination.totalPages; iterator ++) {
      promises.push(dispatch(getApiAction({
        ...options,
        groupRequestId,
        urlParams: {
          ...urlParams,
          'page[number]': iterator,
          'page[size]': 100, // this is the max allowed by the api
        }
      })));
    }

    return Promise.all(promises);
  }).then((responses)=>{
    try { // don't swallow the error regardless of what the API returns
      const responsesData = responses.map(({res})=>res.body.data);
      const responseBody = {
        ...responses[0].res.body,
        data: [].concat.apply([], responsesData) // flatten array of arrays
      };

      dispatch({
        ...responses[0].action,
        type: API_CALL_LOAD_ALL_PAGES_DONE,
        payload: {
          ...responses[0].action.payload,
          ...options,
          groupRequestId
        },
        responseBody
      });
    } catch (error) {
      throw error;
    }

    return responses;
  });
}


export function loadingAllPagesReducer(state, action) {
  if (action.type.match(API_CALL_LOADING)) {
    const pageNumber = action.payload.urlParams['page[number]'] || 1;
    state = addOutstandingRequestId(state, action.payload.groupRequestId);
    state = addOutstandingRequestId(state, action.payload.requestId);
    if (pageNumber === 1) {
      state = updateEndpointStatus(state, action, {
        loading: true,
        pages:{
          [pageNumber]: {
            error: null,
            loading: true,
            pagination: null
          }
        }
      });
      state = updateEndpoint(state, action);
    } else {
      state = updateEndpointStatus(state, action, {
        pages: {
          [pageNumber]: {
            error: null,
            loading: true,
            pagination: null
          }
        }
      });
      state = updateEndpoint(state, action);
    }
  }

  return state;
}

export function successAllPagesReducer(state, action) {
  if (action.type.match(API_CALL_SUCCESS)) {
    const groupRequestId = state.api.outstandingRequestIds.indexOf(action.payload.groupRequestId);
    const requestIdIndex = state.api.outstandingRequestIds.indexOf(action.payload.requestId);
    const pageNumber = action.payload.urlParams['page[number]'] || 1;

    // Only handle the response if its id hasn't been removed from outstandingRequestIds.
    // IDs are removed from outstandingRequestIds when their respective responses are received
    // or the user navigates away from the view.
    if (requestIdIndex !== -1 && groupRequestId !== -1) {
      state = removeOutstandingRequestId(state, action.payload.requestId);
      state = updateEndpointStatus(state, action, {
        pages: {
          [pageNumber]: {
            error: null,
            loading: false,
            pagination: action.responseBody?.meta?.pagination || null
          }
        }
      });
      state = updateEndpoint(state, action);
    }
  }

  return state;
}

export function failAllPagesReducer(state, action) {
  if (action.type.match(API_CALL_FAIL)) {
    const groupRequestId = state.api.outstandingRequestIds.indexOf(action.payload.groupRequestId);
    const requestIdIndex = state.api.outstandingRequestIds.indexOf(action.payload.requestId);
    const pageNumber = action.payload.urlParams['page[number]'] || 1;

    if (requestIdIndex !== -1 && groupRequestId !== -1) {
      state = removeOutstandingRequestId(state, action.payload.requestId);
      state = removeOutstandingRequestId(state, action.payload.groupRequestId);
      state = updateEndpointStatus(state, action, {
        pages: {
          [pageNumber]: {
            error: action.error.message,
            loading: false,
            pagination: null
          }
        }
      });
      state = updateEndpoint(state, action);
    }
  }

  return state;
}

export function successAllPagesCompleteReducer(state, action) {
  if (action.type === API_CALL_LOAD_ALL_PAGES_DONE) {
    const groupRequestId = state.api.outstandingRequestIds.indexOf(action.payload.groupRequestId);

    if (groupRequestId !== -1) {
      state = removeOutstandingRequestId(state, action.payload.groupRequestId);
      const {responseReducer} = apiMappings[action.payload.name];

      if (responseReducer) {
        state = responseReducer(state, action);
      }
      state = updateEndpointStatus(state, action, {
        loading: false
      });
      state = updateEndpoint(state, action);
    }
  }

  return state;
}
