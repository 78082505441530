import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import InfoIcon from '@react/react-spectrum/Icon/Info';
import CheckmarkIcon from '@react/react-spectrum/Icon/Checkmark';
import AlertIcon from '@react/react-spectrum/Icon/Alert';
import '../style/index.styl';

export default class InlineTextAlert extends Component {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string,PropTypes.element]),
    variant: PropTypes.oneOf(['default', 'info', 'success', 'error', 'warning'])
  };

  static defaultProps = {
    label: '',
    variant: 'info',
    noIcon: false
  };

  render() {
    const {
      label,
      children,
      variant,
      className,
      noIcon,
      ...otherProps
    } = this.props;

    let Icon = null;
    let color = null;
    if(variant === 'info') {
      Icon = InfoIcon;
      color = ''
    } else if (variant === 'success') {
      Icon = CheckmarkIcon;
    } else if (variant === 'error') {
      Icon = AlertIcon;
    } else if (variant === 'warning') {
      Icon = AlertIcon;
    }

    return (
      <span {...otherProps} className={classNames([
        'inlineTextAlert',
        `inlineTextAlert--${variant}`,
        {'inlinetextAlert--with-icon': !noIcon},
         className
      ])}>
        {noIcon ? null : <Icon size="S" className="inlineTextAlert-Icon"/>}
        <span className="inlineTextAlert-Label">
          {label}
          {children}
        </span>
      </span>
    );
  }
}
