/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/


import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import {throttle} from 'lodash-es';
import classNames from 'classnames';
import Button from '@react/react-spectrum/Button';
import Dropdown from '@react/react-spectrum/Dropdown';
import {Menu} from '@react/react-spectrum/Menu';
import MoreIcon from '@react/react-spectrum/Icon/More';
import ChevronDownIcon from '@react/react-spectrum/Icon/ChevronDown';

/*
This menu must exist inside a wrapper who's size changes. It was built to be used inside the
<TableActions /> component.
The passed in menuItems should render to `display:inline` or `display:inline-block` elements
truncatedMenuItems should be an array of react-spectrum MenuItems
*/

class AutoTruncatingActionMenu extends PureComponent {
  static propTypes = {
    menuItems: PropTypes.array,
    truncatedMenuItems: PropTypes.array,
    truncatedMenuOnSelect: PropTypes.func
  };

  state = { width: null };
  _containerDomNode = null;
  _dropdownDomNode = null;
  _renderedMenuItems = [];
  _truncatedMenuItems = [];
  _throttledOnResize = null;

  onResize = () => {
    if (this._containerDomNode) {
      const containerWidth = this._containerDomNode.offsetWidth;
      const dropdownWidth = ReactDOM.findDOMNode(this._dropdownDomNode).offsetWidth;
      // for each of the menuItems (starting at the end), if they don't fit in
      // the container's width, mark them as truncated by moving them to
      // the truncated menu
      const {menuItems} = this.props;
      const allItemsRequiredWidth = this.getWidthOfMenuItems(menuItems.length - 1);
      const allItemsFitInContainer = allItemsRequiredWidth < containerWidth;

      this._truncatedMenuItems = [];
      for (let index = menuItems.length - 1; index >= 0; index--) {
        // const menuItem = menuItems[index];
        const remainingMenuItemsWidth = this.getWidthOfMenuItems(index);
        const totalMenuItemsWidth = (
          allItemsFitInContainer ? remainingMenuItemsWidth : (
            remainingMenuItemsWidth + dropdownWidth
          )
        );

        if (totalMenuItemsWidth > containerWidth) {
          this._truncatedMenuItems.unshift({index});
        }
      }

      // update the width and kick off the render
      this.setState({ width: containerWidth });
    }
  };
  getWidthOfMenuItems = (index) => {
    let width = 0;
    this._renderedMenuItems.slice(0, index + 1).forEach(renderedMenuItem => {
      const renderedMenuItemDomNode = ReactDOM.findDOMNode(renderedMenuItem);
      return width += renderedMenuItemDomNode.offsetWidth;
    });
    return width;
  };

  componentDidMount = () => {
    this._throttledOnResize = throttle(this.onResize.bind(this), 30);
    this.onResize();
    window.addEventListener('resize', this._throttledOnResize);
  };
  componentWillUnmount() {
    window.removeEventListener('resize', this._throttledOnResize);
  }

  render = () => {
    const {menuItems, truncatedMenuItems, truncatedMenuOnSelect} = this.props;
    const allTruncated = menuItems && this._truncatedMenuItems.length === menuItems.length;

    if (!menuItems || !truncatedMenuItems || menuItems.length !== truncatedMenuItems.length) {
      console.warn('menuItems and truncatedMenuItems must contain the same number of nodes');
      return null;
    }

    return (
      <div className="AutoTruncatingActionMenu u-marginRight" ref={(node)=>{ this._containerDomNode = node; }}>
        {menuItems && menuItems.map((menuItem, index)=>{
          const isTruncatedMenuItem = Boolean(this._truncatedMenuItems.find(truncatedMenuItemRef=>{
            return truncatedMenuItemRef && truncatedMenuItemRef.index === index;
          }));

          // we have to render the menuItems even if they are truncated
          // so that we can continually check their width
          return React.cloneElement(menuItem, {
            key: index,
            ref: (node) => {
              this._renderedMenuItems[index] = node;
            },
            className: classNames(menuItem.props.className, {'invisible': isTruncatedMenuItem})
          });
        })}

        <div className={classNames('u-inlineBlock', {'invisible': this._truncatedMenuItems.length === 0})}>
          <Dropdown
            ref={(node)=>{ this._dropdownDomNode = node; }}
            className="u-inlineBlock"
            onSelect={truncatedMenuOnSelect}
          >
            <Button quiet={!allTruncated} variant="primary" className="u-relativePosition">
              {allTruncated ? (
                <React.Fragment>
                  <span className="u-paddingRightXs">Actions</span>
                  <ChevronDownIcon size="S" />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <MoreIcon size="S" />
                  <span className="u-paddingLeftXs">More</span>
                </React.Fragment>
              )}
            </Button>
            <Menu className="tableActionsMenu">
              {this._truncatedMenuItems.map(({index})=>{
                return React.cloneElement(truncatedMenuItems[index], {key: index});
              })}
            </Menu>
          </Dropdown>
        </div>
      </div>
    );
  };
};

export default AutoTruncatingActionMenu;
