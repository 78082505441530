/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import actionsHandler from '../../redux/actionsHandler';
import Immutable from 'seamless-immutable';
import { setLocalStorageItem } from '../../utils/storageUtils';

export const LEFT_NAV_EXPANDED_KEY = 'leftNavExpanded';


export const SET_IS_EXPANDED = 'leftNav/SET_IS_EXPANDED';

//Reducers
export default actionsHandler({
  [SET_IS_EXPANDED](state, {payload:{isExpanded}}) {
    return state.merge({isExpanded});
  },
  default(state = Immutable({isExpanded: true})) {
    return state;
  }
});

//Action Creators
export let actionCreators = {
  setIsExpanded(isExpanded) {
    setLocalStorageItem(LEFT_NAV_EXPANDED_KEY, isExpanded);

    return {
      type: SET_IS_EXPANDED,
      payload: {isExpanded}
    };
  }
};
