/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/


import React, {PureComponent} from 'react';

// defaults previously in use by shell
export const EXTRA_LARGE_MAX_WIDTH = 1450;
export const LARGE_MAX_WIDTH = 1250;
export const MEDIUM_MAX_WIDTH = 900;
export const SMALL_MAX_WIDTH = 650;

export default (WrappedComponent, props) => {
  class WindowSizeAware extends PureComponent {
    state = {
      windowSize: 'L',
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight
    };

    resizeTimeout = null;
    onResize = () => {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(()=>{
        const innerWidth = window.innerWidth;
        const innerHeight = window.innerHeight;
        let windowSize = this.state.windowSize;
        if (innerWidth > this.extraLargeMaxWidth) {
          windowSize = 'XXL';
        } else if (innerWidth > this.largeMaxWidth) {
          windowSize = 'XL';
        } else if (innerWidth > this.mediumMaxWidth) {
          windowSize = 'L';
        } else if (innerWidth <= this.smallMaxWidth) {
          windowSize = 'S';
        }

        if (
          windowSize !== this.state.windowSize ||
          innerWidth !== this.state.innerWidth ||
          innerHeight !== this.state.innerHeight
        ) {
          this.setState({ windowSize, innerWidth, innerHeight });
        }
      }, props?.resizeDelay || 50);
    };

    componentDidMount = () => {
      this.extraLargeMaxWidth = props && props.extraLargeMaxWidth || EXTRA_LARGE_MAX_WIDTH;
      this.largeMaxWidth = props && props.largeMaxWidth || LARGE_MAX_WIDTH;
      this.mediumMaxWidth = props && props.mediumMaxWidth || MEDIUM_MAX_WIDTH;
      this.smallMaxWidth = props && props.smallMaxWidth || SMALL_MAX_WIDTH;
      this.onResize();
      window.addEventListener('resize', this.onResize);
    };
    componentWillUnmount() {
      window.removeEventListener('resize', this.onResize);
      clearTimeout(this.resizeTimeout);
    }

    render = () => {
      const props = {
        ...this.state,
        windowWidth: this.state.innerWidth,
        windowHeight: this.state.innerHeight,
        ...this.props
      };
      return <WrappedComponent {...props} />;
    };
  };

  return WindowSizeAware;
};
