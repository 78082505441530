/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/


// This is a generic key/value store that can be used from anywhere in the app
// for values that cannot be put on redux because they are not serializable.
// A few examples are below...
// - GlobalRenderers - Toasts register their associated renderer function
// - ControlledListActions - When a component is wrapped with ControlledListActions
//                           it's actual redux actions will be registered using it's
//                           key so that other components will be able to use them.

let keyValueStore = {};

const GlobalKeyValueStore = {
  get(id) {
    return keyValueStore[id];
  },
  set(id, value) {
    keyValueStore[id] = value;
    return id;
  },
  remove(id) {
    delete keyValueStore[id];
  }
};

export default GlobalKeyValueStore;
