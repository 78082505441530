/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import Immutable from 'seamless-immutable';
import {actionCreators as apiActions} from '../../../utils/api/apiActions';
import actionsHandler from '../../../redux/actionsHandler';
import {actionCreators as extensionCacheBustActions} from '../extensions/extensionCacheBustActions';
import {getActionCreators as getPublishingActions} from '../publishing/publishingActions';
import {areExtensionsUsedByResourceInstalled} from '../../delegate/delegateHelpers';
import {STATE_KEY as EXTENSION_CACHE_STATE_KEY} from '../extensions/extensionCacheBustActions';
import {
  getRevisionRange,
  getRevisionRangeParameterName
} from '../../compareView/compareViewUtils';
import {DATA_ELEMENTS} from '../../../utils/api/apiTypes';

import {push, replace} from '../../../routes/namedRouteUtils';
import {getApiData} from '../../../utils/api/apiTools';
import {change} from 'redux-form';


export const FORM_NAME = 'dataElementEditForm';
export const STATE_KEY = 'dataElementEdit';
export const DATA_ELEMENT_REVISIONS_STATEKEY = 'dataElementEditRevisions';
export const SET_IS_DATA_ELEMENT_VALIDATING = 'dataElementEdit/SET_IS_DATA_ELEMENT_VALIDATING';


export const publishingActions = getPublishingActions(STATE_KEY);

export const STORAGE_DURATION_NONE = 'none';

export const durationTypeOptions = [{
  name: 'None',
  value: STORAGE_DURATION_NONE
}, {
  name: 'Pageview',
  value: 'pageview'
}, {
  name: 'Session',
  value: 'session'
}, {
  name: 'Visitor',
  value: 'visitor'
}];

let abortController;

//Reducers
export default actionsHandler({
  [SET_IS_DATA_ELEMENT_VALIDATING](state, action) {
    return state.set('isDataElementValidating', action.payload);
  },
  default(state = Immutable({isDataElementValidating: false})) {
    return state;
  }
});

//Action Creators
export let actionCreators = {
  goToDataElementRevision(params, revisionId) {
    return (dispatch)=>{
      dispatch(push({
        name: 'editDataElement',
        params: { ...params, dataElement: revisionId }
      }));
    };
  },
  goToComponentCompare({
    params,
    revisionRange,
    shouldReplaceRoute = false
  }) {
    return (dispatch, getState)=>{
      dispatch((shouldReplaceRoute ? replace : push)({
        name: 'editDataElementCompare',
        params: {
          ...params,
          [getRevisionRangeParameterName(DATA_ELEMENTS)]: revisionRange ? revisionRange : getRevisionRange({
            rightRevisionId: getApiData(getState(), STATE_KEY).dataElement.id
          })
        }
      }));
    };
  },
  checkAndLoadUninstalledExtension(dataElement, params) {
    return (dispatch, getState) => {
      const currentState = getState();
      const extensions = getApiData(currentState, EXTENSION_CACHE_STATE_KEY).extensions;
      const extensionUsedByDelegateIsInstalled = areExtensionsUsedByResourceInstalled(
        [dataElement],
        extensions
      );

      return (extensionUsedByDelegateIsInstalled ? Promise.resolve() : dispatch(apiActions.apiAction({
        name: 'getExtension',
        stateKey: STATE_KEY,
        urlData: {
          ...params,
          extension: dataElement.relationships.extension.data.id
        },
        urlParams: {
          include: 'extension_package'
        },
        abortSignal: abortController.signal
      })));
    };
  },
  // dataElement is optional
  // if not provided dataElement will be requested.
  initializeDataElement({params}) {
    return async(dispatch, getState) => {
      abortController = new AbortController();

      dispatch(actionCreators.setIsDataElementValidating(false));
      await dispatch(extensionCacheBustActions.checkAndRefreshCache(params));

      if (params.dataElement !== 'new') {
        await dispatch(apiActions.apiAction({
          name: 'getDataElement',
          stateKey: STATE_KEY,
          urlData: {...params},
          abortSignal: abortController.signal
        }));
        const dataElement = getApiData(getState(), STATE_KEY).dataElement;
        await dispatch(actionCreators.checkAndLoadUninstalledExtension(dataElement, params));
      }

      // make the fetched dataElement available
      return getApiData(getState(), STATE_KEY).dataElement;
    };
  },
  tearDownDataElement() {
    return (dispatch) => {
      abortController?.abort();

      dispatch(apiActions.resetEndpoint(
        'getDataElement',
        STATE_KEY
      ));
      dispatch(apiActions.resetData([
        'extension',
        'extensionPackage',
        'delegateDescriptors'
      ], STATE_KEY));
      dispatch(apiActions.resetEndpoint(
        'getRevisions',
        DATA_ELEMENT_REVISIONS_STATEKEY
      ));
    };
  },
  goToDataElements(params) {
    return (dispatch) => {
      dispatch(push({ name: 'dataElements', params }));
    };
  },
  setIsDataElementValidating(isValidating) {
    return {
      type: SET_IS_DATA_ELEMENT_VALIDATING,
      payload: isValidating
    };
  },
  saveDataElement({
    newValues,
    params,
    buildOnSave = false,
    selectedDevLibrary,
    saveToLibrary = false
  }) {
    return (dispatch, getState)=> {
      let isNew = !newValues.id;

      return dispatch(apiActions.apiAction({
        name: (isNew ? 'create' : 'update') + 'DataElement',
        stateKey: STATE_KEY,
        urlData: {
          ...params,
          dataElement: !isNew ? newValues.id : undefined
        },
        data: { data: {
          ...newValues,
          type: 'data_elements',
        }}
      }))
      .then(()=>{
        const dataElementEditApiData = getApiData(getState(), STATE_KEY);
        return dataElementEditApiData.dataElement;
      })
      .then((savedDataElement)=>{
        if (saveToLibrary) {
          return dispatch(publishingActions.saveLatestDelegatesToLibrary(
            {...params, dataElement: savedDataElement.id, library: selectedDevLibrary.id},
            [savedDataElement],
            selectedDevLibrary
          ));
        }
        if (buildOnSave) {
          return dispatch(publishingActions.saveLatestDelegatesToLibraryAndBuild(
            {...params, dataElement: savedDataElement.id, library: selectedDevLibrary.id},
            [savedDataElement],
            selectedDevLibrary
          ));
        }
      });
    };
  },
  setDataElementToRevision(dataElementRevision, params) {
    return (dispatch)=>{
      dispatch(actionCreators.checkAndLoadUninstalledExtension(dataElementRevision, params)).then(()=>{
        dispatch(change(FORM_NAME, 'attributes.settings', dataElementRevision.attributes.settings));
        dispatch(change(FORM_NAME, 'relationships.extension.data.id', dataElementRevision.relationships.extension.data.id));
        dispatch(change(FORM_NAME, 'attributes.delegateDescriptorId', dataElementRevision.attributes.delegateDescriptorId));
        dispatch(change(FORM_NAME, 'attributes.name', dataElementRevision.attributes.name));
        dispatch(change(FORM_NAME, 'attributes.defaultValue', dataElementRevision.attributes.defaultValue));
        dispatch(change(FORM_NAME, 'attributes.forceLowerCase', dataElementRevision.attributes.forceLowerCase));
        dispatch(change(FORM_NAME, 'attributes.cleanText', dataElementRevision.attributes.cleanText));
        dispatch(change(FORM_NAME, 'attributes.storageDuration', dataElementRevision.attributes.storageDuration || STORAGE_DURATION_NONE));
      });
    };
  },
  loadDataElementRevisions(params) {
    return (dispatch)=>{
      return dispatch(apiActions.apiAction({
        name: 'getRevisions',
        urlData: {
          ...params,
          resource: params.dataElement,
          resourceType: 'data_elements'
        },
        stateKey: DATA_ELEMENT_REVISIONS_STATEKEY,
        abortSignal: abortController.signal
      }));
    };
  }
};
