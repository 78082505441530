/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import actionsHandler from '../../redux/actionsHandler';
import {actionCreators as apiActions} from '../../utils/api/apiActions';
import Immutable from 'seamless-immutable';
import {querySerializer} from '../../utils/sortFilterQueryParamsUtils';
import {
  getCurrentRouteParamsFromState,
  getCurrentLocationFromState
} from '../../routes/routeSelectors';
import {getPaginationFromState, getPaginationQuery} from './paginationSelectors';
import {getSearchRequestBodyDataFromState} from '../search/searchSelectors';
import {getApiMappingName} from '../../utils/api/apiTools';
import queryString from 'query-string';
import { replace } from 'connected-react-router';
import {setPaginationPageSizeInLocalStorage} from './paginationUtils.js';
import {escapeCommas} from '../../utils/dataUtils';
import camelize from 'camelize';
import {actionCreators as searchActions} from '../search/searchActions';
import { browserHistory } from '../../utils/browserHistoryUtils';

export const SET_PAGE_DATA = 'pagination/SET_PAGE_DATA';
export const SET_QUERY_AND_PENDING_QUERY = 'pagination/SET_QUERY_AND_PENDING_QUERY';
export const SET_PAGE_SIZE = 'pagination/SET_PAGE_SIZE';
export const SET_URL_DATA = 'pagination/SET_URL_DATA';
export const SET_INITIALIZED = 'pagination/SET_INITIALIZED';
export const SET_ENDPOINT_GROUP_KEY = 'pagination/SET_ENDPOINT_GROUP_KEY';
export const SET_SHOULD_MERGE_API_RESULTS = 'pagination/SET_SHOULD_MERGE_API_RESULTS';
export const PERSIST_PENDING_QUERY = 'pagination/PERSIST_PENDING_QUERY';
export const SET_PENDING_QUERY_PAGE = 'pagination/SET_PENDING_QUERY_PAGE';
export const SET_USE_HISTORY = 'pagination/SET_USE_HISTORY';
export const INCREMENT_PENDING_QUERY_PAGE = 'pagination/INCREMENT_PENDING_QUERY_PAGE';
export const DECREMENT_PENDING_QUERY_PAGE = 'pagination/DECREMENT_PENDING_QUERY_PAGE';
export const CLEAR_PENDING_QUERY_FILTER = 'pagination/CLEAR_PENDING_QUERY_FILTER';
export const SET_PENDING_QUERY_SORT = 'pagination/SET_PENDING_QUERY_SORT';
export const SET_PENDING_QUERY_FILTER = 'pagination/SET_PENDING_QUERY_FILTER';
export const TOGGLE_PENDING_QUERY_FILTER = 'pagination/TOGGLE_PENDING_QUERY_FILTER';
export const ON_VIEW_UNMOUNTED = 'pagination/ON_VIEW_UNMOUNTED';
export const SET_INITIAL_LOAD_COMPLETED = 'pagination/SET_INITIAL_LOAD_COMPLETED';
export const LOAD = 'pagination/LOAD';
export const CLEAN_UP = 'pagination/CLEAN_UP';

function removePropertiesHoldingEmptyObjects(obj) {
  // whenever we find an empty object, we want to exclude it
  if (typeof obj === 'object' && !Object.keys(obj).length) {
    return undefined;
  }

  Object.keys(obj).forEach(key => {
    if (obj.hasOwnProperty(key)) {
      let oldValue = obj[key];
      // we don't care to remove anything but empty objects
      if (typeof oldValue === 'object') {
        const newValue = removePropertiesHoldingEmptyObjects(oldValue);
        // we got back a flag to exclude, or by excluding keys the object became empty. nuke the key.
        if (newValue == null || !Object.keys(newValue).length) {
          obj = obj.without(key);
        } else {
          // use the returned result as the new result for the current recursion frame
          obj = obj.set(key, newValue);
        }
      }
    }
  });

  return obj;
}

//Reducers
export default actionsHandler({
  [SET_PAGE_DATA]: (state, action)=> {
    if (!action.payload.stateKey) {
      if (window.environmentSettings.lens_debug) {
        console.warn('The specified action is missing a stateKey and will not get pagination setup', action);
      }
      return state;
    }

    // if translateCase is false, we need to ensure this is camelized before destructuring
    // this may be set on the controlledList.initialize or manually anywhere apiActions.apiAction is called
    let {currentPage, totalCount, totalPages} = camelize(action.payload.pageMeta);

    return state.update(
      action.payload.stateKey,
      (group)=> {
        const groupData = {
          pageItems: action.payload.listItems,
          currentPage,
          totalCount,
          totalPages,
          pageSize: action.payload.pageSize
        };
        return group ? group.merge(groupData) : groupData;
      }
    );
  },
  [SET_QUERY_AND_PENDING_QUERY]: (state, {payload:{stateKey, query}})=> {
    const newQuery = query;
    return state
      .setIn([stateKey, 'query'], newQuery)
      .setIn([stateKey, 'pendingQuery'], newQuery);
  },
  [SET_PAGE_SIZE]: (state, {payload:{stateKey, pageSize}}) => {
    return state.setIn([stateKey, 'pageSize'], pageSize);
  },
  [SET_URL_DATA]: (state, {payload:{stateKey, urlData}}) => {
    return state.setIn([stateKey, 'urlData'], urlData);
  },
  [SET_ENDPOINT_GROUP_KEY]: (state, {payload:{stateKey, endpointGroupKey}}) => {
    return state.setIn([stateKey, 'endpointGroupKey'], endpointGroupKey);
  },
  [SET_INITIALIZED]: (state, {payload:{stateKey, isInitialized}}) => {
    return state.setIn([stateKey, 'isInitialized'], isInitialized);
  },
  [SET_SHOULD_MERGE_API_RESULTS]: (state, {payload:{stateKey, shouldMergeResults}}) => {
    return state.setIn([stateKey, 'shouldMergeResults'], shouldMergeResults);
  },
  [PERSIST_PENDING_QUERY]: (state, {payload:{stateKey}})=> {
    const pendingQuery = state.getIn([stateKey, 'pendingQuery']) || Immutable({});
    return state.setIn([stateKey, 'query'], pendingQuery);
  },
  [SET_PENDING_QUERY_PAGE]: (state, {payload:{stateKey, pageNumber}})=> {
    return state.setIn([stateKey, 'pendingQuery', 'page', 'number'], pageNumber);
  },
  [SET_USE_HISTORY]: (state, {payload:{stateKey, useHistory}})=> {
    return state.setIn([stateKey, 'useHistory'], useHistory);
  },
  [ON_VIEW_UNMOUNTED]: (state, {payload:{stateKey}}) => {
    return state.setIn([stateKey, 'isInitialized'], false);
  },
  [INCREMENT_PENDING_QUERY_PAGE]: (state, {payload:{stateKey}})=> {
    const {currentPage, totalPages} = state[stateKey];
    let targetPage = currentPage;

    if (currentPage < totalPages) {
      targetPage++;
    }

    return state.setIn([stateKey, 'pendingQuery', 'page', 'number'], targetPage);
  },
  [DECREMENT_PENDING_QUERY_PAGE]: (state, {payload:{stateKey}})=> {
    const {currentPage} = state[stateKey];
    let targetPage = currentPage;

    if (currentPage > 1) {
      targetPage--;
    }

    return state.setIn([stateKey, 'pendingQuery', 'page', 'number'], targetPage);
  },
  [CLEAR_PENDING_QUERY_FILTER]: (state, {payload:{stateKey, filterBy}})=> {
    return state.updateIn([stateKey, 'pendingQuery'], (pendingQuery) => {
      if (filterBy) {
        if (pendingQuery.filter) {
          return pendingQuery.update('filter', (filter) => {
            return filter.without(filterBy);
          });
        } else {
          return pendingQuery;
        }
      } else {
        return pendingQuery.without('filter');
      }
    });
  },
  [SET_PENDING_QUERY_SORT]: (state, {payload:{stateKey, sortBy}})=> {
    const currentSortQualifier = state.getIn(
      [stateKey, 'pendingQuery', 'sort', sortBy, 'qualifier']
    );
    // right now this only supports sorting on a single column
    return state.setIn([stateKey, 'pendingQuery', 'sort'], {
      [sortBy]: {
        'qualifier': currentSortQualifier === 'ascending' ? 'descending' : 'ascending'
      }
    });
  },
  [SET_PENDING_QUERY_FILTER]: (state, {payload:{stateKey, filterBy, filterValue, qualifier}})=> {
    return state.updateIn([stateKey, 'pendingQuery'], (query)=>{
      return query.update('filter', (filter = Immutable({})) => {
        return filter
          .without(filterBy)
          .setIn(
            [filterBy, escapeCommas(filterValue), 'qualifiers'],
            {[qualifier]: true}
          );
      });
    });
  },
  [TOGGLE_PENDING_QUERY_FILTER]: (state, {payload:{stateKey, filterBy, filterValue, qualifier}})=> {
    return state.updateIn([stateKey, 'pendingQuery'], (query)=>{
      const currentFilterQualifier = query.getIn(
        ['filter', filterBy, filterValue, 'qualifiers', qualifier]
      );
      const newQuery = (
        query.updateIn(['filter', filterBy, filterValue, 'qualifiers'],
          (qualifiers = Immutable({})) => {
            return currentFilterQualifier ?
              qualifiers.without(qualifier) :
              qualifiers.set(qualifier, true);
          }
        )
      );

      return removePropertiesHoldingEmptyObjects(newQuery);
    });
  },
  [SET_INITIAL_LOAD_COMPLETED]: (state, {payload:{stateKey, initialLoadCompleted}})=> {
    return state.setIn([stateKey, 'initialLoadCompleted'], initialLoadCompleted);
  },
  [CLEAN_UP]: (state, {payload:{stateKey}})=> {
    return state.without(stateKey);
  },
  default:(state = Immutable({}))=> {
    return state;
  }
});


export const DEFAULT_PAGINATION_QUERY = {
  page: { number: 1 }
};

//Action Creators creator
export let getActionCreators = (stateKey) => {
  /**
   * Initialize pagination actions for calling to the API.
   * @param {number} pageSize - The amount of results to call for
   * @param {boolean} useHistory - Store into browser history the query param changes as we call for pages
   * @param {string[]} editViewRouteNames - The names for which to restore browser query params when useHistory is true
   * @param {number} queryPage - The page to start from
   * @param {object} forcedParams - The param data to force on the query
   * @param {boolean} shouldMergeResults - Whether to keep a running list of results
   * @param {string} endpointGroupKey - The endpoint to call, if different than stateKey
   * @param {object} searchBody - Body Data that will be on all search api calls it changes to manually use the search endpoint
   */
  function initialize({
    pageSize,
    useHistory = true,
    editViewRouteNames,
    queryPage,
    forcedParams,
    shouldMergeResults,
    endpointGroupKey,
    searchBody
  } = {}) {
    return (dispatch, getState) => {
      const query = getPaginationQuery(
        stateKey, editViewRouteNames, useHistory, getState()
      );

      dispatch(cleanUpPagination());
      dispatch(setQueryAndPendingQuery(query));
      searchBody && dispatch(searchActions.setSearchRequestBodyData(searchBody, stateKey));

      const computedQueryPage = queryPage != null ? queryPage : query?.page?.number;
      if (Number(computedQueryPage) >= 1) {
        dispatch(setPendingQueryPage(computedQueryPage));
      }

      dispatch(setUseHistory(useHistory));
      dispatch(setPageSize(pageSize));
      dispatch(setUrlData(
        (forcedParams || getCurrentRouteParamsFromState(getState()))
      ));
      dispatch(setShouldMergeAPIResults(Boolean(shouldMergeResults)));

      dispatch(setEndpointGroupKey(endpointGroupKey || stateKey));

      dispatch(setInitialized(true));
    };
  }
  function setQueryAndPendingQuery(query) {
    return {
      type: SET_QUERY_AND_PENDING_QUERY,
      payload: {
        stateKey,
        query
      }
    };
  }
  function setPageSize(pageSize) {
    return dispatch => {
      setPaginationPageSizeInLocalStorage(stateKey, pageSize);

      dispatch({
        type: SET_PAGE_SIZE,
        payload: {
          pageSize,
          stateKey
        }
      });
    };
  }
  function setUrlData(urlData) {
    return {
      type: SET_URL_DATA,
      payload: {
        stateKey,
        urlData
      }
    };
  }
  function setInitialized(isInitialized) {
    return {
      type: SET_INITIALIZED,
      payload: {
        stateKey,
        isInitialized
      }
    };
  }
  function setEndpointGroupKey(endpointGroupKey) {
    return {
      type: SET_ENDPOINT_GROUP_KEY,
      payload: {
        stateKey,
        endpointGroupKey
      }
    };
  }
  function setShouldMergeAPIResults(shouldMergeResults) {
    return {
      type: SET_SHOULD_MERGE_API_RESULTS,
      payload: {
        stateKey,
        shouldMergeResults
      }
    };
  }
  function persistPendingQuery() {
    return {
      type: PERSIST_PENDING_QUERY,
      payload: { stateKey }
    };
  }
  function setPendingQueryPage(pageNumber) {
    return {
      type: SET_PENDING_QUERY_PAGE,
      payload: {
        stateKey,
        pageNumber
      }
    };
  }

  function setUseHistory(useHistory) {
    return {
      type: SET_USE_HISTORY,
      payload: {
        stateKey,
        useHistory
      }
    };
  }

  function incrementPendingQueryPage() {
    return {
      type: INCREMENT_PENDING_QUERY_PAGE,
      payload: {
        stateKey
      }
    };
  }
  function decrementPendingQueryPage() {
    return {
      type: DECREMENT_PENDING_QUERY_PAGE,
      payload: {
        stateKey
      }
    };
  }

  function clearPendingQueryFilter(filterBy) {
    return {
      type: CLEAR_PENDING_QUERY_FILTER,
      payload: {
        stateKey,
        filterBy
      }
    };
  }

  function setPendingQuerySort(sortBy) {
    return {
      type: SET_PENDING_QUERY_SORT,
      payload: {
        stateKey,
        sortBy
      }
    };
  }

  function setPendingQueryFilter(filterBy, filterValue, qualifier) {
    return {
      type: SET_PENDING_QUERY_FILTER,
      payload: {
        stateKey,
        filterBy,
        filterValue,
        qualifier
      }
    };
  }

  function togglePendingQueryFilter(filterBy, filterValue, qualifier) {
    return {
      type: TOGGLE_PENDING_QUERY_FILTER,
      payload: {
        stateKey,
        filterBy,
        filterValue,
        qualifier
      }
    };
  }

  function setInitialLoadCompleted(initialLoadCompleted) {
    return {
      type: SET_INITIAL_LOAD_COMPLETED,
      payload: { stateKey, initialLoadCompleted }
    };
  }

  function onViewUnmounted() {
    // Generally speaking we will leave the last pagination data hanging around until
    // a reset is requested. This allows us to restore the last query in special cases.
    return {
      type: ON_VIEW_UNMOUNTED,
      payload: {
        stateKey
      }
    };
  }

  function resetData() {
    return (dispatch, getState) => {
      const { endpointGroupKey } = getPaginationFromState(stateKey, getState());
      dispatch(apiActions.resetEndpoint(
        getApiMappingName(endpointGroupKey, 'get', true),
        stateKey
      ));
    };
  }

  // use when you know you won't have to restore data
  function cleanUpPagination({
    forceResetData = false
  } = {}) {
    return (dispatch, getState) => {
      let promises = [];

      const pagination = getPaginationFromState(stateKey, getState());
      if (pagination?.shouldMergeResults || forceResetData) {
        promises.push(dispatch(resetData()));
      }

      promises.push(dispatch({
        type: CLEAN_UP,
        payload: {
          stateKey
        }
      }));

      return promises;
    };
  }

  function buildUrlForNavigation(pendingQuery, state) {
    const otherQueryParams = queryString.pick(
      browserHistory.location.search,
      window.environmentSettings.lens_configUrlParamKeys
    );
    const stringifiedQuery = queryString.stringify({
      ...querySerializer(pendingQuery),
      ...queryString.parse(otherQueryParams, {parseBooleans: true}),
    });
    const pathname = getCurrentLocationFromState(state)?.pathname;
    return pathname + '?' + stringifiedQuery;
  }

  function loadPaginationPage({
    forcedQuery,
    bypassError,
    abortSignal,
    translateCase
  }) {
    return (dispatch, getState) => {
      const {
        pageSize,
        pendingQuery,
        urlData,
        shouldMergeResults,
        endpointGroupKey,
        useHistory
      } = getPaginationFromState(stateKey, getState());

      const searchBody = getSearchRequestBodyDataFromState(stateKey, getState());

      if (searchBody) {
        return dispatch(loadPaginationPageFromSearch({
          bypassError,
          abortSignal,
          translateCase,
          searchBody
        }));
      }

      const serializedParams = querySerializer(
        (pendingQuery || Immutable({}))
          .merge([
            {page: {size: pageSize}},
            forcedQuery
          ], { deep: true })
      );
      if (useHistory) {
        dispatch(replace(buildUrlForNavigation(pendingQuery, getState())));
      }
      dispatch(setQueryAndPendingQuery(pendingQuery));
      return dispatch(apiActions.apiAction({
        name: getApiMappingName(endpointGroupKey, 'get', true),
        urlParams: serializedParams,
        urlData,
        stateKey,
        pageSize,
        shouldMergeResults,
        bypassError,
        abortSignal,
        translateCase,
        updatePaginationState: true
      })).then((result)=>{
        const state = getState();
        if (!state.pagination[stateKey].initialLoadCompleted) {
          dispatch(setInitialLoadCompleted(true));
        }
        return result;
      });
    };
  }

  function loadPaginationPageFromSearch({
    bypassError,
    abortSignal,
    translateCase,
    searchBody
  }) {
    return (dispatch, getState) => {
      const {
        pageSize,
        pendingQuery,
        urlData,
        shouldMergeResults,
        useHistory
      } = getPaginationFromState(stateKey, getState());

      const query = (pendingQuery || Immutable({})).merge([
        {
          data: {
            from: pageSize * (pendingQuery?.page?.number - 1 || 0),
            size: pageSize,
          }
        },
        {data:searchBody}
      ], { deep: true });


      if (useHistory) {
        dispatch(replace(buildUrlForNavigation(pendingQuery, getState())));
      }
      dispatch(setQueryAndPendingQuery(pendingQuery));
      return dispatch(apiActions.apiAction({
        name: 'elasticSearch',
        data: JSON.parse(JSON.stringify(query)),
        mappingLocation:stateKey,
        urlData,
        stateKey,
        pageSize,
        shouldMergeResults,
        bypassError,
        abortSignal,
        translateCase,
        updatePaginationState: true
      })).then((result)=>{
        const state = getState();
        if (!state.pagination[stateKey].initialLoadCompleted) {
          dispatch(setInitialLoadCompleted(true));
        }
        return result;
      });
    };
  }

  return {
    initialize,
    setPageSize,
    setQueryAndPendingQuery,
    persistPendingQuery,
    setPendingQueryPage,
    incrementPendingQueryPage,
    decrementPendingQueryPage,
    clearPendingQueryFilter,
    setPendingQuerySort,
    setPendingQueryFilter,
    togglePendingQueryFilter,
    setInitialLoadCompleted,
    resetData,
    cleanUpPagination,
    loadPaginationPage,
    loadPaginationPageFromSearch,
    setUrlData,
    onViewUnmounted
  };
};
