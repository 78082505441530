/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import React, {Component} from 'react';
import LinkedBreadcrumb from '../../LinkedBreadcrumb/LinkedBreadcrumb';
import Wait from '@react/react-spectrum/Wait';
import PropertyCopyStatus from '../../properties/propertyCopy/PropertyCopyStatus';

export class ContentLayout extends Component {
  render = () => {
    const {
      loading,
      breadcrumb,
      children,
      className,
      contentPadding = true,
      displayCapabilityBreadCrumb = false,
      displayCompanyBreadcrumb = false,
      displayPropertyCopy = true
    } = this.props;

    return (
      <div
        className={
          'contentLayout u-flex u-flexColumn u-flexOne' +
          (className ? ' ' + className : '')
        }
      >
        <div className="u-flex">
          {breadcrumb || displayCapabilityBreadCrumb ? (
            <div className="u-padding u-flexOne">
              <LinkedBreadcrumb
                displayCompany={displayCompanyBreadcrumb}
                displayCapability={displayCapabilityBreadCrumb}
                items={breadcrumb}
              />
            </div>
          ) : null}

          {displayPropertyCopy ? <div className="u-padding"><PropertyCopyStatus /></div> : null}
        </div>
        <div
          className={
            'u-flexOne u-flex u-flexColumn u-overflowYAuto' +
            (contentPadding ? ' u-padding' : '')
          }
        >
          {loading ? <Wait centered size="L"/> : children}
        </div>
      </div>
    );
  };
};

export default ContentLayout;
