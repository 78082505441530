/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2021 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import Immutable from 'seamless-immutable';
import {actionCreators as apiActions} from '../../../utils/api/apiActions';
import {push} from '../../../routes/namedRouteUtils';
import {omit} from 'lodash-es';
import {isPollingSecretStatusFromState} from './secretsSelectors';
import {actionCreators as asyncPollActions} from '../../../utils/asyncPollActions';
import actionsHandler from '../../../redux/actionsHandler';
import { SECRET_TYPE_OAUTH2 } from '../../../utils/secretsUtils';

export const SECRET_EDIT_STATE_KEY = 'secretEdit';
export const SECRET_CREATE_STATE_KEY = 'secretCreate';
export const SECRETS_PENDING_STATE_KEY = 'secretsPending';
export const SET_IS_GENERATING_TEST_TOKEN = 'secretsEdit/SET_IS_GENERATING_TEST_TOKEN';
export const SET_POST_SAVE_AUTHORIZATION_NEEDED = 'secretsEdit/SET_POST_SAVE_AUTHORIZATION_NEEDED';

let getSecretAbortController = new AbortController();
const secretStatusPollingActions = asyncPollActions(SECRET_EDIT_STATE_KEY);

const defaultState = Immutable({
  isGeneratingTestToken: false
});

export default actionsHandler({
  [SET_IS_GENERATING_TEST_TOKEN](state, { payload: { isGeneratingTestToken }}) {
    return state.set('isGeneratingTestToken', isGeneratingTestToken);
  },
  [SET_POST_SAVE_AUTHORIZATION_NEEDED](state, {payload: { postSaveAuthorizationNeeded }}) {
    return state.set('postSaveAuthorizationNeeded', postSaveAuthorizationNeeded);
  },
  default: (state = defaultState) => {
    return state;
  }
});

export let actionCreators = {
  setIsGeneratingTestToken(isGeneratingTestToken) {
    return dispatch => {
      dispatch({
        type: SET_IS_GENERATING_TEST_TOKEN,
        payload: {
          isGeneratingTestToken
        }
      });
    };
  },
  setPostSaveAuthorizationNeeded(postSaveAuthorizationNeeded) {
    return {
      type: SET_POST_SAVE_AUTHORIZATION_NEEDED,
      payload: {
        postSaveAuthorizationNeeded
      }
    };
  },
  loadAllPendingSecrets({
    params,
    urlParams = {},
    stateKey = SECRETS_PENDING_STATE_KEY,
    abortSignal
  }) {
    return (dispatch) => {
      return dispatch(apiActions.apiAction({
        name: 'getSecrets',
        urlData: { ...params },
        urlParams: {
          'sort': '-updated_at',
          ...urlParams,
          'filter[status]': 'EQ pending'
        },
        loadAllPages: true,
        stateKey,
        abortSignal
      }));
    };
  },
  loadSecret(params, abortSignal) {
    return apiActions.apiAction({
      name: 'getSecret',
      urlData: {...params},
      stateKey: SECRET_EDIT_STATE_KEY,
      abortSignal
    });
  },
  createSecret(params, data) {
    return apiActions.apiAction({
      name: 'createSecret',
      urlData: {...params},
      data,
      stateKey: `${SECRET_CREATE_STATE_KEY}`
    });
  },
  updateSecret(params, data) {
    return apiActions.apiAction({
      name: 'updateSecret',
      urlData: {...params},
      data,
      stateKey: SECRET_EDIT_STATE_KEY
    });
  },
  retrySecret(params, secret) {
    return (dispatch => {
      let endpointData = {
        name: 'updateSecret',
        urlData: {
          ...omit(params, ['secret']),
          secret: secret.id
        },
        data: {
          data: {
            id: secret.id,
            type: 'secrets',
            attributes: {
              // always pull from the loaded secret, not the form value because if the user changes the "typeOf" on
              // the form, we should disable the refresh because it's unclear what you're refreshing. Therefore, we
              // only refresh when the secret's typeOf matches the typeOf on the form.
              typeOf: secret.attributes?.typeOf
            },
            meta: {
              action: 'retry'
            }
          }
        },
        stateKey: SECRET_EDIT_STATE_KEY
      };
      if (secret.relationships?.environment?.data?.id) {
        endpointData.data.data.relationships = {
          environment: secret.relationships.environment
        };
      }

      return dispatch(apiActions.apiAction(endpointData));
    });
  },
  reauthorizeSecret(params, secret) {
    return (dispatch => {
      let endpointData = {
        name: 'updateSecret',
        urlData: {
          ...omit(params, ['secret']),
          secret: secret.id
        },
        data: {
          data: {
            id: secret.id,
            type: 'secrets',
            attributes: {
              // always pull from the loaded secret, not the form value because if the user changes the "typeOf" on
              // the form, we should disable the refresh because it's unclear what you're refreshing. Therefore, we
              // only refresh when the secret's typeOf matches the typeOf on the form.
              typeOf: secret.attributes?.typeOf
            },
            meta: {
              action: 'reauthorize'
            }
          }
        },
        stateKey: SECRET_EDIT_STATE_KEY
      };
      if (secret.relationships?.environment?.data?.id) {
        endpointData.data.data.relationships = {
          environment: secret.relationships.environment
        };
      }

      return dispatch(apiActions.apiAction(endpointData));
    });
  },
  generateTestToken(params, secret) {
    return async(dispatch) => {
      if (secret.attributes?.typeOf === SECRET_TYPE_OAUTH2) {
        const endpointData = {
          name: 'updateSecret',
          urlData: {
            ...omit(params, ['secret']),
            secret: secret.id
          },
          data: {
            data: {
              id: secret.id,
              type: 'secrets',
              attributes: {
                typeOf: SECRET_TYPE_OAUTH2
              },
              meta: {
                action: 'test'
              }
            }
          },
          stateKey: SECRET_EDIT_STATE_KEY
        };

        dispatch(actionCreators.setIsGeneratingTestToken(true));
        await dispatch(apiActions.apiAction(endpointData));
        dispatch(actionCreators.setIsGeneratingTestToken(false));
      } else {
        return Promise.reject();
      }
    };
  },
  goToSecrets(params) {
    return push({ name: 'secrets', params });
  },
  startSecretStatusPolling(params = {}) {
    return async(dispatch, getState) => {
      if (!isPollingSecretStatusFromState(getState())) {
        getSecretAbortController.abort();
        getSecretAbortController = new AbortController();

        dispatch(secretStatusPollingActions.startPolling(async() => {
          await dispatch(actionCreators.loadSecret(
            params,
            getSecretAbortController?.signal
          ));

          const secret = getState().api?.[SECRET_EDIT_STATE_KEY]?.secret;
          if (secret?.attributes?.status !== 'pending') {
            dispatch(secretStatusPollingActions.stopPolling());
            getSecretAbortController.abort();
          } else if (!isPollingSecretStatusFromState(getState())) {
            return dispatch(actionCreators.startSecretStatusPolling(params));
          }
        }));
      }
    };
  },
  stopSecretsPolling() {
    return (dispatch, getState) => {
      if (isPollingSecretStatusFromState(getState())) {
        dispatch(secretStatusPollingActions.stopPolling());
      }
      getSecretAbortController.abort();
    };
  }
};
