/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/
import {getResourceOriginId} from '../../utils/resourceUtils';
import {COMPARE_BASE_RESOURCE_STATE_KEY, initialState} from './componentCompareView/componentCompareActions';
import { getCurrentRouteParamsFromState } from '../../routes/routeSelectors';
import { RULES,DATA_ELEMENTS,  EXTENSIONS } from '../../utils/api/apiTypes';
import { getRevisionRangeFromParams } from './compareViewUtils';
import { getRevisionIdsFromRevisionRange } from './compareViewUtils';
import { getApiData } from '../../utils/api/apiTools';
import { getSingularResourceTypeFromId } from '../../utils/resourceUtils';


export function getRuleCompareViewData(state) {
  return state.ruleCompareView;
}

export function getComponentCompareOrDefaultData(state, stateKey) {
  return state.componentCompareView[stateKey] || initialState;
}

export function getResourceByOriginIdAndStateKey(state, originId, stateKey) {
  const ruleComponents = getRuleCompareViewData(state)[stateKey].ruleComponents;
  return getResourceFromRuleComponentsByOrigin(ruleComponents, originId);
}

export function getResourceFromRuleComponentsByOrigin(ruleComponents, originId) {
  return Object.keys(ruleComponents).reduce((accumulator, componentType)=>{
    if (accumulator) { return accumulator; }
    ruleComponents[componentType].forEach((component)=>{
      if (accumulator) { return; }
      if (getResourceOriginId(component) === originId) {
        accumulator = component;
      }
    });
    return accumulator;
  }, null);
}

export function getCompareRightSideResourceId(params) {
  let type;
  if (params[RULES + 'RevisionRange']) {
    type = RULES;
  } else if (params[DATA_ELEMENTS + 'RevisionRange']) {
    type = DATA_ELEMENTS;
  } else if (params[EXTENSIONS + 'RevisionRange']) {
    type = EXTENSIONS;
  }

  const revisionRange = getRevisionRangeFromParams(params, type);
  if (!revisionRange) { return null; }

  const {rightRevisionId} = getRevisionIdsFromRevisionRange(revisionRange);
  return rightRevisionId;
}

export function getCompareBaseResourceFromState(state) {
  const currentRouteParams = getCurrentRouteParamsFromState(state);
  const compareBaseResourceId = getCompareRightSideResourceId(currentRouteParams);
  const compareBaseResourceTypeSingular = getSingularResourceTypeFromId(compareBaseResourceId);
  const compareBaseResource = getApiData(state, COMPARE_BASE_RESOURCE_STATE_KEY)[compareBaseResourceTypeSingular];
  return compareBaseResource;
}
