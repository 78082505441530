/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/


import { getRevisionName } from '../../utils/resourceUtils';
import {
  DATA_ELEMENT_COMPARE_STATE_KEY,
  COMPONENT_COMPARE_STATE_KEY
} from './componentCompareView/componentCompareActions';
import { DATA_ELEMENTS } from '../../utils/api/apiTypes';


export function getRevisionRangeParameterName(type) {
  return type + 'RevisionRange';
}

export function getRevisionRangeFromParams(params, type) {
  return params?.[getRevisionRangeParameterName(type)];
}


export function getRevisionIdsFromRevisionRange(revisionRange) {
  if (!revisionRange) { return null; }

  const [leftRevisionId,rightRevisionId] = revisionRange.split('...');
  return {leftRevisionId, rightRevisionId};
}

export function getRevisionRange({leftRevisionId = '', rightRevisionId = ''}) {
  return leftRevisionId + '...' + rightRevisionId;
}


export function getDominantSideData({
  leftSideId,
  rightSideId,
  leftManualResourceData,
  rightManualResourceData,
  stateKeyLeft,
  stateKeyRight
}) {
  let dominantSideId;
  let nonDominantSideId;
  let dominantStateKey;
  let nonDominantStateKey;
  let dominantSideData;
  let nonDominantSideData;


  if (leftSideId) {
    dominantSideId = leftSideId;
    nonDominantSideId = rightSideId;
    dominantSideData = leftManualResourceData;
    nonDominantSideData = rightManualResourceData;
    dominantStateKey = stateKeyLeft;
    nonDominantStateKey = stateKeyRight;
  }
  if (
    rightSideId ||
    (!leftSideId && !rightSideId) // default to the right side
  ) {
    dominantSideId = rightSideId;
    nonDominantSideId = leftSideId;
    dominantSideData = rightManualResourceData;
    nonDominantSideData = leftManualResourceData;
    dominantStateKey = stateKeyRight;
    nonDominantStateKey = stateKeyLeft;
  }

  return {
    dominantSideId,
    nonDominantSideId,
    dominantStateKey,
    nonDominantStateKey,
    dominantSideData,
    nonDominantSideData,
  };
}

export function getRevisionToUseForNonDominantSide({
  dominantSideId,
  latestRevisionId,
  revisions = [],
  originId,
  originDirty,
  howToSetNonDominantSide = 'previousRevision', //previousRevision , upstream
}) {

  let nonDominantSideId = revisions.find((revision)=>(
    revision.id === latestRevisionId
  ))?.id;
  // this should get the first revision that is not head.
  // we need a key here that finds the upstream version of this resource.
  // So we can default to upstream for the left side.
  //
  if (howToSetNonDominantSide === 'previousRevision') {
    // compare against origin
    if (dominantSideId === originId && originDirty) {
      nonDominantSideId = revisions.find((revision)=>(
        revision.id === latestRevisionId
      ))?.id;
    } else {
    // compare against non origin revision
      const revisionIndex = revisions.findIndex((revision)=>(
        revision.id === dominantSideId
      ));
      nonDominantSideId = (
        revisions[revisionIndex + 1] ||
        revisions[0]
      )?.id;
    }
  }
  return nonDominantSideId;
}

// gets the html title attribute for 2 resource revisions by combining their
// revision numbers and names
export function getResourceNamesAsTitleAttr({
  leftResource,
  rightResource,
  isLoading
}) {
  const leftName = leftResource?.attributes.displayName || leftResource?.attributes.name || '';
  let leftRevisionName = getRevisionName(leftResource) || '';
  const rightName = rightResource?.attributes.displayName || rightResource?.attributes.name || '';
  let rightRevisionName = getRevisionName(rightResource) || '';

  let title = 'Loading...';
  if (!isLoading) {
    title = (
      leftRevisionName + ' \r\n' + leftName +
      '\r\n\r\n' +
      rightRevisionName + ' \r\n' + rightName
    );
  }

  return title;
}

export function getResourceIds(props) {
  const {leftResourceId, rightResourceId} = props;
  const revisionIdsFromParams = getRevisionIdsFromRevisionRange(getRevisionRangeFromParams(props.params, props.type));
  // If the component's props already have the resourceIds then we'll use them
  // otherwise, we'll use the "base resource" from the compare params in the URL.
  return leftResourceId || rightResourceId ? {
    leftResourceId,
    rightResourceId
  } : {
    leftResourceId: revisionIdsFromParams?.leftRevisionId,
    rightResourceId: revisionIdsFromParams?.rightRevisionId
  };
}

export function getComponentCompareStateKeyByResourceType(resource) {
  return (
    resource?.type === DATA_ELEMENTS ?
    DATA_ELEMENT_COMPARE_STATE_KEY :
    COMPONENT_COMPARE_STATE_KEY
  );
}
