/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import Immutable from 'seamless-immutable';
import promiseCreator from './promiseCreator';
import actionsHandler from '../../redux/actionsHandler';
import {actionCreators as apiActions} from '../../utils/api/apiActions';

export const STATE_KEY = 'dataElementSelector';

export const TOGGLE_SELECTOR = 'dataElementSelector/TOGGLE_SELECTOR';
export const SELECT_DATA_ELEMENT = 'dataElementSelector/SELECT_DATA_ELEMENT';
export const CHANGE_FILTER = 'dataElementSelector/CHANGE_FILTER';
export const SET_TOKENIZE =  'dataElementSelector/SET_TOKENIZE';



//Reducers
export default actionsHandler({
  [TOGGLE_SELECTOR]: (state)=> {
    return state.set('selectorOpen', !state.selectorOpen);
  },
  [SELECT_DATA_ELEMENT]: (state, {payload:dataElement})=> {
    return state.set('selectedDataElement', dataElement );
  },
  [CHANGE_FILTER]: (state, {payload:filter})=> {
    return state.set('filterString', filter);
  },
  [SET_TOKENIZE]: (state, {payload:tokenize})=> {
    return state.set('tokenize', tokenize);
  },
  default:(state)=> {
    return state || Immutable({
      selectorOpen: false,
      tokenize: true
    });
  }
});

export const promiseUtil = promiseCreator();

//Action Creators
export let actionCreators = {
  toggle() {
    return { type: TOGGLE_SELECTOR };
  },
  selectDataElement(dataElement) {
    return { type: SELECT_DATA_ELEMENT, payload: dataElement };
  },
  changeFilter(newValue) {
    return {
      type: CHANGE_FILTER,
      payload: newValue
    };
  },
  getExtensions(params) {
    return dispatch => {
      return dispatch(apiActions.apiAction({
        name: 'getExtensions',
        stateKey: STATE_KEY,
        urlData: {...params}
      }));
    };
  },
  setTokenize(tokenize) {
    return { type: SET_TOKENIZE, payload: tokenize };
  }
};
