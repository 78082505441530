/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe Systems Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe Systems Incorporated and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Adobe Systems Incorporated and its
 * suppliers and are protected by all applicable intellectual property
 * laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe Systems Incorporated.
 **************************************************************************/


import { COMPANY_LOADER_STATE_KEY } from './companyLoaderActions';
import { BASE_LAYOUT_STATE_KEY } from '../baseLayout/baseLayoutActions';

export function getCompanyFromState(state) {
  return state.api?.[COMPANY_LOADER_STATE_KEY]?.endpoints?.getCompany?.data;
}

export function getCompaniesFromState(state) {
  return state.api?.[BASE_LAYOUT_STATE_KEY]?.endpoints?.getCompanies?.data;
}
