/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/
import React from 'react';
import { SearchField } from '@adobe/react-spectrum'; // React Spectrum v3
import Search from 'spectrum-alternatives/Search'; // React Spectrum v2

const DEBOUNCE_DURATION = 700;

/**
 * Debounces search component input while the user is typing. If the user hits the enter button or clicks the
 * clear button (X), the search will be performed immediately. Instead of providing onChange and onSubmit props,
 * just provide an onSearch prop.
 */
let timeoutId;
export default function DebouncedSearch(props) {
  const {onSearch, spectrumVersion = 2, onChange, className, ariaLabel = 'Search Resources', ...rest} = props;

  return spectrumVersion === 3 ? (
    <SearchField
      {...rest}
      UNSAFE_className={className}
      data-private
      onChange={(value) => {
        onChange?.(value);

        clearTimeout(timeoutId);
        timeoutId = setTimeout(()=>{
          onSearch(value);
        }, DEBOUNCE_DURATION);
      }}
      onClear={() => {
        onChange?.('');
        onSearch('');
      }}
      onSubmit={(value) => {
        clearTimeout(timeoutId);
        onSearch(value);
      }}
      data-test-id={'listViewSearchField'}
      aria-label={ariaLabel}
    />
  ) : (
    <Search
      {...rest}
      className={className}
      data-private
      onChange={(value, event, meta) => {
        onChange?.(value);

        clearTimeout(timeoutId);

        // If the user hit the clear button, it seems to make sense from a user
        // experience perspective to load results immediately. It's only when
        // the user is actively typing that it makes sense to debounce.
        if (meta.from === 'clearButton') {
          onSearch(value);
        } else {
          timeoutId = setTimeout(()=>{ onSearch(value); }, DEBOUNCE_DURATION);
        }
      }}
      onSubmit={(value) => {
        clearTimeout(timeoutId);
        onSearch(value);
      }}
    />
  );
};
