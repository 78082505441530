/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/


let emmiterTimeout = null;
let reduxActionAsDomEventQueue = [];

export default function queueReduxActionAsDomEvent(action, state) {
  // Actions can be decorated with a "noReduxDomEventEmit" attribute in which case
  // they won't be emitted on the dom. This is important to prevent
  // analytics from tracking events that are automated by the UI
  // such as resource copy and property copy.
  if (!action?.payload?.noReduxDomEventEmit) {
    reduxActionAsDomEventQueue.push({action, state});

    clearTimeout(emmiterTimeout);
    emmiterTimeout = setTimeout(emitNextReduxActionAsDomEvent, 10);
  }
}

function emitNextReduxActionAsDomEvent() {
  if (reduxActionAsDomEventQueue.length) {
    const {action, state} = reduxActionAsDomEventQueue.shift();
    const event = new CustomEvent(
      action && action.type || 'unknown-redux-action', {
        detail: {
          action,
          state
        }
      }
    );

    // this is particularly important for Cypress as events were being dispatched after document.body was removed
    // only continue if the document is still around
    if (window.document?.body) {
      window.document.body.dispatchEvent(event);

      // keep going until no events are left
      clearTimeout(emmiterTimeout);
      emmiterTimeout = setTimeout(emitNextReduxActionAsDomEvent, 10);
    } else {
      // empty the queue
      reduxActionAsDomEventQueue = [];
    }
  }
}
