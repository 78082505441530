import actionsHandler from '../../../redux/actionsHandler';
import Immutable from 'seamless-immutable';
import {actionCreators as searchActions} from '../../search/searchActions';

export const STATE_KEY = 'auditEvents';

export const SET_FILTER_VALUE = 'auditEvents/SET_FILTER_VALUE';
export const CLEAN_UP_FILTER_VALUES = 'auditEvents/CLEAN_UP_FILTER_VALUES';

const DEFAULT_FILTER_VALUES = Immutable({
  user: '',
  email: '',
  entityName:'',
  entityType: ''
});

// Reducers
export default actionsHandler({
  [SET_FILTER_VALUE](state, action) {
    return state.set('auditEventFilterValues', action.payload);
  },
  [CLEAN_UP_FILTER_VALUES](state, {payload: filterField}) {
    return filterField ?
      state.merge({auditEventFilterValues: {[filterField]: ''}}, {deep: true}) :
      state.set('auditEventFilterValues', DEFAULT_FILTER_VALUES);
  },
  default(state = Immutable({
    auditEventFilterValues: DEFAULT_FILTER_VALUES
  })) {
    return state;
  }
});

export let actionCreators = {
  setAuditEventSearchBodyValue({attribute, value, currentSearchBody}) {
    let finalQuery = currentSearchBody.asMutable({deep: true});
    return (dispatch) => {
      value ? (finalQuery.query[attribute] = {value: value}) : delete finalQuery.query[attribute];
      return dispatch(searchActions.setSearchRequestBodyData(finalQuery, STATE_KEY));
    };
  },

  setAuditEventFilterValue(option, value, currentSelections) {
    let selections = currentSelections.asMutable({deep: true});
    selections[option] = value;
    return {
      type: SET_FILTER_VALUE,
      payload: selections
    };
  },

  cleanUpAuditEventFilterValues(filterField) {
    return {
      type: CLEAN_UP_FILTER_VALUES,
      payload: filterField
    };
  }
};
