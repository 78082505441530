/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import React, {Component} from 'react';
import Popover from '@react/react-spectrum/Popover';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import classNames from 'classnames';
import DebouncedSearch from '../higherOrderComponents/DebouncedSearch';
import { Text } from '@adobe/react-spectrum';
import Filter from '@spectrum-icons/workflow/Filter';
import ActionButtonWithPointer from 'spectrum-alternatives/Button/ActionButtonWithPointer';
import {actionCreators as filterPanelActions} from '../properties/filterPanel/filterPanelActions';

class SearchWithFilter extends Component {
  constructor() {
    super();
    this.state = {
      hasFocus: false,
      isMouseOver: false
    };
  }

  handleFilterClick() {
    this.props.dispatch(filterPanelActions.setIsFilterPanelOpen(!this.props.isFilterPanelOpen));
  }

  render() {
    const {
      value, placeholder, className, onChange, onSearch, defaultValue,
      popoverIcon, menuClassName, popoverContent, isFilterPanelOpen, hasFilterPanel
    } = this.props;

    return hasFilterPanel ? (
      // This is the component for Search's that are using a filter panel (like audit events)
      // Hopefully some day we can get the other list views switched over to the search
      // endpoint and have more robust filters.
      <div
        className={classNames(
          'searchWithFilter u-flex',
          {'hasFocus': this.state.hasFocus},
          {'mouseIsOver': this.state.isMouseOver}
        )}
        onMouseEnter={()=>{ this.setState({ isMouseOver: true }); }}
        onMouseLeave={()=>{ this.setState({ isMouseOver: false }); }}
      >
        {!isFilterPanelOpen ? (
          <ActionButtonWithPointer
            onPress={() => this.handleFilterClick()}
            placement="bottom"
            trigger="click"
            isQuiet
            data-test-id="filterButton"
          >
            <Filter />
          </ActionButtonWithPointer>
        ) : (
          <div className="filterIcon u-flex u-flexStartJustifyContent u-relativePosition u-marginRight">
            <ActionButtonWithPointer
              onPress={() => this.handleFilterClick()}
              placement="bottom"
              trigger="click"
              isQuiet
              className="u-marginRightSm"
              data-test-id="filterButton"
            >
              <Filter />
              <Text>Hide Filters</Text>
            </ActionButtonWithPointer>
          </div>
        )}

        <DebouncedSearch
          onFocus={()=>{ this.setState({ hasFocus: true }); }}
          onBlur={()=>{ this.setState({ hasFocus: false }); }}
          placeholder={placeholder}
          className={className}
          onSearch={onSearch}
          defaultValue={defaultValue}
          onChange={onChange}
          value={value}
          data-test-id="listViewSearchTextfield"
          spectrumVersion={3}
        />
      </div>
    ) : (
      <div
        className={classNames(
          'searchWithFilter u-flex',
          {'noBorderRight': popoverIcon},
          {'hasFocus': this.state.hasFocus},
          {'mouseIsOver': this.state.isMouseOver}
        )}
        onMouseEnter={()=>{ this.setState({ isMouseOver: true }); }}
        onMouseLeave={()=>{ this.setState({ isMouseOver: false }); }}
      >
        <DebouncedSearch
          onFocus={()=>{ this.setState({ hasFocus: true }); }}
          onBlur={()=>{ this.setState({ hasFocus: false }); }}
          placeholder={placeholder}
          className={className}
          onSearch={onSearch}
          onChange={onChange}
          defaultValue={defaultValue}
          data-test-id="listViewSearchTextfield"
        />
        {popoverIcon ? (
          <OverlayTrigger placement="bottom" trigger="click">
            <div className="popoverIconTrigger u-pointer u-marginBottomSm" data-test-id="listViewSearchFilterTrigger">
              {popoverIcon}
            </div>
            <Popover className={classNames('searchWithFilter-content', menuClassName)} open={true} placement="bottom">
              {popoverContent}
            </Popover>
          </OverlayTrigger>
        ) : null}
      </div>
    );
  }
}

export default SearchWithFilter;
