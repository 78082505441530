import {
  GLOBAL_DIALOGS_KEY,
  getActionCreators as getDialogsActions
} from '../higherOrderComponents/dialogsActions';

export const DIALOGS_STATE_KEY = GLOBAL_DIALOGS_KEY;
export const UNSAVED_CHANGES_KEY = 'unsavedChanges';

const dialogsActions = getDialogsActions(GLOBAL_DIALOGS_KEY);

let confirmCallback;

export const actionCreators = {
  getUserConfirmation(callback) {
    return (dispatch) => {
      // getUserConfirmation determine's if navigation to the requested URL should happen
      // This is only implemented where we are using <ReactRouterPause />
      confirmCallback = callback; // callback(boolean) is used to determine if navigation should continue
      dispatch(dialogsActions.openDialog(UNSAVED_CHANGES_KEY));
    };
  },
  proceed() {
    return (dispatch) => {
      // Prompt triggers its message renderer for a frame between the prompt
      // being confirmed and navigating. Due to us not using the when
      // attribute this causes double rendering of this dialog. Delaying the
      // confirm/cancel action for the dialog fixes this.
      setTimeout(()=>{dispatch(dialogsActions.closeDialog(UNSAVED_CHANGES_KEY));},1);

      if (confirmCallback) {
        confirmCallback(true);
        confirmCallback = null;
      }
    };
  },
  cancel() {
    return (dispatch) => {
      // Prompt triggers its message renderer for a frame between the prompt
      // being confirmed and navigating. Due to us not using the when
      // attribute this causes double rendering of this dialog. Delaying the
      // confirm/cancel action for the dialog fixes this.
      setTimeout(()=>{dispatch(dialogsActions.closeDialog(UNSAVED_CHANGES_KEY));},1);

      if (confirmCallback) {
        confirmCallback(false);
        confirmCallback = null;
      }
    };
  }
};
