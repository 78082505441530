import Immutable from 'seamless-immutable';

/**
 * Returns information about the selected items and selectable items for the specified stateKey.
 * @param stateKey
 * @param state
 * @returns {Immutable.object}
 */
export const getSelectableItemsInfoFromState = (stateKey, state) => {
  const defaultSelectedListOfItems = Immutable({
    selectableItems: [],
    selectedItems: []
  });

  const stateSelectedListOfItems = state.selections?.[stateKey];

  return stateSelectedListOfItems || defaultSelectedListOfItems;
};
