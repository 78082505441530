/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import Immutable from 'seamless-immutable';
import actionsHandler from '../redux/actionsHandler';

const DEFAULT_DELAY = 5 * 1000;
const DEFAULT_RETRIES_BEFORE_BACKOFF = 9;
const DEFAULT_BACKOFF_MULTIPLIER = 1.3;
const DEFAULT_MAX_RETRIES = 24;

export const POLLING_STOPPED_CODES = {
  POLLING_ERROR: 'There was an error while polling.',
  MAX_RETRIES: 'The maximum retry count was reached.'
};

export const POLLING_STARTED = 'ayncPoll/POLLING_STARTED';
export const POLLING_STOPPED = 'ayncPoll/POLLING_STOPPED';
export const TIMEOUT_STARTED = 'ayncPoll/TIMEOUT_STARTED';
export const TIMEOUT_COMPLETED = 'ayncPoll/TIMEOUT_COMPLETED';
export const CALL_STARTED = 'ayncPoll/CALL_STARTED';
export const CALL_COMPLETED = 'ayncPoll/CALL_COMPLETED';
export const SET_MAX_RETRIES_REACHED = 'ayncPoll/SET_MAX_RETRIES_REACHED';
export const SET_POLLING_STOPPED_CODE = 'ayncPoll/SET_POLLING_STOPPED_CODE';

export default actionsHandler({
  [POLLING_STARTED](state, {payload: {key}}) {
    return state.merge({
      [key]: {
        isPolling: true,
        isFirstPollIntervalComplete: false,
        maxRetriesReached: false,
        pollingStoppedCode: null
      }
    }, { deep: true });
  },
  [POLLING_STOPPED](state, {payload: {key}}) {
    return state.merge({
      [key]: {
        isPolling: false,
        timeoutId: null,
        outstandingPromiseId: null,
        pollsCount: 0
      }
    }, { deep: true });
  },
  [TIMEOUT_STARTED](state, {payload: {key, timeoutId}}) {
    return state.merge({
      [key]: {
        timeoutId: timeoutId
      }
    }, { deep: true });
  },
  [TIMEOUT_COMPLETED](state, {payload: {key}}) {
    return state.merge({
      [key]: {
        timeoutId: null
      }
    }, { deep: true });
  },
  [CALL_STARTED](state, {payload: {key, outstandingPromiseId}}) {
    let pollsCount = state[key] && state[key].pollsCount || 0;
    pollsCount++;
    return state.merge({
      [key]: {
        outstandingPromiseId,
        pollsCount
      }
    }, { deep: true });
  },
  [CALL_COMPLETED](state, {payload: {key}}) {
    return state.merge({
      [key]: {
        outstandingPromiseId: null,
        isFirstPollIntervalComplete: true
      }
    }, { deep: true });
  },
  [SET_MAX_RETRIES_REACHED](state, {payload: {key, maxRetriesReached}}) {
    return state.merge({
      [key]: { maxRetriesReached }
    }, { deep: true });
  },
  [SET_POLLING_STOPPED_CODE](state, {payload: {key, pollingStoppedCode}}) {
    return state.merge({
      [key]: { pollingStoppedCode }
    }, { deep: true });
  },
  default: (state = Immutable({}))=> {
    return state;
  }
});

let promiseIdIncrementor = 0;

export let actionCreators = (pollingKey)=>{
  const keyedActionCreators = {
    // expose pollingKey for better debugging and decisioning logic
    // this is not a dispatchable action (no associated reducer)
    getPollingInfo() {
      return { type: 'pollingInfo', pollingKey };
    },

    /**
     * Calls a function repeatedly with a delay between each call. If the function returns a
     * promise, it will wait until the promise resolves or fails before beginning the next delay.
     *
     * @param {Function} fn The function to call after each delay
     * @param {Object} options
     * @param {number} [options.delayMillis=5000] The number of milliseconds to delay.
     * @param {number} [options.retriesBeforeBackoff=9] The number retries before the backoff starts to be applied.
     * @param {number} [options.maxRetries=24] The number of retries before polling stops.
     * @param {number} [options.backoffMultiplier=1.3] The multiplier for each subsequent poll.
     * @param {boolean} [options.delayFirstCall=false] Whether there should be a delay before the
     * first call. If false, the first call will be made immediately.
     */
    startPolling(fn, options = {}) {
      const {
        delayMillis = DEFAULT_DELAY,
        retriesBeforeBackoff = DEFAULT_RETRIES_BEFORE_BACKOFF,
        maxRetries = DEFAULT_MAX_RETRIES,
        backoffMultiplier = DEFAULT_BACKOFF_MULTIPLIER,
        delayFirstCall = false
      } = options;

      return (dispatch, getState) => {
        let pollState = getState().polling[pollingKey];

        if (pollState && pollState.isPolling) {
          return;
        }

        dispatch({
          type: POLLING_STARTED,
          payload: {
            key: pollingKey
          }
        });

        const startTimeout = () => {

          // pollState should have been updated by handlePromise() so it should be the latest
          let pollsCount = pollState && pollState.pollsCount || 0;

          // calculate the next delay based on the current poll tries and our backoffMultiplier
          // if there is a `retriesBeforeBackoff` we want to wait until those retries are done before the backoff
          const nextDelayMillis = (
            delayMillis * (
              pollsCount < retriesBeforeBackoff ?
              1 :
              Math.pow(backoffMultiplier, pollsCount - retriesBeforeBackoff)
            )
          );

          const timeoutId = setTimeout(onTimeout, nextDelayMillis);

          dispatch({
            type: TIMEOUT_STARTED,
            payload: {
              key: pollingKey,
              timeoutId
            }
          });
        };

        const onTimeout = () => {
          dispatch({
            type: TIMEOUT_COMPLETED,
            payload: {
              key: pollingKey
            }
          });

          const outstandingPromiseId = ++promiseIdIncrementor;

          const handlePromise = (err) => {
            if (err instanceof Error) {
              console.error(err);

              dispatch(keyedActionCreators.stopPolling());
              dispatch({
                type: SET_POLLING_STOPPED_CODE,
                payload: {
                  key: pollingKey,
                  pollingStoppedCode: POLLING_STOPPED_CODES.POLLING_ERROR
                }
              });
              return;
            }
            pollState = getState().polling[pollingKey];
            dispatch({
              type: CALL_COMPLETED,
              payload: {
                key: pollingKey
              }
            });

            // It's very possible that polling was stopped after fn was called but before
            // the promise is resolved. We only want to continue handling this resolution if
            // we consider the promise to be "outstanding". When polling is stopped, we clear the
            // outstanding promise ID from the state so it's no longer considered outstanding.
            if (outstandingPromiseId === pollState.outstandingPromiseId) {
              let pollsCount = pollState && pollState.pollsCount || 0;

              // quit polling if we've hit our max retries
              if (pollsCount < maxRetries) {
                startTimeout();
              } else {
                dispatch(keyedActionCreators.stopPolling());
                dispatch({
                  type: SET_MAX_RETRIES_REACHED,
                  payload: {
                    key: pollingKey,
                    maxRetriesReached: true
                  }
                });
                dispatch({
                  type: SET_POLLING_STOPPED_CODE,
                  payload: {
                    key: pollingKey,
                    pollingStoppedCode: POLLING_STOPPED_CODES.MAX_RETRIES
                  }
                });
              }
            }
          };

          Promise
            .resolve(fn(getState))
            .then(handlePromise)
            .catch(handlePromise);

          dispatch({
            type: CALL_STARTED,
            payload: {
              key: pollingKey,
              outstandingPromiseId
            }
          });
        };

        if (delayFirstCall) {
          startTimeout();
        } else {
          onTimeout();
        }
      };
    },
    stopPolling() {
      return (dispatch, getState) => {
        const pollState = getState().polling[pollingKey];

        if (!pollState || !pollState.isPolling) {
          return;
        }

        if (pollState.timeoutId) {
          clearTimeout(pollState.timeoutId);
        }

        dispatch({
          type: POLLING_STOPPED,
          payload: {
            key: pollingKey
          }
        });
      };
    }
  };

  return keyedActionCreators;
};
