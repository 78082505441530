/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import React, { Component } from 'react';
import {getLogRocketSessionURL} from './logrocketUtils';
import {Notifier} from '@airbrake/browser';
import {Toast} from '@react/react-spectrum/Toast';
import Button from '@react/react-spectrum/Button';

export const airbrake = new Notifier({
  projectId: window.environmentSettings.lens_airbrakeProjectId,
  projectKey: window.environmentSettings.lens_airbrakeProjectKey,
  environment: window.environmentSettings.lens_environmentName
});

let logRocketSessionURL;
getLogRocketSessionURL().then((sessionUrl)=>{
  logRocketSessionURL = sessionUrl;
});

airbrake.addFilter((notice)=>{
  notice.context.logRocketSessionURL = logRocketSessionURL;
  notice.context.environment = window.environmentSettings.lens_environmentName;
  notice.context.imsEcConfig = window.environmentSettings.lens_imsEcConfig;
  notice.context.version = window.environmentSettings.lens_version;
  notice.context.component = 'version ' + window.environmentSettings.lens_version;

  // airbrake's limitation is 64000 characters - our state is often bigger than that, so we
  // don't send redux state.

  // If airbrake errors start getting hard to debug consider moving js files to
  // akamai then we can get better sourcemap support
  if (notice.context.environment === 'local' && notice.errors.length) {
    console.log('airbrake notice', notice);
  }
  if (window.environmentSettings.lens_errorReportingEnabled && notice.errors.length) {
    return notice;
  } else {
    return null;
  }
});

// I think we may be able to remove this but we should let this run in production for a while
// And make sure that we dont have any error cases that this shows up in the call stack.
export let ensureErrorObject = (message)=>{
  return message instanceof Error ? message
    : new Error(JSON.stringify(message));
};

export class AirbrakeErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }


  componentDidCatch(error, info) {
    this.setState({ hasError: true });

    airbrake.notify({
      error: error,
      params: {info: info}
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="u-center u-paddingVertical100px">
          <Toast variant="error">
            <span className="spectrum-Heading--subtitle1 c-white u-paddingBottomSm">
              Application Error
            </span>
            <div>
              An unknown error has occurred. This has been reported.
            </div>
          </Toast>

          <div className="u-marginTop u-textCenter">
            <Button
              variant="primary"
              onClick={()=>{
                window.location.reload();
              }}
            >
              Reload Application
            </Button>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
