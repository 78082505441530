/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import apiMappings from './apiMappings';
import {LOCATION_CHANGE} from 'connected-react-router';
import {actionCreators as globalsActions} from '../../components/higherOrderComponents/globalsActions';
import {airbrake, ensureErrorObject} from '../errorReporter';
import {actionCreators as toastsActions} from '../../components/higherOrderComponents/toastsActions';
import {
  loadingAllPagesReducer,
  successAllPagesReducer,
  failAllPagesReducer,
  successAllPagesCompleteReducer,
  apiActionsLoadAllPages
} from './apiLoadAllPagesUtils';
import {getAppError, getGenericApiErrorBody} from './apiTools';
import { endpointIncludedReducer, endpointDataReducer, endpointPaginationReducer, getEndpointPathFromAction } from './apiResponseHandlers';
import Immutable from 'seamless-immutable';
import {actionCreators as shellActions} from '../../components/header/shellActions';

export const API_CALL = '@@api/API_CALL';
export const API_CALL_LOADING = new RegExp(API_CALL + '.*?LOADING');
export const API_CALL_SUCCESS = new RegExp(API_CALL + '.*?SUCCESS');
export const API_CALL_FAIL = new RegExp(API_CALL + '.*?FAIL');
export const RESET_ENDPOINT = '@@api/RESET_ENDPOINT';
export const RESET_DATA = '@@api/RESET_DATA';
export const MANUALLY_SET_DATA = '@@api/MANUALLY_SET_DATA';


// We maintain a a list of state keys that we've seen while processing actions.
// We use this to reset endpoint states for all state keys.
let stateKeys = [];

function updateOutstandingRequestIds(state, outstandingRequestIds) {
  return state.setIn(['api', 'outstandingRequestIds'], outstandingRequestIds);
}

export function addOutstandingRequestId(state, requestId) {
  return updateOutstandingRequestIds(state, state.api.outstandingRequestIds.concat(requestId));
}

export function removeOutstandingRequestId(state, requestId) {
  let outstandingRequestIds = state.api.outstandingRequestIds;
  const index = outstandingRequestIds.indexOf(requestId);
  outstandingRequestIds = outstandingRequestIds
    .slice(0, index)
    .concat(outstandingRequestIds.slice(index + 1));
  return updateOutstandingRequestIds(state, outstandingRequestIds);
}

function removeAllOutstandingRequestIds(state) {
  return updateOutstandingRequestIds(state, []);
}

export function updateEndpointStatus(state, action, statusDataToMerge) {
  const endpointPath = getEndpointPathFromAction(action);
  // The first time loadingReducer is called, the endpointState will be undefined
  // so we default to an empty immutable.
  return state.updateIn(endpointPath, (endpointState = Immutable({})) => {
    return endpointState.merge(statusDataToMerge);
  });
}

export function updateEndpoint(state, action) {
  state = endpointPaginationReducer(state, action);
  state = endpointDataReducer(state, action);
  state = endpointIncludedReducer(state, action);

  return state;
}

function loadingReducer(state, action) {
  if (action.type.match(API_CALL_LOADING)) {
    state = addOutstandingRequestId(state, action.payload.requestId);
    state = updateEndpointStatus(state, action, {
      loading: true,
      error: null
    });
    state = updateEndpoint(state, action);
  }

  return state;
}

function successReducer(state, action) {
  if (action.type.match(API_CALL_SUCCESS)) {
    const requestIdIndex = state.api.outstandingRequestIds.indexOf(action.payload.requestId);

    // Only handle the response if its id hasn't been removed from outstandingRequestIds.
    // IDs are removed from outstandingRequestIds when their respective responses are received
    // or the user navigates away from the view.
    if (requestIdIndex !== -1) {
      state = removeOutstandingRequestId(state, action.payload.requestId);

      const {responseReducer} = apiMappings[action.payload.name];

      if (responseReducer) {
        state = responseReducer(state, action);
      }
      state = updateEndpointStatus(state, action, {
        loading: false,
        error: null
      });
      state = updateEndpoint(state, action);
    }
  }

  return state;
}

function failReducer(state, action) {
  if (action.type.match(API_CALL_FAIL)) {
    const requestIdIndex = state.api.outstandingRequestIds.indexOf(action.payload.requestId);

    if (requestIdIndex !== -1) {
      state = removeOutstandingRequestId(state, action.payload.requestId);
      state = updateEndpointStatus(state, action, {
        loading: false,
        error: action.error.message
      });
      state = updateEndpoint(state, action);
    }
  }

  return state;
}

function resetEndpointReducer(state, action) {
  if (action.type === RESET_ENDPOINT) {
    const {responseReducer, mappingLocation} = apiMappings[action.payload.name];

    // this mapping location is pretty specific to getListReducer
    // we should remove this when we make everything use data from the newer
    // state.api[stateKey].endpoints[endpointName].data
    if (mappingLocation) {
      state = resetDataReducer(state, {
        ...action,
        payload: {
          ...action.payload,
          dataKeys:[mappingLocation]
        }
      });
    }

    if (responseReducer) {
      if (action.payload.stateKey) {
        // this make the response reducer pretend that state.api is the nested state.api[stateKey]
        // this means response reducers cannot interact with both state.api and state.api[stateKey]
        // at the same time if the stateKey is provided.

        // We should use the resetData instead.
        // I put this in as a safety but We need to scrub the app for all the resetEndpoints and
        // make them use both resetEndpoint and resetData.
        // Once that is done we can remove this code.

        const tempState = responseReducer(
          state.set('api',state.api[action.payload.stateKey] || {}),
          action
        );
        state = tempState.set('api', state.api).setIn(['api', action.payload.stateKey], tempState.api);
      } else {
        state = responseReducer(state, action);
      }
    }

    state = updateEndpointStatus(state, action, {
      loading: false,
      error: null
    });
    state = updateEndpoint(state, action);
  }

  return state;
}

function resetDataReducer(state, action) {
  const updatePath = action.payload.stateKey ? ['api', action.payload.stateKey] : ['api'];
  return state.updateIn(updatePath, (apiData)=>{
    action.payload.dataKeys.forEach((keyToReset) => {
      apiData = apiData && apiData.without(keyToReset);
    });
    return apiData;
  });
}

function manuallySetDataReducer(state, action) {
  return state.setIn(
    action.payload.path,
    action.payload.data
  );
}

export function resetEndpointsOnLocationChangeReducer(state, action) {
  if (
    action.type === LOCATION_CHANGE &&
    action.payload.action !== 'REPLACE' &&
    state.api.outstandingRequestIds.length
  ) {
    // Ignore any future response that we may receive for currently outstanding requests.
    state = removeAllOutstandingRequestIds(state);

    // TODO Remove once everything is using stateKey.
    Object.keys(state.api.endpoints).forEach((endpointName) => {
      const actionWithStateKeyAndEndpointName = {
        ...action,
        payload: {
          ...action.payload,
          stateKey: '',
          name: endpointName
        }
      };
      state = updateEndpointStatus(state, actionWithStateKeyAndEndpointName, {
        loading: false,
        error: null
      });
      state = updateEndpoint(state, actionWithStateKeyAndEndpointName);
    });

    // handle stateKey entries
    stateKeys.forEach((stateKey) => {
      Object.keys(state.api[stateKey].endpoints).forEach((endpointName) => {
        const actionWithStateKeyAndEndpointName = {
          ...action,
          payload: {
            ...action.payload,
            stateKey,
            name: endpointName
          }
        };
        state = updateEndpointStatus(state, actionWithStateKeyAndEndpointName, {
          loading: false,
          error: null
        });
        state = updateEndpoint(state, actionWithStateKeyAndEndpointName);
      });
    });
  }

  return state;
}

//Reducers
export default function apiActions(state, action) {
  if (!state.api) {
    state = state.set('api', {
      endpoints: {}, // TODO Remove once everything is using stateKey.
      outstandingRequestIds: []
    });
  }

  if (action?.type?.match(API_CALL)) {
    const stateKey = action.payload.stateKey;

    if (stateKey) {
      if (!state.api[stateKey]) {
        state = state.setIn(['api', stateKey], {
          endpoints: {}
        });
      }

      if (stateKeys.indexOf(stateKey) === -1) {
        stateKeys.push(stateKey);
      }
    }

    if (action.payload.loadAllPages) {
      state = loadingAllPagesReducer(state, action);
      state = successAllPagesReducer(state, action);
      state = failAllPagesReducer(state, action);
      state = successAllPagesCompleteReducer(state, action);
    } else {
      state = loadingReducer(state, action);
      state = successReducer(state, action);
      state = failReducer(state, action);
    }
  } else if (action.type === RESET_ENDPOINT) {
    state = resetEndpointReducer(state, action);
  } else if (action.type === RESET_DATA) {
    state = resetDataReducer(state, action);
  } else if (action.type === MANUALLY_SET_DATA) {
    state = manuallySetDataReducer(state, action);
  }

  // Tech Debt TODO:
  // This reducer was inadvertently moved sometime ago to a location that made it never
  // get executed. It has not been functional for a while. Using it reveals several
  // errors that should be addressed before reenabling it.
  // state = resetEndpointsOnLocationChangeReducer(state, action);

  return state;
};

let requestIdIncrementor = 0;

export function getNewRequestId() {
  return requestIdIncrementor++;
}

export const getApiAction = (options)=>{
  return {
    type: API_CALL + '_' + options.name.toUpperCase(),
    payload: {
      // name = String,                   // name is the name of the endpoint in apiMappings
      // data = Object,                   // this is the body for the request
      // urlData = Object,                // url data is what will replace variables inside apiMapping url
      // urlParams = Object,              // these are the query params added to the end of the url
      // headers = Object,                // headers passed with the request
      // stateKey = String,               // where to store all endpoints and response data on state.api
      // updateState = Boolean,           // update the redux state.api variables
      // bypassError = Boolean            // ignore the default toast error messages
      // abortSignal = AbortSignal        // optional abort signal use this to cancel the promise
      // swallowAbortErrors = Boolean     // optional swallow errors when requests are aborted
      // loadAllPages = Boolean           // inspects the meta and loads all available pages
      // updatePaginationState = Boolean  // update the redux state.pagination slice

      // defaults
      urlParams: {},
      headers: {},
      updateState: true,
      bypassError: false,
      abortSignal: null,
      swallowAbortErrors: true,

      // override defaults with passed in options
      ...options,
      requestId: getNewRequestId()
    }
  };
};

//Action Creators
export let actionCreators = {
  apiAction(options) {
    return (dispatch)=>{
      if (options.loadAllPages) {
        return apiActionsLoadAllPages(dispatch, options);
      } else {
        return dispatch(getApiAction(options));
      }
    };
  },
  resetEndpoint(endpointName, stateKey) {
    return {
      type: RESET_ENDPOINT,
      payload: {
        stateKey,
        name: endpointName
      }
    };
  },
  resetData(dataKeys, stateKey) {
    return {
      type: RESET_DATA,
      payload: {
        stateKey,
        dataKeys, // list of keys to remove under stateKey
      }
    };
  },
  manuallySetData(path, data) {
    return {
      type: MANUALLY_SET_DATA,
      payload: {
        path,
        data
      }
    };
  },
  handleFetchResponseErrors(response, bypassError) {
    return (dispatch, getState) => {
      const state = getState();

      // this tells us if it's ok to force logout next time there is a 401 response
      // we'll only set this once - see the 401 block below
      if (!state.globals.firstAuthSucceeded && response.status !== 401) {
        dispatch(globalsActions.setFirstAuthSucceeded(true));
      }

      if (response.ok) {
        return Promise.resolve(response);
      } else {
        // base error to be populated by potential error states below
        // at a bare minimum, this object needs to be extended with `body` and `textBody`
        let appError = getAppError({networkResponse: response});

        // we are handling the sceneario where the API does not return a
        // standardized JSON error as well as when it does via response.text()
        return response.text().then((text)=>{
          // save the textBody regardless of our response so we can use it later
          // to generate a standard error if needed
          appError.networkResponse.textBody = text;

          // this will fail and skip to our `catch` for invalid JSON responses
          appError.networkResponse.body = JSON.parse(text);

          // if a standard error already exists, use it
          const errorDetail = appError.networkResponse?.body?.errors[0]?.detail;
          if (errorDetail) { appError.message = errorDetail; }

          return Promise.reject(appError);
        }).catch(() => {
          // sometimes we want to bypass the standard error handling because
          // we will handle it in a different way (EX: dialog instead of toast)
          if (bypassError) {
            return Promise.reject(appError);
          }

          // ensure a standardized error format if body parsing failed
          // this handles all scenearios where a response body could not be retrieved or parsed
          if (!appError.networkResponse.body) {
            const textBody = appError.networkResponse?.textBody;
            appError.networkResponse.body = getGenericApiErrorBody(textBody ? {
              detail: appError.networkResponse.textBody
            } : undefined);
          }

          // handle different response status codes
          if (response.status === 401) {
            // the API is reporting that you are not authenticated with IMS
            // this could also mean that your x-gw-ims-org-id is one that you don't have access to
            dispatch(globalsActions.setApiUnauthorized(true));

            // auth succeeded at one point so we'll assume that their token
            // has been invalidated somehow (signing in elsewhere or timeout)
            if (state.globals.firstAuthSucceeded) {
              dispatch(shellActions.logout());
            }
          } else if (response.status === 403) {
            // there are legitimate cases where we want to bypass a 403 error
            // this can happen when navigating to a company that you don't have access to.
            // in some cases we want to handle the error manually in the specific view.
            if (!bypassError) {
              dispatch(globalsActions.setApiForbidden(true));
            }
          } else if (response.status === 404) {
            // there are legitimate cases where we want to bypass a 404 error
            // because the load failure might be handled by the UI
            if (!bypassError) {
              appError.message = 'A resource you requested could not be found.';
              dispatch(toastsActions.addToast({
                variant: 'error',
                error: appError
              }));
            }
          } else if (
            (response.status === 503) &&
            (appError.networkResponse.body.errors[0].code === 'system-maintenance')
          ) {
            dispatch(globalsActions.maintenanceActive(true));
          } else if (
            response.status >= 500 &&
            response.status < 600
          ) {
            appError.lensCode = 'serverError';
            appError.message = 'An error has occurred on the server.';

            dispatch(toastsActions.addToast({
              variant: 'error',
              error: appError
            }));
          } else if (
            response.status >= 400 &&
            response.status < 500
          ) {
            appError.message = 'An unknown error has occurred.';

            dispatch(toastsActions.addToast({
              variant: 'error',
              error: appError
            }));

            response.text().then((text)=>{
              airbrake.notify(ensureErrorObject({...appError, body: text}));
            }).catch((err)=>{
              airbrake.notify(ensureErrorObject({
                ...appError,
                body: 'Unable to get response body\r' + err
              }));
            });
          }

          return Promise.reject(appError);
        });
      };
    };
  }
};
