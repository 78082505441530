export const paginationLocalStorageNamespace = 'listPaginationPageSize';
import { getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem} from '../../utils/storageUtils';

const getLocalStorageKey = stateKey => {
  if (!stateKey) {
    return undefined;
  }

  return `${paginationLocalStorageNamespace}_${stateKey}`;
};

export const supportedPaginationPageSizes = [
  { label: '10', value: 10 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];
const supportedPageSizeValuesList = supportedPaginationPageSizes.map(({ value }) => value);

/**
 * Returns the remembered pagination page size to use for certain list pages around lens.
 * @param {string} stateKey - The name you want to store and retrieve your page size under
 * @param {number|string} [defaultSize] - Override the default returned value
 * @returns {number} -  The remembered number, or 10 as a default
 */
export const getPaginationPageSizeFromLocalStorage = (stateKey, defaultSize) => {
  const pageSizeStorageKey = getLocalStorageKey(stateKey);
  const storedPageSize = parseInt(getLocalStorageItem(pageSizeStorageKey), 10);

  if (!isNaN(storedPageSize) && supportedPageSizeValuesList.includes(storedPageSize)) {
    return storedPageSize;
  } else {
    // clear bad data and return the smallest supported page size OR the default passed in
    removeLocalStorageItem(pageSizeStorageKey);
    if (supportedPageSizeValuesList.includes(parseInt(defaultSize, 10))) {
      return defaultSize;
    }
    return supportedPageSizeValuesList[0];
  }
};

export const setPaginationPageSizeInLocalStorage = (stateKey, pageSize) => {
  if (!stateKey) {
    return;
  }

  const pageSizeStorageKey = getLocalStorageKey(stateKey);

  if (supportedPageSizeValuesList.includes(parseInt(pageSize, 10))) {
    setLocalStorageItem(pageSizeStorageKey, pageSize);
  }
};
