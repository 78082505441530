/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe Systems Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe Systems Incorporated and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Adobe Systems Incorporated and its
 * suppliers and are protected by all applicable intellectual property
 * laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe Systems Incorporated.
 **************************************************************************/

import actionsHandler from '../../redux/actionsHandler';
import promiseCreator from './promiseCreator';
import Immutable from 'seamless-immutable';

export const SET_REGEX_TESTER_OPEN = 'regexTester/SET_REGEX_TESTER_OPEN';
export const SET_PATTERN = 'regexTester/SET_PATTERN';
export const SET_FLAGS = 'regexTester/SET_FLAGS';
export const SET_TEST_STRING = 'regexTester/SET_TEST_STRING';
export const SET_TEMPORARY_FLAGS = 'regexTester/SET_TEMPORARY_FLAGS';

const addMatch = (matches, result) => {
  matches.push({
    index: result.index,
    length: result[0].length
  });
};

const findMatches = (pattern = '', flags = '', testString = '') => {
  let regExp;

  // Certain patterns can be invalid (for example: ?). In these cases, we'll return undefined.
  try {
    regExp = new RegExp(pattern, flags);
  } catch (e) {
    return;
  }

  const matches = [];
  let result;

  if (flags.indexOf('g') !== -1) {
    while (result = regExp.exec(testString)) {
      addMatch(matches, result);

      if (result.index === regExp.lastIndex) {
        break;
      }
    }
  } else {
    result = regExp.exec(testString);
    if (result) {
      addMatch(matches, result);
    }
  }

  return matches;
};

//Reducers
export default actionsHandler({
  [SET_REGEX_TESTER_OPEN]: (state, {payload})=> {
    return state.set('testerOpen', payload);
  },
  [SET_PATTERN]: (state, {payload})=> {
    return state.merge({
      pattern: payload,
      matches: findMatches(payload, state.flags + state.temporaryFlags, state.testString)
    });
  },
  [SET_FLAGS]: (state, {payload})=> {
    return state.merge({
      flags: payload,
      matches: findMatches(state.pattern, payload + state.temporaryFlags, state.testString)
    });
  },
  [SET_TEST_STRING]: (state, {payload})=> {
    return state.merge({
      testString: payload,
      matches: findMatches(state.pattern, state.flags + state.temporaryFlags, payload)
    });
  },
  [SET_TEMPORARY_FLAGS]: (state, {payload = ''})=> {
    return state.merge({
      temporaryFlags: payload,
      matches: findMatches(state.pattern, state.flags + payload, state.testString)
    });
  },
  default:(state)=> {
    return state || Immutable({
      testerOpen: false,
      pattern: '',
      flags: '',
      temporaryFlags: '',
      testString: '',
      matches: null
    });
  }
});

export const promiseUtil = promiseCreator();

//Action Creators
export let actionCreators = {
  setRegexTesterOpen(testerOpen) {
    return {
      type: SET_REGEX_TESTER_OPEN,
      payload: testerOpen
    };
  },
  setPattern(pattern) {
    return {
      type: SET_PATTERN,
      payload: pattern
    };
  },
  setFlags(flags) {
    return {
      type: SET_FLAGS,
      payload: flags
    };
  },
  setTestString(testString) {
    return {
      type: SET_TEST_STRING,
      payload: testString
    };
  },
  setTemporaryFlags(flags) {
    return {
      type: SET_TEMPORARY_FLAGS,
      payload: flags
    };
  },
  addTemporaryFlag(flag = '') {
    return (dispatch, getState) => {
      const currentTemporaryFlags = getState()?.regexTester?.temporaryFlags;
      if (currentTemporaryFlags.indexOf(flag) === -1) {
        dispatch({
          type: SET_TEMPORARY_FLAGS,
          payload: currentTemporaryFlags + flag
        });
      }
    };
  },
  removeTemporaryFlag(flag = '') {
    return (dispatch, getState) => {
      const currentTemporaryFlags = getState()?.regexTester?.temporaryFlags;
      if (currentTemporaryFlags.indexOf(flag) !== -1) {
        dispatch({
          type: SET_TEMPORARY_FLAGS,
          payload: ''.replace(new RegExp(flag, 'gi'), '')
        });
      }
    };
  }
};
