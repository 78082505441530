/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import {
  populateUrlFromOrgAndExperienceCloud,
  replaceExperienceWithExperienceCloud
} from '../higherOrderComponents/imsWrapperUtils';
import { getCompanyFromState } from '../layouts/company/companySelectors';
import { getLocalStorageItem } from '../../utils/storageUtils';
import { getCurrentRouteParamsFromState } from '../../routes/routeSelectors';
import { CAPABILITY_TYPES } from '../../routes/capabilityUtils';
import { CAPABILITY_FEATURE_FLAGS } from '../../routes/capabilityUtils';


export const ACTIVE_ORG_LOCALSTORAGE_KEY = 'activeOrg';

export function getOrganizations(state) {
  return state.api?.profile?.attributes?.organizations || {};
}

export function getOrgsFromImsProfile(imsProfile) {
  const projectedProductContexts = imsProfile.projectedProductContext;

  const organizationContexts = projectedProductContexts.filter((projectedProductContext)=>{
    return (
      projectedProductContext.prodCtx.serviceCode === 'dma_tartan' &&
      projectedProductContext.prodCtx.tenant_id
    );
  });

  return organizationContexts.reduce((orgs, organizationContext)=>{
    const prodCtx = organizationContext.prodCtx;

    // only add it if we didn't already do so
    const orgId = prodCtx.owningEntity;
    if (!orgs[orgId]) {
      orgs[orgId] = {
        id: orgId,
        orgName: prodCtx.tenant_id, // we don't know it so we'll fallback to tenant_id
        orgRef: {
          ident: orgId.split('@')[0],
          authSrc: orgId.split('@')[1]
        },
        orgType: null,
        countryCode: null,
        groups: [],
      };
    }

    return orgs;
  }, {});
}

export function getLocalShellImsOrgsFromState(state) {
  return state.ims?.localShell?.imsOrganizations || {};
}

export function getEditProfileLink(state) {
  const hostedShellConfig = state.ims?.hostedShellConfig || {};
  const activeOrg = getActiveOrg(state);

  return replaceExperienceWithExperienceCloud(
    populateUrlFromOrgAndExperienceCloud({
      href:hostedShellConfig?.profile?.editProfileLink || '',
      activeOrg
    })
  );
}

// check for the active org in localStorage, then default to the first org
export function getActiveOrgId(state) {
  const activeOrgIdFromLocalStorage = getLocalStorageItem(ACTIVE_ORG_LOCALSTORAGE_KEY);
  const organizations = getOrganizations(state);
  const organizationsFromImsProfile = getLocalShellImsOrgsFromState(state);
  const firstOrgIdFromApi = Object.keys(organizations || {})[0];
  const firstOrgIdFromImsProfile = Object.keys(organizationsFromImsProfile || {})[0];
  const activeOrgIdFromLocalStorageIsValid = (
    Object.keys(organizations).includes(activeOrgIdFromLocalStorage) ||
    Object.keys(organizationsFromImsProfile).includes(activeOrgIdFromLocalStorage)
  );
  const routeParams = getCurrentRouteParamsFromState(state);

  // For users with the manage_system or read_system rights, the current company
  // may not match shell's active org. We will use the company's org id instead of
  // the Shell active org id. This allows company-scoped features to work
  // properly including search (quick search field and overview page recent activity)
  // and private extensions.
  let companyOrgId;
  if (routeParams?.company) {
    const company = getCompanyFromState(state);
    companyOrgId = company?.attributes?.orgId;
  }

  // Loading the profile happens very first thing. When doing so, the
  // `x-gw-ims-org-id` header will be empty because the list of organizations
  // hasn't been loaded yet (it comes from the response of the profile
  // endpoint). In this case we still want to send the activeOrgId if we
  // can get it from the imsProfile (from imslib) or localStorage.

  // Always prioritize company's orgId if it's present.
  // Prioritize localStorage if it's valid.
  // Otherwise, prioritize API (if any orgs are loaded)
  let orgId;
  if (companyOrgId) {
    orgId = companyOrgId;
  } else if (activeOrgIdFromLocalStorageIsValid) {
    orgId = activeOrgIdFromLocalStorage || firstOrgIdFromApi || firstOrgIdFromImsProfile;
  } else {
    orgId = firstOrgIdFromApi || firstOrgIdFromImsProfile || activeOrgIdFromLocalStorage;
  }

  return orgId;
}

export function getActiveOrg(state) {
  const organizations = getOrganizations(state);
  const activeOrgId = getActiveOrgId(state);
  const activeOrg = organizations[activeOrgId] ? {
    id: activeOrgId,
    ...organizations[activeOrgId]
  } : null;

  return activeOrg;
}

export function getShellData(state) {
  const profile = state.api?.profile?.attributes || {};
  const organizations = getOrganizations(state);
  const activeOrg = getActiveOrg(state);
  const imsEcConfig = state.ims.imsEcConfig;

  const shellData = {
    currentActiveOrg: activeOrg ? {
      tenantId: activeOrg.tenantId,
      imsOrgId: activeOrg.id,
      imsOrgName: activeOrg.name
    } : null,
    imsOrgs: Object.keys(organizations).map(organizationKey=>{
      const organization = organizations[organizationKey];
      return {
        tenantId: organization.tenantId,
        imsOrgId: organizationKey,
        imsOrgName: organization.name
      };
    }),
    profileComponent: {
      userName: profile.displayName,
      // if userAvatarUrl ever fails to load, it will go into an error state
      // and only render the green circle, so it's important that this value is
      // available before we ever render shell the first time
      userAvatarUrl: state.ims?.profileImageUrl,
      userTitle: profile.jobFunction,
      editProfileLink: `https://${imsEcConfig.ecHost}/profile`
    }
  };

  return shellData;
}

export function getActiveCapabilities(state) {
  const capabilities = [CAPABILITY_TYPES.TAGS];

  // the feature flags return strings with boolean values inside the strings
  if (state.globals?.featureFlags?.[CAPABILITY_FEATURE_FLAGS.APP_CONFIGURATIONS] === 'true') {
    capabilities.push(CAPABILITY_TYPES.APP_CONFIGURATIONS);
  }
  if (state.globals?.featureFlags?.[CAPABILITY_FEATURE_FLAGS.APP_SURFACES] === 'true') {
    capabilities.push(CAPABILITY_TYPES.APP_SURFACES);
  }
  if (state.globals?.featureFlags?.[CAPABILITY_FEATURE_FLAGS.EVENT_FORWARDING] === 'true') {
    capabilities.push(CAPABILITY_TYPES.EVENT_FORWARDING);
    capabilities.push(CAPABILITY_TYPES.MONITORING);
  }

  return capabilities;
}
