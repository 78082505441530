/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

/**
 * These rights functions translate high-level user rights that the API sends to us
 * into more granular rights we can efficiently use within Lens. For example, if we
 * need to enable the notes feature in the UI, rather than check to see if the
 * user has the develop OR approve OR publish right, we can just check to see if they
 * have the addNoteToLibrary right.
 **/

import {isEqual} from 'lodash-es';
import Immutable from 'seamless-immutable';
import { getCompanyFromState } from '../layouts/company/companySelectors';


export function getProfileRights(profile) {
  const rights = profile?.meta?.rights;
  if (!Array.isArray(rights)) { return {}; }

  const profileRights = {
    manageSystem: rights.includes('manage_system'), // not yet serialized to the endpoint but probably should be
    readSystem: rights.includes('read_system'), // not yet serialized to the endpoint but probably should be
    manageCompanies: rights.includes('manage_companies'), // derived from the manage_system right in Blacksmith
    manageExtensionPackages: rights.includes('manage_extension_packages'),
  };

  return profileRights;
}

export function getCompanyRights(company) {
  const rights = company?.meta?.platformRights;
  if (!rights) { return {}; }

  let companyRights = {
    managePropertiesOfAtLeastOnePlatformType: false,
    // manageAppConfigurations shows up in platform_rights (which are tied to properties, but this particular right is only a company-level right)
    manageAppConfigurations: company?.meta?.rights?.includes('manage_app_configurations'),
    manageAppSurfaces: company?.meta?.rights?.includes('manage_app_surfaces')
  };
  if (typeof rights === 'object') {
    Object.keys(rights).forEach(platformKey=>{
      const platformRights = rights[platformKey];
      const hasManagePropertiesRight = Array.isArray(platformRights) && platformRights.includes('manage_properties');
      companyRights.managePropertiesOfAtLeastOnePlatformType = (
        hasManagePropertiesRight ? true : companyRights.managePropertiesOfAtLeastOnePlatformType
      );
      companyRights[platformKey] = {
        manageProperties: hasManagePropertiesRight,
        developExtensions: Array.isArray(platformRights) && platformRights.includes('develop_extensions') // This right doesn't currently affect the UI.
      };
    });
  }

  return companyRights;
}

export function getPropertyRights(property) {
  const rights = property?.meta?.rights;
  if (!Array.isArray(rights)) { return {}; }

  const hasDevelopRight = rights.includes('develop');
  const hasApproveRight = rights.includes('approve');
  const hasPublishRight = rights.includes('publish');
  const hasManageExtensionsRight = rights.includes('manage_extensions');
  const hasManageEnvironmentsRight = rights.includes('manage_environments');

  const propertyRights = {
    manageRules: hasDevelopRight,
    manageDataElements: hasDevelopRight,
    manageDevLibraries: hasDevelopRight,
    createDevBuild: hasDevelopRight,
    submitLibraryForApproval: hasDevelopRight,
    rejectApprovalPendingLibrary: hasDevelopRight || hasApproveRight,
    rejectApprovedLibrary: hasDevelopRight || hasApproveRight || hasPublishRight,
    createStagingBuild: hasApproveRight,
    approveLibrary: hasApproveRight,
    publishLibrary: hasPublishRight,
    manageExtensions: hasManageExtensionsRight,
    manageEnvironments: hasManageEnvironmentsRight,
    manageHosts: hasManageEnvironmentsRight,
    // TODO: remove this once the feature is available to everyone
    // this is implemented via a feature flag which blacksmith serializes to the endpoint
    oauth2_google: rights.includes('oauth2_google')
  };

  return propertyRights;
}


let cachedRights = {};
export function getRights(state) {
  const rights = {
    ...getProfileRights(state.api.profile),
    ...getCompanyRights(getCompanyFromState(state)),
    ...getPropertyRights(state.api.property)
  };

  // this caching is important! it prevents unnecessary rerenders all throughout the app
  if (!isEqual(rights, cachedRights)) {
    cachedRights = Immutable(rights);
  }

  return cachedRights;
}
