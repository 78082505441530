/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe Systems Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe Systems Incorporated and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Adobe Systems Incorporated and its
 * suppliers and are protected by all applicable intellectual property
 * laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe Systems Incorporated.
 **************************************************************************/
import Immutable from 'seamless-immutable';
import { isEqual } from 'lodash-es';

export const SECRET_TYPE_OAUTH2 = 'oauth2-client_credentials';
export const SECRET_TYPE_HTTP = 'simple-http';
export const SECRET_TYPE_TOKEN = 'token';
export const SECRET_TYPE_OAUTH2_GOOGLE = 'oauth2-google';
export const MINIMUM_OAUTH2_REFRESH_OFFSET = 14400; // 4 hours
export const MAXIMUM_OAUTH2_REFRESH_OFFSET = 315360000; // 10 years

export function getFriendlyNameForSecretType(type) {
  if (type === SECRET_TYPE_OAUTH2) {
    return 'OAuth 2';
  } else if (type === SECRET_TYPE_HTTP) {
    return 'Simple HTTP';
  } else if (type === SECRET_TYPE_TOKEN) {
    return 'Token';
  } else if (type === SECRET_TYPE_OAUTH2_GOOGLE) {
    return 'Google OAuth 2';
  }
}

export function stringValuesArePresentAndEqual(val1, val2) {
  return Boolean(
    typeof val1 === 'string' &&
    val1.length &&
    typeof val2 === 'string' &&
    val2.length &&
    val1 === val2
  );
}

export function checkIfSecretRequiresCredentialPhrase({ isNew, secret, formValues }) {
  return Boolean(
    isNew ||
    !stringValuesArePresentAndEqual(secret?.attributes?.typeOf, formValues?.typeOf) // user has changed the type of the saved secret
  );
}

export function checkIfSecretCredentialsDirty({ isNew, secret, formValues }) {
  if (
    isNew ||
    !stringValuesArePresentAndEqual(secret?.attributes?.typeOf, formValues?.typeOf) || // user has changed the type of the saved secret
    !secret?.attributes?.credentials ||
    !formValues?.credentials
  ) {
    return true;
  }

  let areCredentialsEqual;

  // these are !isNew secrets at this point
  if (secret.attributes.typeOf === SECRET_TYPE_OAUTH2) {
    const fromDatabase = Immutable({...secret.attributes.credentials}).without('clientSecret'); // remove any client secret that may ever be in the secret

    let fromFormValues = Immutable(formValues?.credentials || {});
    fromFormValues = fromFormValues.set('refreshOffset', parseInt(fromFormValues?.refreshOffset, 10)); // the database refreshOffset is a number
    const formValueOptionsAsDatabaseShape = (formValues?.credentials?.options || []).reduce((opts, {key, value}) => {
      if (key?.length || value?.length) {
        return {
          ...opts,
          [key]: value
        };
      }
      return opts;
    }, {});

    if (
      // options were never saved before, and we didn't find any filled out values for options on the form
      !fromDatabase?.credentials?.options && !Object.keys(formValueOptionsAsDatabaseShape).length
    ) {
      fromFormValues = fromFormValues.without('options');
    } else {
      fromFormValues = fromFormValues.set('options', formValueOptionsAsDatabaseShape);
    }
    areCredentialsEqual = Boolean(
      (!fromFormValues?.credentials?.clientSecret?.length) &&
      isEqual(fromDatabase, fromFormValues)
    );
  } else if (secret.attributes.typeOf === SECRET_TYPE_HTTP) {
    areCredentialsEqual = Boolean(
      stringValuesArePresentAndEqual(
        secret.attributes.credentials.username,
        formValues.credentials.username
      ) &&
      !formValues.credentials.password?.length
    );
  } else {
    areCredentialsEqual = !formValues.credentials.token?.length;
  }

  return !areCredentialsEqual;
}

export function refreshOffsetSecondsToFriendlyTime(refreshOffsetString) {
  const refreshOffset = parseInt(refreshOffsetString, 10);

  if (isNaN(refreshOffset)) {
    return '0 hours, 0 minutes';
  }

  const toYears = (seconds) => seconds / 3600 / 24 / 365;
  const toMonths = (seconds) => seconds / 3600 / 24 / (365 / 12);
  const toDays = (seconds) => seconds / 3600 / 24;
  const toHours = (seconds) => seconds / 3600;
  const toMinutes = (seconds) => seconds / 60;

  if (Math.floor(toYears(refreshOffset)) >= 1) {
    const yearsRounded = Math.floor(toYears(refreshOffset));
    const monthsRounded = Math.ceil((toYears(refreshOffset) - yearsRounded) * 12); // big numbers, round up
    return (`${yearsRounded} ${yearsRounded === 1 ? 'year' : 'years'}, ${monthsRounded} ${monthsRounded === 1 ? 'month' : 'months'}`);
  } else if (Math.floor(toMonths(refreshOffset)) >= 1) {
    const monthsRounded = Math.floor(toMonths(refreshOffset));
    const daysRounded = Math.ceil((toMonths(refreshOffset) - monthsRounded) * (365 / 12)); // big numbers, round up
    return (`${monthsRounded} ${monthsRounded === 1 ? 'month' : 'months'}, ${daysRounded} ${daysRounded === 1 ? 'day' : 'days'}`);
  } else if (Math.floor(toDays(refreshOffset)) >= 1) {
    const daysRounded = Math.floor(toDays(refreshOffset));
    const hoursRounded = Math.ceil((toDays(refreshOffset) - daysRounded) * 24); // big numbers, round up
    return `${daysRounded} ${daysRounded === 1 ? 'day' : 'days'}, ${hoursRounded} ${hoursRounded === 1 ? 'hour' : 'hours'}`;
  } else if (toHours(refreshOffset) >= 1) {
    const hoursRounded = Math.floor(toHours(refreshOffset));
    const minutesRounded = Math.floor((toHours(refreshOffset) - hoursRounded) * 60); // more precise, round down
    return `${hoursRounded} ${hoursRounded === 1 ? 'hour' : 'hours'}, ${minutesRounded} ${minutesRounded === 1 ? 'minute' : 'minutes'}`;
  } else {
    const minutesRounded = Math.floor(toMinutes(refreshOffset));
    const secondsRounded = Math.floor((toMinutes(refreshOffset) - minutesRounded) * 60);  // more precise, round down
    return `${minutesRounded} ${minutesRounded === 1 ? 'minute' : 'minutes'}, ${secondsRounded} ${secondsRounded === 1 ? 'second' : 'seconds'}`;
  }
}
