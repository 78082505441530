/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import actionsHandler from '../../redux/actionsHandler';
import Immutable from 'seamless-immutable';

export const SET_SEARCH_REQUEST_BODY_DATA = 'search/SET_SEARCH_REQUEST_BODY_DATA';
export const SET_SEARCH_VALUE = 'search/SET_SEARCH_VALUE';


export default actionsHandler({
  [SET_SEARCH_VALUE]: (state, {payload:{value, stateKey}})=> {
    return state.setIn([stateKey, 'searchValue'], value);
  },
  [SET_SEARCH_REQUEST_BODY_DATA]: (state, {payload:{bodyData, stateKey}})=> {
    return state.setIn([stateKey, 'searchBody'], bodyData);
  },
  default:(state = Immutable({}))=> {
    return state;
  }
});

export let actionCreators = {
  setSearchValue(value, stateKey) {
    return {
      type: SET_SEARCH_VALUE,
      payload: {value, stateKey}
    };
  },
  setSearchRequestBodyData(bodyData, stateKey) {
    return {
      type: SET_SEARCH_REQUEST_BODY_DATA,
      payload: {bodyData, stateKey}
    };
  }
};
