/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import Immutable from 'seamless-immutable';
import actionsHandler from '../../redux/actionsHandler';
import {actionCreators as apiActions} from '../../utils/api/apiActions';
import {getActiveOrgId} from '../header/shellSelectors';
import {
  parseJWT,
  populateImsMacConfigFromBlacksmithProfile
} from './imsWrapperUtils';
import {actionCreators as shellActions} from '../header/shellActions';
import { setSessionStorageItem } from '../../utils/storageUtils';

export const MANUAL_SIGNIN_KEY = 'manualSignInData';

export const SET_IMS_DATA = 'imsWrapper/SET_IMS_DATA';
export const SET_IMS_ERROR = 'imsWrapper/SET_IMS_ERROR';
export const SET_SOLUTION_CONFIG = 'imsWrapper/SET_SOLUTION_CONFIG';


//Reducers
export default actionsHandler({
  [SET_IMS_DATA]: (state, {payload:{imsData}})=> {
    const imsUserId = imsData.imsAccessToken && parseJWT(imsData.imsAccessToken)?.user_id || null;
    return state.merge({
      ...imsData,
      imsUserId
    });
  },
  [SET_IMS_ERROR]: (state, {payload:{imsError}})=> {
    return state.merge({imsError});
  },
  [SET_SOLUTION_CONFIG]: (state, {payload:{profile}})=> {
    const solutionConfig = populateImsMacConfigFromBlacksmithProfile(
      state,
      profile
    );
    const imsHost = state.imsEcConfig.imsHost;
    const avatarPath = solutionConfig.urls.avatarPath;
    return state.merge({
      solutionConfig,
      profile,
      profileImageUrl: `//${imsHost}${avatarPath}/${state.imsUserId}`
    });
  },
  default:(state = Immutable({}))=> {
    return state;
  }
});


//Action Creators creator
export const actionCreators = {
  getProfile(logOutOnError) {
    return (dispatch, getState)=> {
      return dispatch(apiActions.apiAction({
        name: 'getProfile',
      })).then(()=>{
        const state = getState();
        const profile = state.api.profile;
        const result = {
          apiUnauthorized: state.globals.apiUnauthorized,
          profile
        };

        dispatch(actionCreators.setSolutionConfig(profile));

        // ensure the active org is set on the session
        const activeOrgId = getActiveOrgId(state);
        dispatch(shellActions.setActiveOrg(activeOrgId));

        return result;
      }).catch((error)=>{
        const isImsSessionError = error?.networkResponse?.body?.errors?.[0]?.code === 'ims-session-error';
        console.warn(error);
        if (logOutOnError || isImsSessionError) {
          dispatch(shellActions.logout());
        }
        return {
          apiUnauthorized: getState().globals.apiUnauthorized
        };
      });
    };
  },
  setImsError(imsError) {
    return {
      type: SET_IMS_ERROR,
      payload: { imsError }
    };
  },
  setSolutionConfig(profile) {
    return {
      type: SET_SOLUTION_CONFIG,
      payload: { profile }
    };
  },
  setIMSData(imsData, persistToSessionStorage) {
    // if manually signed in, persisting to sessionStorage will
    // mimic the behavior of imsLib.js
    if (persistToSessionStorage) {
      setSessionStorageItem(MANUAL_SIGNIN_KEY, {
        access_token: imsData.imsAccessToken,
        expiration: new Date().getTime() + imsData.expiresIn
      });
    }

    return {
      type: SET_IMS_DATA,
      payload: {
        imsData
      }
    };
  }
};
