/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe Systems Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe Systems Incorporated and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Adobe Systems Incorporated and its
 * suppliers and are protected by all applicable intellectual property
 * laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe Systems Incorporated.
 **************************************************************************/

import {actionCreators as apiActions} from '../../../utils/api/apiActions';
import { getCompanyFromState } from './companySelectors';

export const COMPANY_LOADER_STATE_KEY = 'companyLoader';
import actionsHandler from '../../../redux/actionsHandler';
import Immutable from 'seamless-immutable';
import {actionCreators as shellActions} from '../../header/shellActions';
import { getActiveOrgId } from '../../header/shellSelectors';


export const SET_COMPANY_AUTHORIZED = 'company/SET_COMPANY_AUTHORIZED';
export const SET_COMPANY_MATCHING_ACTIVE_ORG = 'company/SET_COMPANY_MATCHING_ACTIVE_ORG';
export const SET_COMPANY_NEEDS_REFRESH = 'company/SET_COMPANY_NEEDS_REFRESH';

//Reducers
export default actionsHandler({
  [SET_COMPANY_AUTHORIZED](state, { payload:{companyAuthorized} }) {
    return state.merge({companyAuthorized});
  },
  [SET_COMPANY_MATCHING_ACTIVE_ORG](state, { payload:{companyMatchingActiveOrg} }) {
    return state.merge({companyMatchingActiveOrg});
  },
  [SET_COMPANY_NEEDS_REFRESH](state, { payload: {companyNeedsRefresh} }) {
    return state.merge({companyNeedsRefresh});
  },
  default(state = Immutable({
    companyAuthorized: true,
    companyMatchingActiveOrg: undefined,
    companyNeedsRefresh: false
  })) {
    return state;
  }
});

//Action Creators
export let actionCreators = {
  loadCompany(params) {
    return (dispatch, getState) => {
      dispatch(actionCreators.setCompanyAuthorized(true));

      return dispatch(apiActions.apiAction({
        name: 'getCompany',
        urlData: params,
        bypassError: true,
        stateKey: COMPANY_LOADER_STATE_KEY
      })).then(() => {
        dispatch(actionCreators.setCompanyNeedsRefresh(false));
        return getCompanyFromState(getState());
      }).catch(async(error)=>{
        dispatch(actionCreators.setCompanyNeedsRefresh(false));
        if (error?.networkResponse?.status === 403) {
          // try to find the company matching the active org
          const activeOrgId = getActiveOrgId(getState());
          return dispatch(shellActions.getCompanyByOrgId(params, activeOrgId)).then(companyMatchingOrg=>{
            dispatch(actionCreators.setCompanyAuthorized(false));
            if (companyMatchingOrg) {
              dispatch(actionCreators.setCompanyMatchingActiveOrg(companyMatchingOrg));
            }
          }).catch(()=>{
            dispatch(actionCreators.setCompanyAuthorized(false));
          });
        } else {
          throw error;
        }
      });
    };
  },
  setCompanyAuthorized(companyAuthorized) {
    return {
      type: SET_COMPANY_AUTHORIZED,
      payload: {companyAuthorized}
    };
  },
  setCompanyMatchingActiveOrg(companyMatchingActiveOrg) {
    return {
      type: SET_COMPANY_MATCHING_ACTIVE_ORG,
      payload: {companyMatchingActiveOrg}
    };
  },
  setCompanyNeedsRefresh(companyNeedsRefresh = false) {
    return {
      type: SET_COMPANY_NEEDS_REFRESH,
      payload: {companyNeedsRefresh}
    };
  }
};
