/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import Immutable from 'seamless-immutable';
import {libraryTypes} from './publishingActions';
import {
  DATA_ELEMENTS,
  RULES,
  EXTENSIONS
} from '../../../utils/api/apiTypes';
import {get} from 'lodash-es';


export function getLibraryStatus(library) {
  let libraryStatus = ((library.meta.buildStatus === libraryTypes.buildStatus.SUCCESS
    || library.attributes.state === libraryTypes.states.PUBLISHED)
    && !library.attributes.buildRequired) ?
    'positive' : 'notice';
  if (library.attributes.state === libraryTypes.states.PUBLISHED && !library?.meta?.currentlyLive) {
    libraryStatus = 'neutral';
  }
  if (library.attributes.state === libraryTypes.states.REJECTED ||
    library.meta.buildStatus === libraryTypes.buildStatus.FAILED) {
    libraryStatus = 'negative';
  }
  return libraryStatus;
}

export function getLibraryTooltipMessage(library) {
  const {
    meta: { buildStatus, buildRequiredDetail },
    attributes: { state, buildRequired }
  } = library;

  // because we can can - CoSpence must aprove changes to this!
  let sTooltipMessage = buildRequiredDetail;

  if (buildStatus === libraryTypes.buildStatus.PENDING) {
    sTooltipMessage = 'Building library...';
  } else if (state === libraryTypes.states.REJECTED) {
    sTooltipMessage = 'Changes were rejected: review and rebuild required';
  } else if (buildStatus === libraryTypes.buildStatus.FAILED) {
    sTooltipMessage = buildRequiredDetail;
  } else if (buildStatus === libraryTypes.buildStatus.SUCCESS) {
    if (buildRequired) {
      // changes upstream can invalidate last build
      sTooltipMessage = 'Build required';
    } else {
      sTooltipMessage = 'Library has a successful build';
    }
  }
  return sTooltipMessage;
}

export function getLibraryLocation(params, library, resourceId) {
  const libraryLocation = {
    name: 'editLibrary',
    params: {
      ...params,
      library: library?.id
    }
  };

  return resourceId ? {
    ...libraryLocation,
    hash: resourceId
  } : libraryLocation;
};

export function getPropertyLocation(params, property) {
  return {
    name: 'propertyOverview',
    params: {
      ...params,
      property: property?.id
    }
  };
}

export function getLibraryEnvironment(library, environments) {
  const attachedEnvironment = get(library, ['relationships', 'environment', 'data']);

  if (environments && attachedEnvironment) {
    return environments.find((environment)=>{
      return environment.id === attachedEnvironment.id;
    });
  } else {
    return attachedEnvironment;
  }
};

export function getEnvironmentLocation(params, environment) {
  return {
    name: 'editEnvironment',
    params: {
      ...params,
      environment: environment && environment.id
    }
  };
};

export function addLocationToDelegates(delegates, params) {
  return delegates.map((delegate) => {
    if (!delegate.isBlank) {
      if (delegate.type === 'extensions') {
        return {
          ...delegate,
          location: {
            name: 'editExtension',
            params: {
              ...params,
              extension: delegate.id
            }
          }
        };
      } else if (delegate.type === 'dataElements') {
        return {
          ...delegate,
          location: {
            name: 'editDataElement',
            params: {
              ...params,
              dataElement: delegate.id
            }
          }
        };
      } else if (delegate.type === 'rules') {
        return {
          ...delegate,
          location: {
            name: 'editRule',
            params: {
              ...params,
              rule: delegate.id
            }
          }
        };
      }
    }

    return delegate;
  });
}

export function getNewLibrary() {
  return {
    meta:{},
    attributes:{ state: libraryTypes.states.DEVELOPMENT },
    relationships:{}
  };
}

export function addWarningStatesToDelegates(delegates, library, libraries) {
  if (delegates && library && libraries) {
    return delegates.map((delegate)=>{
      return addWarningStatesToDelegate(delegate, library, libraries);
    });
  }

  return delegates;
}
export function addWarningStatesToDelegate(delegate, library, libraries) {
  let inUseUpstream = false;
  let inOtherDevLibrary = false;

  if (delegate && library && libraries && !delegate.isBlank) {
    // linked libraries only contain minimal data so we need to find the
    // full associated library
    let relatedLibraries = get(delegate, 'relationships.libraries.data');
    if (relatedLibraries) {
      relatedLibraries = relatedLibraries.map((relatedLibrary)=>{
        return libraries.find((_library)=>{
          return _library.id === relatedLibrary.id;
        }) || null;
      }).filter(library => library !== null);
    }

    if (relatedLibraries) {
      relatedLibraries.forEach((relatedLibrary)=>{
        const isCurrentLibrary = (library.id === relatedLibrary.id);
        if (!isCurrentLibrary) {
          const librariesBothInDev = (
            library.attributes.state === libraryTypes.states.DEVELOPMENT
            && relatedLibrary.attributes.state === libraryTypes.states.DEVELOPMENT
          );
          const libraryIsNotPublished = (
            library.attributes.state !== libraryTypes.states.PUBLISHED
          );

          if (librariesBothInDev) { inOtherDevLibrary = true; }
          if (libraryIsNotPublished
            && isLibraryUpstream(library, relatedLibrary)) {
            inUseUpstream = true;
          }
        }
      });
    }
  }

  return {
    ...delegate,
    warnings: {
      inUseUpstream,
      inOtherDevLibrary
    }
  };
}

// this should probably be done server-side!
export function libraryHasUpstreamChanges(library, libraries) {
  if (!library || !libraries) { return false; }

  if (library.attributes.state === libraryTypes.states.DEVELOPMENT) {
    return libraries.some((library)=>{
      return (
        library.attributes.state === libraryTypes.states.SUBMITTED ||
        library.attributes.state === libraryTypes.states.APPROVED
      );
    });
  } else if (library.attributes.state === libraryTypes.states.SUBMITTED) {
    return libraries.some((library)=>{
      return library.attributes.state === libraryTypes.states.APPROVED;
    });
  } else {
    return false;
  }
}

export function getFirstUpstreamLibrary(library, libraries) {
  if (!library || !libraries) { return; }

  let upstreamLibrary;
  const upstreamStates = getLibraryUpstreamStates(library);
  upstreamStates.forEach((upstreamState)=>{
    if (!upstreamLibrary) {
      upstreamLibrary = libraries.find((library)=>{
        return library.attributes.state === upstreamState;
      });
    }
  });
  return upstreamLibrary;
}

export function isLibraryUpstream(library, upstreamLibrary) {
  if (!library || !upstreamLibrary) { return false; }

  const upstreamStates = getLibraryUpstreamStates(library);
  return upstreamStates.includes(upstreamLibrary.attributes.state);
}

export function getLibraryUpstreamStates(library) {
  let upstreamStates = [];

  if (library) {
    if (library.attributes.state === libraryTypes.states.DEVELOPMENT) {
      upstreamStates.push(libraryTypes.states.SUBMITTED);
      upstreamStates.push(libraryTypes.states.APPROVED);
      upstreamStates.push(libraryTypes.states.PUBLISHED);
    } else if (library.attributes.state === libraryTypes.states.SUBMITTED) {
      upstreamStates.push(libraryTypes.states.APPROVED);
      upstreamStates.push(libraryTypes.states.PUBLISHED);
    } else if (library.attributes.state === libraryTypes.states.APPROVED) {
      upstreamStates.push(libraryTypes.states.PUBLISHED);
    }
  }

  return upstreamStates;
}

export function getSortedFilteredLibrariesByState(libraries, libraryState, textFilter = null) {
  let regexFilter;
  if (textFilter) {
    regexFilter = new RegExp(textFilter, 'i');
  }

  const sortBy = libraryState === libraryTypes.states.PUBLISHED ? 'publishedAt' : 'updatedAt';
  if (libraries) {
    // sort requires libraries to be mutable.
    libraries = libraries.asMutable().sort((a, b)=>{
      return new Date(b.attributes[sortBy]) - new Date(a.attributes[sortBy]);
    }).filter((library)=>{
      // libraryState can be a string or array of strings
      let status = library.attributes.state === libraryState ||
        (libraryState.push && libraryState.includes(library.attributes.state));
      if (status && regexFilter) {
        status = library.attributes.name.match(regexFilter);
      }

      return status;
    });
  }

  return Immutable(libraries || []);
};

// expects saveData with selectedDelegates and/or an environment
export function deNormalizeLibraryData(saveData) {
  let normalData = {
    data: {
      type: 'libraries'
    }
  };

  // Why is this here? You can't update an id can you?
  // yet this property is required by the API
  if (saveData.id) {
    normalData.data.id = saveData.id;
  }

  if (saveData.name) {
    normalData.data.attributes = { name: saveData.name };
  }

  // don't add this stuff when saving the environment only
  if (saveData?.selectedDelegates) {
    // selectedDelegates is the source of truth for what resources belong to the
    // library. If these lists remain empty, it's because you want to wipe out their
    // data.
    const delegateResourcesByType = {
      [DATA_ELEMENTS]: {
        data: []
      },
      [RULES]: {
        data: []
      },
      [EXTENSIONS]: {
        data: []
      }
    };

    saveData.selectedDelegates.forEach(delegate => {
      let typeBackup = RULES;
      if (delegate.id.substring(0, 2) === 'EX') {
        typeBackup = EXTENSIONS;
      } else if (delegate.id.substring(0, 2) === 'DE') {
        typeBackup = DATA_ELEMENTS;
      }

      const resource = {
        id: delegate.id,
        type: typeBackup
        //the api is ignoring the data case camel for this.
        //So we cant currently use delegate.type
      };

      if (delegate?.attributes?.revisionNumber === 0) {
        resource.meta = {
          action: 'revise'
        };
      }

      delegateResourcesByType[typeBackup].data.push(resource);
    });

    normalData.data.relationships = delegateResourcesByType;
  }

  if (saveData.selectedEnvironment || saveData.selectedEnvironment === null) {
    // ensure that relationships exists before updating it
    normalData.data.relationships = normalData.data.relationships || {};
    // update the enfironment
    normalData.data.relationships.environment = {
      data: saveData.selectedEnvironment ? {
        id: saveData.selectedEnvironment,
        type: 'environments'
      } : null
    };
  }

  return normalData;
}

// expects a resourceData collection with the following format:
// [
//   {parentResource, resource, resourceUpdatedWithExtensionId, resourceExtensionId},
//   ...
// ]
// returns a collection uniqued by parentResource
export function getUniqRequiredResourceDataByParentResource(requiredResourceData) {
  // we'l unique the disabled extensions by parentResource and combine all the extensions
  // so that we can present all disabled extensions in a single tooltip
  const uniqDisabledExtensionsResourceData = [];
  requiredResourceData.forEach((requiredResourceDataItem)=>{
    // find all entries that use this resource
    const dataItemsByResource = requiredResourceData.filter(({parentResource})=>{
      return requiredResourceDataItem.parentResource.id === parentResource.id;
    });

    // copy the dataItem and extend it with requiredExtensions
    let newDataItem = {...dataItemsByResource[0]}; // there will always be at least 1
    newDataItem.requiredExtensions = [newDataItem.requiredExtension]; // add first required extension
    dataItemsByResource.forEach(dataItemByResource=>{
      if (!newDataItem.requiredExtensions.find(
        requiredExtension=>requiredExtension.id === dataItemByResource.requiredExtension.id
      )) {
        newDataItem.requiredExtensions.push(dataItemByResource.requiredExtension);
      }
    });

    // add it if we haven't already added it (unique it)
    if (!uniqDisabledExtensionsResourceData.find(
      uniqDataItem=>uniqDataItem.parentResource.id === requiredResourceDataItem.parentResource.id
    )) {
      uniqDisabledExtensionsResourceData.push(newDataItem);
    }
  });

  return uniqDisabledExtensionsResourceData;
}

export function isAnyLibraryEditRoute(route) {
  return (
    route?.name === 'editLibrary' ||
    route?.name === 'editLibraryRuleCompare' ||
    route?.name === 'editLibraryRuleComponentCompare' ||
    route?.name === 'editLibraryRuleComponentExtensionCompare' ||
    route?.name === 'editLibraryDataElementCompare' ||
    route?.name === 'editLibraryDataElementExtensionCompare' ||
    route?.name === 'editLibraryExtensionCompare'
  );
}
