/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/


import React, {Component} from 'react';
import {connect} from 'react-redux';
import Dialog from 'spectrum-alternatives/dialog/Dialog';
import Button from '@react/react-spectrum/Button';
import DialogHost from './dialogHost';
import {
  DIALOGS_STATE_KEY,
  UNSAVED_CHANGES_KEY,
  actionCreators
} from './confirmNavigationDialogActions';

class ConfirmNavigationDialog extends Component {
  render = () => {
    const {dispatch} = this.props;

    return (
      <DialogHost
        dialogsStateKey={DIALOGS_STATE_KEY}
        render={({dialogs})=>(
          <Dialog
            open={dialogs[UNSAVED_CHANGES_KEY]}
            variant="error"
          >
            <Dialog.Header>Unsaved Changes</Dialog.Header>
            <Dialog.Content>
              <p>
                Your unsaved changes will be lost. Are you sure you would like to leave this page?
              </p>
            </Dialog.Content>
            <Dialog.Footer>
              <Button
                quiet
                variant="primary"
                onClick={() => {
                  dispatch(actionCreators.cancel());
                }}
              >
                Don't Leave
              </Button>
              <Button
                variant="warning"
                onClick={() => {
                  dispatch(actionCreators.proceed());
                }}
              >
                Discard Changes
              </Button>
            </Dialog.Footer>
          </Dialog>
        )}
      />
    );
  };
}

export default connect()(ConfirmNavigationDialog);
