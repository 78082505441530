/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import { routes } from './routes';

// recursively checks if a route is a child of the parent
export function isRouteChildOf(parentRoute, childRoute) {
  if (!parentRoute || !childRoute) { return null; }

  let isChildRoute = false;
  if (parentRoute.subRoutes) {
    parentRoute.subRoutes.forEach((parentsChildRoute)=>{
      if (isChildRoute === true) { return; } // already found that it's a child

      isChildRoute = (parentsChildRoute.name === childRoute.name);
      if (!isChildRoute && parentsChildRoute.subRoutes) {
        // recurse children
        isChildRoute = isRouteChildOf(parentsChildRoute, childRoute);
      }
    });
  }

  return isChildRoute;
}

export function getParentRoute(route) {
  if (!route) { return null; }

  let parentRoute;

  // recursive child route finder
  function getParentRouteOfChildRoute(baseRoute, childRoute) {
    if (!baseRoute || !childRoute) { return null; }

    baseRoute.subRoutes?.forEach(subRoute=>{
      if (parentRoute) { return; }

      if (subRoute.name === childRoute.name) {
        parentRoute = baseRoute; // found the parent
      } else {
        getParentRouteOfChildRoute(subRoute, childRoute);
      }
    });
  }

  routes.forEach(_route=>{
    if (!parentRoute) {
      if (_route.name === route.name) {
        return null; // no parent because route in question is at the root
      }
      getParentRouteOfChildRoute(_route, route);
    }
  });

  return parentRoute;
}

export function getSelectedRouteIndex(routes, selectedTabRoute) {
  let currentIndex = 0;

  for (let i = 0; i < routes.length; i++) {
    const route = routes[i];
    if (route.hidden) {
      continue;
    }

    if (
      (selectedTabRoute.name === route.name || isRouteChildOf(selectedTabRoute, route))
    ) {
      return currentIndex;
    }

    currentIndex += 1;
  }

  return 0;
}

export function setLocationHash(hash) {
  if (hash) {
    // react-router listens to this hash change
    window.location.hash = hash;
  } else {
    // react-router is ignorant of a pushState hash removal so we'll fire a hashchange event first
    window.location.hash = '';

    // remove the remaining # from the url
    history.pushState(
      '',
      document.title,
      window.location.pathname + window.location.search
    );
  }
}
