/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import thunk from 'redux-thunk';
import {apiMiddleware} from '../utils/api/apiMiddleware';
import {routerMiddleware} from 'connected-react-router';
import allAppReducers from './allAppReducers';
import {compose, createStore, applyMiddleware} from 'redux';
import {stateTransformer} from 'redux-seamless-immutable';
import {addLogRocketMiddleware} from '../utils/logrocketUtils';

function getLogger(createLogger) {
  return createLogger({
    stateTransformer,
    diff: true, // if an error is thrown while diff is on you may get an unhelpful error
    collapsed: (getState, action, logEntry) => !logEntry.error
  });
};

function getMiddlewares(browserHistory, createLogger) {
  let middlewares = [
    apiMiddleware,
    thunk,
    routerMiddleware(browserHistory)
  ];

  if (createLogger) {
    middlewares.push(getLogger(createLogger));
  }
  addLogRocketMiddleware(middlewares);
  return middlewares;
}


export default (browserHistory, createLogger) => {
  const composeEnhancers = compose(
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  );

  const middlewares = getMiddlewares(browserHistory, createLogger);
  return composeEnhancers(
    applyMiddleware(...middlewares)
  )(createStore)(allAppReducers);
};
