/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import actionsHandler from '../../../redux/actionsHandler';
import {actionCreators as apiActions} from '../../../utils/api/apiActions';
import {push} from '../../../routes/namedRouteUtils';
import {cloneDeep} from 'lodash-es';
import Immutable from 'seamless-immutable';

export const CHANGE_STAGE = 'environmentEdit/CHANGE_STAGE';
export const CHANGE_HOST = 'environmentEdit/CHANGE_HOST';
export const RESET_ENVIRONMENT = 'environmentEdit/RESET_ENVIRONMENT';


//Reducers
export default actionsHandler({
  [CHANGE_STAGE](state, {payload: {newStage}}) {
    return state.set('stage', newStage);
  },
  [CHANGE_HOST](state, {payload: {newHost}}) {
    return state.set('host', newHost);
  },
  [RESET_ENVIRONMENT]() {
    return Immutable({});
  },
  default(state = Immutable({})) {
    return state;
  }
});

export const CREATE_ENVIRONMENT_API_NAME = 'createEnvironment';

export const DEFAULT_DEVELOPMENT_ENVIRONMENT = {
  name: 'Development',
  stage: 'development'
};

export const DEFAULT_STAGING_ENVIRONMENT = {
  name: 'Staging',
  stage: 'staging'
};

export const DEFAULT_PRODUCTION_ENVIRONMENT = {
  name: 'Production',
  stage: 'production'
};

//Action Creators
// TODO: this should be refactored and stateKeyed at the action creator level.
export let actionCreators = {
  loadEnvironments(params, stateKey) {
    return apiActions.apiAction({
      name: 'getEnvironments',
      urlData: {...params},
      stateKey
    });
  },
  loadEnvironment(params, stateKey) {
    return apiActions.apiAction({
      name: 'getEnvironment',
      urlData: {...params},
      stateKey
    });
  },
  loadEnvironmentSecrets(params, stateKey) {
    return apiActions.apiAction({
      name: 'getEnvironmentSecrets',
      urlData: {...params},
      stateKey,
      loadAllPages: true
    });
  },
  loadHosts(params) {
    return apiActions.apiAction({
      name: 'getHosts',
      urlData: {...params}
    });
  },
  createEnvironment(params, data) {
    return apiActions.apiAction({
      name: CREATE_ENVIRONMENT_API_NAME,
      urlData: {...params},
      data: data
    });
  },
  updateEnvironment(params, data) {
    let dataWithoutStage = cloneDeep(data);
    delete dataWithoutStage.data.attributes.stage;
    return apiActions.apiAction({
      name: 'updateEnvironment',
      urlData: {...params},
      data: dataWithoutStage
    });
  },
  changeStage(newStage) {
    return {
      type: CHANGE_STAGE,
      payload: {
        newStage
      }
    };
  },
  changeHost(newHost) {
    return {
      type: CHANGE_HOST,
      payload: {
        newHost
      }
    };
  },
  reset(stateKey) {
    return (dispatch) => {
      dispatch(apiActions.resetEndpoint('getEnvironmentSecrets', stateKey));
      dispatch({ type: RESET_ENVIRONMENT });
    };
  },
  goToEnvironments(params) {
    return push({ name: 'environments', params });
  },
  goToEnvironment(params) {
    return push({ name: 'editEnvironment', params });
  },
};
