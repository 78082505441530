/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import Checkbox from '@react/react-spectrum/Checkbox';
import ChevronDownIcon from '@react/react-spectrum/Icon/ChevronDown';
import ChevronRightIcon from '@react/react-spectrum/Icon/ChevronRight';
import CloseIcon from '@react/react-spectrum/Icon/Close';
import Button from '@react/react-spectrum/Button';
import Wait from '@react/react-spectrum/Wait';
import React, {Component} from 'react';
import {get} from 'lodash-es';
import classNames from 'classnames';

const DefaultHeaderCellRenderer = ({column, rendererData}) => {
  const combinedRendererData = {...rendererData, ...column.rendererData};

  let content;

  if (column.headerCellContent) {
    if (typeof column.headerCellContent === 'function') {
      content = <column.headerCellContent column={column} rendererData={combinedRendererData} />;
    } else {
      content = <span>{column.headerCellContent}</span>;
    }
  } else {
    content = <span>{column.key}</span>;
  }

  return (
    <th className={classNames('spectrum-Table-headCell', 'u-noWrap', column.headerCellClassName)}>
      {content}
    </th>
  );
};

const DefaultCellRenderer = ({
  column, rowData, rendererData, isRowHovered, isRowSelected, isRowExpanded
}) => {
  const combinedRendererData = {...rendererData, ...column.rendererData};

  let content;

  if (column.cellContent) {
    if (typeof column.cellContent === 'function') {
      content = (
        <column.cellContent
          isRowHovered={isRowHovered}
          isRowSelected={isRowSelected}
          isRowExpanded={isRowExpanded}
          column={column}
          rowData={rowData}
          rendererData={combinedRendererData}
        />
      );
    } else {
      content = <span data-private>{column.cellContent}</span>;
    }
  } else {
    content = <span data-private>{get(rowData, column.key)}</span>;
  }

  return (
    <td
      className={
        classNames(
          'spectrum-Table-cell',
          'u-breakInWords',
          column.cellClassName
        )
      }
    >
      {content}
    </td>
  );
};


/*
  props:
    loading: boolean
    moreItemsLoading: boolean
    hideHeader: [optional] - boolean
    rows: [required] - an array of objects
    columns: [optional] - array of objects {
      key:'',
      headerCellContent: string or component,
      headerCellClassName: string,
      headerCellRenderer: component (must render <th>)
      cellContent: string or component,
      cellClassName: string,
      cellRenderer: component (must render <td>)
      - specifies which columns to use and optionally renames their headers and renders their cells
    selectable: [optional] - boolean - make rows selectable?
    allowSelectAll: [optional] - boolean - defaults to true
    selectedRows: [optional] - an array of row objects
    onToggleRowSelection: function(row)
    onToggleAllRowsSelected: function()
    onChangeSelection: [optional] - selection change handler function(selectedRows)
    onRowClick: [optional] - row click handler function(row)
    expandOnRowClick: [optional] - boolean - makes row click expand row
    expandable: [optional] - boolean - make rows expandable
    expandedRowRenderer: [optional] - function(row) - must include <tr /> tag
    onExpandRow: [optional] - function(row)
    onCollapseRow: [optional] - function(Row)
    removable: [optional] - boolean - make rows removable
    rowIsRemovable: [optional] - function(row) - make row removable based on function result
    onRemoveRow: [optional] - function(row)
    areRowsEqual: [optional] - function(rowA, rowB) => boolean - are two different rows equal?
    rendererData: [optional] - extra data for cell renderers
    tableClassName: [optional] - string - class that will be applied to the underlying html table
*/
class CustomTable extends Component {
  static defaultProps = {
    onToggleRowSelection: null,
    onToggleAllRowsSelected: null,
    onRowClick: null,
    onCollapseRow: null,
    onExpandRow: null,
    areRowsEqual: (rowA, rowB) => {
      // We wouldn't have to do this if we required rows and selectedRows to be the same objects
      // in memory. Right now rows are normalized and selectedRows are not. :(
      return rowA?.id === rowB?.id;
    },
    rows: [],
    allowSelectAll: true,
    selectedRows: [],
    expandedRows: []
  };

  constructor(props) {
    super(props);
    this.state = {
      hoveredRow: null
    };
  }
  updateHoveredRow = (row) => {
    this.setState({ hoveredRow: row });
  };
  toggleRowExpanded = (row) => {
    let foundRow = this.props.expandedRows.find(_row => {
      return this.props.areRowsEqual(_row, row);
    });

    foundRow ? this.props.onCollapseRow?.(row) : this.props.onExpandRow?.(row);
  };

  isRowInRows = (rows, row) => {
    return Boolean(rows.find((_row)=>{
      return this.props.areRowsEqual(_row, row);
    }));
  };
  isRowExpanded = (row) => {
    return this.isRowInRows(this.props.expandedRows, row);
  };
  changeSelection = (row) => {
    row ? this.props.onToggleRowSelection?.(row) : this.props.onToggleAllRowsSelected?.();
  };
  areAllRowsSelected = () => {
    let selectedRowCount = 0;
    this.props.selectedRows.forEach((selectedRow)=>{
      this.props.rows.forEach((row)=>{
        if (this.props.areRowsEqual(selectedRow, row)) { selectedRowCount++; }
      });
    });
    return this.props.rows.length === selectedRowCount && selectedRowCount > 0;
  };
  isRowSelected = (row) => {
    return this.isRowInRows(this.props.selectedRows, row);
  };

  // uses passed-in columns or default to the keys of the first row
  getColumns = () => {
    let columns;
    if (this.props.columns) {
      columns = this.props.columns;
    } else if (this.props.rows && this.props.rows[0]) {
      // convert keys to column objects
      columns = Object.keys(this.props.rows[0]).map((key) => {
        return { key: key };
      });
    }
    return columns || [];
  };

  getColumnsWithUtils = () => {
    // shallow copy array so we don't modify column prop.
    let columns = this.getColumns().slice();
    if (this.props.expandable) {
      columns.unshift({
        key: 'expandContract',
        isUtilColumn: true
      });
    }
    if (this.props.selectable) {
      columns.unshift({
        key: 'select',
        isUtilColumn: true
      });
    }
    if (this.props.removable) {
      columns.push({
        key: 'remove',
        isUtilColumn: true
      });
    }
    return columns;
  };

  rowClickHandler = (row, event) => {
    if (event.target.tagName !== 'INPUT' && event.target.tagName !== 'A') {
      this.props.onRowClick?.(row, event);
      if (this.props.expandOnRowClick) {
        this.toggleRowExpanded(row);
      }
      if (this.props.selectable) {
        this.changeSelection?.(row);
      }
    }
  };

  // row and cell rendering functions
  getHeaderRow = () => {
    if (this.props.hideHeader) {
      return [];
    }

    let headerCells = this.getColumns().map((column, index) => {
      const HeaderCellRenderer = column.headerCellRenderer || DefaultHeaderCellRenderer;
      return <HeaderCellRenderer key={`column_${index}_${column.key}`} column={column}/>;
    });

    return (
      <thead className="spectrum-Table-head">
        <tr>
          {this.props.selectable ?
            <th
              className={classNames([
                'spectrum-Table-headCell',
                'selectableHeaderCell',
                'u-textCenter',
                'u-noPadding'
              ])}
            >
              {this.props.allowSelectAll ? (
                <Checkbox
                  className="selectableCheckbox u-noPadding"
                  checked={this.areAllRowsSelected()}
                  onChange={() => { this.changeSelection(); }}
                />
              ) : null}
            </th>
          : null}
          {this.props.expandable ?
            <th className="spectrum-Table-headCell expandableHeaderCell"></th>
          : null}

          {/* is-sortable is-sorted-desc */}
          {headerCells}

          {this.props.removable ? (
            <th className="spectrum-Table-headCell removableHeaderCell"></th>
          ) : null}
        </tr>
      </thead>
    );
  };

  getRows = (rows) => {
    let newRows = [];
    rows.forEach((row, index)=> {
      const rowIsRemovable = (
        !this.props.rowIsRemoveable
      ) || (
        this.props.rowIsRemoveable && this.props.rowIsRemoveable(row)
      );

      newRows.push((
        <tr
          key={`row_${index}_${row.id || 'noId'}`}
          data-resource-id={row.id}
          className={
            classNames('spectrum-Table-row', {
              'u-cursorDefault': (
                !this.props.selectable && !this.props.onRowClick && !this.props.expandOnRowClick
              )
            })
          }
          onClick={(e) => { this.rowClickHandler(row, e); }}
          onMouseEnter={(e) => { this.updateHoveredRow(row, e); }}
          onMouseLeave={(e) => { this.updateHoveredRow(null, e); }}
        >
          {this.props.selectable ?
            <td className="spectrum-Table-cell selectableCell u-noPadding u-textCenter">
              {/*
                the empty string for the checked value is a fix for react-coral
                switching the component between controlled and uncontrolled
              */}
              <Checkbox
                className="selectableCheckbox"
                checked={this.isRowSelected(row) ? 'checked' : ''}
                onChange={() => { this.changeSelection(row); }}
              />
            </td>
          : null}
          {this.props.expandable ?
            <td className="spectrum-Table-cell expandableCell u-textCenter u-verticalAlignMiddle">
              {
                this.isRowExpanded(row) ?
                <ChevronDownIcon size="S" className="expandedCellIcon"/> :
                <ChevronRightIcon size="S" className="expandedCellIcon"/>
              }
            </td>
          : null}

          {this.getCells(rows, row)}

          {this.props.removable ? (
            <td className="spectrum-Table-cell removableCell u-textCenter u-pointer">
              <div
                className="u-inlineBlock"
                onClick={rowIsRemovable ? (event)=>{
                  event.stopPropagation();
                  this.props.onRemoveRow && this.props.onRemoveRow(row);
                  // when removing the row, the next row isn't automatically hovered
                  // the mouseEnter never takes place since the mouse is already over
                  // the next row when this one gets removed. We'll fix that by marking
                  // the next row as hovered.
                  const nextRow = rows.length > index + 1 && rows[index + 1];
                  if (nextRow) { this.setState({ hoveredRow: nextRow }); }
                } : null}
                data-test-id="tableRemoveRowButton"
              >
                {rowIsRemovable ? (
                  <Button
                    quiet
                    variant="secondary"
                    className="u-iconButton"
                  >
                    <CloseIcon
                      size="S"
                      className="expandedCellIcon u-centerVertical"
                    />
                  </Button>
                ) : null}
              </div>
            </td>
          ) : null}
        </tr>
      ));

      const {expandable, expandedRowRenderer} = this.props;
      const isRowExpanded = this.isRowExpanded(row);
      if (expandable && isRowExpanded && expandedRowRenderer) {
        const ExpandedRowRenderer = this.props.expandedRowRenderer;
        const rendererData = this.props.rendererData;
        newRows.push(
          // This render must include the <tr /> tag
          <ExpandedRowRenderer
            columns={this.getColumnsWithUtils()}
            rowData={row}
            rows={rows}
            rendererData={rendererData}
            key={`row_${index}_${row.id || 'noId'}_expanded`}
          />
        );
      }
    });

    return newRows;
  };
  getCells = (rows, row) => {
    return this.getColumns().map((column, index) => {
      const CellRenderer = column.cellRenderer || DefaultCellRenderer;
      const rendererData = this.props.rendererData;
      return (
        <CellRenderer
          key={`column_${index}_${column.key}`}
          column={column}
          rowData={row}
          rows={rows}
          rendererData={rendererData}
          isRowHovered={(row === this.state.hoveredRow)}
          isRowSelected={this.isRowSelected(row)}
          isRowExpanded={this.isRowExpanded(row)}
        />
      );
    });
  };

  render = () => {
    if (!this.props.rows) { return null; }

    const headerRow = this.getHeaderRow();
    const rows = this.getRows(this.props.rows);

    return (
      <div
        className={classNames(
          'CustomTable u-relativePosition',
          {'hideHeader' : this.props.hideHeader},
          this.props.className
        )}
        data-test-id={this.props.testId}
      >
        <div
          className={classNames('tableLoader u-borderLt3 u-rounded', {'u-hidden':!this.props.loading})}
          data-test-id="listViewTableLoader"
        >
          <Wait centered/>
        </div>
        <table
          className={classNames(
            'spectrum-Table',
            'u-tableLayoutFixed',
            this.props.tableClassName
          )}
        >
          {headerRow}
          <tbody className="spectrum-Table-body">
            {rows}
            {this.props.moreItemsLoading ? (
              <tr className="spectrum-Table-row loaderRow">
                <td
                  className="spectrum-Table-cell loaderCell u-relativePosition"
                  colSpan={this.getColumnsWithUtils().length}
                >
                  <div className="u-padding">
                    <Wait centered/>
                  </div>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    );
  };
};

export default CustomTable;
