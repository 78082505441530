/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import Immutable from 'seamless-immutable';
import actionsHandler from '../../../redux/actionsHandler';
import {actionCreators as apiActions} from '../../../utils/api/apiActions';
import {
  actionCreators as extensionCacheBustActions,
  STATE_KEY as EXTENSION_CACHE_STATE_KEY
} from '../extensions/extensionCacheBustActions';
import {getFormValues, change} from 'redux-form';
import {push, replace} from '../../../routes/namedRouteUtils';
import {getApiData} from '../../../utils/api/apiTools';
import {
  getRevisionRange,
  getRevisionRangeParameterName
} from '../../compareView/compareViewUtils';
import {RULES} from '../../../utils/api/apiTypes';

export const STATE_KEY = 'ruleBuilder';
export const RULE_REVISIONS_STATEKEY = 'ruleBuilderRevisions';
export const RULE_BUILDER_FORM_NAME = 'ruleBuilderEditForm';

export const REINITIALIZE_RULE = 'ruleBuilder/REINITIALIZE_RULE';
export const CLEANUP_RULE = 'ruleBuilder/CLEANUP_RULE';
export const SET_SAVING = 'ruleBuilder/SET_SAVING';


let abortController;


const baseData = Immutable({
  rulesLoading: false,
  ruleDetails: {},
  delegateToEdit: null
});

//Reducers
export default actionsHandler({
  [REINITIALIZE_RULE](state = {}, action) {
    let {rule} = action.payload;
    let ruleData = baseData.set('ruleDetails', {
      name: rule.attributes.name,
      labels: rule.attributes.labels
    });

    return state.merge(ruleData, { deep: true });
  },
  [CLEANUP_RULE]() {
    return baseData;
  },
  [SET_SAVING](state, action) {
    return state.set('saving', action.payload.isSaving);
  },
  default: (state = {})=> {
    return state && state.get ? state : baseData;
  }
});

//Action Creators
export const actionCreators = {
  initialize() {
    return ()=>{
      abortController = new AbortController();
    };
  },
  goToRuleRevision(params, revisionId) {
    return (dispatch)=>{
      dispatch(push({
        name: 'editRule',
        params: { ...params, rule: revisionId }
      }));
    };
  },
  goToRuleCompareView({
    params,
    revisionRange,
    shouldReplaceRoute = false
  }) {
    return (dispatch, getState)=>{
      dispatch((shouldReplaceRoute ? replace : push)({
        name: 'editRuleCompare',
        params: {
          ...params,
          [getRevisionRangeParameterName(RULES)]: revisionRange ? revisionRange : getRevisionRange({
            rightRevisionId: getApiData(getState(), STATE_KEY).rule.id
          })
        }
      }));
    };
  },
  loadRule(params, ruleEditRuleComponentsActions) {
    return (dispatch, getState)=> {
      ruleEditRuleComponentsActions.setDefaultState();
      const extensionPromise = dispatch(extensionCacheBustActions.checkAndRefreshCache(params));

      if (params.rule === 'new') {
        return extensionPromise.then(()=>{
          const {extensions, delegateDescriptors} = getApiData(
            getState(),
            EXTENSION_CACHE_STATE_KEY
          );
          const loadedValues = Immutable({
            rule:{},
            ruleComponents: [],
            extensions,
            delegateDescriptors
          });
          ruleEditRuleComponentsActions.initialize(loadedValues.ruleComponents);
          return loadedValues;
        });
      } else {
        const rulePromise = dispatch(apiActions.apiAction({
          name:'getRule',
          stateKey: STATE_KEY,
          urlData: {...params},
          abortSignal: abortController.signal
        }));
        const ruleComponentsPromise = dispatch(apiActions.apiAction({
          name:'getRuleComponents',
          stateKey: STATE_KEY,
          urlData: {...params},
          abortSignal: abortController.signal
        }));

        return Promise.all([rulePromise, ruleComponentsPromise, extensionPromise]).then(()=> {
          const state = getState();
          const {rule, ruleComponents = Immutable([])} = getApiData(state, STATE_KEY);
          const {extensions, delegateDescriptors} = getApiData(state, EXTENSION_CACHE_STATE_KEY);

          ruleEditRuleComponentsActions.initialize(ruleComponents);
          dispatch({
            type: REINITIALIZE_RULE,
            payload: {
              rule
            }
          });

          return {
            rule,
            ruleComponents,
            extensions,
            delegateDescriptors
          };
        });
      }
    };
  },
  saveRule(rule, ruleDetails, params) {
    return (dispatch)=> {
      let isNew = params.rule === 'new';
      rule = rule && !isNew ? rule : Immutable({attributes: {}, type: 'rules'});
      rule = rule.merge({
        attributes: {
          ...ruleDetails,
          revision:false
        }
      }, { deep: true });
      return dispatch(apiActions.apiAction({
        name: (!isNew ? 'update' : 'create') + 'Rule',
        urlData: {...params},
        data: {data: {...rule}}
      }));
    };
  },
  cleanupRule(ruleEditRuleComponentsActions) {
    return (dispatch)=>{
      abortController?.abort();
      ruleEditRuleComponentsActions.setDefaultState();
      dispatch(apiActions.resetEndpoint('getRule', STATE_KEY));
      dispatch(apiActions.resetData(['rule', 'ruleComponents'], STATE_KEY));
      dispatch({ type: CLEANUP_RULE });
      dispatch(apiActions.resetEndpoint('getRevisions', RULE_REVISIONS_STATEKEY));
    };
  },
  setSaving(isSaving) {
    return {
      type: SET_SAVING,
      payload: { isSaving }
    };
  },
  goToRules(params) {
    return (dispatch) => {
      dispatch(push({ name: 'rules', params }));
    };
  },
  saveRuleAndComponents({
    rule,
    ruleDetails,
    params,
    values,
    ruleEditRuleComponentsActions,
    publishingActions,
    buildOnSave = false,
    selectedDevLibrary,
    saveToLibrary = false
  }) {
    return (dispatch, getState) => {
      ruleDetails = {
        ...ruleDetails,
        ...getFormValues(RULE_BUILDER_FORM_NAME)(getState()),
        ...values
      };

      dispatch(actionCreators.setSaving(true));

      return dispatch(actionCreators.saveRule(rule, ruleDetails, params)).then(() => {
        return ruleEditRuleComponentsActions.saveRuleComponents({
          ...params,
          rule: getState().api.rule.id
        });
      }).then(()=>{
        if (saveToLibrary) {
          return publishingActions.saveLatestDelegatesToLibrary(
            {...params, library: selectedDevLibrary.id},
            [getState().api.rule],
            selectedDevLibrary
          );
        }
        if (buildOnSave) {
          return publishingActions.saveLatestDelegatesToLibraryAndBuild(
            {...params, library: selectedDevLibrary.id},
            [getState().api.rule],
            selectedDevLibrary
          );
        }
      }).then(()=>{
        dispatch(actionCreators.setSaving(false));
      }).catch((err)=>{
        dispatch(actionCreators.setSaving(false));
        return Promise.reject(err);
      });
    };
  },
  loadRuleRevisions(params) {
    return (dispatch)=>{
      return dispatch(apiActions.apiAction({
        name: 'getRevisions',
        urlData: {
          ...params,
          resource: params.rule,
          resourceType: 'rules'
        },
        stateKey: RULE_REVISIONS_STATEKEY,
        abortSignal: abortController.signal
      }));
    };
  },
  setRuleToRevision(rule, ruleComponents, ruleEditRuleComponentsActions) {
    return (dispatch)=>{
      dispatch(change(RULE_BUILDER_FORM_NAME, 'name', rule.attributes.name));
      ruleEditRuleComponentsActions.setRuleComponentsToRevision(ruleComponents);
    };
  }
};
