/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TERMS_OF_USE_KEY } from '../../higherOrderComponents/termsOfUseDialog';
import { PRIVACY_DIALOG_STATE_KEY } from '../../higherOrderComponents/PrivacySettingsDialog';
import {
  GLOBAL_DIALOGS_KEY,
  getActionCreators as getDialogsActions
} from '../../higherOrderComponents/dialogsActions';
import classNames from 'classnames';
import {getEditProfileLink} from '../../header/shellSelectors';

class AppFooter extends Component {
  render() {
    const {
      className,
      dispatch,
      // editProfileLink // will require more work to properly implement (see the <a/> tag below)
    } = this.props;
    return (
      <div className={
        classNames(
          'appFooter',
          'u-flex',
          'u-padding',
          'u-front',
          'u-flexShrink0',
          className
        )}
      >
        <div className="u-flexOne u-flex u-paddingTop u-borderTopThick">
          <a
            className="u-hoverUnderline footerLink u-marginRight u-pointer"
            onClick={()=>{
              dispatch(
                getDialogsActions(GLOBAL_DIALOGS_KEY).openDialog(TERMS_OF_USE_KEY)
              );
            }}
          >
            Terms of Use
          </a>
          <a
            className="u-hoverUnderline footerLink u-marginRight u-pointer"
            onClick={()=>{
              dispatch(
                getDialogsActions(GLOBAL_DIALOGS_KEY).openDialog(PRIVACY_DIALOG_STATE_KEY)
              );
            }}
          >
            Privacy
          </a>
          {/* We'll comment out the language setting until we support it.
              You can link to the setting but the user's prefernce should show up here. */}
          {/* <div>
            Language: <a className="u-linkColor u-link u-noTextDecoration u-pointer" href={editProfileLink}>English</a>
          </div> */}
          <span className="copyright">&copy; {new Date().getFullYear()} Adobe. All Rights Reserved.</span>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    editProfileLink: getEditProfileLink(state),
    termsOfUseOpen: state.dialogs.getIn([GLOBAL_DIALOGS_KEY, TERMS_OF_USE_KEY])
  };
}

export default connect(mapStateToProps)(AppFooter);
