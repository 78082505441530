/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import {
  getDefaultExtensionId,
  getExtensionsWithDelegateType,
  areExtensionsUsedByResourceInstalled
} from '../../delegate/delegateHelpers';
import actionsHandler from '../../../redux/actionsHandler';
import {
  getRuleComponentFromGroupedComponents
} from './ruleEditHelpers';
import {EVENTS, CONDITIONS, ACTIONS} from '../../../utils/api/apiTypes';
import {getApiData} from '../../../utils/api/apiTools';
import {STATE_KEY} from './ruleBuilderActions';
import {STATE_KEY as EXTENSION_CACHE_STATE_KEY} from '../extensions/extensionCacheBustActions';
import {replace, push} from '../../../routes/namedRouteUtils';
import {getActiveRuleComponents} from './ruleEditSelectors';
import {actionCreators as apiActions} from '../../../utils/api/apiActions';
import Immutable from 'seamless-immutable';

const componentTypeByRouteParam = {
  newEvent: EVENTS,
  newCondition: CONDITIONS,
  newAction: ACTIONS
};

export const SET_EXTENSIONS_INSTALLED_CONTAIN_DELEGATE_TYPE = 'ruleComponentEditor/SET_EXTENSIONS_INSTALLED_CONTAIN_DELEGATE_TYPE';
export const SET_IS_RULE_COMPONENT_VALIDATING = 'ruleComponentEditor/SET_IS_RULE_COMPONENT_VALIDATING';

const defaultState = Immutable({
  extensionsInstalledContainDelegateType: true,
  isRuleComponentValidating: false
});

//Reducers
export default actionsHandler({
  [SET_EXTENSIONS_INSTALLED_CONTAIN_DELEGATE_TYPE](
    state,
    {payload: {extensionsInstalledContainDelegateType}}
  ) {
    return state.set(
      'extensionsInstalledContainDelegateType',
      extensionsInstalledContainDelegateType
    );
  },
  [SET_IS_RULE_COMPONENT_VALIDATING](state, action) {
    return state.set('isRuleComponentValidating', action.payload);
  },
  default: (state = defaultState)=> {
    return state;
  }
});



export const actionCreators = {
  initializeDelegate(ruleEditRuleComponentsActions, params) {
    return (dispatch, getState) => {
      const routeIndicatesNewRuleComponent = Object.keys(
        componentTypeByRouteParam
      ).indexOf(params.ruleComponent) !== -1;


      const currentState = getState();
      const extensionApiData = getApiData(currentState, EXTENSION_CACHE_STATE_KEY);
      const extensions = extensionApiData.extensions;
      const extensionPackages = extensionApiData.extensionPackages;


      if (routeIndicatesNewRuleComponent) {
        const componentType = componentTypeByRouteParam[params.ruleComponent];
        const extensionsInstalledContainDelegateType = getExtensionsWithDelegateType(
          extensionPackages,
          extensions,
          componentType
        ).length > 0;

        dispatch(actionCreators.setExtensionsInstalledContainDelegateType(
          extensionsInstalledContainDelegateType
        ));

        if (extensionsInstalledContainDelegateType) {
          ruleEditRuleComponentsActions.editNewRuleComponent(
            componentType,
            getDefaultExtensionId(
              currentState,
              EXTENSION_CACHE_STATE_KEY,
              componentType
            )
          );
        }
      } else {

        let ruleComponentId = params.ruleComponent;

        // Create a new rule component.
        // Hit save, which takes you back to the rule builder.
        // Click on the card for the rule component you just created. This will navigate you to
        // .../ruleComponent/1
        // where 1 is the ID of the rule component you just created.
        // In this case, the ID on the delegateImplementation object is an number, so in order for
        // editExistingRuleComponent to find the correct delegate, we need to convert the
        // "1" string from the URL params to a number before passing it in.
        if (!isNaN(params.ruleComponent)) {
          ruleComponentId = Number(params.ruleComponent);
        }

        // If the active implementation we're trying to edit doesn't exist, send the user
        // back to the rule builder.
        const activeRuleComponents = getActiveRuleComponents(getState(), STATE_KEY);
        const existingRuleComponent = getRuleComponentFromGroupedComponents(
          activeRuleComponents,
          ruleComponentId
        );

        if (!existingRuleComponent) {
          dispatch(replace({ name: 'editRule', params }));
          return;
        }

        const extensionsUsedByResourceAreInstalled = areExtensionsUsedByResourceInstalled(
          [existingRuleComponent],
          extensions
        );

        const extensionsInstalledContainDelegateType = getExtensionsWithDelegateType(
          extensionPackages,
          extensions,
          existingRuleComponent.componentType
        ).length > 0;

        (extensionsUsedByResourceAreInstalled ? Promise.resolve() : dispatch(apiActions.apiAction({
          name: 'getExtension',
          stateKey: STATE_KEY,
          //abortSignal: abortController?.signal,
          urlData: {
            ...params,
            extension: existingRuleComponent.relationships.extension.data.id
          },
          urlParams: {
            include: 'extension_package'
          }
        }))).then(()=>{

          dispatch(actionCreators.setExtensionsInstalledContainDelegateType(
            extensionsInstalledContainDelegateType
          ));

          ruleEditRuleComponentsActions.editExistingRuleComponent(ruleComponentId);
        });
      }
    };
  },
  setExtensionsInstalledContainDelegateType(extensionsInstalledContainDelegateType) {
    return {
      type: SET_EXTENSIONS_INSTALLED_CONTAIN_DELEGATE_TYPE,
      payload: {
        extensionsInstalledContainDelegateType
      }
    };
  },
  goToEditRule(params) {
    return (dispatch) => {
      dispatch(push({ name: 'editRule', params }));
    };
  },
  setIsRuleComponentValidating(isValidating) {
    return {
      type: SET_IS_RULE_COMPONENT_VALIDATING,
      payload: isValidating
    };
  }
};
