/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import React, {useEffect} from 'react';
import Wait from '@react/react-spectrum/Wait';
import { connect } from 'react-redux';
import {Redirect} from 'react-router';
import {actionCreators as shellActions} from '../components/header/shellActions';


export const CAPABILITY_TYPES = {
  TAGS: 'tags',
  EVENT_FORWARDING: 'eventForwarding',
  APP_CONFIGURATIONS: 'appConfigurations',
  APP_SURFACES: 'appSurfaces',
  MONITORING: 'monitoring'
};

export const CAPABILITY_FEATURE_FLAGS = {
  TAGS: '', // no feature flag required for users to access tags
  APP_CONFIGURATIONS: 'launch-app-configurations-enabled',
  APP_SURFACES: 'launch-app-surfaces-enabled',
  EVENT_FORWARDING: 'launch-server-properties-enabled'
};

export function getCapabilityDisplayName(capabilityType, nameOnly) {
  if (capabilityType === CAPABILITY_TYPES.TAGS) {
    return nameOnly ? 'Tags' : 'Tag Properties';
  } else if (capabilityType === CAPABILITY_TYPES.EVENT_FORWARDING) {
    return nameOnly ? 'Event Forwarding' : 'Event Forwarding Properties';
  } else if (capabilityType === CAPABILITY_TYPES.APP_CONFIGURATIONS) {
    return 'App Configurations';
  } else if (capabilityType === CAPABILITY_TYPES.APP_SURFACES) {
    return 'App Surfaces';
  } else if (capabilityType === CAPABILITY_TYPES.MONITORING) {
    return 'Monitoring';
  }
}

export function getCapabilityLocation(capabilityType, params) {
  if (capabilityType === CAPABILITY_TYPES.TAGS) {
    return {
      name: 'properties',
      params
    };
  } else if (capabilityType === CAPABILITY_TYPES.EVENT_FORWARDING) {
    return {
      name: 'properties',
      params
    };
  } else if (capabilityType === CAPABILITY_TYPES.APP_CONFIGURATIONS) {
    return {
      name: 'appConfigurations',
      params
    };
  } else if (capabilityType === CAPABILITY_TYPES.APP_SURFACES) {
    return {
      name: 'appSurfaces',
      params
    };
  } else if (capabilityType === CAPABILITY_TYPES.MONITORING) {
    return {
      name: 'monitoring',
      params
    };
  }
}

export function CapabilityRedirector({allowedCapabilities, RenderComponent}) {
  return (props) => {
    if ( !allowedCapabilities.includes(props.params.capability) ) {
      return (
        <Redirect to={`/${allowedCapabilities[0] || CAPABILITY_TYPES.TAGS}${location.search}`}/>
      );
    }
    return <RenderComponent {...props}/>;
  };
}

export const TopLevelCapabilityRedirector = connect((state)=>{
  return {
    activeOrgId: state.api.profile?.attributes?.activeOrg
  };
})((props)=>{
  useEffect(()=> {
    if (Object.values(CAPABILITY_TYPES).indexOf(props.params.capability) >= 0) {
      props.dispatch(shellActions.redirectToCompanyByOrgId(props.activeOrgId));
    }
  },[]);
  return (
    <Wait centered size="L"/>
  );
});
