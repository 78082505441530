/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import {combineReducers} from 'redux-seamless-immutable';
import Immutable from 'seamless-immutable';
import queueReduxActionAsDomEvent from '../utils/reduxActionAsDomEvent';

import routerReducers from './routerActions';
import globalsReducers from '../components/higherOrderComponents/globalsActions';
import imsActionsReducers from '../components/higherOrderComponents/imsWrapperActions';
import companyLoaderReducers from '../components/layouts/company/companyLoaderActions';
import toastsReducers from '../components/higherOrderComponents/toastsActions';
import dialogsReducers from '../components/higherOrderComponents/dialogsActions';
import leftNavReducers from '../components/leftNav/leftNavActions';
import propertyCopyReducers from '../components/properties/propertyCopy/propertyCopyActions';
import resourceCopyReducers from '../components/resourceCopy/resourceCopyActions';
import workingLibraryReducers from '../components/properties/workingLibraryActions';
import ruleBuilderReducers from '../components/properties/rules/ruleBuilderActions';
import ruleComponentEditorReducers from '../components/properties/rules/ruleComponentEditorActions';
import extensionEditReducers from '../components/properties/extensions/extensionEditActions';
import propertyEditReducers from '../components/properties/edit/propertyEditActions';
import environmentsReducers from '../components/properties/environments/environmentsActions';
import environmentEditReducer from '../components/properties/environments/environmentEditActions';
import publishingReducers from '../components/properties/publishing/publishingActions';
import {reducer as formReducer} from 'redux-form';
import selectionsReducer from '../components/controlledList/selectableListActions';
import expandedReducer from '../components/CustomTable/expandedActions';
import paginationReducer from '../components/pagination/paginationActions';
import apiActionsReducer from '../utils/api/apiActions';
import asyncPollReducer from '../utils/asyncPollActions';
import ruleEditRuleComponentsReducer from '../components/properties/rules/ruleEditRuleComponentsActions';
import codeEditorReducer from '../components/delegate/codeEditorActions';
import regexTesterReducer from '../components/delegate/regexTesterActions';
import dataElementSelectorReducer from '../components/delegate/dataElementSelectorActions';
import revisionSelectorReducers from
  '../components/properties/publishing/RevisionSelector/revisionSelectorActions';
import libraryUpstreamReducers from
  '../components/properties/publishing/LibraryUpstream/libraryUpstreamActions';
import controlledListReducers from '../components/controlledList/controlledListActions';
import ruleCompareViewReducers from '../components/compareView/ruleCompareView/ruleCompareViewActions';
import singleComponentCompareReducers from '../components/compareView/componentCompareView/componentCompareActions';
import extensionCacheBustReducers from '../components/properties/extensions/extensionCacheBustActions';
import elasticSearchReducers from '../components/search/elasticSearchActions';
import dataElementEditReducers from '../components/properties/dataElements/dataElementEditActions';
import recentActivityReducers from '../components/properties/overview/RecentActivityActions';
import auditEventReducers from '../components/properties/auditEvents/auditEventActions';
import searchReducers from '../components/search/searchActions';
import notesReducers from '../components/rightNavigation/notesActions';
import rightRailReducers from '../components/rightNavigation/rightRailActions';
import filterPanelReducers from '../components/properties/filterPanel/filterPanelActions';
import secretsEditReducers from '../components/properties/secrets/secretsEditActions';
import secretsListReducers from '../components/properties/secrets/secretsListActions';
import clipBoardReducers from '../components/properties/clipboardActions';
import appSurfaceReducers from '../components/companies/appSurfaces/appSurfaceActions.jsx';

const combinedReducers = combineReducers({
  router: routerReducers,
  globals: globalsReducers,
  ims: imsActionsReducers,
  // combineReducers requires all keys to be defined upfront
  // it will complain without this key
  api: (state = null)=>state,
  company: companyLoaderReducers,
  toasts: toastsReducers,
  dialogs: dialogsReducers,
  form: formReducer,
  controlledList: controlledListReducers,
  pagination: paginationReducer,
  selections: selectionsReducer,
  expanded: expandedReducer,
  codeEditor: codeEditorReducer,
  polling: asyncPollReducer,
  leftNav: leftNavReducers,
  propertyCopy: propertyCopyReducers,
  resourceCopy: resourceCopyReducers,
  workingLibrary: workingLibraryReducers,
  ruleBuilder: ruleBuilderReducers,
  extensionEdit: extensionEditReducers,
  propertyEdit: propertyEditReducers,
  environments: environmentsReducers,
  environmentEdit: environmentEditReducer,
  publishing: publishingReducers,
  dataElementEdit: dataElementEditReducers,
  ruleEditRuleComponents: ruleEditRuleComponentsReducer,
  ruleComponentEditor: ruleComponentEditorReducers,
  dataElementSelector: dataElementSelectorReducer,
  regexTester: regexTesterReducer,
  revisionSelector: revisionSelectorReducers,
  libraryUpstream: libraryUpstreamReducers,
  ruleCompareView: ruleCompareViewReducers,
  componentCompareView: singleComponentCompareReducers,
  extensionCacheBust: extensionCacheBustReducers,
  elasticSearch: elasticSearchReducers,
  auditEvents: auditEventReducers,
  search: searchReducers,
  propertyOverviewRecentActivity: recentActivityReducers,
  notes: notesReducers,
  rightRail: rightRailReducers,
  filterPanel: filterPanelReducers,
  secretsEdit: secretsEditReducers,
  secretsList: secretsListReducers,
  clipboard: clipBoardReducers,
  appSurface: appSurfaceReducers
});

// final reducer updates the state and then
// emits DOM events for ALL redux actions
// we may want to pass the updatedState as
// part of the event if needed in the future
export default function(state = Immutable({}), action) {
  let combinedState = combinedReducers(state, action);
  combinedState = apiActionsReducer(combinedState, action);

  queueReduxActionAsDomEvent(action, combinedState);

  if (!Immutable.isImmutable(combinedState)) {
    console.warn('Redux state is mutable');
  }

  return combinedState;
};
