/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe Systems Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe Systems Incorporated and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Adobe Systems Incorporated and its
 * suppliers and are protected by all applicable intellectual property
 * laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe Systems Incorporated.
 **************************************************************************/


import { getLocalStorageItem, setLocalStorageItem } from '../../../utils/storageUtils';


const DIAGNOSTIC_TRACKING_REFUSED = 'diagnosticTrackingRefused';

export function hasUserRefusedDiagnosticTracking() {
  return getLocalStorageItem(DIAGNOSTIC_TRACKING_REFUSED);
}

export function setUserDiagnosticTrackingRefusal(trackingRefused) {
  setLocalStorageItem(DIAGNOSTIC_TRACKING_REFUSED, trackingRefused);
}
