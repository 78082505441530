/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import React, {Component} from 'react';
import {Link as DOMLink} from 'react-router-dom';
import {populateUrl} from '../utils/api/apiTools';
import {
  push as reduxRouterPush,
  replace as reduxRouterReplace
} from 'connected-react-router';
import {get} from 'lodash-es';
import {matchPath} from 'react-router';
import queryString from 'query-string';

export const ROUTE_NAMES = {
  EDIT_ENVIRONMENT: 'editEnvironment',
  EDIT_SECRET: 'editSecret'
};

let routePathsByName = {};

export function formatRoutePath(routePath) {
  return routePath + (routePath.slice(-1) === '/' ? '' : '/');
}

function buildNamedRoutePath(route, baseRoute) {
  const routePath = baseRoute + route.path;
  if (route.name) {
    routePathsByName[route.name] = {
      ...route,
      path: routePath
    };
  }
  if (route.subRoutes) {
    buildNamedRoutePaths(route.subRoutes, formatRoutePath(routePath));
  }
};

export function buildNamedRoutePaths(routes, baseRoute = '') {
  routes.forEach((route)=>{
    buildNamedRoutePath(route, baseRoute);
  });
};

export function populateNamedRoutePath(route) {
  if (typeof route === 'string') {
    return (
      (populateUrl(get(routePathsByName, [route, 'path'])) || route) +
      queryString.pick(location.search, window.environmentSettings.lens_configUrlParamKeys)
    );
  } else {
    return (
      populateUrl(routePathsByName[route.name].path, route.params, route.urlParams) +
      queryString.pick(location.search, window.environmentSettings.lens_configUrlParamKeys)
    );
  }
};

export function getRouterMatchByPath(routePath) {
  const route = getRouteByPath(routePath);
  return matchPath(routePath, {
    path: route?.path,
    exact: true,
    strict: false
  });
}

export function getRouterParamsByPath(routePath) {
  // get a route match so we can populate current route params
  // on state (because react-router-redux doesn't do it for us)
  const routeMatch = getRouterMatchByPath(routePath);
  if (!routeMatch) {
    // if the current route is not hit, the user has visited
    // a url that isn't in our routes, OR there is a bug in
    // our namedRouteUtils functions
    console.error('Current path did not match a route!');
  }

  return routeMatch?.params || {};
}

export function getNamedRoute(routeName) {
  return routePathsByName[routeName];
}

export function getNamedRoutePath(routeName) {
  return getNamedRoute(routeName).path;
}

export function getRouteByPath(pathname) {
  const routes = Object.keys(routePathsByName || {}).map((routeKey)=>{
    return routePathsByName[routeKey];
  });

  let foundRoute = routes.find((route)=>{
    const match = matchPath(pathname, {
      path: route.path,
      exact: true,
      strict: false
    });

    return match;
  });

  return foundRoute;
}

// this needs to be a class so that button does not complain when it creates a ref of this link
export class Link extends Component {
  render = ()=>{
    const props = this.props;
    let pathname = populateNamedRoutePath(props.to);
    // this may not be needed but its a safty net for the
    // case that a route has a built in hash or search
    const hashMatch = pathname.match(/#.*/);
    pathname = hashMatch ? pathname.replace(hashMatch[0],'') : pathname;
    const searchMatch = pathname.match(/\?.*/);
    pathname = searchMatch ? pathname.replace(searchMatch[0],'') : pathname;

    const location = {
      pathname,
      search: searchMatch && searchMatch[0],
      hash: hashMatch && hashMatch[0],
      ...props.to
    };

    return (
      <DOMLink {...props} to={location}>
        {props.children}
      </DOMLink>
    );
  };
};

export function push(route) {
  return reduxRouterPush(populateNamedRoutePath(route));
}

export function replace(route) {
  return reduxRouterReplace(populateNamedRoutePath(route));
}
