import {isEndpointLoadingForStateKey, getApiMappingName} from '../../utils/api/apiTools';
import { getPaginationFromState } from '../pagination/paginationSelectors';
/**
 * Returns information that was set during the call to controlledList.init for the specified stateKey.
 * @param stateKey
 * @param state
 * @returns {Immutable.object}
 */
export const getControlledListOptionsFromState = (stateKey, state) => {
  return state.controlledList?.[stateKey];
};

export const getControlledListSavingOrDeletingFromState = (stateKey, state) => {
  const { endpointGroupKey } = getPaginationFromState(stateKey, state);
  return isEndpointLoadingForStateKey(
    state,
    stateKey,
    [
      getApiMappingName(endpointGroupKey, 'create'),
      getApiMappingName(endpointGroupKey, 'delete')
    ]
  );
};
