/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/


import React, {Component, PureComponent} from 'react';
import {Table, THead, TBody, TR, TH, TD} from '@react/react-spectrum/Table';
import {connect} from 'react-redux';
import Button from '@react/react-spectrum/Button';
import Heading from '@react/react-spectrum/Heading';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import Popover from '@react/react-spectrum/Popover';
import Progress from '@react/react-spectrum/Progress';
import ChevronRightIcon from '@react/react-spectrum/Icon/ChevronRight';
import Rule from '@react/react-spectrum/Rule';
import StatusLight from '@react/react-spectrum/StatusLight';
import Wait from '@react/react-spectrum/Wait';
import Tooltip from '@react/react-spectrum/Tooltip';
import {
  getAllResourcesCountFromState,
  getPropertyCopyFromState,
  getCompletedResourcesPercentFromState
} from './PropertyCopySelectors';
import {
  actionCreators as propertyCopyActions,
  COPY_STATUS
} from './propertyCopyActions';
import InlineTextAlert from 'spectrum-alternatives/InlineTextAlert';
import {apiTypeToDisplayName} from '../../../utils/api/apiTypes';
import UpdateThrottler from '../../higherOrderComponents/UpdateThrottler';
import SplitButton from '@react/react-spectrum/SplitButton';
import {MenuItem} from '@react/react-spectrum/Menu';
import { getCurrentRouteParamsFromState } from '../../../routes/routeSelectors';
import PropertyCopyStatusDetailsErrorMessage from '../../content/PropertyCopyStatusDetailsErrorMessage';

class PropertyCopyStatusThrottler extends Component {
  render() {
    const {copyStatus, allResourcesCount} = this.props;

    // We'll only throttle the render if there are a lot of resources
    // when there are many resources each render can take quite a bit of time
    // causing the entire app to become sluggish. We tried to address this by
    // preventing additional renders at a higher level, which helped a bit,
    // but when you are rending 300+ resources it just runs slow. So for now
    // we've throttled the render. We may want to spend more time optimizing
    // this at some point.
    return (
      copyStatus === COPY_STATUS.IN_PROGRESS &&
      allResourcesCount > 300
    ) ? (
      <UpdateThrottler delay={5000}>
        <PropertyCopyStatus {...this.props} />
      </UpdateThrottler>
    ) : (
      <PropertyCopyStatus {...this.props} />
    );
  }
};

class PropertyCopyStatus extends PureComponent {
  componentDidMount = () => {
    window.addEventListener('online', this.onlineChanged);
    window.addEventListener('offline', this.onlineChanged);
  };
  componentWillUnmount = () => {
    window.removeEventListener('online', this.onlineChanged);
    window.removeEventListener('offline', this.onlineChanged);
  };
  onlineChanged = () => {
    // it's possible for window.navigator.onLine to be out of sync with the react
    // render cycle so we'll ensure a render any time the online status changes
    this.forceUpdate();
  };
  render = () => {
    const {
      dispatch, copyStatus, percentComplete, allResourcesCount,
      sourceProperty, destinationProperty, resourcesData, copiedResourcesData, failedResourcesData
    } = this.props;

    let progressBarLabel = percentComplete === 100 ? 'Done' : 'Copying...';
    if (!window.navigator?.onLine) { progressBarLabel = 'Paused'; }

    return (copyStatus !== COPY_STATUS.NOT_STARTED && copyStatus !== COPY_STATUS.CANCELLED) ? (
      <div className="propertyCopyStatus u-marginLeft u-flex">
        <OverlayTrigger
          placement="bottom"
          trigger="click"
        >
          <Button variant="action" data-test-id="propertyCopyStatusButton">
            {(
              window.navigator?.onLine &&
              copyStatus !== COPY_STATUS.COMPLETED
            ) && (
              <React.Fragment>
                <Wait size="S" /> <div className="u-marginLeftXs">Copying</div>
              </React.Fragment>
            )}
            {(
              !window.navigator?.onLine &&
              copyStatus !== COPY_STATUS.COMPLETED
            ) ? (
              <StatusLight variant="notice">Copy Paused</StatusLight>
            ) : null}
            {copyStatus === COPY_STATUS.COMPLETED && (
              <StatusLight variant={failedResourcesData.length ? 'negative' : 'positive'}>
                Copy Completed
              </StatusLight>
            )}
          </Button>
          <Popover placement="bottom" className="propertyCopyStatusPopover" data-test-id="propertyCopyStatusPopover">
            <div>
              <Heading variant="subtitle1">Copy Property</Heading>
              <div className="u-dim u-noWrap u-overflowHidden u-ellipsis">
                {sourceProperty?.attributes.name}
                <ChevronRightIcon className="rightArrow u-marginHorizontalXs" size="XS" />
                {destinationProperty?.attributes.name}
              </div>
              <Rule variant="small" className="u-marginVerticalLg" />
              <div className="content u-relativePosition">
                {copyStatus === COPY_STATUS.PREPARING && (
                  <div className="preparing u-fillParent u-flex u-flexColumn u-flexCenterJustifyContent u-marginBottomLg">
                    <div className="u-flexCenter">
                      <Wait size="S" className="wait" /> <div className="u-inlineBlock u-marginLeftXs u-dim">Preparing to copy</div>
                    </div>
                  </div>
                )}
                {(
                  copyStatus === COPY_STATUS.IN_PROGRESS ||
                  copyStatus === COPY_STATUS.PAUSED ||
                  copyStatus === COPY_STATUS.COMPLETED
                ) && (
                  <div className="inProgress">
                    {resourcesData?.length ? (
                      <React.Fragment>
                        <Progress
                          className="u-fullWidth u-marginBottomLg"
                          label={progressBarLabel}
                          showPercent
                          labelPosition="top"
                          value={percentComplete}
                        />
                        <div>
                          <Table quiet className="resourceTableHead u-tableLayoutFixed u-fullWidth">
                            <THead>
                              <TH className="u-noWrap u-overflowHidden">Resource</TH>
                              <TH className="u-noWrap u-overflowHidden">Type</TH>
                              <TH className="u-noWrap u-overflowHidden">Status</TH>
                            </THead>
                          </Table>
                          <div className="resourceTableBodyWrapper u-fullWidth u-overflowYAuto" data-test-id="propertyCopyResourceTableBody">
                            <Table quiet className="resourceTableBody u-tableLayoutFixed u-fullWidth">
                              <TBody>
                                {resourcesData?.map(resource=>{
                                  const resourceName = resource.attributes.displayName || resource.attributes.name;

                                  let statusLightVariant = 'neutral';
                                  let statusLightText = 'Queued';
                                  let error = null;

                                  const copyStarted = resource.copyStarted;
                                  const copiedResource = copiedResourcesData.find(
                                    _resource=>_resource.id === resource.id
                                  );
                                  const failedResource = failedResourcesData.find(
                                    _resource=>_resource.id === resource.id
                                  );
                                  let currentlyCopying = (
                                    copyStarted &&
                                    !copiedResource &&
                                    copyStatus === COPY_STATUS.IN_PROGRESS
                                  );

                                  if (copiedResource) {
                                    statusLightVariant = 'positive';
                                    statusLightText = 'Copied';
                                  } else if (failedResource) {
                                    statusLightVariant = 'negative';
                                    statusLightText = 'Failed';
                                    error = failedResource.error;
                                  }

                                  return (
                                    <TR key={resource.id}>
                                      <TD className="u-noWrap u-overflowHidden u-ellipsis">{resourceName}</TD>
                                      <TD className="u-noWrap u-overflowHidden">{apiTypeToDisplayName(resource.type)}</TD>
                                      <TD className="u-noWrap u-overflowHidden">
                                        {error ? (
                                          <div className="itemStatus">
                                            <OverlayTrigger placement="bottom" trigger={error.message ? 'hover' : null}>
                                              <StatusLight className="u-inline" variant={statusLightVariant}></StatusLight>
                                              <Tooltip
                                                placement="bottom"
                                                className="u-noPadding buildErrorPopdown"
                                              >
                                                <PropertyCopyStatusDetailsErrorMessage
                                                  error={error}
                                                />
                                              </Tooltip>
                                            </OverlayTrigger>
                                            {statusLightText}
                                          </div>
                                        ) : (
                                          <div className="itemStatus">
                                            {!copiedResource && currentlyCopying ? (
                                              <div className="resourceCopying">
                                                <Wait size="S" className="wait u-marginRightXs"/> Copying
                                              </div>
                                            ) : (
                                              <StatusLight variant={statusLightVariant}>
                                                {statusLightText}
                                              </StatusLight>
                                            )}
                                          </div>
                                        )}
                                      </TD>
                                    </TR>
                                  );
                                })}
                                {(
                                  resourcesData?.length &&
                                  allResourcesCount > resourcesData.length &&
                                  copyStatus !== COPY_STATUS.COMPLETED
                                ) ? (
                                  <TR>
                                    {/* until react-spectrum supports colspan we will just use the TD class */}
                                    <td className="spectrum-Table-cell u-paddingLg u-fullWidth" colSpan="3">
                                      {copyStatus === COPY_STATUS.PAUSED ? (
                                        <span className="u-italic u-dim">. . .</span>
                                      ) : (
                                        <Wait size="S" className="wait" centered />
                                      )}
                                    </td>
                                  </TR>
                                ) : null}
                              </TBody>
                            </Table>
                          </div>
                        </div>
                      </React.Fragment>
                    ) : null}
                    {(
                      !resourcesData?.length &&
                      copyStatus !== COPY_STATUS.COMPLETED
                    ) ? (
                      <Wait size="S" className="wait u-negativeMarginTopSm" centered />
                    ) : null}
                  </div>
                )}
              </div>
              <Rule variant="small" className="u-marginBottomLg" />
              <div className="u-flex">
                <div className="u-flexOne statusWrapper">
                  {!window.navigator?.onLine ? (
                    <InlineTextAlert variant="warning">Offline</InlineTextAlert>
                  ) : null}
                  {(
                    window.navigator?.onLine &&
                    failedResourcesData.length &&
                    copyStatus === COPY_STATUS.COMPLETED
                  ) ? (
                    <InlineTextAlert variant="error">Some resources were not copied</InlineTextAlert>
                  ) : null}
                  {(
                    window.navigator?.onLine &&
                    !failedResourcesData.length &&
                    copyStatus === COPY_STATUS.COMPLETED
                  ) ? (
                    <InlineTextAlert variant="success">Success</InlineTextAlert>
                  ) : null}
                </div>
                <div>
                  {copyStatus !== COPY_STATUS.COMPLETED ? (
                    <React.Fragment>
                      <Button
                        quiet
                        variant="primary"
                        onClick={()=>{
                          dispatch(propertyCopyActions.cancelCopying());
                        }}
                      >
                        Cancel
                      </Button>
                    </React.Fragment>
                  ) : (
                    <SplitButton
                      variant="primary"
                      label={failedResourcesData.length ? 'Retry failed resources' : 'Go to new property'}
                      onClick={()=>{
                        this.handleSplitButtonAction(failedResourcesData.length ? 'retry' : 'goToNewProperty');
                      }}
                      onSelect={(action)=>{
                        this.handleSplitButtonAction(action);
                      }}
                    >
                      {(() => {
                        let menuItems = [];
                        if (failedResourcesData.length) {
                          menuItems.push(<MenuItem key={0} value="goToNewProperty">Go to new property</MenuItem>);
                        }
                        menuItems.push(<MenuItem key={1} value="close">Close</MenuItem>);
                        return menuItems;
                      })()}
                    </SplitButton>
                  )}
                </div>
              </div>
            </div>
          </Popover>
        </OverlayTrigger>
      </div>
    ) : null;
  };
  handleSplitButtonAction = (action) => {
    const {dispatch} = this.props;
    if (action === 'retry') {
      dispatch(propertyCopyActions.retry());
    } else if (action === 'goToNewProperty') {
      dispatch(propertyCopyActions.goToDestinationProperty());
      dispatch(propertyCopyActions.initialize({resetLocalStorage: true}));
    } else if (action === 'close') {
      dispatch(propertyCopyActions.initialize({resetLocalStorage: true}));
    }
  };
};

function mapStateToProps(state) {
  const propertyCopy = getPropertyCopyFromState(state);
  const {sourceProperty, destinationProperty, resourcesData, copiedResourcesData, failedResourcesData} = propertyCopy;
  const allResourcesCount = getAllResourcesCountFromState(state);
  const percentComplete = getCompletedResourcesPercentFromState(state);

  return {
    copyStatus: propertyCopy.copyStatus,
    sourceProperty,
    destinationProperty,
    percentComplete,
    allResourcesCount,
    resourcesData,
    copiedResourcesData,
    failedResourcesData,
    params: getCurrentRouteParamsFromState(state)
  };
}

export default connect(mapStateToProps)(PropertyCopyStatusThrottler);
