/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import {isEqual} from 'lodash-es';
import {
  EDITOR_RULE_COMPONENT,
  PRISTINE_RULE_COMPONENTS,
  ACTIVE_RULE_COMPONENTS,
  DELETION_RULE_COMPONENTS
} from './ruleEditRuleComponentsActions';
import {
  flattenGroupedRuleComponents,
  getRuleComponentFromGroupedComponents
} from './ruleEditHelpers';


function getRuleComponentsByStage(state, stateKey, stage) {
  const ruleComponents = getRuleComponentsState(state, stateKey);
  return ruleComponents ? ruleComponents[stage] : null;
}

function getPristineRuleComponentById(state, stateKey, ruleComponentId) {
  return getRuleComponentFromGroupedComponents(
    getPristineRuleComponents(state, stateKey),
    ruleComponentId
  );
}

export function getRuleComponentsState(state, stateKey) {
  return state.ruleEditRuleComponents[stateKey];
}

export function getActiveRuleComponents(state, stateKey) {
  return getRuleComponentsByStage(state, stateKey, ACTIVE_RULE_COMPONENTS);
}

export function getActiveRuleComponentById(state, stateKey, ruleComponentId) {
  return getRuleComponentFromGroupedComponents(
    getActiveRuleComponents(state, stateKey),
    ruleComponentId
  );
}

export function getPristineRuleComponents(state, stateKey) {
  return getRuleComponentsByStage(state, stateKey, PRISTINE_RULE_COMPONENTS);
}

export function getEditorRuleComponent(state, stateKey) {
  return getRuleComponentsByStage(
    state,
    stateKey,
    EDITOR_RULE_COMPONENT
  );
}

export function getDeletionRuleComponents(state, stateKey) {
  return getRuleComponentsByStage(state, stateKey, DELETION_RULE_COMPONENTS);
}

export function getRuleComponentsWithChanges(state, stateKey) {
  const flattenedActiveRuleComponents =
    flattenGroupedRuleComponents(getActiveRuleComponents(state, stateKey));
  return flattenedActiveRuleComponents.filter((activeRuleComponent)=>{
    //if there are no pristine implementations we should always save everything in the active list
    const pristineRuleComponent = getPristineRuleComponentById(
      state,
      stateKey,
      activeRuleComponent.id
    );

    return !isEqual(pristineRuleComponent, activeRuleComponent);
  });
}

export function getRuleComponentEditorData(state) {
  return state.ruleComponentEditor;
};
