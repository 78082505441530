/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import actionsHandler from '../../redux/actionsHandler';
import Immutable from 'seamless-immutable';
import { setLocationHash } from '../../routes/routeUtils';

export const SET_EXPANDABLE_ITEMS = 'expanded/SET_EXPANDABLE_ITEMS';
export const TOGGLE_EXPANDED_ITEM = 'expanded/TOGGLE_EXPANDED_ITEM';
export const TOGGLE_EXPANDED_ALL = 'expanded/TOGGLE_EXPANDED_ALL';
export const COLLAPSE_ALL = 'expanded/COLLAPSE_ALL';

const defaultState = {
  expandableItems: [],
  expandedItems: []
};

//Reducers
export default actionsHandler({
  [SET_EXPANDABLE_ITEMS]: (state, action)=> {
    const {expandedStateKey, expandableItems, resetExpandedItems} = action.payload;
    const expandedItems = state[expandedStateKey]?.expandedItems || [];
    return state.set(expandedStateKey, {
      expandableItems: expandableItems,
      expandedItems: resetExpandedItems ? [] : expandedItems
    });
  },
  [TOGGLE_EXPANDED_ITEM]: (state, action)=> {
    const {expandedStateKey, expandable, expanded} = action.payload;
    const expandedIndex = state[expandedStateKey].expandedItems.findIndex((item)=>{
      return item.id === expandable.id;
    });
    if (expandedIndex > -1 || expanded === false) {
      // remove it
      return state.updateIn([expandedStateKey, 'expandedItems'], (expandedItems)=>{
        return expandedItems.slice(0, expandedIndex).concat(expandedItems.slice(expandedIndex + 1));
      });
    } else if (expandedIndex < 0 || expanded === true) {
      // add it
      return state.updateIn([expandedStateKey, 'expandedItems'], (expandedItems)=>{
        return expandedItems.concat(expandable);
      });
    }
  },
  [TOGGLE_EXPANDED_ALL]: (state, action)=> {
    const {expandedStateKey} = action.payload;
    const expandableItems = state[expandedStateKey].expandableItems;
    let expandedItems = state[expandedStateKey].expandedItems;

    expandedItems = expandedItems.length === expandableItems.length ? [] : expandableItems;

    return state.setIn([expandedStateKey, 'expandedItems'], expandedItems);
  },
  [COLLAPSE_ALL]: (state, action)=> {
    const {expandedStateKey} = action.payload;
    return state.setIn([expandedStateKey, 'expandedItems'], []);
  },
  default:(state = Immutable(defaultState))=> {
    return state;
  }
});


//Action Creators creator
export let actionCreators = (expandedStateKey)=> {
  return {
    setExpandableItems(expandableItems, resetExpandedItems = true) {
      return {
        type: SET_EXPANDABLE_ITEMS,
        payload: {
          expandedStateKey,
          expandableItems,
          resetExpandedItems
        }
      };
    },
    toggleExpandedItem(expandable, expanded, shouldUpdateHash) {
      if (shouldUpdateHash && expandable?.id) {
        setLocationHash(expanded ? expandable.id : null);
      }
      return {
        type: TOGGLE_EXPANDED_ITEM,
        payload: {
          expandedStateKey,
          expandable,
          expanded
        }
      };
    },
    toggleExpandAll() {
      return {
        type: TOGGLE_EXPANDED_ALL,
        payload: {
          expandedStateKey
        }
      };
    },
    collapseAll(shouldUpdateHash) {
      if (shouldUpdateHash) {
        setLocationHash();
      }
      return {
        type: COLLAPSE_ALL,
        payload: {
          expandedStateKey
        }
      };
    }
  };
};
