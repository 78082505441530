import React from 'react';
import Select from '@react/react-spectrum/Select';
import './PaginationFooter.styl';
import PaginationList from 'spectrum-alternatives/PaginationList';
import {getActionCreators as getPaginationActions} from './paginationActions';
import {getActionCreators as getListActions} from '../controlledList/controlledListActions';
import {getPaginationFromState} from './paginationSelectors';
import {connect} from 'react-redux';
import {supportedPaginationPageSizes} from './paginationUtils.js';

class PaginationListFooter extends React.Component {
  render() {
    const {
      stateKey,
      dispatch,
      currentPage,
      lastPageNumber,
      pageSize,
      totalCount,
      pageSizeLabel = 'Items',
      onReadyLoadList
    } = this.props;

    // hide pagination controls if there aren't even enough items where changing the page size would matter.
    if (parseInt(totalCount, 10) < supportedPaginationPageSizes[0].value) {
      return null;
    }

    const deferLoadingToParent = typeof onReadyLoadList === 'function';

    return (
      <footer className="pagination-footer u-padding">
        <PaginationList
          lastPageNumber={lastPageNumber}
          currentPage={currentPage}
          onChange={currentPage => {
            dispatch(getPaginationActions(stateKey).setPendingQueryPage(currentPage));
            if (deferLoadingToParent) {
              onReadyLoadList();
            } else {
              dispatch(getListActions(stateKey).loadList());
            }
          }}
          className={'u-negativeMarginLeft'}
        />
        <div className={'pageSize-selector-container'}>
          <span className={'pageSize-selector-label'}>{pageSizeLabel}: </span>
          <Select
            options={supportedPaginationPageSizes}
            value={pageSize}
            onChange={newPageSize => {
              dispatch(getPaginationActions(stateKey).setPageSize(newPageSize));
              dispatch(getPaginationActions(stateKey).setPendingQueryPage(1));
              if (deferLoadingToParent) {
                onReadyLoadList();
              } else {
                dispatch(getListActions(stateKey).loadList());
              }
            }}
            flexible
            alignRight
            quiet
          />
        </div>
      </footer>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const pagination = getPaginationFromState(ownProps.stateKey, state);

  let lastPageNumber = pagination?.totalPages;
  if (ownProps.lastPageNumberOverride != null) {
    lastPageNumber = ownProps.lastPageNumberOverride;
  }

  return {
    currentPage: pagination?.currentPage || 1,
    lastPageNumber: lastPageNumber || 1,
    pageSize: pagination?.pageSize,
    totalCount: pagination?.totalCount,
  };
};

export default connect(mapStateToProps)(PaginationListFooter);
