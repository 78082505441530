/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe Systems Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe Systems Incorporated and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Adobe Systems Incorporated and its
 * suppliers and are protected by all applicable intellectual property
 * laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe Systems Incorporated.
 **************************************************************************/

// Typically we would use the routerReducer from react-router-redux; however, because we're using
// seamless-immutable, we have to use a seamless-immutable-compatible version. Fortunately,
// there's redux-seamless-immutable, which provides a replacement that would normally be compatible.
// Unfortunately, we're using an alpha version of react-router-redux which is incompatible with
// redux-seamless-immutable, so we're left implementing our own version until that changes.
// https://github.com/eadmundo/redux-seamless-immutable/issues/10
import Immutable from 'seamless-immutable';
import { browserHistory } from '../utils/browserHistoryUtils';
import { LOCATION_CHANGE, connectRouter } from 'connected-react-router';
import {isEqual,omit} from 'lodash-es';
import {getRouteByPath, getRouterParamsByPath, push} from '../routes/namedRouteUtils';
import { getCurrentRouteParamsFromState, getCurrentRouteFromState } from '../routes/routeSelectors';
import { getParentRoute } from '../routes/routeUtils';
import { replace } from '../routes/namedRouteUtils';


//Reducers
export default function routerReducers(state, action) {
  if (!browserHistory) {return null;}
  let combinedState = connectRouter(browserHistory)(state, action);

  // When the location changes, we want to update the lastLocation and add the route and parmas obejcts to locations
  if (action.type === LOCATION_CHANGE) {
    const lastLocation = state?.location;
    const nextLocation = combinedState.location;

    // check if we need to update the lastLocation
    if (lastLocation) {
      const locationNoKey = lastLocation.without('key').asMutable();
      let nextLocationNoKey = omit(nextLocation);
      const sameLocation = isEqual(locationNoKey, nextLocationNoKey);
      if (!sameLocation && action.payload.action !== 'REPLACE') {
        combinedState = Immutable.set(combinedState, 'lastLocation', lastLocation);
      }
    }

    // lastLocation handled so now we can update the nextLocation
    combinedState = Immutable.set(
      combinedState,
      'location',
      {
        ...nextLocation,
        route: getRouteByPath(nextLocation.pathname),
        params: getRouterParamsByPath(nextLocation.pathname)
      }
    );

    combinedState = Immutable.set(combinedState, 'historyAction', action.payload.action);
  }

  return combinedState;
}

//Action Creators
export let actionCreators = {
  goToParentRoute({params, shouldReplaceRoute} = {}) {
    return (dispatch, getState) => {
      const state = getState();
      const currentRoute = getCurrentRouteFromState(state);
      const currentRouteParams = getCurrentRouteParamsFromState(state);
      const parentRoute = getParentRoute(currentRoute);

      const navigateMethod = shouldReplaceRoute ? replace : push;
      return dispatch(
        navigateMethod({
          name: parentRoute.name,
          params: params || currentRouteParams
        })
      );
    };
  }
};
