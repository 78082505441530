/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import {ACTIVE_ORG_LOCALSTORAGE_KEY, getActiveOrgId, getActiveCapabilities} from './shellSelectors';
import { setLocalStorageItem, removeSessionStorageItem } from '../../utils/storageUtils';
import {
  getShouldUseLocalShell,
  getExcRuntime,
  getAdobeIms,
} from '../higherOrderComponents/imsWrapperUtils';
import { MANUAL_SIGNIN_KEY } from '../higherOrderComponents/imsWrapperActions';
import { removeLocalStorageItem } from '../../utils/storageUtils';
import { getCurrentRouteParamsFromState } from '../../routes/routeSelectors';
import { populateNamedRoutePath } from '../../routes/namedRouteUtils';
import {actionCreators as apiActions} from '../../utils/api/apiActions';
import { browserHistory } from '../../utils/browserHistoryUtils';
import {actionCreators as companyLoaderActions, COMPANY_LOADER_STATE_KEY} from '../layouts/company/companyLoaderActions';
import { CAPABILITY_TYPES } from '../../routes/capabilityUtils';

//Action Creators
export let actionCreators = {
  setActiveOrgFromUnifiedShell(orgId) {
    return (dispatch, getState) => {
      const lastActiveOrgId = getActiveOrgId(getState());

      // when the active org changes, the currently loaded company may no longer match it.
      // because getActiveOrgId prioritizes using current company from state, it must be reset.
      dispatch(apiActions.resetEndpoint('getCompany', COMPANY_LOADER_STATE_KEY));
      dispatch(apiActions.resetData(['company'], COMPANY_LOADER_STATE_KEY));

      dispatch(actionCreators.setActiveOrg(orgId)); // don't refresh yet

      // Unified Shell triggers an org change when the page first loads. If we navigate back to
      // the root every time, on refresh the root would get loaded twice.
      // We don't want to redirect to the root immediately unless the org has actually changed
      // which can only happen after the org has been set the first time (in the `ready` event above).
      // In this case, we will validate that its value has actually changed.
      if (lastActiveOrgId && lastActiveOrgId !== orgId) {
        window.location = window.environmentSettings.lens_publicAppPath + location.search;
      }
    };
  },
  setActiveOrg(orgId, refresh) {
    return (dispatch) => {
      setLocalStorageItem(ACTIVE_ORG_LOCALSTORAGE_KEY, orgId);

      if (refresh) {
        dispatch(actionCreators.redirectToCompanyByOrgId(orgId));
      }
    };
  },
  redirectToCompanyByOrgId(orgId) {
    return async(dispatch, getState) => {
      const params = getCurrentRouteParamsFromState(getState());
      getExcRuntime() && (getExcRuntime().spinner = true);

      // The companies endpoint only returns the company matching your current org, unless you
      // have read_system or manage_system rights in which case it returns the first 100 companies.
      let companyMatchingOrg;
      try {
        companyMatchingOrg = await dispatch(actionCreators.getCompanyByOrgId(params, orgId));
      } catch (error) {
        // do nothing
      }

      if (companyMatchingOrg) {
        // if the user does have access to the company ID in the route, and its orgId
        // does not match the current company's orgId, then redirect to the company
        // that matches the active org
        const activeCapabilities = getActiveCapabilities(getState());
        browserHistory.replace(populateNamedRoutePath({
          name: 'company',
          params: {
            ...params,
            capability: activeCapabilities.includes(params.capability) ? params.capability : CAPABILITY_TYPES.TAGS,
            company: companyMatchingOrg.id
          }
        }));
        // this reload is a way to clear all of lens's error states
        // this is needed due to these error states stopping users from doing
        // anything else in lens
        window.location.reload();

        // manually clear error states
      } else {
        // null means that the company does not exist
        // this will trigger an error message to show in baseLayout
        dispatch(companyLoaderActions.setCompanyMatchingActiveOrg(null));
        browserHistory.replace(`/${params.capability}${location.search}`);
      }
    };
  },
  getCompanyByOrgId(params, orgId) {
    return (dispatch) => {
      return dispatch(apiActions.apiAction({
        name: 'getCompanies',
        urlParams: {
          'filter[org_id]': 'EQ ' + orgId
        },
        updateState: false,
        headers: {
          // this must be manually overridden or apiMiddleware will
          // get the previous company's org id from getActiveOrgId()
          'x-gw-ims-org-id': orgId
        }
      })).then(result=>{
        const companyByOrgId = result?.res?.body?.data?.[0];
        return companyByOrgId;
      });
    };
  },
  logout() {
    return (dispatch, getState)=>{
      // Remove active org when signing out to prevent user/org mismatch
      // when signing back in.
      removeLocalStorageItem(ACTIVE_ORG_LOCALSTORAGE_KEY);

      // handle signout for manual logins
      const {isManualLogin} = getState().ims;
      if (isManualLogin) {
        removeSessionStorageItem(MANUAL_SIGNIN_KEY);
        window.location = window.environmentSettings.lens_publicAppPath + location.search;
        return;
      }

      getShouldUseLocalShell() ?
      getAdobeIms().signOut() :
      getExcRuntime().authExpired();
    };
  }
};


