/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import actionsHandler from '../../../redux/actionsHandler';
import Immutable from 'seamless-immutable';
import { actionCreators as apiActions } from '../../../utils/api/apiActions';

export const APP_SURFACES_STATE_KEY = 'appSurfaces';
export const MAP_CONFIGURATION_TO_SURFACE = 'appSurfaces/MAP_CONFIGURATION_TO_SURFACE';
export const CLEAN_UP_CONFIGURATIONS = 'appSurfaces/CLEAN_UP_CONFIGURATIONS';

const initialState = Immutable({
  configurationsBySurfaceId: {}
});

// Reducers
export default actionsHandler({
  [MAP_CONFIGURATION_TO_SURFACE](state, {payload}) {
    return state.setIn(['configurationsBySurfaceId', payload.surfaceId], payload.configurations);
  },
  [CLEAN_UP_CONFIGURATIONS]() {
    return initialState;
  },
  default(state) {
    return state ? state : initialState;
  }
});

// Action Creators
export let actionCreators = {
  mapConfigurationToSurface(configurations = [], surfaceId) {
    return {
      type: MAP_CONFIGURATION_TO_SURFACE,
      payload: {configurations, surfaceId}
    };
  },
  cleanUpConfigurations() {
    return {
      type: CLEAN_UP_CONFIGURATIONS,
    };
  },
  getAppConfigurationsForSurface(params, stateKey) {
    return apiActions.apiAction({
      name: 'getAppConfigurationsForSurface',
      urlData: {...params},
      stateKey,
      translateCase: false
    });
  },
  deleteAppConfigurationsForSurface({selectedItems, params, stateKey}) {
    return (dispatch, getState) => {
      let configurationsBySurfaceId = getState().appSurface?.configurationsBySurfaceId;

      selectedItems.map(selectedItem => {
        let selectedItemConfigurations = configurationsBySurfaceId[selectedItem.id];
        if (selectedItemConfigurations) {
          selectedItemConfigurations.map(configuration => {
            return dispatch(apiActions.apiAction({
              name: 'deleteAppConfiguration',
              urlData: {...params, appConfiguration: configuration.id},
              stateKey,
              translateCase: false
            }));
          });
        }
      });
    };
  }
};
