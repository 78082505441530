/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import Immutable from 'seamless-immutable';
import {
  extensionPackagesNormalizer,
  delegateDescriptorsNormalizer
} from './apiNormalizers';
import {keyBy} from 'lodash-es';
import apiMappings from './apiMappings';

function delegateDescriptorsReducer(state, extensionPackages) {
  return state.update('delegateDescriptors', (delegateDescriptors = Immutable({})) => {
    // If the extension packages are not defined we should clear out the delegate descriptors.
    // this assumes that you are doing a reset
    // At some point it would be better to find where all the calls originate for this reducer
    // and remove the this reducer call if there are no extension packages.
    return (
      extensionPackages[0] ?
      delegateDescriptors.merge(delegateDescriptorsNormalizer(extensionPackages)) :
      Immutable({})
    );
  });
}

function extensionsReducer(state, extensions, extensionPackages) {
  state = state.set('extensions', keyBy(extensions, 'id'));

  if (extensionPackages) {
    state = extensionPackagesReducer(state, extensionPackages);
  }

  return state;
}

function extensionReducer(state, extension, extensionPackage) {
  state = state.set('extension', extension);

  if (extensionPackage) {
    state = extensionPackageReducer(state, extensionPackage);
  }

  return state;
}

function extensionPackageReducer(state, extensionPackage) {
  state = state.set('extensionPackage', extensionPackage);
  state = delegateDescriptorsReducer(state, [extensionPackage]);
  return state;
}

function extensionPackagesReducer(state, extensionPackages) {
  state = state.set('extensionPackages', extensionPackagesNormalizer(extensionPackages));
  state = delegateDescriptorsReducer(state, extensionPackages);
  return state;
}

export function getExtensionPackagesReducer(state, action) {
  return extensionPackagesReducer(state, action.responseBody.data);
}

export function getExtensionPackageReducer(state, action) {
  return extensionPackageReducer(state, action.responseBody.data);
}

export function getExtensionsReducer(state, action) {
  return extensionsReducer(state, action.responseBody.data, action.responseBody.included);
}

export function getExtensionReducer(state, action) {
  let extensionPackage;

  if (action.responseBody.included && action.responseBody.included.length) {
    extensionPackage = action.responseBody.included[0];
  }

  return extensionReducer(state, action.responseBody.data, extensionPackage);
}

export function getRevisionLibrariesReducer(state, action) {
  let data = action.responseBody && action.responseBody.data;
  if (data && !data.splice) { data = [data]; } // ensure that it is an array

  return state.set('revisionLibraries', data);
}

export function getEndpointPathFromAction(action) {
  const stateKey = action?.payload?.stateKey;
  const endpointName = action?.payload?.name;
  const loadAllPages = action?.payload?.loadAllPages;
  const pageNumber = action?.payload?.urlParams?.['page[number]'] || 1;
  let path = ['api', 'endpoints', endpointName];
  if (loadAllPages) { path.splice(3, 0, 'pages', pageNumber); }
  if (stateKey) { path.splice(1, 0, stateKey); }
  return path;
}

export function endpointPaginationReducer(state, action) {
  const endpointPath = getEndpointPathFromAction(action);
  const path = [...endpointPath, 'pagination'];
  state = state.setIn(
    path,
    action.responseBody?.meta?.pagination || null
  );
  return state;
}

export function endpointDataReducer(state, action) {
  const endpointName = action?.payload?.name;
  const isListEndpoint = apiMappings[endpointName]?.isList;
  const shouldMergeResults = action?.payload?.shouldMergeResults;
  let data = action?.responseBody?.data;

  const endpointPath = getEndpointPathFromAction(action);
  let path = [...endpointPath, 'data'];

  const previousData = state.getIn(path) || null;

  let finalData = shouldMergeResults ? previousData : null; // don't clear out old results if merge is requested
  if (data) { // only set data on a successful request
    if (isListEndpoint) {
      finalData = shouldMergeResults ? [
        ...(previousData || []),
        ...(data || [])
      ] : [
        ...(data || [])
      ];
    } else {
      finalData = shouldMergeResults ? {
        ...(previousData || {}),
        ...(data || {})
      } : {
        ...(data || {})
      };
    }
  };

  state = state.setIn(path, finalData);
  return state;
}

export function endpointIncludedReducer(state, action) {
  const shouldMergeResults = action?.payload?.shouldMergeResults;
  const included = action?.responseBody?.included;

  const endpointPath = getEndpointPathFromAction(action);
  let path = [...endpointPath, 'included'];

  const previousIncluded = state.getIn(path) || null;

  let finalIncluded = shouldMergeResults ? previousIncluded : null;  // don't clear out old results if merge is requested
  if (included) { // only set data on a successful request
    finalIncluded = shouldMergeResults ? [
      ...(previousIncluded || []),
      ...(included || [])
    ] : [
      ...(included || [])
    ];
  };

  state = state.setIn(path, finalIncluded);
  return state;
}
