/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import React, {Component} from 'react';
import Wait from '@react/react-spectrum/Wait';
import {Toast} from '@react/react-spectrum/Toast';
import Button from '@react/react-spectrum/Button';
import Dialog from 'spectrum-alternatives/dialog/Dialog';
import IdleTimer from 'react-idle-timer';
import {isEndpointLoading, getEndpointError} from '../../utils/api/apiTools';
import {actionCreators as imsWrapperActions} from './imsWrapperActions';
import {actionCreators as shellActions} from '../header/shellActions';
import {connect} from 'react-redux';
import makeCancelable from '../../utils/makeCancelable';
import Maintenance from '../maintenance/maintenance';
import {setupLogRocketIdentity} from '../../utils/logrocketUtils';
import {
  loadImsLib,
  getAdobeIms,
  loadUnifiedShellRuntime,
  getShouldUseLocalShell,
  getUnifiedShellUrl,
  updateUnifiedShellTitleByRoutePath,
  createExcRuntime,
  persistLocalShellOverrideSetting,
  getIsInsideCypressAndNotInSubIframe,
  getManualLoginDataFromUrl,
  getBaseImsData,
  getManualLoginDataFromSessionStorage,
  cleanLocationHashAfterLogin,
  getIsImsLibProd
} from './imsWrapperUtils';
import { rootDocumentTitle } from '../../routes/routes';
import { browserHistory } from '../../utils/browserHistoryUtils';
import { isStorageSupported, removeLocalStorageItem } from '../../utils/storageUtils';
import {actionCreators as globalsActions} from './globalsActions';
import { getOrgsFromImsProfile } from '../header/shellSelectors';
import { CAPABILITY_FEATURE_FLAGS } from '../../routes/capabilityUtils';
import queryString from 'query-string';
import { ALLOWED_CONFIG_PARAM_KEYS } from '../../utils/environmentInitUtils';

let externalQueryParams = {};


function filterExternalQueryParams(externalQueryParamsToFilter) {
  const parsedExternalQueryParams = queryString.parse(externalQueryParamsToFilter, {parseBooleans: true});
  Object.keys(parsedExternalQueryParams).forEach(key => {
    if (!ALLOWED_CONFIG_PARAM_KEYS.includes(key)) {
      delete parsedExternalQueryParams[key];
    }
  });
  return parsedExternalQueryParams;
}

class ImsWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoutDanger: false,
      TTL: Infinity,
      initialLoadComplete: false
    };
    this.profileTimeout = null;
    this.loadingPromise = null;
  }
  componentDidMount() {
    if (!isStorageSupported()) {
      this.props.dispatch(globalsActions.setStorageSupported(false));
    }

    this.setState({initialLoadComplete: false});
    this.selectAndUseShell();
  }
  componentWillUnmount = () => {
    clearTimeout(this.profileTimeout);
    if (this.loadingPromise) {
      this.loadingPromise.cancel();
    }
  };
  componentDidUpdate = (prevProps)=>{
    const {profile, loading, imsError, profileError, imsAccessToken} = this.props;
    const initialLoadComplete = Boolean(
      this.state.initialLoadComplete ||
      imsError ||
      (profile || profileError) && !loading
    );

    if (initialLoadComplete !== this.state.initialLoadComplete) {
      this.setState({initialLoadComplete});
    }

    if (prevProps.imsAccessToken !== imsAccessToken) {
      this.profileTimeoutHandler(this.props);
    }
  };

  selectAndUseShell() {
    persistLocalShellOverrideSetting();

    const shouldUseLocalShell = getShouldUseLocalShell();
    if (shouldUseLocalShell) {
      this.useLocalShell();
    } else {
      // check if we need to redirect to the Unified Shell url first
      if (window === window.parent || getIsInsideCypressAndNotInSubIframe()) {
        const unifiedShellUrl = getUnifiedShellUrl();
        console.info('Redirecting to Unified Shell', unifiedShellUrl);
        window.location = unifiedShellUrl;
      } else {
        this.useUnifiedShell();
      }
    }
  }

  useLocalShell() {
    console.info('Using stand-alone shell');

    // user can manually login with a token in the URL
    const manuallyLoggedIn = this.handleManualLogin();
    if (manuallyLoggedIn) {
      return;
    }

    // imslib v2 config
    // https://git.corp.adobe.com/IMS/imslib2.js
    // https://git.corp.adobe.com/pages/IMS/imslib2.js/interfaces/_adobe_id_iadobeiddata_.iadobeiddata.html
    const imsLibEnv = getIsImsLibProd() ? 'prod' : 'stg1';
    const debug = window.environmentSettings.lens_debug;
    const imsConfig = { // AKA adobeid info
      logsEnabled: debug,
      client_id: window.environmentSettings.lens_imsClientId,
      scope: window.environmentSettings.lens_imsScopes.join(','),
      locale: 'en_US',
      environment: imsLibEnv, // https://git.corp.adobe.com/pages/IMS/imslib2.js/enums/_adobe_id_ienvironment_.ienvironment.html
      useLocalStorage: false, // force the use of sessionStorage for CSRF validation
      onAccessTokenHasExpired: () => {
        debug && console.log('IMSLib Access Token Expired');

        getAdobeIms().signIn();
      },
      onReady: async() => {
        debug && console.log('IMSLib ready');

        const adobeIMS = getAdobeIms();
        if (!adobeIMS.isSignedInUser()) {
          adobeIMS.signIn();
        }

        const imsAccessToken = adobeIMS.getAccessToken()?.token;
        const imsProfile = await adobeIMS.getProfile();
        const imsOrganizations = getOrgsFromImsProfile(imsProfile);
        const imsData = {
          ...getBaseImsData(),
          imsAccessToken,
          localShell: {
            imsProfile,
            imsOrganizations
          }
        };
        this.props.dispatch(imsWrapperActions.setIMSData(imsData));

        // enable all capabilities for localShell mode since we don't have Unified Shell's featureFlags available
        const featureFlags = Object.values(CAPABILITY_FEATURE_FLAGS).reduce((featureFlags, current) => ({
          ...featureFlags,
          [current]: 'true'
        }), {});
        this.props.dispatch(globalsActions.setLeftNavFlags(featureFlags));

        externalQueryParams = {};

        // keep the url clean after sign in (IMS redirects sometimes leaves a hash)
        cleanLocationHashAfterLogin();

        this.profileTimeoutHandler(this.props);
      },
      onError: (error) => {
        // regardless of what we show the user, we'll log the full imslib error
        console.log('IMSLib error', error);

        // keep the url clean after sign in (IMS redirects sometimes leaves a hash)
        cleanLocationHashAfterLogin();

        // see imslib error types are here...
        // https://git.corp.adobe.com/IMS/imslib2.js/blob/master/src/adobe-id/IErrorType.ts
        this.props.dispatch(
          imsWrapperActions.setImsError(error?.userMessage || error?.message || 'IMS error')
        );
      }
    };

    // log the config if debug is on
    debug && console.log('IMSLib config', imsConfig);

    loadImsLib(imsConfig);
  }

  handleManualLogin() {
    // handle manual login mode
    const manualLoginData = getManualLoginDataFromUrl();
    const manualLoginDataFromSessionStorage = getManualLoginDataFromSessionStorage();
    const sessionStorageAccessToken = manualLoginDataFromSessionStorage?.access_token;

    // if we have a manual login access token
    if (manualLoginData.accessToken || sessionStorageAccessToken) {
      // tell lens we are signed in and continue
      const shouldPersistSessionData = manualLoginData.accessToken !== sessionStorageAccessToken;
      const imsData = {
        ...getBaseImsData(),
        imsAccessToken: manualLoginData.accessToken || sessionStorageAccessToken,
        expiresIn: manualLoginData.expiresIn || new Date().getTime() + (1000 * 60 * 60 * 24), // 24 hours (the IMS default)
        isManualLogin: true
      };
      this.props.dispatch(imsWrapperActions.setIMSData(imsData, shouldPersistSessionData));

      // keep the url clean after sign in (IMS redirects sometimes leaves a hash)
      cleanLocationHashAfterLogin();

      return true;
    }
  }

  useUnifiedShell() {
    console.info('Using Unified Shell');

    loadUnifiedShellRuntime(()=>{
      // setup the unified shell module runtime
      const excRuntime = createExcRuntime();
      excRuntime.spinner = false; // lens has it's own loading spinner while waiting for shell
      excRuntime.title = rootDocumentTitle;
      excRuntime.solution = {
        icon: 'AdobeExperiencePlatform',
        title: rootDocumentTitle,
        shortTitle: 'Launch'
      };
      excRuntime.nps = {enabled: false, sampling: 500}; // nps popups are annoying
      excRuntime.appContainer = '#exc-control-wrapper';
      // https://git.corp.adobe.com/exc/unified-shell/blob/master/packages/help-center/README.md#options
      excRuntime.helpCenter = {
        // make the Launch Help available on all pages
        featured: [{
          description: 'Tags Documentation',
          href: 'https://experienceleague.adobe.com/docs/experience-platform/tags/home.html',
          label: 'Launch Documentation',
          path: [
            '/:orgId?/data-collection/tags/(.*)?',
            '/:orgId?/data-collection/eventForwarding/(.*)?'
          ]
        }],
        // populate the remaining help items in the "for you" section with
        // launch specific things
        recommendations: {
          enabled: true,
          terms: [{
            path: [
              '/:orgId?/data-collection/tags/(.*)?',
              '/:orgId?/data-collection/eventForwarding/(.*)?'
            ],
            term: 'rules launch'
          }]
        },
        // we could populate FAQ auto-complete here that shows up when a user is typing in the search field
        // questions: ['What is Adobe Launch']
      };

      // On initial signin this event is fired BEFORE the ready event
      // When changing orgs, a new token will be passed in (due to type2e requirements)
      // https://git.corp.adobe.com/exc/unified-shell/wiki/Unified-Shell-Type2e-and-You#2-make-sure-to-use-new-tokens-from-the-unified-shell-when-the-account-changes
      excRuntime.user.on('change:imsToken', (accessToken) => {
        if (accessToken) {
          const imsData = {
            ...getBaseImsData(),
            imsAccessToken: accessToken
          };
          this.props.dispatch(imsWrapperActions.setIMSData(imsData));
        }
      });

      excRuntime.on('ready', (payload) => {
        this.props.dispatch(
          globalsActions.setLeftNavFlags(payload.featureFlags)
        );

        externalQueryParams = filterExternalQueryParams(payload.externalQueryParams);
        if (window.environmentSettings.lens_debug) {
          console.log('excRuntime ready', payload);
        }

        // redirect to data-collection route if we are on the old launch route
        const shellAppName = payload.baseUrl.split('#')?.[1]?.split('/')?.[2];
        if (shellAppName === 'launch') {
          excRuntime.shellRedirect('/data-collection', {discovery: true, replace: true});
        }

        // ensure that no old accessToken info is hanging around in localStorage
        // because the signin process is controlled by Unified Shell
        const clientId = window.environmentSettings.lens_imsClientId;
        removeLocalStorageItem(clientId);

        // use activeOrg from Unified Shell
        const activeOrgId = payload.imsOrg;
        this.props.dispatch(
          shellActions.setActiveOrgFromUnifiedShell(activeOrgId)
        );

        const imsData = {
          ...getBaseImsData(),
          imsAccessToken: payload.imsToken
        };
        this.props.dispatch(imsWrapperActions.setIMSData(imsData));

        // Tell unified shell that the app is done loading and is ready for user interaction.
        // TODO: discover the best way to call this only after ALL requests are done and the entire content is displayed
        excRuntime.done();
      });

      excRuntime.on('history', ({type, path}) => {
        // see https://git.corp.adobe.com/exc/unified-shell/blob/master/docs/integration.md#history
        // for the sample code on how to handle history events

        // ensure that the path starts with a / because it's possible for a relative path to match an absolute path
        // in this case we don't want to redirect because we are already on the page in question
        // EX: 'companies/CO123' === '/companies/CO123'

        const cleanedPath = queryString.stringifyUrl({
          url: (path[0] === '/' ? path : '/' + path),
          // sometimes unifiedshell passes a hisotry event that does not include the query params
          // from the the left side of the url before the hash. This will reapply those params.
          query: externalQueryParams
          // https://github.com/sindresorhus/query-string/issues/305
          // the query string library has issues with %20 in the query params
          // so we manually replace them with the correct character so that we can get a match
        }).replaceAll('%20', '+');
        // type tells us if the history event came from lens (internal) or Unified Shell (external)
        if (
          type === 'external' &&
          `/solutions/${window.environmentSettings.lens_spaPipelineAppId}${browserHistory.location.pathname}${browserHistory.location.search.replaceAll('%20', '+')}` !== cleanedPath
        ) {
          // lens_configUrlParamKeys control's which query params are re-injected into the url
          // if you manually change these in unified shell we need to update what keys are being re-injected
          window.environmentSettings.lens_configUrlParamKeys = Object.keys(
            queryString.parseUrl(cleanedPath).query
          ).filter(
            (paramKey)=> ALLOWED_CONFIG_PARAM_KEYS.includes(paramKey)
          );
          browserHistory.replace(cleanedPath);
          updateUnifiedShellTitleByRoutePath(cleanedPath);
        }
      });

      excRuntime.user.on('change:imsOrg', (orgId) => {
        if (orgId) {
          // note that for type2e accounts, the change:imsToken event (above) will
          // get triggered immediately after this event
          this.props.dispatch(
            shellActions.setActiveOrgFromUnifiedShell(orgId)
          );
        }
      });

      excRuntime.on('configuration', (payload) => {
        this.props.dispatch(
          globalsActions.setLeftNavFlags(payload.featureFlags)
        );

        externalQueryParams = filterExternalQueryParams(payload.externalQueryParams);
      });

      this.profileTimeoutHandler(this.props);
    });
  }

  // The logOutOnError param is for a very specific use case. Typically, when an error occurs
  // when making the profile call, we don't want to log the user out because we want to show
  // the user the error. The one occasion where this isn't the case is when the user's session
  // has expired, we've shown them the dialog that tells them their session has
  // expired (see render() below), the user has clicked the Refresh Session button, and then
  // that first subsequent profile call fails. In this case, we were unable to refresh their
  // session. In the dialog that was shown to the user, we already told them they may have to log
  // in again (refreshing the session doesn't always succeed), so we want to log them out in this
  // case rather than showing them an error message.
  profileTimeoutHandler = ({loading, imsAccessToken, dispatch}, logOutOnError) =>{
    if (
      !this.props.apiUnauthorized &&
      !loading &&
      imsAccessToken
    ) {
      this.loadingPromise = makeCancelable(
        dispatch(imsWrapperActions.getProfile(logOutOnError))
      );

      this.loadingPromise.then((result)=>{
        if (!result.apiUnauthorized) {
          // IMS tokens are good for 24 hours. Some customers have requested/required shorter
          // than 24 hour session times. Blacksmith has a SESSION_EXPIRY environment variable
          // on startup. When this time is passed, on the next request, it revalidates the
          // token with IMS.
          const TTL = 30 * 60 * 1000;
          this.setState({TTL});

          if (!window.environmentSettings.disableImsAuth) {
            clearTimeout(this.profileTimeout);
            this.profileTimeout = setTimeout(()=>{
               // <IdleTimer /> may not be rendered (likely due to a profile load error after having a valid profile)
              if (this.idleTimer?.isIdle()) {
                this.setState({logoutDanger: true});
              } else {
                this.profileTimeoutHandler(this.props);
              }
            // The added 10 seconds helps ensure we make the request AFTER the session has expired
            // on the server. If we were to make the getProfile call before the session had actually
            // expired on the server, then the session wouldn't get refreshed.
            }, TTL + 10000);
          }
        }
      }).catch((err)=>{
        const isCanceled = err && err.isCanceled;
        if (isCanceled) {
          // swallow canceled promise
          return;
        } else {
          Promise.reject(err);
        }
      });
    }
  };
  signInErrorRenderer(error) {
    const {dispatch} = this.props;

    return (
      <div className=" u-textCenter u-paddingVertical100px u-flexOne">
        <Toast variant="error" className="u-textLeft">
          <span className="spectrum-Heading--subtitle1 c-white u-paddingBottomSm">
            Error Loading Account
          </span>
          {error !== 'Failed to fetch' ? (
            <div className="serverMessage">There was a problem signing in. {error ? `"${error}"` : ''}</div>
          ) : null}
          <div className="nextStepsMessage">Your session may have timed out.</div>
        </Toast>
        <div className="u-marginTop">
          <Button
            variant="primary"
            onClick={()=>{
              dispatch(shellActions.logout());
            }}
          >
            Sign In Again
          </Button>
        </div>
      </div>
    );
  }
  storageUnsupportedRenderer() {
    return (
      <div className="userLoadErrorMessage u-flexOne">
        <Toast variant="error" className="u-textLeft">
          <span className="spectrum-Heading--subtitle1 c-white u-paddingBottomSm">
            Browser Support Error
          </span>
          <div className="">Your browser may be blocking Web Storage APIs.</div>
        </Toast>
      </div>
    );
  }
  render = () => {
    const {dispatch, imsError, profileError, imsAccessToken, storageSupported, maintenance} = this.props;
    const imsAuthDisabled = window.environmentSettings.disableImsAuth;

    if (!storageSupported) {
      return this.storageUnsupportedRenderer();
    } else if (maintenance) {
      return <Maintenance />;
    } else if (imsError) {
      return this.signInErrorRenderer(imsError);
    } else if (!imsAccessToken || !this.state.initialLoadComplete || this.postLogout) {
      return <Wait centered size="L"/>;
    } else if (profileError) {
      return this.signInErrorRenderer(profileError);
    } else {
      return (
        <IdleTimer
          ref={ref => (this.idleTimer = ref)}
          timeout={imsAuthDisabled ? Infinity : this.state.TTL + 10001}
        >
          <div className="imsWrapper u-flex u-flexOne">
            {/* after authentication, the main app renders here */}
            {this.props.children}

            <Dialog
              open={this.state.logoutDanger}
              variant="error"
              title="Are you still there?"
              onClose={()=>{
                dispatch(shellActions.logout());
              }}

            >
              <p>
                You have been inactive for over {Math.ceil(this.state.TTL / 1000 / 60)} minutes.
                Your session is expired.
                If your session is more than 24 hours old, you may be required to log in again.
              </p>
              <Dialog.Footer>
                <Button
                  quiet
                  variant="primary"
                  onClick={()=>{
                    dispatch(shellActions.logout());
                  }}
                >
                  Logout
                </Button>
                <Button
                  variant="warning"
                  onClick={()=>{
                    this.profileTimeoutHandler(this.props, true);
                    this.setState({
                      logoutDanger: false
                    });
                  }}
                >
                  Refresh Session
                </Button>
              </Dialog.Footer>
            </Dialog>
          </div>
        </IdleTimer>
      );
    }
  };
};

export default connect((state)=>{
  window.userData = state.ims;
  setupLogRocketIdentity();

  return {
    loading: isEndpointLoading(state, 'getProfile'),
    imsError: state.ims.imsError,
    profileError: getEndpointError(state, 'getProfile'),
    profile: state.api.profile,
    storageSupported: state.globals.storageSupported,
    apiUnauthorized: state.globals.apiUnauthorized,
    imsAccessToken: state.ims.imsAccessToken,
    maintenance: state.globals.maintenanceActive,
    lastLocation: state.router.lastLocation
  };
})(ImsWrapper);
