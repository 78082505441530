/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import actionsHandler from '../../../redux/actionsHandler';
import {actionCreators as apiActions} from '../../../utils/api/apiActions';
import {sortAuditEventsAndParseEntityObjects, getAuditEventsQueryObject} from './recentActivityUtils';
import Immutable from 'seamless-immutable';
import {API_RESOURCE_TYPES} from '../../../utils/api/apiTypes';
const {DATA_ELEMENTS, EXTENSIONS, RULE_COMPONENTS, RULES} = API_RESOURCE_TYPES ;

export const RECENT_ACTIVITY_STATE_KEY = 'recentActivity';


export const SET_RECENT_ACTIVITY_ITEMS = 'recentActivity/SET_RECENT_ACTIVITY_ITEMS';
export const SET_EXTENSION_PACKAGE = 'recentActivity/SET_EXTENSION_PACKAGE';
export const SET_LOADING = 'recentActivity/SET_LOADING';
export const SET_LOADING_ERROR = 'recentActivity/SET_LOADING_ERROR';

//Reducers
export default actionsHandler({
  [SET_RECENT_ACTIVITY_ITEMS]: (state, action) => {
    return state.set('recentActivityItems', action.payload);
  },
  [SET_LOADING]: (state, action) => {
    return state.set('loading', action.payload);
  },
  [SET_LOADING_ERROR]: (state, action) => {
    return state.set('loadingError', action.payload);
  },
  [SET_EXTENSION_PACKAGE]: (state, action) => {
    return state.setIn(['extensionPackages', action.payload.id], action.payload);
  },
  default(state = Immutable({})) {
    return state;
  }
});


//ActionCreators
export let actionCreators = {
  getAuditEvents({params, abortController, limitTotalResultsCount}) {
    return (dispatch, getState) => {
      let userEmail = getState().api.profile.attributes.email;
      let allResults = [];

      dispatch(actionCreators.setLoading(true));

      return Promise.all([
        dispatch(apiActions.apiAction(
          getAuditEventsQueryObject({
            params,
            userEmail,
            resourceType: RULES,
            limitTotalResultsCount,
            abortController
          })
        )).then(()=>{
          allResults = allResults.concat(getState().api[RECENT_ACTIVITY_STATE_KEY]?.elasticSearch?.data || []);
        }),
        dispatch(apiActions.apiAction(
          getAuditEventsQueryObject({
            params,
            userEmail,
            resourceType: DATA_ELEMENTS,
            limitTotalResultsCount,
            abortController
          })
        )).then(()=>{
          allResults = allResults.concat(getState().api[RECENT_ACTIVITY_STATE_KEY]?.elasticSearch?.data || []);
        }),
        dispatch(apiActions.apiAction(
          getAuditEventsQueryObject({
            params,
            userEmail,
            resourceType: EXTENSIONS,
            limitTotalResultsCount,
            abortController
          })
        )).then(()=>{
          allResults = allResults.concat(getState().api[RECENT_ACTIVITY_STATE_KEY]?.elasticSearch?.data || []);
        }),
        dispatch(apiActions.apiAction(
          getAuditEventsQueryObject({
            params,
            userEmail,
            resourceType: RULE_COMPONENTS,
            limitTotalResultsCount,
            abortController
          })
        )).then(()=>{
          allResults = allResults.concat(getState().api[RECENT_ACTIVITY_STATE_KEY]?.elasticSearch?.data || []);
        })
      ]).then(() => {
        let firstTenAuditEvents = sortAuditEventsAndParseEntityObjects(allResults).slice(0, limitTotalResultsCount);
        dispatch(actionCreators.setRecentActivityItems(firstTenAuditEvents));
        dispatch(actionCreators.setLoading(false));

        return dispatch(actionCreators.getExtensionPackagesForAuditEvents({
          auditEvents: firstTenAuditEvents,
          abortController,
          params
        }));

      }).catch(()=>{
        dispatch(actionCreators.setLoadingError(true));
        dispatch(actionCreators.setLoading(false));
      });
    };
  },
  setExtensionPackage(extensionPackage) {
    return {
      type: SET_EXTENSION_PACKAGE,
      payload: extensionPackage
    };
  },
  setLoading(isLoading) {
    return {
      type: SET_LOADING,
      payload: isLoading
    };
  },
  setLoadingError(hasError) {
    return {
      type: SET_LOADING_ERROR,
      payload: hasError
    };
  },
  setRecentActivityItems(recentActivityItems) {
    return {
      type: SET_RECENT_ACTIVITY_ITEMS,
      payload: recentActivityItems
    };
  },
  getExtensionPackagesForAuditEvents({
    auditEvents,
    abortController,
    params
  }) {
    return (dispatch, getState) => {
      return Promise.all(auditEvents.reduce((accumulator, auditEvent) => {
        if (
          auditEvent.attributes.entity.data.type === EXTENSIONS &&
          accumulator.indexOf(auditEvent.attributes.entity.data.relationships.extensionPackage.data.id) < 0
        ) {
          accumulator.push(auditEvent.attributes.entity.data.relationships.extensionPackage.data.id);
          return accumulator;
        }
        return accumulator;
      }, []).map(neededExtensionPackageId => {
        dispatch(apiActions.apiAction({
          name: 'getExtensionPackage',
          stateKey: RECENT_ACTIVITY_STATE_KEY,
          abortSignal: abortController.signal,
          urlData: {
            ...params,
            extensionPackage: neededExtensionPackageId
          }
        })).then(()=>{
          dispatch(actionCreators.setExtensionPackage(getState().api[RECENT_ACTIVITY_STATE_KEY].extensionPackage));
        });
      }));
    };
  }
};
