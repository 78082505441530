/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import {createBrowserHistory as history_createBrowserHistory} from 'history'; // TODO: remove this! modern browsers all support the HTML5 history API

export let browserHistory;
export function createBrowserHistory(createBrowserHistoryOptions) {
  browserHistory = history_createBrowserHistory(createBrowserHistoryOptions);
  return browserHistory;
}
