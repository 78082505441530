/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import React from 'react';
import {Link} from '../../routes/namedRouteUtils';
import {RESOURCE_ID_REGEX, getResourceLocation} from '../../utils/resourceUtils';

// these renderers should default to displaying the "detail" prop if any data is missing
export const ERROR_CODE_RENDERERS = {
  'extension-not-found': ({ detail, resources })=>{
    const extensionPackage = resources.find(resource=>resource.type === 'extensionPackages');
    return extensionPackage ? (
      <div>
        At least one resource requires the "{
          extensionPackage.attributes.displayName
        }" extension to be enabled. Add it to your library and enable it, then rebuild.
      </div>
    ) : detail;
  },
  'minification-error': ({ detail })=>{
    return (
      <div>
        <div>There was an error minifying your custom code.</div>
        <div><code>{detail}</code></div>
      </div>
    );
  },
  'invalid-rule': resourceErrorRenderer,
  'invalid-extension': resourceErrorRenderer,
  'invalid-data_element': resourceErrorRenderer,
  'invalid-extension_package_id': resourceErrorRenderer,
  'upgraded-extension-required': ({ detail, resources })=>{
    const extension = resources[0];
    return extension ? (
      <div>
        The "{extension.attributes.displayName}" extension
        has been upgraded on this property. The upgraded
        version must be included in this library
        by selecting the "latest" revision.
        Add it to your library, then rebuild.
      </div>
    ) : detail;
  },
  unkownError: ({detail})=>{
    return <div>{detail}</div>;
  }
};

function resourceErrorRenderer({ detail, resources, params }) {
  let updatedDetail = replaceResourceNames(detail, resources);
  let detailComponent = getReplacedResourceNamesRenderer(updatedDetail, resources, params);

  return (<div data-private>{detailComponent || updatedDetail}</div>);
};

// replaces resource ids with quoted resource names
function replaceResourceNames(detail, resources) {
  return resources.reduce((updatedDetail, resource)=>{
    const resourceName = resource.attributes.displayName || resource.attributes.name;
    return updatedDetail.replace(resource.id, '"' + resourceName + '"');
  }, detail);
};

// Generic API statusDetails.errors[item] renderer
// Builds a component by splitting the text into an array
// and replacing resource IDs with links or names
function getReplacedResourceNamesRenderer(detail, resources, params) {
  // Use a delimiter to split detail into a list.
  // This allows us to replace each resource id in the list with
  // it's corresponding resource Link or name
  const delimiter = '~split~';
  let detailList = detail.replace(RESOURCE_ID_REGEX, (id)=>{
    return delimiter + id + delimiter;
  }).replace(
    // remove delimiter from start and end of string if necessary
    new RegExp('^' + delimiter + '|' + delimiter + '$', 'g'), ''
  ).split(delimiter);

  if (detailList && detailList.length) {
    detailList = detailList.map((detailPart)=>{
      const foundResource = resources.find(resource=>resource.id === detailPart);
      return foundResource ? getResourceLinkOrName(foundResource, params) : detailPart;
    });

    return <span>{detailList}</span>;
  }
};

let nextKey = 0;
function getResourceLinkOrName(resource, params) {
  let resourceName = resource.attributes.displayName || resource.attributes.name;
  const resourceLocation = getResourceLocation(resource.id, params);
  // convert the resource to a link if possible or use the resources name
  return resourceLocation ? (
    <Link key={nextKey++} className="spectrum-Link" to={resourceLocation}>{resourceName}</Link>
  ) : resourceName;
};
