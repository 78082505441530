/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe Systems Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe Systems Incorporated and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Adobe Systems Incorporated and its
 * suppliers and are protected by all applicable intellectual property
 * laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe Systems Incorporated.
 **************************************************************************/

import Immutable from 'seamless-immutable';
import {ACTIONS, CONDITIONS, EVENTS} from '../../../utils/api/apiTypes';

function sortRuleComponentsByOrder(a, b) {
  const aOrder = a.attributes.order || -1;
  const bOrder = b.attributes.order || -1;
  return aOrder - bOrder;
}

function setRuleComponentOrderValue(ruleComponent, value) {
  return ruleComponent.setIn(['attributes', 'order'], value);
}

export function updateRuleComponentInGroupedComponents(
  groupedComponents, ruleComponentId, updater
) {
  let flattened = flattenGroupedRuleComponents(groupedComponents);
  const index = flattened.findIndex(ruleComponent => ruleComponent.id === ruleComponentId);
  if (index !== -1) {
    flattened = flattened.update(index, updater);
  }

  return groupAndSortRuleComponents(flattened);
}

export function flattenGroupedRuleComponents(groupedComponents) {
  return Immutable(Object.keys(groupedComponents || {}).reduce((accumulator, key)=>{
    return accumulator.concat(groupedComponents[key]);
  },[]));
}

export function getRuleComponentFromGroupedComponents(groupedComponents, ruleComponentId) {
  return flattenGroupedRuleComponents(groupedComponents)
    .find(ruleComponent => ruleComponent.id === ruleComponentId);
}

export function setRuleComponentsOrderAttributeBasedOnSortIndex(groupedComponents) {
  Object.keys(groupedComponents).forEach((componentType) => {
    groupedComponents = groupedComponents.update(
      componentType,
      ruleComponents => ruleComponents.map(setRuleComponentOrderValue)
    );
  });

  return groupedComponents;
}

// This separates actions, conditions, and events into separate arrays
export function groupAndSortRuleComponents(ruleComponents = []) {
  let componentsByComponentType = {
    [EVENTS]: [],
    [CONDITIONS]: [],
    [ACTIONS]: []
  };

  ruleComponents.forEach((ruleComponent)=>{
    componentsByComponentType[ruleComponent.componentType].push(ruleComponent);
  });

  Object.keys(componentsByComponentType).forEach((componentType) => {
    componentsByComponentType[componentType].sort(sortRuleComponentsByOrder);
  });

  return Immutable(componentsByComponentType);
}

export function getAsyncRuleComponentPreludeText(property, previousResource) {
  if (
    property.attributes.ruleComponentSequencingEnabled &&
    previousResource?.attributes?.delayNext
  ) {
    return 'WAIT, THEN';
  } else {
    return 'THEN';
  }
}
