/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

// There are a few reasons why we use this. Some places includes can be kinda hard.
// For example on the rule builder if you get includes for rule_components
// You cannot get extension includes for the included rule components.
// Another reason is your installed extensions will very rarely change.
// setCacheCooldown(false) can be used to make your next checkAndRefreshCache
// call force a refresh.

// The idea with this utility is you call it on every view that needs
// extensions, extension_packages, or delegate_descriptors. And extensions will be
// refreshed when you call checkAndRefreshCache if your cache is more than 5 minutes old.


import {actionCreators as apiActions} from '../../../utils/api/apiActions';
import actionsHandler from '../../../redux/actionsHandler';
import {getApiData} from '../../../utils/api/apiTools';
import Immutable from 'seamless-immutable';

let cacheTimeout;
let cachePromise;
const FIVE_MINUTES = 1000 * 60 * 5;

export const STATE_KEY = 'extensionCache';

export const SET_COOLDOWN = 'extensionCacheBust/SET_COOLDOWN';


//Reducers
export default actionsHandler({
  [SET_COOLDOWN](state, action) {
    if (!action.payload) {
      clearTimeout(cacheTimeout);
    }
    return state.set('cacheCooldownActive', action.payload);
  },
  default(state = Immutable({ cacheCooldownActive: false })) {
    return state;
  }
});

export const actionCreators = {
  setCacheCooldown(cooldownShouldBeActive) {
    return {
      type: SET_COOLDOWN,
      payload: cooldownShouldBeActive
    };
  },
  checkAndRefreshCache(params) {
    return (dispatch, getState)=>{
      if (!getState().extensionCacheBust.cacheCooldownActive) {
        dispatch(actionCreators.setCacheCooldown(true));
        cacheTimeout = setTimeout(()=>{
          dispatch(actionCreators.setCacheCooldown(false));
        }, FIVE_MINUTES);
        return cachePromise = dispatch(
          actionCreators.fetchExtensionsAndExtensionPackagesAndDelegateDescriptors(params)
        ).then(()=>{
          return getApiData(getState(), STATE_KEY);
        });
      } else {
        return cachePromise;
      }
    };
  },
  fetchExtensionsAndExtensionPackagesAndDelegateDescriptors(params) {
    return (dispatch) => {
      return dispatch(apiActions.apiAction({
        name: 'getExtensions',
        stateKey: STATE_KEY,
        urlData: {...params},
        urlParams: {
          include: 'extension_package'
        }
      }));
    };
  }
};
