/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import React, {PureComponent} from 'react';
import {
  Route,
  Redirect,
  Switch,
  useParams,
  useHistory,
} from 'react-router';
import {
  getNamedRoute,
  populateNamedRoutePath,
  formatRoutePath
} from './namedRouteUtils';
import DocumentTitle from '../utils/DocumentTitle.jsx';
import { useSelector } from 'react-redux';
import { getCurrentLocationFromState } from './routeSelectors';


export const ROUTE_TYPES = {
  ROUTE: 'route',
  REDIRECT: 'redirect'
};

//This is route is used for a parent route when the parent has no component to render
function AmbiguousRoute({getChildren, ...rest}) {
  return getChildren(rest);
};


function RouteRenderer({
  parentRouteProps,
  route,
  routePath
}) {
  let params = useParams();
  let location = useSelector(getCurrentLocationFromState);
  let history = useHistory();
  // routeProps are props that contain route specific information
  // location, match, history... these come from react-router during the match
  const RenderComponent = route.component ? route.component : AmbiguousRoute;
  return (
    // components no longer simply return built children but they return a getter
    // for children that enables you to extend the props provided to children
    // react-router 4 moved params inside a match object we are putting that back
    // on the route components here so we can use it the same way we used to
    <DocumentTitle title={getNamedRoute(route.name).title}>
      <RenderComponent
        {...parentRouteProps}
        location={location}
        params={params}
        // TODO: this match stuff should be cleand up in fact all the hooks should be moved to the views that actually use them
        match={{params}}
        history={history}
        getChildren={(viewProps = {})=>(
          // viewProps are props provided by the view thats rendering the
          // matched route component. such as baseLayout

          // pass the sub-routes down to keep nesting
          route.subRoutes ? (
            <RoutesWithSubRoutes
              routes={route.subRoutes}
              additionalRouteProps={{ location, params, match:{params}, history, ...viewProps}}
              location={location}
              basePath={formatRoutePath(routePath)}
            />
          ) : null
        )}
      />
    </DocumentTitle>
  );
}


// DONT MOVE THIS OUT OF THIS FILE! It's recursive!
const RouteFromConfig = ({route, routeKey, parentRouteProps, basePath})=> {
  // parentRouteProps are additional props that are either passed from a
  // parent route that was recursively created or from props being added
  // to the initial RoutesWithSubRoutes creation
  const routePath = basePath + route.path;
  switch (route.type) {
    case ROUTE_TYPES.REDIRECT:
      return (
        <Redirect
          key={routeKey}
          push={route.push}
          from={route.path}
          to={
            route.to ||
            (
              route.getTo && route.getTo({props:parentRouteProps})
            ) ||
            route.toNamedRoute &&
            populateNamedRoutePath({
              name:route.toNamedRoute,
              params:parentRouteProps.match.params
            })
          }
        />
      );

    default:
      return (
        <Route
          key={routeKey}
          path={routePath}
          exact={route.exact}
        >
          <RouteRenderer
            parentRouteProps={parentRouteProps}
            route={route}
            routePath={routePath}
          />
        </Route>
      );
  }
};

// this renders all routes in a routes config all routes can also include
// an array of subRoutes which will recursively be nested through this function
export class RoutesWithSubRoutes extends PureComponent {
  render = () => {
    const {
      routes,
      additionalRouteProps,
      location,
      basePath = ''
    } = this.props;

    return (
      <Switch location={location}>
        {
          routes.map((route, index)=> (
            RouteFromConfig({
              key:index,
              route:route,
              routeKey:index,
              parentRouteProps:additionalRouteProps,
              basePath:basePath,
            })
          ))
        }
      </Switch>
    );
  };
}
