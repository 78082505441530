/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import Immutable from 'seamless-immutable';
import {get} from 'lodash-es';

export function buildSaveData(extensionPackage) {
  return Immutable({
    type: 'extensions',
    attributes: {
      delegateDescriptorId: get(extensionPackage, 'attributes.configuration.id'),
      revision: false,
      settings: null
    },
    relationships: {
      extensionPackage: { data: { id: get(extensionPackage, 'id'), type: 'extension_packages' } }
    }
  });
}
