/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/


import apiMappings from './apiMappings';
import {API_CALL, actionCreators as apiActions} from './apiActions';
import {API_CALL_LOAD_ALL_PAGES_DONE} from './apiLoadAllPagesUtils';
import {
  populateUrl,
  getAppError,
  APP_ERROR_CODES
} from './apiTools';
import {getActiveOrgId} from '../../components/header/shellSelectors';

import {
  apiResponseBodyNormalizer,
  apiRequestBodyNormalizer,
  elasticSearchRequestBodyNormalizer
} from './apiNormalizers';
import {actionCreators as toastsActions} from '../../components/higherOrderComponents/toastsActions';

export function apiMiddleware(store) {
  return function apiMiddlewareNext(next) {
    return function apiMiddlewareAction(action) {
      if (
        !action.type ||
        action.type === API_CALL_LOAD_ALL_PAGES_DONE ||
        action.type.indexOf(API_CALL) < 0 || // not an api call
        action.type.indexOf('_LOADING') >= 0 ||
        action.type.indexOf('_SUCCESS') >= 0 ||
        action.type.indexOf('_FAIL') >= 0
      ) {
        return next(action);
      }
      // Reference apiActions.getApiAction for documentation on these params.
      const {
        name,
        data,
        urlData,
        urlParams,
        headers,
        updateState,
        bypassError,
        abortSignal,
        swallowAbortErrors,
        stateKey,
        translateCase
      } = action.payload;

      const LOADING = `${API_CALL}_${name.toUpperCase()}_LOADING`;
      const SUCCESS = `${API_CALL}_${name.toUpperCase()}_SUCCESS`;
      const FAIL = `${API_CALL}_${name.toUpperCase()}_FAIL`;

      let {url, verb, params} = apiMappings[name];

      url = populateUrl(url, urlData, {...params, ...urlParams});

      if (updateState) {
        store.dispatch({
          ...action,
          type: LOADING
        });
      }

      const {imsAccessToken, clientId} = store.getState().ims;
      let requestOptions = {
        method: verb.toUpperCase(),
        headers: {
          'accept': 'application/vnd.api+json;revision=1',
          'authorization': 'Bearer ' + imsAccessToken,
          'x-api-key': clientId,
          'content-type': 'application/vnd.api+json',
          ...headers
        },
        mode: 'cors'
      };

      // This header will be empty if the org is not saved in localStorage and
      // the profile isn't yet loaded OR if the user is not authorized on any orgs.
      const activeOrgId = getActiveOrgId(store.getState());
      if (!requestOptions.headers['x-gw-ims-org-id']) {
        requestOptions.headers['x-gw-ims-org-id'] = activeOrgId || '';
      }

      if (data) {
        if (name === 'elasticSearch') {
          requestOptions.body = JSON.stringify(
            elasticSearchRequestBodyNormalizer(data)
          );
        } else {
          requestOptions.body = JSON.stringify(
            apiRequestBodyNormalizer(data, translateCase)
          );
        }
      }

      if (abortSignal) {
        requestOptions.signal = abortSignal;
      }

      return (new Promise((resolve, reject) => {
        const promise = fetch(url, requestOptions);

        promise.then(()=>{
          return resolve(promise);
        }).catch((error) => {
          if (swallowAbortErrors && error.name === 'AbortError') {
            // swallow abort errors
          } else {
            return reject(error);
          }
          // when an API request is aborted this is where we should end the promise chain
          store.dispatch(apiActions.resetEndpoint(name, stateKey));
        });
      })).catch((error) => {
        if (!bypassError) {
          // handle unknown network issues
          store.dispatch(toastsActions.addToast({
            variant: 'error',
            error: getAppError({
              lensCode: APP_ERROR_CODES.NETWORK_ERROR,
              message: error.message
            })
          }));
        }

        return Promise.reject(error);
      }).then((response) => {
        // handle response issues
        return store.dispatch(apiActions.handleFetchResponseErrors(response, bypassError));
      }).catch((error) => {
        if (updateState && error.status !== 403) {
          store.dispatch({
            ...action,
            type: FAIL,
            error
          });
        }

        return Promise.reject(error);
      }).then(function apiRequestSuccess(response) {
        // handle 204 no content responses
        let jsonPromise = response.status === 204 ? Promise.resolve({}) : response.json();
        return Promise.all([jsonPromise, response]);
      }).then(function apiRequestJsonParsed([body, response]) {
        // For some reason when you try to spread the properties off
        // the response object they dont end up getting copied over.
        // So we are just manually copying all the properties.
        const res = apiResponseBodyNormalizer({
          headers: response.headers,
          ok: response.ok,
          redirected: response.redirected,
          status: response.status,
          statusText: response.statusText,
          type: response.type,
          url: response.url,
          useFinalURL: response.useFinalURL,
          bodyUsed: response.bodyUsed,
          body
        }, translateCase);

        if (updateState) {
          store.dispatch({
            ...action,
            type: SUCCESS,
            responseBody: res.body
          });
        }

        return {res, action};
      });
    };
  };
}
