/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import React from 'react';
import actionsHandler from '../../redux/actionsHandler';
import Immutable from 'seamless-immutable';
import GlobalKeyValueStore from '../../utils/globalKeyValueStore';
import {getActionCreators as getDialogsActions} from './dialogsActions';
import {getAppError} from '../../utils/api/apiTools';
import {getToastsFromState, getErrorToastsFromState} from './toastsSelectors';
import {getUID} from '../../utils/dataUtils';

export const TOASTS_ERRORS_KEY = 'toastsErrors';
export const ERROR_DETAILS_DIALOG_KEY = 'errorDetailsDialog';
const dialogsActions = getDialogsActions(TOASTS_ERRORS_KEY);

export const ADD_TOAST = 'toastsManager/ADD_TOAST';
export const REMOVE_TOAST = 'toastsManager/REMOVE_TOAST';
export const UPDATE_TOAST = 'toastsManager/UPDATE_TOAST';

let toastTimeouts = {};

//Reducers
export default actionsHandler({
  [ADD_TOAST](state, action) {
    return state.concat({
      id: action.payload.id,
      time: (new Date()).getTime(),
      variant: action.payload.variant || 'info',
      closable: action.payload.closable,
      error: action.payload.error,
      timeout: action.payload.timeout,
      timeoutStarted: action.payload.timeoutStarted,
      republish: action.payload.republish,
      propertyId: action.payload.propertyId
    });
  },
  [UPDATE_TOAST](state, action) {
    const toastIndex = state.findIndex(toast=>toast.id === action.payload.id);
    return state.set(toastIndex, action.payload.toast);
  },
  [REMOVE_TOAST](state, action) {
    return state.filter(toast=>toast.id !== action.payload.id);
  },
  default(state = Immutable([])) {
    return state;
  }
});

//Action Creators
export let actionCreators = {
  addToast({
    variant = 'info',
    timeout,
    closable = true,
    onClose,
    renderer,
    lensCode,
    message,
    error,
    republish = false,
    propertyId
  }) {
    return function(dispatch, getState) {
      const preexistingToasts = getToastsFromState(getState());

      const time = new Date().getTime();
      const id = getUID();
      let toast = republish ?
        { id, time, variant, closable, timeout, republish, propertyId } :
        { id, time, variant, closable, timeout };

      // ensure we have a standard appError object
      if (variant === 'error') {
        toast.error = (
          (!error || !error?.message) ?
          getAppError({ lensCode, message }) :
          error
        );
      }

      if (renderer || message) {
        GlobalKeyValueStore.set(id, {
          variant: toast.variant,
          closable: toast.closable,
          onClose: ()=>{
            dispatch(actionCreators.removeToast(id));
            if (onClose) { onClose(); }
          },
          children: renderer || message
        });
      }

      if (timeout) {
        toast.timeoutStarted = false;

        if (!preexistingToasts.length || toast.variant === 'error') {
          toast.timeoutStarted = true;
          toastTimeouts[id] = setTimeout(()=>{
            dispatch(actionCreators.removeToast(id));
          }, timeout);
        }
      }

      dispatch({
        type: ADD_TOAST,
        payload: toast
      });

      return id;
    };
  },
  removeToast(id) {
    return function(dispatch, getState) {
      clearTimeout(toastTimeouts[id]);

      dispatch({
        type: REMOVE_TOAST,
        payload: { id }
      });

      GlobalKeyValueStore.remove(id);

      const state = getState();
      const toasts = getToastsFromState(state);

      const errorToasts = getErrorToastsFromState(state);
      // if all errors have been removed then close the errors dialog
      if (!errorToasts.length) {
        dispatch(dialogsActions.closeDialog(ERROR_DETAILS_DIALOG_KEY));
      }

      // start the next toast's timeout
      let nextToast = toasts[0];
      if (
        nextToast &&
        typeof nextToast.timeout !== 'undefined' &&
        !nextToast.timeoutStarted
      ) {
        nextToast = nextToast.set('timeoutStarted', true);

        dispatch({
          type: UPDATE_TOAST,
          payload: {
            id: nextToast.id,
            toast: nextToast
          }
        });

        toastTimeouts[nextToast.id] = setTimeout(()=>{
          dispatch(actionCreators.removeToast(nextToast.id));
        }, nextToast.timeout);
      }
    };
  },
  removeErrorToasts() {
    return function(dispatch, getState) {
      const errorToasts = getErrorToastsFromState(getState());

      errorToasts.forEach((errorToast)=>{
        dispatch(actionCreators.removeToast(errorToast.id));
      });

      dispatch(dialogsActions.closeDialog(ERROR_DETAILS_DIALOG_KEY));
    };
  },
  setErrorDetailsDialogOpen(isOpen) {
    return function(dispatch) {
      return (
        isOpen ?
        dispatch(dialogsActions.openDialog(ERROR_DETAILS_DIALOG_KEY)) :
        dispatch(dialogsActions.closeDialog(ERROR_DETAILS_DIALOG_KEY))
      );
    };
  }
};

export const exposeToastActions = (dispatch) => {
  window.addToast = ({variant, timeout, closable, message, onClose, error}) => {
    const renderer = variant === 'error' ? null : (<span>{message}</span>);
    const lensCode = variant === 'error' ? 'externalError' : undefined;
    return dispatch(actionCreators.addToast({
      variant, timeout, closable, lensCode, message, error, onClose, renderer
    }));
  };
  window.removeToast = (toastId) => {
    dispatch(actionCreators.removeToast(toastId));
  };
};
