import paginationReducers, {SET_PAGE_DATA} from '../../components/pagination/paginationActions.js';
import selectableListReducer, {SET_SELECTABLE_ITEMS} from '../../components/controlledList/selectableListActions.js';
import { RESET_ENDPOINT } from './apiActions';
import apiMappings from './apiMappings';

export const ENDPOINT_GROUP_KEYS = {
  COMPANIES: 'company',
  PROPERTIES: 'property',
  RULES: 'rule',
  RULE_COMPONENTS: 'ruleComponent',
  DATA_ELEMENTS: 'dataElement',
  EXTENSIONS: 'extension',
  REVISIONS: 'revision',
  REVISION_LIBRARIES: 'revisionLibrary',
  EXTENSION_PACKAGES: 'extensionPackage',
  ENVIRONMENTS: 'environment',
  HOSTS: 'host',
  SECRETS: 'secret',
  LIBRARIES: 'library',
  RULE_LIBRARY_RESOURCES: 'ruleLibraryResources',
  DATA_ELEMENT_LIBRARY_RESOURCES: 'dataElementLibraryResources',
  EXTENSION_LIBRARY_RESOURCES: 'extensionLibraryResources',
  BUILDS: 'build',
  UPSTREAM_BUILDS: 'upstreamBuild',
  UPSTREAM_RULES: 'upstreamRules',
  UPSTREAM_DATA_ELEMENTS: 'upstreamDataElements',
  UPSTREAM_EXTENSIONS: 'upstreamExtensions',
  PROPERTY_NOTES: 'propertyNote',
  RULE_NOTES: 'ruleNote',
  RULE_COMPONENT_NOTES: 'ruleComponentNote',
  DATA_ELEMENT_NOTES: 'dataElementNote',
  SECRET_NOTES: 'secretNote',
  EXTENSION_NOTES: 'extensionNote',
  LIBRARY_NOTES: 'libraryNote',
  AUDIT_EVENTS: 'auditEvents',
  APP_CONFIGURATIONS: 'appConfiguration',
  APP_SURFACES: 'appSurface',
  APP_SURFACES_APP_CONFIGURATIONS: 'appSurfaceappConfiguration',
  APP_SURFACES_APP_CONFIGURATIONS_RELATIONSHIPS: 'appSurfaceappConfigurationRelationship',
  PROFILE: 'profile',
  ENTITLEMENTS: 'entitlement',
};

export const LIST_RESOURCE_MAPPING_KEYS = {
  COMPANIES: 'companies',
  PROPERTIES: 'properties',
  RULES: 'rules',
  RULE_COMPONENTS: 'ruleComponents',
  DATA_ELEMENTS: 'dataElements',
  EXTENSIONS: 'extensions',
  REVISIONS: 'revisions',
  REVISION_LIBRARIES: 'revisionLibraries',
  EXTENSION_PACKAGES: 'extensionPackages',
  ENVIRONMENTS: 'environments',
  HOSTS: 'hosts',
  SECRETS: 'secrets',
  LIBRARIES: 'libraries',
  LIBRARY_RULE_RESOURCES: 'libraryRuleResources',
  LIBRARY_DATA_ELEMENT_RESOURCES: 'libraryDataElementResources',
  LIBRARY_EXTENSION_RESOURCES: 'libraryExtensionResources',
  BUILDS: 'builds',
  UPSTREAM_RULES: 'upstreamRules',
  UPSTREAM_DATA_ELEMENTS: 'upstreamDataElements',
  UPSTREAM_EXTENSIONS: 'upstreamExtensions',
  PROPERTY_NOTES: 'propertyNotes',
  RULE_NOTES: 'ruleNotes',
  RULE_COMPONENT_NOTES: 'ruleComponentNotes',
  DATA_ELEMENT_NOTES: 'dataElementNotes',
  SECRET_NOTES: 'secretNotes',
  EXTENSION_NOTES: 'extensionNotes',
  LIBRARY_NOTES: 'libraryNotes',
  AUDIT_EVENTS: 'auditEvents',
  APP_CONFIGURATIONS: 'appConfigurations',
  APP_SURFACES: 'appSurfaces',
  APP_SURFACES_APP_CONFIGURATIONS: 'appSurfaceappConfigurations',
  APP_SURFACES_APP_CONFIGURATIONS_RELATIONSHIPS: 'appSurfaceappConfigurationRelationships',
};

export function reduceToApiSlice(reducer) {
  return (state, action)=> {
    action = action.responseBody ? action : {...action, responseBody: {}};
    const stateKey = action.payload && action.payload.stateKey;

    if (stateKey && state.api[stateKey]) {
      state = state.setIn(['api', stateKey], reducer(state.api[stateKey], action));
    } else {
      // TODO Remove once everything is using stateKey.
      state = state.set('api', reducer(state.api, action));
    }

    return state;
  };
}

function listItemsReducer(state, action) {
  if (!action.payload.paginationMeta) {
    return state;
  }

  let pagination = state.pagination;
  if (action.payload.updatePaginationState) {
    // at some point we could separate stateKey into paginationKey and
    // selectionsKey so You can have a list without selections.
    pagination = paginationReducers(state.pagination, {
      type: SET_PAGE_DATA,
      payload: {
        pageMeta: action.payload.paginationMeta,
        listItems: action.payload.listItems,
        stateKey: action.payload.stateKey,
        pageSize: action.payload.pageSize
      }
    });
  }

  // NOTE: not a current problem for selectionsActions, but using the same stateKey from outside the pagination/list/selections actions flow
  // (using the same stateKey during something like a raw apiActions.apiAction call) could lead to unexpected state updates
  // through a race condition as the different calls resolve. Solved the pagination use-case as a small iterative step.
  // re-evaluate action.payload.updatePaginationState variable name and whether we would want it to cover this case too
  // or introduce its own key within the action payload.
  const selections = selectableListReducer(state.selections, {
    type: SET_SELECTABLE_ITEMS,
    payload: {
      stateKey: action.payload.stateKey,
      selectableItems: action.payload.listItems
    }
  });

  return state.merge({
    pagination,
    selections
  });
}

// this function requires mappingLocation on the apiMapping that used this reducer
export function getListReducer() {
  return (state, action)=> {
    const payload = action.payload || {};
    const remapApiDataKey = apiMappings[action.payload.name].mappingLocation || action.payload.mappingLocation;

    if (!remapApiDataKey) {
      console.error(`The api mapping ${action.payload.name} is missing a 'mappingLocation' key`);
    }

    if (action.type === RESET_ENDPOINT) {
      return state;
    }

    const data = action?.responseBody?.data;
    const meta = (
      action?.responseBody?.meta?.pagination
    );
    const stateKey = payload?.stateKey;

    let listItems;

    if (stateKey) {
      listItems = payload.shouldMergeResults ? [
        ...(state.api[stateKey] && state.api[stateKey][remapApiDataKey] || []),
        ...data
      ] : data;
      state = state.setIn(['api', stateKey, remapApiDataKey], listItems);
    } else {
      // TODO Remove once everything is using stateKey.
      listItems = payload.shouldMergeResults ? [
        ...(state.api[remapApiDataKey] || []),
        ...data
      ] : data || [];
      state = state.setIn(['api', remapApiDataKey], listItems);
    }

    return state.merge(
      listItemsReducer(state, {
        payload: {
          endpointGroupKey: payload.endpointGroupKey,
          stateKey: payload.stateKey,
          pageSize: payload.pageSize,
          listItems,
          paginationMeta: meta,
          updatePaginationState: payload.updatePaginationState
        }
      })
    );
  };
}

export function elasticSearchReducer(state, action) {
  const {
    payload = {},
    responseBody = {},
  } = action;

  if (!payload.stateKey) {
    console.error(new Error('stateKey is required for search endpoint call to store results.'));
    return state;
  }

  let resultsPath = ['api', payload.stateKey, 'elasticSearch'];
  if (payload.endpointGroupKey) { // endpointGroupKey is if you want to make several searches under 1 state key.
    resultsPath.push(payload.endpointGroupKey);
  }

  // opting into keys rather than spreading everything.
  const { data = [], meta = {} } = responseBody;
  state = state.setIn([...resultsPath], { data, meta });

  if (action.payload.mappingLocation) {
    action.responseBody.meta = getPaginationMetaFromSearchMeta(state, action);
    return getListReducer()(state,action);
  }
  return state;
}

function getPaginationMetaFromSearchMeta(state, action) {
  const startIndex = action.payload.data.data.from;
  const pageSize = state.pagination[action.payload.stateKey].pageSize;
  const totalHits = action.responseBody.meta.totalHits;
  const totalPages = Math.ceil(totalHits / pageSize);
  const currentPage = (startIndex / pageSize) + 1;

  return {
    pagination: {
      current_page: currentPage,
      next_page: currentPage === totalPages ? null : currentPage + 1,
      prev_page: currentPage - 1 || null,
      total_pages: totalPages,
      total_count: totalHits
    }
  };
}

export function companyMetricsReducer(state, action) {
  const {
    payload = {},
    responseBody = {},
  } = action;

  if (!payload.stateKey) {
    console.error(new Error('stateKey is required for monitoring endpoints to store results.'));
    return state;
  }

  const endpointName = payload.name;
  let resultsPath = ['api', payload.stateKey, endpointName];

  // opting into keys rather than spreading everything.
  const { data = [] } = responseBody;
  state = state.setIn([...resultsPath], { data });

  return state;
}
