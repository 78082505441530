/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

export default {
  genericError: 'Sorry, there was an error. Please try again.',
  companyCount: `
            {totalCount, plural,
              =0 {No companies to display}
              =1 {Displaying # company}
              other {Displaying {start} - {end} of {totalCount}}
            }`,
  propertyCount: `
            {totalCount, plural,
              =0 {No properties to display}
              =1 {Displaying # property}
              other {Displaying {start} - {end} of {totalCount}}
            }`,
  userCount: `
            {userCount, plural,
              =0 {No registered users to display}
              =1 {Displaying # registered user}
              other {Displaying all # registered users}
            }`,
  groupCount: `
            {groupCount, plural,
              =0 {No groups to display}
              =1 {Displaying # group}
              other {Displaying all # groups}
            }`,
  dataElementCount: `
            {totalCount, plural,
              =0 {No data elements to display}
              =1 {Displaying # data element}
              other {Displaying {start} - {end} of {totalCount}}
            }`,
  ruleCount: `
            {totalCount, plural,
              =0 {No rules to display}
              =1 {Displaying # rule}
              other {Displaying {start} - {end} of {totalCount}}
            }`,
  environmentCount: `
            {totalCount, plural,
              =0 {No environments to display}
              =1 {Displaying # environment}
              other {Displaying {start} - {end} of {totalCount}}
            }`,
  hostCount: `
            {totalCount, plural,
              =0 {No hosts to display}
              =1 {Displaying # host}
              other {Displaying {start} - {end} of {totalCount}}
            }`,
  extensionAddedCount: `
            {totalCount, plural,
              =0 {No extensions installed}
              =1 {# extension installed}
              other {Displaying {start} - {end} of {totalCount}}
            }`,
  extensionsAvailableCount: `
            {totalCount, plural,
              =0 {No extensions available}
              =1 {# extension available}
              other {Displaying {start} - {end} of {totalCount}}
            }`,
  regexMatchCount: `
            {count, plural,
              =0 {No match}
              =1 {{count} match}
              other {{count} matches}
            }`,
  host: 'host',
  hostTitle: 'Host',
  build: 'build',
  buildTitle: 'Build',
  company: 'company',
  companyTitle: 'Company',
  dataElement: 'data element',
  dataElementTitle: 'Data Element',
  environment: 'environment',
  environmentTitle: 'Environment',
  extension: 'extension',
  extensionTitle: 'Extension',
  extensionPackage: 'extension package',
  extensionPackageTitle: 'Extension Package',
  note: 'note',
  group: 'group',
  groupTitle: 'Group',
  library: 'library',
  libraryTitle: 'Library',
  property: 'property',
  propertyTitle: 'Property',
  rule: 'rule',
  ruleTitle: 'Rule',
  ruleComponent: 'rule component',
  ruleComponentTitle: 'Rule Component',
  user: 'user',
  userTitle: 'User',
  created: 'created',
  updated: 'updated',
  deleted: 'deleted',
  createdTitle: 'Created',
  updatedTitle: 'Updated',
  deletedTitle: 'Deleted',
  auditEventActionDescription: '{action} by {actor}',
  invalidPattern: 'Invalid pattern',
  succeededTitle: 'Success',
  failedTitle: 'Failed',
  pendingTitle: 'Pending',
  plaintextTitle: 'Plain Text',
  jsonTitle: 'JSON',
  htmlTitle: 'HTML',
  cssTitle: 'CSS',
  javascriptTitle: 'JavaScript',
  none: 'None',
  pageview: 'Pageview',
  session: 'Session',
  visitor: 'Visitor'
};
