/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import DeviceDesktop from '@react/react-spectrum/Icon/DeviceDesktop';
import DevicePhone from '@react/react-spectrum/Icon/DevicePhone';
import ServersIcon from '@react/react-spectrum/Icon/Servers';

export const WEB_PROPERTY = 'web';

export const MOBILE_PROPERTY = 'mobile';

export const EDGE_PROPERTY = 'edge'; // aka (SSF, Server Side, Event Forwarding)

const PROPERTY_TYPES = [WEB_PROPERTY, MOBILE_PROPERTY, EDGE_PROPERTY];

export const getPropertyTypeIcon = type => {
  if (type === MOBILE_PROPERTY) {
    return DevicePhone;
  } else if (type === EDGE_PROPERTY) {
    return ServersIcon;
  } else {
    return DeviceDesktop;
  }
};

export const getPropertyType = state => {
  const type = state.api.property ? state.api.property.attributes.platform : '';
  return PROPERTY_TYPES.indexOf(type) > -1 ? type : null;
};
