/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import React, {Component} from 'react';
import {connect} from 'react-redux';
import Button from '@react/react-spectrum/Button';
import CopyIcon from '@react/react-spectrum/Icon/Copy';
import {Toast} from '@react/react-spectrum/Toast';
import { CSSTransition } from 'react-transition-group';
import {animationVars, getTimingForReact} from '../../utils/animationUtils';
import {actionCreators as toastsActions, TOASTS_ERRORS_KEY, ERROR_DETAILS_DIALOG_KEY} from './toastsActions';
import GlobalKeyValueStore from '../../utils/globalKeyValueStore';
import Dialog from 'spectrum-alternatives/dialog/Dialog';
import {FormattedDate} from 'react-intl';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import CustomTable from '../CustomTable/CustomTable';
import classNames from 'classnames';
import {actionCreators as getExpandedActions} from '../CustomTable/expandedActions';
import {getToastsFromState, getErrorToastsFromState} from './toastsSelectors';
import {getActiveOrg} from '../header/shellSelectors';
import { getCompanyFromState } from '../layouts/company/companySelectors';

// this would be ideal, but it makes webpack explode (due to a circular dependency references)
// as a work-around we'll call getExpandedActions() as needed which solves the compile timing issue
// const expandedActions = getExpandedActions(TOASTS_ERRORS_KEY);

export class ToastRenderer extends React.Component {
  componentWillUnmount() {
    if (this.props.onExited) {
      this.props.onExited();
    }
  }

  render() {
    const {
      variant,
      closable,
      onClose,
      actionLabel,
      onAction,
      children
    } = this.props;

    return (
      <Toast
        className="toast"
        variant={variant}
        closable={closable}
        onClose={onClose}
        actionLabel={actionLabel}
        onAction={onAction}
      >
        {children}
      </Toast>
    );
  }
};

class Toasts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoveredErrorItem: null,
      copiedToClipboard: null,
      toastsParentShouldBeMounted: Boolean(props.toasts?.length || props.errorToasts?.length)
    };
    this.hoveredErrorItemTimeout = null;
  }

  componentDidUpdate(prevProps) {
    const { props } = this;

    const hadToasts = Boolean(prevProps?.toasts?.length || prevProps?.errorToasts?.length);
    const nowHasToasts = Boolean(props?.toasts?.length || props?.errorToasts?.length);

    // let the component know that we need the parent node to throw the toasts into
    if (!hadToasts && nowHasToasts) {
      this.setState({ toastsParentShouldBeMounted: true });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.hoveredErrorItemTimeout);
    clearTimeout(this.onCopyTimeout);
  }

  errorItemMouseEnter = (index) => {
    clearTimeout(this.hoveredErrorItemTimeout);
    this.setState({hoveredErrorItem: index});
  };

  errorItemMouseLeave = () => {
    clearTimeout(this.hoveredErrorItemTimeout);
    this.hoveredErrorItemTimeout = setTimeout(()=>{
      this.setState({hoveredErrorItem: null});
    }, 1000);
  };

  onCopy = (copiedKey) => {
    this.setState({ copiedToClipboard: copiedKey });
    clearTimeout(this.onCopyTimeout);
    this.onCopyTimeout = setTimeout(()=>{
      this.setState({copiedToClipboard: null});
    }, 2000);
  };

  appErrorCellRenderer = (tableProps)=>{
    const toast = tableProps.rowData;

    return (
      <div
        className={classNames(
          'collapsedRowContent',
          'u-ellipsis',
          'u-cursorDefault'
        )}
      >
        <FormattedDate value={toast.time} format="numericDateTime" />
        {toast.error.message ? ' - ' + toast.error.message : ''}
      </div>
    );
  };

  expandedRowRenderer = (tableProps)=>{
    const toasts = tableProps.rows;
    const toast = tableProps.rowData;
    const index = toasts.findIndex(_toast=>_toast.id === toast.id);
    const {hoveredErrorItem, copiedToClipboard} = tableProps.rendererData;

    const errorTextToCopy = toast.error.message + '\r\n\r\n' +
      'Details: \r\n' +
      JSON.stringify(toast.error, null, '  ');

    return (
      <tr className="spectrum-Table-row">
        <td className="spectrum-Table-cell u-noPadding" colSpan={tableProps.columns.length}>
          <div
            className={classNames(
              'expandedRowContent',
              'u-backgroundDefaultLt5',
              'u-cursorDefault'
            )}
          >
            <div
              className="errorDetails u-padding u-relativePosition"
              onMouseEnter={()=>{ this.errorItemMouseEnter(index); }}
              onMouseLeave={()=>{ this.errorItemMouseLeave(); }}
              key={index}
            >
              <div className="u-flex">
                {hoveredErrorItem === index ? (
                  <div className="actions u-absolutePosition u-top u-right u-paddingRightXs">
                    <CopyToClipboard
                      text={errorTextToCopy}
                      onCopy={()=>{ this.onCopy(index); }}
                    >
                      <Button
                        quiet
                        variant="primary"
                        icon={copiedToClipboard === index ? null : <CopyIcon size="S" />}
                        disabled={copiedToClipboard === index}
                      >
                        {copiedToClipboard === index ? 'Copied' : 'Copy'}
                      </Button>
                    </CopyToClipboard>
                  </div>
                ) : null}
              </div>

              <div className="errorMessage">
                {toast.error.message}
              </div>

              <div className="u-marginTop">
                <strong>Details:</strong>
                <div>
                  <pre className="u-overflowXAuto">
                    <code>{JSON.stringify(toast.error, null, '  ')}</code>
                  </pre>
                </div>
              </div>
            </div>

          </div>
        </td>
      </tr>
    );
  };

  getCustomerCareEmailLink() {
    const {errorToasts, activeOrg, company, property, imsUserId, imsUserName} = this.props;

    const emailLinkBase = 'mailto:customercare@adobe.com?subject=' + encodeURIComponent('Launch Issue Report');
    let emailLinkBody = '&body=' + encodeURIComponent(
      'ISSUE REPORT GENERATED BY LAUNCH\n\n' +
      '----\n\n' +
      'Summary of issue(s):\n\n\n' +
      'Account information at time of report:\n' +
      '    Organization: ' + activeOrg?.name + ' (' + activeOrg?.id + ')\n' +
      '    Company: ' + company?.attributes.name + ' (' + company?.id + ')\n' +
      '    Property: ' + property?.attributes.name + ' (' + property?.id + ')\n' +
      '    IMS User: ' + imsUserName + ' (' + imsUserId + ')\n\n\n' +
      'Steps to reproduce issue(s):\n\n\n' +
      'Support Priority:\n\n\n' +
      'Business impact:\n\n\n' +
      'Your expectations:\n\n\n' +
      'Additional information:\n\n\n' +
      'URL at time of report:\n' +
      '    ' + window.location.toString() + '\n\n\n' +
      'Launch Captured Error Info:\n'
    );
    errorToasts.forEach(toast=>{
      emailLinkBody += encodeURIComponent(
        toast.error.message + '\n\n' +
        'Details: \n' +
        JSON.stringify(toast.error, null, '  ') + '\n\n' +
        '----\n\n'
      );
    });
    emailLinkBody += encodeURIComponent(
      'This email template was generated by Launch based off of https://helpx.adobe.com/experience-cloud/enterprise-email-support-guidelines.html'
    );

    return emailLinkBase + emailLinkBody;
  }

  /**
   * Decides when it's time to unmount the parent dom node
   */
  handleToastExited = () => {
    const { toasts, errorToasts } = this.props;

    if (!Boolean(toasts?.length || errorToasts?.length)) {
      this.setState({ toastsParentShouldBeMounted: false });
    }
  };

  render = () => {
    const {dispatch, toasts, errorToasts, errorDetailsDialogOpen, expandedRows} = this.props;
    const {toastsParentShouldBeMounted, hoveredErrorItem, copiedToClipboard} = this.state;

    if (!toastsParentShouldBeMounted) {
      return null;
    }

    // The spectrum design pattern is to prioritize error toasts over
    // non-error toasts. Also, we should only show 1 toast at a time.
    // If there are multiple error toasts, we will combine them into
    // a single toast with a details action. If there are multiple
    // non-error toasts, we will show them in the order they arrived.
    const mostProminentToast = toasts.length && (
      errorToasts.length ? errorToasts[0] : toasts[0]
    );

    return (
      <React.Fragment>
        <div className="toasts u-textCenter">
          <CSSTransition
            in={!!errorToasts.length}
            classNames="a-slideUpFromBottom"
            unmountOnExit
            appear={true}
            timeout={{
              appear: getTimingForReact(animationVars.enterTime),
              enter: getTimingForReact(animationVars.enterTime),
              exit: getTimingForReact(animationVars.leaveTime)
            }}
          >
            <div>
              <div className="errorToast" key={mostProminentToast.id} data-test-id="errorToast">
                <ToastRenderer
                  variant="error"
                  closable
                  onClose={()=>{
                    dispatch(toastsActions.removeErrorToasts());
                  }}
                  actionLabel="Details"
                  onAction={()=>{
                    dispatch(toastsActions.setErrorDetailsDialogOpen(true));
                  }}
                  onExited={this.handleToastExited}
                >
                  {errorToasts.length > 1 ? (
                    <span>{errorToasts.length} errors have occurred</span>
                  ) : (
                    <span>An error has occurred</span>
                  )}
                </ToastRenderer>
              </div>
            </div>
          </CSSTransition>
          <CSSTransition
            in={!errorToasts.length}
            classNames="a-slideUpFromBottom"
            unmountOnExit
            appear={true}
            timeout={{
              appear: getTimingForReact(animationVars.enterTime),
              enter: getTimingForReact(animationVars.enterTime),
              exit: getTimingForReact(animationVars.leaveTime)
            }}
          >
            <div className="nonErrorToast" key={mostProminentToast.id} data-test-id="nonErrorToast">
              {
                GlobalKeyValueStore.get(mostProminentToast.id) != null && (
                  <ToastRenderer
                    {...GlobalKeyValueStore.get(mostProminentToast.id)}
                    onExited={this.handleToastExited}
                  />
                )
              }
            </div>
          </CSSTransition>
        </div>

        <Dialog open={errorDetailsDialogOpen} className="toastsDialog dialogXl">
          <Dialog.Header>Error Details</Dialog.Header>
          <Dialog.Content>
            <div className="u-marginBottom">
              The errors below have occurred. If you continue to experience these errors, please
              {' '}
              <a href={this.getCustomerCareEmailLink()} className="spectrum-Link">
                report them to Customer Care
              </a>. <br />
            </div>
            <div className="errorDetailsDialogContent u-overflowAuto">
              <CustomTable
                tableClassName="errorsTable"
                testId="errorsTable"
                expandable={true}
                expandedRows={expandedRows}
                expandedRowRenderer={this.expandedRowRenderer}
                rendererData={{
                  hoveredErrorItem,
                  copiedToClipboard
                }}
                limitExpandedRowsToOne={true}
                expandOnRowClick={true}
                onExpandRow={(toast)=>{
                  const expandedActions = getExpandedActions(TOASTS_ERRORS_KEY);
                  dispatch(expandedActions.collapseAll());
                  dispatch(expandedActions.toggleExpandedItem(toast, true));
                }}
                onCollapseRow={(toast)=>{
                  dispatch(getExpandedActions(TOASTS_ERRORS_KEY).toggleExpandedItem(toast, false));
                }}
                removable={true}
                onRemoveRow={(toast)=>{
                  dispatch(toastsActions.removeToast(toast.id));
                }}
                rows={errorToasts}
                hideHeader={true}
                columns={[{
                  key: 'id',
                  cellContent: this.appErrorCellRenderer,
                  cellClassName: 'u-verticalAlignMiddle'
                }]}
              />
            </div>
          </Dialog.Content>
          <Dialog.Footer>
            <div className="u-flex u-fullWidth u-marginTopXs">
              <div className="u-flexOne c-default">
                Learn more about{' '}
                <a
                  href="http://www.adobe.com/go/dma_customer_care_en"
                  target="_blank"
                  className="spectrum-Link spectrum-Link--quiet"
                >
                  Customer Care options
                </a>.
              </div>
              <Button
                variant="primary"
                onClick={() => {
                  dispatch(toastsActions.setErrorDetailsDialogOpen(false));
                }}
              >
                Close
              </Button>
            </div>
          </Dialog.Footer>
        </Dialog>
      </React.Fragment>
    );
  };
};

function mapStateToProps(state) {
  return {
    activeOrg: getActiveOrg(state),
    company: getCompanyFromState(state),
    property: state.api.property,
    imsUserId: state.ims?.imsUserId,
    imsUserName: state.ims?.profile?.attributes.displayName,
    toasts: getToastsFromState(state),
    errorToasts: getErrorToastsFromState(state),
    expandedRows: state.expanded[TOASTS_ERRORS_KEY]?.expandedItems,
    errorDetailsDialogOpen: state.dialogs[TOASTS_ERRORS_KEY]?.[ERROR_DETAILS_DIALOG_KEY]
  };
}

export default connect(mapStateToProps)(Toasts);
