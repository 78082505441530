/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import React from 'react';
import {useSelector} from 'react-redux';
import { getCurrentLocationFromState } from './routeSelectors';
import {useParams} from 'react-router';
import {CapabilityRedirector, CAPABILITY_TYPES, TopLevelCapabilityRedirector} from './capabilityUtils';
import {buildNamedRoutePaths, populateNamedRoutePath} from './namedRouteUtils';
import {ROUTE_TYPES, RoutesWithSubRoutes} from './routesWithSubRoutes';
import {
  RULES,
  RULE_COMPONENTS,
  DATA_ELEMENTS,
  EXTENSIONS
} from '../utils/api/apiTypes';
import {getRevisionRangeParameterName} from '../components/compareView/compareViewUtils';
import Loadable from '@loadable/component';

const BaseLayout = Loadable(() => import(/* webpackChunkName: "BaseLayout" */ '../components/layouts/baseLayout/baseLayout'));
const Companies = Loadable(() => import(/* webpackChunkName: "Companies" */ '../components/companies/companies'));
const CompanyLoader = Loadable(() => import(/* webpackChunkName: "CompanyLoader" */ '../components/layouts/company/companyLoader'));
const Properties = Loadable(() => import(/* webpackChunkName: "Properties" */ '../components/properties/list/properties'));
const PropertyAuditEvents = Loadable(() => import(/* webpackChunkName: "PropertyAuditEvents" */ '../components/properties/auditEvents/auditEvents'));
const CompanyAppConfigurations = Loadable(() => import(/* webpackChunkName: "CompanyAppConfigurations" */ '../components/companies/appConfigurations/appConfigurations'));
const CompanyAppConfigurationsEdit = Loadable(() => import(/* webpackChunkName: "CompanyAppConfigurationsEdit" */ '../components/companies/appConfigurations/appConfigurationsEdit'));
const CompanyEdit = Loadable(() => import(/* webpackChunkName: "CompanyEdit" */ '../components/companies/companyEdit'));
const CompanyAppSurfaces = Loadable(() => import(/* webpackChunkName: "CompanyAppSurfaces" */ '../components/companies/appSurfaces/AppSurfaces.jsx'));
const CompanyAppSurfacesEdit = Loadable(() => import(/* webpackChunkName: "CompanyAppSurfacesEdit" */ '../components/companies/appSurfaces/AppSurfacesEdit.jsx'));
const CompanyMonitoring = Loadable(() => import(/* webpackChunkName: "monitoring" */ '../components/companies/monitoring/monitoring'));
const CompanyMonitoringDashboard = Loadable(() => import(/* webpackChunkName: "monitoringDashboard" */ '../components/companies/monitoring/monitoringDashboard'));

const PropertyLayout = Loadable(() => import(/* webpackChunkName: "PropertyLayout" */ '../components/layouts/property/propertyLayout'));
const PropertiesOverview = Loadable(() => import(/* webpackChunkName: "PropertiesOverview" */ '../components/properties/overview/PropertyOverview'));
const PropertiesEdit = Loadable(() => import(/* webpackChunkName: "PropertiesEdit" */ '../components/properties/edit/propertyEdit'));
const PropertiesInstalledExtensions = Loadable(() => import(/* webpackChunkName: "PropertiesInstalledExtensions" */ '../components/properties/extensions/extensionsInstalled'));
const PropertiesExtensionsCatalog = Loadable(() => import(/* webpackChunkName: "PropertiesExtensionsCatalog" */ '../components/properties/extensions/extensionsCatalog'));
const PropertiesRules = Loadable(() => import(/* webpackChunkName: "PropertiesRules" */ '../components/properties/rules/rules'));
const PropertiesDataElements = Loadable(() => import(/* webpackChunkName: "PropertiesDataElements" */ '../components/properties/dataElements/dataElements'));
const PropertiesDataElementsEdit = Loadable(() => import(/* webpackChunkName: "PropertiesDataElementsEdit" */ '../components/properties/dataElements/dataElementsEdit'));
const PropertiesPublishing = Loadable(() => import(/* webpackChunkName: "PropertiesPublishing" */ '../components/properties/publishing/publishing'));
const PropertiesPublishingLibraryEdit = Loadable(() => import(/* webpackChunkName: "PropertiesPublishingLibraryEdit" */ '../components/properties/publishing/libraryEdit'));
const PropertiesEnvironments = Loadable(() => import(/* webpackChunkName: "PropertiesEnvironments" */ '../components/properties/environments/environments'));
const PropertiesEnvironmentsEdit = Loadable(() => import(/* webpackChunkName: "PropertiesEnvironmentsEdit" */ '../components/properties/environments/environmentEdit'));
const PropertiesHosts = Loadable(() => import(/* webpackChunkName: "PropertiesHosts" */ '../components/properties/hosts/hosts'));
const PropertiesHostsEdit = Loadable(() => import(/* webpackChunkName: "PropertiesHostsEdit" */ '../components/properties/hosts/hostEdit'));
const PropertiesSecrets = Loadable(() => import(/* webpackChunkName: "PropertiesSecrets" */ '../components/properties/secrets/secrets'));
const PropertiesSecretsEdit = Loadable(() => import(/* webpackChunkName: "PropertiesSecretsEdit" */ '../components/properties/secrets/secretsEdit'));
const PropertiesRuleComponentRedirect = Loadable(() => import(/* webpackChunkName: "PropertiesRuleComponentRedirect" */ '../components/properties/ruleComponents/ruleComponentRedirect'));
const PropertiesRuleComponentRedirectToRule = Loadable(() => import(/* webpackChunkName: "PropertiesRuleComponentRedirectToRule" */ '../components/properties/ruleComponents/ruleComponentRedirectToRule'));
const PropertiesRuleBuilder = Loadable(() => import(/* webpackChunkName: "PropertiesRuleBuilder" */ '../components/properties/rules/RuleBuilder'));
const PropertiesRuleComponentEditor = Loadable(() => import(/* webpackChunkName: "PropertiesRuleComponentEditor" */ '../components/properties/rules/RuleComponentEditor'));
const PropertiesExtensionsExtensionEdit = Loadable(() => import(/* webpackChunkName: "PropertiesExtensionsExtensionEdit" */ '../components/properties/extensions/extensionEdit'));
const Maintenance = Loadable(() => import(/* webpackChunkName: "Maintenance" */ '../components/maintenance/maintenance'));
const CompareView = Loadable(() => import(/* webpackChunkName: "CompareView" */ '../components/compareView/CompareView'));
const LibraryResourceCompareView = Loadable(() => import(/* webpackChunkName: "LibraryResourceCompareView" */ '../components/compareView/LibraryResourceCompareView'));

export const rootDocumentTitle = 'Data Collection';

export const routes = [
  {
    name: 'maintenance',
    title: 'Maintenance - ' + rootDocumentTitle,
    path: '/maintenance',
    component: Maintenance
  },

  // begin: routes for backward compatibility
  // these backward compatible routes can probably be removed in a few months from now (Jun 2021)
  // but we should validate that they are not being used any more
  {
    type: ROUTE_TYPES.REDIRECT,
    path: '/clientSide',
    getTo: ({props})=>{
      return {
        ...props.location,
        pathname: props.location.pathname.replace('/clientSide', '/' + CAPABILITY_TYPES.TAGS)
      };
    }
  },
  {
    type: ROUTE_TYPES.REDIRECT,
    path: '/client',
    getTo: ({props})=>{
      return {
        ...props.location,
        pathname: props.location.pathname.replace('/client', '/' + CAPABILITY_TYPES.TAGS)
      };
    }
  },
  {
    type: ROUTE_TYPES.REDIRECT,
    path: '/serverSide',
    getTo: ({props})=>{
      return {
        ...props.location,
        pathname: props.location.pathname.replace('/serverSide', '/' + CAPABILITY_TYPES.EVENT_FORWARDING)
      };
    }
  },
  {
    type: ROUTE_TYPES.REDIRECT,
    path: '/server',
    getTo: ({props})=>{
      return {
        ...props.location,
        pathname: props.location.pathname.replace('/server', '/' + CAPABILITY_TYPES.EVENT_FORWARDING)
      };
    }
  },
  // end: routes for backward compatibility

  {
    name: 'home',
    title: 'Home',
    path: '/:capability?',
    component: CapabilityRedirector({
      allowedCapabilities: Object.values(CAPABILITY_TYPES),
      RenderComponent: BaseLayout
    }),
    subRoutes: [
      {
        name: 'company',
        title: 'Company - ' + rootDocumentTitle,
        path: 'companies/:company',
        component: CompanyLoader,
        subRoutes: [
          {
            name: 'property',
            title: 'Property - ' + rootDocumentTitle,
            path: 'properties/:property',
            component: CapabilityRedirector({
              allowedCapabilities: [CAPABILITY_TYPES.TAGS, CAPABILITY_TYPES.EVENT_FORWARDING],
              RenderComponent: PropertyLayout
            }),
            subRoutes: [
              {
                name: 'editProperty',
                title: 'Edit Property - ' + rootDocumentTitle,
                path: 'edit',
                component: PropertiesEdit
              },{
                name: 'propertyOverview',
                title: 'Property Overview - ' + rootDocumentTitle,
                path: 'overview',
                component: PropertiesOverview
              },{
                name: 'editRule',
                title: 'Edit Rule - ' + rootDocumentTitle,
                path: 'rules/:rule',
                component: PropertiesRuleBuilder,
                subRoutes: [
                  {
                    name: 'editRuleCompare',
                    title: 'Compare Rule - ' + rootDocumentTitle,
                    path: `ruleCompare/:${getRevisionRangeParameterName(RULES)}`,
                    component: CompareView,
                    subRoutes: [
                      {
                        name: 'editRuleComponentCompare',
                        title: 'Compare Rule Component - ' + rootDocumentTitle,
                        path: `ruleComponentCompare/:${getRevisionRangeParameterName(RULE_COMPONENTS)}`,
                        component: CompareView,
                        subRoutes: [
                          {
                            name: 'editRuleComponentExtensionCompare',
                            title: 'Compare Rule Component Extension - ' + rootDocumentTitle,
                            path: `extensionCompare/:${getRevisionRangeParameterName(EXTENSIONS)}`,
                            component: CompareView,
                          },
                        ]
                      },
                    ]
                  },
                  {
                    name: 'editRuleEditRuleComponent',
                    title: 'Edit Rule Component - ' + rootDocumentTitle,
                    path: 'ruleComponent/:ruleComponent',
                    component: PropertiesRuleComponentEditor,
                  }
                ]
              },{
                name: 'rules',
                title: 'Rules - ' + rootDocumentTitle,
                path: 'rules',
                component: PropertiesRules
              },{
                name: 'editRuleComponentRule',
                title: 'Edit Rule - ' + rootDocumentTitle,
                path: 'ruleComponentsRule/:ruleComponent',
                component: PropertiesRuleComponentRedirectToRule
              },{
                name: 'editRuleComponent',
                title: 'Edit Rule Component - ' + rootDocumentTitle,
                path: 'ruleComponents/:ruleComponent',
                component: PropertiesRuleComponentRedirect
              },{
                name: 'editDataElement',
                title: 'Edit Data Element - ' + rootDocumentTitle,
                path: 'dataElements/:dataElement',
                component: PropertiesDataElementsEdit,
                subRoutes: [
                  {
                    name: 'editDataElementCompare',
                    title: 'Compare Data Element - ' + rootDocumentTitle,
                    path: `dataElementCompare/:${getRevisionRangeParameterName(DATA_ELEMENTS)}`,
                    component: CompareView,
                    subRoutes: [
                      {
                        name: 'editDataElementExtensionCompare',
                        title: 'Compare Data Element Extension - ' + rootDocumentTitle,
                        path: `dataElementExtensionCompare/:${getRevisionRangeParameterName(EXTENSIONS)}`,
                        component: CompareView,
                      }
                    ]
                  }
                ]
              },{
                name: 'dataElements',
                title: 'Data Elements - ' + rootDocumentTitle,
                path: 'dataElements',
                component: PropertiesDataElements,
              },{
                name: 'extensions',
                title: 'Extensions - ' + rootDocumentTitle,
                path: 'extensions',
                subRoutes: [
                  {
                    name: 'installedExtensions',
                    title: 'Installed Extensions - ' + rootDocumentTitle,
                    path: 'installed',
                    component: PropertiesInstalledExtensions
                  },
                  {
                    name: 'extensionCatalog',
                    title: 'Extension Catalog - ' + rootDocumentTitle,
                    path: 'catalog',
                    component: PropertiesExtensionsCatalog
                  },
                  {
                    name: 'installExtension',
                    title: 'Install Extension - ' + rootDocumentTitle,
                    path: ':extensionPackage/install',
                    component: PropertiesExtensionsExtensionEdit
                  },
                  {
                    name: 'editExtension',
                    title: 'Edit Extension - ' + rootDocumentTitle,
                    path: ':extension',
                    component: PropertiesExtensionsExtensionEdit,
                    subRoutes: [
                      {
                        name: 'editExtensionCompare',
                        title: 'Compare Extension - ' + rootDocumentTitle,
                        path: `extensionCompare/:${getRevisionRangeParameterName(EXTENSIONS)}`,
                        component: CompareView,
                      }
                    ]
                  },
                  {
                    type: ROUTE_TYPES.REDIRECT,
                    path: '*',
                    toNamedRoute: 'installedExtensions'
                  }
                ]
              },{
                name: 'editHost',
                title: 'Edit Host - ' + rootDocumentTitle,
                path: 'hosts/:host',
                component: PropertiesHostsEdit
              },{
                name: 'hosts',
                title: 'Hosts - ' + rootDocumentTitle,
                path: 'hosts',
                component: PropertiesHosts
              },{
                name: 'editSecret',
                title: 'Edit Secret - ' + rootDocumentTitle,
                path: 'secrets/:secret',
                component: PropertiesSecretsEdit
              },{
                name: 'secrets',
                title: 'Secrets - ' + rootDocumentTitle,
                path: 'secrets',
                component: PropertiesSecrets
              },{
                name: 'editEnvironment',
                title: 'Edit Environment - ' + rootDocumentTitle,
                path: 'environments/:environment',
                component: PropertiesEnvironmentsEdit
              },{
                name: 'environments',
                title: 'Environments - ' + rootDocumentTitle,
                path: 'environments',
                component: PropertiesEnvironments
              },{
                name: 'editLibrary',
                title: 'Edit Library - ' + rootDocumentTitle,
                path: 'publishing/:library',
                component: PropertiesPublishingLibraryEdit,
                subRoutes: [
                  {
                    name: 'editLibraryRuleCompare',
                    title: 'Library Rule Compare - ' + rootDocumentTitle,
                    path: `ruleCompare/:${getRevisionRangeParameterName(RULES)}`,
                    component: LibraryResourceCompareView,
                    subRoutes: [
                      {
                        name: 'editLibraryRuleComponentCompare',
                        title: 'Library Rule Component Compare - ' + rootDocumentTitle,
                        path: `ruleComponentCompare/:${getRevisionRangeParameterName(RULE_COMPONENTS)}`,
                        component: CompareView,
                        subRoutes: [
                          {
                            name: 'editLibraryRuleComponentExtensionCompare',
                            title: 'Library Rule Component Extension Compare - ' + rootDocumentTitle,
                            path: `extensionCompare/:${getRevisionRangeParameterName(EXTENSIONS)}`,
                            component: CompareView,
                          }
                        ]
                      }
                    ]
                  },{
                    name: 'editLibraryDataElementCompare',
                    title: 'Library Data Element Compare - ' + rootDocumentTitle,
                    path: `dataElementCompare/:${getRevisionRangeParameterName(DATA_ELEMENTS)}`,
                    component: LibraryResourceCompareView,
                    subRoutes: [
                      {
                        name: 'editLibraryDataElementExtensionCompare',
                        title: 'Library Data Element Extension Compare - ' + rootDocumentTitle,
                        path: `extensionCompare/:${getRevisionRangeParameterName(EXTENSIONS)}`,
                        component: CompareView,
                      }
                    ]
                  },{
                    name: 'editLibraryExtensionCompare',
                    title: 'Library Extension Compare - ' + rootDocumentTitle,
                    path: `extensionCompare/:${getRevisionRangeParameterName(EXTENSIONS)}`,
                    component: LibraryResourceCompareView,
                  }
                ]
              },{
                name: 'publishing',
                title: 'Publishing - ' + rootDocumentTitle,
                path: 'publishing',
                component: PropertiesPublishing
              },{
                name: 'auditEvents',
                title: 'Audit Events - ' + rootDocumentTitle,
                path: 'auditEvents',
                component: CapabilityRedirector({
                  allowedCapabilities: [CAPABILITY_TYPES.TAGS, CAPABILITY_TYPES.EVENT_FORWARDING],
                  RenderComponent: PropertyAuditEvents
                })
              },{
                type: ROUTE_TYPES.REDIRECT,
                path: '*',
                toNamedRoute: 'propertyOverview'
              }
            ]
          },{
            name: 'properties',
            title: 'Properties - ' + rootDocumentTitle,
            path: 'properties',
            component: CapabilityRedirector({
              allowedCapabilities: [CAPABILITY_TYPES.TAGS, CAPABILITY_TYPES.EVENT_FORWARDING],
              RenderComponent: Properties
            })
          },{
            name: 'editAppConfiguration',
            title: 'Edit App Configuration - ' + rootDocumentTitle,
            path: 'configurations/:appConfiguration',
            component: CapabilityRedirector({
              allowedCapabilities: [CAPABILITY_TYPES.APP_CONFIGURATIONS],
              RenderComponent: CompanyAppConfigurationsEdit
            })
          },{
            name: 'appConfigurations',
            title: 'App Configurations - ' + rootDocumentTitle,
            path: 'configurations',
            component: CapabilityRedirector({
              allowedCapabilities: [CAPABILITY_TYPES.APP_CONFIGURATIONS],
              RenderComponent: CompanyAppConfigurations
            })
          },{
            name: 'editAppSurface',
            title: 'Edit App Surface - ' + rootDocumentTitle,
            path: 'appSurfaces/:appSurface',
            component: CapabilityRedirector({
              allowedCapabilities: [CAPABILITY_TYPES.APP_SURFACES],
              RenderComponent: CompanyAppSurfacesEdit
            })
          },{
            name: 'appSurfaces',
            title: 'App Surfaces - ' + rootDocumentTitle,
            path: 'appSurfaces',
            component: CapabilityRedirector({
              allowedCapabilities: [CAPABILITY_TYPES.APP_SURFACES],
              RenderComponent: CompanyAppSurfaces
            })
          },
          {
            name: 'monitoring',
            title: 'Monitoring - ' + rootDocumentTitle,
            path: 'monitoring',
            component: CapabilityRedirector({
              allowedCapabilities: [CAPABILITY_TYPES.MONITORING],
              RenderComponent: CompanyMonitoring
            }),
            subRoutes: [
              {
                name: 'monitoringDashboard',
                title: 'Monitoring Dashboard - ' + rootDocumentTitle,
                path: 'dashboard',
                component: CompanyMonitoringDashboard
              },
              {
                type: ROUTE_TYPES.REDIRECT,
                path: '*',
                toNamedRoute: 'monitoringDashboard'
              }
            ]
          },
          {
            name: 'editCompany',
            title: 'Edit Company - ' + rootDocumentTitle,
            path: 'edit',
            component: CompanyEdit
          },
          {
            type: ROUTE_TYPES.REDIRECT,
            path: '*',
            getTo: ({props})=>{
              if (
                props.match.params.capability === CAPABILITY_TYPES.TAGS ||
                props.match.params.capability === CAPABILITY_TYPES.EVENT_FORWARDING
              ) {
                return (populateNamedRoutePath({
                  name: 'properties',
                  params: props.match.params
                }));
              }
              if (props.match.params.capability === CAPABILITY_TYPES.APP_CONFIGURATIONS) {
                return (populateNamedRoutePath({
                  name: 'appConfigurations',
                  params: props.match.params
                }));
              }
              if (props.match.params.capability === CAPABILITY_TYPES.APP_SURFACES) {
                return (populateNamedRoutePath({
                  name: 'appSurfaces',
                  params: props.match.params
                }));
              }
              if (props.match.params.capability === CAPABILITY_TYPES.MONITORING) {
                return (populateNamedRoutePath({
                  name: 'monitoringDashboard',
                  params: props.match.params
                }));
              }
            }
          }
        ]
      },
      {
        name: 'companies',
        title: 'Companies - ' + rootDocumentTitle,
        path: 'companies',
        component: Companies
      },
      {
        name: 'toplevelRedirector',
        path: '',
        component: TopLevelCapabilityRedirector
      }
    ]
  }
];

// this must happen before any namedRouteUtils can be used
// so we'll do it immediately after defining our routes
buildNamedRoutePaths(routes);



export default function Routes() {
  let params = useParams();
  let location = useSelector(getCurrentLocationFromState);
  return (
    <RoutesWithSubRoutes
      routes={routes}
      additionalRouteProps={{
        location,
        match:{params},
        params
      }}
      location={location}
    />
  );
}


