/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import React from 'react';
import { isResourceLatestAndUnchanged } from '../../../../utils/resourceUtils';
import {Tag} from 'spectrum-alternatives/TagList';


export default function RevisionLabel(props = {}) {
  const {resource} = props;
  if (!resource) { return null; }

  const {revisionNumber} = resource.attributes;
  const revisionMatchesLatest = isResourceLatestAndUnchanged(resource);
  let revisionLabel = revisionNumber === 0 ? (
    <span className="revisionLabel" data-test-id="revision" data-revision-id={resource.id}>
      <span
        data-test-id="revisionText"
        className="revisionText u-marginRightXs"
      >
        Latest
      </span>
    </span>
  ) : (
    <span className="revisionLabel" data-test-id="revision" data-revision-id={resource.id}>
      <span
        data-test-id="revisionText"
        className={
          'revisionText u-inlineBlock' +
          (
            (revisionMatchesLatest || resource.attributes.published || resource.attributes.publishedAt) ?
            ' u-marginRightXs' :
            ''
          )
        }
      >
        {'Revision ' + revisionNumber}
      </span>
      {revisionMatchesLatest ? (
        <Tag size="S" className="u-floatNone u-noMarginVertical">Latest</Tag>
      ) : null}
      {resource.attributes.published ? (
        <Tag variant="info" size="S" className="u-floatNone u-noMarginVertical">Live</Tag>
      ) : null}
      {!resource.attributes.published && resource.attributes.publishedAt ? (
        <Tag size="S" className="u-floatNone u-noMarginVertical">Published</Tag>
      ) : null}
    </span>
  );

  return revisionLabel;
}
