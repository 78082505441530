/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import Immutable from 'seamless-immutable';
import {getResourceOriginId} from '../../../utils/resourceUtils';
import {isEqual} from 'lodash-es';
import {getBoundGroupPairs} from '../alignColumnsUtil';

export const STATE_KEY_LEFT = 'ruleCompareViewLeft';
export const STATE_KEY_RIGHT = 'ruleCompareViewRight';

const defaultDummyComponent = Immutable({
  id: null,
  isDummyComponent:true,
  hasChanges:true,
  orderHasChanged: false,
  relationships: {origin: { data: {id: null}}}
});

const diffAttributesToIgnoreBase = [
  'updatedAt',
  'createdAt',
  'revisionNumber',
  'order',
  'dirty',
  'published',
  'publishedAt',
  'createdByEmail',
  'createdByDisplayName',
  'updatedByEmail',
  'updatedByDisplayName',
];


export function getRuleComponentsDiffByType(rightComponents, leftComponents, property) {

  const originalRightComponents = rightComponents;
  const originalLeftComponents = leftComponents;
  // if the property has turned off rule component sequencing then we should ignore
  // the timeout and delay attributes in the compare view.
  const diffAttributesToIgnore = diffAttributesToIgnoreBase.concat(
    property.attributes.ruleComponentSequencingEnabled ?
    [] :
    ['timeout', 'delayNext']
  );

  // left and right could probably be combined
  rightComponents.forEach((component, index)=>{
    if (!component?.isDummyComponent) {
      const componentOriginId = getResourceOriginId(component);
      const leftMatchIndex = leftComponents.findIndex(
        (leftComponent)=> getResourceOriginId(leftComponent) === componentOriginId
      );
      const leftMatchComponent = leftComponents[leftMatchIndex];
      const hasChanges = !isEqual(
        leftMatchComponent?.attributes?.without(diffAttributesToIgnore),
        component.attributes.without(diffAttributesToIgnore)
      );
      const orderHasChanged = (
        leftMatchComponent &&
        !leftMatchComponent.isDummyComponent &&
        leftMatchComponent.attributes.order !== component.attributes.order
      );

      rightComponents = rightComponents.setIn(
        [index, 'hasChanges'],
        hasChanges
      ).setIn(
        [index, 'orderHasChanged'],
        orderHasChanged
      );

      if (leftMatchComponent) {
        leftComponents = leftComponents.setIn(
          [leftMatchIndex, 'hasChanges'],
          hasChanges
        ).setIn(
          [leftMatchIndex, 'orderHasChanged'],
          orderHasChanged
        );
      } else {
        rightComponents = rightComponents.setIn(
          [index, 'isAddition'],
          true
        );

      }
    }
  });

  leftComponents.forEach((component, index)=>{
    if (!component?.isDummyComponent) {
      const componentOriginId = getResourceOriginId(component);
      const rightMatchIndex = rightComponents.findIndex(
        (rightComponent)=> getResourceOriginId(rightComponent) === componentOriginId
      );
      const rightMatchComponent = rightComponents[rightMatchIndex];
      const hasChanges = !isEqual(
        rightMatchComponent?.attributes?.without(diffAttributesToIgnore),
        component.attributes.without(diffAttributesToIgnore)
      );
      const orderHasChanged = (
        rightMatchComponent &&
        !rightMatchComponent.isDummyComponent &&
        rightMatchComponent.attributes.order !== component.attributes.order
      );

      leftComponents = leftComponents.setIn(
        [index, 'hasChanges'],
        hasChanges
      ).setIn(
        [index, 'orderHasChanged'],
        orderHasChanged
      );

      if (rightMatchComponent) {
        rightComponents = rightComponents.setIn(
          [rightMatchIndex, 'hasChanges'],
          hasChanges
        ).setIn(
          [rightMatchIndex, 'orderHasChanged'],
          orderHasChanged
        );
      } else {
        leftComponents = leftComponents.setIn(
          [index, 'isRemoval'],
          true
        );

      }
    }
  });

  return {
    boundGroupPairedComponents: Immutable(getBoundGroupPairs(
      [leftComponents.asMutable() || [], rightComponents.asMutable() || []],
      {
        getItemId: (component) => getResourceOriginId(component),
        placeholder: defaultDummyComponent
      }
    )),
    [STATE_KEY_RIGHT]: originalRightComponents,
    [STATE_KEY_LEFT]: originalLeftComponents
  };

}

// separate by rule component type
export function getRuleComponentsDiff({rightComponents, leftComponents, property}) {
  return Object.keys(rightComponents).reduce((accumulator, type)=>{

    // We remove any old dummy delegates so we dont attempt to compare
    // them to real components.
    rightComponents = rightComponents.update( type, (componentsByType)=>(
      componentsByType.filter((component)=>(!component.isDummyComponent)))
    );
    leftComponents = leftComponents.update( type, (componentsByType)=>(
      componentsByType.filter((component)=>(!component.isDummyComponent)))
    );

    const diffs = getRuleComponentsDiffByType(rightComponents[type], leftComponents[type], property);
    accumulator.boundGroupPairedComponents = accumulator.boundGroupPairedComponents || {};
    accumulator.boundGroupPairedComponents[type] = diffs.boundGroupPairedComponents;
    accumulator[STATE_KEY_RIGHT][type] = diffs[STATE_KEY_RIGHT];
    accumulator[STATE_KEY_LEFT][type] = diffs[STATE_KEY_LEFT];
    return accumulator;
  }, {[STATE_KEY_RIGHT]:{}, [STATE_KEY_LEFT]:{}});
}
