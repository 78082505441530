/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/


import createStore from '../redux/createStore';
import {
  actionCreators as confirmNavigationDialogActions
} from '../components/higherOrderComponents/confirmNavigationDialogActions';
import {createBrowserHistory, browserHistory} from './browserHistoryUtils';


let store;

export function getStore() {
  return store;
}

export function exposeStore() {
  // expose the store for Cypress tests and easier debugging
  // https://docs.cypress.io/faq/questions/using-cypress-faq.html#Can-my-tests-interact-with-Redux-Vuex-data-store
  window.getStore = getStore;
}

// On QA/Stage/Prod the pathname will be `/solutions/<appname>...`
// To account for this we either take that path "prefix" as the  history
// basename or else leave it blank (in the development case).
const match = window.location.pathname.match(/^(\/solutions\/[^/$]*)/);
const basename = match ? match[1] : '';

// The store must exist before using createBrowserHistory,
// and the browserHistory must be used in createStore.
// It's a chicken/egg scenario so we do both things together
// after creating the store variable above
export function initializeStoreWithBrowserHistory() {
  return new Promise(resolve=>{
    createBrowserHistory({
      basename,
      // getUserConfirmation determine's if navigation to the requested URL should happen
      // This is only implemented where we are using react-router's <Prompt />
      getUserConfirmation(message, callback) {
        store.dispatch(confirmNavigationDialogActions.getUserConfirmation(callback));
      }
    });

    if (window.environmentSettings.lens_debug) {
      import(/* webpackChunkName: "reduxlogger" */ 'redux-logger').then(({createLogger})=>{
        store = createStore(browserHistory, createLogger);
        resolve({store, browserHistory});
      });
    } else {
      store = createStore(browserHistory);
      resolve({store, browserHistory});
    }
  });
}

