/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import actionsHandler from '../../redux/actionsHandler';
import {actionCreators as apiActions} from '../../utils/api/apiActions';
import Immutable from 'seamless-immutable';
import {libraryTypes} from './publishing/publishingActions';
import {isEqual} from 'lodash-es';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../utils/storageUtils';


export const SET_ACTIVE_LIBRARY = 'workingLibrary/SET_ACTIVE_LIBRARY';
export const SET_NEEDS_REFRESH = 'workingLibrary/SET_NEEDS_REFRESH';

const defaultState = Immutable({
  library: null,
  needsRefresh: false
});

//Reducers
export default actionsHandler({
  [SET_ACTIVE_LIBRARY](state, {payload:{library}}) {
    return state.set('library', library);
  },
  [SET_NEEDS_REFRESH](state, {payload:{needsRefresh}}) {
    return state.set('needsRefresh', needsRefresh);
  },
  default: (state = defaultState)=> {
    return state;
  }
});


//Action Creators
export const actionCreators = {
  loadWorkingLibrary(params) {
    return (dispatch)=>{
      return dispatch(apiActions.apiAction({
        name: 'getLibrary',
        urlData: {...params},
        stateKey: 'workingLibrary'
      }));
    };
  },
  setWorkingLibrary(params, library) {
    if (library) {
      setLocalStorageItem(params.property + '_workingLibrary', library.id);
    } else {
      removeLocalStorageItem(params.property + '_workingLibrary');
    }

    return {
      type: SET_ACTIVE_LIBRARY,
      payload: { library }
    };
  },
  setWorkingLibraryNeedsRefresh(needsRefresh = true) {
    return {
      type: SET_NEEDS_REFRESH,
      payload: { needsRefresh }
    };
  },
  restoreWorkingLibrary(params) {
    return function(dispatch, getState) {
      const previousWorkingLibraryId = getLocalStorageItem(params.property + '_workingLibrary');

      if (previousWorkingLibraryId) {
        return dispatch(
          actionCreators.loadWorkingLibrary({...params, library: previousWorkingLibraryId})
        ).then(()=>{
          const state = getState();
          const loadedWorkingLibrary = state.api.workingLibrary.library;
          const currentWorkingLibrary = state.workingLibrary.library;
          // we check if the library isEqual because it's attributes may have changed
          // for example: from a "needs build" to a "building" state
          if (!isEqual(loadedWorkingLibrary, currentWorkingLibrary)) {
            dispatch(actionCreators.setWorkingLibrary(params, loadedWorkingLibrary));
          }
          dispatch(actionCreators.removeWorkingLibraryIfNotInDevelopment(params));
          return true;
        }).catch(()=>{
          // could not restore the active library so we'll cleanup
          return dispatch(actionCreators.setWorkingLibrary(params, null));
        });
      } else {
        // no active library so cleanup
        return dispatch(actionCreators.setWorkingLibrary(params, null));
      }
    };
  },
  removeWorkingLibraryIfNotInDevelopment(params) {
    return (dispatch, getState) => {
      const state = getState();
      const workingLibrary = state.workingLibrary.library;
      if (workingLibrary &&
        workingLibrary.attributes.state !== libraryTypes.states.DEVELOPMENT &&
        workingLibrary.attributes.state !== libraryTypes.states.REJECTED) {
        removeLocalStorageItem(params.property + '_workingLibrary');
        dispatch(actionCreators.setWorkingLibrary(params, null, true));
        dispatch(apiActions.resetEndpoint('getWorkingLibrary'));
      }
    };
  }
};
