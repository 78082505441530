/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import React, { useEffect, useState} from 'react';
import { useDispatch, useSelector} from 'react-redux';
import {actionCreators as apiActions} from '../../utils/api/apiActions';
import makeCancelable from '../../utils/makeCancelable';
import classNames from 'classnames';
import {get} from 'lodash-es';
import {ProgressCircle} from '@adobe/react-spectrum';
import {actionCreators as extensionCacheBustActions} from '../properties/extensions/extensionCacheBustActions';
import {ERROR_CODE_RENDERERS} from './StatusDetailsErrorMessageRenderers';
import {RESOURCE_ID_REGEX, DELEGATE_DESCRIPTOR_ID_REGEX} from '../../utils/resourceUtils';
import { getCurrentRouteParamsFromState } from '../../routes/routeSelectors';
import { getResourceInfoById } from '../../utils/resourceUtils';


function loadResources({
  resourceLoadingPromises,
  error,
  params,
  dispatch,
  setState
}) {
  // extract resource ids from message (use meta when it becomes available from the API)
  const idMatches = error && error.detail.match(RESOURCE_ID_REGEX) || [];
  const delegateDescriptorIdMatches = (
    error &&
    error.detail.match(DELEGATE_DESCRIPTOR_ID_REGEX) ||
    []
  );

  // load resources for matched ids
  if ( idMatches.length || delegateDescriptorIdMatches.length ) {
    setState({ loading: true });
  }

  if (idMatches.length) {
    idMatches.forEach((resourceId)=>{
      const resourceInfo = getResourceInfoById(resourceId, params);
      const {endpointNames, params: _params} = resourceInfo;

      if (endpointNames?.get) {
        const loadingPromise = makeCancelable(dispatch(apiActions.apiAction({
          name: endpointNames.get,
          urlData: {..._params},
          // Since this function is used as a nice-to-have (replacing resource IDs with names)
          // we don't want the app to error if one or more return a 404 or other error. In that
          // case the app is still functional.
          updateState: false,
          bypassError: true
        })));

        resourceLoadingPromises.push(loadingPromise);
      }
    });
  }

  const extensionCachePromise = (
    delegateDescriptorIdMatches.length &&
    dispatch(extensionCacheBustActions.checkAndRefreshCache(params))
  ) || Promise.resolve();

  (
    resourceLoadingPromises.length ?
    Promise?.allSettled(resourceLoadingPromises) :
    Promise.resolve([])
  ).then((results)=>{
    // filtering out any resources that may have been deleted during the load call
    let resources = results.filter(result=>result?.value).map(result=>get(result, 'value.res.body.data'));
    return extensionCachePromise.then((extensionData)=>{
      const extensionPackages = delegateDescriptorIdMatches.map((delegateDescriptorId)=>{
        const extensionPackageId = get(extensionData, ['delegateDescriptors', delegateDescriptorId, 'extensionPackageId']);
        return get(extensionData, ['extensionPackages', extensionPackageId]);
      }).filter((extensionPackage)=>extensionPackage);

      const anyCancellations = results.some(result=>result?.reason?.isCanceled);
      // if any promises were cancelled then we can't setState because this component will have unmounted
      if (!anyCancellations) {
        setState({
          loading: false,
          errorRenderer: ERROR_CODE_RENDERERS[error.code] || ERROR_CODE_RENDERERS['unkownError'],
          errorRendererProps: {
            detail: error.detail,
            resources: resources.concat(extensionPackages),
            params
          }
        });
      }
    });
  });
};


const initialState = {
  loading: false,
  errorRenderer: null,
  errorRendererProps: null
};

export default function StatusDetailsErrorMessage({
  className,
  error
}) {

  let [{loading, errorRenderer, errorRendererProps},setState] = useState(initialState);
  let params = useSelector(getCurrentRouteParamsFromState);
  let dispatch = useDispatch();
  useEffect(()=>{
    let resourceLoadingPromises = [];
    setState(initialState);
    loadResources({
      resourceLoadingPromises,
      error,
      params,
      dispatch,
      setState
    });

    return ()=>{
      if (resourceLoadingPromises && resourceLoadingPromises.length) {
        resourceLoadingPromises.forEach((promise)=>{ promise.cancel(); });
      }
    };
  },[error]);

  return (
    <div className={classNames('StatusDetailsErrorMessage u-padding', className)}>
      {loading ? (
        <div>
          <ProgressCircle aria-label="Loading…" isIndeterminate />
        </div>
      ) : (
        <span>
          {errorRenderer ? errorRenderer(errorRendererProps) : 'Unkown error'}
        </span>
      )}
    </div>
  );
}
