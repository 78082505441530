/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2016 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

const getTop = (element) => {
  if (!element) {
    return Infinity;
  }
  return element.offsetTop;
};

const getProperty = (element, propertyName) => {
  if (!element) {
    element = window;
  }

  return element[propertyName];
};

export const isElementInViewport = (element, offset = {}, parentCssSelector = null) => {
  const parent = document.querySelector(parentCssSelector);
  const rect = element.getBoundingClientRect();
  const _offset = { top: 0, left: 0, bottom: 0, right: 0 };
  Object.assign(_offset, offset);

  return (
    rect.top >= 0 + _offset.top &&
    rect.left >= 0 + _offset.left &&
    rect.bottom <= getProperty(parent, 'innerHeight') + rect.height + _offset.bottom &&
    rect.right <= getProperty(parent, 'innerWidth') + rect.width + _offset.right
  );
};

// https://www.kirupa.com/js/easing.js
function easeInOutQuad(currentIteration, startValue, changeInValue, totalIterations) {
  if ((currentIteration /= totalIterations / 2) < 1) {
    return changeInValue / 2 * currentIteration * currentIteration + startValue;
  }
  return -changeInValue / 2 * ((--currentIteration) * (currentIteration - 2) - 1) + startValue;
}

function animateNumeric(element, property, to, duration) {
  const fps = 60;
  const fpsInterval = 1000 / fps;
  const requestAnimationFrame = window.requestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.msRequestAnimationFrame;

  let iteration = 1;
  let totalIterations = 1 + Math.round(duration / fpsInterval);

  let from = element[property];

  let then = Date.now();
  function draw() {
    if (!element) { return; }

    // calc elapsed time since last loop
    let now = Date.now();
    let elapsed = now - then;

    // if enough time has elapsed, draw the next frame
    if (elapsed > fpsInterval) {
      then = now - (elapsed % fpsInterval);

      // draw it
      element[property] = easeInOutQuad(iteration, from, to, totalIterations);

      // determine if the animation is complete
      if (element[property] === to || iteration >= totalIterations) {
        iteration = 0;
        element[property] = to;
        return; // prevent next run
      } else {
        iteration += 1;
      }
    }

    requestAnimationFrame(draw);
  }

  draw();
}

export const scrollElementIntoView = (element, parentElement, delay = 0, duration = 1000) => {
  setTimeout(()=>{
    // handle css selectors
    if (typeof element === 'string') {
      element = document.querySelector(element);
    }
    if (typeof parentElement === 'string') {
      parentElement = document.querySelector(parentElement);
    }

    if (element) {
      if (parentElement) {
        // scroll within parent
        animateNumeric(parentElement, 'scrollTop', element.offsetTop, duration);
      } else {
        // scroll viewport
        element.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
      }
    }
  }, delay);
};
export const scrollFirstValidationErrorIntoView = (
  parentCssSelector = null, delay = 0, offset = {}
) => {
  setTimeout(() => {
    const errorTip = document.querySelector('.is-invalid');
    const error = document.querySelector('.spectrum-Alert--error');
    const errorIcon = document.querySelector('.spectrum-Icon.c-error');
    const errorInputSpectrum3 = document.querySelector(parentCssSelector + ' [aria-invalid]');

    let errorIconParent = null;
    if (errorIcon && errorIcon.nodeName === 'svg') {
      errorIconParent = errorIcon.parentElement;
    }

    const errorElements = [errorTip, error, errorIcon, errorIconParent, errorInputSpectrum3];

    let lowestTop = Infinity;
    let topElement;
    errorElements.forEach((errorElement)=>{
      const elementTop = getTop(errorElement);
      topElement = (elementTop < lowestTop) ? errorElement : topElement;
      lowestTop = (elementTop < lowestTop) ? elementTop : lowestTop;
    });
    // offset for sticky footer
    if (topElement && !isElementInViewport(topElement, offset, parentCssSelector)) {
      scrollElementIntoView(topElement);
    }
  }, delay);
};

let scrollHandlerData = {};
export const registerScrollHandler = (
  key, selector, handler, bottomOffset = 0, unregisterOnHandler
) => {
  const scrollElement = document.querySelector(selector);
  if (scrollElement) {
    const onScroll = () => {
      window.requestAnimationFrame(function() {
        // check if the element is scrolled to the bottomOffset and fire the handler if so
        if (scrollElement.scrollTop >= (
          scrollElement.scrollHeight - scrollElement.offsetHeight - bottomOffset
        )) {
          const scrollHandlerDataItem = scrollHandlerData[key];
          if (typeof scrollHandlerDataItem.handler === 'function') {
            scrollHandlerDataItem.handler();
            if (unregisterOnHandler) { unregisterScrollHandler(key); }
          }
        }
      });
    };

    // unregister existing event for this key before registering new
    if (scrollHandlerData[key]) { unregisterScrollHandler(key); }
    // store data for later execution and unregistering
    scrollHandlerData[key] = { selector, handler, onScroll };

    scrollElement.addEventListener('scroll', onScroll);
  }
};

export const unregisterScrollHandler = (key) => {
  const scrollHandlerDataItem = scrollHandlerData[key];
  if (scrollHandlerDataItem) {
    const scrollElement = document.querySelector(scrollHandlerDataItem.selector);
    if (scrollElement) {
      scrollElement.removeEventListener('scroll', scrollHandlerDataItem.onScroll);
    }
    delete scrollHandlerData[key];
  }
};

export function getBoundingRectWithWrapperOffset(domNode, wrapperNode) {
  if (!domNode || !wrapperNode) {
    return {top:0, left:0, bottom:0, right:0, height:0, width:0};
  }
  const domNodeRect = domNode.getBoundingClientRect();
  const wrapperRect = wrapperNode.getBoundingClientRect();

  let baseRect = {
    top: domNodeRect.top - wrapperRect.top,
    left: domNodeRect.left - wrapperRect.left,
    height: domNodeRect.height,
    width: domNodeRect.width
  };
  baseRect.bottom = baseRect.top + baseRect.height;
  baseRect.right = baseRect.left + baseRect.width;
  return baseRect;
}

export const getFont = (node) => {
  if (!node) {return null;}
  const style = window.getComputedStyle(node);
  return style.getPropertyValue('font');
};

let textWidthCanvas = null;
export const getTextWidth = (text, wrapperNode) => {
  if (!text || !wrapperNode) { return 0; }
  textWidthCanvas = textWidthCanvas || document.createElement('canvas');
  const context = textWidthCanvas.getContext('2d');
  context.font = getFont(wrapperNode);
  const metric = context.measureText(text);
  return Math.ceil(metric.width * 1.1); // measureText method's return value may be slightly underestimated, add some pixels or scale it
};