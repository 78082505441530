/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import { getRouteByPath } from '../../routes/namedRouteUtils';
import {rootDocumentTitle} from '../../routes/routes';
import { getLocalStorageItem, setLocalStorageItem, getSessionStorageItem, storageKeyPrefix } from '../../utils/storageUtils';
import { MANUAL_SIGNIN_KEY } from './imsWrapperActions';
import { setLocationHash } from '../../routes/routeUtils';

export const LOCAL_SHELL_LOCALSTORAGE_KEY = 'localShell';

export function persistLocalShellOverrideSetting() {
  if (getLocalShellOverrideSetting()) {
    setLocalStorageItem(LOCAL_SHELL_LOCALSTORAGE_KEY, true); // if it was set via the querystring we want to perist it for development
    console.info(
      `localStorage.${storageKeyPrefix}${LOCAL_SHELL_LOCALSTORAGE_KEY} = true` +
      ' - Delete it to allow auto-redirecting to Unified Shell.'
    );
  }
}

export function getLocalShellOverrideSetting() {
  const localShellOverrideSetting = new URL(window.location.href.toLowerCase()).searchParams.get('localshell');
  return (
    localShellOverrideSetting && localShellOverrideSetting === 'true' ||
    getLocalStorageItem(LOCAL_SHELL_LOCALSTORAGE_KEY) === true
  );
}

export function getIsInsideCypressAndNotInSubIframe() {
  // we have to try/catch this because window.parent is not accessible inside Unified Shell
  // unless run from inside Cypress. window.Cypress should be available, but it's not
  // available in time.
  let cypress;
  try { cypress = window.parent.Cypress; } catch (error) {}

  const isInSubIframe = window.parent !== window.top;
  return Boolean(cypress && !isInSubIframe);
}

export function getShouldUseLocalShell() {
  // It would be ideal to do this check based on the presence of the module runtime
  // _mr query param can be used for this, however since Lens redirects internally
  // that param goes away after the first page view. Caching it works unless the app
  // needs to reload inside the iframe at which point the current URL without the _mr
  // query param is loaded. As such, there appears to be no way to definitively check
  // if the module runtime is present.

  // We need to handle these cases...
  // IMS auth disabled: shouldUseLocalShell = true
  // outside Unified Shell: shouldUseLocalShell = true
  // inside Unified Shell: shouldUseLocalShell = false
  // inside Cypress
  //   outside Unified Shell: shouldUseLocalShell = true
  //   inside Unified Shell: shouldUseLocalShell = false
  const imsAuthDisabled = window.environmentSettings.disableImsAuth;
  const shouldUseLocalShell = imsAuthDisabled || (
    window === window.parent &&
    window.parent === window.top &&
    getLocalShellOverrideSetting()
  ) || (
    getIsInsideCypressAndNotInSubIframe() &&
    getLocalShellOverrideSetting()
  );

  return shouldUseLocalShell;
}

// imslib docs...
// https://git.corp.adobe.com/IMS/imslib2.js
export function loadImsLib(config) {
  const imsEcConfig = window.environmentSettings.lens_imsEcConfig;
  window.adobeid = config; // includes onReady handler
  const imsLibScript = document.createElement('script');
  imsLibScript.src = imsEcConfig.imsLib;
  document.querySelector('body').appendChild(imsLibScript);
}

export function getIsImsLibProd() {
  const imsEcConfig = window.environmentSettings.lens_imsEcConfig;
  const isImsLibProd = imsEcConfig.imsLib.indexOf('auth.services.adobe.com') >= 0;
  return isImsLibProd;
}

// keep the url clean after sign in (IMS redirect sometimes leaves a hash)
export function cleanLocationHashAfterLogin() {
  const hash = window.location.hash;
  if (hash.length && (hash === '#' || hash.match(/#access_token=/))) {
    setLocationHash();
  }
}

// loadImsLib embeds the imslib.js script which creates the window.adobeIMS
export function getAdobeIms() {
  return window.adobeIMS;
}


export function getUnifiedShellUrl() {
  const imsEcConfig = window.environmentSettings.lens_imsEcConfig;
  // The Unified Shell runtime utilizeds a route config to determines which
  // launch URL to load in its iframe. shell_source tells Unified Shell which
  // URL to load in its iframe. See our Unified Shell route config...
  // https://git.corp.adobe.com/exc/unified-shell/blob/master/packages/unified-shell/src/js/solutions/launch.ts
  //
  // experience-dev.adobe.com and experience.adobe.com (prod)
  // shell_sources are handled automatically. For some others we
  // need to manually select which Unified Shell route config to use.
  //
  // launch-stage.adobe.com requires IMS prod, but experience-stage.adobe.com defaults to IMS stage.
  // To solve this, we specify shell_ims=prod. During signin, their IMS redirect forces
  // us back to experience.adobe.com instead of experience-stage.adobe.com. For this reason,
  // stage needs to both force shell_ims=prod and shell_source=stage.
  let unifiedShellOptions = '';
  if (window.location.hostname === 'localhost') {
    unifiedShellOptions = '?shell_source=dev&shell_metricsmode=off';
  } else if (window.location.hostname === 'launch-integration.adobe.com') {
    unifiedShellOptions = '?shell_source=integration';
  } else if (window.location.hostname === 'launch-demo.adobe.com') {
    unifiedShellOptions = '?shell_source=demo';
  } else if (window.location.hostname === 'launch-stage.adobe.com') {
    unifiedShellOptions = '?shell_source=stage&shell_ims=prod';
  }
  const currentPath = window.location.pathname.replace(/^\//, '') + location.search;
  return `https://${imsEcConfig.ecHost}/${unifiedShellOptions}#/data-collection/${currentPath}`;
}

export function loadUnifiedShellRuntime(onReady) {
  window.EXC_US_HMR = true;
  if ('exc-module-runtime' in window) {
    onReady();
  } else {
    window.EXC_MR_READY = () => onReady();
  }

  // unified shell module runtime...
  // https://git.corp.adobe.com/exc/unified-shell/blob/master/packages/unified-shell/src/js/runtime-loader.min.js
  try {
    // eslint-disable-next-line
    (function(e,t){if(t.location===t.parent.location)throw new Error("Module Runtime: Needs to be within an iframe!");var o=function(e){var t=new URL(e.location.href).searchParams.get("_mr");return t||!e.EXC_US_HMR?t:e.sessionStorage.getItem("unifiedShellMRScript")}(t);if(!o)throw new Error("Module Runtime: Missing script!");if("https:"!==(o=new URL(decodeURIComponent(o))).protocol)throw new Error("Module Runtime: Must be HTTPS!");if(!/^(exc-unifiedcontent\.)?experience(-qa|-stage|-cdn|-cdn-stage)?\.adobe\.(com|net)$/.test(o.hostname)&&!/localhost\.corp\.adobe\.com$/.test(o.hostname))throw new Error("Module Runtime: Invalid domain!");if(!/\.js$/.test(o.pathname))throw new Error("Module Runtime: Must be a JavaScript file!");t.EXC_US_HMR&&t.sessionStorage.setItem("unifiedShellMRScript",o.toString());var n=e.createElement("script");n.async=1,n.src=o.toString(),n.onload=n.onreadystatechange=function(){n.readyState&&!/loaded|complete/.test(n.readyState)||(n.onload=n.onreadystatechange=null,n=void 0,"EXC_MR_READY"in t&&t.EXC_MR_READY())},e.head.appendChild(n)})(document,window);
  } catch (error) {
    // if the Unified Shell Runtime fails to load, we can't communicate with Unified Shell.
    // In this case, we must rely on them to handle the failure which will show up in the form of a timeout.
    console.log('Error loading Unified Shell Runtime. Are you not on an adobe.com domain?');
    console.error(error);
  }
}

let excRuntime; // this is the unified shell module runtime instance
export function createExcRuntime(configuration = {}) {
  const Runtime = window['exc-module-runtime'].default;
  excRuntime = window.excRuntime = new Runtime(configuration);
  return excRuntime;
}

export function getExcRuntime() {
  return excRuntime;
}

export function updateUnifiedShellTitleByRoutePath(routePath) {
  const nextRoute = getRouteByPath(routePath.replace(/\?.*$/, '')); // remove the querystring for path matching
  const excRuntime = getExcRuntime();
  if (excRuntime) {
    excRuntime.title = nextRoute?.title || rootDocumentTitle;
  }
}

// support manually signing in by passing the access token to the hash on first page load
// /#access_token=ACCESS_TOKEN&expires_in=24_HOURS_MS&token_type=bearer
export function getManualLoginDataFromUrl() {
  let manualLoginData = {
    accessToken: null,
    expiresIn: null,
    tokenType: null
  };

  const hash = window.location.hash;
  if (hash && hash.length) {
    hash.replace(/^#/, '').split('&').forEach(nameValuePair=>{
      const nameValuePairArray = nameValuePair.split('=');
      if (nameValuePairArray[0] === 'access_token') {
        manualLoginData.accessToken = nameValuePairArray[1];
      } else if (nameValuePairArray[0] === 'expires_in') {
        manualLoginData.expiresIn = nameValuePairArray[1];
      } else if (nameValuePairArray[0] === 'token_type') {
        manualLoginData.tokenType = nameValuePairArray[1];
      }
    });
  }

  return manualLoginData;
}

export function getManualLoginDataFromSessionStorage() {
  try {
    return getSessionStorageItem(MANUAL_SIGNIN_KEY);
  } catch (error) {
    return null;
  }
}

export function getBaseImsData() {
  const imsEcConfig = window.environmentSettings.lens_imsEcConfig;
  return {
    disableImsAuth: window.environmentSettings.disableImsAuth,
    imsDomain: imsEcConfig.imsHost,
    imsServicesHost: imsEcConfig.imsServicesHost,
    ecHost: imsEcConfig.ecHost,
    clientId: window.environmentSettings.lens_imsClientId,
    scopes: window.environmentSettings.lens_imsScopes,
    imsEcConfig
  };
}

// this function can probably go away once the profile endpoint is properly supported on experience.adobe.com
export function replaceExperienceWithExperienceCloud(url) {
  return url.replace(/experience([\.-]*)/, 'experiencecloud$1'); // because experience-* and experiencecloud-* are different things
}

export function parseJWT(token) {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  } catch (error) {
    return null;
  }
};

export function getImsClientIdFromState(state) {
  return state?.ims?.clientId;
}

export function getImsSessionUrlFromState(state) {
  const imsAccessToken = state?.ims?.imsAccessToken;
  if (imsAccessToken) {
    const tokenPayloadDecoded = parseJWT(imsAccessToken);
    const sessionState = JSON.parse(tokenPayloadDecoded.state);
    const sessionUrl = sessionState.session;
    return sessionUrl;
  }
}

export function populateImsMacConfigFromBlacksmithProfile(
  state,
  profile
) {
  const {imsEcConfig, ecHost} = state;
  const {attributes: {activeOrg: activeOrgID}} = profile;
  const activeOrg = profile.attributes.organizations[activeOrgID];
  return {
    urls: Object.keys(imsEcConfig.urls).reduce((urls, current) => {
      urls[current] = populateUrlFromOrgAndExperienceCloud({
        href:imsEcConfig.urls[current],
        activeOrgID,
        activeOrg,
        ecHost
      });
      return urls;
    }, {})
  };
}

export function populateUrlFromOrgAndExperienceCloud({
  href,
  activeOrgID,
  activeOrg,
  ecHost
}) {
  if (!activeOrg) {
    activeOrg = {};
  }

  return href.replace(
    new RegExp('<tenant>', 'g'), activeOrg.tenantId
  ).replace(
    new RegExp('<tenantUrl>', 'g'), `https://${ activeOrg.tenantId }.${ ecHost }`
  ).replace(
    new RegExp('<orgid>', 'g'), activeOrgID
  ).replace(
    new RegExp('<company>', 'g'), activeOrg.loginCompany
  );
};

export function collapseShellLeftNav() {
  // when we are doing anything that would show a properties left nav
  // we are telling shell to toggle the left nav to be turned off.
  if (window['exc-module-runtime']) {
    window['exc-module-runtime'].sidebar.collapsed = true;
  }
}

export function expandShellLeftNav() {
  if (window['exc-module-runtime']) {
    window['exc-module-runtime'].sidebar.collapsed = false;
  }
}
