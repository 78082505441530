/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/


import {getUID} from '../../../utils/dataUtils';
import {actionCreators as toastsActions} from '../../higherOrderComponents/toastsActions';
import { getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem, storageKeyPrefix, isStorageSupported } from '../../../utils/storageUtils';

export const PROCESS_LOCKED = 'procesLocked';
export const PROCESS_LOCK_TIMEOUT = 1000 * 10; // 10 seconds

// periodically during copy this window will get set as the locking window
// so that no other window can take control of the copy process unless
// this window hasn't responded for a set amount of time
export const currentWindowId = getUID();
export function refreshCurrentWindowProcessLock(params) {
  let lockExpires = new Date().getTime() + PROCESS_LOCK_TIMEOUT; // unlock after 10 seconds
  const lockData = {
    windowId: currentWindowId,
    lockExpires
  };
  setLocalStorageItem(params.company + '_propertyCopy_lock', lockData);
}
export function isProcessLockedByAnotherWindow(params) {
  // it's possible that the value was modified outside of Lens so we need to
  // do a try/catch in case it won't parse properly
  try {
    const lockData = getLocalStorageItem(params.company + '_propertyCopy_lock');
    const now = new Date().getTime();
    const currentWindowOwnsLock = lockData.windowId === currentWindowId;
    const lockExpired = now >= lockData.lockExpires;
    return !currentWindowOwnsLock && !lockExpired;
  } catch (error) {
    return false;
  }
}
export function removeCurrentWindowProcessLock(params) {
  removeLocalStorageItem(params.company + '_propertyCopy_lock');
}

export function getCompanyScopedStateFromLocalStorage(params) {
  return getLocalStorageItem(params.company + '_propertyCopy');
}
export function saveCompanyScopedStateToLocalStorage(params, propertyCopyState) {
  setLocalStorageItem(params.company + '_propertyCopy', propertyCopyState);
}
export function removeCompanyScopedStateFromLocalStorage(params) {
  removeLocalStorageItem(params.company + '_propertyCopy');
}

export function cleanupExpiredPropertyCopies(dispatch) {
  if (!isStorageSupported()) { return; }

  const expiredPropertyCopies = Object.keys(window.localStorage).filter(key=>{
    const keyRegex = new RegExp('CO\\w{32}_propertyCopy$');
    return key.match(keyRegex); // only keep propertyCopy localStorage keys
  }).filter(key=>{
    const propertyCopyState = getLocalStorageItem(key.replace(storageKeyPrefix, ''));
    return (
      propertyCopyState?.expires &&
      new Date().getTime() >= propertyCopyState?.expires // only keep expired items
    );
  });

  if (expiredPropertyCopies.length) {
    expiredPropertyCopies.forEach(key=>{
      removeLocalStorageItem(key.replace(storageKeyPrefix, ''));
      removeLocalStorageItem(key.replace(storageKeyPrefix, '') + '_lock');
    });
    dispatch(toastsActions.addToast({
      variant: 'warning',
      message: 'Property copy did not complete. Please try again.'
    }));
  };

}
