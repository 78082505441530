/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe Systems Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe Systems Incorporated and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Adobe Systems Incorporated and its
 * suppliers and are protected by all applicable intellectual property
 * laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe Systems Incorporated.
 **************************************************************************/

import {actionCreators as apiActions} from '../../../utils/api/apiActions';
import { getCompaniesFromState } from '../company/companySelectors';

export const BASE_LAYOUT_STATE_KEY = 'baseLayout';

//Action Creators
export let actionCreators = {
  loadCompanies(params, abortController) {
    return (dispatch, getState) => {
      return dispatch(apiActions.apiAction({
        name: 'getCompanies',
        urlData: params,
        abortSignal: abortController?.signal,
        stateKey: BASE_LAYOUT_STATE_KEY,
      })).then(() => {
        return getCompaniesFromState(getState());
      });
    };
  }
};
