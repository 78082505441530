/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/
import {RECENT_ACTIVITY_STATE_KEY} from './RecentActivityActions';
import {apiResponseBodyNormalizer} from '../../../utils/api/apiNormalizers';


export function getAuditEventsQueryObject({
  params,
  userEmail,
  resourceType,
  abortController,
  limitTotalResultsCount
}) {
  let property = params.property;

  return {
    name: 'elasticSearch',
    abortSignal: abortController.signal,
    stateKey: RECENT_ACTIVITY_STATE_KEY,
    data: {
      data: {
        size: limitTotalResultsCount,
        query: {
          'relationships.entity.data.type': {
            value: resourceType,
          },
          'relationships.property.data.id': {
            value: property
          },
          'attributes.attributed_to_email': {
            value: userEmail
          }
          // There is currently no way to get latest and not deleted items.
          // If it gets added to the API we should add those here.
          //
          // 'attributes.revision_number': {
          //   value: 0, // get the latest revision.
          // },
          // 'attributes.deleted_at': {
          //   exists: false, // only get resources that have not been deleted
          // }
        },
        resourceTypes: ['audit_events'],
        sort: [{
          'attributes.created_at': 'desc'
        }]
      }
    },
  };
}


export function sortAuditEventsAndParseEntityObjects(combinedAuditEvents) {
  let parsedEntityAuditEvents = combinedAuditEvents.map(auditEvent => {
    return {
      ...auditEvent,
      attributes: {
        ...auditEvent.attributes,
        entity: apiResponseBodyNormalizer({body:JSON.parse(auditEvent.attributes.entity)}).body
      }
    };
  });

  // sort and return audit events based on the createdAt attribute
  return parsedEntityAuditEvents.sort((auditEventA, auditEventB) => {
    const auditEventADate = new Date(auditEventA.attributes.createdAt);
    const auditEventBDate = new Date(auditEventB.attributes.createdAt);

    return auditEventBDate - auditEventADate;
  });
}
