import Immutable from 'seamless-immutable';
import {queryParser} from '../../utils/sortFilterQueryParamsUtils';
import {getCurrentQueryParamsFromState} from '../../routes/routeSelectors';
import {getLastLocationFromState} from '../../routes/routeSelectors';
import {DEFAULT_PAGINATION_QUERY} from './paginationActions';
import {isEndpointLoadingForStateKey, getApiMappingName} from '../../utils/api/apiTools';

export const getPaginationFromState = (stateKey, state, apiMapping) => {
  const defaultPagination = Immutable({
    pageItems: [],
    query: {},
    pendingQuery: {}
  });
  const statePagination = state.pagination?.[stateKey];
  const newPagination = defaultPagination.merge([statePagination], { deep: true });
  const loading = isEndpointLoadingForStateKey(state, stateKey, apiMapping || getApiMappingName(newPagination.endpointGroupKey, 'get', true));
  return newPagination.set('loading', loading);
};

export const getPaginationQuery = (
  paginationStateKey,
  editViewRouteNames = [],
  useBrowserHistory,
  state
) => {
  const queryParamsFromUrl = queryParser(getCurrentQueryParamsFromState(state));

  const pagination = getPaginationFromState(paginationStateKey, state);
  const lastQuery = pagination?.query;
  const lastLocation = getLastLocationFromState(state);

  // if we navigated here from the editViewLocation when we'll restore the query
  const shouldRestorePreviousQueryParams = Boolean(
    useBrowserHistory &&
    lastQuery &&
    editViewRouteNames?.length > 0 &&
    editViewRouteNames.includes(lastLocation?.route?.name)
  );

  const DEEP_MERGE = { deep: true };
  let query = Immutable({});

  if (useBrowserHistory) {
    query = query.merge([queryParamsFromUrl], DEEP_MERGE);
  } else {
    query = query.merge([DEFAULT_PAGINATION_QUERY], DEEP_MERGE);
  }

  if (shouldRestorePreviousQueryParams) {
    query = query.merge([lastQuery], DEEP_MERGE);
  }

  return query;
};
