/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import actionsHandler from '../../redux/actionsHandler';
import Immutable from 'seamless-immutable';

export const SET_SELECTABLE_ITEMS = 'selections/SET_SELECTABLE_ITEMS';
export const TOGGLE_SELECT_ITEM = 'selections/TOGGLE_SELECT_ITEM';
export const TOGGLE_SELECT_ALL = 'selections/TOGGLE_SELECT_ALL';
export const DESELECT_ALL = 'selections/UNSELECT_ALL';


//Reducers
export default actionsHandler({
  [SET_SELECTABLE_ITEMS]: (state, action)=> {
    if (!action.payload.stateKey) {
      if (window.environmentSettings.lens_debug) {
        console.warn('The specified action is missing a stateKey and will not get selectableListOfItems setup', action);
      }
      return state;
    }

    const {stateKey, selectableItems} = action.payload;
    return state.set(stateKey, {
      selectableItems: selectableItems,
      selectedItems: []
    });
  },
  [TOGGLE_SELECT_ITEM]: (state, action)=> {
    const {stateKey, selectable} = action.payload;
    let selectedItems = state[stateKey].selectedItems;
    const selectedIndex = selectedItems.findIndex((selectedItem) => {
      return selectedItem.id === selectable.id;
    });
    selectedItems = selectedIndex === -1 ?
      selectedItems.concat(selectable) :
      selectedItems.slice(0, selectedIndex).concat(selectedItems.slice(selectedIndex + 1));

    return state.setIn([stateKey, 'selectedItems'], selectedItems);
  },
  [TOGGLE_SELECT_ALL]: (state, action)=> {
    const {stateKey} = action.payload;
    const selectableItems = state[stateKey].selectableItems;
    let selectedItems = state[stateKey].selectedItems;

    selectedItems = selectedItems.length === selectableItems.length ? [] : selectableItems;

    return state.setIn([stateKey, 'selectedItems'], selectedItems);
  },
  [DESELECT_ALL]: (state, action)=> {
    const {stateKey} = action.payload;
    return state.setIn([stateKey, 'selectedItems'], []);
  },
  default:(state = Immutable({}))=> {
    return state;
  }
});


//Action Creators creator
export let getActionCreators = (stateKey)=> {
  return {
    setSelectableItems(selectableItems) {
      return {
        type: SET_SELECTABLE_ITEMS,
        payload: {
          stateKey,
          selectableItems
        }
      };
    },
    toggleSelectItem(selectable) {
      return {
        type: TOGGLE_SELECT_ITEM,
        payload: {
          stateKey,
          selectable
        }
      };
    },
    toggleSelectAll() {
      return {
        type: TOGGLE_SELECT_ALL,
        payload: {
          stateKey
        }
      };
    },
    deselectAll() {
      return {
        type: DESELECT_ALL,
        payload: {
          stateKey
        }
      };
    }
  };
};
