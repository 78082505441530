/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe Systems Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe Systems Incorporated and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Adobe Systems Incorporated and its
 * suppliers and are protected by all applicable intellectual property
 * laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe Systems Incorporated.
 **************************************************************************/

import React, {useRef, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {populateNamedRoutePath} from '../../routes/namedRouteUtils';
// this import is what loads the breadcrumb styles
// eslint-disable-next-line no-unused-vars
import Breadcrumbs from '@react/react-spectrum/Breadcrumbs';
import {push} from '../../routes/namedRouteUtils';
import TruncateString from '../higherOrderComponents/react-truncate-string';
import { padStringForReact } from '../../utils/dataUtils';
import { getCurrentRouteParamsFromState } from '../../routes/routeSelectors';
import { getCapabilityDisplayName, getCapabilityLocation } from '../../routes/capabilityUtils';
import {ActionButton} from '@react-spectrum/button';
import FolderBreadcrumb from '@spectrum-icons/ui/FolderBreadcrumb';
import ChevronRightSmall from '@spectrum-icons/ui/ChevronRightSmall';
import {Menu, MenuTrigger, Item} from '@react-spectrum/menu';
import WindowSizeAware from '../higherOrderComponents/WindowSizeAware';
import { getCompanyFromState } from '../layouts/company/companySelectors';

// hacks the breadcrumb links to have an href and also
// uses push to navigate so as not to reload the window
const LinkedBreadcrumb = ({
  params,
  dispatch,
  items = [],
  displayCapability = true,
  displayCompany = false,
  windowWidth,
  company
} = {}) => {
  const [collapsedItems,setCollapsedItems] = useState([]);
  const containerRef = useRef(null);
  const previousWindowWidthRef = useRef(windowWidth);

  items = [
    ...(
      displayCompany ? [{
        label: 'Companies',
        location: {name:'companies',params},
        className: 'u-flexShrink0'
      }, {
        label: company?.attributes?.name || 'No Company Selected',
        location: {name:'company',params}
      }] : []
    ),
    ...items
  ];

  if (displayCapability) {
    items.unshift({
      label: getCapabilityDisplayName(params.capability),
      location: getCapabilityLocation(params.capability, params),
      className: 'u-flexShrink0'
    });
  }

  items.forEach((item,index)=>{item.id = index;});


  items = items.filter((item)=>(
    !collapsedItems.find((collapsedItem)=>(collapsedItem.id === item.id))
  ));

  useEffect(()=>{
    handleCollapsedItems();
  }, [containerRef.current,collapsedItems]);
  useEffect(()=>{
    if (previousWindowWidthRef.current !== windowWidth) {
      handleCollapsedItems({
        previousWindowWidth: previousWindowWidthRef.current,
        currentWindowWidth: windowWidth,
        widthChange:true
      });
    }
  },[windowWidth]);
  useEffect(() => {
    previousWindowWidthRef.current = windowWidth;
  });


  function collapseItem(totalWidth, containerDom) {
    if (
      totalWidth === containerDom.offsetWidth &&
      items.length >= 3
    ) {
      // remove one item from the list
      setCollapsedItems([items[1],...collapsedItems]);
      // This is to force the TruncateString script to run again
      window.dispatchEvent(new Event('resize'));
    }
  }

  function uncollapseItem() {
    setCollapsedItems(collapsedItems.slice(1));
    window.dispatchEvent(new Event('resize'));
  }

  function handleCollapsedItems({
    widthChange = false,
    previousWindowWidth,
    currentWindowWidth
  } = {}) {
    const containerDom = containerRef.current;
    const listItemsDom = Array.from(containerDom.querySelectorAll('.spectrum-Breadcrumbs-item'));

    const totalWidth = listItemsDom.reduce((acc, nextDomNode)=>{
      const computedStyle = getComputedStyle(nextDomNode);
      return (
        acc +
        nextDomNode.offsetWidth +
        parseInt(computedStyle.marginRight) +
        parseInt(computedStyle.marginLeft)
      );
    },0);

    if (widthChange) {
      if (previousWindowWidth < currentWindowWidth) {
        uncollapseItem();
      } else if (previousWindowWidth > currentWindowWidth) {
        collapseItem(totalWidth,containerDom);
      }
    } else {
      collapseItem(totalWidth,containerDom);
    }
  }

  const allItems = [
    ...items.slice(0,1),
    ...collapsedItems.reverse(),
    ...items.slice(1)
  ];

  const newItems = collapsedItems.length ? [
    ...items.slice(0,1),
    {
      label: (
        <MenuTrigger>
          <ActionButton
            aria-label="…"
            isQuiet
          >
            <FolderBreadcrumb />
          </ActionButton>
          <Menu
            selectionMode='single'
            selectedKeys={[
              // the to string stuff here is needed react-spectrum gets confused if you use a number here.
              allItems[allItems.length - 1].id.toString()
            ]}
            onAction={(key)=>{
              const itemActedOn = allItems.find((item)=>item.id.toString() === key);
              itemActedOn.location && dispatch(push(itemActedOn.location));
            }}
          >
            {allItems.map((item)=>(
              <Item
                key={item.id.toString()}
              >
                <a
                  href={item.location && populateNamedRoutePath(item.location)}
                  style={{
                    gridArea:'text',
                    color: 'unset',
                    textDecoration: 'unset'
                  }}
                  className="u-ellipsis"
                  onClick={(e)=>{
                    e?.preventDefault(); // ignore the href and use the location instead
                  }}
                >
                  {item.label}
                </a>
              </Item>
            ))}
          </Menu>
        </MenuTrigger>
      ),
      isCollapsedMenu: true,
      className: 'u-flexShrink0',
      id: 'collapsedMenu'
    },
    ...items.slice(1)
  ] : items;


  return (
    <div
      data-private
      className="linkedBreadcrumb u-flex u-flexOne"
      data-test-id="linkedBreadcrumb"
      ref={containerRef}
    >
      <div className="u-flex u-flexOne">
        <ul className={'u-flex u-flexOne spectrum-Breadcrumbs'}>

          {newItems.map((item, index)=>{
            let itemWithLink = {
              label: typeof item.label === 'string' ? (
                <span className="u-flex" title={item.title || item.label}>
                  <TruncateString
                    className="u-flexOne"
                    ellipsisString={'\u2026'}
                    text={padStringForReact(item.label, {rightPad: 6})} // add extra space which mostly helps right side from being partially hidden
                  />
                </span>
              ) : item.label
            };

            if (item.location) {
              itemWithLink.href = populateNamedRoutePath(item.location);
              itemWithLink.onClick = (e) => {
                e?.preventDefault(); // ignore the href and use the location instead
                dispatch(push(item.location));
              };
            }

            if (item.onClick) {
              itemWithLink.onClick = item.onClick;
            }

            return (
              <li
                className={
                  'spectrum-Breadcrumbs-item ' +
                  (item.isCollapsedMenu ? 'collapsedMenu ' : '') +
                  (item.className || '')
                }
                key={item.id}
              >
                {item.isCollapsedMenu ? (
                  <span className="u-ellipsis u-flex spectrum-Breadcrumbs-itemLink">
                    {itemWithLink.label}
                  </span>
                ) : (
                  <a
                    onClick={itemWithLink.onClick}
                    href={itemWithLink.href}
                    className="u-ellipsis u-flex spectrum-Breadcrumbs-itemLink"
                    role="link"
                  >
                    {itemWithLink.label}
                  </a>
                )}
                {newItems.length === index + 1 ? null : (
                  <ChevronRightSmall UNSAFE_className="spectrum-Breadcrumbs-itemSeparator" />
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    params: getCurrentRouteParamsFromState(state),
    company: getCompanyFromState(state)
  };
}

const ConnectedLinkedBreadcrumb = connect(mapStateToProps)(LinkedBreadcrumb);

export default WindowSizeAware(ConnectedLinkedBreadcrumb, {resizeDelay: 500});
