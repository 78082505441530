/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

export function buildSaveData(environment, host, initialValues = {}) {
  let saveData = {
    data: {
      attributes: {
        name: environment.name,
        stage: environment.stage,
        relativePath: environment.relativePath,
        archive: environment.archive
      },
      id: environment.id,
      type: 'environments',
      relationships: {
        host: {
          data: {
            id: host.id,
            type: 'hosts'
          }
        }
      },
    }
  };

  if (environment.archivePassphrase) {
    saveData.data.attributes.archivePassphrase = environment.archivePassphrase;
  }
  // To remove the archive passphrase you must set it to an empty string.
  if (initialValues.archiveEncrypted && !environment.archiveEncrypted) {
    saveData.data.attributes.archivePassphrase = '';
  }
  // when switch from SFTP to akamai hosts path needs to be an empty string
  // this is due to how its implemented by the api.
  saveData.data.attributes.path = (
    host.attributes.typeOf === 'sftp' || environment.archive ?
    environment.path.trim() :
    ''
  );

  return saveData;
};
