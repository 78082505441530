/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import {getActionCreators as getPaginationActions} from '../pagination/paginationActions';
import { getPaginationFromState } from '../pagination/paginationSelectors';
import {getActionCreators as getSelectableListOfItemsActions} from './selectableListActions';
import {getActionCreators as getDialogsActions} from '../higherOrderComponents/dialogsActions';
import {actionCreators as apiActions} from '../../utils/api/apiActions';
import {getApiMappingName} from '../../utils/api/apiTools';
import {registerScrollHandler, unregisterScrollHandler} from '../../utils/domUtils';
import {getActionCreators as getPublishingActions} from '../properties/publishing/publishingActions';
import Immutable from 'seamless-immutable';
import actionsHandler from '../../redux/actionsHandler';
import {getControlledListOptionsFromState} from './controlledListSelectors';

const publishingActions = getPublishingActions('controlledList');

export const CONFIRMDELETE_DIALOG_KEY = 'CONFIRMDELETE';
export const CONFIRMDELETEDISCONTINUED_DIALOG_KEY = 'CONFIRMDELETEDISCONTINUED';
export const DELETEFAILED_DIALOG_KEY = 'DELETEFAILED';
export const ENABLEINFO_DIALOG_KEY = 'ENABLEINFO';
export const DISABLEINFO_DIALOG_KEY = 'DISABLEINFO';
export const ENABLED_CHANGED_DIALOG_KEY = 'ENABLED_CHANGED';

const INITIALIZE = 'controlledList/INITIALIZE';
const SET_DELETION_FAILURE_RESOURCES = 'controlledList/SET_DELETION_FAILURE_RESOURCES';
const SET_DELETING = 'controlledList/SET_DELETING';

//Reducers
export default actionsHandler({
  [INITIALIZE]: (
    state,
    {
      payload: {
        stateKey,
        translateCase,
        filterToPlatform,
        filterToMaxAvailability,
        optionalBaseQueryParams = {},
        infiniteScrollDomSelector
      }
    }
  ) => {
    return state.updateIn([stateKey], (listOptions = {}) => {
      return Immutable.merge(listOptions, {
        translateCase,
        filterToPlatform,
        filterToMaxAvailability,
        optionalBaseQueryParams,
        infiniteScrollDomSelector
      });
    });
  },
  [SET_DELETION_FAILURE_RESOURCES]: (state, action)=> {
    return state.set('deletionFailureResources', action.payload);
  },
  [SET_DELETING]: (state, action) => {
    return state.set('deleting', action.payload);
  },
  default:(state = Immutable({}))=> {
    return state;
  }
});

export const getActionCreators = (stateKey) => {
  const infiniteScrollEventKey = stateKey + '_infiniteScroll';

  const listActions = {
    initialize({
      translateCase = true,
      filterToPlatform,
      filterToMaxAvailability,
      optionalBaseQueryParams,
      infiniteScrollDomSelector,
    } = {}) {
      return (dispatch, getState) => {
        const pagination = getPaginationFromState(stateKey, getState());

        if (!pagination?.isInitialized) {
          throw new Error(
            `pagination for "${stateKey}" is required to be initialized before initializing listActions.`
          );
        }

        dispatch({
          type: INITIALIZE,
          payload: {
            stateKey,
            translateCase,
            filterToPlatform,
            filterToMaxAvailability,
            optionalBaseQueryParams,
            infiniteScrollDomSelector
          }
        });

        if (infiniteScrollDomSelector) {
          const paginationActions = getPaginationActions(stateKey);
          // we want to reset infiniteScroll lists on mount because we don't currently
          // have a use case for restoring their last position
          dispatch(paginationActions.resetData());
          dispatch(paginationActions.clearPendingQueryFilter());
        }
      };
    },
    deleteListItems({
      resources,
      endpointGroupKeyOverride,
      paginationStateKey = stateKey,
      dialogsStateKey = stateKey
    } = {}) {
      return (dispatch, getState) => {
        dispatch(listActions.setDeleting(true));
        const { endpointGroupKey } = getPaginationFromState(stateKey, getState());
        const deletePromises = resources.map((resource)=> {
          return dispatch(apiActions.apiAction({
            name: getApiMappingName(endpointGroupKeyOverride || endpointGroupKey, 'delete'),
            urlData: {[endpointGroupKeyOverride || endpointGroupKey]: resource.id},
            stateKey,
            translateCase: getState().controlledList[stateKey]?.translateCase,
            bypassError:true
          }))
          .then(() => {
            return {
              success: true,
              resource
            };
          })
          .catch(() => {
            return {
              success: false,
              resource
            };
          });
        });

        const dialogsActions = getDialogsActions(dialogsStateKey);

        return Promise.all(deletePromises).then((summaries) => {
          dispatch(dialogsActions.closeDialog(CONFIRMDELETE_DIALOG_KEY));
          dispatch(dialogsActions.closeDialog(CONFIRMDELETEDISCONTINUED_DIALOG_KEY));

          const failedResources = summaries.filter((summary) => {
            return !summary.success;
          }).map((summary) => {
            return summary.resource;
          });

          if (failedResources.length) {
            dispatch(listActions.setDeleteFailedResources(failedResources));
            dispatch(dialogsActions.openDialog(DELETEFAILED_DIALOG_KEY));
          }

          const successfulDeletionCount = summaries.length - failedResources.length;
          const pagination = getState().pagination[stateKey];
          const pageSize = getState().pagination[stateKey]?.pageSize;
          const newLastPage = Math.ceil((pagination.totalCount - successfulDeletionCount) / pageSize);

          if (newLastPage < pagination.currentPage) {
            dispatch(getPaginationActions(paginationStateKey).setPendingQueryPage(newLastPage));
          }

          dispatch(listActions.setDeleting(false));
          return dispatch(listActions.loadList());
        });
      };
    },
    setDeleting(loadingStatus) {
      return {
        type: SET_DELETING,
        payload: loadingStatus
      };
    },
    setDeleteFailedResources(resources) {
      return {
        type: SET_DELETION_FAILURE_RESOURCES,
        payload: resources
      };
    },
    updateEnabledForListItems({
      params: reactRouterParams,
      listItems,
      enabled,
      endpointGroupKeyOverride,
      selectableListActionsStateKey = stateKey,
      dialogsStateKey = stateKey
    } = {}) {
      return (dispatch, getState) => {
        let dialogKey = enabled ? ENABLEINFO_DIALOG_KEY : DISABLEINFO_DIALOG_KEY;
        const workingLibrary = getState().workingLibrary.library;
        const { endpointGroupKey } = getPaginationFromState(stateKey, getState());

        let updatePromises = listItems.map(
          (listItem)=>{
            // prep for save
            listItem = listItem.update('attributes', (attributes) => {
              return attributes
                .without('revisionNumber')
                .set('enabled', enabled);
            });

            return dispatch(apiActions.apiAction({
              name: getApiMappingName(endpointGroupKeyOverride || endpointGroupKey, 'patch'),
              urlData: {
                property:reactRouterParams.property,
                [endpointGroupKeyOverride || endpointGroupKey]:listItem.id
              },
              stateKey,
              translateCase: getState().controlledList[stateKey]?.translateCase,
              data: { data: listItem }
            }));
          }
        );

        return Promise.all(updatePromises).then(()=>{
          return dispatch(listActions.loadList());
        }).then(()=>{
          // retain the user's prior selectedItems
          const selectableListActions = getSelectableListOfItemsActions(selectableListActionsStateKey);
          listItems.forEach((listItem)=>{
            dispatch(
              selectableListActions.toggleSelectItem(listItem)
            );
          });

          const dialogsActions = getDialogsActions(dialogsStateKey);
          if (workingLibrary) {
            dispatch(dialogsActions.openDialog(ENABLED_CHANGED_DIALOG_KEY));
          } else {
            dispatch(dialogsActions.openDialog(dialogKey));
          }
        });
      };
    },
    addSelectedItemsToLibraryAndBuild(reactRouterParams, listItems, selectedDevLibrary) {
      return (dispatch) => {
        dispatch(listActions.closeEnabledChangedDialog());

        return dispatch(publishingActions.saveLatestDelegatesToLibraryAndBuild(
          {...reactRouterParams, library: selectedDevLibrary.id},
          listItems,
          selectedDevLibrary
        ));
      };
    },
    loadList({
      bypassError,
      abortSignal,
      swallowAbortErrors,
      paginationStateKey = stateKey,
    } = {}) {
      return (dispatch, getState) => {
        const controlledListOptions = getControlledListOptionsFromState(paginationStateKey, getState());

        // Take care not to modify anything in the original optionalBaseQueryParams object.
        let forcedQueryWithPlatformAndMaxAvailability = {
          ...controlledListOptions.optionalBaseQueryParams
        };
        const property = getState().api.property;

        if (controlledListOptions.filterToPlatform) {
          forcedQueryWithPlatformAndMaxAvailability.filter = {
            ...(forcedQueryWithPlatformAndMaxAvailability.filter || {}),
            platform: {
              [property.attributes.platform]: {qualifiers: {'EQ': true}},
              // TODO: Remove this as soon as the backend starts preventing sparse extension packages
              null: {qualifiers: {'EQ': true}}
            }
          };
        }

        if (controlledListOptions.filterToMaxAvailability && !property.attributes.development) {
          forcedQueryWithPlatformAndMaxAvailability.max_availability = 'private';
        }

        const paginationActions = getPaginationActions(paginationStateKey);
        return dispatch(paginationActions.loadPaginationPage({
          forcedQuery: forcedQueryWithPlatformAndMaxAvailability,
          translateCase: getState().controlledList[stateKey]?.translateCase,
          bypassError,
          abortSignal,
          swallowAbortErrors,
          pageSize: getState().pagination[stateKey]?.pageSize
        }));
      };
    },
    // in some cases this needs to be called manually (data element selector model for example)
    attachInfiniteScrollDomEvent() {
      return (dispatch, getState) => {
        // always unregister old handler before registering the new
        unregisterScrollHandler(infiniteScrollEventKey);

        // this will register the scroll handler for the NEXT page (not the current page)
        const controlledListOptions = getControlledListOptionsFromState(stateKey, getState());

        registerScrollHandler(
          infiniteScrollEventKey,
          controlledListOptions.infiniteScrollDomSelector,
          ()=>{ return dispatch(listActions.loadInfiniteScrollList({isFirstPage: false})); },
          50,
          true
        );
      };
    },
    loadInfiniteScrollList({isFirstPage} = {}) {
      return (dispatch, getState) => {
        const {
          currentPage,
          totalPages
        } = getPaginationFromState(stateKey, getState());
        const isLastPage = !isFirstPage && currentPage >= totalPages;

        // only register scroll handler if we are not on the last page
        if (!isLastPage) {
          if (currentPage && !isFirstPage) {
            dispatch(getPaginationActions(stateKey).incrementPendingQueryPage());
          }
          // this will load the NEXT page (not the current page)
          dispatch(listActions.loadList()).then(()=>{
            dispatch(listActions.attachInfiniteScrollDomEvent());
          });
        }
      };
    },
    closeEnableInfoDialog({ dialogsStateKey = stateKey } = {}) {
      return getDialogsActions(dialogsStateKey).closeDialog(ENABLEINFO_DIALOG_KEY);
    },
    closeDisableInfoDialog({ dialogsStateKey = stateKey } = {}) {
      return getDialogsActions(dialogsStateKey).closeDialog(DISABLEINFO_DIALOG_KEY);
    },
    closeEnabledChangedDialog({ dialogsStateKey = stateKey } = {}) {
      return getDialogsActions(dialogsStateKey).closeDialog(ENABLED_CHANGED_DIALOG_KEY);
    },
    closeDeleteFailedDialog({ dialogsStateKey = stateKey } = {}) {
      return getDialogsActions(dialogsStateKey).closeDialog(DELETEFAILED_DIALOG_KEY);
    },
    onViewUnmounted({ paginationStateKey = stateKey } = {}) {
      return (dispatch, getState) => {
        const listOptions = getControlledListOptionsFromState(paginationStateKey, getState());
        if (listOptions?.infiniteScrollDomSelector) {
          unregisterScrollHandler(infiniteScrollEventKey);
        }
      };
    }
  };

  return listActions;
};
