/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

// Given a bound group, find the complementary bound group in the
// complementary column.
function getComplementaryBoundGroup(boundGroup, complementaryColumn) {
  const pairItemIndices = boundGroup.map((boundGroupItem) => {
    return complementaryColumn.indexOf(boundGroupItem);
  }).filter((boundGroupItemIndex) => {
    return boundGroupItemIndex !== -1;
  });

  if (pairItemIndices.length) {
    return complementaryColumn.slice(
      Math.min(...pairItemIndices),
      Math.max(...pairItemIndices) + 1
    );
  } else {
    return [];
  }
}

// Given the index in column 1 that should form the top boundary of the bound group,
// find the items within the bound group.
function getBoundGroup(topItemColumn1Index, column1, column2) {
  const topItem = column1[topItemColumn1Index];
  const topItemColumn2Index = column2.indexOf(topItem);

  if (topItemColumn2Index === -1) {
    return [topItem];
  }

  // Find the bottom item in the bound group.
  for (
    let bottomItemCandidateIndex = column1.length - 1;
    bottomItemCandidateIndex >= topItemColumn1Index;
    bottomItemCandidateIndex--
  ) {
    const bottomItemCandidate = column1[bottomItemCandidateIndex];
    const bottomItemCandidateComplementaryIndex = column2.indexOf(bottomItemCandidate);

    // If this item has a pair and the pair is at or above the pair of the top item in
    // the bound group, than this item is, in fact, the bottom item in the bound
    // group. If not, keep looking.
    if (
      bottomItemCandidateComplementaryIndex !== -1 &&
      bottomItemCandidateComplementaryIndex <= topItemColumn2Index
    ) {
      return column1.slice(topItemColumn1Index, bottomItemCandidateIndex + 1);
    }
  }
}

// Given a bound group from column 1, find the pair of bound groups. Note that
// the bound group from column 1 passed into this function may not match the
// bound group from column 1 returned from this function due to it being
// expanded to include more items.
function getBoundGroupPair(boundGroup1, column1, column2) {
  let prevBoundGroup1;
  let prevBoundGroup2;
  let boundGroup2 = getComplementaryBoundGroup(boundGroup1, column2);

  // Items in one bound group may expand the complementary bound group. We
  // need to go back and forth, expanding the bound groups as necessary until
  // we have all applicable items for both groups.
  while (
    boundGroup1.length &&
    boundGroup2.length &&
    (
      !prevBoundGroup1 ||
      !prevBoundGroup2 ||
      boundGroup1.length !== prevBoundGroup1.length ||
      boundGroup2.length !== prevBoundGroup2.length
    )
  ) {
    prevBoundGroup1 = boundGroup1;
    prevBoundGroup2 = boundGroup2;
    boundGroup1 = getComplementaryBoundGroup(prevBoundGroup2, column1);
    boundGroup2 = getComplementaryBoundGroup(boundGroup1, column2);
  }

  return [
    boundGroup1,
    boundGroup2
  ];
}

// The goal is get each pair of items vertically as close to each other
// as possible while still maintaining order.
//
// It's very important to keep in mind that items may be present in one column
// and not the other. To figure out proper vertical placement of items, we'll
// start at the top of column 1 and move our way down finding bound groups
// of items along the way. Bound groups of items can be identified as follows:
//
//  1. As Item A moves vertically closer to being aligned with Item A's pair,
//     it eventually moves Item B farther away from Item B's pair. Likewise,
//     as Item B moves vertically closer to being aligned with Item B's pair,
//     it eventually moves Item A farther away from Item A's pair.
//  2. All items between the two most vertically distant bound items are
//     considered to be in the same bound group. In other words, if Item X
//     is between Item A an Item B in the previous example, the bound group
//     would include Item A, Item X, and Item B. Note that Item X may or
//     may not have a pair of its own.
//
// If an item can be vertically aligned with its pair without forcing other
// items to distance themselves from their own pairs, that item will be
// treated as its own bound group and no other item will be in its bound group.
//
// Once a bound group of items has been identified in column 1, the
// complementary group in column 2 must then be identified. Given a bound
// group of Item A, Item X, and Item B (in vertical order) from column 1,
// we look to Item A, Item X, and Item B in column 2. In column 2, the most
// vertically distant amongst Item A, Item X, and Item B form the boundary
// of our bound group in column 2. Because the bound group in column 2 may
// contain a new item that should need to force the bound group in column 1
// to expand, we need to re-evaluate the column 1 bound group against the
// items in the column 2 bound group. We go back and forth, expanding the
// bound group on both columns until the length of the bound groups ceases
// to grow. At this point, we have found our bound group pair.
//
// The consumer will then typically attempt to vertically center the
// bound groups within each pair.
export function getBoundGroupPairs(columns, options = {}) {
  // Convert from original values to IDs if getItemId is specified as an option.
  let itemsByIdByColumn;
  if (options.getItemId) {
    itemsByIdByColumn = [];
    columns = columns.map((column) => {
      const itemsById = {};
      itemsByIdByColumn.push(itemsById);
      return column.map((item) => {
        const itemId = options.getItemId(item);
        itemsById[itemId] = item;
        return itemId;
      });
    });
  }

  let [
    column1,
    column2
  ] = columns;

  let boundGroupPairs = [];
  let column1Index = 0;
  let column2Index = 0;

  while (column1Index < column1.length) {
    const initialBoundGroup1 = getBoundGroup(column1Index, column1, column2);
    const [boundGroup1, boundGroup2] = getBoundGroupPair(initialBoundGroup1, column1, column2);

    // If bound group 2 has items in it, we need to see if any items are
    // above the group in column 2. Each item found should be placed in
    // its own bound group (it doesn't have a pair in column 1) and added
    // before we add the bound group pair we found.
    if (boundGroup2.length) {
      const boundGroupPairTopItemIndex = column2.indexOf(boundGroup2[0]);
      const boundGroupPairBottomItemIndex = column2.indexOf(boundGroup2[boundGroup2.length - 1]);

      if (boundGroupPairTopItemIndex > column2Index) {
        for (let i = column2Index; i < boundGroupPairTopItemIndex; i++) {
          boundGroupPairs.push([
            [],
            [column2[i]]
          ]);
        }

      }

      column2Index = boundGroupPairBottomItemIndex + 1;
    }

    boundGroupPairs.push([
      boundGroup1,
      boundGroup2
    ]);

    const group1BottomIndex = column1.indexOf(boundGroup1[boundGroup1.length - 1]);
    column1Index = group1BottomIndex + 1;
  }

  // After we've gone through all items in column 1 finding any bound
  // group pairs, we may be left with some items remaining in column 2.
  // Each item should be placed in its own bound group (it doesn't have
  // a pair in column 1).
  while (column2Index < column2.length) {
    boundGroupPairs.push([
      [],
      [column2[column2Index]]
    ]);
    column2Index++;
  }

  // Add placeholders if requested.
  if (options.placeholder !== undefined) {
    boundGroupPairs.forEach((boundGroupPair) => {
      const boundGroup1 = boundGroupPair[0];
      const boundGroup2 = boundGroupPair[1];

      boundGroup1.forEach((item, index) => {
        if (boundGroup2.indexOf(item) === -1) {
          boundGroup2.splice(index, 0, options.placeholder);
        }
      });

      boundGroup2.forEach((item, index) => {
        if (item !== options.placeholder && boundGroup1.indexOf(item) === -1) {
          boundGroup1.splice(index, 0, options.placeholder);
        }
      });
    });
  }

  // Convert from IDs to original values.
  if (options.getItemId) {
    boundGroupPairs = boundGroupPairs.map((boundGroupPair) => {
      return boundGroupPair.map((boundGroup, columnIndex) => {
        return boundGroup.map((itemId) => {
          return itemId === options.placeholder ?
            options.placeholder :
            itemsByIdByColumn[columnIndex][itemId];
        });
      });
    });
  }

  return boundGroupPairs;
}
