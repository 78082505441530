import React, { useEffect } from 'react';


let timeout;
function titleUpdater( title ) {
  clearTimeout(timeout);
  timeout = setTimeout(()=>{
    document.title = title;
  },10);
}


export default function DocumentTitle({title, children}) {
  useEffect(()=>{
    titleUpdater(title);
  });
  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
};
