/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2022 Adobe Systems Incorporated
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and are protected by all applicable intellectual property
* laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
**************************************************************************/

import React, {Component} from 'react';
import {EQ, CONTAINS} from '../../utils/sortFilterQueryParamsUtils';
import {get} from 'lodash-es';
import FilterIcon from '@react/react-spectrum/Icon/Filter';
import CloseIcon from '@react/react-spectrum/Icon/Close';
import Checkbox from '@react/react-spectrum/Checkbox';
import classNames from 'classnames';
import SearchWithFilter from './SearchWithFilter';
import {connect} from 'react-redux';


class TableActions extends Component {
  constructor(props) {
    super(props);
    const filterQuery = Object.keys(get(props.listQuery, ['filter', props.searchOnKey]) || {})[0];
    // Commas are escaped for the api call. This reverses it for display.
    this.defaultSearchValue = filterQuery ? filterQuery.replace(/\\,/g, ',') : '';
  }

  clearFilters = () => {
    const {
      dispatch,
      listActions,
      paginationActions,
      filters = []
    } = this.props;

    dispatch(paginationActions.setPendingQueryPage(1));

    filters.forEach((filter)=>{
      dispatch(paginationActions.clearPendingQueryFilter(filter.value));
    }, this);

    dispatch(listActions.loadList());
  };

  doSearch = (value) => {
    const {
      dispatch,
      paginationActions,
      listActions,
      searchOnKey,
      onSearch
    } = this.props;

    dispatch(paginationActions.setPendingQueryPage(1));
    dispatch(paginationActions.clearPendingQueryFilter(searchOnKey));

    onSearch && onSearch(value);

    if (value) {
      dispatch(paginationActions.setPendingQueryFilter(searchOnKey, value, CONTAINS));
    }

    dispatch(listActions.loadList());
  };

  render = () => {
    const {
      dispatch,
      listQuery,
      paginationActions,
      listActions,
      defaultMenu,
      selectedMenu,
      primaryCtaMenu,
      filters = [],
      displayFilters = true,
      className = '',
      usesSearchEndpoint,
      searchValue,
      onSearchChange,
      isFilterPanelOpen
    } = this.props;

    var hasSelectedRows = Boolean(this.props.selectedRows?.length);
    const anyQueryFiltersActiveBesidesName = Object.keys(
      listQuery.filter || {}
    ).filter(filterName=>{ return filterName !== 'name'; }).length;

    return usesSearchEndpoint ? (
      <div className={'CustomTable-viewControls TableActions u-overflowHidden ' + className}>
        <SearchWithFilter
          placeholder="Search"
          className="searchText u-marginBottomSm"
          onChange={onSearchChange}
          onSearch={this.doSearch}
          value={searchValue}
          defaultValue={this.defaultSearchValue}
          isFilterPanelOpen={isFilterPanelOpen}
          dispatch={dispatch}
          hasFilterPanel={true}
        />
        <div className="u-flexOne u-flex">
          <div
            className={classNames(
              'actionButtonWrapper',
              'u-flexOne',
              'u-textRight',
              'u-overflowHidden',
              'u-marginRightSm',
              {'hasSelections': hasSelectedRows}
            )}
          >
            {!hasSelectedRows ? defaultMenu : selectedMenu }
          </div>
          <div>
            {primaryCtaMenu}
          </div>
        </div>
      </div>
    ) : (
      <div className={'CustomTable-viewControls TableActions ' + className}>
        <SearchWithFilter
          placeholder="Search"
          className="searchText u-marginBottomSm"
          onChange={onSearchChange}
          onSearch={this.doSearch}
          defaultValue={this.defaultSearchValue}
          popoverIcon={displayFilters && filters.length ? (
            <FilterIcon
              size="S"
              className={classNames(
                {'u-muteText': !anyQueryFiltersActiveBesidesName},
                {'c-primary': anyQueryFiltersActiveBesidesName}
              )}
            />
          ) : null}
          menuClassName="filterPopover"
          popoverContent={displayFilters && filters.length ? (
            <div>
              {filters.map((filter, index)=>{
                const queryValues = Object.keys(get(listQuery, ['filter', filter.value]) || {});
                return (
                  <div key={index} className="u-marginBottom">
                    <div className="u-marginBottomXs u-bold">{filter.title}</div>
                    <div>
                      {Object.keys(filter.options).map((optionName, index)=>{
                        const optionValue = filter.options[optionName];
                        const isChecked = queryValues.includes(optionValue);
                        return (
                          <Checkbox
                            key={index}
                            className="u-flex"
                            checked={isChecked}
                            onClick={()=>{
                              dispatch(paginationActions.togglePendingQueryFilter(
                                filter.value, optionValue, EQ
                              ));
                              dispatch(paginationActions.setPendingQueryPage(1));
                              dispatch(listActions.loadList());
                            }}
                          >
                            {optionName}
                          </Checkbox>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
              <div className="u-link u-pointer u-marginTopLg" onClick={this.clearFilters}>
                <CloseIcon className="clearFilters u-pointer u-marginRightSm" size="XS" />Clear All
              </div>
            </div>
          ) : null}
        />
        <div className="u-flexOne u-flex">
          <div
            className={classNames(
              'actionButtonWrapper',
              'u-flexOne',
              'u-textRight',
              'u-overflowHidden',
              'u-marginRightSm',
              {'hasSelections': hasSelectedRows}
            )}
          >
            {!hasSelectedRows ? defaultMenu : selectedMenu }
          </div>
          <div>
            {primaryCtaMenu}
          </div>
        </div>
      </div>
    );
  };
};


TableActions.defaultProps = {
  searchOnKey: 'name'
};


export default connect()(TableActions);
